import AllSkintones from "character-creator/colours/skin";
import EarShapeList from "character-creator/components/head/ears/EarShapeList";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";

const headPath = basePath + "head.";
const earsPath = headPath + "ears.";

const EarsPage = {
  id: "earsPage",
  name: "Ears",
  description: "",
  symmetryAttribute: earsPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "earShape",
        name: "Shape and texture",
        description: "",
        dataAttribute: [earsPath + "left.shape", earsPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EarShapeList,
        colourControls: [
          {
            id: "earColour",
            name: "Colour",
            description: "",
            dataAttribute: [
              earsPath + "left.colour",
              earsPath + "right.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "earLeftShape",
        name: "Left shape",
        description: "",
        dataAttribute: [earsPath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EarShapeList,
        colourControls: [
          {
            id: "earLeftColour",
            name: "Colour",
            description: "",
            dataAttribute: [earsPath + "left.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
      },
      {
        id: "earRightShape",
        name: "Right shape",
        description: "",
        dataAttribute: [earsPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: EarShapeList,
        colourControls: [
          {
            id: "earRightColour",
            name: "Colour",
            description: "",
            dataAttribute: [earsPath + "right.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: AllSkintones,
          },
        ],
      },
    ],
  },
};

export default EarsPage;
