import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const nose_shape_pUF2d = {
  name: "Nose 1",
  description: "A long nose",
  tags: ["long", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: <ellipse cx="250" cy="258.63" rx="10.17" ry="30.07" />,
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="247.98" rx="14.12" ry="27.98" />
        </>
      ),
    },
  },
};

const nose_shape_xnxot = {
  name: "Nose 2",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: <ellipse cx="250" cy="274.69" rx="11.91" ry="14.01" />,
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="267.54" rx="13.66" ry="10.4" />
        </>
      ),
    },
  },
};

const nose_shape_X4TQk = {
  name: "Nose 3",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: <ellipse cx="250" cy="277.81" rx="15.31" ry="10.89" />,
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="272.4" rx="15.31" ry="7.51" />
        </>
      ),
    },
  },
};

const nose_shape_fJUEo = {
  name: "Nose 4",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="m252.55 264.62 10.54 19.83c1.02 1.92-.37 4.25-2.55 4.25h-21.07c-2.18 0-3.57-2.32-2.55-4.25l10.54-19.83c1.07-2.04 4.01-2.04 5.09 0z" />
    ),
    basic: {
      light: (
        <>
          <circle cx="250" cy="270.51" r="12.58" />
        </>
      ),
    },
  },
};

const nose_shape_b1vLk = {
  name: "Nose 5",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="m252.81 243.43 9.95 41.71c.43 1.82-.94 3.56-2.81 3.56h-19.89c-1.87 0-3.24-1.74-2.81-3.56l9.95-41.71c.69-2.96 4.91-2.96 5.61 0z" />
    ),
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="258.67" rx="12.84" ry="21.23" />
        </>
      ),
    },
  },
};

const nose_shape_2ZLO8 = {
  name: "Nose 6",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="m250.94 288.25 15.98-19.75c.64-.79.08-1.98-.94-1.98h-31.96c-1.02 0-1.59 1.18-.94 1.98l15.98 19.75c.48.6 1.4.6 1.88 0z" />
    ),
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="265.72" rx="22.28" ry="5.75" />
        </>
      ),
    },
  },
};

const nose_shape_wMPpG = {
  name: "Nose 7",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <ellipse cx="243.58" cy="284.65" rx="3.62" ry="4.05" />
        <ellipse cx="256.42" cy="284.65" rx="3.62" ry="4.05" />
      </>
    ),
    basic: {
      light: <></>,
    },
  },
};

const nose_shape_SMEl1 = {
  name: "Nose 8",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M259.55,273.78c1.97,0,3.48,1.82,3.04,3.74c-1.45,6.43-6.54,11.17-12.6,11.17c-6.06,0-11.15-4.75-12.59-11.17c-0.43-1.92,1.07-3.74,3.04-3.74H259.55z" />
    ),
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="274.5" rx="12.67" ry="6.74" />
        </>
      ),
    },
  },
};

const nose_shape_0dFS5 = {
  name: "Nose 9",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M257.09,258.34v28.38c0,1.09-0.89,1.98-1.98,1.98h-10.22c-1.09,0-1.98-0.89-1.98-1.98v-28.38c0-1.09,0.89-1.98,1.98-1.98h10.22C256.2,256.37,257.09,257.25,257.09,258.34z" />
    ),
    basic: {
      light: (
        <path d="M257.09,250.68v25.87c0,1.09-0.89,1.98-1.98,1.98h-10.22c-1.09,0-1.98-0.89-1.98-1.98v-25.87c0-1.09,0.89-1.98,1.98-1.98h10.22C256.2,248.71,257.09,249.59,257.09,250.68z" />
      ),
    },
  },
};

const nose_shape_px2B9 = {
  name: "Nose 10",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M259.6 288.7h-19.2c-1.09 0-1.98-.89-1.98-1.98v-10.08c0-1.09.89-1.98 1.98-1.98h19.2c1.09 0 1.98.89 1.98 1.98v10.08c0 1.09-.89 1.98-1.98 1.98z" />
    ),
    basic: {
      light: (
        <path d="M259.6 279.9h-19.2c-1.09 0-1.98-.89-1.98-1.98v-1.28c0-1.09.89-1.98 1.98-1.98h19.2c1.09 0 1.98.89 1.98 1.98v1.28c0 1.1-.89 1.98-1.98 1.98z" />
      ),
    },
  },
};

const nose_shape_9uveq = {
  name: "Nose 11",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M267.66 275.94c-19.87 7.04-5.06 12.75-17.66 12.75-12.61 0 2.21-5.71-17.66-12.75s10.76-12.75 17.66-12.75 37.54 5.71 17.66 12.75z" />
    ),
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="264.85" rx="22.4" ry="8.35" />
        </>
      ),
    },
  },
};

const nose_shape_GWBQv = {
  name: "Nose 12",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M261.92 275.23c-2.62 1.99-4.99 4.29-7.07 6.83-.52 3.8-2.49 6.63-4.84 6.63-2.36 0-4.33-2.83-4.85-6.64-2.08-2.54-4.45-4.84-7.07-6.83-2.04-1.37-2.61-4.12-1.81-6.57.07-.36.18-.71.33-1.05l11.01-24.67c1.1-2.47 3.68-2.47 4.79 0l11.01 24.67c.15.34.26.7.33 1.05.78 2.47.21 5.21-1.83 6.58z" />
    ),
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="252.76" rx="17.9" ry="20.45" />
        </>
      ),
    },
  },
};

const nose_shape_QNJbB = {
  name: "Nose 13",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M259.91 284.93h-3.38c-2.94 5.02-10.1 5.02-13.04 0h-3.39c-5.75 0-9.33-6.31-6.46-11.36l1.69-2.97 8.15-14.34.06-.11c2.87-5.05 10.06-5.05 12.93 0l.06.11 8.15 14.33 1.69 2.98c2.88 5.05-.72 11.36-6.46 11.36z" />
    ),
    basic: {
      light: (
        <path d="M263.93 261.06c-.09 0-.18.01-.27.01-1.26-7.23-6.9-12.68-13.66-12.68s-12.39 5.45-13.66 12.68c-.09 0-.18-.01-.27-.01-4.22 0-7.65 3.42-7.65 7.65s3.42 7.65 7.65 7.65c1.48 0 2.85-.43 4.02-1.15 2.52 2.89 6.03 4.69 9.91 4.69s7.38-1.8 9.91-4.69c1.17.72 2.54 1.15 4.02 1.15 4.22 0 7.65-3.42 7.65-7.65s-3.43-7.65-7.65-7.65z" />
      ),
    },
  },
};

const nose_shape_GHARB = {
  name: "Nose 14",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M257.96 282.5c-1.2 1.41-2.4 2.63-3.71 4.09-1.18 1.31-2.67 2.1-4.22 2.11h-.07c-1.55-.01-3.04-.79-4.22-2.11-1.3-1.45-2.51-2.68-3.71-4.09-.78-.91-1.71-1.54-2.72-1.94-2.64-1.05-4.72-4.51-4.33-9.07.05-.5.13-1.01.25-1.54.31-1.35.64-2.69 1-4.03.25-.95.51-1.9.78-2.84 1.57-5.49 3.48-10.88 5.73-16.08.02-.05.04-.09.07-.14 2.75-6.73 11.61-6.73 14.36 0 .02.05.04.09.07.14 2.26 5.2 4.17 10.59 5.74 16.08.27.94.53 1.88.77 2.83.35 1.34.68 2.69 1 4.04.12.52.2 1.03.25 1.54.39 4.55-1.69 8.02-4.33 9.07-.99.4-1.93 1.03-2.71 1.94z" />
    ),
    basic: {
      light: <ellipse cx="250" cy="256.71" rx="15.82" ry="19.51" />,
    },
  },
};

const nose_shape_Jvjzy = {
  name: "Nose 15",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M262.1 284.68c-2.59 1.34-5.06 2.23-7.98 3.58-1.74.59-6.51.59-8.25 0-2.92-1.35-5.39-2.25-7.98-3.58-4.24-.1-8.08-2.08-7.48-4.83.07-.26.18-.52.33-.79.41-.69.84-1.38 1.3-2.07.33-.49.66-.98 1.02-1.46 2.04-2.83 4.54-5.6 7.48-8.27.03-.02.06-.05.09-.07 3.59-3.46 15.15-3.46 18.74 0 .03.02.06.05.09.07 2.95 2.67 5.45 5.45 7.49 8.27.35.48.69.97 1.01 1.45.46.69.89 1.38 1.3 2.08.16.27.26.53.33.79.59 2.74-3.24 4.72-7.49 4.83z" />
    ),
    basic: {
      light: (
        <>
          <ellipse cx="250" cy="269.33" rx="19.62" ry="10.52" />
        </>
      ),
    },
  },
};

const nose_shape_n4UIG = {
  name: "Nose 16",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M265.06 274.23c.01.19 0 .36-.02.55 0 .1-.01.21-.03.31-.36 1.9-2.42 4.68-5.01 7.22-3.29 3.24-7.44 6.11-10 6.39-2.56-.27-6.7-3.14-9.99-6.38-2.59-2.54-4.66-5.3-5.02-7.22-.02-.11-.03-.22-.03-.33-.02-.19-.02-.36-.02-.54 0-5.59 5.91-27.11 15.06-27.32 9.15.22 15.06 21.74 15.06 27.32z" />
    ),
    basic: {
      light: <ellipse cx="250" cy="255.07" rx="17.15" ry="20.89" />,
    },
  },
};

const nose_shape_4Zj0W = {
  name: "Nose 17",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M265.67 279.98c-1.6 4.49-5.62 5.01-6.02 4.36-.88-.84 2.55-4.22 1.21-6.8-12.22 6.16-3.11 11.15-10.86 11.15s1.36-5-10.86-11.15c-1.33 2.57 2.1 5.96 1.21 6.8-.4.65-4.43.13-6.02-4.36-.83-1.5-.66-3.97.76-6.41.97-1.67 2.53-3.33 4.76-4.63 3.38-1.48 7.66-2.42 9.73-2.54.14-.01.27-.01.41-.01.14 0 .27.01.41.01 2.07.12 6.36 1.05 9.73 2.54 2.23 1.3 3.79 2.96 4.77 4.64 1.42 2.43 1.6 4.9.77 6.4z" />
    ),
    basic: {
      light: (
        <path d="M264.47 261.93c-2.25 0-4.26.88-5.6 2.27-2.12-1.38-5.31-2.27-8.87-2.27s-6.75.88-8.87 2.27c-1.34-1.38-3.35-2.27-5.6-2.27-4.03 0-7.29 2.82-7.29 6.31 0 3.48 3.26 6.31 7.29 6.31 2.25 0 4.26-.88 5.6-2.27 2.12 1.38 5.31 2.27 8.87 2.27s6.75-.88 8.87-2.27c1.34 1.38 3.35 2.27 5.6 2.27 4.03 0 7.29-2.82 7.29-6.31 0-3.49-3.27-6.31-7.29-6.31z" />
      ),
    },
  },
};

const nose_shape_ICJ42 = {
  name: "Nose 18",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M262.74 283.28c-1.22-.34-2.52-1.05-3.93-1.79l-5.48 5.8c-1.79 1.89-4.88 1.89-6.66 0l-5.48-5.8c-1.41.75-2.71 1.45-3.93 1.79-3.3 1.24-5.89-.39-4.82-5.23.49-1.58 1.09-3.08 1.77-4.47 1.06-2.18 2.32-4.11 3.74-5.75 3.39-3.91 7.65-6.12 12.05-5.98 4.4-.14 8.66 2.07 12.05 5.98 1.42 1.63 2.69 3.56 3.74 5.75.68 1.39 1.27 2.89 1.77 4.47 1.06 4.84-1.52 6.47-4.82 5.23z" />
    ),
    basic: {
      light: (
        <path d="M264.26 261.63c-.19 0-.38.02-.57.03-2.13-4.61-7.45-7.89-13.69-7.89s-11.56 3.27-13.69 7.89c-.19-.01-.38-.03-.57-.03-4.65 0-8.41 3.77-8.41 8.41s3.77 8.41 8.41 8.41c2.23 0 4.25-.87 5.76-2.29 2.4 1.44 5.33 2.29 8.5 2.29s6.1-.85 8.5-2.29c1.51 1.42 3.53 2.29 5.76 2.29 4.65 0 8.41-3.77 8.41-8.41s-3.77-8.41-8.41-8.41z" />
      ),
    },
  },
};

const nose_shape_QiXNa = {
  name: "Nose 19",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M266.02 270.76c.01.23 0 .45-.02.68 0 .13-.01.26-.03.39-.38 2.36-2.58 5.8-5.33 8.95-3.51 4.02-7.92 7.58-10.64 7.92-2.72-.34-7.13-3.9-10.63-7.91-2.76-3.15-4.96-6.58-5.34-8.95-.02-.14-.03-.27-.03-.41-.02-.23-.02-.45-.02-.67 0-6.93 6.28-19.13 16.02-19.4 9.74.27 16.02 12.47 16.02 19.4z" />
    ),
    basic: {
      light: <circle cx="250" cy="259.97" r="19.93" />,
    },
  },
};

const nose_shape_K2dg2 = {
  name: "Nose 20",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M262.74 279.04c.01-.26.02-.51.01-.77 0-7.88-5.01-11.38-12.77-11.68-7.76.31-12.75 3.8-12.75 11.68 0 .25 0 .5.01.76 0 .16.01.31.03.47v.02c2.89 5.54 7.5 9.15 12.72 9.15 5.21 0 9.82-3.6 12.71-9.13 0-.01.01-.03.01-.05.03-.15.03-.3.03-.45z" />
    ),
    basic: {
      light: (
        <path d="M262.76,271.89c0,6.75-5.71,9.04-12.76,9.04s-12.76-2.29-12.76-9.04c0-6.75,5.71-12.21,12.76-12.21S262.76,265.14,262.76,271.89z" />
      ),
    },
  },
};

const nose_shape_LeW95 = {
  name: "Nose 21",
  description: "A round nose",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M266.01 271.44c.02-.23.02-.45.02-.68 0-6.93-6.28-19.13-16.02-19.4-9.74.27-16.02 12.47-16.02 19.4 0 .22 0 .44.02.67 0 .14.01.27.03.41v.02c3.63 4.87 9.42 8.05 15.97 8.05 6.54 0 12.33-3.17 15.96-8.03 0-.01.01-.03.01-.04.02-.14.03-.27.03-.4z" />
    ),
    basic: {
      light: <ellipse cx="250" cy="262.41" rx="15.1" ry="14.05" />,
    },
  },
};

export const nosePack1 = {
  nose_shape_pUF2d,
  nose_shape_xnxot,
  nose_shape_X4TQk,
  nose_shape_fJUEo,
  nose_shape_b1vLk,
  nose_shape_2ZLO8,
  nose_shape_wMPpG,
  nose_shape_SMEl1,
  nose_shape_0dFS5,
  nose_shape_px2B9,
  nose_shape_9uveq,
  nose_shape_GWBQv,
  nose_shape_QNJbB,
  nose_shape_GHARB,
  nose_shape_Jvjzy,
  nose_shape_n4UIG,
  nose_shape_4Zj0W,
  nose_shape_ICJ42,
  nose_shape_QiXNa,
  nose_shape_K2dg2,
  nose_shape_LeW95,
};
