import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Eyes from "character-creator/components/head/face/eyes/CharacterEyes";
import { connect } from "react-redux";
import { EyeShapesList } from "../shapes/EyeShapeList";

function mapStateToProps(state, ownProps) {
  const eyeConfigLeftShape = state.config.base.head.face.eyes.base.left.shape;
  return {
    defaultEyeShape:
      eyeConfigLeftShape !== "0" ? eyeConfigLeftShape : "eye_shape_Uugu5",
    ...ownProps,
  };
}

export const PreviewBase = (props) => {
  const { component, defaultEyeShape, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Eyes
        preview={true}
        previewId={previewId}
        eyes={EyeShapesList[defaultEyeShape]}
        eyelash={component}
        defaultEyeShape={defaultEyeShape}
      />
    </>
  );
};

export const viewbox = "170 205 80 80";

export default connect(mapStateToProps)(PreviewBase);
