/**
 * Menu sections
 */
import BaseMenuConfig from "character-creator/menu/config/Base/MenuBase";
import FaceMenuConfig from "character-creator/menu/config/Face/MenuFace";
import AccessoriesMenuConfig from "./Accessories/MenuAccessories";
import BackgroundMenuConfig from "./Background/MenuBackground";
import ClothingMenuConfig from "./Clothing/MenuClothing";
import HairMenuConfig from "./Hair/MenuHair";
import SettingsMenuConfig from "./Settings/MenuSettings";
import { allColours } from "character-creator/colours/colourSchemes";

export const menuSkeleton = {
  menuSettings: SettingsMenuConfig,
  menuBase: BaseMenuConfig,
  menuFace: FaceMenuConfig,
  menuHair: HairMenuConfig,
  menuClothing: ClothingMenuConfig,
  menuAccessories: AccessoriesMenuConfig,
  menuBackground: BackgroundMenuConfig,
};

export const MenuConfigSectionStandard = (props) => {
  const { id, name, description, configPath, options, dependencies } = props;

  return {
    id: id + "Shape",
    name: name,
    description: description,
    dataAttribute: [configPath + ".shape"],
    variationAttribute: "",
    previewType: "preview",
    options: options,
    dependencies: { ...dependencies },
    colourControls: [],
    transformControls: [],
  };
};

export const MenuConfigSectionSymmetry = (props) => {};

export const MenuConfigShape = (props) => {};

export const MenuConfigColour = (props) => {
  const { id, name, description, configPath, options } = props;
  return {
    id: id + "Colour",
    name: name ?? "Colour",
    description: description,
    dataAttribute: [configPath + ".colour"],
    attributeType: "multi",
    previewType: "swatch",
    options: options ?? allColours,
  };
};

export const MenuConfigDependencies = (props) => {
  const { description, attribute, value } = props;
  return {
    description: description,
    attribute: attribute,
    value: value,
  };
};

export const MenuConfigTransform = (props) => {
  const { id, configPath, x, y, scale, o } = props;

  const xConfig = {
    id: id + "TransformX",
    name: "Horizontal",
    description: "",
    dataAttribute: [configPath + "transform.x"],
    range: {
      min: -50,
      max: 50,
      step: 10,
    },
  };

  const yConfig = {
    id: id + "TransformY",
    name: "Vertical",
    description: "",
    dataAttribute: [configPath + "transform.y"],
    range: {
      min: -50,
      max: 50,
      step: 10,
    },
  };

  const scaleConfig = {
    id: id + "TransformScale",
    name: "Size",
    description: "",
    dataAttribute: [configPath + "transform.scale"],
    range: {
      min: 0.5,
      max: 1.5,
      step: 0.25,
    },
  };

  const opacityConfig = {
    id: id + "TransformO",
    name: "Opacity",
    description: "",
    dataAttribute: [configPath + "transform.opacity"],
    range: {
      min: 0.25,
      max: 1,
      step: 0.25,
    },
  };

  let transformControls = [
    x && xConfig,
    y && yConfig,
    scale && scaleConfig,
    o && opacityConfig,
  ];

  return transformControls;
};
