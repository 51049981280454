// @ts-nocheck
/** @jsxRuntime classic */
/** @jsx nativeEvents */
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import "vanilla-colorful";
import "vanilla-colorful/hex-input.js";
import nativeEvents from "jsx-native-events";
import { nanoid } from "@reduxjs/toolkit";
import ControlContainer from "../ControlContainer";
import { set } from "lodash";
import { updateConfig } from "character-creator/utilities/characterSlice";
import { updateCustomPalettes } from "../../../menu-helper";

const mapDispatchToProps = {
  updateConfig,
};

const mapStateToProps = (state, ownProps) => {
  const paletteConfig = ownProps.paletteConfig
    ? ownProps.paletteConfig
    : {
        id: "palette_" + nanoid(5),
        name: "New palette",
        description: "My new palette!",
      };

  const paletteColours = ownProps.paletteColours
    ? ownProps.paletteColours
    : {
        outline: {
          id: "outline",
          name: "Outline",
          base: "#000000",
        },
        darker: {
          id: "darker",
          name: "Darker",
          base: "#222222",
        },
        dark: {
          id: "dark",
          name: "Dark",
          base: "#444444",
        },
        base: {
          id: "base",
          name: "Base",
          base: "#666666",
        },
        light: {
          id: "light",
          name: "Light",
          base: "#888888",
        },
        lighter: {
          id: "lighter",
          name: "Lighter",
          base: "#AAAAAA",
        },
      };

  return {
    navKeys: state.menu.navKeys,
    customPalettes: state.config.customPalettes,
    paletteConfig,
    paletteColours,
    ...ownProps,
  };
};

// const ColourPicker = (props) => {
//   // Swatch colour
//   let swatchColour = props.default;
//   let updateSwatchColour = props.action;
//   let selectedSwatch = props.selectedSwatch;
//   let swatchComponent = {
//     name: props.name,
//     base: swatchColour,
//   };

//   return (
//     <Fragment>
//       <hex-color-picker
//         id={selectedSwatch + "_picker"}
//         color={swatchColour}
//         onEventColorChanged={updateSwatchColour}
//       ></hex-color-picker>
//       <hex-input color={swatchColour}></hex-input>
//     </Fragment>
//   );
// };

const PaletteEditor = (props) => {
  const dispatch = useDispatch();
  const { paletteConfig, paletteColours, customPalettes, onCloseAction } =
    props;
  const [paletteSwatches, updateSwatches] = useState(paletteColours);
  const [selectedSwatch, updateSelectedSwatch] = useState("outline");
  const [activeColour, updateActiveColour] = useState(
    paletteSwatches[selectedSwatch].base
  );

  const paletteControlConfig = {
    ...paletteConfig,
    dataAttribute: "",
    previewType: "swatch",
    options: Object.values(paletteSwatches),
  };

  const selectSwatch = (dispatch, dataAttribute, updateValue) => {
    if (selectedSwatch !== updateValue) {
      updateSelectedSwatch(updateValue);
      updateActiveColour(paletteSwatches[updateValue].base);
    }
  };

  const updateSwatchColour = (event) => {
    const eventId = event.target.id.split("_");
    const selectedSwatch = eventId[0];
    const oldColour = paletteSwatches[selectedSwatch].base;
    const newColour = event.detail.value;

    if (oldColour !== newColour) {
      const newPaletteColours = paletteColours;
      set(newPaletteColours, `${selectedSwatch}.base`, newColour);
      updateActiveColour(newColour);
      updateSwatches(newPaletteColours);
    }
  };

  const PaletteControl = (
    <ControlContainer
      control={paletteControlConfig}
      sectionLevel={props.sectionLevel}
      onChangeOverride={selectSwatch}
      selectedOption={selectedSwatch}
      hideHeader={true}
    />
  );

  const ColourPicker = (
    <>
      <hex-color-picker
        id={selectedSwatch + "_picker"}
        color={activeColour}
        onEventColorChanged={updateSwatchColour}
      ></hex-color-picker>
      {/* <br /> */}
      <hex-input
        id={selectedSwatch + "_hex"}
        color={activeColour}
        onEventColorChanged={updateSwatchColour}
      >
        <input type="text"></input>
      </hex-input>
    </>
  );

  const savePalette = (event) => {
    let newPaletteSwatches = paletteSwatches;
    const newPaletteColours = {
      outline: newPaletteSwatches.outline.base,
      darker: newPaletteSwatches.darker.base,
      dark: newPaletteSwatches.dark.base,
      base: newPaletteSwatches.base.base,
      light: newPaletteSwatches.light.base,
      lighter: newPaletteSwatches.lighter.base,
    };

    const paletteObject = { ...paletteConfig, ...newPaletteColours };
    const customPalettesUpdated = updateCustomPalettes(
      customPalettes,
      paletteObject
    );
    dispatch(
      updateConfig({
        attribute: ["customPalettes"],
        value: customPalettesUpdated,
      })
    );

    onCloseAction(false);
  };

  return (
    <div className="control-group palette-editor">
      {" "}
      <h5>New palette</h5>
      <div className="palette-meta">
        <label>
          Name
          <input type="text" defaultValue={paletteConfig.name} />
        </label>
        <label>
          Description
          <textarea defaultValue={paletteConfig.description} />
        </label>
      </div>
      <div className="palette-colours">
        {PaletteControl}
        <div className="palette-picker">{selectedSwatch && ColourPicker}</div>
      </div>
      <div className="actions palette-actions">
        <button
          className="button-secondary"
          onClick={() => onCloseAction(false)}
        >
          Cancel
        </button>
        <button onClick={savePalette}>Save</button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaletteEditor);
