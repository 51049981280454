// Components
import { IrisColours } from "character-creator/colours/eye";
import { irisOuterShapeList } from "character-creator/components/head/face/eyes/irises/outer/IrisOuterShapeList";
import { irisInnerShapeList } from "character-creator/components/head/face/eyes/irises/inner/IrisInnerShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyePath = "base.head.face.eyes.";
const eyeBasePath = eyePath + "base.";
const irisPath = eyePath + "irises.";

const IrisPage = {
  id: "irisPage",
  name: "Irises",
  description: "",
  dependencies: [
    {
      description: "Eye shape",
      attribute: [eyeBasePath + "left.shape", eyeBasePath + "right.shape"],
    },
  ],
  dependencyMessage: "Please choose an eye shape first",
  symmetryAttribute: irisPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "irisOuterShape",
        name: "Iris Outer Shape",
        description: "",
        dataAttribute: [
          irisPath + "left.outer.shape",
          irisPath + "right.outer.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Eye shape chosen",
            attribute: [
              eyeBasePath + "left.shape",
              eyeBasePath + "right.shape",
            ],
          },
        ],
        colourControls: [
          {
            id: "irisOuterColour",
            name: "Iris Outer Colour",
            description: "",
            dataAttribute: [
              irisPath + "left.outer.colour",
              irisPath + "right.outer.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "irisInnerShape",
        name: "Iris Inner Shape",
        description: "",
        dataAttribute: [
          irisPath + "left.inner.shape",
          irisPath + "right.inner.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        options: irisInnerShapeList,
        dependencies: [
          {
            description: "Eye shape chosen",
            attribute: [
              eyeBasePath + "left.shape",
              eyeBasePath + "right.shape",
            ],
          },
        ],
        colourControls: [
          {
            id: "irisInnerColour",
            name: "Iris Inner Colour",
            description: "",
            dataAttribute: [
              irisPath + "left.inner.colour",
              irisPath + "right.inner.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "leftIrisOuterShape",
        name: "Left Outer Shape",
        description: "",
        dataAttribute: [irisPath + "left.outer.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Left eye shape chosen",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "leftIrisOuterColour",
            name: "Left Outer Colour",
            description: "",
            dataAttribute: [irisPath + "left.outer.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "leftIrisInnerShape",
        name: "Left Inner Shape",
        description: "",
        dataAttribute: [irisPath + "left.inner.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Left eye shape chosen",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "leftIrisInnerColour",
            name: "Left Inner Colour",
            description: "",
            dataAttribute: [irisPath + "left.inner.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "rightIrisOuterShape",
        name: "Right Outer Shape",
        description: "",
        dataAttribute: [irisPath + "right.outer.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Right eye shape chosen",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "rightIrisOuterColour",
            name: "Right Outer Colour",
            description: "",
            dataAttribute: [irisPath + "right.outer.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
      {
        id: "rightIrisInnerShape",
        name: "Right Inner Shape",
        description: "",
        dataAttribute: [irisPath + "right.inner.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: irisOuterShapeList,
        dependencies: [
          {
            description: "Right eye shape chosen",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "rightIrisInnerColour",
            name: "Right Inner Colour",
            description: "",
            dataAttribute: [irisPath + "right.inner.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: IrisColours,
          },
        ],
      },
    ],
  },
};

export default IrisPage;
