import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { GlassesPack1 } from "./packs/GlassesPack1";
import { GlassesShinePack1 } from "./packs/GlassesShinePack1";

export const EyeAccessoriesList = {
  "0": BlankListOption,
  ...GlassesPack1,
};

export const GlassesShineList = {
  "0": BlankListOption,
  ...GlassesShinePack1,
};
