import React from "react";
import { connect } from "react-redux";
import CharacterHeadShadow from "./CharacterHeadShadow";

function mapStateToProps(state, ownProps) {}

export const ClippedHeadShadow = (props) => {
  const { colour, componentName, maskUrl, maskTransform } = props;

  return (
    <g id={"g-" + componentName + "-headShadow"}>
      <mask id={maskUrl + "_headShadow"} fill="white" fillRule="evenodd">
        <use
          href={`#${maskUrl}`}
          transform-origin="50% 50%"
          {...maskTransform}
        />

        <CharacterHeadShadow
          colour={colour}
          alternateId={`headShadow-${componentName}`}
          alternateMask={maskUrl + "_headShadow"}
        />
      </mask>
    </g>
  );
};

export default connect(mapStateToProps)(ClippedHeadShadow);
