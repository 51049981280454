import React from "react";
////////////////////////////////////////////
// Default - used for normal previews
////////////////////////////////////////////
export const svgDimensions = {
  default: "100",
  small: "75",
};

export const defaultViewbox = "0 0 500 500";

////////////////////////////////////////////
// Small - used for colour swatches
////////////////////////////////////////////

export const SvgButton = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgDimensions[props.size ? props.size : "default"]}
      height={svgDimensions[props.size ? props.size : "default"]}
      viewBox={props.viewbox ? props.viewbox : defaultViewbox}
      role="button"
    >
      <title>{props.title}</title>
      {props.children}
    </svg>
  );
};
