import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { muzzleShapeList } from "./MuzzleShapeList";
import ClippedMarkings from "character-creator/components/head/markings/ClippedMarkings";
import {
  translateTransformObject,
  buildTransform,
} from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const muzzleConfig = state.config.base.head.face.extras.muzzle;
  let muzzleObject = null;
  if (ownProps.preview && ownProps.muzzle) {
    muzzleObject = ownProps.muzzle;
  } else if (muzzleConfig.shape !== "0") {
    muzzleObject = muzzleShapeList[muzzleConfig.shape];
  }

  const colour = muzzleConfig.colour
    ? {
        base: muzzleConfig.colour.dark,
        dark: muzzleConfig.colour.darker,
      }
    : {
        base: state.config.base.skintone.dark,
        dark: state.config.base.skintone.darker,
      };

  const transform = !ownProps.preview
    ? translateTransformObject(buildTransform(muzzleConfig.transform))
    : "";

  return {
    colour: colour,
    Muzzle: muzzleObject,
    transform,
  };
}

const Muzzle = (props) => {
  const { colour, Muzzle, transform, previewId } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (Muzzle) {
    return (
      <g
        id={previewId ? `g-muzzle~${previewId}` : "g-muzzle"}
        transform-origin="50% 50%"
        {...transform}
      >
        <Component
          id={previewId ? `muzzle~${previewId}` : "muzzle"}
          component={Muzzle}
          colour={colour}
        />
        {!previewId && Muzzle && (
          <ClippedMarkings componentName="muzzle" maskUrl="muzzle_mask" />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Muzzle);
