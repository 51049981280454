import React from "react";
import {
  PreviewBase,
  viewboxLeft,
  viewboxLeftAboveShoulderClose,
} from "../../Preview";

const hair_side_fjewi_L = {
  name: "Hair style 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeft,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M194.2 188.4c-12.01 5.57-20.28 17.3-24.63 29.82-4.35 12.51-5.27 25.91-6.16 39.13-3.42 50.87-6.83 101.74-10.25 152.61 5.08-6.47 6.65-14.94 8.07-23.05 2.48 31.77-2.74 64.12-15.1 93.49 9.41-4.92 14.22-15.52 17.51-25.61 13.98-42.89 12.44-89.04 11.92-134.15-.51-45.12.43-91.71 18.64-132.24z" />
    ),
    basic: {
      dark: (
        <path d="M143.13 483.09c14.52-32.46 22.96-67.62 24.75-103.14 1.46-28.96-1.45-58.02.19-86.98 1.63-28.95 8.36-58.77 26.54-81.37-8.5 30.55-14.7 61.98-14.73 93.69-.05 47.67 13.74 95.79 4.04 142.46-2.73 13.11-7.39 26.04-15.47 36.72s-19.84 18.97-33.07 21.01c3.72-9.31 6.2-18.94 7.75-22.39z" />
      ),
    },
  },
};

const hair_side_123mf_L = {
  name: "Hair style 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeft,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M148.53 415.79c-2.16-8.83-3.58-18.88-1.94-28.06 1.08-6.03 3.39-11.13 4.73-17 2.54-11.08 1.41-23.71-.44-35.22-1.85-11.51-4.4-22.88-4.62-34.84-.47-25.53 4.41-48.96 12.93-72.52 7.79-21.55 36.52-49.83 33.69-38.76-3.33 13.01-14.78 40.98-15.75 57.72-.68 11.76 5.3 48.94 6.05 61.24.88 14.51 6.57 27.39 5.96 42.28-.72 17.49-10.49 32.77-7.89 49.69 1.13 7.37 4.44 12.96 6.59 19.57 3.15 9.69 3.74 21.65 2.26 32.53-1.48 10.88-4.91 20.72-9.23 28.87l1.62-6.12c-6.4 11.05-14.76 18.45-23.58 20.86 4.75-8.3 8-19.29 9.16-30.97-3.72 10.76-7.92 20.99-12.55 30.58 2.56-16.67 4.48-33.64 5.75-50.77-1.86 9.13-4.1 18.45-8.21 24.84 3.44-7.43 3.56-18.28 2.04-27.53-1.5-9.25-4.41-17.55-6.57-26.39z" />
    ),
    basic: {
      dark: (
        <path d="M162 297.56c-1.59 21.36 2.03 31.37 1.34 52.76-.39 12.11-3.1 23.56-5.78 34.72 2.27-2.85 4.21-6.54 5.67-10.76-2.87 9.43-1.44 21.18.98 31.01 2.41 9.83 5.77 19.16 6.72 29.75 1.12 12.43-1.26 25.14-4.82 36.02-3.56 10.88-8.28 20.39-12.42 30.59 9.61.94 19.38-4.24 27.13-14.4 3.55-4.65 6.69-10.32 9.13-16.85 4.96-13.28 6.83-29.45 7.74-45.31 2.08-36.34-.37-73.09-4.6-108.83-1.8-15.18-3.92-30.29-7.2-44.67-2.38-10.46-14.6-36.7-15.59-30.79-1.95 11.68-7.06 40.12-8.3 56.76z" />
      ),
    },
  },
};

const hair_side_jiw74_L = {
  name: "Hair style 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeft,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M139.55 301.12c3.21-5.71 8.38-10.02 12.82-14.84 4.44-4.82 8.38-10.79 8.16-17.34-.34-9.81-9.7-17.14-11.54-26.78-1.2-6.27 2.62-18.93 5.58-24.58 3.25-6.2 18.18-30.87 38.58-30.87 4.78 0-11.21 46.89-11.55 49.96-.28 2.56-1.45 4.95-1.91 7.48-1.9 10.6 8.69 19.65 9.4 30.39.47 7.07-3.42 13.67-7.76 19.28-4.33 5.61-9.35 10.92-11.78 17.57-1.56 4.25-1.72 9.63 1.61 12.71 1.97 1.82 4.72 2.44 7.28 3.23 5.3 1.63 10.47 4.41 13.64 8.95 4.63 6.63 4.11 15.71 1.28 23.28-2.84 7.57-7.67 14.21-11.45 21.36-3.1 5.87-5.53 12.65-3.83 19.07 2.26 8.57 10.9 13.59 18.76 17.69-11.79 1.09-23.97-4.37-30.99-13.9s-8.64-22.78-4.12-33.71c1.78-4.31 4.44-8.26 5.82-12.72 1.38-4.46 1.19-9.85-2.13-13.14-2.87-2.84-7.2-3.37-11.03-4.64-7.44-2.48-13.66-8.4-16.48-15.72-2.83-7.33-2.21-15.89 1.64-22.73z" />
    ),
    basic: {
      dark: (
        <>
          <path d="M186.35 366.4c7.03-7.91 15.83-19.14 9.6-29.91-.1.45-.23.88-.44 1.29-10.7-13.51-43.85-6.13-41.31-29.18 4.31-10.81 12.76-17.16 17.14-27.94 6.04-19-7.89-24.39-7.89-48.69.72-15.15 18.43-42.19 27.16-33.34 7.62 7.73-3.7 35.22 1.04 44.98 11.07 25.68 6.69 55.07 5.29 82.28 12.25 11.69 22.88 33.47 9.17 47.73-7.23 6.79-23.7 10.45-21.13 23.11 1.33 7.96 21.6 13.91 15.57 22.79-16.09 7.36-32.01-11.28-28.02-27.32 1.68-9.71 7.5-18.2 13.82-25.8z" />
          <path d="M134.04 312.27c.16-.87.78-2 1.6-1.66 3.56 38.25 50.9 18.79 39.2 45.61-3.53 6.68-9.79 11.62-13.19 18.36-1.29 2.56-2.22 13.12-5.35 11.85-3.35-1.36-5.8-10.7-6.95-13.77-6.29-19.48-18.4-39.49-15.31-60.39z" />
        </>
      ),
      light: <></>,
    },
  },
};

const hair_side_2k47f_L = {
  name: "Hair style 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeft,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M155.6 392.7c-.5-2.56-2.92-4.19-4.77-6.03-4.88-4.86-6.26-12.53-4.61-19.21 1.65-6.68 5.98-12.45 11.09-17.07 2.18-1.97 4.78-4.51 3.96-7.34-.29-1-.98-1.82-1.61-2.64-5.56-7.21-8-16.74-6.57-25.74 1.43-8.99 6.7-17.3 14.22-22.43 2.22-1.51 4.99-3.81 3.87-6.25-.5-1.09-1.66-1.7-2.63-2.4-4.31-3.14-5.55-8.93-6.33-14.21-2.38-15.93-3.38-32.83 2.52-48.14 1.83-4.75 12.61-27.65 26.15-27.65 8.22 0 .51 90.19-12.06 93.59-.72 4.16 1.04 8.29 2.53 12.24 2.65 7.01 4.54 14.47 4.01 21.95-.54 7.48-3.78 15.01-9.81 19.46-1.83 1.35-4.04 2.6-4.69 4.79-.7 2.35.72 4.75 1.96 6.87 3.89 6.65 6.64 14.3 5.82 21.96s-5.88 15.19-13.32 17.18c7.34 7.4 11.22 18.09 10.33 28.48-.95-1.57-2.59-2.7-4.4-3.04 2.2 5.83 4.06 11.79 5.59 17.84-2.42-.53-4.65-1.89-6.23-3.79.04 2.52.08 5.03.12 7.55.01.79.01 1.64-.41 2.31-1.11 1.79-3.88.95-5.61-.26-5.54-3.86-9.78-9.57-11.86-16-.92-2.83-3.21-6.64-5.76-5.09-.47-6.64 1.39-13.43 5.18-18.9 1.7-2.45 3.9-5.1 3.32-8.03z" />
    ),
    basic: {
      dark: (
        <path d="M148.53 386.15c-5.31-5.84-6.03-15.34-1.65-21.91-1.33 6.63 1.83 20.25 10.42 18.85-.97-2.6-4.76-9.09-1.69-10.83 7.03 11.94 13.58 15.51 19.28.24.58-3.66 4.24-2.64 4.56.76 1.41 8.35-4.5 15.59-11.54 18.88 1.74 4.87 5.88 9.82 6.11 15.58-2.62-3.54-5.82-6.65-9.44-9.15 1.82 5.99 2.81 12.23 2.93 18.49-5.48-2.67-9.64-7.88-11.04-13.81-2.24 6.3-9.27 10.84-9.1 17.52-4.55-9.54 3.23-18.92 5.66-27.91-.16-2.75-2.64-4.67-4.5-6.71zm28.09-26.02c-2.8-2.22-11.43-14.77-11.49-4.71.23 1.6.61 3.53-.6 4.6-5.74-8.81.31-11.83-11.99-5.24.34-4.38 6.18-7.24 5.57-11.59-2.84-6.46-7.42-13.27-5.12-20.59 3.45 4.62 6.7 15.3 13.27 15.02 2.83-1.87 4.83-4.92 7.32-7.25-.07 2.2-.13 4.41-.2 6.61 5.62.51 8.12-6.02 12.15-8.73-8.15 22.84-17.2 6.87-8.91 31.88zm7.9-69.49c2.34-.85 4.46-2.14 6.3-3.74-1.85-2.93-4.01-5.47-6.4-8.25-.88-1.02-1.93-2.22-2.92-3.52-4.57-3.13-8.36-11.6-11.82-14.74-2.63 5.09.73 11.5 1.07 16-7.61-5.89-5.06-17.7-10.44-24.98-3.95 12.85 1.84 24.55 8.14 35.16.11 5.11-8.99 5.88-8.47 11.14 1.7-.46 3.42-1.11 3.44 1.58-.23 1.71-2.23 4.27-.16 5.51 12.79-19.86 14.51-2.86 21.78 8.9 3.53-7.31-12.1-22.57-.52-23.06zm-22.1 145.5c0-1.12-.1-2.23-.31-3.33.98 1.86 2.19 3.91 4.23 4.41-.2-4.14.01-8.3.62-12.39 8.14 9.63 7.86 8.62 7.32-3.17 12.07 24.62-14.3 32.9-20.44 6.83 2.6 2.84 5.25 5.72 8.58 7.65z" />
      ),
    },
  },
};

const hair_side_nf9a3_L = {
  name: "Hair style 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeft,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M201.06 177.47c-18.52 9.98-29.51 32.66-33.42 53.34-3.9 20.68-1.74 41.95.01 62.91 4.99 59.55 6.63 119.38 4.9 179.11 7.47-23 9.2-47.44 9.75-71.61.73-32.04-.51-64.13-3.71-96.02-2.18-21.7-5.27-43.53-3.11-65.24 2.16-21.7 23.31-61.83 25.58-62.49z" />
    ),
    basic: {
      dark: (
        <path d="M170.76 478.38c10.7-69.02 10.32-139.44 3.64-208.96-.14-1.42.01-3.29 1.39-3.66 1.08-.29 2.09.62 2.8 1.49 7.98 9.61 11.41 22.17 13.59 34.48 4.68 26.45 4.59 53.49 4.48 80.35-.11 27.31-.28 55.01-7.55 81.34-1.25 4.54-2.79 9.16-5.89 12.71-3.11 3.53-8.14 5.76-12.46 2.25z" />
      ),
    },
  },
};

const hair_side_nfkw9_L = {
  name: "Hair style 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeft,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M194.24 187.06c.56-.88-.29-1.98-1.29-1.69-3.71 1.05-8.98 3.35-11.16 4.59-2.78 1.59-6.61 4.61-10.56 10.47-1.9 2.83-3.49 7.95-3.17 11.34.55 5.87-9.1 10.03-7.5 15.91.94 3.47 5.53 5.19 5.95 8.76.54 4.53-5.86 6.96-6.82 11.42-1.07 4.97 5.04 8.99 4.97 14.08-.07 5.19-6.4 8.47-6.95 13.63-.58 5.39 5.34 9.45 6.01 14.83.93 7.39-8.21 14.3-5.09 21.07 1.21 2.62 4.04 4.3 4.93 7.05 1.82 5.65-5.53 10.34-5.95 16.26-.45 6.34 7.04 10.92 7.23 17.26.21 7.11-8.81 13.21-6.06 19.77 1.17 2.8 4.22 4.45 5.47 7.21 3.09 6.83-6.44 14-4.65 21.28.61 2.49 2.48 4.47 3.49 6.83 2.15 5.04.1 10.76-1.16 16.1-1.39 5.87-1.12 13.41 5.01 15.72 1.46.55 3.19-.11 3.76-1.57.95-2.42-2.3-4.21-3.9-6.3-2.37-3.1-.95-7.57.9-11 1.85-3.43 4.15-7.15 3.24-10.94-.95-3.94-5.16-6.6-5.69-10.62-.9-6.87 9.32-12.25 6.99-18.77-1.16-3.23-5.02-4.79-6.48-7.9-3.39-7.21 8.13-14 6.99-21.88-.81-5.59-7.76-9.09-7.71-14.74.03-3.5 2.8-6.27 5.07-8.94 2.28-2.66 4.3-6.4 2.69-9.51-1.47-2.86-5.34-3.74-7.03-6.47-1.88-3.05-.35-7.02 1.57-10.04 1.92-3.02 4.31-6.15 4.07-9.72-.35-5.24-6.25-9.1-5.88-14.33.36-5.13 6.6-8.35 6.99-13.48.4-5.11-5.23-9.21-4.9-14.33.31-4.83 5.81-8.25 5.73-13.08-.07-4.38-3.56-8.47-1.28-12.21 6-9.82 6.05-15.96 6.62-18.38 1.56-6.62 8.92-7.16 15.55-17.68z" />
    ),
    basic: {
      dark: (
        <path d="M184.63 352.14c-.6-6.31-1.19-12.61-1.79-18.92 2.48-24.78 2.01-49.86-1.42-74.53-.34-2.47-.75-5.02-2.12-7.1s-4-3.54-6.38-2.79c-3.03.96-4.04 4.66-4.58 7.79-.94 5.48-1.89 11.07-1.1 16.58.91 6.37 4.11 12.82 2.14 18.94-1.14 3.56-3.99 6.8-3.48 10.51.33 2.38 1.98 4.26 3.48 6.21-1.66 4.39-1.39 9.03-.73 13.74-1.18 1.82-2.33 3.68-2.83 5.7-1.26 5.07 1.76 10.05 3.94 14.83-1.31 8.04-4.97 15.7-10.54 21.66-1.83-2.16-4.41-3.6-6.49-3.14-2.08.46-3.64 2.15-4.93 3.84-10.52 13.67-13.04 32.07-11.23 49.23.98 9.33 3.29 18.88 9.16 26.19 5.87 7.32 16 11.82 24.9 8.85 6.54-2.18 11.39-7.98 13.94-14.38 2.55-6.4 3.1-13.41 3.33-20.3.69-21.01-1.29-41.99-3.27-62.91z" />
      ),
    },
  },
};

const hair_side_04nsc_L = {
  name: "Hair style 7",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeftAboveShoulderClose,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M196.73 182.61c-5.21.4-10.43 1.72-14.87 4.47s-8.07 7.02-9.43 12.06c-1.31 4.87-.49 10.1-1.63 15.01-1.61 6.93-6.84 12.33-10.89 18.18s-7.03 13.66-3.68 19.93c2.31 4.32 7.33 7.23 8.08 12.07.51 3.25-1.13 6.47-3.17 9.06-2.04 2.58-4.53 4.83-6.22 7.66-4.04 6.77-2.18 16.44 4.08 21.23 2.12 1.62 4.61 2.7 6.77 4.26 2.16 1.56 4.08 3.82 4.21 6.49.19 4.08-3.69 7.18-4.73 11.12-1.36 5.16 2.45 10.32 6.81 13.4 5.55 3.93 13.22 5.96 19.15 2.64 1.03-.58 2.05-1.41 2.28-2.57.47-2.4-2.7-3.96-5.11-3.54-2.4.42-4.61 1.89-7.05 1.86-4.68-.05-7.55-5.65-6.97-10.3.57-4.65 3.34-8.69 5.14-13.01s2.44-9.83-.73-13.27c-3.38-3.67-9.75-3.51-12.73-7.51-2.81-3.78-1.08-9.23 1.58-13.11 2.66-3.88 6.24-7.4 7.22-12 2.11-9.84-8.56-19.23-6.11-29 1.95-7.78 11.29-11.61 14.2-19.08 2.97-7.61-1.26-17.6 4.22-23.65 1.97-2.17 4.82-3.29 7.06-5.18 2.25-1.88 3.83-5.28 2.52-7.22z" />
    ),
    basic: {
      dark: (
        <path d="M197.84 342.7c-5.87 4.77-14.47 5.9-21.37 2.8s-11.77-10.28-12.1-17.83c1.66 2.25 5.36 1.63 7.42-.26 2.06-1.89 3.13-4.61 4.83-6.83 2.37-3.1 5.95-5.21 7.94-8.56 1.72-2.88 2.06-6.55.91-9.7-1.15-3.15-3.78-5.73-6.95-6.83-2.99-1.03-6.25-.77-9.4-1.02-3.15-.24-6.57-1.21-8.31-3.85-2.29-3.46-.73-8.15 1.51-11.64s5.18-6.8 5.69-10.92c.64-5.08-2.6-9.75-3.92-14.7-1.78-6.62.12-14.11 4.84-19.08 2.67-2.82 6.74-4.9 10.4-3.6 3.3 1.17 5.14 4.61 6.53 7.82 9.05 20.99 11.6 44.12 14.03 66.85.87 8.11 1.74 16.21 2.61 24.32.29 2.71.58 5.52-.21 8.13-.79 2.62-2.94 5.03-4.45 4.9z" />
      ),
      light: (
        <path d="M200.45 176.52c-2.9 7.29-8.85 13.31-16.11 16.29-3.13 1.28-6.67 2.15-8.8 4.78-3.17 3.91-1.73 9.62-2.05 14.64-.36 5.64-3.12 10.84-6.2 15.58-3.07 4.74-6.55 9.3-8.62 14.56-2.08 5.26-2.57 11.5.3 16.37-1.21.84-2.97.43-4.06-.56s-1.67-2.42-2.14-3.82c-3.89-11.67-2.68-24.49.48-36.37 2.38-8.95 5.89-17.7 11.25-25.25 5.36-7.56 12.66-13.9 21.32-17.2 5.18-1.97 11.72-2.56 14.63.98z" />
      ),
    },
  },
};

const hair_side_vifwo_L = {
  name: "Hair style 8",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeftAboveShoulderClose,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M200.06 180.3c-9.6 2.34-17.72 9.09-23.3 17.25-5.58 8.16-8.85 17.67-11.31 27.24-5.58 21.67-7.27 44.32-5.08 66.58 1.07 10.86 3.06 21.64 5.97 32.16.74-3.33 1.49-6.66 2.23-9.99 3.16 10.97 8.36 22.5 18.59 27.57-11.08-31.73-14.82-65.99-10.87-99.37 2.57-21.76 8.85-44 23.77-61.44z" />
    ),
    basic: {
      dark: (
        <path d="M166.51 330.68c-9.88-22.16-12.6-47.45-7.64-71.21 1.26 8.7 4.09 17.16 8.29 24.88-.99-15.7 2.7-31.66 10.49-45.32.58 25.44 1.25 51.38 9.72 75.37 3.2 9.06 7.48 17.74 10.09 26.99.65 2.3 1.17 4.85.14 7-1.56 3.25-5.87 4.03-9.45 3.59-11.18-1.37-17.61-12.28-21.64-21.3z" />
      ),
      light: (
        <path d="M203.09 180.18c-10.39 7.57-21.03 15.41-27.91 26.28-6.67 10.55-9.26 23.1-11.72 35.34-.06-13.53 1.88-27.29 7.65-39.53 5.77-12.24 15.69-22.87 28.34-27.67 1.43-.54 2.97-1.02 4.48-.76 1.51.26 2.96 1.47 2.99 3.01.04 2.24-2.6 3.41-3.83 3.33z" />
      ),
    },
  },
};

const hair_side_84n2k_L = {
  name: "Hair style 9",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeftAboveShoulderClose,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M196.22 181.88c-11.97 2.81-19.09 11.29-25.14 21.99-6.05 10.7-8.65 23.01-9.96 35.23-.82 7.61-1.18 15.37-3.52 22.66-2.34 7.29-7.04 14.25-14.09 17.24 3.47 1.91 7.99.69 11.01-1.87 3.02-2.56 4.88-6.19 6.67-9.73.86 19.62-4.83 39.48-15.95 55.66 5.85-.88 11.29-4.2 14.76-8.98-1.71 18.78 8.8 38.11 25.49 46.88-4.96-9.26-9.88-18.67-12.46-28.86-6.07-23.9 1.25-48.89 2.7-73.51.73-12.38-.03-24.9 2.05-37.13 2.31-13.55 10.27-25.73 19.6-36.27 2.48-2.81 5.26-4.49-1.16-3.31z" />
    ),
    basic: {
      dark: (
        <path d="M147.07 317.59c6.85-8.09 11.68-17.89 13.94-28.25.43 11.06 2.73 22.05 6.77 32.36-3.9-26.12-.61-53.27 9.41-77.71 4.17 15.97.93 32.84 1.52 49.34.88 24.42 10.15 47.7 20.02 70.06.98 2.21 1.82 5.18-.03 6.74-1.02.87-2.5.9-3.84.86-11.5-.3-23.19-2.21-33.34-7.61s-18.65-14.66-21.21-25.88c-1.67-7.21 1.28-13.45 6.76-19.91z" />
      ),
      light: (
        <path d="M204.39 180.54c-8.25 5.88-16.58 11.83-23.24 19.47-11.31 12.96-17.14 30.56-15.8 47.72-2.38-14.52-1.89-29.8 3.66-43.43 5.55-13.63 16.61-25.39 30.68-29.71 1.69-.52 3.51-.93 5.23-.51s3.29 1.94 3.21 3.72c-.09 1.76-2.41 3.17-3.74 2.74z" />
      ),
    },
  },
};

const hair_side_8419n_L = {
  name: "Hair style 10",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeftAboveShoulderClose,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M198.84 181.37c-3.77-2.82-14.61 1.62-19.08 5.89-10.19 9.73-18.96 21.21-24.07 34.35-5.1 13.14-6.33 28.04-1.8 41.39 2.95 8.68 8.18 16.42 11.48 24.97 3.31 8.55 4.44 18.8-.6 26.45-1.85 2.8-4.58 5.17-7.86 5.87s-7.08-.56-8.74-3.48c-.64 2.53.53 5.28 2.49 7.01 1.95 1.73 4.56 2.55 7.15 2.85 8.81 1.03 18.01-4.03 21.87-12.02 2.97-6.17 2.84-13.42 1.41-20.12-1.42-6.7-4.04-13.08-5.91-19.67-6.91-24.48-2.69-51.87 11.28-73.13 3.49-5.29 13.9-19.22 12.38-20.36z" />
    ),
    basic: {
      dark: (
        <path d="M153.86 316.17c1.69 2.15 4.99 2.53 7.48 1.4s4.25-3.46 5.41-5.93c2.46-5.24 2.62-11.28 1.84-17.01-.79-5.73-2.46-11.3-3.55-16.98-5.33-27.88 4.48-57.76 23.85-78.51 1.02-1.09 2.37-2.26 3.82-1.88 2.09.55 2.11 3.48 1.67 5.59-5.01 23.93-10.41 48.13-9.11 72.54.45 8.43 1.7 16.8 1.91 25.24.21 8.43-.66 17.08-4.24 24.72-3.58 7.64-10.18 14.18-18.39 16.13-5.9 1.41-12.35.31-17.46-2.95-5.11-3.27-8.81-8.67-10.01-14.61-.66-3.28-.55-6.83 1.01-9.79 1.56-2.96 4.79-5.17 8.13-4.88 3.33.27 5.38 4.05 7.64 6.92z" />
      ),
      light: (
        <path d="M202.77 180.87c-9.95 9.35-22.93 14.89-33.24 23.84-15.02 13.03-23.45 33.29-22.12 53.12-3.62-25.3 4.83-52.05 22.31-70.69 6.73-7.17 15.5-13.45 25.33-13.36 2.52.02 5.15.52 7.1 2.11 1.95 1.6 2.99 4.49.62 4.98z" />
      ),
    },
  },
};

const hair_side_4j29g_L = {
  name: "Hair style 11",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxLeftAboveShoulderClose,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M167.29 235.13c-.78 7.91-.66 16.34-4.78 23.14-2.25 3.7-5.58 6.59-8.46 9.82-2.88 3.23-5.44 7.11-5.55 11.44s3.11 8.95 7.44 8.97c.99.01 1.99-.22 2.95-.01 2.61.56 3.58 3.96 2.89 6.54-.68 2.58-2.43 4.75-3.34 7.26-.91 2.51-.65 5.88 1.71 7.13 2.89 1.53 6.4-1.21 9.61-.57 2.68.54 4.38 3.41 4.44 6.14.06 2.73-1.14 5.32-2.43 7.72 3.12-1.1 5.54-3.97 6.12-7.23.57-3.26-.73-6.78-3.28-8.88-2.77-2.28-7.15-3.43-7.81-6.96-.64-3.46 2.88-6.08 4.68-9.1 2.83-4.73.82-11.67-4.1-14.16-1.82-.92-4.07-1.4-5.17-3.13-1.81-2.84.81-6.34 3.16-8.75 5.49-5.61 10.79-12.37 10.89-20.21.05-3.63-1.04-7.16-1.58-10.75s-.45-7.53 1.65-10.48c.92-1.29 2.17-2.32 3.06-3.63s1.68-3.34.56-4.46c-5.28-5.29-11.88 12.24-12.66 20.16z" />
    ),
    basic: {
      dark: (
        <path d="M174.67 323.4c2.23-2.98 2.79-7.14 1.43-10.61-1.36-3.47-4.59-6.14-8.26-6.81-2.09-.38-4.42-.22-6.11-1.51-1.77-1.36-2.18-3.91-1.77-6.1.4-2.19 1.45-4.21 2.06-6.35.62-2.14.74-4.62-.56-6.44-1.88-2.63-5.92-2.81-8.06-5.24-2.4-2.73-1.4-7.08.62-10.11.76-1.14 1.69-2.45 1.27-3.76-.45-1.39-2.3-1.89-3.66-1.36s-2.31 1.76-3.11 2.97c-6.25 9.45-8.14 21.65-5.13 32.57s10.82 20.42 20.93 25.54c2.11 1.07 4.47 1.98 6.79 1.51 2.31-.45 4.37-2.82 3.56-4.3zm-10.12-49.84c-2.21-.12-3.56-2.71-3.3-4.9.26-2.19 1.59-4.09 2.68-6.01 4.39-7.7 5.29-16.82 6.88-25.54 1.59-8.72 4.31-17.88 11.12-23.55 1.07-.89 2.75-1.65 3.72-.66.49.5.57 1.27.61 1.98.9 16.28-2.65 32.78-10.14 47.26-2.61 5.03-6.13 10.18-11.57 11.42z" />
      ),
    },
  },
};

export const hairSidePack1Left = {
  hair_side_fjewi_L,
  hair_side_123mf_L,
  hair_side_jiw74_L,
  hair_side_2k47f_L,
  hair_side_nf9a3_L,
  hair_side_nfkw9_L,
  hair_side_04nsc_L,
  hair_side_vifwo_L,
  hair_side_84n2k_L,
  hair_side_8419n_L,
  hair_side_4j29g_L,
};
