import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { faceTattoosEyesSidedShapeList } from "./TattooList";
import { buildTransform } from "character-creator/menu/menu-helper";

// TODO: Set up full tattoo config

function mapStateToProps(state, ownProps) {
  const tattooConfig = state.config.base.head.face.markings.tattoo;

  // Left
  const tattooConfigLeft = tattooConfig.left;

  let tattooObjectLeft = null;
  if (ownProps.preview && ownProps.tattoo) {
    tattooObjectLeft = ownProps.tattoo.shape.left;
  } else if (tattooConfigLeft.shape !== "0") {
    tattooObjectLeft =
      faceTattoosEyesSidedShapeList[tattooConfigLeft.shape].shape.left;
  }

  const coloursLeft = tattooConfigLeft.colour
    ? tattooConfigLeft.colour
    : { base: "#222" };

  // Right
  const tattooConfigRight = tattooConfig.right;

  let tattooObjectRight = null;
  if (!ownProps.preview && tattooConfigRight.shape !== "0") {
    tattooObjectRight =
      faceTattoosEyesSidedShapeList[tattooConfigRight.shape].shape.right;
  }

  const coloursRight = tattooConfigRight.colour
    ? tattooConfigRight.colour
    : { base: "#222" };

  const transformLeft = buildTransform(tattooConfigLeft.transform);
  const transformRight = buildTransform(tattooConfigRight.transform);

  return {
    previewId: ownProps.previewId,
    Left: {
      shape: tattooObjectLeft,
      colour: coloursLeft,
      transform: transformLeft,
    },
    Right: {
      shape: tattooObjectRight,
      colour: coloursRight,
      transform: transformRight,
    },
    coloursLeft,
    tattooObjectLeft,
    transformLeft,
    coloursRight,
    tattooObjectRight,
    transformRight,
  };
}

const Tattoo = (props) => {
  const { Left, Right, previewId, alternateId, alternateMask } = props;

  let componentId = "headTattoos";
  let maskId = previewId ? `head~${previewId}_mask` : "head_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  if (Left.shape || Right.shape) {
    return (
      <g
        id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
        style={{
          mask: `url(#${maskId})`,
        }}
      >
        {Left.shape && (
          <Component
            id={
              previewId ? `head-tattoo-left~${previewId}` : "head-tattoo-left"
            }
            component={Left}
            colour={Left.colour}
            transform={Left.transform}
            strokeOverride={"none"}
          />
        )}
        {Right.shape && (
          <Component
            id={
              previewId ? `head-tattoo-right~${previewId}` : "head-tattoo-right"
            }
            component={Right}
            colour={Right.colour}
            transform={Right.transform}
            strokeOverride={"none"}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Tattoo);
