import React from "react";

export const linearGradientFade = (colour, transform, id) => {
  return (
    <linearGradient
      id={`gradient_${id}`}
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform={transform}
    >
      <stop offset="0" stopColor={colour} stopOpacity={1} />
      <stop offset="0.5" stopColor={colour} stopOpacity={0.75} />
      <stop offset="1" stopColor={colour} stopOpacity={0} />
    </linearGradient>
  );
};
