import React from "react";
import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import FrontHorns from "./FrontHorns";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
      <FrontHorns preview={true} previewId={previewId} horns={component} />
    </>
  );
};
