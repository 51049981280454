import React from "react";
import { connect } from "react-redux";
import eyebrowList from "character-creator/components/head/face/facialHair/eyebrows/EyebrowShapeList";
import Component from "character-creator/components/Component";
import {
  buildTransform,
  translateTransformObject,
} from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  // Left
  const eyebrowConfigLeft = state.config.base.head.face.eyebrows.left;
  let eyebrowObjectLeft = null;
  let leftStroke = null;
  let leftLinework = null;
  if (ownProps.preview && ownProps.eyebrows.shape.left) {
    eyebrowObjectLeft = ownProps.eyebrows.shape.left;
    leftStroke = ownProps.eyebrows.stroke;
    leftLinework = ownProps.eyebrows.linework;
  } else if (eyebrowConfigLeft.shape !== "0") {
    eyebrowObjectLeft = eyebrowList[eyebrowConfigLeft.shape].shape.left;
    leftStroke = eyebrowList[eyebrowConfigLeft.shape].stroke;
    leftLinework = eyebrowList[eyebrowConfigLeft.shape].linework;
  }

  const coloursLeft = eyebrowConfigLeft.colour
    ? eyebrowConfigLeft.colour
    : state.config.base.hair.colour;

  // Right
  const eyebrowConfigRight = state.config.base.head.face.eyebrows.right;
  let eyebrowObjectRight = null;
  let rightStroke = null;
  let rightLinework = null;
  if (ownProps.preview && ownProps.eyebrows.shape.right) {
    eyebrowObjectRight = ownProps.eyebrows.shape.right;
    rightStroke = ownProps.eyebrows.stroke;
    rightLinework = ownProps.eyebrows.linework;
  } else if (eyebrowConfigRight.shape !== "0") {
    eyebrowObjectRight = eyebrowList[eyebrowConfigRight.shape].shape.right;
    rightStroke = eyebrowList[eyebrowConfigRight.shape].stroke;
    rightLinework = eyebrowList[eyebrowConfigRight.shape].linework;
  }

  const coloursRight = eyebrowConfigRight.colour
    ? eyebrowConfigRight.colour
    : state.config.base.hair.colour;

  const transformLeft = !ownProps.preview
    ? translateTransformObject(buildTransform(eyebrowConfigLeft.transform))
    : "";
  const transformRight = !ownProps.preview
    ? translateTransformObject(buildTransform(eyebrowConfigRight.transform))
    : "";

  return {
    left: {
      shape: eyebrowObjectLeft,
      colour: coloursLeft,
      stroke: leftStroke,
      linework: leftLinework,
      transform: transformLeft,
    },
    right: {
      shape: eyebrowObjectRight,
      colour: coloursRight,
      stroke: rightStroke,
      linework: rightLinework,
      transform: transformRight,
    },
  };
}

export function Eyebrows(props) {
  const { left, right, previewId } = props;

  if (right.shape || left.shape) {
    return (
      <g id={previewId ? `g-eyebrows~${previewId}` : "g-eyebrows"}>
        {left.shape && (
          <g
            id={"g-eyebrow-left"}
            transform-origin="center center"
            {...left.transform}
          >
            <Component
              id={previewId ? `eyebrow-left~${previewId}` : "eyebrow-left"}
              component={left}
              colour={left.colour}
              linework={left.linework}
            />
          </g>
        )}
        {right.shape && (
          <g
            id={"g-eyebrow-right"}
            {...right.transform}
            transform-origin="50% 50%"
          >
            <Component
              id={previewId ? `eyebrow-right~${previewId}` : "eyebrow-right"}
              component={right}
              colour={right.colour}
              linework={right.linework}
            />
          </g>
        )}
      </g>
    );
  } else {
    return <></>;
  }
}

export default connect(mapStateToProps)(Eyebrows);
