import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import hairMidRightList from "./right/HairMidRightList";
import hairMidLeftList from "./left/HairMidLeftList";
import hairMidTopList from "./top/HairMidTopList";

function mapStateToProps(state, ownProps) {
  const hairConfig = state.config.base.hair.head.mid;

  const hairMidColour = hairConfig.colour
    ? hairConfig.colour
    : state.config.base.hair.colour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig.left;
  let hairObjectLeft = null;
  if (ownProps.preview) {
    hairObjectLeft = ownProps.hair.shape;
  } else if (hairConfigLeft.shape !== "0") {
    hairObjectLeft = hairMidLeftList[hairConfigLeft.shape].shape;
  }

  const coloursLeft = hairConfigLeft.colour
    ? hairConfigLeft.colour
    : hairMidColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig.right;
  let hairObjectRight = null;
  if (ownProps.preview) {
    hairObjectRight = ownProps.hair.shape;
  } else if (hairConfigRight.shape !== "0") {
    hairObjectRight = hairMidRightList[hairConfigRight.shape].shape;
  }

  const coloursRight = hairConfigRight.colour
    ? hairConfigRight.colour
    : hairMidColour;

  ////////////////////////
  // Top
  ////////////////////////
  const hairConfigTop = hairConfig.top;
  let hairObjectTop = null;
  if (ownProps.preview) {
    hairObjectTop = ownProps.hair.shape;
  } else if (hairConfigTop.shape !== "0") {
    hairObjectTop = hairMidTopList[hairConfigTop.shape].shape;
  }

  const coloursTop = hairConfigTop.colour
    ? hairConfigTop.colour
    : hairMidColour;

  return {
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
    top: {
      shape: hairObjectTop,
      colour: coloursTop,
    },
  };
}

const CharacterHairMid = (props) => {
  const { left, right, top, previewId } = props;

  if (top.shape || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-mid~${previewId}` : "g-hair-mid"}>
        {top.shape && (
          <Component
            id={previewId ? `hair-mid-top~${previewId}` : "hair-mid-top"}
            component={top}
            colour={top.colour}
            preview={previewId ? true : false}
          />
        )}
        {left.shape && (
          <Component
            id={previewId ? `hair-mid-left~${previewId}` : "hair-mid-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <Component
            id={previewId ? `hair-mid-right~${previewId}` : "hair-mid-right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairMid);
