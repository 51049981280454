import React from "react";
import { connect } from "react-redux";
import hairTopList from "character-creator/components/hair/top/HairTopList";
import Component from "character-creator/components/Component";

function mapStateToProps(state, ownProps) {
  const hairConfig = state.config.base.hair.head.top;

  const hairTopColour = hairConfig.colour
    ? hairConfig.colour
    : state.config.base.hair.colour;
  ////////////////////////
  // Full style
  ////////////////////////
  const hairConfigFull = hairConfig.full;
  let hairObjectFull = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectFull = ownProps.hair.shape;
  } else if (hairConfigFull.shape !== "0") {
    hairObjectFull = hairTopList[hairConfigFull.shape].shape;
  }
  const coloursFull = hairConfigFull.colour
    ? hairConfigFull.colour
    : hairTopColour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig.full;
  let hairObjectLeft = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectLeft = ownProps.hair.shape;
  } else if (hairConfigLeft.shape !== "0") {
    hairObjectLeft = hairTopList[hairConfigLeft.shape].shape;
  }
  const coloursLeft = hairConfigLeft.colour
    ? hairConfigLeft.colour
    : hairTopColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig.full;
  let hairObjectRight = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectRight = ownProps.hair.shape;
  } else if (hairConfigRight.shape !== "0") {
    hairObjectRight = hairTopList[hairConfigRight.shape].shape;
  }
  const coloursRight = hairConfigRight.colour
    ? hairConfigRight.colour
    : hairTopColour;

  return {
    full: {
      shape: hairObjectFull,
      colour: coloursFull,
    },
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
  };
}

const CharacterHairTop = (props) => {
  const { full, left, right, previewId } = props;

  if (full.shape || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-top~${previewId}` : "g-hair-top"}>
        {full.shape && (
          <Component
            id={previewId ? `hair-top-full~${previewId}` : "hair-top-full"}
            component={full}
            colour={full.colour}
            preview={previewId ? true : false}
          />
        )}
        {!full.shape && left.shape && (
          <Component
            id={previewId ? `hair-top-left~${previewId}` : "hair-top-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {!full.shape && right.shape && (
          <Component
            id={previewId ? `hair-top-right~${previewId}` : "hair-top-right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairTop);
