import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Cheeks from "./Cheeks";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Cheeks preview={true} previewId={previewId} cheeks={component} />
    </>
  );
};

export const viewbox = "150 160 200 180";
