// Sections
import BodyExtrasPage from "./pages/body/BodyExtrasPage";
import BodyShapePage from "./pages/body/BodyPage";
import BodyScarsPage from "./pages/body/BodyScarsPage";
import BodyMarkingsPage from "./pages/body/MarkingsPage";
import TailsPage from "./pages/body/TailsPage";
import EarsPage from "./pages/head/EarsPage";
import HeadShapePage from "./pages/head/HeadPage";
import HornsPage from "./pages/head/HornsPage";
import HeadMarkingsPage from "./pages/head/MarkingsPage";
import HeadScarsPage from "./pages/head/ScarsPage";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// Attribute paths
// export const basePath = "base.";

const BaseMenuConfig = {
  id: "Base",
  name: "Base",
  // icon: icon,
  description: "Build the foundation of your character.",
  groups: [
    {
      id: "Body",
      name: "Body",
      pages: {
        bodyShapePage: BodyShapePage,
        tailsPage: TailsPage,
        bodyMarkingsPage: BodyMarkingsPage,
        bodyExtrasPage: BodyExtrasPage,
        bodyScarsPage: BodyScarsPage,
      },
    },
    {
      id: "Head",
      name: "Head",
      pages: {
        headShapePage: HeadShapePage,
        earsPage: EarsPage,
        headHornsPage: HornsPage,
        headMarkingsPage: HeadMarkingsPage,
        headScarsPage: HeadScarsPage,
      },
    },
  ],
};

export default BaseMenuConfig;
