import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { buildTransform } from "character-creator/menu/menu-helper";
import { HeadMarkingsList } from "./HeadMarkingsList";

function mapStateToProps(state, ownProps) {
  const markingsConfig = state.config.base.head.markings;

  // ////////////////////////////////
  // Marking 1
  // ////////////////////////////////

  const marking1Config = markingsConfig.marking1;
  const marking1Object =
    ownProps.preview && ownProps.marking1
      ? ownProps.marking1
      : HeadMarkingsList[marking1Config.shape];

  let marking1Shape = marking1Object.shape;
  if (marking1Object.shape.left) {
    marking1Shape = {
      base: (
        <>
          {marking1Object.shape.left.base}
          {marking1Object.shape.right.base}
        </>
      ),
    };
  }

  const marking1Transform = buildTransform(marking1Config.transform);

  const marking1Colours = marking1Config.colour
    ? marking1Config.colour
    : { base: state.config.base.skintone.light };

  // ////////////////////////////////
  // Marking 2
  // ////////////////////////////////

  const marking2Config = markingsConfig.marking2;
  let marking2Object = HeadMarkingsList[marking2Config.shape];

  let marking2Shape = marking2Object.shape;
  if (marking2Object.shape.left) {
    marking2Shape = {
      base: (
        <>
          {marking2Object.shape.left.base}
          {marking2Object.shape.right.base}
        </>
      ),
    };
  }

  const marking2Transform = buildTransform(marking2Config.transform);

  const marking2Colours = marking2Config.colour
    ? marking2Config.colour
    : { base: state.config.base.skintone.light };

  // ////////////////////////////////
  // Marking 3
  // ////////////////////////////////

  const marking3Config = markingsConfig.marking3;
  let marking3Object = HeadMarkingsList[marking3Config.shape];

  let marking3Shape = marking3Object.shape;
  if (marking3Object.shape.left) {
    marking3Shape = {
      base: (
        <>
          {marking3Object.shape.left.base}
          {marking3Object.shape.right.base}
        </>
      ),
    };
  }

  const marking3Transform = buildTransform(marking3Config.transform);

  const marking3Colours = marking3Config.colour
    ? marking3Config.colour
    : { base: state.config.base.skintone.light };

  return {
    Marking1: {
      shape: { shape: marking1Shape },
      colour: marking1Colours,
      transform: marking1Transform,
    },
    Marking2: {
      shape: { shape: marking2Shape },
      colour: marking2Colours,
      transform: marking2Transform,
    },
    Marking3: {
      shape: { shape: marking3Shape },
      colour: marking3Colours,
      transform: marking3Transform,
    },
    previewId: ownProps.previewId,
    alternateId: ownProps.alternateId,
    alternateMask: ownProps.alternateMask,
  };
}

const HeadMarkings = (props) => {
  const {
    Marking1,
    Marking2,
    Marking3,
    previewId,
    alternateId,
    alternateMask,
  } = props;

  const componentId = alternateId ? alternateId : "head-marking";
  const maskId = alternateMask ? alternateMask : "head_mask";

  if (Marking1.shape || Marking2.shape || Marking3.shape) {
    return (
      <g
        id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
        mask={`url(#${maskId})`}
      >
        {Marking1.shape && (
          <Component
            id={previewId ? `${componentId}1~${previewId}` : `${componentId}1`}
            component={Marking1.shape}
            colour={Marking1.colour}
            preview={previewId ? true : false}
            strokeOverride={false}
            transform={Marking1.transform}
          />
        )}

        {!previewId && Marking2.shape && (
          <Component
            id={previewId ? `${componentId}2~${previewId}` : `${componentId}2`}
            component={Marking2.shape}
            colour={Marking2.colour}
            preview={previewId ? true : false}
            strokeOverride={false}
            transform={Marking2.transform}
          />
        )}

        {!previewId && Marking3.shape && (
          <Component
            id={previewId ? `${componentId}3~${previewId}` : `${componentId}3`}
            component={Marking3.shape}
            colour={Marking3.colour}
            preview={previewId ? true : false}
            strokeOverride={false}
            transform={Marking3.transform}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(HeadMarkings);
