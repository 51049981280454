import AllSkintones from "character-creator/colours/skin";
import HeadShapeList from "character-creator/components/head/shape/HeadShapeList";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";

const headPath = basePath + "head.";

const HeadShapePage = {
  id: "headShapePage",
  name: "Shape",
  description: "",
  controls: [
    {
      id: "headShape",
      name: "Shape and texture",
      description: "",
      dataAttribute: [headPath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      previewViewbox: "125 140 250 250",
      options: HeadShapeList,
      colourControls: [
        {
          id: "headColour",
          name: "Colour",
          description: "",
          dataAttribute: [headPath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
  ],
};

export default HeadShapePage;
