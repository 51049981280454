import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { BackgroundShapesList } from "./BackgroundShapesList";
import AllPurples from "character-creator/colours/collections/Purples";

function mapStateToProps(state, ownProps) {
  // Configs
  const shapesConfig = state.config.background.shapes;
  const shape1Config = shapesConfig.shape1;
  const shape2Config = shapesConfig.shape2;
  const shape3Config = shapesConfig.shape3;

  // Shape 1
  const Shape1Object =
    ownProps.preview && ownProps.shape
      ? ownProps.shape
      : BackgroundShapesList[shape1Config.shape];

  const Shape1Colours = shape1Config.colour
    ? shape1Config.colour
    : AllPurples[Object.keys(AllPurples)[0]];

  // Shape 2
  const Shape2Object = BackgroundShapesList[shape2Config.shape];

  const Shape2Colours = shape2Config.colour
    ? shape2Config.colour
    : AllPurples[Object.keys(AllPurples)[1]];

  // Shape 3
  const Shape3Object = BackgroundShapesList[shape3Config.shape];

  const Shape3Colours = shape3Config.colour
    ? shape3Config.colour
    : AllPurples[Object.keys(AllPurples)[2]];

  return {
    Shape1Object,
    Shape1Colours,
    Shape2Object,
    Shape2Colours,
    Shape3Object,
    Shape3Colours,
  };
}

const BackgroundShapes = (props) => {
  const {
    Shape1Object,
    Shape1Colours,
    Shape2Object,
    Shape2Colours,
    Shape3Object,
    Shape3Colours,
    preview,
    previewId,
  } = props;

  if (Shape1Object?.shape || Shape2Object?.shape || Shape3Object?.shape) {
    return (
      <g
        id={
          previewId ? `g-background-shapes~${previewId}` : "g-background-shapes"
        }
      >
        {/* Shape 1 */}
        <Component
          id={
            previewId ? `background-shape1~${previewId}` : "background-shape1"
          }
          component={Shape1Object}
          colour={Shape1Colours}
          preview={previewId ? true : false}
        />

        {/* Shape 2 */}
        {!preview && Shape2Object.shape && (
          <Component
            id={"background-shape2"}
            component={Shape2Object}
            colour={Shape2Colours}
            preview={false}
          />
        )}

        {/* Shape 3 */}
        {!preview && Shape3Object.shape && (
          <Component
            id={"background-shape3"}
            component={Shape3Object}
            colour={Shape3Colours}
            preview={false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BackgroundShapes);
