// Sections

import ClothingLayersPage from "./pages/ClothingLayersPage";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// Attribute paths
// export const basePath = "base.";

const ClothingMenuConfig = {
  id: "Clothing",
  name: "Clothing",
  // icon: icon,
  description: "Dress to impress!",
  groups: [
    {
      id: "Layers",
      Name: "Layers",
      pages: { Layer1Page: ClothingLayersPage },
    },
  ],
};

export default ClothingMenuConfig;
