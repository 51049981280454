// Components
import teethColours from "character-creator/colours/teeth";
import {
  teethUpperShapeList,
  teethLowerShapeList,
} from "character-creator/components/head/face/mouth/teeth/TeethShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const mouthPath = "base.head.face.mouth.";
const teethPath = mouthPath + "teeth.";

const TeethPage = {
  id: "teethPage",
  name: "Teeth",
  description: "Sink your teeth into these options",
  controls: [
    {
      id: "teethUpperShape",
      name: "Upper Shape",
      description: "",
      dataAttribute: [teethPath + "upper.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: teethUpperShapeList,
      colourControls: [
        {
          id: "teethUpperColour",
          name: "Colour",
          description: "",
          dataAttribute: [teethPath + "upper.colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: teethColours,
        },
      ],
    },
    {
      id: "teethLowerShape",
      name: "Lower Shape",
      description: "",
      dataAttribute: [teethPath + "lower.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: teethLowerShapeList,
      colourControls: [
        {
          id: "teethLowerColour",
          name: "Colour",
          description: "",
          dataAttribute: [teethPath + "lower.colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: teethColours,
        },
      ],
    },
  ],
};

export default TeethPage;
