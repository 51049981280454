import React from "react";
import { connect } from "react-redux";
import { FrontSideHornsList } from "./FrontHornsList";
import { CentreHornsList } from "./FrontHornsList";
import Component from "character-creator/components/Component";

function mapStateToProps(state, ownProps) {
  // Config path
  const frontHornsConfig = state.config.base.head.extras.horns.front;

  // Centre
  const hornConfigCentre = frontHornsConfig.centre;

  let hornObjectCentre = null;
  if (ownProps.preview && ownProps.horns.shape.centre) {
    hornObjectCentre = ownProps.horns.shape.centre;
  } else if (hornConfigCentre.shape !== "0") {
    hornObjectCentre = CentreHornsList[hornConfigCentre.shape].shape.centre;
  }

  const coloursCentre = hornConfigCentre.colour
    ? hornConfigCentre.colour
    : state.config.base.skintone;

  // Left
  const hornConfigLeft = frontHornsConfig.left;

  let hornObjectLeft = null;
  if (ownProps.preview && ownProps.horns.shape.left) {
    hornObjectLeft = ownProps.horns.shape.left;
  } else if (hornConfigLeft.shape !== "0") {
    hornObjectLeft = FrontSideHornsList[hornConfigLeft.shape].shape.left;
  }

  const coloursLeft = hornConfigLeft.colour
    ? hornConfigLeft.colour
    : state.config.base.skintone;

  // Right
  const hornConfigRight = frontHornsConfig.right;

  let hornObjectRight = null;
  if (ownProps.preview && ownProps.horns.shape.right) {
    hornObjectRight = ownProps.horns.shape.right;
  } else if (hornConfigRight.shape !== "0") {
    hornObjectRight = FrontSideHornsList[hornConfigRight.shape].shape.right;
  }

  const coloursRight = hornConfigRight.colour
    ? hornConfigRight.colour
    : state.config.base.skintone;

  return {
    left: {
      shape: hornObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hornObjectRight,
      colour: coloursRight,
    },
    centre: {
      shape: hornObjectCentre,
      colour: coloursCentre,
    },
  };
}

const FrontHorns = (props) => {
  const { left, right, centre, previewId } = props;

  if (centre.shape || left.shape || right.shape) {
    return (
      <g id={previewId ? `g-horns-front~${previewId}` : "g-horns-front"}>
        {left.shape && (
          <Component
            id={
              previewId ? `horns-front-left~${previewId}` : "horns-front-left"
            }
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <Component
            id={
              previewId ? `horns-front-right~${previewId}` : "horns-front-right"
            }
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
        {centre.shape && (
          <Component
            id={
              previewId
                ? `horns-front-centre~${previewId}`
                : "horns-front-centre"
            }
            component={centre}
            colour={centre.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(FrontHorns);
