import { createSlice } from '@reduxjs/toolkit';
// import { menuConfig } from 'character-creator/menu/menu-config';
import { set } from 'lodash';

const initialMenuConfig = {
    openPage: "pageBase",
    openSubPage: "subPageMain",
    controlsPerPage: 8,
	  navKeys: [32,13],
    // theme: "light"
  };

const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuConfig,
  reducers: {
    updateMenu: (state, action) => {
        set(state, action.payload.attribute, action.payload.value)
      }
    }
});

export const selectMenu = state => state.menu;

export const { updateMenu } = menuSlice.actions;

export default menuSlice.reducer;
