const colour_red_7IO0j = {
  id: "colour_red_7IO0j",
  name: "Dark Red",
  outline: "hsl(231, 82%, 6%)",
  darker: "hsl(339, 80%, 12%)",
  dark: "hsl(354, 93%, 22%)",
  base: "hsl(358, 97%, 31%)",
  light: "hsl(0, 100%, 41%)",
  lighter: "hsl(12, 98%, 44%)",
};

const colour_red_WSLbY = {
  id: "colour_red_WSLbY",
  name: "Orange",
  outline: "hsl(0, 100%, 41%))",
  darker: "hsl(12, 98%, 44%)",
  dark: "hsl(23, 97%, 46%)",
  base: "hsl(34, 95%, 49%)",
  light: "hsl(39, 96%, 50%)",
  lighter: "hsl(43, 100%, 52%)",
};

const colour_red_AkZjf = {
  id: "colour_red_AkZjf",
  name: "Flame",
  outline: "#3E000C",
  darker: "#780116",
  dark: "#C32F27",
  base: "#D8572A",
  light: "#DB7C26",
  lighter: "#F7B538",
};

const colour_red_QZ04F = {
  id: "colour_red_QZ04F",
  name: "Cinnamon",
  outline: "#9B3F25",
  darker: "#BF5B44",
  dark: "#E1784A",
  base: "#EFB779",
  light: "#FFD9A8",
  lighter: "#F5EDD8",
};

const colour_red_yeuNc = {
  id: "colour_red_yeuNc",
  name: "Brick Red",
  outline: "#201212",
  darker: "#541918",
  dark: "#771514",
  base: "#8A1B16",
  light: "#A21D1C",
  lighter: "#C54230",
};

const colour_red_ZyTFV = {
  id: "colour_red_ZyTFV",
  name: "Spicy Peach",
  outline: "#8D2E1B",
  darker: "#AE3520",
  dark: "#C3331F",
  base: "#E53C27",
  light: "#E76C53",
  lighter: "#ED875F",
};

const colour_red_HdQAq = {
  id: "colour_red_HdQAq",
  name: "Ginger",
  outline: "#643622",
  darker: "#91482A",
  dark: "#BF6608",
  base: "#CB842B",
  light: "#D0A952",
  lighter: "#E8CC75",
};

const colour_red_PnbH8 = {
  id: "colour_red_PnbH8",
  name: "Light Auburn",
  outline: "#421710",
  darker: "#69251C",
  dark: "#8D352B",
  base: "#A7493D",
  light: "#BA5E51",
  lighter: "#DA8173",
};

const colour_red_JTZDw = {
  id: "colour_red_JTZDw",
  name: "Auburn",
  outline: "#240909",
  darker: "#240909",
  dark: "#3c100f",
  base: "#541615",
  light: "#6d1c1a",
  lighter: "#9d2926",
};

export const AllReds = {
  colour_red_7IO0j: colour_red_7IO0j,
  colour_red_WSLbY: colour_red_WSLbY,
  colour_red_AkZjf: colour_red_AkZjf,
  colour_red_QZ04F: colour_red_QZ04F,
  colour_red_yeuNc: colour_red_yeuNc,
  colour_red_ZyTFV: colour_red_ZyTFV,
  colour_red_HdQAq: colour_red_HdQAq,
  colour_red_PnbH8: colour_red_PnbH8,
  colour_red_JTZDw: colour_red_JTZDw,
};

export default AllReds;
