import React from "react";
import { connect } from "react-redux";

import {
  EyeballColours,
  IrisColours,
  pupilColours,
  ShineColours,
} from "character-creator/colours/eye";
import { EyeShapesList } from "character-creator/components/head/face/eyes/shapes/EyeShapeList";
import { pupilShapeList } from "character-creator/components/head/face/eyes/pupils/PupilShapeList";
import { irisOuterShapeList } from "character-creator/components/head/face/eyes/irises/outer/IrisOuterShapeList";

import Component from "character-creator/components/Component";
import {
  eyelashShapeListLower,
  eyelashShapeListUpper,
} from "./eyelashes/EyelashShapeList";
import { undereyeShapeList } from "../../markings/undereye/UndereyeList";
import { irisInnerShapeList } from "./irises/inner/IrisInnerShapeList";
import { shineShapeList } from "./shine/ShineShapeList";
import {
  translateTransformObject,
  buildTransform,
} from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const faceConfig = state.config.base.head.face;
  const eyeConfig = faceConfig.eyes;
  const eyeBaseConfig = eyeConfig.base;
  const irisConfig = eyeConfig.irises;
  const pupilConfig = eyeConfig.pupils;
  const shineConfig = eyeConfig.shine;
  const eyelashConfig = eyeConfig.eyelashes;
  const undereyeConfig = eyeConfig.undereye;
  //////////////////////////////
  // Left
  //////////////////////////////
  // Config
  const eyeConfigLeft = eyeBaseConfig.left;
  const eyelashConfigLeft = eyelashConfig.left;
  const undereyeConfigLeft = undereyeConfig.left;
  const irisConfigLeft = irisConfig.left;
  const pupilConfigLeft = pupilConfig.left;
  const shineConfigLeft = shineConfig.left;

  // Object shapes
  const eyeShapeObjectLeft =
    ownProps.preview && ownProps.eyes
      ? ownProps.eyes.shape.left
      : EyeShapesList[eyeConfigLeft.shape].shape.left;
  const eyelidObjectLeft = eyeShapeObjectLeft && eyeShapeObjectLeft.eyelid;
  // Iris
  const irisOuterObjectLeft =
    ownProps.preview && ownProps.irisOuter
      ? ownProps.irisOuter.shape.left
      : irisOuterShapeList[irisConfigLeft.outer.shape].shape.left;
  const irisInnerObjectLeft =
    ownProps.preview && ownProps.irisInner
      ? ownProps.irisInner.shape.left
      : irisInnerShapeList[irisConfigLeft.inner.shape].shape.left;
  // Pupil
  const pupilObjectLeft =
    ownProps.preview && ownProps.pupil
      ? ownProps.pupil.shape.left
      : pupilShapeList[pupilConfigLeft.shape].shape.left;
  // Shine
  const shineObjectLeft =
    ownProps.preview && ownProps.shine
      ? ownProps.shine.shape.left
      : shineShapeList[shineConfigLeft.shape].shape.left;

  // Undereye
  let undereyeObjectLeft = null;
  if (ownProps.preview && ownProps.undereye && eyeConfigLeft.shape !== "0") {
    undereyeObjectLeft = ownProps.undereye.shape[eyeConfigLeft.shape].left;
  } else if (undereyeConfigLeft.shape !== "0" && eyeConfigLeft.shape !== "0") {
    undereyeObjectLeft =
      undereyeShapeList[undereyeConfigLeft.shape].shape[eyeConfigLeft.shape]
        .left;
  }

  // Eyelashes upper
  let eyelashObjectLeftUpper = null;
  if (ownProps.preview && ownProps.eyelash) {
    const previewEyeShape =
      eyeConfigLeft.shape !== "0"
        ? eyeConfigLeft.shape
        : ownProps.defaultEyeShape;
    eyelashObjectLeftUpper = ownProps.eyelash.shape[previewEyeShape].left.upper;
  } else if (
    eyeConfigLeft.shape !== "0" &&
    eyelashConfigLeft.upper.shape !== "0"
  ) {
    eyelashObjectLeftUpper =
      eyelashShapeListUpper[eyelashConfigLeft.upper.shape].shape[
        eyeConfigLeft.shape
      ].left.upper;
  }

  // Eyelashes lower
  let eyelashObjectLeftLower = null;
  if (ownProps.preview && ownProps.eyelash) {
    const previewEyeShape =
      eyeConfigLeft.shape !== "0"
        ? eyeConfigLeft.shape
        : ownProps.defaultEyeShape;
    eyelashObjectLeftLower = ownProps.eyelash.shape[previewEyeShape].left.lower;
  } else if (
    eyeConfigLeft.shape !== "0" &&
    eyelashConfigLeft.lower.shape !== "0"
  ) {
    eyelashObjectLeftLower =
      eyelashShapeListLower[eyelashConfigLeft.lower.shape].shape[
        eyeConfigLeft.shape
      ].left.lower;
  }

  // Colours
  // Eyeball
  const eyeballColourLeft = eyeConfigLeft.colour.eyeball
    ? eyeConfigLeft.colour.eyeball
    : EyeballColours[0];
  // Eyelids
  const eyelidColourLeftTop = eyeConfigLeft.colour.eyelid.dark
    ? { base: eyeConfigLeft.colour.eyelid.dark }
    : { base: state.config.base.head.colour.dark };
  const eyelidColourLeftBottom = eyeConfigLeft.colour.eyelid.light
    ? { base: eyeConfigLeft.colour.eyelid.light }
    : { base: state.config.base.head.colour.light };
  // Iris
  const irisOuterColourLeft = irisConfigLeft.outer.colour
    ? irisConfigLeft.outer.colour
    : IrisColours[Object.keys(IrisColours)[0]];
  const irisInnerColourLeft = irisConfigLeft.inner.colour
    ? irisConfigLeft.inner.colour
    : IrisColours[Object.keys(IrisColours)[1]];
  // Pupil
  const pupilColourLeft = pupilConfigLeft.colour
    ? pupilConfigLeft.colour
    : pupilColours[Object.keys(pupilColours)[0]];
  // Shine
  const shineColourLeft = shineConfigLeft.colour
    ? shineConfigLeft.colour
    : ShineColours[Object.keys(ShineColours)[0]];
  // Eyelashes
  const eyelashColourLeftUpper = eyelashConfigLeft.upper.colour;
  const eyelashColourLeftLower = eyelashConfigLeft.lower.colour;
  // Undereye
  const undereyeColourLeft = undereyeConfigLeft.colour
    ? undereyeConfigLeft.colour
    : { base: state.config.base.skintone.dark };

  // Compiled
  const leftEye = {
    shape: eyeShapeObjectLeft,
    colour: eyeballColourLeft,
  };
  const leftEyelid = eyelidObjectLeft && {
    top: {
      shape: eyelidObjectLeft.top,
      colour: eyelidColourLeftTop,
    },
    bottom: {
      shape: eyelidObjectLeft.bottom,
      colour: eyelidColourLeftBottom,
    },
  };
  const leftEyelidShadow = {
    shape: eyeShapeObjectLeft ? eyeShapeObjectLeft.eyelidShadow : null,
    colour: { base: eyeballColourLeft.dark },
  };
  const leftIris = {
    outer: {
      shape: irisOuterObjectLeft,
      colour: irisOuterColourLeft,
    },
    inner: {
      shape: irisInnerObjectLeft,
      colour: irisInnerColourLeft,
    },
  };
  const leftPupil = {
    shape: pupilObjectLeft,
    colour: pupilColourLeft,
  };
  const leftShine = {
    shape: shineObjectLeft,
    colour: shineColourLeft,
  };
  const leftEyelashes = {
    upper: {
      shape: eyelashObjectLeftUpper,
      colour: eyelashColourLeftUpper,
    },
    lower: {
      shape: eyelashObjectLeftLower,
      colour: eyelashColourLeftLower,
    },
  };
  const leftUndereye = {
    shape: undereyeObjectLeft,
    colour: undereyeColourLeft,
  };

  const transformLeft = !ownProps.preview
    ? translateTransformObject(buildTransform(eyeConfigLeft.transform))
    : "";

  //////////////////////////////
  // Right
  //////////////////////////////
  // Config
  const eyeConfigRight = eyeBaseConfig.right;
  const eyelashConfigRight = eyelashConfig.right;
  const undereyeConfigRight = undereyeConfig.right;
  const irisConfigRight = irisConfig.right;
  const pupilConfigRight = pupilConfig.right;
  const shineConfigRight = shineConfig.right;

  // Shapes
  // Eyeball
  const eyeShapeObjectRight =
    ownProps.preview && ownProps.eyes
      ? ownProps.eyes.shape.right
      : EyeShapesList[eyeConfigRight.shape].shape.right;
  // Eyelid
  const eyelidObjectRight = eyeShapeObjectRight && eyeShapeObjectRight.eyelid;
  // Iris
  const irisOuterObjectRight =
    ownProps.preview && ownProps.irisOuter
      ? ownProps.irisOuter.shape.right
      : irisOuterShapeList[irisConfigRight.outer.shape].shape.right;
  const irisInnerObjectRight =
    ownProps.preview && ownProps.irisInner
      ? ownProps.irisInner.shape.right
      : irisInnerShapeList[irisConfigRight.inner.shape].shape.right;
  // Pupil
  const pupilObjectRight =
    ownProps.preview && ownProps.pupil
      ? ownProps.pupil.shape.right
      : pupilShapeList[pupilConfigRight.shape].shape.right;
  // Shine
  const shineObjectRight =
    ownProps.preview && ownProps.shine
      ? ownProps.shine.shape.right
      : shineShapeList[shineConfigRight.shape].shape.right;

  // Undereye
  let undereyeObjectRight = null;
  if (ownProps.preview && ownProps.undereye && eyeConfigRight.shape !== "0") {
    undereyeObjectRight = ownProps.undereye.shape[eyeConfigRight.shape].right;
  } else if (
    undereyeConfigRight.shape !== "0" &&
    eyeConfigRight.shape !== "0"
  ) {
    undereyeObjectRight =
      undereyeShapeList[undereyeConfigRight.shape].shape[eyeConfigRight.shape]
        .right;
  }

  // Eyelashes upper
  let eyelashObjectRightUpper = null;
  if (ownProps.preview && ownProps.eyelash) {
    const previewEyeShape =
      eyeConfigRight.shape !== "0"
        ? eyeConfigRight.shape
        : ownProps.defaultEyeShape;
    eyelashObjectRightUpper =
      ownProps.eyelash.shape[previewEyeShape].right.upper;
  } else if (
    eyeConfigLeft.shape !== "0" &&
    eyelashConfigRight.upper.shape !== "0"
  ) {
    eyelashObjectRightUpper =
      eyelashShapeListUpper[eyelashConfigRight.upper.shape].shape[
        eyeConfigRight.shape
      ].right.upper;
  }

  // Eyelashes lower
  let eyelashObjectRightLower = null;
  if (ownProps.preview && ownProps.eyelash) {
    const previewEyeShape =
      eyeConfigRight.shape !== "0"
        ? eyeConfigRight.shape
        : ownProps.defaultEyeShape;
    eyelashObjectRightLower =
      ownProps.eyelash.shape[previewEyeShape].right.lower;
  } else if (
    eyeConfigLeft.shape !== "0" &&
    eyelashConfigRight.lower.shape !== "0"
  ) {
    eyelashObjectRightLower =
      eyelashShapeListLower[eyelashConfigRight.lower.shape].shape[
        eyeConfigRight.shape
      ].right.lower;
  }

  // Colours
  // Eyeball
  const eyeballColourRight = eyeConfigRight.colour.eyeball
    ? eyeConfigRight.colour.eyeball
    : EyeballColours[0];
  // Eyelids
  const eyelidColourRightTop = eyeConfigRight.colour.eyelid.dark
    ? { base: eyeConfigRight.colour.eyelid.dark }
    : { base: state.config.base.head.colour.dark };
  const eyelidColourRightBottom = eyeConfigRight.colour.eyelid.light
    ? { base: eyeConfigRight.colour.eyelid.light }
    : { base: state.config.base.head.colour.light };
  // Iris
  const irisOuterColourRight = irisConfigRight.outer.colour
    ? irisConfigRight.outer.colour
    : IrisColours[Object.keys(IrisColours)[0]];
  const irisInnerColourRight = irisConfigRight.inner.colour
    ? irisConfigRight.inner.colour
    : IrisColours[Object.keys(IrisColours)[1]];
  // Pupil
  const pupilColourRight = pupilConfigRight.colour
    ? pupilConfigRight.colour
    : pupilColours[Object.keys(pupilColours)[0]];
  // Shine
  const shineColourRight = shineConfigRight.colour
    ? shineConfigRight.colour
    : ShineColours[Object.keys(ShineColours)[0]];
  // Eyelashes
  const eyelashColourRightUpper = eyelashConfigRight.upper.colour;
  const eyelashColourRightLower = eyelashConfigRight.lower.colour;
  // Undereye
  const undereyeColourRight = undereyeConfigRight.colour
    ? undereyeConfigRight.colour
    : { base: state.config.base.skintone.dark };

  // Compiled
  const rightEye = {
    shape: eyeShapeObjectRight,
    colour: eyeballColourRight,
  };
  const rightEyelidShadow = {
    shape: eyeShapeObjectRight ? eyeShapeObjectRight.eyelidShadow : null,
    colour: { base: eyeballColourRight.dark },
  };
  const rightEyelid = eyelidObjectRight && {
    top: {
      shape: eyelidObjectRight.top,
      colour: eyelidColourRightTop,
    },
    bottom: {
      shape: eyelidObjectRight.bottom,
      colour: eyelidColourRightBottom,
    },
  };
  const rightIris = {
    outer: {
      shape: irisOuterObjectRight,
      colour: irisOuterColourRight,
    },
    inner: {
      shape: irisInnerObjectRight,
      colour: irisInnerColourRight,
    },
  };
  const rightPupil = {
    shape: pupilObjectRight,
    colour: pupilColourRight,
  };
  const rightShine = {
    shape: shineObjectRight,
    colour: shineColourRight,
  };
  const rightEyelashes = {
    upper: {
      shape: eyelashObjectRightUpper,
      colour: eyelashColourRightUpper,
    },
    lower: {
      shape: eyelashObjectRightLower,
      colour: eyelashColourRightLower,
    },
  };
  const rightUndereye = {
    shape: undereyeObjectRight,
    colour: undereyeColourRight,
  };

  const transformRight = !ownProps.preview
    ? translateTransformObject(buildTransform(eyeConfigRight.transform))
    : "";

  return {
    leftEye,
    leftEyelidShadow,
    leftEyelid,
    leftIris,
    leftPupil,
    leftShine,
    leftEyelashes,
    leftUndereye,
    transformLeft,
    rightEye,
    rightEyelidShadow,
    rightEyelid,
    rightIris,
    rightPupil,
    rightShine,
    rightEyelashes,
    rightUndereye,
    transformRight,
    preview: ownProps.preview,
    previewId: ownProps.previewId,
  };
}

const Eyes = (props) => {
  let {
    leftEye,
    leftEyelidShadow,
    leftEyelid,
    leftIris,
    leftPupil,
    leftShine,
    leftEyelashes,
    leftUndereye,
    transformLeft,
    rightEye,
    rightEyelidShadow,
    rightEyelid,
    rightIris,
    rightPupil,
    rightShine,
    rightEyelashes,
    rightUndereye,
    transformRight,
    previewId,
  } = props;

  let leftEyeballId = previewId ? `eyeball-left~${previewId}` : "eyeball-left";
  let leftIrisId = previewId ? `iris-left~${previewId}` : "iris-left";
  let leftPupilId = previewId ? `pupil-left~${previewId}` : "pupil-left";
  let leftShineId = previewId ? `shine-left~${previewId}` : "shine-left";

  let rightEyeballId = previewId
    ? `eyeball-right~${previewId}`
    : "eyeball-right";
  let rightIrisId = previewId ? `iris-right~${previewId}` : "iris-right";
  let rightPupilId = previewId ? `pupil-right~${previewId}` : "pupil-right";
  let rightShineId = previewId ? `shine-right~${previewId}` : "shine-right";

  const blendMultiply = { mixBlendMode: "multiply" };

  if (leftEye.shape || rightEye.shape) {
    return (
      <g id={previewId ? `g-eyes~${previewId}` : "g-eyes"}>
        <g
          id={previewId ? `g-eye-left~${previewId}` : "g-eye-left"}
          transform-box="fill-box"
          transform-origin="center center"
          {...transformLeft}
        >
          {/* Undereye */}
          {leftEye.shape && leftUndereye.shape && (
            <Component
              id={previewId ? `undereye-left~${previewId}` : "undereye-left"}
              component={leftUndereye}
              colour={leftUndereye.colour}
            />
          )}
          {/* First the eyeball */}
          {leftEye.shape && (
            <Component
              id={leftEyeballId}
              component={leftEye}
              colour={leftEye.colour}
              transform={transformLeft}
            />
          )}
          {/* Iris */}
          <g id={`g-${leftIrisId}`}>
            {leftIris.outer.shape && (
              <Component
                id={leftIrisId + "_outer"}
                component={leftIris.outer}
                colour={leftIris.outer.colour}
                maskId={leftEyeballId + "_mask"}
              />
            )}
            {leftIris.inner.shape && (
              <Component
                id={leftIrisId + "_inner"}
                component={leftIris.inner}
                colour={leftIris.inner.colour}
                maskId={leftIrisId + "_outer_mask"}
              />
            )}
            {/* Pupil */}
            {leftPupil.shape && (
              <Component
                id={leftPupilId}
                component={leftPupil}
                colour={leftPupil.colour}
                maskId={leftIrisId + "_outer_mask"}
              />
            )}
            {/* Shine */}
            {leftShine.shape && (
              <Component
                id={leftShineId}
                component={leftShine}
                colour={leftShine.colour}
                maskId={leftEyeballId + "_mask"}
              />
            )}
          </g>
          {/* And then the eyelids */}
          {leftEyelidShadow.shape && (
            <Component
              id={
                previewId
                  ? `eyelid-left-shadow~${previewId}`
                  : "eyelid-left-shadow"
              }
              component={leftEyelidShadow}
              colour={leftEyelidShadow.colour}
              maskId={leftEyeballId + "_mask"}
              style={{ ...blendMultiply }}
              strokeOverride={false}
            />
          )}
          <g id={previewId ? `g-eyelids-left~${previewId}` : "g-eyelids-left"}>
            {leftEyelid && leftEyelid.top.shape && (
              <Component
                id={
                  previewId
                    ? `eyelid-left-upper~${previewId}`
                    : "eyelid-left-upper"
                }
                component={leftEyelid.top}
                colour={leftEyelid.top.colour}
              />
            )}
            {leftEyelid && leftEyelid.bottom.shape && (
              <Component
                id={
                  previewId
                    ? `eyelid-left-bottom~${previewId}`
                    : "eyelid-left-bottom"
                }
                component={leftEyelid.bottom}
                colour={leftEyelid.bottom.colour}
              />
            )}
          </g>
          {/* Eyelash time! */}
          {(!previewId || (previewId && props.eyelash)) && (
            <g id={previewId ? `g-eyelashes~${previewId}` : "g-eyelashes"}>
              {leftEye.shape && leftEyelashes.upper.shape && (
                <Component
                  id={
                    previewId
                      ? `eyelash-left-upper~${previewId}`
                      : "eyelash-left-upper"
                  }
                  component={leftEyelashes.upper}
                  colour={leftEyelashes.upper.colour}
                />
              )}
              {leftEye.shape && leftEyelashes.lower.shape && (
                <Component
                  id={
                    previewId
                      ? `eyelash-left-lower~${previewId}`
                      : "eyelash-left-lower"
                  }
                  component={leftEyelashes.lower}
                  colour={leftEyelashes.lower.colour}
                />
              )}
            </g>
          )}
        </g>
        <g
          id={previewId ? `g-eye-right~${previewId}` : "g-eye-right"}
          transform-box="fill-box"
          transform-origin="center"
          {...transformRight}
        >
          {/* Undereye */}
          {rightEye.shape && rightUndereye.shape && (
            <Component
              id={previewId ? `undereye-right~${previewId}` : "undereye-right"}
              component={rightUndereye}
              colour={rightUndereye.colour}
            />
          )}
          {/* First the eyeball */}
          {rightEye.shape && (
            <Component
              id={rightEyeballId}
              component={rightEye}
              colour={rightEye.colour}
            />
          )}
          {/* Iris */}
          <g id={`g-${rightIrisId}`}>
            {rightIris.outer.shape && (
              <Component
                id={rightIrisId + "_outer"}
                component={rightIris.outer}
                colour={rightIris.outer.colour}
                maskId={rightEyeballId + "_mask"}
              />
            )}
            {rightIris.inner.shape && (
              <Component
                id={rightIrisId + "_inner"}
                component={rightIris.inner}
                colour={rightIris.inner.colour}
                maskId={rightIrisId + "_outer_mask"}
              />
            )}
            {/* Pupil */}
            {rightPupil.shape && (
              <Component
                id={rightPupilId}
                component={rightPupil}
                colour={rightPupil.colour}
                maskId={rightIrisId + "_outer_mask"}
              />
            )}
            {/* Shine */}
            {rightShine.shape && (
              <Component
                id={rightShineId}
                component={rightShine}
                colour={rightShine.colour}
                maskId={rightEyeballId + "_mask"}
              />
            )}
          </g>
          {/* And then the eyelids */}
          {rightEyelidShadow.shape && (
            <Component
              id={
                previewId
                  ? `eyelid-right-shadow~${previewId}`
                  : "eyelid-right-shadow"
              }
              component={rightEyelidShadow}
              colour={rightEyelidShadow.colour}
              maskId={rightEyeballId + "_mask"}
              style={{ ...blendMultiply }}
              strokeOverride={false}
            />
          )}
          <g
            id={previewId ? `g-eyelids-right~${previewId}` : "g-eyelids-right"}
          >
            {rightEyelid && rightEyelid.top.shape && (
              <Component
                id={
                  previewId
                    ? `eyelid-right-top~${previewId}`
                    : "eyelid-right-top"
                }
                component={rightEyelid.top}
                colour={rightEyelid.top.colour}
              />
            )}
            {rightEyelid && rightEyelid.bottom.shape && (
              <Component
                id={
                  previewId
                    ? `eyelid-right-bottom~${previewId}`
                    : "eyelid-right-bottom"
                }
                component={rightEyelid.bottom}
                colour={rightEyelid.bottom.colour}
              />
            )}
          </g>
          {/* Eyelash time! */}
          {(!previewId || (previewId && props.eyelash)) && (
            <g
              id={
                previewId
                  ? `g-eyelashes-right~${previewId}`
                  : "g-eyelashes-right"
              }
            >
              {rightEye.shape && rightEyelashes.upper.shape && (
                <Component
                  id={
                    previewId
                      ? `eyelash-right-upper~${previewId}`
                      : "eyelash-right-upper"
                  }
                  component={rightEyelashes.upper}
                  colour={rightEyelashes.upper.colour}
                />
              )}
              {rightEye.shape && rightEyelashes.lower.shape && (
                <Component
                  id={
                    previewId
                      ? `eyelash-right-lower~${previewId}`
                      : "eyelash-right-lower"
                  }
                  component={rightEyelashes.lower}
                  colour={rightEyelashes.lower.colour}
                />
              )}
            </g>
          )}
        </g>
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Eyes);
