import React from "react";
import NoseAccessories from "./NoseAccessories";
import Nose from "../../../../head/face/nose/CharacterNose";
import CharacterHead from "../../../../head/CharacterHead";

export const PreviewBaseLeft = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Nose preview={true} previewId={previewId} />
      <NoseAccessories
        preview={true}
        previewId={previewId}
        previewSide={"left"}
        accessory={component}
      />
    </>
  );
};

export const PreviewBaseRight = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Nose preview={true} previewId={previewId} />
      <NoseAccessories
        preview={true}
        previewId={previewId}
        previewSide={"right"}
        accessory={component}
      />
    </>
  );
};

export const viewbox = "200 210 100 100";
