import React from "react";
import { connect } from "react-redux";

import Component from "character-creator/components/Component";
import { hairSideListLeft } from "./Left/HairSideListLeft";
import { hairSideListRight } from "./Right/HairSideListRight";

function mapStateToProps(state, ownProps) {
  const hairConfig = state.config.base.hair.head.side;

  const hairSideColour = hairConfig.colour
    ? hairConfig.colour
    : state.config.base.hair.colour;

  ////////////////////////
  // Left
  ////////////////////////
  const hairConfigLeft = hairConfig.left;
  let hairObjectLeft = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectLeft = ownProps.hair.shape;
  } else if (hairConfigLeft.shape !== "0") {
    hairObjectLeft = hairSideListLeft[hairConfigLeft.shape].shape;
  }
  const coloursLeft = hairConfigLeft.colour
    ? hairConfigLeft.colour
    : hairSideColour;

  ////////////////////////
  // Right
  ////////////////////////
  const hairConfigRight = hairConfig.right;
  let hairObjectRight = null;
  if (ownProps.preview && ownProps.hair.shape) {
    hairObjectRight = ownProps.hair.shape;
  } else if (hairConfigRight !== "0") {
    hairObjectRight = hairSideListRight[hairConfigRight.shape].shape;
  }
  const coloursRight = hairConfigRight.colour
    ? hairConfigRight.colour
    : hairSideColour;

  return {
    left: {
      shape: hairObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hairObjectRight,
      colour: coloursRight,
    },
  };
}

const CharacterHairSide = (props) => {
  const { left, right, previewId } = props;

  if (left.shape || right.shape) {
    return (
      <g id={previewId ? `g-hair-side~${previewId}` : "g-hair-side"}>
        {left.shape && (
          <Component
            id={previewId ? `hair_side_left~${previewId}` : "hair_side_left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <Component
            id={previewId ? `hair_side_right~${previewId}` : "hair_side_right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairSide);
