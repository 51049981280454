import React from "react";
import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import BackHorns from "./BackHorns";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <BackHorns preview={true} previewId={previewId} horns={component} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};
