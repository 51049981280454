import React from "react";
import { viewbox, PreviewBase } from "../Preview";

const patch_shape_gl4ii = {
  name: "Patch 1 (both)",
  description:
    "A large, irregular-edged patch that goes from the centre of the top of the head, curving outwards slightly before coming back to just beyond where the jaw meets the neck",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M250.588 157.74c-2.55 1.83-2.21 5.95-.27 8.43 1.94 2.48 4.91 3.85 7.56 5.55 2.65 1.7 5.23 4.14 5.44 7.27.28 4.09-3.42 7.18-6.43 9.97-.98.91-1.98 1.96-2.16 3.29-.33 2.48 2.25 4.3 4.56 5.27 3.03 1.26 6.27 2.19 8.89 4.18 2.61 1.99 4.48 5.46 3.4 8.56-.56 1.6-1.84 2.95-1.98 4.64-.14 1.74.98 3.43.69 5.14-.52 3.09-4.6 3.62-7.65 4.36-7.05 1.71-8.937 12.977-11.84 16.15-1.414 1.545-4.76.69-5.58 2.89-.82 2.2-.29 4.67.48 6.89.78 2.22 1.8 4.4 1.99 6.74.47 5.87-4.26 10.72-8.63 14.68-3.55 3.22-7.46 6.6-12.23 7.01-3.13.27-6.19-.79-9.21-1.66-3.02-.87-6.27-1.57-9.25-.58-2.98.98-5.42 4.24-4.44 7.22.5 1.53 1.76 2.68 2.46 4.13 2.1 4.34-1.3 9.2-4.4 12.88-3.1 3.69-.847 10.675-3.68 12.95-2.834 2.275-9.134-.799-13.32.7-66.261 23.732-108.986-44.781-108.266-71.131.72-26.35 53.583-138.598 64.153-141.818 10.58-3.24 111.607-14.406 109.713 56.289Z" />
    ),
  },
};

const patch_shape_roe42 = {
  name: "Patch 1 (Right)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M316.514 246.808c-.328 8.518-10.94-.149-14.74 11.03-.455 1.337.27 2.78.52 4.17.61 3.42-1.03 6.92-3.45 9.4-2.42 2.48-5.56 4.13-8.64 5.73-2.63 1.37-5.41 2.77-8.37 2.67-2.96-.1-6.07-2.31-6.06-5.27.01-2.33 1.77-4.99.2-6.71-1.26-1.38-3.65-.76-5.22-1.78-2.51-1.64-.99-5.6 1.08-7.78 2.07-2.18 4.388-6.38 3.58-7.68-.809-1.3-5.57-.63-8.43-.12-2.86.51-6.83-.7-6.62-3.6.08-1.16.87-2.13 1.49-3.12 2.13-3.41 2.44-7.61 3.69-11.43 1.26-3.82 4.23-7.71 8.25-7.64 3.52.06 6.59 3.18 10.08 2.71 2.9-.39 5.07-3.18 7.98-3.51 3.1-.34 5.67 2.15 8.14 4.04 2.47 1.89 6.34 3.19 8.44.88 1.07-1.18 1.3-3.01 2.55-4.02 1.73-1.4 4.54-.26 5.56 1.71 1.02 1.97.7 4.36.11 6.5-.59 2.14-1.42 4.27-1.37 6.49.06 2.48 1.325 4.852 1.23 7.33Z" />
    ),
  },
};

const patch_shape_roe42_L = {
  name: "Patch 1 (Left)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M183.486 246.808c.328 8.518 10.94-.149 14.74 11.03.455 1.337-.27 2.78-.52 4.17-.61 3.42 1.03 6.92 3.45 9.4 2.42 2.48 5.56 4.13 8.64 5.73 2.63 1.37 5.41 2.77 8.37 2.67 2.96-.1 6.07-2.31 6.06-5.27-.01-2.33-1.77-4.99-.2-6.71 1.26-1.38 3.65-.76 5.22-1.78 2.51-1.64.99-5.6-1.08-7.78-2.07-2.18-4.388-6.38-3.58-7.68.809-1.3 5.57-.63 8.43-.12 2.86.51 6.83-.7 6.62-3.6-.08-1.16-.87-2.13-1.49-3.12-2.13-3.41-2.44-7.61-3.69-11.43-1.26-3.82-4.23-7.71-8.25-7.64-3.52.06-6.59 3.18-10.08 2.71-2.9-.39-5.07-3.18-7.98-3.51-3.1-.34-5.67 2.15-8.14 4.04-2.47 1.89-6.34 3.19-8.44.88-1.07-1.18-1.3-3.01-2.55-4.02-1.73-1.4-4.54-.26-5.56 1.71-1.02 1.97-.7 4.36-.11 6.5.59 2.14 1.42 4.27 1.37 6.49-.06 2.48-1.325 4.852-1.23 7.33Z" />
    ),
  },
};

const patch_shape_rl1i5 = {
  name: "Patch 1 (Both)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M255.5 157.74c-2.55 1.83-2.21 5.95-.27 8.43s4.91 3.85 7.56 5.55c2.65 1.7 5.23 4.14 5.44 7.27.28 4.09-3.42 7.18-6.43 9.97-.98.91-1.98 1.96-2.16 3.29-.33 2.48 2.25 4.3 4.56 5.27 3.03 1.26 6.27 2.19 8.89 4.18 2.61 1.99 4.48 5.46 3.4 8.56-.56 1.6-1.84 2.95-1.98 4.64-.14 1.74.98 3.43.69 5.14-.52 3.09-4.6 3.62-7.65 4.36-7.05 1.71-12.34 8.91-11.84 16.15-2.14-.97-4.76.69-5.58 2.89s-.29 4.67.48 6.89c.78 2.22 1.8 4.4 1.99 6.74.47 5.87-4.26 10.72-8.63 14.68-3.55 3.22-7.46 6.6-12.23 7.01-3.13.27-6.19-.79-9.21-1.66-3.02-.87-6.27-1.57-9.25-.58-2.98.98-5.42 4.24-4.44 7.22.5 1.53 1.76 2.68 2.46 4.13 2.1 4.34-1.3 9.2-4.4 12.88-3.1 3.69-6.23 8.87-3.68 12.95-4.44.23-8.88.47-13.32.7-18.73-18.55-29.35-45.01-28.63-71.36.72-26.35 12.76-52.2 32.48-69.7 8.27-7.34 17.9-13.29 28.47-16.51 10.58-3.24 22.13-3.65 33.28.91zm57.86 128.79c-.31 4.63-9.4 3.31-10.52 7.81-.34 1.37.27 2.78.52 4.17.61 3.42-1.03 6.92-3.45 9.4-2.42 2.48-5.56 4.13-8.64 5.73-2.63 1.37-5.41 2.77-8.37 2.67-2.96-.1-6.07-2.31-6.06-5.27.01-2.33 1.77-4.99.2-6.71-1.26-1.38-3.65-.76-5.22-1.78-2.51-1.64-.99-5.6 1.08-7.78 2.07-2.18 4.69-4.89 3.58-7.68-2.31-1.77-5.57-.63-8.43-.12-2.86.51-6.83-.7-6.62-3.6.08-1.16.87-2.13 1.49-3.12 2.13-3.41 2.44-7.61 3.69-11.43 1.26-3.82 4.23-7.71 8.25-7.64 3.52.06 6.59 3.18 10.08 2.71 2.9-.39 5.07-3.18 7.98-3.51 3.1-.34 5.67 2.15 8.14 4.04 2.47 1.89 6.34 3.19 8.44.88 1.07-1.18 1.3-3.01 2.55-4.02 1.73-1.4 4.54-.26 5.56 1.71 1.02 1.97.7 4.36.11 6.5s-1.42 4.27-1.37 6.49c.06 2.48 1.23 4.85 1.23 7.33.01 2.51-2.08 5.35-4.22 3.22z" />
    ),
  },
};

const patch_shape_eo239 = {
  name: "Patch 2 (Left)",
  description:
    "An almost triangular, irregular-edged patch that starts at the mid-left temple, angling towards the inner corner of the eye and then out to just past where the jaw meets the neck",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M184.599 315.78c3.161.027 8.637 1.422 10.77-1.51 2.468-3.393-1.05-14.49 4.04-18.85 3.41-2.92 8.615-2.16 10.306-9.507.439-1.909-5.086-7.703-4.646-9.763.85-4.02 2.01-8.5 5.54-10.62 1.83-1.1 4.03-1.37 6.12-1.82 2.09-.45 4.28-1.19 5.56-2.9 1.28-1.71 1.11-4.62-.81-5.55.73-3.9-4.54-7.12-3.67-11 .46-2.08 2.56-3.37 4.58-4.02 2.03-.65 4.22-.94 5.99-2.12 1.77-1.18 2.233-4.128 1.7-5.47-.533-1.342-4.24-.47-4.9-2.58-.66-2.11-.6-4.62-2.2-6.15-2.28-2.19-6.94-1.29-8.19-4.19-1.38-3.21 3.38-7.28 1.09-9.92-1.66-1.92-4.68-.35-7.13.31-5.35 1.44-11.35-3.57-10.9-9.09.18-2.14 1.15-4.21.95-6.35-.2-2.14-3.633-1.897-4.34-3.2-.707-1.303 1.364-3.74.1-4.62-2.327-1.62-11.164-5.362-14.06-5.1-122.169 11.067-128.037 132.897 4.1 134.02Z" />
    ),
  },
};

const patch_shape_fsje4 = {
  name: "Patch 2 (Right)",
  description:
    "An almost triangular, irregular-edged patch that starts at the mid-right temple, angling towards the inner corner of the eye and then out to just past where the jaw meets the neck",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M315.401 315.78c-3.161.027-8.637 1.422-10.77-1.51-2.468-3.393 1.05-14.49-4.04-18.85-3.41-2.92-8.615-2.16-10.306-9.507-.439-1.909 5.086-7.703 4.646-9.763-.85-4.02-2.01-8.5-5.54-10.62-1.83-1.1-4.03-1.37-6.12-1.82-2.09-.45-4.28-1.19-5.56-2.9-1.28-1.71-1.11-4.62.81-5.55-.73-3.9 4.54-7.12 3.67-11-.46-2.08-2.56-3.37-4.58-4.02-2.03-.65-4.22-.94-5.99-2.12-1.77-1.18-2.233-4.128-1.7-5.47.533-1.342 4.24-.47 4.9-2.58.66-2.11.6-4.62 2.2-6.15 2.28-2.19 6.94-1.29 8.19-4.19 1.38-3.21-3.38-7.28-1.09-9.92 1.66-1.92 4.68-.35 7.13.31 5.35 1.44 11.35-3.57 10.9-9.09-.18-2.14-1.15-4.21-.95-6.35.2-2.14 3.633-1.897 4.34-3.2.707-1.303-1.364-3.74-.1-4.62 2.327-1.62 11.164-5.362 14.06-5.1 122.169 11.067 128.037 132.897-4.1 134.02Z" />
    ),
  },
};

const patch_shape_r9295 = {
  name: "Patch 2 (Both)",
  description:
    "Symmetrical, almost triangular, irregular-edged patches that start at the mid-temple, angling towards the inner corner of the eyes and then out to just past where the jaw meets the neck",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M184.45 315.78c2.92 1.21 7.85-2.71 10.77-1.51-.74-6.66-1.05-14.49 4.04-18.85 3.41-2.92 9.17-4.4 9.4-8.88-2.09-.15-3.7-2.1-4.16-4.15-.45-2.05-.02-4.18.42-6.24.85-4.02 2.01-8.5 5.54-10.62 1.83-1.1 4.03-1.37 6.12-1.82 2.09-.45 4.28-1.19 5.56-2.9s1.11-4.62-.81-5.55c.73-3.9-4.54-7.12-3.67-11 .46-2.08 2.56-3.37 4.58-4.02 2.03-.65 4.22-.94 5.99-2.12s2.92-3.72 1.7-5.47c-1.61 1.51-4.24-.47-4.9-2.58-.66-2.11-.6-4.62-2.2-6.15-2.28-2.19-6.94-1.29-8.19-4.19-1.38-3.21 3.38-7.28 1.09-9.92-1.66-1.92-4.68-.35-7.13.31-5.35 1.44-11.35-3.57-10.9-9.09.18-2.14 1.15-4.21.95-6.35-.2-2.14-2.5-4.3-4.34-3.2.03-1.54.07-3.08.1-4.62-2.57-1.37-12.08-7.23-14.06-5.1-8.49 9.14-15.69 28.43-16.6 40.86-.91 12.44 1.99 24.63 1.84 37.1-.12 10.06.25 20.3 3.39 29.86 3.15 9.57 7.64 22.97 15.47 26.2zm131.1 0c-2.92 1.21-7.85-2.71-10.77-1.51.74-6.66 1.05-14.49-4.04-18.85-3.41-2.92-9.17-4.4-9.4-8.88 2.09-.15 3.7-2.1 4.16-4.15.45-2.05.02-4.18-.42-6.24-.85-4.02-2.01-8.5-5.54-10.62-1.83-1.1-4.03-1.37-6.12-1.82-2.09-.45-4.28-1.19-5.56-2.9-1.28-1.71-1.11-4.62.81-5.55-.73-3.9 4.54-7.12 3.67-11-.46-2.08-2.56-3.37-4.58-4.02-2.03-.65-4.22-.94-5.99-2.12-1.77-1.18-2.92-3.72-1.7-5.47 1.61 1.51 4.24-.47 4.9-2.58.66-2.11.6-4.62 2.2-6.15 2.28-2.19 6.94-1.29 8.19-4.19 1.38-3.21-3.38-7.28-1.09-9.92 1.66-1.92 4.68-.35 7.13.31 5.35 1.44 11.35-3.57 10.9-9.09-.18-2.14-1.15-4.21-.95-6.35.2-2.14 2.5-4.3 4.34-3.2-.03-1.54-.07-3.08-.1-4.62 2.57-1.37 12.08-7.23 14.06-5.1 8.49 9.14 15.69 28.43 16.6 40.86.91 12.44-1.99 24.63-1.84 37.1.12 10.06-.25 20.3-3.39 29.86-3.15 9.57-7.64 22.97-15.47 26.2z" />
    ),
  },
};

const patch_shape_eo2e1 = {
  name: "Patch 3",
  description:
    "A central triangular, semi-irregular patch that starts where the neck meets the jaw and tapers towards the centre of the face",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M297.15 324.51c-.22-2.25-2.54-4.38-4.65-3.57-3.52-4.56-7.24-10.24-5.26-15.65.46-1.25 1.22-2.43 1.3-3.76.12-1.95-1.29-3.7-2.92-4.78-1.63-1.09-3.52-1.72-5.18-2.75-6.5-4.05-7.78-12.8-11.64-19.42-3.5-6.01-9.18-10.27-15.09-14.06-.92-1.99-2.12-3.31-3.58-2.41-.05.03-.09.06-.14.08-.05-.03-.09-.06-.14-.08-1.46-.9-2.66.42-3.58 2.41-5.91 3.79-11.59 8.06-15.09 14.06-3.85 6.62-5.13 15.37-11.64 19.42-1.66 1.04-3.55 1.67-5.18 2.75-1.63 1.09-3.03 2.83-2.92 4.78.08 1.33.84 2.51 1.3 3.76 1.99 5.41-1.73 11.09-5.26 15.65-2.11-.81-4.43 1.32-4.65 3.57-.22 2.25-2.52 12.88-1.27 14.76 9.54 14.27 29.16 15.83 46.31 16.65.69.03 1.39.04 2.1.03.71.01 1.41.01 2.1-.03 17.15-.82 36.77-2.38 46.31-16.65 1.29-1.88-1.01-12.5-1.23-14.76z" />
    ),
  },
};

const patch_shape_vnamq = {
  name: "Patch 4 (Left)",
  description:
    "A somewhat smooth-edged patch that comes from just above the temple, covers most of the eye area then curves slightly upwards towards the outer cheek",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M158.39 242.25c-1.86 5.24 12.68 11.15 17.63 13.67s10.73 3.6 14.95 7.23c1.91 1.64 3.43 3.76 5.63 4.98 3.07 1.71 6.83 1.36 10.33.95 7.87-.91 15.75-1.83 23.62-2.74 2.44-3.24 5.01-7.04 4.18-11.01-.44-2.1 4.85-6.5 5.43-8.57 3.21-11.49-3.53-15.55-5.94-24.45-2.3-8.52-12.74-12.32-16.5-20.31-2.61-5.56-1.54-12.24-3.79-17.96-1.92-4.89-6.03-8.52-10-11.96-3.91-3.38-8.5-6.99-13.62-6.31-4.03.53-7.21 3.63-9.83 6.74-16.62 19.68-33.56 46.65-22.09 69.74z" />
    ),
  },
};

const patch_shape_dalqp = {
  name: "Patch 4 (Right)",
  description:
    "A somewhat smooth-edged patch that comes from just above the temple, covers most of the eye area then curves slightly upwards towards the outer cheek",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M341.61 242.25c1.86 5.24-12.68 11.15-17.63 13.67s-10.73 3.6-14.95 7.23c-1.91 1.64-3.43 3.76-5.63 4.98-3.07 1.71-6.83 1.36-10.33.95-7.87-.91-15.75-1.83-23.62-2.74-2.44-3.24-5.01-7.04-4.18-11.01.44-2.1-4.85-6.5-5.43-8.57-3.21-11.49 3.53-15.55 5.94-24.45 2.3-8.52 12.74-12.32 16.5-20.31 2.61-5.56 1.54-12.24 3.79-17.96 1.92-4.89 6.03-8.52 10-11.96 3.91-3.38 8.5-6.99 13.62-6.31 4.03.53 7.21 3.63 9.83 6.74 16.62 19.68 33.56 46.65 22.09 69.74z" />
    ),
  },
};

const patch_shape_daqwe = {
  name: "Patch 4 (Both)",
  description:
    "Somewhat smooth-edged patches that come from just above the temple, cover most of the eye area then curves slightly upwards towards the outer cheeks",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M158.39 242.25c-1.86 5.24 12.68 11.15 17.63 13.67s10.73 3.6 14.95 7.23c1.91 1.64 3.43 3.76 5.63 4.98 3.07 1.71 6.83 1.36 10.33.95 7.87-.91 15.75-1.83 23.62-2.74 2.44-3.24 5.01-7.04 4.18-11.01-.44-2.1 4.85-6.5 5.43-8.57 3.21-11.49-3.53-15.55-5.94-24.45-2.3-8.52-12.74-12.32-16.5-20.31-2.61-5.56-1.54-12.24-3.79-17.96-1.92-4.89-6.03-8.52-10-11.96-3.91-3.38-8.5-6.99-13.62-6.31-4.03.53-7.21 3.63-9.83 6.74-16.62 19.68-33.56 46.65-22.09 69.74zm183.22 0c1.86 5.24-12.68 11.15-17.63 13.67s-10.73 3.6-14.95 7.23c-1.91 1.64-3.43 3.76-5.63 4.98-3.07 1.71-6.83 1.36-10.33.95-7.87-.91-15.75-1.83-23.62-2.74-2.44-3.24-5.01-7.04-4.18-11.01.44-2.1-4.85-6.5-5.43-8.57-3.21-11.49 3.53-15.55 5.94-24.45 2.3-8.52 12.74-12.32 16.5-20.31 2.61-5.56 1.54-12.24 3.79-17.96 1.92-4.89 6.03-8.52 10-11.96 3.91-3.38 8.5-6.99 13.62-6.31 4.03.53 7.21 3.63 9.83 6.74 16.62 19.68 33.56 46.65 22.09 69.74z" />
    ),
  },
};

const patch_shape_deqpo = {
  name: "Patch 5 (Left)",
  description:
    "A smooth, almost rectangular apostrophe shape that starts just above the outer eye, sweeping downwards over the eye and cheek and towards the outer corner of the mouth",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M187.53 245.12c-3.63 17.42-1.64 35.97 5.61 52.22 2.53 5.68 6.2 11.52 12.11 13.45 2.48.81 5.81.49 6.9-1.89.8-1.76 0-3.79-.38-5.69-1.22-6.08 2.08-12.06 5.25-17.39 4.2-7.06 8.41-14.11 12.61-21.17 2.96-4.96 5.95-10 7.57-15.54.91-3.1 1.37-6.33 1.37-9.57 0-3.29-.49-6.65-1.99-9.59-1.91-3.75-5.27-6.52-8.54-9.17-3.71-3.01-7.43-6.02-11.14-9.03-3.56-2.89-7.92-5.96-12.33-4.71-2.77.79-4.83 3.18-6.07 5.77-1.25 2.6-1.84 5.45-2.73 8.19-1.78 5.46-4.75 10.46-6.72 15.86-1.98 5.42-2.86 11.61-1.52 8.26z" />
    ),
  },
};

const patch_shape_fajem = {
  name: "Patch 5 (Right)",
  description:
    "A smooth, almost rectangular apostrophe shape that starts just above the outer eye, sweeping downwards over the eye and cheek and towards the outer corner of the mouth",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M312.34 245.12c3.63 17.42 1.64 35.97-5.61 52.22-2.53 5.68-6.2 11.52-12.11 13.45-2.48.81-5.81.49-6.9-1.89-.8-1.76 0-3.79.38-5.69 1.22-6.08-2.08-12.06-5.25-17.39-4.2-7.06-8.41-14.11-12.61-21.17-2.96-4.96-5.95-10-7.57-15.54-.91-3.1-1.37-6.33-1.37-9.57 0-3.29.49-6.65 1.99-9.59 1.91-3.75 5.27-6.52 8.54-9.17 3.71-3.01 7.43-6.02 11.14-9.03 3.56-2.89 7.92-5.96 12.33-4.71 2.77.79 4.83 3.18 6.07 5.77s1.84 5.45 2.73 8.19c1.78 5.46 4.75 10.46 6.72 15.86 1.98 5.42 2.87 11.61 1.52 8.26z" />
    ),
  },
};

const patch_shape_xrvtb = {
  name: "Patch 5 (Both)",
  description:
    "Smooth, almost rectangular apostrophe shapes that start just above the outer eye, sweeping downwards over the eyes and cheeks and towards the outer corners of the mouth",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M187.53 245.12c-3.63 17.42-1.64 35.97 5.61 52.22 2.53 5.68 6.2 11.52 12.11 13.45 2.48.81 5.81.49 6.9-1.89.8-1.76 0-3.79-.38-5.69-1.22-6.08 2.08-12.06 5.25-17.39 4.2-7.06 8.41-14.11 12.61-21.17 2.96-4.96 5.95-10 7.57-15.54.91-3.1 1.37-6.33 1.37-9.57 0-3.29-.49-6.65-1.99-9.59-1.91-3.75-5.27-6.52-8.54-9.17-3.71-3.01-7.43-6.02-11.14-9.03-3.56-2.89-7.92-5.96-12.33-4.71-2.77.79-4.83 3.18-6.07 5.77-1.25 2.6-1.84 5.45-2.73 8.19-1.78 5.46-4.75 10.46-6.72 15.86-1.98 5.42-2.86 11.61-1.52 8.26zm124.81 0c3.63 17.42 1.64 35.97-5.61 52.22-2.53 5.68-6.2 11.52-12.11 13.45-2.48.81-5.81.49-6.9-1.89-.8-1.76 0-3.79.38-5.69 1.22-6.08-2.08-12.06-5.25-17.39-4.2-7.06-8.41-14.11-12.61-21.17-2.96-4.96-5.95-10-7.57-15.54-.91-3.1-1.37-6.33-1.37-9.57 0-3.29.49-6.65 1.99-9.59 1.91-3.75 5.27-6.52 8.54-9.17 3.71-3.01 7.43-6.02 11.14-9.03 3.56-2.89 7.92-5.96 12.33-4.71 2.77.79 4.83 3.18 6.07 5.77s1.84 5.45 2.73 8.19c1.78 5.46 4.75 10.46 6.72 15.86 1.98 5.42 2.87 11.61 1.52 8.26z" />
    ),
  },
};

const patch_shape_awwsd = {
  name: "Patch 6 (Left)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M165.42 260.39c1.53-6.48 3.52-13.62 9.19-17.12 2.14-1.32 4.7-2.03 6.45-3.83 3.37-3.46 2.63-9.76 6.4-12.78 3.25-2.61 8.62-1.66 11.34-4.81 1.27-1.48 1.63-3.53 2.64-5.2 1.89-3.14 5.87-4.51 9.53-4.3 3.66.21 7.09 1.73 10.44 3.22 4.72 2.11 9.65 4.37 12.79 8.48 3.39 4.44 4.14 10.29 4.77 15.84.62 5.4 1.06 11.46-2.37 15.68-1.33 1.63-3.12 2.81-4.88 3.96-2.02 1.32-4.05 2.65-6.07 3.97-1.37.89-2.82 1.89-3.37 3.43-1.13 3.14 2.05 6.21 2.25 9.53.24 3.96-3.61 6.82-7.11 8.68-4.63 2.46-9.55 4.63-13.25 8.34-4.95 4.97-7.07 12.03-9 18.78-.6 2.09-1.2 4.19-1.8 6.28-.39 1.35-.78 2.72-1.55 3.9-2.67 4.09-8.61 4.3-13.34 3.12-13.66-3.42-24.81-15.3-27.37-29.14-2.58-13.85 3.6-28.93 14.31-36.03z" />
    ),
  },
};

const patch_shape_qepqo = {
  name: "Patch 6 (Right)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M334.58 260.39c-1.53-6.48-3.52-13.62-9.19-17.12-2.14-1.32-4.7-2.03-6.45-3.83-3.37-3.46-2.63-9.76-6.4-12.78-3.25-2.61-8.62-1.66-11.34-4.81-1.27-1.48-1.63-3.53-2.64-5.2-1.89-3.14-5.87-4.51-9.53-4.3-3.66.21-7.09 1.73-10.44 3.22-4.72 2.11-9.65 4.37-12.79 8.48-3.39 4.44-4.14 10.29-4.77 15.84-.62 5.4-1.06 11.46 2.37 15.68 1.33 1.63 3.12 2.81 4.88 3.96 2.02 1.32 4.05 2.65 6.07 3.97 1.37.89 2.82 1.89 3.37 3.43 1.13 3.14-2.05 6.21-2.25 9.53-.24 3.96 3.61 6.82 7.11 8.68 4.63 2.46 9.55 4.63 13.25 8.34 4.95 4.97 7.07 12.03 9 18.78.6 2.09 1.2 4.19 1.8 6.28.39 1.35.78 2.72 1.55 3.9 2.67 4.09 8.61 4.3 13.34 3.12 13.66-3.42 24.81-15.3 27.37-29.14 2.58-13.85-3.6-28.93-14.31-36.03z" />
    ),
  },
};

const patch_shape_qwlek = {
  name: "Patch 6 (Both)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M165.42 260.39c1.53-6.48 3.52-13.62 9.19-17.12 2.14-1.32 4.7-2.03 6.45-3.83 3.37-3.46 2.63-9.76 6.4-12.78 3.25-2.61 8.62-1.66 11.34-4.81 1.27-1.48 1.63-3.53 2.64-5.2 1.89-3.14 5.87-4.51 9.53-4.3 3.66.21 7.09 1.73 10.44 3.22 4.72 2.11 9.65 4.37 12.79 8.48 3.39 4.44 4.14 10.29 4.77 15.84.62 5.4 1.06 11.46-2.37 15.68-1.33 1.63-3.12 2.81-4.88 3.96-2.02 1.32-4.05 2.65-6.07 3.97-1.37.89-2.82 1.89-3.37 3.43-1.13 3.14 2.05 6.21 2.25 9.53.24 3.96-3.61 6.82-7.11 8.68-4.63 2.46-9.55 4.63-13.25 8.34-4.95 4.97-7.07 12.03-9 18.78-.6 2.09-1.2 4.19-1.8 6.28-.39 1.35-.78 2.72-1.55 3.9-2.67 4.09-8.61 4.3-13.34 3.12-13.66-3.42-24.81-15.3-27.37-29.14-2.58-13.85 3.6-28.93 14.31-36.03zm169.16 0c-1.53-6.48-3.52-13.62-9.19-17.12-2.14-1.32-4.7-2.03-6.45-3.83-3.37-3.46-2.63-9.76-6.4-12.78-3.25-2.61-8.62-1.66-11.34-4.81-1.27-1.48-1.63-3.53-2.64-5.2-1.89-3.14-5.87-4.51-9.53-4.3-3.66.21-7.09 1.73-10.44 3.22-4.72 2.11-9.65 4.37-12.79 8.48-3.39 4.44-4.14 10.29-4.77 15.84-.62 5.4-1.06 11.46 2.37 15.68 1.33 1.63 3.12 2.81 4.88 3.96 2.02 1.32 4.05 2.65 6.07 3.97 1.37.89 2.82 1.89 3.37 3.43 1.13 3.14-2.05 6.21-2.25 9.53-.24 3.96 3.61 6.82 7.11 8.68 4.63 2.46 9.55 4.63 13.25 8.34 4.95 4.97 7.07 12.03 9 18.78.6 2.09 1.2 4.19 1.8 6.28.39 1.35.78 2.72 1.55 3.9 2.67 4.09 8.61 4.3 13.34 3.12 13.66-3.42 24.81-15.3 27.37-29.14 2.58-13.85-3.6-28.93-14.31-36.03z" />
    ),
  },
};

const patch_shape_dawio = {
  name: "Patch 7",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M320.21 232.4c-.91-.83-1.96-1.52-2.68-2.52-.72-1-.99-2.47-.22-3.43 1.07-1.33 3.84-1.59 3.52-3.26-.14-.74-.92-1.16-1.6-1.47-8.04-3.67-16.45-7.42-25.29-7.13-4.03.13-8.2 1.09-12.02-.22-1.34-.46-2.61-1.2-4.02-1.32-2.48-.2-4.63 1.54-6.64 2.99-4.05 2.92-13.86 3.6-21.25 8.38-7.38-4.77-17.2-5.46-21.25-8.38-2.02-1.45-4.17-3.19-6.64-2.99-1.41.11-2.68.85-4.02 1.32-3.81 1.32-7.98.36-12.02.22-8.84-.29-17.24 3.46-25.29 7.13-.69.31-1.46.73-1.6 1.47-.32 1.67 2.45 1.93 3.52 3.26.77.96.49 2.43-.22 3.43-.72 1-1.77 1.69-2.68 2.52-3.88 3.57-4.55 10.11-1.47 14.38 1.23 1.7 2.95 3.07 3.84 4.97 1.43 3.05.37 6.6.35 9.97s2.12 7.46 5.45 6.99c2.29-.32 3.94-2.75 6.25-2.84 3.64-.13 4.97 5.17 8.37 6.5 2.8 1.09 5.74-.87 8.34-2.38 5.63-3.29 12.11-4.75 18.48-6.16 6.37-1.41 12.85-2.86 18.49-6.13.7-.41 1.41-.85 2.1-1.32.69.47 1.4.91 2.1 1.32 5.64 3.27 12.13 4.72 18.49 6.13 6.37 1.41 12.85 2.88 18.48 6.16 2.59 1.51 5.54 3.47 8.34 2.38 3.39-1.32 4.73-6.63 8.37-6.5 2.31.09 3.96 2.52 6.25 2.84 3.33.46 5.47-3.63 5.45-6.99s-1.07-6.92.35-9.97c.89-1.9 2.62-3.26 3.84-4.97 3.07-4.28 2.41-10.82-1.47-14.38z" />
    ),
  },
};

const patch_shape_pwqem = {
  name: "Patch 8",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M318.75 308.42c-.16-5.24-1.32-11.52-6.18-13.48-3.14-1.26-7.25-.37-9.57-2.84-2.41-2.57-1.15-7.07-3.27-9.88-1.73-2.29-5.02-2.6-7.88-2.39-2.86.21-5.9.71-8.46-.59-1.95-1-3.84-3.04-5.91-2.31-1.13.4-1.89 1.56-3.05 1.87-1.94.51-3.49-1.51-5.2-2.56-3.51-2.18-8.05-.2-11.65 1.84-2 1.13-4.25 2.29-6.48 2.76-.38.08-.75.2-1.1.33-.36-.14-.72-.25-1.1-.33-2.23-.47-4.48-1.63-6.48-2.76-3.6-2.04-8.14-4.02-11.65-1.84-1.7 1.06-3.26 3.08-5.2 2.56-1.16-.31-1.92-1.47-3.05-1.87-2.07-.72-3.95 1.32-5.91 2.31-2.55 1.3-5.6.8-8.46.59-2.86-.21-6.15.11-7.88 2.39-2.12 2.81-.86 7.31-3.27 9.88-2.32 2.47-6.43 1.57-9.57 2.84-4.87 1.96-6.02 8.24-6.18 13.48-.18 5.9-.01 11.81.51 17.69.49 5.52 1.44 11.37 5.14 15.5 3.26 3.63 8.12 5.32 12.8 6.7 15.53 4.56 31.61 7.19 47.78 7.87.01.06.02.11.03.17.83-.01 1.66-.06 2.49-.08.83.02 1.66.07 2.49.08.01-.06.02-.11.03-.17 16.17-.68 32.25-3.31 47.78-7.87 4.68-1.38 9.54-3.06 12.8-6.7 3.7-4.13 4.65-9.98 5.14-15.5.52-5.88.69-11.79.51-17.69z" />
    ),
  },
};

export const patchPack1 = {
  patch_shape_gl4ii,
  patch_shape_roe42,
  patch_shape_roe42_L,
  patch_shape_rl1i5,
  patch_shape_eo239,
  patch_shape_fsje4,
  patch_shape_r9295,
  patch_shape_eo2e1,
  patch_shape_vnamq,
  patch_shape_dalqp,
  patch_shape_daqwe,
  patch_shape_deqpo,
  patch_shape_fajem,
  patch_shape_xrvtb,
  patch_shape_awwsd,
  patch_shape_qepqo,
  patch_shape_qwlek,
  patch_shape_dawio,
  patch_shape_pwqem,
};
