import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterHairBase from "./CharacterHairBase";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <CharacterHairBase
        preview={true}
        previewId={previewId}
        hair={component}
      />
    </>
  );
};

export const viewbox = "150 160 200 180";
