import React from "react";
import { viewbox, PreviewBase } from "../Preview";

// All optimised

const tattoo_QTphVy9jzJ = {
  name: "Geometric 1",
  description:
    "Three circles above the eye which get bigger towards the centre of the face",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M185.655 219.992c0-1.488-1.208-2.696-2.695-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.487 0 2.695-1.208 2.695-2.696Zm12.301-7.015c0-2.384-1.935-4.319-4.319-4.319s-4.319 1.935-4.319 4.319 1.935 4.319 4.319 4.319 4.319-1.935 4.319-4.319Zm15.989-4.319c0-3.256-2.643-5.9-5.899-5.9-3.256 0-5.899 2.644-5.899 5.9 0 3.256 2.643 5.899 5.899 5.899 3.256 0 5.899-2.643 5.899-5.899Z" />
      ),
    },
    right: {
      base: (
        <path d="M314.345 219.992c0-1.488 1.208-2.696 2.695-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.487 0-2.695-1.208-2.695-2.696Zm-12.301-7.015c0-2.384 1.935-4.319 4.319-4.319s4.319 1.935 4.319 4.319-1.935 4.319-4.319 4.319-4.319-1.935-4.319-4.319Zm-15.989-4.319c0-3.256 2.643-5.9 5.899-5.9 3.256 0 5.899 2.644 5.899 5.9 0 3.256-2.643 5.899-5.899 5.899-3.256 0-5.899-2.643-5.899-5.899Z" />
      ),
    },
  },
};

const tattoo_HdwpAbHK2v = {
  name: "Geometric 2",
  description:
    "Three circles above the eye which get smaller towards the centre of the face",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M201.858 208.658c0-3.256 2.643-5.9 5.899-5.9 3.256 0 5.899 2.644 5.899 5.9 0 3.256-2.643 5.899-5.899 5.899-3.256 0-5.899-2.643-5.899-5.899Zm15.989 4.319c0-2.384 1.935-4.319 4.319-4.319s4.319 1.935 4.319 4.319-1.935 4.319-4.319 4.319-4.319-1.935-4.319-4.319Zm12.301 7.015c0-1.488 1.208-2.696 2.695-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.487 0-2.695-1.208-2.695-2.696Z" />
      ),
    },
    right: {
      base: (
        <path d="M298.142 208.658c0-3.256-2.643-5.9-5.899-5.9-3.256 0-5.899 2.644-5.899 5.9 0 3.256 2.643 5.899 5.899 5.899 3.256 0 5.899-2.643 5.899-5.899Zm-15.989 4.319c0-2.384-1.935-4.319-4.319-4.319s-4.319 1.935-4.319 4.319 1.935 4.319 4.319 4.319 4.319-1.935 4.319-4.319Zm-12.301 7.015c0-1.488-1.208-2.696-2.695-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.487 0 2.695-1.208 2.695-2.696Z" />
      ),
    },
  },
};

const tattoo_S9uJL7SpZ5 = {
  name: "Geometric 3",
  description:
    "Five circles above the eye which get larger towards the centre of the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M230.176 219.992c0-1.488 1.208-2.696 2.696-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.488 0-2.696-1.208-2.696-2.696Zm-12.301-7.015c0-2.384 1.935-4.319 4.319-4.319s4.319 1.935 4.319 4.319-1.935 4.319-4.319 4.319-4.319-1.935-4.319-4.319Zm-10.08 1.58h-.009c-3.256 0-5.9-2.643-5.9-5.899 0-3.256 2.644-5.9 5.9-5.9h.009c3.256 0 5.899 2.644 5.899 5.9 0 3.256-2.643 5.899-5.899 5.899Zm-10.089-1.58c0-2.384-1.936-4.319-4.319-4.319-2.384 0-4.32 1.935-4.32 4.319s1.936 4.319 4.32 4.319c2.383 0 4.319-1.935 4.319-4.319Zm-12.301 7.015c0-1.488-1.208-2.696-2.696-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.488 0 2.696-1.208 2.696-2.696Z" />
      ),
    },
    right: {
      base: (
        <path d="M314.595 219.992c0-1.488 1.208-2.696 2.696-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.488 0-2.696-1.208-2.696-2.696Zm-12.301-7.015c0-2.384 1.936-4.319 4.319-4.319 2.384 0 4.32 1.935 4.32 4.319s-1.936 4.319-4.32 4.319c-2.383 0-4.319-1.935-4.319-4.319Zm-10.08 1.58h-.009c-3.256 0-5.899-2.643-5.899-5.899 0-3.256 2.643-5.9 5.899-5.9h.009c3.256 0 5.9 2.644 5.9 5.9 0 3.256-2.644 5.899-5.9 5.899Zm-10.089-1.58c0-2.384-1.935-4.319-4.319-4.319s-4.319 1.935-4.319 4.319 1.935 4.319 4.319 4.319 4.319-1.935 4.319-4.319Zm-12.301 7.015c0-1.488-1.208-2.696-2.696-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.488 0 2.696-1.208 2.696-2.696Z" />
      ),
    },
  },
};

const tattoo_7xAjw5Tvhu = {
  name: "Geometric 4",
  description: "Six small circles that curve around the outer of the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M178.506 239.754c0-1.488-1.208-2.696-2.696-2.696-1.487 0-2.695 1.208-2.695 2.696 0 1.488 1.208 2.696 2.695 2.696 1.488 0 2.696-1.208 2.696-2.696Zm.597 10.769c0-1.488-1.208-2.696-2.696-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.488 0 2.696-1.208 2.696-2.696Zm2.099-21.21c0-1.488-1.208-2.696-2.696-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.488 0 2.696-1.208 2.696-2.696Zm1.708 30.537c0-1.488-1.208-2.696-2.695-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.487 0 2.695-1.208 2.695-2.696Zm3.684-39.858c0-1.488-1.208-2.696-2.696-2.696-1.488 0-2.696 1.208-2.696 2.696 0 1.488 1.208 2.696 2.696 2.696 1.488 0 2.696-1.208 2.696-2.696Zm7.328-6.761c0-1.488-1.208-2.696-2.696-2.696-1.487 0-2.695 1.208-2.695 2.696 0 1.487 1.208 2.695 2.695 2.695 1.488 0 2.696-1.208 2.696-2.695Z" />
      ),
    },
    right: {
      base: (
        <path d="M321.494 239.754c0-1.488 1.208-2.696 2.696-2.696 1.487 0 2.695 1.208 2.695 2.696 0 1.488-1.208 2.696-2.695 2.696-1.488 0-2.696-1.208-2.696-2.696Zm-.597 10.769c0-1.488 1.208-2.696 2.696-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.488 0-2.696-1.208-2.696-2.696Zm-2.099-21.21c0-1.488 1.208-2.696 2.696-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.488 0-2.696-1.208-2.696-2.696Zm-1.708 30.537c0-1.488 1.208-2.696 2.695-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.487 0-2.695-1.208-2.695-2.696Zm-3.684-39.858c0-1.488 1.208-2.696 2.696-2.696 1.488 0 2.696 1.208 2.696 2.696 0 1.488-1.208 2.696-2.696 2.696-1.488 0-2.696-1.208-2.696-2.696Zm-7.328-6.761c0-1.488 1.208-2.696 2.696-2.696 1.487 0 2.695 1.208 2.695 2.696 0 1.487-1.208 2.695-2.695 2.695-1.488 0-2.696-1.208-2.696-2.695Z" />
      ),
    },
  },
};

const tattoo_qMPvZepVYT = {
  name: "Geometric 5",
  description:
    "Two equilateral triangles, one on the outside and one underneath the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M213.316 281.534c-.187.27-.494.431-.822.431-.328 0-.635-.161-.822-.431-1.539-2.223-4.855-7.011-6.576-9.497-.212-.306-.236-.704-.064-1.033.173-.33.514-.536.886-.536h13.227c.358 0 .685.198.851.515.166.316.142.698-.061.992-1.708 2.467-5.067 7.318-6.619 9.559Zm-40.094-35.447c-.27-.186-.431-.494-.431-.822 0-.328.161-.635.431-.822 2.223-1.539 7.011-4.855 9.497-6.576.306-.212.704-.236 1.033-.063.33.172.536.513.536.885v13.228c0 .357-.198.685-.514.85-.317.166-.699.143-.993-.061-2.467-1.708-7.317-5.067-9.559-6.619Z" />
      ),
    },
    right: {
      base: (
        <path d="M286.684 281.534c.187.27.494.431.822.431.328 0 .635-.161.822-.431 1.539-2.223 4.855-7.011 6.576-9.497.212-.306.236-.704.064-1.033-.173-.33-.514-.536-.886-.536h-13.227c-.358 0-.685.198-.851.515-.166.316-.142.698.061.992 1.708 2.467 5.067 7.318 6.619 9.559Zm40.094-35.447c.27-.186.431-.494.431-.822 0-.328-.161-.635-.431-.822-2.223-1.539-7.011-4.855-9.497-6.576-.306-.212-.704-.236-1.033-.063-.33.172-.536.513-.536.885v13.228c0 .357.198.685.514.85.317.166.699.143.993-.061 2.467-1.708 7.317-5.067 9.559-6.619Z" />
      ),
    },
  },
};

const tattoo_b3ShzmN3Qv = {
  name: "Geometric 6",
  description:
    "Two equilateral triangles, one above and one underneath the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M213.316 281.534c-.187.27-.494.431-.822.431-.328 0-.635-.161-.822-.431-1.539-2.223-4.855-7.011-6.576-9.497-.212-.306-.236-.704-.064-1.033.173-.33.514-.536.886-.536h13.227c.358 0 .685.198.851.515.166.316.142.698-.061.992-1.708 2.467-5.067 7.318-6.619 9.559Zm0-72.891c-.187-.27-.494-.431-.822-.431-.328 0-.635.161-.822.431-1.539 2.223-4.855 7.011-6.576 9.497-.212.306-.236.704-.064 1.033.173.33.514.536.886.536h13.227c.358 0 .685-.198.851-.515.166-.316.142-.698-.061-.992-1.708-2.467-5.067-7.318-6.619-9.559Z" />
      ),
    },
    right: {
      base: (
        <path d="M286.684 281.534c.187.27.494.431.822.431.328 0 .635-.161.822-.431 1.539-2.223 4.855-7.011 6.576-9.497.212-.306.236-.704.064-1.033-.173-.33-.514-.536-.886-.536h-13.227c-.358 0-.685.198-.851.515-.166.316-.142.698.061.992 1.708 2.467 5.067 7.318 6.619 9.559Zm0-72.891c.187-.27.494-.431.822-.431.328 0 .635.161.822.431 1.539 2.223 4.855 7.011 6.576 9.497.212.306.236.704.064 1.033-.173.33-.514.536-.886.536h-13.227c-.358 0-.685-.198-.851-.515-.166-.316-.142-.698.061-.992 1.708-2.467 5.067-7.318 6.619-9.559Z" />
      ),
    },
  },
};

const tattoo_FwpM9nFpYG = {
  name: "Geometric 7",
  description:
    "Two equilateral triangles, one above and one on the outside the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M173.222 246.087c-.27-.186-.431-.494-.431-.822 0-.328.161-.635.431-.822 2.223-1.539 7.011-4.855 9.497-6.576.306-.212.704-.236 1.033-.063.33.172.536.513.536.885v13.228c0 .357-.198.685-.514.85-.317.166-.699.143-.993-.061-2.467-1.708-7.317-5.067-9.559-6.619Zm40.094-37.444c-.187-.27-.494-.431-.822-.431-.328 0-.635.161-.822.431-1.539 2.223-4.855 7.011-6.576 9.497-.212.306-.236.704-.064 1.033.173.33.514.536.886.536h13.227c.358 0 .685-.198.851-.515.166-.316.142-.698-.061-.992-1.708-2.467-5.067-7.318-6.619-9.559Z" />
      ),
    },
    right: {
      base: (
        <path d="M326.778 246.087c.27-.186.431-.494.431-.822 0-.328-.161-.635-.431-.822-2.223-1.539-7.011-4.855-9.497-6.576-.306-.212-.704-.236-1.033-.063-.33.172-.536.513-.536.885v13.228c0 .357.198.685.514.85.317.166.699.143.993-.061 2.467-1.708 7.317-5.067 9.559-6.619Zm-40.094-37.444c.187-.27.494-.431.822-.431.328 0 .635.161.822.431 1.539 2.223 4.855 7.011 6.576 9.497.212.306.236.704.064 1.033-.173.33-.514.536-.886.536h-13.227c-.358 0-.685-.198-.851-.515-.166-.316-.142-.698.061-.992 1.708-2.467 5.067-7.318 6.619-9.559Z" />
      ),
    },
  },
};

const tattoo_cbzPrW898U = {
  name: "Geometric 8",
  description: "An equilateral triangle on the outside of the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M173.222 246.087c-.27-.186-.431-.494-.431-.822 0-.328.161-.635.431-.822 2.223-1.539 7.011-4.855 9.497-6.576.306-.212.704-.236 1.033-.063.33.172.536.513.536.885v13.228c0 .357-.198.685-.514.85-.317.166-.699.143-.993-.061-2.467-1.708-7.317-5.067-9.559-6.619Z" />
      ),
    },
    right: {
      base: (
        <path d="M286.684 281.534c.187.27.494.431.822.431.328 0 .635-.161.822-.431 1.539-2.223 4.855-7.011 6.576-9.497.212-.306.236-.704.064-1.033-.173-.33-.514-.536-.886-.536h-13.227c-.358 0-.685.198-.851.515-.166.316-.142.698.061.992 1.708 2.467 5.067 7.318 6.619 9.559Z" />
      ),
    },
  },
};

const tattoo_FzdKcSWxYK = {
  name: "Geometric 9",
  description: "An equilateral triangle underneath the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M213.316 281.534c-.187.27-.494.431-.822.431-.328 0-.635-.161-.822-.431-1.539-2.223-4.855-7.011-6.576-9.497-.212-.306-.236-.704-.064-1.033.173-.33.514-.536.886-.536h13.227c.358 0 .685.198.851.515.166.316.142.698-.061.992-1.708 2.467-5.067 7.318-6.619 9.559Z" />
      ),
    },
    right: {
      base: (
        <path d="M326.778 246.087c.27-.186.431-.494.431-.822 0-.328-.161-.635-.431-.822-2.223-1.539-7.011-4.855-9.497-6.576-.306-.212-.704-.236-1.033-.063-.33.172-.536.513-.536.885v13.228c0 .357.198.685.514.85.317.166.699.143.993-.061 2.467-1.708 7.317-5.067 9.559-6.619Z" />
      ),
    },
  },
};

const tattoo_Kt7ZBrEunV = {
  name: "Geometric 10",
  description: "An equilateral triangle above the eye",
  tags: ["geometric", "eye"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M213.316 208.643c-.187-.27-.494-.431-.822-.431-.328 0-.635.161-.822.431-1.539 2.223-4.855 7.011-6.576 9.497-.212.306-.236.704-.064 1.033.173.33.514.536.886.536h13.227c.358 0 .685-.198.851-.515.166-.316.142-.698-.061-.992-1.708-2.467-5.067-7.318-6.619-9.559Z" />
      ),
    },
    right: {
      base: (
        <path d="M286.684 208.643c.187-.27.494-.431.822-.431.328 0 .635.161.822.431 1.539 2.223 4.855 7.011 6.576 9.497.212.306.236.704.064 1.033-.173.33-.514.536-.886.536h-13.227c-.358 0-.685-.198-.851-.515-.166-.316-.142-.698.061-.992 1.708-2.467 5.067-7.318 6.619-9.559Z" />
      ),
    },
  },
};

export const eyesGeometricPack1 = {
  tattoo_QTphVy9jzJ,
  tattoo_HdwpAbHK2v,
  tattoo_S9uJL7SpZ5,
  tattoo_7xAjw5Tvhu,
  tattoo_qMPvZepVYT,
  tattoo_b3ShzmN3Qv,
  tattoo_FwpM9nFpYG,
  tattoo_cbzPrW898U,
  tattoo_FzdKcSWxYK,
  tattoo_Kt7ZBrEunV,
};
