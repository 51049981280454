import React from "react";
import { connect } from "react-redux";
import { NeckAccessoriesList } from "./NeckAccessoriesList";
import NeckAccessoryBack from "./NeckAccessoryBack";

/**
This will be used to build the different back layers of neck accessories
 */

function mapStateToProps(state, ownProps) {
  const Layer1 = state.config.clothing.accessories.neck.layer1;
  const Layer2 = state.config.clothing.accessories.neck.layer2;
  const Layer3 = state.config.clothing.accessories.neck.layer3;

  return { Layer1, Layer2, Layer3 };
}

const NeckAccessoriesBackLayers = (props) => {
  const { previewId, Layer1, Layer2, Layer3 } = props;

  if (Layer1.shape && NeckAccessoriesList[Layer1.shape].shape.back) {
    return (
      <g
        id={
          previewId
            ? `g-neckAccessory-back-layers~${previewId}`
            : "g-neckAccessory-back-layers"
        }
      >
        {Layer1.shape && <NeckAccessoryBack layer="1" layerConfig={Layer1} />}
        {Layer2.shape && NeckAccessoriesList[Layer2.shape].shape.back && (
          <NeckAccessoryBack layer="2" layerConfig={Layer2} />
        )}
        {Layer3.shape && NeckAccessoriesList[Layer3.shape].shape.back && (
          <NeckAccessoryBack layer="3" layerConfig={Layer3} />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NeckAccessoriesBackLayers);
