import React from "react";
import { PreviewBase, previewViewbox, bodyMaskId } from "../Preview";

const head_shape_ZH6yu = {
  name: "Head 1",
  description: "A circle/oval head with smooth skin",
  tags: ["oval", "humanoid", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: <ellipse cx="250" cy="251.83" rx="80.76" ry="93.67" />,
  },
};

const head_shape_0bTw9 = {
  name: "Head 2",
  description: "A squarish head with smooth skin",
  tags: ["square", "humanoid", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M264.55,346.7h-29.1a66.24,66.24,0,0,1-66.21-66.28v-56a66.24,66.24,0,0,1,66.21-66.28h29.1a66.24,66.24,0,0,1,66.21,66.28v56A66.24,66.24,0,0,1,264.55,346.7Z" />
    ),
  },
};

const head_shape_yToPY = {
  name: "Head 3",
  description: "A round, vaguely heart-shaped head with smooth skin",
  tags: ["humanoid", "heart", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M250,345.5h0c-44.6,0-80.76-41.94-80.76-93.67V224c0-36.37,25.42-65.86,56.78-65.86h48c31.36,0,56.78,29.49,56.78,65.86v27.82C330.76,303.56,294.6,345.5,250,345.5Z" />
    ),
  },
};

const head_shape_kraxZ = {
  name: "Head 4",
  description: "A dome-shaped head with smooth skin",
  tags: ["humanoid", "dome", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M250,159.07h0c-44.6,0-80.76,41.73-80.76,93.22V280c0,36.19,25.42,65.53,56.78,65.53h48c31.36,0,56.78-29.34,56.78-65.53V252.29C330.76,200.8,294.6,159.07,250,159.07Z" />
    ),
  },
};

const head_shape_4CFTf = {
  name: "Head 5",
  description:
    "A hexagonal head with a pointed top and bottom, and smooth skin",
  tags: ["pointed", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M317.06,313.83l-51.14,34.55a28.22,28.22,0,0,1-31.84,0l-51.14-34.55a31.91,31.91,0,0,1-13.7-26.48v-74.7a31.91,31.91,0,0,1,13.7-26.48l51.14-34.55a28.22,28.22,0,0,1,31.84,0l51.14,34.55a31.91,31.91,0,0,1,13.7,26.48v74.7A31.91,31.91,0,0,1,317.06,313.83Z" />
    ),
  },
};

const head_shape_NVuAD = {
  name: "Head 6",
  description: "A heart-shaped head with a rounded chin and smooth skin",
  tags: ["humanoid", "heart", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M310.45,319.6l-37.56,26.68a38.93,38.93,0,0,1-45.78,0L189.55,319.6c-12.26-8.71-19.68-23.79-19.68-40V217.79c0-16.2,7.42-31.29,19.68-40h0c36.87-26.19,84-26.19,120.9,0h0c12.26,8.71,19.68,23.8,19.68,40V279.6C330.13,295.81,322.71,310.89,310.45,319.6Z" />
    ),
  },
};

const head_shape_nna8K = {
  name: "Head 7",
  description: "A heart-shaped head with a pointed chin and smooth skin",
  tags: ["humanoid", "heart", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M310.91,319.84l-52.09,36.77a15.07,15.07,0,0,1-17.64,0l-52.09-36.77c-12.36-8.72-19.85-23.83-19.85-40.06v-61.9c0-16.23,7.49-31.33,19.85-40.06h0c37.15-26.23,84.67-26.23,121.82,0h0c12.36,8.73,19.85,23.83,19.85,40.06v61.9C330.76,296,323.27,311.12,310.91,319.84Z" />
    ),
  },
};

const head_shape_9PgPk = {
  name: "Head 8",
  description: "A reverse heart-shaped head with a pointed top and smooth skin",
  tags: ["humanoid", "round", "pointed", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M310.91,183.65l-52.09-36.8a15,15,0,0,0-17.64,0l-52.09,36.8c-12.36,8.73-19.85,23.85-19.85,40.1v62c0,16.25,7.49,31.37,19.85,40.1h0c37.15,26.25,84.67,26.25,121.82,0h0c12.36-8.73,19.85-23.85,19.85-40.1v-62C330.76,207.5,323.27,192.38,310.91,183.65Z" />
    ),
  },
};

const head_shape_LbfRpQaBU5 = {
  name: "Head 9",
  description: "A reverse heart-shaped head with a pointed top and smooth skin",
  tags: ["humanoid", "round", "pointed", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M170.273 260.038c-.506-5.122-.78-9.366-.916-11.887 2.28-50.12 37.541-89.991 80.643-89.991 43.102 0 78.363 39.871 80.643 89.991-.136 2.521-.41 6.765-.916 11.887-1.355 13.704-4.375 33.699-10.88 43.827-10.477 16.311-38.004 33.043-50.949 39.652-5.178 2.644-30.618 2.644-35.796 0-12.945-6.609-40.472-23.341-50.949-39.652-6.505-10.128-9.525-30.123-10.88-43.827Z" />
    ),
  },
};

const head_shape_aJdDNqSU8G = {
  name: "Head 10",
  description: "A reverse heart-shaped head with a pointed top and smooth skin",
  tags: ["humanoid", "round", "pointed", "smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.24 253.317c0-52.519 36.187-95.157 80.76-95.157 44.573 0 80.76 42.638 80.76 95.157 0 0-1.436 45.575-11.913 63.316s-38.004 35.939-50.949 43.127c-5.178 2.876-30.618 2.876-35.796 0-12.945-7.188-40.472-25.386-50.949-43.127-10.477-17.741-11.913-63.316-11.913-63.316Z" />
    ),
  },
};

const head_shape_ZiBTtrTzVg = {
  name: "Head 11 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M329.728 260.026c-1.354 13.705-4.374 33.708-10.881 43.839-10.477 16.311-24.439 33.043-37.384 39.652-3.125 1.596-18.569 2.228-31.463 1.898-12.894.33-28.338-.302-31.463-1.898-12.945-6.609-26.907-23.341-37.384-39.652-6.507-10.131-9.527-30.134-10.881-43.839-.506-5.12-.779-9.361-.915-11.879 2.278-50.118 37.539-89.987 80.643-89.987s78.365 39.869 80.643 89.987a272.256 272.256 0 0 1-.915 11.879Z" />
    ),
  },
};

const head_shape_JUaWpHtrJc = {
  name: "Head 12 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M181.153 316.614c-10.477-17.738-11.913-63.308-11.913-63.308 0-52.513 36.187-95.146 80.76-95.146 44.573 0 80.76 42.633 80.76 95.146 0 0-1.436 45.57-11.913 63.308-10.477 17.739-24.439 35.935-37.384 43.122-3.125 1.735-18.569 2.423-31.463 2.064-12.894.359-28.338-.329-31.463-2.064-12.945-7.187-26.907-25.383-37.384-43.122Z" />
    ),
  },
};

const head_shape_pCSvWPdqDn = {
  name: "Head 13 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M247.672 158.16h4.656c44.573 0 80.76 41.972 80.76 93.67 0 0 .079 3.045.114 7.806l-.004.39c-.133 14.901-1.015 35.829-4.766 41.579-6.006 9.206-15.833 18.548-26.276 26.07-7.772 5.6-34.771 17.825-52.156 17.825-17.385 0-44.384-12.225-52.156-17.825-10.443-7.522-20.27-16.864-26.276-26.07-3.751-5.75-4.633-26.678-4.766-41.579l-.004-.39c.035-4.761.114-7.806.114-7.806 0-51.698 36.187-93.67 80.76-93.67Z" />
    ),
  },
};

const head_shape_32CZRMPqfD = {
  name: "Head 14 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M247.672 158.16h4.656c44.573 0 80.76 41.972 80.76 93.67 0 0 1.35 52.316-4.656 62.327-6.006 10.011-15.833 20.171-26.276 28.351-7.772 6.09-34.771 19.385-52.156 19.385-17.385 0-44.384-13.295-52.156-19.385-10.443-8.18-20.27-18.34-26.276-28.351s-4.656-62.327-4.656-62.327c0-51.698 36.187-93.67 80.76-93.67Z" />
    ),
  },
};

const head_shape_TyUUgwRK6D = {
  name: "Head 15 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.856 263.446c-1.884-3.421-3.936-6.702-6.143-9.554-2.996-3.87-8.554-12.648-3.223-12.058 6.36.704 8.456-2.541 10.037-6.734 6.809-43.727 39.823-76.94 79.473-76.94s72.664 33.213 79.473 76.94c1.581 4.193 3.677 7.438 10.037 6.734 5.331-.59-.227 8.188-3.223 12.058-2.207 2.852-4.259 6.133-6.143 9.554-3.796 6.892-6.914 14.352-9.26 20.02-2.221 5.364-7.43 29.171-7.473 30.069-.202 4.151 2.018 9.216-3.195 8.494-8.093-1.12-17.131 11.771-17.184 17.63-.062 6.928-3.481 1.451-8.466 2.264-22.206 3.618-28.769 17.28-31.957 24.399-1.105 2.468-1.805 4.149-2.609 4.354-.804-.205-1.504-1.886-2.609-4.354-3.188-7.119-9.751-20.781-31.957-24.399-4.985-.813-8.404 4.664-8.466-2.264-.053-5.859-9.091-18.75-17.184-17.63-5.213.722-2.993-4.343-3.195-8.494-.043-.898-5.252-24.705-7.473-30.069-2.346-5.668-5.464-13.128-9.26-20.02Z" />
    ),
  },
};

const head_shape_cuiQijkDrL = {
  name: "Head 16 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.856 263.446c-1.884-3.421-3.936-6.702-6.143-9.554-2.996-3.87-8.554-12.648-3.223-12.058 6.36.704 8.456-2.541 10.037-6.734 6.809-43.727 39.823-76.94 79.473-76.94s72.664 33.213 79.473 76.94c1.581 4.193 3.677 7.438 10.037 6.734 5.331-.59-.227 8.188-3.223 12.058-2.207 2.852-4.259 6.133-6.143 9.554-3.796 6.892-6.914 14.352-9.26 20.02-2.221 5.364-7.43 45.499-7.473 46.397-.202 4.15 2.018 9.215-3.195 8.493-8.093-1.12-17.131 11.772-17.184 17.63-.062 6.928-3.481 1.452-8.466 2.264-22.206 3.618-28.769 17.28-31.957 24.4-1.105 2.467-1.805 4.148-2.609 4.353-.804-.205-1.504-1.886-2.609-4.353-3.188-7.12-9.751-20.782-31.957-24.4-4.985-.812-8.404 4.664-8.466-2.264-.053-5.858-9.091-18.75-17.184-17.63-5.213.722-2.993-4.343-3.195-8.493-.043-.898-5.252-41.033-7.473-46.397-2.346-5.668-5.464-13.128-9.26-20.02Z" />
    ),
  },
};

const head_shape_pVk8UumUgR = {
  name: "Head 17 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M170.527 235.1c6.809-43.727 39.823-76.94 79.473-76.94s72.664 33.213 79.473 76.94c.846 5.429 1.078 24.54.671 28.346-1.779 16.648-16.721 49.834-16.733 50.089-.202 4.151 2.018 9.216-3.195 8.494-8.093-1.12-17.131 11.771-17.184 17.63-.062 6.928-3.481 1.451-8.466 2.264-22.206 3.618-28.769 17.28-31.957 24.399-1.105 2.468-1.805 4.149-2.609 4.354-.804-.205-1.504-1.886-2.609-4.354-3.188-7.119-9.751-20.781-31.957-24.399-4.985-.813-8.404 4.664-8.466-2.264-.053-5.859-9.091-18.75-17.184-17.63-5.213.722-2.993-4.343-3.195-8.494-.012-.255-14.954-33.441-16.733-50.089-.407-3.806-.175-22.917.671-28.346Z" />
    ),
  },
};

const head_shape_RNhb9KkaQX = {
  name: "Head 18 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M170.527 235.1c6.809-43.727 39.823-76.94 79.473-76.94s72.664 33.213 79.473 76.94a108.641 108.641 0 0 1 1.287 16.73c0 3.933-.209 7.81-.616 11.616-1.599 14.966-10.409 29.695-13.198 40.764-2.109 8.37-3.511 25.147-3.535 25.653-.202 4.15 2.018 9.215-3.195 8.493-8.093-1.12-17.131 11.772-17.184 17.63-.062 6.928-3.481 1.452-8.466 2.264-22.206 3.618-28.769 17.28-31.957 24.4-1.105 2.467-1.805 4.148-2.609 4.353-.804-.205-1.504-1.886-2.609-4.353-3.188-7.12-9.751-20.782-31.957-24.4-4.985-.812-8.404 4.664-8.466-2.264-.053-5.858-9.091-18.75-17.184-17.63-5.213.722-2.993-4.343-3.195-8.493-.024-.506-1.426-17.283-3.535-25.653-2.789-11.069-11.599-25.798-13.198-40.764a109.241 109.241 0 0 1-.616-11.616c0-5.71.441-11.301 1.287-16.73Z" />
    ),
  },
};

const head_shape_FY85PMyYTV = {
  name: "Head 19 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.256 249.967c.856-50.839 36.708-91.807 80.744-91.807s79.888 40.968 80.744 91.807l.005.291c-.341 8.705-1.041 15.632-1.799 21.367-1.891 14.309-4.147 21.198-2.112 29.784a7.817 7.817 0 0 1-5.937 9.241c-10.954 2.234-5.422 18.694-30.622 25.612-4.028 1.105-1.405 19.958-11.423 16.246-7.33-2.716-8.666-5.719-26.723-5.201-.657.08-3.609.08-4.266 0-18.057-.518-19.393 2.485-26.723 5.201-10.018 3.712-7.395-15.141-11.423-16.246-25.2-6.918-19.668-23.378-30.622-25.612a7.817 7.817 0 0 1-5.937-9.241c2.035-8.586-.221-15.475-2.112-29.784-.758-5.735-1.458-12.662-1.799-21.367l.005-.291Z" />
    ),
  },
};

const head_shape_WGEw6tMqcV = {
  name: "Head 20 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.256 249.967c.856-50.839 36.708-91.807 80.744-91.807s79.888 40.968 80.744 91.807l.005.291c-.341 8.705-1.041 15.632-1.799 21.367-1.891 14.309-4.147 38.005-2.112 46.591a7.817 7.817 0 0 1-5.937 9.241c-10.954 2.234-5.422 18.694-30.622 25.612-4.028 1.106-1.405 19.958-11.423 16.246-7.33-2.716-8.666-5.719-26.723-5.201-.657.08-3.609.08-4.266 0-18.057-.518-19.393 2.485-26.723 5.201-10.018 3.712-7.395-15.14-11.423-16.246-25.2-6.918-19.668-23.378-30.622-25.612a7.817 7.817 0 0 1-5.937-9.241c2.035-8.586-.221-32.282-2.112-46.591-.758-5.735-1.458-12.662-1.799-21.367l.005-.291Z" />
    ),
  },
};

const head_shape_x5Z6mHh74x = {
  name: "Head 21 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.24 251.83c0-51.698 36.187-93.67 80.76-93.67 44.573 0 80.76 41.972 80.76 93.67 0 10.758-3.661 20.403-4.451 30.72-.849 11.106-2.215 25.763-.647 35.917a6.768 6.768 0 0 1-6.268 7.899c-12.258.705-18.916 13.913-28.056 14.604-23.314 1.76-20.866 4.188-27.124 13.915-1.301 2.011-6.842 4.58-14.214 4.03-7.372.55-12.913-2.019-14.214-4.03-6.258-9.727-3.81-12.155-27.124-13.915-9.14-.691-15.798-13.899-28.056-14.604a6.766 6.766 0 0 1-6.268-7.899c1.568-10.154.202-24.811-.647-35.917-.79-10.317-4.451-19.962-4.451-30.72Z" />
    ),
  },
};

const head_shape_WmgbEDnNZX = {
  name: "Head 22 (Furry)",
  description: "A reverse heart-shaped head with a pointed top and furry skin",
  tags: ["humanoid", "round", "pointed", "furry"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shadowMask: bodyMaskId,
  shape: {
    base: (
      <path d="M169.24 251.83c0-51.698 36.187-93.67 80.76-93.67 44.573 0 80.76 41.972 80.76 93.67 0 10.758-3.661 20.403-4.451 30.72-.849 11.106-2.215 46.025-.647 56.179a6.77 6.77 0 0 1-6.268 7.899c-12.258.704-18.916 13.913-28.056 14.603-23.314 1.761-20.866 4.189-27.124 13.915-1.301 2.012-6.842 4.581-14.214 4.03-7.372.551-12.913-2.018-14.214-4.03-6.258-9.726-3.81-12.154-27.124-13.915-9.14-.69-15.798-13.899-28.056-14.603a6.77 6.77 0 0 1-6.268-7.899c1.568-10.154.202-45.073-.647-56.179-.79-10.317-4.451-19.962-4.451-30.72Z" />
    ),
  },
};

export const headShapePack1 = {
  head_shape_ZH6yu,
  head_shape_0bTw9,
  head_shape_yToPY,
  head_shape_kraxZ,
  head_shape_4CFTf,
  head_shape_NVuAD,
  head_shape_nna8K,
  head_shape_9PgPk,
  head_shape_LbfRpQaBU5,
  head_shape_aJdDNqSU8G,
  head_shape_ZiBTtrTzVg,
  head_shape_JUaWpHtrJc,
  head_shape_pCSvWPdqDn,
  head_shape_32CZRMPqfD,
  head_shape_TyUUgwRK6D,
  head_shape_cuiQijkDrL,
  head_shape_pVk8UumUgR,
  head_shape_RNhb9KkaQX,
  head_shape_FY85PMyYTV,
  head_shape_WGEw6tMqcV,
  head_shape_x5Z6mHh74x,
  head_shape_WmgbEDnNZX,
};
