import React from "react";

import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import NeckAccessoryBack from "./NeckAccessoryBack";
import NeckAccessoryFront from "./NeckAccessoryFront";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <NeckAccessoryBack
        preview={true}
        previewId={previewId}
        previewTransform={false}
        accessory={component}
        layer={"1"}
      />
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
      <NeckAccessoryFront
        preview={true}
        previewId={previewId}
        previewTransform={false}
        accessory={component}
        layer={"1"}
      />
    </>
  );
};

export const viewbox = "150 260 200 180";
