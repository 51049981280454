import React from "react";

import Ears from "character-creator/components/head/ears/CharacterEars";
import CharacterHead from "character-creator/components/head/CharacterHead";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <Ears preview={true} ears={component} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewbox = "110 200 100 100";
