import React from "react";
import BackgroundShapes from "./BackgroundShapes";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <BackgroundShapes
        preview={true}
        previewId={previewId}
        shape={component}
      />
    </>
  );
};

export const viewbox = "0 0 500 500";
