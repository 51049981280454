import AllSkintones from "character-creator/colours/skin";
import { BodyExtrasList } from "character-creator/components/body/extras/BodyExtrasList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const bodyExtrasPath = "base.body.extras.";
const extrasPath1 = bodyExtrasPath + "extras1.";
const extrasPath2 = bodyExtrasPath + "extras2.";
const extrasPath3 = bodyExtrasPath + "extras3.";

const BodyExtrasPage = {
  id: "bodyExtrasPage",
  name: "Extras",
  description: "",
  controls: [
    /////////////////////////////
    // Extra 1
    /////////////////////////////
    {
      id: "bodyExtras1Shape",
      name: "Extra 1",
      description: "",
      dataAttribute: [extrasPath1 + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyExtrasList,
      colourControls: [
        {
          id: "bodyExtras1Colour",
          name: "Colour",
          description: "",
          dataAttribute: [extrasPath1 + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
    /////////////////////////////
    // Extra 2
    /////////////////////////////
    {
      id: "bodyExtras2Shape",
      name: "Extra 2",
      description: "",
      dataAttribute: [extrasPath2 + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyExtrasList,
      colourControls: [
        {
          id: "bodyCExtras2Colour",
          name: "Colour",
          description: "",
          dataAttribute: [extrasPath2 + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
    /////////////////////////////
    // Extra 3
    /////////////////////////////
    {
      id: "bodyExtras3Shape",
      name: "Extra 3",
      description: "",
      dataAttribute: [extrasPath3 + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyExtrasList,
      colourControls: [
        {
          id: "bodyCExtras3Colour",
          name: "Colour",
          description: "",
          dataAttribute: [extrasPath3 + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
  ],
};

export default BodyExtrasPage;
