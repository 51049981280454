import React from "react";
import { connect } from "react-redux";
import { mouthShapeList } from "character-creator/components/head/face/mouth/MouthShapeList";
import Component from "character-creator/components/Component";
import {
  teethLowerShapeList,
  teethUpperShapeList,
} from "./teeth/TeethShapeList";
import ClippedMarkings from "../../markings/ClippedMarkings";
import {
  translateTransformObject,
  buildTransform,
} from "character-creator/menu/menu-helper";
import LipColours from "character-creator/colours/lips";

function mapStateToProps(state, ownProps) {
  /** *******************************************
   * Get mouth config and objects
   ******************************************* **/
  const mouthConfig = state.config.base.head.face.mouth;
  const mouthObject =
    ownProps.preview && ownProps.mouth
      ? ownProps.mouth
      : mouthShapeList[mouthConfig.shape];

  /** *******************************************
   * Get teeth config and objects
   ******************************************* **/
  const teethConfig = mouthConfig.teeth;
  // Underbite? Default is overbite :)

  // #TODO Add underbite control

  // const teethUnderbite = ownProps.teeth
  //   ? ownProps.teeth.underbite
  //   : teethConfig.shape !== "0" && teethConfig.underbite;
  // const underbite = teethUnderbite;

  const previewMouthShape =
    mouthConfig.shape !== "0" ? mouthConfig.shape : ownProps.defaultMouthShape;

  // Upper teeth
  let teethObjectUpper = null;
  // If teeth preview object present and it has upper teeth option
  if (
    ownProps.preview &&
    ownProps.teeth &&
    ownProps.teeth.shape[previewMouthShape]
  ) {
    teethObjectUpper = ownProps.teeth.shape[previewMouthShape];
    // If teeth config already set and not mouth preview
  } else if (
    !ownProps.preview &&
    !ownProps.mouth &&
    mouthConfig.shape !== "0" &&
    teethConfig.upper.shape !== "0" &&
    teethUpperShapeList[teethConfig.upper.shape]?.shape[mouthConfig.shape]
  ) {
    teethObjectUpper =
      teethUpperShapeList[teethConfig.upper.shape]?.shape[mouthConfig.shape];
    // If teeth config not set, use default teeth for that mouth
  } else if (mouthObject.shape.teeth?.upper) {
    teethObjectUpper = mouthObject.shape.teeth.upper.shape;
  }

  // Lower teeth
  let teethObjectLower = null;
  // If teeth preview object present and have lower option
  if (
    ownProps.preview &&
    ownProps.teeth &&
    mouthConfig.shape !== "0" &&
    ownProps.teeth.shape[previewMouthShape]
  ) {
    teethObjectLower = ownProps.teeth.shape[previewMouthShape];
    // If teeth config already set and not mouth preview
  } else if (
    !ownProps.preview &&
    !ownProps.mouth &&
    mouthConfig.shape !== "0" &&
    teethConfig.lower.shape !== "0" &&
    teethLowerShapeList[teethConfig.lower.shape]?.shape[mouthConfig.shape]
  ) {
    teethObjectLower =
      teethLowerShapeList[teethConfig.lower.shape]?.shape[mouthConfig.shape];
    // If teeth config not set, use default teeth for that mouth
  } else if (mouthObject.shape.teeth?.lower) {
    teethObjectLower = mouthObject.shape.teeth.lower.shape;
  }

  /** *******************************************
   * Get colours
   ******************************************* **/
  const mouthConfigColours =
    mouthConfig.colour.lips.base !== state.config.base.skintone.base
      ? mouthConfig.colour
      : {
          lips: LipColours[Object.keys(LipColours)[3]],
          tongue: { ...mouthConfig.colour.tongue },
        };

  const colour = {
    skintone: state.config.base.skintone,
    teeth: {
      upper: {
        base: teethConfig.upper.colour.light,
      },
      lower: {
        base: teethConfig.lower.colour.dark,
      },
    },
    lowerLip: {
      base: mouthConfigColours.lips.base,
      light: mouthConfigColours.lips.light,
      outline: mouthConfigColours.lips.outline,
    },
    ...mouthConfigColours,
  };

  const transform = !ownProps.preview
    ? translateTransformObject(buildTransform(mouthConfig.transform))
    : "";

  /** *******************************************
   * Returneth
   ******************************************* **/
  return {
    colour: colour,
    strokeOverride: mouthObject.stroke,
    mouthObject: mouthObject.shape,
    teethObject: {
      upper: { shape: teethObjectUpper },
      lower: { shape: teethObjectLower },
    },
    transform,
    //underbite,
    defaultTeeth: teethConfig.shape === "0",
    linework: mouthObject.linework,
  };
}

const Mouth = (props) => {
  const {
    colour,
    mouthObject,
    teethObject,
    transform,
    strokeOverride,
    previewId,
    underbite = false,
    linework,
  } = props;
  const { lips, tongue, shadow, inside } = mouthObject;

  if (lips) {
    return (
      <g
        id={previewId ? `g-mouth~${previewId}` : "g-mouth"}
        transform-origin="50% 50%"
        {...transform}
      >
        {/* Shadow */}
        {shadow && (
          <Component
            id={previewId ? `mouth-shadow~${previewId}` : "mouth-shadow"}
            component={shadow}
            colour={{ base: colour.skintone.dark }}
            preview={previewId ? true : false}
          />
        )}
        {/* Inside mouth */}
        {inside && (
          <Component
            id={previewId ? `mouth-inside~${previewId}` : "mouth-inside"}
            component={inside}
            colour={{ base: colour.lips.darker }}
            preview={previewId ? true : false}
          />
        )}

        {/* Inside tongue */}
        {tongue?.inner && (
          <Component
            id={previewId ? `tongue-inside~${previewId}` : "tongue-inside"}
            component={tongue.inner}
            colour={colour.tongue}
            preview={previewId ? true : false}
          />
        )}

        {lips?.lower?.shape && (
          <Component
            id={previewId ? `lip-lower~${previewId}` : "lip-lower"}
            component={lips.lower}
            colour={colour.lowerLip}
            preview={previewId ? true : false}
            strokeOverride={strokeOverride}
            linework={linework}
          />
        )}

        {lips?.upper?.shape && (
          <Component
            id={previewId ? `lip-upper~${previewId}` : "lip-upper"}
            component={lips.upper}
            colour={colour.lips}
            preview={previewId ? true : false}
            strokeOverride={strokeOverride}
            linework={linework}
          />
        )}

        {/* Teefs! */}
        {teethObject?.lower?.shape && (
          <Component
            id={previewId ? `teeth-lower~${previewId}` : "teeth-lower"}
            component={teethObject.lower}
            colour={colour.teeth.lower}
            preview={previewId ? true : false}
          />
        )}
        {teethObject?.upper?.shape && (
          <Component
            id={previewId ? `teeth-upper~${previewId}` : "teeth-upper"}
            component={teethObject.upper}
            colour={colour.teeth.upper}
            preview={previewId ? true : false}
          />
        )}

        {/* Cheeky tongue */}
        {tongue?.outer && (
          <Component
            id={previewId ? `tongue-outside~${previewId}` : "tongue-outside"}
            component={tongue.outer}
            colour={colour.tongue}
            preview={previewId ? true : false}
          />
        )}

        {/* Clipped markings on lips */}
        {!previewId && lips?.upper && !linework && (
          <ClippedMarkings
            componentName="lip-upper"
            maskUrl="lip-upper_base"
            maskTransform={transform}
          />
        )}
        {!previewId && lips?.lower && !linework && (
          <ClippedMarkings
            componentName="lip-lower"
            maskUrl="lip-lower_base"
            maskTransform={transform}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Mouth);
