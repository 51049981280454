/**
 * Variations/options
 */
import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { hairBackRightPack1 } from "./packs/HairBackRightPack1";
import { hairBackRightPack2 } from "./packs/HairBackRightPack2";

export const hairBackRightList = {
  "0": BlankListOption,
  ...hairBackRightPack1,
  ...hairBackRightPack2,
};

export default hairBackRightList;
