// Sections
import BeardPage from "./pages/face/BeardPage";
import EyebrowsPage from "./pages/face/EyebrowsPage";
import MoustachePage from "./pages/face/MoustachePage";
import HairBackPage from "./pages/head/HairBackPage";
import HairBasePage from "./pages/head/HairBasePage";
import HairFrontPage from "./pages/head/HairFrontPage";
import HairMidPage from "./pages/head/HairMidPage";
import HairSidePage from "./pages/head/HairSidesPage";
import HairTopPage from "./pages/head/HairTopPage";

// Attribute paths
// export const basePath = "base.";

const HairMenuConfig = {
  name: "Hair",
  description: "Hair today, gone tomorrow",
  groups: [
    {
      id: "groupHairHead",
      name: "Head",
      pages: {
        hairBasePage: HairBasePage,
        hairBackPage: HairBackPage,
        hairMidPage: HairMidPage,
        hairSidePage: HairSidePage,
        hairTopPage: HairTopPage,
        hairFrontPage: HairFrontPage,
      },
    },
    {
      id: "groupHairFacial",
      name: "Face",
      pages: {
        hairEyebrowsPage: EyebrowsPage,
        hairMoustachePage: MoustachePage,
        hairBeardPage: BeardPage,
      },
    },
  ],
};

export default HairMenuConfig;
