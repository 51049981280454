import React from "react";
import { viewbox, PreviewBase } from "../../Preview";

/***************************************
A basic crew neck t-shirt
***************************************/

const tshirt_47yZcnwC6w = {
  name: "T-Shirt (Crew)",
  description: "A basic crew neck t-shirt",
  tags: ["basic", "tshirt", "crew", "sleeves"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      base: (
        <path d="M57.359 500s-5.779-50.329 12.648-70.977c20.301-22.747 50.142-28.282 114.375-43.039 0 0 9.042-7.476 11.653-4.977 8.859 8.475 20.783 22.728 53.055 22.932.017-.002 1.803-.002 1.82 0 32.272-.204 44.196-14.457 53.055-22.932 2.611-2.499 11.653 4.977 11.653 4.977 64.233 14.757 94.074 20.292 114.375 43.039C448.42 449.671 442.641 500 442.641 500H57.359Z" />
      ),
      basic: {
        dark: (
          <path d="M105.727 475.601c3.06-4.305 6.12-17.298-2.445-31.897-.617-1.053 13.335 4.371 12.956 9.219-2.583 33.045 2.115 52.233 2.115 52.233L56.499 500s-16.907-36.874 0-12.813c12.294 17.495 43.082-2.939 49.228-11.586Zm288.546 0c-3.06-4.305-6.12-17.298 2.445-31.897.617-1.053-13.335 4.371-12.956 9.219 2.583 33.045-2.115 52.233-2.115 52.233L443.501 500s16.907-36.874 0-12.813c-12.294 17.495-43.082-2.939-49.228-11.586ZM250 417.8c29.84-.154 46.892-10.197 56.099-18.949 2.178-3.123 5.786-9.162 5.668-14.752-.151-7.149 4.934 2.046 4.831 2.303 0 0-6.57 16.528-30.148 25.973-15.453 8.779-30.286 9.493-36.45 9.338-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-30.148-25.973-30.148-25.973-.103-.257 4.982-9.452 4.831-2.303-.118 5.59 3.49 11.629 5.668 14.752 9.207 8.752 26.259 18.795 56.099 18.949Z" />
        ),
        darker: (
          <path d="M110.68 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm281.77 0c-1.2-13.01-2.45-22.19-2.45-22.19l-4.48-14.22c1.87 9.69 3.04 22.75 3.81 36.41h3.12Z" />
        ),
      },
    },
    // Body 1B
    base_body_shape_taw74: {
      base: (
        <path d="M56.575 500s-1.875-45.03 13.127-68.286c10.224-15.848 38.98-39.671 104.538-60.066 0 0 10.551-9.017 12.168-5.784 12.125 24.252 29.626 37.871 61.898 38.075.015-.001 3.373-.001 3.388 0 32.272-.204 49.773-13.823 61.898-38.075 1.617-3.233 12.168 5.784 12.168 5.784 65.558 20.395 94.314 44.218 104.538 60.066C445.3 454.97 443.425 500 443.425 500H56.575Z" />
      ),
      basic: {
        dark: (
          <path d="M104.943 475.601c3.061-4.305 6.12-17.298-2.444-31.897-.618-1.053 13.334 4.371 12.955 9.219-2.582 33.045 2.116 52.233 2.116 52.233L55.716 500s-16.908-36.874 0-12.813c12.293 17.495 43.082-2.939 49.227-11.586Zm290.114 0c-3.061-4.305-6.12-17.298 2.444-31.897.618-1.053-13.334 4.371-12.955 9.219 2.582 33.045-2.116 52.233-2.116 52.233L444.284 500s16.908-36.874 0-12.813c-12.293 17.495-43.082-2.939-49.227-11.586Zm-143.821-57.804c29.556-.271 45.957-11.963 55.117-20.67 2.178-3.124 15.16-22.581 15.041-28.17-.151-7.149 4.934 2.046 4.832 2.302 0 0-15.414 31.671-38.993 41.116-15.453 8.779-30.285 9.493-36.449 9.338-.237.006-1.331.006-1.568 0-6.164.155-20.996-.559-36.449-9.338-23.579-9.445-38.993-41.116-38.993-41.116-.102-.256 4.983-9.451 4.832-2.302-.119 5.589 12.863 25.046 15.041 28.17 9.16 8.707 25.561 20.399 55.117 20.67.402-.001 2.072-.001 2.472 0Z" />
        ),
        darker: (
          <path d="M109.896 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm280.208 0c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Z" />
        ),
      },
    },
    // Body 1C
    base_body_shape_jnr29: {
      base: (
        <path d="M249.09 403.939h1.82c32.272-.204 43.231-10.621 52.09-19.096 2.612-2.499 11.653 4.977 11.653 4.977 17.468 6.342 42.323 3.306 58.842 15.663 7.865 5.883 13.144 6.286 18.945 6.345 15.911.161 27.071 5.449 37.553 17.195C448.42 449.671 442.641 500 442.641 500H57.359s-5.779-50.329 12.648-70.977c10.482-11.746 21.642-17.034 37.553-17.195 5.801-.059 11.08-.462 18.945-6.345 16.519-12.357 41.374-9.321 58.842-15.663 0 0 9.041-7.476 11.653-4.977 8.859 8.475 19.818 18.892 52.09 19.096Z" />
      ),
      basic: {
        dark: (
          <path d="M394.273 475.601c-3.06-4.305-6.12-17.298 2.445-31.897.617-1.053-13.335 4.371-12.956 9.219 2.583 33.045-2.115 52.233-2.115 52.233L443.501 500s16.907-36.874 0-12.813c-12.294 17.495-43.082-2.939-49.228-11.586Zm-288.546 0c3.06-4.305 6.12-17.298-2.445-31.897-.617-1.053 13.335 4.371 12.956 9.219-2.583 33.045 2.115 52.233 2.115 52.233L56.499 500s-16.907-36.874 0-12.813c12.294 17.495 43.082-2.939 49.228-11.586ZM250 421.713c-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-29.183-22.137-29.183-22.137-.103-.257 4.982-9.452 4.831-2.303-.118 5.589 2.525 7.793 4.703 10.916 9.207 8.752 26.259 18.795 56.099 18.949 29.84-.154 46.892-10.197 56.099-18.949 2.178-3.123 4.821-5.327 4.703-10.916-.151-7.149 4.934 2.046 4.831 2.303 0 0-5.605 12.692-29.183 22.137-15.453 8.779-30.286 9.493-36.45 9.338Z" />
        ),
        darker: (
          <path d="M389.32 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-278.64 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Z" />
        ),
      },
    },
    // Body 2A
    base_body_shape_g825h: {
      base: (
        <path d="M57.359 500s-5.779-50.329 12.648-70.977c20.301-22.747 50.142-28.282 114.375-43.039 0 0 9.042-7.476 11.653-4.977 8.859 8.475 20.783 22.728 53.055 22.932.017-.002 1.803-.002 1.82 0 32.272-.204 44.196-14.457 53.055-22.932 2.611-2.499 11.653 4.977 11.653 4.977 64.233 14.757 94.074 20.292 114.375 43.039C448.42 449.671 442.641 500 442.641 500H57.359Z" />
      ),
      basic: {
        dark: (
          <path d="M105.727 475.601c3.06-4.305 6.12-17.298-2.445-31.897-.617-1.053 13.335 4.371 12.956 9.219-2.583 33.045 2.115 52.233 2.115 52.233L56.499 500s-16.907-36.874 0-12.813c12.294 17.495 43.082-2.939 49.228-11.586Zm288.546 0c-3.06-4.305-6.12-17.298 2.445-31.897.617-1.053-13.335 4.371-12.956 9.219 2.583 33.045-2.115 52.233-2.115 52.233L443.501 500s16.907-36.874 0-12.813c-12.294 17.495-43.082-2.939-49.228-11.586ZM250 417.8c29.84-.154 46.892-10.197 56.099-18.949 2.178-3.123 5.786-9.162 5.668-14.752-.151-7.149 4.934 2.046 4.831 2.303 0 0-6.57 16.528-30.148 25.973-15.453 8.779-30.286 9.493-36.45 9.338-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-30.148-25.973-30.148-25.973-.103-.257 4.982-9.452 4.831-2.303-.118 5.59 3.49 11.629 5.668 14.752 9.207 8.752 26.259 18.795 56.099 18.949Z" />
        ),
        darker: (
          <path d="M110.68 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm281.77 0c-1.2-13.01-2.45-22.19-2.45-22.19l-4.48-14.22c1.87 9.69 3.04 22.75 3.81 36.41h3.12Z" />
        ),
      },
    },
    // Body 2B
    base_body_shape_fa048: {
      base: (
        <path d="M56.575 500s-1.594-44.851 13.127-68.286c12.026-19.144 38.98-39.671 104.538-60.066 0 0 10.551-9.017 12.168-5.784 12.125 24.252 29.626 37.871 61.898 38.075.015-.001 3.373-.001 3.388 0 32.272-.204 49.773-13.823 61.898-38.075 1.617-3.233 12.168 5.784 12.168 5.784 65.558 20.395 94.329 42.919 104.538 60.066C444.456 455.494 443.425 500 443.425 500H56.575Z" />
      ),
      basic: {
        dark: (
          <path d="M104.943 475.601c3.061-4.305 6.12-17.298-2.444-31.897-.618-1.053 13.334 4.371 12.955 9.219-2.582 33.045 2.116 52.233 2.116 52.233L55.716 500s-16.908-36.874 0-12.813c12.293 17.495 43.082-2.939 49.227-11.586Zm290.114 0c-3.061-4.305-6.12-17.298 2.444-31.897.618-1.053-13.334 4.371-12.955 9.219 2.582 33.045-2.116 52.233-2.116 52.233L444.284 500s16.908-36.874 0-12.813c-12.293 17.495-43.082-2.939-49.227-11.586Zm-143.821-57.804c29.556-.271 45.957-11.963 55.117-20.67 2.178-3.124 15.16-22.581 15.041-28.17-.151-7.149 4.934 2.046 4.832 2.302 0 0-15.414 31.671-38.993 41.116-15.453 8.779-30.285 9.493-36.449 9.338-.237.006-1.331.006-1.568 0-6.164.155-20.996-.559-36.449-9.338-23.579-9.445-38.993-41.116-38.993-41.116-.102-.256 4.983-9.451 4.832-2.302-.119 5.589 12.863 25.046 15.041 28.17 9.16 8.707 25.561 20.399 55.117 20.67.402-.001 2.072-.001 2.472 0Z" />
        ),
        darker: (
          <path d="M109.896 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm280.208 0c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Z" />
        ),
      },
    },
    // Body 2C
    base_body_shape_mge5o: {
      base: (
        <path d="M249.09 403.939h1.82c32.272-.204 43.231-10.621 52.09-19.096 2.612-2.499 11.653 4.977 11.653 4.977 17.468 6.342 39.014-.295 58.965 14.607 7.869 5.878 13.021 7.342 18.822 7.401 15.911.161 27.071 5.449 37.553 17.195C448.42 449.671 442.641 500 442.641 500H57.359s-5.779-50.329 12.648-70.977c10.482-11.746 19.19-17.763 37.553-17.195 5.799.179 12.514-2.501 20.379-8.384 16.519-12.357 39.94-7.282 57.408-13.624 0 0 9.041-7.476 11.653-4.977 8.859 8.475 19.818 18.892 52.09 19.096Z" />
      ),
      basic: {
        dark: (
          <path d="M394.273 475.601c-3.06-4.305-6.12-17.298 2.445-31.897.617-1.053-13.335 4.371-12.956 9.219 2.583 33.045-2.115 52.233-2.115 52.233L443.501 500s16.907-36.874 0-12.813c-12.294 17.495-43.082-2.939-49.228-11.586Zm-288.546 0c3.06-4.305 6.12-17.298-2.445-31.897-.617-1.053 13.335 4.371 12.956 9.219-2.583 33.045 2.115 52.233 2.115 52.233L56.499 500s-16.907-36.874 0-12.813c12.294 17.495 43.082-2.939 49.228-11.586ZM250 421.713c-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-29.183-22.137-29.183-22.137-.103-.257 4.982-9.452 4.831-2.303-.118 5.589 2.525 7.793 4.703 10.916 9.207 8.752 26.259 18.795 56.099 18.949 29.84-.154 46.892-10.197 56.099-18.949 2.178-3.123 4.821-5.327 4.703-10.916-.151-7.149 4.934 2.046 4.831 2.303 0 0-5.605 12.692-29.183 22.137-15.453 8.779-30.286 9.493-36.45 9.338Z" />
        ),
        darker: (
          <path d="M389.32 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-278.64 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Z" />
        ),
      },
    },
    // Body 3A
    base_body_shape_gj245: {
      base: (
        <path d="M56.948 500s-9.267-51.905 9.16-72.554c20.301-22.747 77.665-38.052 117.863-41.462 0 0 7.708-7.958 10.32-5.459 8.858 8.475 22.116 23.21 54.388 23.414.011-.001 2.631-.001 2.642 0 32.272-.204 45.53-14.939 54.388-23.414 2.612-2.499 10.32 5.459 10.32 5.459 40.198 3.41 97.562 18.715 117.863 41.462 18.427 20.649 9.16 72.554 9.16 72.554H56.948Z" />
      ),
      basic: {
        dark: (
          <path d="M105.727 475.601c3.06-4.305 6.12-17.298-2.445-31.897-.617-1.053 13.335 4.371 12.956 9.219-2.583 33.045 2.115 52.233 2.115 52.233L56.499 500s-16.907-36.874 0-12.813c12.294 17.495 43.082-2.939 49.228-11.586Zm288.546 0c-3.06-4.305-6.12-17.298 2.445-31.897.617-1.053-13.335 4.371-12.956 9.219 2.583 33.045-2.115 52.233-2.115 52.233L443.501 500s16.907-36.874 0-12.813c-12.294 17.495-43.082-2.939-49.228-11.586ZM250 417.8c29.84-.154 46.892-10.197 56.099-18.949 2.178-3.123 5.786-9.162 5.668-14.752-.151-7.149 4.934 2.046 4.831 2.303 0 0-6.57 16.528-30.148 25.973-15.453 8.779-30.286 9.493-36.45 9.338-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-30.148-25.973-30.148-25.973-.103-.257 4.982-9.452 4.831-2.303-.118 5.59 3.49 11.629 5.668 14.752 9.207 8.752 26.259 18.795 56.099 18.949Z" />
        ),
        darker: (
          <path d="M110.68 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm281.77 0c-1.2-13.01-2.45-22.19-2.45-22.19l-4.48-14.22c1.87 9.69 3.04 22.75 3.81 36.41h3.12Z" />
        ),
      },
    },
    // Body 3B
    base_body_shape_942n5: {
      base: (
        <path d="M56.575 500s-1.594-44.851 13.127-68.286c12.026-19.144 38.227-44.354 104.538-60.066 0 0 10.551-9.017 12.168-5.784 12.125 24.252 29.626 37.871 61.898 38.075.015-.001 3.373-.001 3.388 0 32.272-.204 49.773-13.823 61.898-38.075 1.617-3.233 12.168 5.784 12.168 5.784 66.301 15.787 94.329 42.919 104.538 60.066C444.456 455.494 443.425 500 443.425 500H56.575Z" />
      ),
      basic: {
        dark: (
          <path d="M104.943 475.601c3.061-4.305 6.12-17.298-2.444-31.897-.618-1.053 13.334 4.371 12.955 9.219-2.582 33.045 2.116 52.233 2.116 52.233L55.716 500s-16.908-36.874 0-12.813c12.293 17.495 43.082-2.939 49.227-11.586Zm290.114 0c-3.061-4.305-6.12-17.298 2.444-31.897.618-1.053-13.334 4.371-12.955 9.219 2.582 33.045-2.116 52.233-2.116 52.233L444.284 500s16.908-36.874 0-12.813c-12.293 17.495-43.082-2.939-49.227-11.586Zm-143.821-57.804c29.556-.271 45.957-11.963 55.117-20.67 2.178-3.124 15.16-22.581 15.041-28.17-.151-7.149 4.934 2.046 4.832 2.302 0 0-15.414 31.671-38.993 41.116-15.453 8.779-30.285 9.493-36.449 9.338-.237.006-1.331.006-1.568 0-6.164.155-20.996-.559-36.449-9.338-23.579-9.445-38.993-41.116-38.993-41.116-.102-.256 4.983-9.451 4.832-2.302-.119 5.589 12.863 25.046 15.041 28.17 9.16 8.707 25.561 20.399 55.117 20.67.402-.001 2.072-.001 2.472 0Z" />
        ),
        darker: (
          <path d="M109.896 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm280.208 0c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Z" />
        ),
      },
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path d="M249.09 403.939h1.82c32.272-.204 43.231-10.621 52.09-19.096 2.612-2.499 11.653 4.977 11.653 4.977 19.021 2.487 39.014-.295 58.965 14.607 7.869 5.878 13.021 7.342 18.822 7.401 15.911.161 27.071 5.449 37.553 17.195C448.42 449.671 442.641 500 442.641 500H57.359s-5.779-50.329 12.648-70.977c10.482-11.746 19.19-17.763 37.553-17.195 5.799.179 12.514-2.501 20.379-8.384 16.519-12.357 38.773-11.329 57.408-13.624 0 0 9.041-7.476 11.653-4.977 8.859 8.475 19.818 18.892 52.09 19.096Z" />
      ),
      basic: {
        dark: (
          <path d="M394.273 475.601c-3.06-4.305-6.12-17.298 2.445-31.897.617-1.053-13.335 4.371-12.956 9.219 2.583 33.045-2.115 52.233-2.115 52.233L443.501 500s16.907-36.874 0-12.813c-12.294 17.495-43.082-2.939-49.228-11.586Zm-288.546 0c3.06-4.305 6.12-17.298-2.445-31.897-.617-1.053 13.335 4.371 12.956 9.219-2.583 33.045 2.115 52.233 2.115 52.233L56.499 500s-16.907-36.874 0-12.813c12.294 17.495 43.082-2.939 49.228-11.586ZM250 421.713c-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-29.183-22.137-29.183-22.137-.103-.257 4.982-9.452 4.831-2.303-.118 5.589 2.525 7.793 4.703 10.916 9.207 8.752 26.259 18.795 56.099 18.949 29.84-.154 46.892-10.197 56.099-18.949 2.178-3.123 4.821-5.327 4.703-10.916-.151-7.149 4.934 2.046 4.831 2.303 0 0-5.605 12.692-29.183 22.137-15.453 8.779-30.286 9.493-36.45 9.338Z" />
        ),
        darker: (
          <path d="M389.32 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-278.64 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path d="M56.701 500s-9.267-51.905 9.161-72.554c20.3-22.747 77.897-41.27 118.095-44.681 0 0 7.708-7.958 10.32-5.459 8.858 8.475 21.883 26.429 54.155 26.633h3.136c32.272-.204 45.297-18.158 54.155-26.633 2.612-2.499 10.32 5.459 10.32 5.459 40.198 3.411 97.795 21.934 118.095 44.681 18.428 20.649 9.161 72.554 9.161 72.554H56.701Z" />
      ),
      basic: {
        dark: (
          <path d="M394.418 476.087c-3.06-4.306-6.12-17.299 2.445-31.898.617-1.052-13.334 4.372-12.956 9.22 2.583 33.045-2.115 52.233-2.115 52.233l61.854-5.156s16.907-36.874 0-12.813c-12.294 17.494-43.082-2.939-49.228-11.586Zm-288.546 0c3.06-4.306 6.12-17.299-2.444-31.898-.618-1.052 13.334 4.372 12.955 9.22-2.582 33.045 2.116 52.233 2.116 52.233l-61.854-5.156s-16.908-36.874 0-12.813c12.293 17.494 43.082-2.939 49.227-11.586Zm144.273-57.801c-29.84-.154-46.892-10.197-56.099-18.949-2.178-3.124-5.786-9.163-5.668-14.752.151-7.149-4.933 2.046-4.831 2.302 0 0 6.57 16.529 30.148 25.974 15.453 8.779 30.286 9.493 36.45 9.338 6.164.155 20.997-.559 36.45-9.338 23.578-9.445 30.01-28.282 30.01-28.282.102-.256-4.983-9.451-4.832-2.303.119 5.59-3.351 13.937-5.529 17.061-9.207 8.752-26.259 18.795-56.099 18.949Z" />
        ),
        darker: (
          <path d="M389.465 500.486c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-281.77 0c1.2-13.01 2.45-22.19 2.45-22.19l4.48-14.22c-1.87 9.69-3.04 22.75-3.81 36.41h-3.12Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path d="M248.002 403.939h3.996c32.272-.204 51.335-16.25 63.46-40.502 1.616-3.233 12.167 5.783 12.167 5.783 66.312 15.712 98.162 42.511 108.199 62.769C454.904 470.5 445.185 500 445.185 500H54.815s-9.719-29.5 9.361-68.011c10.037-20.258 41.887-47.057 108.199-62.769 0 0 10.551-9.016 12.167-5.783 12.125 24.252 31.188 40.298 63.46 40.502Z" />
      ),
      basic: {
        dark: (
          <path d="M104.943 475.601c3.061-4.305 6.12-17.298-2.444-31.897-.618-1.053 13.334 4.371 12.955 9.219-2.582 33.045 2.116 52.233 2.116 52.233L55.716 500s-16.908-36.874 0-12.813c12.293 17.495 43.082-2.939 49.227-11.586Zm290.114 0c-3.061-4.305-6.12-17.298 2.444-31.897.618-1.053-13.334 4.371-12.955 9.219 2.582 33.045-2.116 52.233-2.116 52.233L444.284 500s16.908-36.874 0-12.813c-12.293 17.495-43.082-2.939-49.227-11.586Zm-143.821-57.804c29.556-.271 45.957-11.963 55.117-20.67 2.178-3.124 16.683-25.152 16.564-30.741-.151-7.149 4.934 2.046 4.832 2.302 0 0-16.937 34.242-40.516 43.687-15.453 8.779-30.285 9.493-36.449 9.338-.237.006-1.331.006-1.568 0-6.164.155-20.996-.559-36.449-9.338-23.579-9.445-40.554-43.544-40.554-43.544-.103-.256 4.982-9.451 4.831-2.302-.118 5.589 14.425 27.474 16.603 30.598 9.16 8.707 25.561 20.399 55.117 20.67.402-.001 2.072-.001 2.472 0Z" />
        ),
        darker: (
          <path d="M109.896 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm280.208 0c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      base: (
        <path d="M50.341 500s-4.026-49.171 13.757-70.377c9.279-11.065 18.287-19.493 38.512-20.874 5.788-.395 13.987-3.909 22.631-8.696 18.047-9.994 35.522-9.733 54.156-12.028 0 0 9.477-7.863 11.654-4.977 8.933 11.84 25.608 20.687 57.879 20.891h2.14c32.271-.204 48.946-9.051 57.879-20.891 2.177-2.886 11.654 4.977 11.654 4.977 18.634 2.295 36.109 2.034 54.156 12.028 8.644 4.787 16.843 8.301 22.631 8.696 20.225 1.381 29.233 9.809 38.512 20.874C453.685 450.829 449.659 500 449.659 500H50.341Z" />
      ),
      basic: {
        dark: (
          <path d="m433.922 493.57-.212.047c-1.272.276-2.592.415-3.945.435l-.226.002c-13.858.068-31.008-12.237-35.425-18.453-1.602-2.253-3.204-6.887-3.138-12.886l.004-.269c.103-5.407 1.563-11.898 5.578-18.742.02-.033.025-.06.016-.08-.251-.623-12.856 4.41-12.977 9.037-.003.088-.001.175.006.262.22 2.819.388 5.537.509 8.148.816 17.497-.41 30.329-1.446 37.5-.624 4.319-1.179 6.585-1.179 6.585L443.341 500s7.992-17.429 7.492-20.503c-.227-1.397-2.208.171-7.492 7.69-2.497 3.553-5.756 5.542-9.419 6.383ZM48.834 479.608c.144-1.583 2.091-.127 7.506 7.579 3.457 4.92 8.377 6.84 13.802 6.867h.226c2.589-.013 5.289-.452 7.999-1.197.529-.146 1.059-.303 1.59-.472 1.04-.331 2.079-.703 3.109-1.111 1.423-.563 2.831-1.193 4.209-1.871l.143-.071.453-.226c.15-.077.3-.154.45-.23l.448-.234.083-.044c.962-.508 1.906-1.037 2.826-1.581.193-.114.385-.229.577-.345l.411-.251c.139-.085.278-.171.417-.257l.412-.259c.885-.562 1.743-1.134 2.567-1.71 3.124-2.182 5.77-4.423 7.635-6.375l.224-.237c.661-.708 1.215-1.374 1.647-1.982 1.086-1.529 2.173-4.153 2.739-7.556 1.028-6.179.339-14.926-5.184-24.341-.035-.06-.023-.099.032-.118l.016-.006.028-.005c1.198-.164 13.232 4.841 12.879 9.348-.034.443-.067.883-.099 1.318-.15 2.051-.272 4.046-.369 5.985-.117 2.342-.197 4.601-.246 6.775-.027 1.179-.044 2.333-.053 3.463-.031 3.822.035 7.356.165 10.591.048 1.215.106 2.387.171 3.516.08 1.388.171 2.711.27 3.967.083 1.056.171 2.064.264 3.025l.068.694.057.553c.172 1.659.353 3.159.533 4.495.691 5.14 1.355 7.851 1.355 7.851L56.34 500s-7.722-16.84-7.51-20.333l.004-.059ZM249.841 417.8c29.84-.154 46.891-10.197 56.098-18.949 2.689-2.555 9.502-7.843 9.384-13.432-.151-7.149 4.934 2.046 4.831 2.302 0 0-10.285 15.209-33.863 24.654-15.453 8.779-30.286 9.493-36.45 9.338-6.164.155-20.997-.559-36.45-9.338-23.578-9.445-33.864-24.654-33.864-24.654-.102-.256 4.983-9.451 4.832-2.302-.119 5.589 6.695 10.877 9.383 13.432 9.207 8.752 26.259 18.795 56.099 18.949Z" />
        ),
        darker: (
          <path d="M389.161 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-278.64 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Z" />
        ),
      },
    },
  },
};

export const TShirtsPack1 = {
  tshirt_47yZcnwC6w,
};
