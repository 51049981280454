import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Mouth from "character-creator/components/head/face/mouth/CharacterMouth";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Mouth preview={true} mouth={component} previewId={previewId} />
    </>
  );
};

export const previewViewbox = "210 270 80 80";

/**
const mouthTemplate = {
  name: "Mouth 1",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  bite: "",
  shape: {
    teeth: {
      upper: {
        shape: {
          base: <></>
        }
      },
      lower: {
        shape: {
          base: <></>
        }
      }
    },
    lips: {
      upper: {
        shape: {
          base: <></>
        }
      },
      lower: {
        shape: {
          base: <></>
        }
      }
    },
    tongue: {
      inner: {
        shape: {
          base: <></>
        }
      },
      outer: {
        shape: {
          base: <></>
        }
      }
    }
  }
}
**/
