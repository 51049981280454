import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterHairTop from "./CharacterHairTop";
import CharacterBody from "character-creator/components/body/CharacterBody";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
      <CharacterHairTop preview={true} previewId={previewId} hair={component} />
    </>
  );
};

export const viewbox = "100 50 300 300";
export const viewboxWide = "60 50 380 380";
