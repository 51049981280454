// Components
import { allColours } from "character-creator/colours/colourSchemes";
import { WhiskersShapeList } from "character-creator/components/head/face/extras/whiskers/WhiskersShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const whiskersPath = "base.head.face.extras.whiskers.";

const WhiskersPage = {
  id: "whiskersPage",
  name: "Whiskers",
  description: "",
  controls: [
    {
      id: "whiskersShapeGroup",
      name: "Shape",
      description: "",
      dataAttribute: [whiskersPath + "shape"],
      previewType: "preview",
      options: WhiskersShapeList,
      colourControls: [
        {
          id: "WhiskersColour",
          name: "Colour",
          description: "",
          dataAttribute: [whiskersPath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "whiskersTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [whiskersPath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "whiskersTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [whiskersPath + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default WhiskersPage;
