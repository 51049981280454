import CharacterHead from "character-creator/components/head/CharacterHead";
import React from "react";
import Whiskers from "./CharacterWhiskers";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Whiskers preview={true} previewId={previewId} whiskers={component} />
    </>
  );
};

export const previewViewbox = "250 225 120 120";
