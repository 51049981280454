import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import BeardList from "./BeardList";

function mapStateToProps(state, ownProps) {
  const beardConfig = state.config.base.head.face.beard;
  let beardObject = null;
  if (ownProps.preview && ownProps.beard) {
    beardObject = ownProps.beard;
  } else if (beardConfig.shape !== "0") {
    beardObject = BeardList[beardConfig.shape];
  }
  const colour = beardConfig.colour
    ? beardConfig.colour
    : state.config.base.hair.colour;

  return {
    colour: colour,
    Beard: beardObject,
  };
}

const Beard = (props) => {
  const { colour, Beard, previewId } = props;

  if (Beard) {
    return (
      <g id={previewId ? `g-beard~${previewId}` : "g-beard"}>
        <Component
          id={previewId ? `beard~${previewId}` : "beard"}
          component={Beard}
          colour={colour}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Beard);
