import React from "react";
import { get, cloneDeep, random } from "lodash";
import Control from "./elements/controls/Control";

/********************************************************
Transforms
 ********************************************************/
export const buildTransform = (transformConfig) => {
  const scale = transformConfig.scale ? `scale(${transformConfig.scale})` : "";
  // const rotate = transformConfig.rotate ? `rotate(${transformConfig.rotate})` : "";
  const translate =
    transformConfig.x != null
      ? `translate(${transformConfig.x ? transformConfig.x : 0}, ${
          transformConfig.y ? transformConfig.y : 0
        })`
      : "";

  return {
    position: translate.concat(" ", scale),
    opacity: transformConfig.opacity,
  };
};

export const translateTransformObject = (transform) => {
  let transformAttribute = (transform.position || transform.opacity) && {
    transform: transform.position,
    opacity: transform.opacity ? transform.opacity : 1,
  };

  return transformAttribute;
};

// const calculateTransformOpposite = (transformConfig) => {};

/********************************************************
  Dependencies! Does this section/control group depend on any other controls? If so, does it meet the conditions? E.g. can't have pupils without an iris, or an iris without an eye!

  If a value list is provided, the value for the given dependency attribute must be in that list, otherwise assume it has to be != "" and != "0"

  dependencies: [
    {
      description: "short description here for me",
      attribute: eyeBasePath + "left.shape",
    },
    {
      description: "short description here for me",
      attribute: hairSidePath + "left.shape",
      value: ["value1", "value2", "value3"]
    }
  ]
  */
export const checkDependencies = (controlDependencies, config) => {
  // Check each dependency
  let dependenciesPassed = controlDependencies.find((dependency) => {
    let thisDependencyPassed = false;

    //////////////////////////////////////////
    // If dependent on an attribute
    //////////////////////////////////////////
    if (dependency.attribute) {
      const dependencyAttributeList = dependency.attribute;

      // Check every attribute to see if any are valid
      thisDependencyPassed = dependencyAttributeList.find((attribute) => {
        const attributeValue = get(config, attribute);
        let thisAttributeValid = dependency.value
          ? dependency.value.includes(attributeValue)
          : !!attributeValue && attributeValue !== "" && attributeValue !== "0";

        return thisAttributeValid;
      });
    }

    return thisDependencyPassed;
  });
  return dependenciesPassed;
};

/********************************************************
Variations

Considered having these as a sort of dependency but would be better if they were a child menu option like colours/transforms?

Variations are better than blanket "dependent" options, because they may only be available for certain components, such as horn texture/breakages (breakages may be difficult/impossible for some horns), glasses (only some styles have bridges/sides), but also keeps main options a bit leaner

// TODO: Use these for hair partings as originally intended?

********************************************************/

// If "variationAttribute" = something AND chosen option has variations then show variations menu. Also used to display whether a component has variations on its button
export const hasVariation = (componentObject, variationName) => {
  return (
    true &&
    componentObject.variations &&
    componentObject.variations[variationName]
  );
};

/********************************************************
Randomising
 ********************************************************/

export const getRandomFromList = (list) => {
  return Object.values(list)[random(Object.keys(list).length)];
};

/********************************************************
Controls
 ********************************************************/

export const ControlOptionsList = (props) => {
  const { optionList, optionKeys } = props;
  const list = optionList.map((controlOption, index) => (
    <Control
      key={index}
      controlId={optionKeys[index]}
      controlOption={controlOption}
      {...props}
    />
  ));
  return <>{list}</>;
};

/********************************************************
Custom colours
 ********************************************************/

// If newColour already in recentColours, return null, otherwise return new recent colours list
export const updateRecentColours = (currentList, newColour) => {
  let newList = cloneDeep(currentList);

  let addToList = true;
  if (newList[newColour.id]) {
    addToList = false;
  }
  if (addToList) {
    newList[newColour.id] = newColour;
    if (Object.keys(newList).length >= 6) {
      delete newList[Object.keys(newList)[0]];
    }
  }

  return newList;
};

// Update custom colour palettes
export const updateCustomPalettes = (currentList, newColour) => {
  let newList = cloneDeep(currentList);

  let addToList = true;
  if (newList[newColour.id]) {
    addToList = false;
  }
  if (addToList) {
    newList[newColour.id] = newColour;
  }

  return newList;
};

/********************************************************
Controls
 ********************************************************/

export const SymmetryControl = (props) => {
  const { controlId, symmetryValue, symmetryAttribute, updateSymmetry } = props;

  return (
    <label
      htmlFor={controlId + "-symmetryToggle"}
      className="button-secondary button-toggle"
      data-checked={!!symmetryValue}
      role="button"
      title={!!symmetryValue ? `Symmetry on` : `Symmetry off`}
    >
      {!!symmetryValue ? `Symmetry on` : `Symmetry off`}

      <input
        id={controlId + "-symmetryToggle"}
        type="checkbox"
        role="switch"
        checked={!!symmetryValue}
        onChange={($this) =>
          updateSymmetry(symmetryAttribute, $this.target.checked)
        }
      />
    </label>
  );
};
