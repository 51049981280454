import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import PaletteEditor from "./PaletteEditor";
import { ControlOptionsList } from "character-creator/menu/menu-helper";

const mapStateToProps = (state, ownProps) => {
  const dataAttributeValue = get(
    state.config,
    ownProps.control.dataAttribute[0]
  );
  const selectedOption = ownProps.control.attributeType
    ? dataAttributeValue.id
    : dataAttributeValue;
  return {
    dataAttributeValue: dataAttributeValue,
    selectedOption: selectedOption ? selectedOption : ownProps.selectedOption,
    recentColours: state.config.recentColours,
    customColours: state.config.customPalettes,
    // items: ownProps.children
  };
};

const ColourControls = (props) => {
  const { control, selectedOption, recentColours, customColours } = props;

  const [showPaletteEditor, togglePaletteEditor] = useState(false);

  // If colour control then custom colours too
  const customColoursControl = ControlOptionsList({
    controlName: control.id,
    dataAttribute: control.dataAttribute,
    selected: selectedOption,
    previewType: control.previewType,
    previewSide: control.previewSide,
    attributeType: control.attributeType,
    optionKeys: Object.keys(customColours),
    optionList: Object.values(customColours),
  });

  // Custom colours section
  const CustomColoursSection = (
    <>
      <div className="header">
        <h4>Custom</h4>
        <div className="actions">
          <button
            className="button-actions"
            onClick={() => togglePaletteEditor(true)}
          >
            Add new
          </button>
        </div>
      </div>

      <ul>{customColoursControl}</ul>
      {showPaletteEditor && (
        <PaletteEditor onCloseAction={togglePaletteEditor} />
      )}
    </>
  );

  // If colour control then recent colours too
  const recentColoursControl = ControlOptionsList({
    controlName: control.id,
    dataAttribute: control.dataAttribute,
    selected: selectedOption,
    previewType: control.previewType,
    previewSide: control.previewSide,
    attributeType: control.attributeType,
    optionKeys: Object.keys(recentColours),
    optionList: Object.values(recentColours),
  });

  // Recent colours section
  const RecentColoursSection = Object.keys(recentColours).length > 0 && (
    <>
      <div className="header">
        <h4>Recent</h4>
      </div>

      <ul>{recentColoursControl}</ul>
    </>
  );

  return (
    <>
      {RecentColoursSection}
      {CustomColoursSection}
      <div className="header">
        <h4>Premade</h4>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(ColourControls);
