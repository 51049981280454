import React from "react";
import { connect } from "react-redux";
import EarShapeList from "character-creator/components/head/ears/EarShapeList";
import Component from "character-creator/components/Component";
import EarAccessoriesBackLayers from "character-creator/components/clothing/Accessories/Ears/EarAccessoriesBackLayers";
import EarAccessoriesFrontLayers from "character-creator/components/clothing/Accessories/Ears/EarAccessoriesFrontLayers";

function mapStateToProps(state, ownProps) {
  // Left
  const earConfigLeft = state.config.base.head.ears.left;

  let earObjectLeft = null;
  if (ownProps.preview && ownProps.ears.shape.left) {
    earObjectLeft = ownProps.ears.shape.left;
  } else if (earConfigLeft.shape !== "0") {
    earObjectLeft = EarShapeList[earConfigLeft.shape].shape.left;
  }

  const coloursLeft = earConfigLeft.colour
    ? earConfigLeft.colour
    : state.config.base.skintone;

  // Right
  const earConfigRight = state.config.base.head.ears.right;

  let earObjectRight = null;
  if (ownProps.preview && ownProps.ears.shape.right) {
    earObjectRight = ownProps.ears.shape.right;
  } else if (earConfigRight.shape !== "0") {
    earObjectRight = EarShapeList[earConfigRight.shape].shape.right;
  }

  const coloursRight = earConfigRight.colour
    ? earConfigRight.colour
    : state.config.base.skintone;

  return {
    previewId: ownProps.previewId,
    left: {
      shape: earObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: earObjectRight,
      colour: coloursRight,
    },
  };
}

const Ears = (props) => {
  const { left, right, previewId } = props;

  if (left.shape || right.shape) {
    return (
      <g id={previewId ? `g-ears~${previewId}` : "g-ears"}>
        {!previewId && <EarAccessoriesBackLayers />}
        {left.shape && (
          <Component
            id={previewId ? `ear-left~${previewId}` : "ear-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <Component
            id={previewId ? `ear-right~${previewId}` : "ear-right"}
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
        {!previewId && <EarAccessoriesFrontLayers />}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Ears);
