import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import {
  NoseStudsPack1Left,
  NoseStudsPack1Right,
} from "./packs/NoseStudsPack1";

export const NoseAccessoriesListLeft = {
  "0": BlankListOption,
  ...NoseStudsPack1Left,
};

export const NoseAccessoriesListRight = {
  "0": BlankListOption,
  ...NoseStudsPack1Right,
};
