import React from "react";
import PreviewBase, {
  viewbox,
} from "character-creator/components/head/face/eyes/eyelashes/Preview";

const eyelash_shape_9gntb = {
  name: "Eyelash 11",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.2,262.52c-4.24-1.95-7.35-6.16-7.97-10.79c-0.32-2.37-3.1-3.75-5.46-3.43c-2.37,0.32-4.38,1.83-6.27,3.3c3.3-1.33,7.33-0.65,10.02,1.69c-3.64-0.72-7.62,1.75-8.59,5.33c2.98-2.8,8.25-2.6,11.02,0.4c-2.92-0.23-5.74,2.38-5.74,5.31C193.1,262.19,197.8,261.57,202.2,262.52z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.8,262.52c4.24-1.95,7.35-6.16,7.97-10.79c0.32-2.37,3.1-3.75,5.46-3.43c2.37,0.32,4.38,1.83,6.27,3.3c-3.3-1.33-7.33-0.65-10.02,1.69c3.64-0.72,7.62,1.75,8.59,5.33c-2.98-2.8-8.25-2.6-11.02,0.4c2.92-0.23,5.74,2.38,5.74,5.31C306.9,262.19,302.2,261.57,297.8,262.52z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.95,260.52c-4.24-1.95-7.35-6.16-7.97-10.79c-0.32-2.37-3.1-3.75-5.46-3.43c-2.37,0.32-4.38,1.83-6.27,3.3c3.3-1.33,7.33-0.65,10.02,1.69c-3.64-0.72-7.62,1.75-8.59,5.33c2.98-2.8,8.25-2.6,11.02,0.4c-2.92-0.23-5.74,2.38-5.74,5.31C190.85,260.19,195.55,259.57,199.95,260.52z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.05,260.52c4.24-1.95,7.35-6.16,7.97-10.79c0.32-2.37,3.1-3.75,5.46-3.43c2.37,0.32,4.38,1.83,6.27,3.3c-3.3-1.33-7.33-0.65-10.02,1.69c3.64-0.72,7.62,1.75,8.59,5.33c-2.98-2.8-8.25-2.6-11.02,0.4c2.92-0.23,5.74,2.38,5.74,5.31C309.15,260.19,304.45,259.57,300.05,260.52z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M195.18,249.47c-2.44-4.19-3.75-6.27-3.62-10.94c0.07-2.39-2.45-4.2-4.83-4.27c-2.39-0.07-4.62,1.1-6.72,2.24c3.48-0.78,7.34,0.54,9.61,3.29c-3.47-1.3-7.8,0.49-9.34,3.86c3.4-2.28,8.56-1.23,10.81,2.19c-2.84-0.7-6.05,1.41-6.53,4.3C188.75,248.67,190.99,247.82,195.18,249.47z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M304.82,249.47c2.44-4.19,3.75-6.27,3.62-10.94c-0.07-2.39,2.45-4.2,4.83-4.27c2.39-0.07,4.62,1.1,6.72,2.24c-3.48-0.78-7.34,0.54-9.61,3.29c3.47-1.3,7.8,0.49,9.34,3.86c-3.4-2.28-8.56-1.23-10.81,2.19c2.84-0.7,6.05,1.41,6.53,4.3C311.25,248.67,309.01,247.82,304.82,249.47z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M205.91,262.09c-5.18-0.14-9.59-0.58-13.01-3.76c-1.75-1.63-4.77-0.92-6.39,0.83s-2.21,4.2-2.74,6.53c1.7-3.13,5.24-5.17,8.81-5.06c-3.27,1.76-4.77,6.2-3.24,9.58c0.52-4.05,4.71-7.26,8.76-6.7c-2.4,1.68-2.92,5.49-1.06,7.75C198.68,267.12,201.91,264.15,205.91,262.09z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M294.09,262.09c5.18-0.14,9.59-0.58,13.01-3.76c1.75-1.63,4.77-0.92,6.39,0.83c1.62,1.75,2.21,4.2,2.74,6.53c-1.7-3.13-5.24-5.17-8.81-5.06c3.27,1.76,4.77,6.2,3.24,9.58c-0.52-4.05-4.71-7.26-8.76-6.7c2.4,1.68,2.92,5.49,1.06,7.75C301.32,267.12,298.09,264.15,294.09,262.09z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.29,260.5c-4.37-2.78-7.93-5.43-9.23-9.92c-0.67-2.29-3.62-3.25-5.91-2.58c-2.29,0.67-4.06,2.46-5.71,4.19c3.07-1.81,7.16-1.73,10.16,0.19c-3.7-0.17-7.28,2.86-7.71,6.54c2.53-3.21,7.77-3.8,10.96-1.24c-2.92,0.2-5.33,3.2-4.89,6.1C192.5,261.1,196.8,260.21,201.29,260.5z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.71,260.5c4.37-2.78,7.93-5.43,9.23-9.92c0.67-2.29,3.62-3.25,5.91-2.58c2.29,0.67,4.06,2.46,5.71,4.19c-3.07-1.81-7.16-1.73-10.16,0.19c3.7-0.17,7.28,2.86,7.71,6.54c-2.53-3.21-7.77-3.8-10.96-1.24c2.92,0.2,5.33,3.2,4.89,6.1C307.5,261.1,303.2,260.21,298.71,260.5z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.17,265.7c-4.38-1.6-7.83-5.55-8.82-10.11c-0.51-2.33-3.39-3.48-5.72-2.97c-2.33,0.51-4.21,2.18-5.98,3.79c3.19-1.6,7.26-1.25,10.12,0.87c-3.68-0.42-7.45,2.36-8.13,6.01c2.75-3.03,8.01-3.26,11.02-0.49c-2.93,0-5.53,2.84-5.29,5.75C193.07,266.11,197.71,265.11,202.17,265.7z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.83,265.7c4.38-1.6,7.83-5.55,8.82-10.11c0.51-2.33,3.39-3.48,5.72-2.97s4.21,2.18,5.98,3.79c-3.19-1.6-7.26-1.25-10.12,0.87c3.68-0.42,7.45,2.36,8.13,6.01c-2.75-3.03-8.01-3.26-11.02-0.49c2.93,0,5.53,2.84,5.29,5.75C306.93,266.11,302.29,265.11,297.83,265.7z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.2,263.02c-4.24-1.95-7.35-6.16-7.97-10.79c-0.32-2.37-3.1-3.75-5.46-3.43c-2.37,0.32-4.38,1.83-6.27,3.3c3.3-1.33,7.33-0.65,10.02,1.69c-3.64-0.72-7.62,1.75-8.59,5.33c2.98-2.8,8.25-2.6,11.02,0.4c-2.92-0.23-5.74,2.38-5.74,5.31C192.1,262.69,196.8,262.07,201.2,263.02z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.8,263.02c4.24-1.95,7.35-6.16,7.97-10.79c0.32-2.37,3.1-3.75,5.46-3.43c2.37,0.32,4.38,1.83,6.27,3.3c-3.3-1.33-7.33-0.65-10.02,1.69c3.64-0.72,7.62,1.75,8.59,5.33c-2.98-2.8-8.25-2.6-11.02,0.4c2.92-0.23,5.74,2.38,5.74,5.31C307.9,262.69,303.2,262.07,298.8,263.02z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M203.47,257.86c-4.65-0.42-8.99-3.34-11.13-7.49c-1.09-2.12-4.17-2.49-6.29-1.4c-2.12,1.1-3.51,3.19-4.8,5.21c2.67-2.36,6.69-3.07,10.01-1.76c-3.67,0.54-6.6,4.2-6.31,7.9c1.87-3.63,6.9-5.22,10.52-3.31c-2.83,0.76-4.62,4.17-3.63,6.92C194.78,260.6,199,258.44,203.47,257.86z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M296.53,257.86c4.65-0.42,8.99-3.34,11.13-7.49c1.09-2.12,4.17-2.49,6.29-1.4s3.51,3.19,4.8,5.21c-2.67-2.36-6.69-3.07-10.01-1.76c3.67,0.54,6.6,4.2,6.31,7.9c-1.87-3.63-6.9-5.22-10.52-3.31c2.83,0.76,4.62,4.17,3.63,6.92C305.22,260.6,301,258.44,296.53,257.86z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M203.47,255.86c-4.65-0.42-8.99-3.34-11.13-7.49c-1.09-2.12-4.17-2.49-6.29-1.4c-2.12,1.1-3.51,3.19-4.8,5.21c2.67-2.36,6.69-3.07,10.01-1.76c-3.67,0.54-6.6,4.2-6.31,7.9c1.87-3.63,6.9-5.22,10.52-3.31c-2.83,0.76-4.62,4.17-3.63,6.92C194.78,258.6,199,256.44,203.47,255.86z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M296.53,255.86c4.65-0.42,8.99-3.34,11.13-7.49c1.09-2.12,4.17-2.49,6.29-1.4s3.51,3.19,4.8,5.21c-2.67-2.36-6.69-3.07-10.01-1.76c3.67,0.54,6.6,4.2,6.31,7.9c-1.87-3.63-6.9-5.22-10.52-3.31c2.83,0.76,4.62,4.17,3.63,6.92C305.22,258.6,301,256.44,296.53,255.86z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M203.46,256.31c-4.66,0.21-9.36-2.11-12.03-5.93c-1.37-1.96-4.47-1.91-6.43-0.54s-3.05,3.64-4.06,5.8c2.33-2.7,6.22-3.94,9.68-3.09c-3.56,1.03-5.97,5.05-5.19,8.67c1.37-3.85,6.14-6.1,9.98-4.69c-2.7,1.13-4.02,4.75-2.67,7.35C195.22,260.2,199.11,257.49,203.46,256.31z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M296.54,256.31c4.66,0.21,9.36-2.11,12.03-5.93c1.37-1.96,4.47-1.91,6.43-0.54c1.96,1.37,3.05,3.64,4.06,5.8c-2.33-2.7-6.22-3.94-9.68-3.09c3.56,1.03,5.97,5.05,5.19,8.67c-1.37-3.85-6.14-6.1-9.98-4.69c2.7,1.13,4.02,4.75,2.67,7.35C304.78,260.2,300.89,257.49,296.54,256.31z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.46,254.31c-6.49-3-8.86-4.11-11.53-7.93c-1.37-1.96-4.47-1.91-6.43-0.54s-3.05,3.64-4.06,5.8c2.33-2.7,6.22-3.94,9.68-3.09c-3.56,1.03-5.97,5.05-5.19,8.67c1.37-3.85,6.14-6.1,9.98-4.69c-2.7,1.13-4.02,4.75-2.67,7.35C194.72,256.2,198.11,255.49,202.46,254.31z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.54,254.31c6.49-3,8.86-4.11,11.53-7.93c1.37-1.96,4.47-1.91,6.43-0.54c1.96,1.37,3.05,3.64,4.06,5.8c-2.33-2.7-6.22-3.94-9.68-3.09c3.56,1.03,5.97,5.05,5.19,8.67c-1.37-3.85-6.14-6.1-9.98-4.69c2.7,1.13,4.02,4.75,2.67,7.35C305.28,256.2,301.89,255.49,297.54,254.31z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.19,255.23c-5.98-3.91-8.17-5.35-10.26-9.52c-1.07-2.14-4.14-2.54-6.28-1.47c-2.14,1.07-3.54,3.16-4.86,5.15c2.69-2.34,6.72-3,10.02-1.66c-3.68,0.5-6.64,4.13-6.4,7.83c1.91-3.61,6.96-5.14,10.55-3.2c-2.84,0.73-4.66,4.12-3.71,6.88C194.26,255.97,197.72,255.76,202.19,255.23z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.81,255.23c5.98-3.91,8.17-5.35,10.26-9.52c1.07-2.14,4.14-2.54,6.28-1.47c2.14,1.07,3.54,3.16,4.86,5.15c-2.69-2.34-6.72-3-10.02-1.66c3.68,0.5,6.64,4.13,6.4,7.83c-1.91-3.61-6.96-5.14-10.55-3.2c2.84,0.73,4.66,4.12,3.71,6.88C305.74,255.97,302.28,255.76,297.81,255.23z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M203.63,255.93c-6.69-2.5-9.14-3.43-12.09-7.04c-1.51-1.85-4.6-1.57-6.45-0.06c-1.85,1.51-2.77,3.86-3.61,6.09c2.12-2.87,5.9-4.4,9.42-3.81c-3.48,1.29-5.58,5.48-4.53,9.04c1.07-3.94,5.66-6.54,9.6-5.43c-2.61,1.33-3.65,5.04-2.11,7.53C196.05,258.38,199.38,257.42,203.63,255.93z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M296.37,255.93c6.69-2.5,9.14-3.43,12.09-7.04c1.51-1.85,4.6-1.57,6.45-0.06c1.85,1.51,2.77,3.86,3.61,6.09c-2.12-2.87-5.9-4.4-9.42-3.81c3.48,1.29,5.58,5.48,4.53,9.04c-1.07-3.94-5.66-6.54-9.6-5.43c2.61,1.33,3.65,5.04,2.11,7.53C303.95,258.38,300.62,257.42,296.37,255.93z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_vmlhi = {
  name: "Eyelash 13",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.78,249.91c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C185.52,250.4,189.9,249.09,193.78,249.91z" />
              <path d="M194.98,254.16c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C188.39,255.06,191.94,253.43,194.98,254.16z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.22,249.91c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C314.48,250.4,310.1,249.09,306.22,249.91z" />
              <path d="M305.02,254.16c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C311.61,255.06,308.06,253.43,305.02,254.16z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.78,249.41c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.52,249.9,187.9,248.59,191.78,249.41z" />
              <path d="M192.98,253.66c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C186.39,254.56,189.94,252.93,192.98,253.66z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.22,249.41c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.48,249.9,312.1,248.59,308.22,249.41z" />
              <path d="M307.02,253.66c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C313.61,254.56,310.06,252.93,307.02,253.66z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.53,238.16c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.27,238.65,187.65,237.34,191.53,238.16z" />
              <path d="M191.48,241.66c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C184.89,242.56,188.44,240.93,191.48,241.66z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.47,238.16c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.73,238.65,312.35,237.34,308.47,238.16z" />
              <path d="M308.52,241.66c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C315.11,242.56,311.56,240.93,308.52,241.66z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.78,258.66c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C184.52,259.15,188.9,257.84,192.78,258.66z" />
              <path d="M195.23,260.16c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C188.64,261.06,192.19,259.43,195.23,260.16z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.22,258.66c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59s-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C315.48,259.15,311.1,257.84,307.22,258.66z" />
              <path d="M304.77,260.16c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C311.36,261.06,307.81,259.43,304.77,260.16z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.53,248.41c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.27,248.9,187.65,247.59,191.53,248.41z" />
              <path d="M191.73,250.66c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C185.14,251.56,188.69,249.93,191.73,250.66z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.47,248.41c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.73,248.9,312.35,247.59,308.47,248.41z" />
              <path d="M308.27,250.66c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C314.86,251.56,311.31,249.93,308.27,250.66z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.78,255.66c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C184.52,256.15,188.9,254.84,192.78,255.66z" />
              <path d="M194.23,259.41c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C187.64,260.31,191.19,258.68,194.23,259.41z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.22,255.66c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59s-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C315.48,256.15,311.1,254.84,307.22,255.66z" />
              <path d="M305.77,259.41c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C312.36,260.31,308.81,258.68,305.77,259.41z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.78,250.91c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C184.52,251.4,188.9,250.09,192.78,250.91z" />
              <path d="M194.23,254.66c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C187.64,255.56,191.19,253.93,194.23,254.66z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.22,250.91c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C315.48,251.4,311.1,250.09,307.22,250.91z" />
              <path d="M305.77,254.66c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C312.36,255.56,308.81,253.93,305.77,254.66z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.03,250.16c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.77,250.65,188.15,249.34,192.03,250.16z" />
              <path d="M194.23,253.41c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C187.64,254.31,191.19,252.68,194.23,253.41z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.97,250.16c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.23,250.65,311.85,249.34,307.97,250.16z" />
              <path d="M305.77,253.41c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C312.36,254.31,308.81,252.68,305.77,253.41z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.28,249.16c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C184.02,249.65,188.4,248.34,192.28,249.16z" />
              <path d="M193.73,251.41c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C187.14,252.31,190.69,250.68,193.73,251.41z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.72,249.16c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C315.98,249.65,311.6,248.34,307.72,249.16z" />
              <path d="M306.27,251.41c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C312.86,252.31,309.31,250.68,306.27,251.41z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.78,250.66c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.52,251.15,187.9,249.84,191.78,250.66z" />
              <path d="M193.23,252.91c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C186.64,253.81,190.19,252.18,193.23,252.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.22,250.66c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.48,251.15,312.1,249.84,308.22,250.66z" />
              <path d="M306.77,252.91c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C313.36,253.81,309.81,252.18,306.77,252.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.78,246.66c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C182.52,247.15,186.9,245.84,190.78,246.66z" />
              <path d="M192.48,248.16c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C185.89,249.06,189.44,247.43,192.48,248.16z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.22,246.66c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C317.48,247.15,313.1,245.84,309.22,246.66z" />
              <path d="M307.52,248.16c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C314.11,249.06,310.56,247.43,307.52,248.16z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.23,247.16c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C185.64,248.06,189.19,246.43,192.23,247.16z" />
              <path d="M191.53,245.16c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.27,245.65,187.65,244.34,191.53,245.16z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.77,247.16c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C314.36,248.06,310.81,246.43,307.77,247.16z" />
              <path d="M308.47,245.16c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.73,245.65,312.35,244.34,308.47,245.16z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.23,250.91c-1.4-0.99-3.32-1.2-4.91-0.53s-2.78,2.18-3.06,3.87c-0.9-0.47-2.17,0.23-2.26,1.25c-0.09,1.01,1.05,1.92,2.02,1.61c0.97-0.31,1.37-1.7,0.72-2.48C186.64,251.81,190.19,250.18,193.23,250.91z" />
              <path d="M191.78,248.91c-3.71-2.31-9.15-1.17-11.36,2.62c-0.97-1.01-2.94-0.68-3.53,0.59c-0.59,1.27,0.42,2.99,1.82,3.08s2.64-1.47,2.22-2.81C183.52,249.4,187.9,248.09,191.78,248.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.77,250.91c1.4-0.99,3.32-1.2,4.91-0.53s2.78,2.18,3.06,3.87c0.9-0.47,2.17,0.23,2.26,1.25c0.09,1.01-1.05,1.92-2.02,1.61c-0.97-0.31-1.37-1.7-0.72-2.48C313.36,251.81,309.81,250.18,306.77,250.91z" />
              <path d="M308.22,248.91c3.71-2.31,9.15-1.17,11.36,2.62c0.97-1.01,2.94-0.68,3.53,0.59c0.59,1.27-0.42,2.99-1.82,3.08s-2.64-1.47-2.22-2.81C316.48,249.4,312.1,248.09,308.22,248.91z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_ealwo = {
  name: "Eyelash 14",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.67,249.5c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C191.5,248.8,192.54,249.24,193.67,249.5z" />
              <path d="M194.87,253.82c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96c0.49,0.08,0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C193.36,253.72,194.2,253.92,194.87,253.82z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.33,249.5c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C308.5,248.8,307.46,249.24,306.33,249.5z" />
              <path d="M305.13,253.82c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96c-0.49,0.08-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C306.64,253.72,305.8,253.92,305.13,253.82z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.67,249.5c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C189.5,248.8,190.54,249.24,191.67,249.5z" />
              <path d="M193.37,253.32c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C191.86,253.22,192.7,253.42,193.37,253.32z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.33,249.5c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310.5,248.8,309.46,249.24,308.33,249.5z" />
              <path d="M306.63,253.32c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C308.14,253.22,307.3,253.42,306.63,253.32z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.42,238.75c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C189.25,238.05,190.29,238.49,191.42,238.75z" />
              <path d="M192.37,243.32c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C190.86,243.22,191.7,243.42,192.37,243.32z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.58,238.75c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310.75,238.05,309.71,238.49,308.58,238.75z" />
              <path d="M307.63,243.32c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28c0.45-0.71,0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C309.14,243.22,308.3,243.42,307.63,243.32z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.92,258.25c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45s-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52s-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C190.75,257.55,191.79,257.99,192.92,258.25z" />
              <path d="M195.62,260.57c-0.98-0.6-2.14-0.95-3.27-0.79c-1.13,0.16-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96c0.49,0.08,0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91S194.95,260.67,195.62,260.57z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.08,258.25c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C309.25,257.55,308.21,257.99,307.08,258.25z" />
              <path d="M304.38,260.57c0.98-0.6,2.14-0.95,3.27-0.79c1.13,0.16,2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12s0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96c-0.49,0.08-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91S305.05,260.67,304.38,260.57z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.42,250c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C189.25,249.3,190.29,249.74,191.42,250z" />
              <path d="M193.12,253.57c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C191.61,253.47,192.45,253.67,193.12,253.57z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.58,250c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310.75,249.3,309.71,249.74,308.58,250z" />
              <path d="M306.88,253.57c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C308.39,253.47,307.55,253.67,306.88,253.57z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.92,255c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45s-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52s-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C190.75,254.3,191.79,254.74,192.92,255z" />
              <path d="M194.12,259.32c-0.98-0.6-2.14-0.95-3.27-0.79c-1.13,0.16-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96c0.49,0.08,0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91S193.45,259.42,194.12,259.32z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.08,255c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C309.25,254.3,308.21,254.74,307.08,255z" />
              <path d="M305.88,259.32c0.98-0.6,2.14-0.95,3.27-0.79c1.13,0.16,2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12s0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96c-0.49,0.08-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91S306.55,259.42,305.88,259.32z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.67,250.25c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C190.5,249.55,191.54,249.99,192.67,250.25z" />
              <path d="M193.87,254.57c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96c0.49,0.08,0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C192.36,254.47,193.2,254.67,193.87,254.57z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.33,250.25c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C309.5,249.55,308.46,249.99,307.33,250.25z" />
              <path d="M306.13,254.57c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12s0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96c-0.49,0.08-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C307.64,254.47,306.8,254.67,306.13,254.57z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.92,249.75c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C189.75,249.05,190.79,249.49,191.92,249.75z" />
              <path d="M193.87,253.07c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C192.36,252.97,193.2,253.17,193.87,253.07z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.08,249.75c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310.25,249.05,309.21,249.49,308.08,249.75z" />
              <path d="M306.13,253.07c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C307.64,252.97,306.8,253.17,306.13,253.07z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.42,249c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C190.25,248.3,191.29,248.74,192.42,249z" />
              <path d="M194.62,251.82c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C193.11,251.72,193.95,251.92,194.62,251.82z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.58,249c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C309.75,248.3,308.71,248.74,307.58,249z" />
              <path d="M305.38,251.82c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28c0.45-0.71,0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C306.89,251.72,306.05,251.92,305.38,251.82z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.17,251.25c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C190,250.55,191.04,250.99,192.17,251.25z" />
              <path d="M194.37,254.07c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96c0.49,0.08,0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C192.86,253.97,193.7,254.17,194.37,254.07z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.83,251.25c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310,250.55,308.96,250.99,307.83,251.25z" />
              <path d="M305.63,254.07c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96c-0.49,0.08-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28s0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C307.14,253.97,306.3,254.17,305.63,254.07z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.92,246.75c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C188.75,246.05,189.79,246.49,190.92,246.75z" />
              <path d="M193.37,249.07c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C191.86,248.97,192.7,249.17,193.37,249.07z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.08,246.75c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C311.25,246.05,310.21,246.49,309.08,246.75z" />
              <path d="M306.63,249.07c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28c0.45-0.71,0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C308.14,248.97,307.3,249.17,306.63,249.07z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.42,245.5c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C189.25,244.8,190.29,245.24,191.42,245.5z" />
              <path d="M193.37,248.82c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C191.86,248.72,192.7,248.92,193.37,248.82z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.58,245.5c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310.75,244.8,309.71,245.24,308.58,245.5z" />
              <path d="M306.63,248.82c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28c0.45-0.71,0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C308.14,248.72,307.3,248.92,306.63,248.82z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M194.62,252.07c-0.98-0.6-2.14-0.95-3.27-0.79s-2.22,0.9-2.6,1.98c-0.39,1.08,0.07,2.45,1.11,2.93c1.04,0.48,2.49-0.2,2.63-1.34c0.08-0.67-0.64-1.29-1.28-1.12c-0.65,0.17-0.96,1.08-0.56,1.61c-0.19-0.46,0.28-1.04,0.77-0.96s0.74,0.79,0.4,1.16c-0.56,0.63-1.72,0.43-2.18-0.28c-0.45-0.71-0.25-1.72,0.33-2.34c0.58-0.62,1.45-0.89,2.3-0.91C193.11,251.97,193.95,252.17,194.62,252.07z" />
              <path d="M191.92,249.5c-0.93-0.85-2.04-1.53-3.27-1.79c-1.23-0.27-2.58-0.09-3.62,0.61c-1.04,0.7-1.73,1.96-1.61,3.21s1.13,2.41,2.38,2.56c1.25,0.16,2.57-0.82,2.66-2.07c0.06-0.73-0.58-1.43-1.31-1.45c-0.73-0.02-1.4,0.64-1.39,1.37c0.14-0.62,0.94-0.98,1.5-0.68c0.56,0.3,0.7,1.16,0.27,1.63c-0.76,0.81-2.29,0.46-2.8-0.52c-0.51-0.99-0.1-2.28,0.76-2.98s2.05-0.88,3.15-0.74C189.75,248.8,190.79,249.24,191.92,249.5z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M305.38,252.07c0.98-0.6,2.14-0.95,3.27-0.79s2.22,0.9,2.6,1.98c0.39,1.08-0.07,2.45-1.11,2.93c-1.04,0.48-2.49-0.2-2.63-1.34c-0.08-0.67,0.64-1.29,1.28-1.12c0.65,0.17,0.96,1.08,0.56,1.61c0.19-0.46-0.28-1.04-0.77-0.96s-0.74,0.79-0.4,1.16c0.56,0.63,1.72,0.43,2.18-0.28c0.45-0.71,0.25-1.72-0.33-2.34c-0.58-0.62-1.45-0.89-2.3-0.91C306.89,251.97,306.05,252.17,305.38,252.07z" />
              <path d="M308.08,249.5c0.93-0.85,2.04-1.53,3.27-1.79c1.23-0.27,2.58-0.09,3.62,0.61c1.04,0.7,1.73,1.96,1.61,3.21s-1.13,2.41-2.38,2.56c-1.25,0.16-2.57-0.82-2.66-2.07c-0.06-0.73,0.58-1.43,1.31-1.45c0.73-0.02,1.4,0.64,1.39,1.37c-0.14-0.62-0.94-0.98-1.5-0.68c-0.56,0.3-0.7,1.16-0.27,1.63c0.76,0.81,2.29,0.46,2.8-0.52c0.51-0.99,0.1-2.28-0.76-2.98s-2.05-0.88-3.15-0.74C310.25,248.8,309.21,249.24,308.08,249.5z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_gnask = {
  name: "Eyelash 15",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M188.21,256.7c1.36-0.26,2.52-1.86,2.68-3.69c-3.05,3.17-6.29,6.23-10.02,7.52c-3.73,1.29-8.07,0.5-10.77-3.21c2.68,1.54,5.72,1.95,8.57,1.16c-1.5,0.15-3.03-0.62-4.09-2.05c3.72,1.53,8.6,0.74,11.27-3.08c-1.34,1.22-3.94-0.09-4.82-1.91c3.72,1.46,7.98,0.07,10.8-3.5c0.63-0.8,2.19-0.77,2.25,0.4C194.3,252.29,190.85,256.73,188.21,256.7z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M311.79,256.7c-1.36-0.26-2.52-1.86-2.68-3.69c3.05,3.17,6.29,6.23,10.02,7.52c3.73,1.29,8.07,0.5,10.77-3.21c-2.68,1.54-5.72,1.95-8.57,1.16c1.5,0.15,3.03-0.62,4.09-2.05c-3.72,1.53-8.6,0.74-11.27-3.08c1.34,1.22,3.94-0.09,4.82-1.91c-3.72,1.46-7.98,0.07-10.8-3.5c-0.63-0.8-2.19-0.77-2.25,0.4C305.7,252.29,309.15,256.73,311.79,256.7z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M186.21,258.2c1.36-0.26,2.52-1.86,2.68-3.69c-3.05,3.17-6.29,6.23-10.02,7.52c-3.73,1.29-8.07,0.5-10.77-3.21c2.68,1.54,5.72,1.95,8.57,1.16c-1.5,0.15-3.03-0.62-4.09-2.05c3.72,1.53,8.6,0.74,11.27-3.08c-1.34,1.22-3.94-0.09-4.82-1.91c3.72,1.46,7.98,0.07,10.8-3.5c0.63-0.8,2.19-0.77,2.25,0.4C192.3,253.79,188.85,258.23,186.21,258.2z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M313.79,258.2c-1.36-0.26-2.52-1.86-2.68-3.69c3.05,3.17,6.29,6.23,10.02,7.52c3.73,1.29,8.07,0.5,10.77-3.21c-2.68,1.54-5.72,1.95-8.57,1.16c1.5,0.15,3.03-0.62,4.09-2.05c-3.72,1.53-8.6,0.74-11.27-3.08c1.34,1.22,3.94-0.09,4.82-1.91c-3.72,1.46-7.98,0.07-10.8-3.5c-0.63-0.8-2.19-0.77-2.25,0.4C307.7,253.79,311.15,258.23,313.79,258.2z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M183.32,242.13c1.06,0.52,2.65,0.03,3.68-1.13c-3.67,0.59-7.42,1-10.63-0.03c-3.2-1.03-5.79-3.77-5.79-7.63c1.07,2.4,2.95,4.22,5.31,5.14c-1.1-0.66-1.77-1.96-1.79-3.46c1.79,2.93,5.54,4.87,9.28,3.65c-1.52,0.14-2.66-2.06-2.35-3.74c1.83,2.87,5.44,4.1,9.16,3.13c0.83-0.22,1.89,0.6,1.35,1.41C189.7,242.26,185.12,243.5,183.32,242.13z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M316.68,242.13c-1.06,0.52-2.65,0.03-3.68-1.13c3.67,0.59,7.42,1,10.63-0.03c3.2-1.03,5.79-3.77,5.79-7.63c-1.07,2.4-2.95,4.22-5.31,5.14c1.1-0.66,1.77-1.96,1.79-3.46c-1.79,2.93-5.54,4.87-9.28,3.65c1.52,0.14,2.66-2.06,2.35-3.74c-1.83,2.87-5.44,4.1-9.16,3.13c-0.83-0.22-1.89,0.6-1.35,1.41C310.3,242.26,314.88,243.5,316.68,242.13z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M186.17,264.14c1.18,0.07,2.46-0.99,2.97-2.45c-3.17,1.95-6.48,3.76-9.83,4.04s-6.79-1.26-8.27-4.83c1.91,1.81,4.34,2.77,6.87,2.71c-1.27-0.19-2.39-1.13-2.97-2.51c2.77,2.02,6.98,2.38,9.97-0.18c-1.35,0.71-3.24-0.89-3.6-2.55c2.79,1.95,6.6,1.71,9.66-0.62c0.68-0.52,1.97-0.17,1.79,0.79C192.11,261.81,188.35,264.71,186.17,264.14z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M313.83,264.14c-1.18,0.07-2.46-0.99-2.97-2.45c3.17,1.95,6.48,3.76,9.83,4.04s6.79-1.26,8.27-4.83c-1.91,1.81-4.34,2.77-6.87,2.71c1.27-0.19,2.39-1.13,2.97-2.51c-2.77,2.02-6.98,2.38-9.97-0.18c1.35,0.71,3.24-0.89,3.6-2.55c-2.79,1.95-6.6,1.71-9.66-0.62c-0.68-0.52-1.97-0.17-1.79,0.79C307.89,261.81,311.65,264.71,313.83,264.14z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M184.51,255.38c1.13,0.35,2.63-0.37,3.47-1.67c-3.54,1.14-7.19,2.11-10.51,1.58c-3.32-0.53-6.29-2.85-6.88-6.67c1.42,2.21,3.56,3.73,6.02,4.28c-1.19-0.48-2.05-1.67-2.29-3.15c2.21,2.62,6.21,3.98,9.72,2.21c-1.49,0.37-2.94-1.64-2.89-3.34c2.24,2.56,6,3.23,9.53,1.71c0.79-0.34,1.96,0.3,1.55,1.19C190.83,254.54,186.49,256.46,184.51,255.38z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M315.49,255.38c-1.13,0.35-2.63-0.37-3.47-1.67c3.54,1.14,7.19,2.11,10.51,1.58c3.32-0.53,6.29-2.85,6.88-6.67c-1.42,2.21-3.56,3.73-6.02,4.28c1.19-0.48,2.05-1.67,2.29-3.15c-2.21,2.62-6.21,3.98-9.72,2.21c1.49,0.37,2.94-1.64,2.89-3.34c-2.24,2.56-6,3.23-9.53,1.71c-0.79-0.34-1.96,0.3-1.55,1.19C309.17,254.54,313.51,256.46,315.49,255.38z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M187.46,263.95c1.36-0.26,2.52-1.86,2.68-3.69c-3.05,3.17-6.29,6.23-10.02,7.52c-3.73,1.29-8.07,0.5-10.77-3.21c2.68,1.54,5.72,1.95,8.57,1.16c-1.5,0.15-3.03-0.62-4.09-2.05c3.72,1.53,8.6,0.74,11.27-3.08c-1.34,1.22-3.94-0.09-4.82-1.91c3.72,1.46,7.98,0.07,10.8-3.5c0.63-0.8,2.19-0.77,2.25,0.4C193.55,259.54,190.1,263.98,187.46,263.95z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M312.54,263.95c-1.36-0.26-2.52-1.86-2.68-3.69c3.05,3.17,6.29,6.23,10.02,7.52c3.73,1.29,8.07,0.5,10.77-3.21c-2.68,1.54-5.72,1.95-8.57,1.16c1.5,0.15,3.03-0.62,4.09-2.05c-3.72,1.53-8.6,0.74-11.27-3.08c1.34,1.22,3.94-0.09,4.82-1.91c-3.72,1.46-7.98,0.07-10.8-3.5c-0.63-0.8-2.19-0.77-2.25,0.4C306.45,259.54,309.9,263.98,312.54,263.95z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M186.96,258.45c1.36-0.26,2.52-1.86,2.68-3.69c-3.05,3.17-6.29,6.23-10.02,7.52c-3.73,1.29-8.07,0.5-10.77-3.21c2.68,1.54,5.72,1.95,8.57,1.16c-1.5,0.15-3.03-0.62-4.09-2.05c3.72,1.53,8.6,0.74,11.27-3.08c-1.34,1.22-3.94-0.09-4.82-1.91c3.72,1.46,7.98,0.07,10.8-3.5c0.63-0.8,2.19-0.77,2.25,0.4C193.05,254.04,189.6,258.48,186.96,258.45z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M313.04,258.45c-1.36-0.26-2.52-1.86-2.68-3.69c3.05,3.17,6.29,6.23,10.02,7.52c3.73,1.29,8.07,0.5,10.77-3.21c-2.68,1.54-5.72,1.95-8.57,1.16c1.5,0.15,3.03-0.62,4.09-2.05c-3.72,1.53-8.6,0.74-11.27-3.08c1.34,1.22,3.94-0.09,4.82-1.91c-3.72,1.46-7.98,0.07-10.8-3.5c-0.63-0.8-2.19-0.77-2.25,0.4C306.95,254.04,310.4,258.48,313.04,258.45z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M184.95,257.91c1.38,0.16,2.96-1.02,3.66-2.73c-3.85,2.12-7.86,4.07-11.81,4.19c-3.94,0.12-7.85-1.93-9.32-6.28c2.1,2.27,4.87,3.57,7.84,3.66c-1.47-0.3-2.71-1.49-3.29-3.18c3.1,2.58,7.99,3.27,11.68,0.42c-1.64,0.76-3.73-1.26-4.03-3.26c3.12,2.5,7.59,2.45,11.35-0.12c0.84-0.58,2.32-0.08,2.03,1.05C192.08,255.52,187.46,258.73,184.95,257.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M315.05,257.91c-1.38,0.16-2.96-1.02-3.66-2.73c3.85,2.12,7.86,4.07,11.81,4.19c3.94,0.12,7.85-1.93,9.32-6.28c-2.1,2.27-4.87,3.57-7.84,3.66c1.47-0.3,2.71-1.49,3.29-3.18c-3.1,2.58-7.99,3.27-11.68,0.42c1.64,0.76,3.73-1.26,4.03-3.26c-3.12,2.5-7.59,2.45-11.35-0.12c-0.84-0.58-2.32-0.08-2.03,1.05C307.92,255.52,312.54,258.73,315.05,257.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M184.95,256.66c1.38,0.16,2.96-1.02,3.66-2.73c-3.85,2.12-7.86,4.07-11.81,4.19c-3.94,0.12-7.85-1.93-9.32-6.28c2.1,2.27,4.87,3.57,7.84,3.66c-1.47-0.3-2.71-1.49-3.29-3.18c3.1,2.58,7.99,3.27,11.68,0.42c-1.64,0.76-3.73-1.26-4.03-3.26c3.12,2.5,7.59,2.45,11.35-0.12c0.84-0.58,2.32-0.08,2.03,1.05C192.08,254.27,187.46,257.48,184.95,256.66z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M315.05,256.66c-1.38,0.16-2.96-1.02-3.66-2.73c3.85,2.12,7.86,4.07,11.81,4.19c3.94,0.12,7.85-1.93,9.32-6.28c-2.1,2.27-4.87,3.57-7.84,3.66c1.47-0.3,2.71-1.49,3.29-3.18c-3.1,2.58-7.99,3.27-11.68,0.42c1.64,0.76,3.73-1.26,4.03-3.26c-3.12,2.5-7.59,2.45-11.35-0.12c-0.84-0.58-2.32-0.08-2.03,1.05C307.92,254.27,312.54,257.48,315.05,256.66z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M184.2,258.41c1.38,0.16,2.96-1.02,3.66-2.73c-3.85,2.12-7.86,4.07-11.81,4.19c-3.94,0.12-7.85-1.93-9.32-6.28c2.1,2.27,4.87,3.57,7.84,3.66c-1.47-0.3-2.71-1.49-3.29-3.18c3.1,2.58,7.99,3.27,11.68,0.42c-1.64,0.76-3.73-1.26-4.03-3.26c3.12,2.5,7.59,2.45,11.35-0.12c0.84-0.58,2.32-0.08,2.03,1.05C191.33,256.02,186.71,259.23,184.2,258.41z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M315.8,258.41c-1.38,0.16-2.96-1.02-3.66-2.73c3.85,2.12,7.86,4.07,11.81,4.19c3.94,0.12,7.85-1.93,9.32-6.28c-2.1,2.27-4.87,3.57-7.84,3.66c1.47-0.3,2.71-1.49,3.29-3.18c-3.1,2.58-7.99,3.27-11.68,0.42c1.64,0.76,3.73-1.26,4.03-3.26c-3.12,2.5-7.59,2.45-11.35-0.12c-0.84-0.58-2.32-0.08-2.03,1.05C308.67,256.02,313.29,259.23,315.8,258.41z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M182.82,250.22c1.09,0.46,2.65-0.11,3.61-1.32c-3.64,0.79-7.36,1.4-10.61,0.55s-5.99-3.45-6.2-7.31c1.2,2.34,3.18,4.05,5.58,4.84c-1.14-0.6-1.88-1.86-1.97-3.36c1.95,2.82,5.8,4.56,9.46,3.14c-1.51,0.22-2.76-1.92-2.55-3.6c1.98,2.77,5.65,3.8,9.32,2.63c0.82-0.26,1.92,0.49,1.42,1.34C189.2,250,184.69,251.48,182.82,250.22z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M317.18,250.22c-1.09,0.46-2.65-0.11-3.61-1.32c3.64,0.79,7.36,1.4,10.61,0.55s5.99-3.45,6.2-7.31c-1.2,2.34-3.18,4.05-5.58,4.84c1.14-0.6,1.88-1.86,1.97-3.36c-1.95,2.82-5.8,4.56-9.46,3.14c1.51,0.22,2.76-1.92,2.55-3.6c-1.98,2.77-5.65,3.8-9.32,2.63c-0.82-0.26-1.92,0.49-1.42,1.34C310.8,250,315.31,251.48,317.18,250.22z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M183.57,248.97c1.09,0.46,2.65-0.11,3.61-1.32c-3.64,0.79-7.36,1.4-10.61,0.55s-5.99-3.45-6.2-7.31c1.2,2.34,3.18,4.05,5.58,4.84c-1.14-0.6-1.88-1.86-1.97-3.36c1.95,2.82,5.8,4.56,9.46,3.14c-1.51,0.22-2.76-1.92-2.55-3.6c1.98,2.77,5.65,3.8,9.32,2.63c0.82-0.26,1.92,0.49,1.42,1.34C189.95,248.75,185.44,250.23,183.57,248.97z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M316.43,248.97c-1.09,0.46-2.65-0.11-3.61-1.32c3.64,0.79,7.36,1.4,10.61,0.55s5.99-3.45,6.2-7.31c-1.2,2.34-3.18,4.05-5.58,4.84c1.14-0.6,1.88-1.86,1.97-3.36c-1.95,2.82-5.8,4.56-9.46,3.14c1.51,0.22,2.76-1.92,2.55-3.6c-1.98,2.77-5.65,3.8-9.32,2.63c-0.82-0.26-1.92,0.49-1.42,1.34C310.05,248.75,314.56,250.23,316.43,248.97z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M183.82,252.47c1.09,0.46,2.65-0.11,3.61-1.32c-3.64,0.79-7.36,1.4-10.61,0.55s-5.99-3.45-6.2-7.31c1.2,2.34,3.18,4.05,5.58,4.84c-1.14-0.6-1.88-1.86-1.97-3.36c1.95,2.82,5.8,4.56,9.46,3.14c-1.51,0.22-2.76-1.92-2.55-3.6c1.98,2.77,5.65,3.8,9.32,2.63c0.82-0.26,1.92,0.49,1.42,1.34C190.2,252.25,185.69,253.73,183.82,252.47z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M316.18,252.47c-1.09,0.46-2.65-0.11-3.61-1.32c3.64,0.79,7.36,1.4,10.61,0.55s5.99-3.45,6.2-7.31c-1.2,2.34-3.18,4.05-5.58,4.84c1.14-0.6,1.88-1.86,1.97-3.36c-1.95,2.82-5.8,4.56-9.46,3.14c1.51,0.22,2.76-1.92,2.55-3.6c-1.98,2.77-5.65,3.8-9.32,2.63c-0.82-0.26-1.92,0.49-1.42,1.34C309.8,252.25,314.31,253.73,316.18,252.47z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_glaww = {
  name: "Eyelash 16",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M194.02,249.34c-2.33-1.83-5.43-2.63-8.35-2.16c-2.92,0.47-5.61,2.21-7.25,4.67C182.14,247.59,189.02,246.54,194.02,249.34z" />
              <path d="M194.45,251.82c-4.92-2.16-11.31,0.02-13.88,4.74C184.1,253.06,189.28,251.29,194.45,251.82z" />
              <path d="M195.62,254.83c-2.26-1.36-5.2-1.53-7.6-0.42c-2.4,1.11-4.19,3.45-4.62,6.05c0.81-2.33,2.6-4.29,4.84-5.32C190.48,254.11,193.14,254.04,195.62,254.83z" />
              <path d="M197.49,258.43c-1.68-1.5-4.05-2.18-6.27-1.81c-2.22,0.37-4.24,1.79-5.34,3.75C188.96,257.35,194.03,256.6,197.49,258.43z" />
              <path d="M201.03,261.52c-2.11-1.4-4.78-1.93-7.26-1.42c-2.48,0.5-4.73,2.03-6.12,4.15C190.94,260.66,196.66,259.58,201.03,261.52z" />
              <path d="M203.89,263.32c-3.67-0.34-7.33,2.64-7.75,6.31C197.43,266.41,200.46,263.94,203.89,263.32z" />
              <path d="M208.33,265.12c-2.61,0.5-4.7,3-4.71,5.66C204.44,268.37,206.33,266.34,208.33,265.12z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M305.98,249.34c2.33-1.83,5.43-2.63,8.35-2.16c2.92,0.47,5.61,2.21,7.25,4.67C317.86,247.59,310.98,246.54,305.98,249.34z" />
              <path d="M305.55,251.82c4.92-2.16,11.31,0.02,13.88,4.74C315.9,253.06,310.72,251.29,305.55,251.82z" />
              <path d="M304.38,254.83c2.26-1.36,5.2-1.53,7.6-0.42c2.4,1.11,4.19,3.45,4.62,6.05c-0.81-2.33-2.6-4.29-4.84-5.32C309.52,254.11,306.86,254.04,304.38,254.83z" />
              <path d="M302.51,258.43c1.68-1.5,4.05-2.18,6.27-1.81c2.22,0.37,4.24,1.79,5.34,3.75C311.04,257.35,305.97,256.6,302.51,258.43z" />
              <path d="M298.97,261.52c2.11-1.4,4.78-1.93,7.26-1.42c2.48,0.5,4.73,2.03,6.12,4.15C309.06,260.66,303.34,259.58,298.97,261.52z" />
              <path d="M296.11,263.32c3.67-0.34,7.33,2.64,7.75,6.31C302.57,266.41,299.54,263.94,296.11,263.32z" />
              <path d="M291.67,265.12c2.61,0.5,4.7,3,4.71,5.66C295.56,268.37,293.67,266.34,291.67,265.12z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.77,248.59c-2.33-1.83-5.43-2.63-8.35-2.16c-2.92,0.47-5.61,2.21-7.25,4.67C179.89,246.84,186.77,245.79,191.77,248.59z" />
              <path d="M192.2,251.07c-4.92-2.16-11.31,0.02-13.88,4.74C181.85,252.31,187.03,250.54,192.2,251.07z" />
              <path d="M193.37,254.08c-2.26-1.36-5.2-1.53-7.6-0.42c-2.4,1.11-4.19,3.45-4.62,6.05c0.81-2.33,2.6-4.29,4.84-5.32C188.23,253.36,190.89,253.29,193.37,254.08z" />
              <path d="M195.99,257.43c-1.68-1.5-4.05-2.18-6.27-1.81c-2.22,0.37-4.24,1.79-5.34,3.75C187.46,256.35,192.53,255.6,195.99,257.43z" />
              <path d="M199.28,260.27c-1.81-1.4-4.11-1.93-6.24-1.42c-2.13,0.5-4.07,2.03-5.26,4.15C190.61,259.41,195.53,258.33,199.28,260.27z" />
              <path d="M202.64,261.57c-3.67-0.34-7.33,2.64-7.75,6.31C196.18,264.66,199.21,262.19,202.64,261.57z" />
              <path d="M207.08,262.12c-2.61,0.5-4.7,3-4.71,5.66C203.19,265.37,205.08,263.34,207.08,262.12z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.23,248.59c2.33-1.83,5.43-2.63,8.35-2.16c2.92,0.47,5.61,2.21,7.25,4.67C320.11,246.84,313.23,245.79,308.23,248.59z" />
              <path d="M307.8,251.07c4.92-2.16,11.31,0.02,13.88,4.74C318.15,252.31,312.97,250.54,307.8,251.07z" />
              <path d="M306.63,254.08c2.26-1.36,5.2-1.53,7.6-0.42c2.4,1.11,4.19,3.45,4.62,6.05c-0.81-2.33-2.6-4.29-4.84-5.32C311.77,253.36,309.11,253.29,306.63,254.08z" />
              <path d="M304.01,257.43c1.68-1.5,4.05-2.18,6.27-1.81c2.22,0.37,4.24,1.79,5.34,3.75C312.54,256.35,307.47,255.6,304.01,257.43z" />
              <path d="M300.72,260.27c1.81-1.4,4.11-1.93,6.24-1.42c2.13,0.5,4.07,2.03,5.26,4.15C309.39,259.41,304.47,258.33,300.72,260.27z" />
              <path d="M297.36,261.57c3.67-0.34,7.33,2.64,7.75,6.31C303.82,264.66,300.79,262.19,297.36,261.57z" />
              <path d="M292.92,262.12c2.61,0.5,4.7,3,4.71,5.66C296.81,265.37,294.92,263.34,292.92,262.12z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.79,237.92c-1.39-1.39-3.36-2.12-5.28-1.97c-1.92,0.15-3.77,1.19-4.98,2.78C184.19,236.03,188.72,235.71,191.79,237.92z" />
              <path d="M191.59,239.62c-3.05-1.77-7.33-0.64-9.29,2.45C184.81,239.86,188.27,238.95,191.59,239.62z" />
              <path d="M191.84,241.72c-1.38-1.07-3.28-1.35-4.9-0.73c-1.62,0.62-2.92,2.12-3.36,3.89c0.67-1.55,1.95-2.79,3.46-3.37C188.55,240.93,190.28,241.03,191.84,241.72z" />
              <path d="M192.54,243.78c-1.08-1.04-2.61-1.52-4.06-1.27s-2.78,1.22-3.51,2.57C186.99,243.01,190.3,242.51,192.54,243.78z" />
              <path d="M193.32,245.65c-1.2-0.92-2.71-1.22-4.08-0.82c-1.38,0.4-2.61,1.5-3.35,2.99C187.66,245.28,190.84,244.42,193.32,245.65z" />
              <path d="M193.66,247.06c-2.36-0.44-4.91,1.39-5.41,3.88C189.28,248.8,191.4,247.29,193.66,247.06z" />
              <path d="M194.94,248.77c-1.72,0.19-3.23,1.79-3.4,3.61C192.22,250.77,193.57,249.49,194.94,248.77z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.21,237.92c1.39-1.39,3.36-2.12,5.28-1.97c1.92,0.15,3.77,1.19,4.98,2.78C315.81,236.03,311.28,235.71,308.21,237.92z" />
              <path d="M308.41,239.62c3.05-1.77,7.33-0.64,9.29,2.45C315.19,239.86,311.73,238.95,308.41,239.62z" />
              <path d="M308.16,241.72c1.38-1.07,3.28-1.35,4.9-0.73s2.92,2.12,3.36,3.89c-0.67-1.55-1.95-2.79-3.46-3.37C311.45,240.93,309.72,241.03,308.16,241.72z" />
              <path d="M307.46,243.78c1.08-1.04,2.61-1.52,4.06-1.27c1.45,0.25,2.78,1.22,3.51,2.57C313.01,243.01,309.7,242.51,307.46,243.78z" />
              <path d="M306.68,245.65c1.2-0.92,2.71-1.22,4.08-0.82c1.38,0.4,2.61,1.5,3.35,2.99C312.34,245.28,309.16,244.42,306.68,245.65z" />
              <path d="M306.34,247.06c2.36-0.44,4.91,1.39,5.41,3.88C310.72,248.8,308.6,247.29,306.34,247.06z" />
              <path d="M305.06,248.77c1.72,0.19,3.23,1.79,3.4,3.61C307.78,250.77,306.43,249.49,305.06,248.77z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.17,257.1c-2.45-0.24-4.91,0.61-6.63,2.28s-2.65,4.15-2.53,6.67C183.39,261.3,187.46,257.39,192.17,257.1z" />
              <path d="M193.68,258.68c-4.34,0.71-7.53,5.2-6.92,9.76C187.39,264.31,189.97,260.67,193.68,258.68z" />
              <path d="M195.94,260.3c-2.18,0.06-4.22,1.29-5.27,3.18c-1.05,1.89-1.09,4.39-0.09,6.45c-0.6-2.04-0.38-4.26,0.6-6.03C192.16,262.14,193.89,260.87,195.94,260.3z" />
              <path d="M199.33,261.49c-1.86-0.3-3.77,0.29-5.07,1.58c-1.3,1.28-1.94,3.23-1.71,5.13C193.12,264.63,196.13,261.77,199.33,261.49z" />
              <path d="M202.93,262.02c-1.9-0.17-3.69,0.5-4.86,1.84s-1.71,3.32-1.46,5.38C196.72,265.38,199.47,262.35,202.93,262.02z" />
              <path d="M205.55,262.16c-2.61,1.44-3.59,5.25-2.06,8.07C202.77,267.33,203.57,264.18,205.55,262.16z" />
              <path d="M209.03,262.01c-1.5,1.56-1.65,4.3-0.35,6.21C208.04,266.12,208.3,263.81,209.03,262.01z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.83,257.1c2.45-0.24,4.91,0.61,6.63,2.28s2.65,4.15,2.53,6.67C316.61,261.3,312.54,257.39,307.83,257.1z" />
              <path d="M306.32,258.68c4.34,0.71,7.53,5.2,6.92,9.76C312.61,264.31,310.03,260.67,306.32,258.68z" />
              <path d="M304.06,260.3c2.18,0.06,4.22,1.29,5.27,3.18c1.05,1.89,1.09,4.39,0.09,6.45c0.6-2.04,0.38-4.26-0.6-6.03C307.84,262.14,306.11,260.87,304.06,260.3z" />
              <path d="M300.67,261.49c1.86-0.3,3.77,0.29,5.07,1.58c1.3,1.28,1.94,3.23,1.71,5.13C306.88,264.63,303.87,261.77,300.67,261.49z" />
              <path d="M297.07,262.02c1.9-0.17,3.69,0.5,4.86,1.84c1.17,1.34,1.71,3.32,1.46,5.38C303.28,265.38,300.53,262.35,297.07,262.02z" />
              <path d="M294.45,262.16c2.61,1.44,3.59,5.25,2.06,8.07C297.23,267.33,296.43,264.18,294.45,262.16z" />
              <path d="M290.97,262.01c1.5,1.56,1.65,4.3,0.35,6.21C291.96,266.12,291.7,263.81,290.97,262.01z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.62,249.54c-2.04-1.38-4.61-1.81-6.91-1.16s-4.31,2.38-5.41,4.65C181.9,249.03,187.34,247.54,191.62,249.54z" />
              <path d="M192.19,251.64c-4.15-1.45-9.1,0.98-10.74,5.27C183.97,253.59,187.98,251.62,192.19,251.64z" />
              <path d="M193.41,254.15c-1.94-0.99-4.33-0.88-6.15,0.28c-1.83,1.16-3.05,3.33-3.16,5.62c0.44-2.08,1.7-3.93,3.41-5.01C189.2,253.96,191.33,253.67,193.41,254.15z" />
              <path d="M195.82,256.82c-1.49-1.15-3.46-1.54-5.21-1.04c-1.75,0.51-3.25,1.91-3.95,3.7C188.86,256.61,192.86,255.53,195.82,256.82z" />
              <path d="M198.73,258.99c-1.58-1.06-3.48-1.32-5.15-0.7c-1.67,0.62-3.09,2.1-3.86,4.03C191.67,258.98,195.53,257.63,198.73,258.99z" />
              <path d="M200.97,260.37c-2.99,0.02-5.66,2.91-5.66,6.11C196.05,263.58,198.26,261.2,200.97,260.37z" />
              <path d="M204.09,261.91c-2.06,0.65-3.5,2.99-3.28,5.29C201.26,265.04,202.59,263.13,204.09,261.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.38,249.54c2.04-1.38,4.61-1.81,6.91-1.16s4.31,2.38,5.41,4.65C318.1,249.03,312.66,247.54,308.38,249.54z" />
              <path d="M307.81,251.64c4.15-1.45,9.1,0.98,10.74,5.27C316.03,253.59,312.02,251.62,307.81,251.64z" />
              <path d="M306.59,254.15c1.94-0.99,4.33-0.88,6.15,0.28c1.83,1.16,3.05,3.33,3.16,5.62c-0.44-2.08-1.7-3.93-3.41-5.01C310.8,253.96,308.67,253.67,306.59,254.15z" />
              <path d="M304.18,256.82c1.49-1.15,3.46-1.54,5.21-1.04c1.75,0.51,3.25,1.91,3.95,3.7C311.14,256.61,307.14,255.53,304.18,256.82z" />
              <path d="M301.27,258.99c1.58-1.06,3.48-1.32,5.15-0.7c1.67,0.62,3.09,2.1,3.86,4.03C308.33,258.98,304.47,257.63,301.27,258.99z" />
              <path d="M299.03,260.37c2.99,0.02,5.66,2.91,5.66,6.11C303.95,263.58,301.74,261.2,299.03,260.37z" />
              <path d="M295.91,261.91c2.06,0.65,3.5,2.99,3.28,5.29C298.74,265.04,297.41,263.13,295.91,261.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.33,255.37c-1.9-1.32-4.32-1.78-6.53-1.23c-2.21,0.55-4.17,2.09-5.27,4.16C184.1,254.67,189.32,253.43,193.33,255.37z" />
              <path d="M193.81,257.32c-3.9-1.43-8.67,0.69-10.35,4.61C185.95,258.92,189.81,257.21,193.81,257.32z" />
              <path d="M194.89,259.66c-1.82-0.95-4.08-0.91-5.86,0.12c-1.77,1.03-3,3-3.17,5.11c0.48-1.9,1.73-3.58,3.38-4.53C190.91,259.39,192.94,259.17,194.89,259.66z" />
              <path d="M196.55,262.42c-1.38-1.1-3.24-1.5-4.92-1.07c-1.68,0.43-3.14,1.68-3.86,3.31C189.94,262.06,193.78,261.16,196.55,262.42z" />
              <path d="M199.46,264.67c-1.7-0.99-3.78-1.25-5.65-0.7s-3.5,1.9-4.44,3.67C191.66,264.58,195.99,263.38,199.46,264.67z" />
              <path d="M201.76,265.94c-2.84-0.05-5.46,2.55-5.55,5.5C196.99,268.78,199.17,266.64,201.76,265.94z" />
              <path d="M205.28,267.11c-1.97,0.55-3.42,2.67-3.26,4.8C202.49,269.93,203.82,268.21,205.28,267.11z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.67,255.37c1.9-1.32,4.32-1.78,6.53-1.23c2.21,0.55,4.17,2.09,5.27,4.16C315.9,254.67,310.68,253.43,306.67,255.37z" />
              <path d="M306.19,257.32c3.9-1.43,8.67,0.69,10.35,4.61C314.05,258.92,310.19,257.21,306.19,257.32z" />
              <path d="M305.11,259.66c1.82-0.95,4.08-0.91,5.86,0.12c1.77,1.03,3,3,3.17,5.11c-0.48-1.9-1.73-3.58-3.38-4.53C309.09,259.39,307.06,259.17,305.11,259.66z" />
              <path d="M303.45,262.42c1.38-1.1,3.24-1.5,4.92-1.07c1.68,0.43,3.14,1.68,3.86,3.31C310.06,262.06,306.22,261.16,303.45,262.42z" />
              <path d="M300.54,264.67c1.7-0.99,3.78-1.25,5.65-0.7s3.5,1.9,4.44,3.67C308.34,264.58,304.01,263.38,300.54,264.67z" />
              <path d="M298.24,265.94c2.84-0.05,5.46,2.55,5.55,5.5C303.01,268.78,300.83,266.64,298.24,265.94z" />
              <path d="M294.72,267.11c1.97,0.55,3.42,2.67,3.26,4.8C297.51,269.93,296.18,268.21,294.72,267.11z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.14,249.18c-2.02-1.5-4.72-2.16-7.26-1.77s-4.88,1.81-6.3,3.84C181.81,247.74,187.8,246.88,192.14,249.18z" />
              <path d="M192.51,251.22c-4.27-1.77-9.84,0.02-12.07,3.89C183.51,252.23,188.02,250.78,192.51,251.22z" />
              <path d="M193.53,253.69c-1.97-1.12-4.52-1.26-6.61-0.35c-2.09,0.91-3.64,2.83-4.01,4.97c0.71-1.91,2.26-3.53,4.21-4.37C189.06,253.1,191.37,253.04,193.53,253.69z" />
              <path d="M194.66,256.4c-1.46-1.23-3.52-1.79-5.45-1.49c-1.93,0.3-3.68,1.47-4.64,3.08C187.24,255.51,191.64,254.9,194.66,256.4z" />
              <path d="M196.24,259.26c-1.65-1.21-3.75-1.67-5.69-1.23c-1.95,0.44-3.72,1.76-4.81,3.59C188.31,258.51,192.81,257.58,196.24,259.26z" />
              <path d="M197.97,260.92c-3.19-0.28-6.38,2.17-6.74,5.18C192.35,263.45,194.99,261.43,197.97,260.92z" />
              <path d="M199.58,262.39c-2.27,0.41-4.08,2.47-4.1,4.65C196.2,265.06,197.84,263.4,199.58,262.39z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.86,249.18c2.02-1.5,4.72-2.16,7.26-1.77s4.88,1.81,6.3,3.84C318.19,247.74,312.2,246.88,307.86,249.18z" />
              <path d="M307.49,251.22c4.27-1.77,9.84,0.02,12.07,3.89C316.49,252.23,311.98,250.78,307.49,251.22z" />
              <path d="M306.47,253.69c1.97-1.12,4.52-1.26,6.61-0.35c2.09,0.91,3.64,2.83,4.01,4.97c-0.71-1.91-2.26-3.53-4.21-4.37C310.94,253.1,308.63,253.04,306.47,253.69z" />
              <path d="M305.34,256.4c1.46-1.23,3.52-1.79,5.45-1.49c1.93,0.3,3.68,1.47,4.64,3.08C312.76,255.51,308.36,254.9,305.34,256.4z" />
              <path d="M303.76,259.26c1.65-1.21,3.75-1.67,5.69-1.23s3.72,1.76,4.81,3.59C311.69,258.51,307.19,257.58,303.76,259.26z" />
              <path d="M302.03,260.92c3.19-0.28,6.38,2.17,6.74,5.18C307.65,263.45,305.01,261.43,302.03,260.92z" />
              <path d="M300.42,262.39c2.27,0.41,4.08,2.47,4.1,4.65C303.8,265.06,302.16,263.4,300.42,262.39z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.85,249.54c-1.86-0.85-4.05-0.87-5.89-0.04s-3.31,2.48-3.94,4.46C183.7,250.39,188.05,248.48,191.85,249.54z" />
              <path d="M192.58,251.17c-3.63-0.63-7.46,1.97-8.31,5.65C185.98,253.8,189.08,251.7,192.58,251.17z" />
              <path d="M193.9,253.03c-1.74-0.55-3.71-0.15-5.09,1.02c-1.38,1.17-2.14,3.09-1.96,4.95c0.12-1.74,0.94-3.39,2.24-4.49S192.12,252.91,193.9,253.03z" />
              <path d="M196.23,254.88c-1.38-0.74-3.06-0.8-4.46-0.16s-2.47,1.96-2.85,3.5C190.4,255.61,193.61,254.22,196.23,254.88z" />
              <path d="M198.91,256.26c-1.45-0.65-3.06-0.61-4.37,0.1c-1.31,0.71-2.32,2.1-2.73,3.75C193.03,257.16,196.08,255.57,198.91,256.26z" />
              <path d="M200.94,257.08c-2.48,0.4-4.36,3.08-3.98,5.67C197.23,260.31,198.79,258.1,200.94,257.08z" />
              <path d="M203.73,257.91c-1.63,0.79-2.56,2.87-2.09,4.7C201.74,260.81,202.63,259.1,203.73,257.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.15,249.54c1.86-0.85,4.05-0.87,5.89-0.04s3.31,2.48,3.94,4.46C316.3,250.39,311.95,248.48,308.15,249.54z" />
              <path d="M307.42,251.17c3.63-0.63,7.46,1.97,8.31,5.65C314.02,253.8,310.92,251.7,307.42,251.17z" />
              <path d="M306.1,253.03c1.74-0.55,3.71-0.15,5.09,1.02c1.38,1.17,2.14,3.09,1.96,4.95c-0.12-1.74-0.94-3.39-2.24-4.49S307.88,252.91,306.1,253.03z" />
              <path d="M303.77,254.88c1.38-0.74,3.06-0.8,4.46-0.16c1.4,0.64,2.47,1.96,2.85,3.5C309.6,255.61,306.39,254.22,303.77,254.88z" />
              <path d="M301.09,256.26c1.45-0.65,3.06-0.61,4.37,0.1c1.31,0.71,2.32,2.1,2.73,3.75C306.97,257.16,303.92,255.57,301.09,256.26z" />
              <path d="M299.06,257.08c2.48,0.4,4.36,3.08,3.98,5.67C302.77,260.31,301.21,258.1,299.06,257.08z" />
              <path d="M296.27,257.91c1.63,0.79,2.56,2.87,2.09,4.7C298.26,260.81,297.37,259.1,296.27,257.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.1,248.04c-1.86-0.85-4.05-0.87-5.89-0.04s-3.31,2.48-3.94,4.46C183.95,248.89,188.3,246.98,192.1,248.04z" />
              <path d="M192.83,249.67c-3.63-0.63-7.46,1.97-8.31,5.65C186.23,252.3,189.33,250.2,192.83,249.67z" />
              <path d="M194.15,251.53c-1.74-0.55-3.71-0.15-5.09,1.02c-1.38,1.17-2.14,3.09-1.96,4.95c0.12-1.74,0.94-3.39,2.24-4.49S192.37,251.41,194.15,251.53z" />
              <path d="M196.48,253.38c-1.38-0.74-3.06-0.8-4.46-0.16s-2.47,1.96-2.85,3.5C190.65,254.11,193.86,252.72,196.48,253.38z" />
              <path d="M199.16,254.76c-1.45-0.65-3.06-0.61-4.37,0.1c-1.31,0.71-2.32,2.1-2.73,3.75C193.28,255.66,196.33,254.07,199.16,254.76z" />
              <path d="M201.19,255.58c-2.48,0.4-4.36,3.08-3.98,5.67C197.48,258.81,199.04,256.6,201.19,255.58z" />
              <path d="M203.98,256.41c-1.63,0.79-2.56,2.87-2.09,4.7C201.99,259.31,202.88,257.6,203.98,256.41z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.9,248.04c1.86-0.85,4.05-0.87,5.89-0.04s3.31,2.48,3.94,4.46C316.05,248.89,311.7,246.98,307.9,248.04z" />
              <path d="M307.17,249.67c3.63-0.63,7.46,1.97,8.31,5.65C313.77,252.3,310.67,250.2,307.17,249.67z" />
              <path d="M305.85,251.53c1.74-0.55,3.71-0.15,5.09,1.02c1.38,1.17,2.14,3.09,1.96,4.95c-0.12-1.74-0.94-3.39-2.24-4.49S307.63,251.41,305.85,251.53z" />
              <path d="M303.52,253.38c1.38-0.74,3.06-0.8,4.46-0.16c1.4,0.64,2.47,1.96,2.85,3.5C309.35,254.11,306.14,252.72,303.52,253.38z" />
              <path d="M300.84,254.76c1.45-0.65,3.06-0.61,4.37,0.1c1.31,0.71,2.32,2.1,2.73,3.75C306.72,255.66,303.67,254.07,300.84,254.76z" />
              <path d="M298.81,255.58c2.48,0.4,4.36,3.08,3.98,5.67C302.52,258.81,300.96,256.6,298.81,255.58z" />
              <path d="M296.02,256.41c1.63,0.79,2.56,2.87,2.09,4.7C298.01,259.31,297.12,257.6,296.02,256.41z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.25,249.92c-1.93-0.68-4.11-0.49-5.87,0.5c-1.76,0.99-3.07,2.77-3.52,4.8C183.21,251.51,187.37,249.21,191.25,249.92z" />
              <path d="M192.12,251.47c-3.67-0.3-7.25,2.64-7.76,6.38C185.79,254.7,188.68,252.32,192.12,251.47z" />
              <path d="M193.6,253.21c-1.78-0.38-3.71,0.19-4.98,1.48c-1.27,1.29-1.85,3.27-1.5,5.11c-0.04-1.74,0.63-3.47,1.82-4.67C190.13,253.92,191.82,253.25,193.6,253.21z" />
              <path d="M196.09,254.83c-1.44-0.61-3.12-0.52-4.46,0.25s-2.29,2.18-2.52,3.74C190.36,256.1,193.43,254.42,196.09,254.83z" />
              <path d="M198.89,255.96c-1.5-0.52-3.1-0.33-4.34,0.5c-1.24,0.83-2.12,2.3-2.37,3.99C193.11,257.4,196.01,255.54,198.89,255.96z" />
              <path d="M200.99,256.6c-2.44,0.63-4.06,3.47-3.45,6.01C197.59,260.15,198.93,257.81,200.99,256.6z" />
              <path d="M203.84,257.17c-1.56,0.94-2.29,3.09-1.66,4.87C202.13,260.24,202.85,258.45,203.84,257.17z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.75,249.92c1.93-0.68,4.11-0.49,5.87,0.5c1.76,0.99,3.07,2.77,3.52,4.8C316.79,251.51,312.63,249.21,308.75,249.92z" />
              <path d="M307.88,251.47c3.67-0.3,7.25,2.64,7.76,6.38C314.21,254.7,311.32,252.32,307.88,251.47z" />
              <path d="M306.4,253.21c1.78-0.38,3.71,0.19,4.98,1.48c1.27,1.29,1.85,3.27,1.5,5.11c0.04-1.74-0.63-3.47-1.82-4.67C309.87,253.92,308.18,253.25,306.4,253.21z" />
              <path d="M303.91,254.83c1.44-0.61,3.12-0.52,4.46,0.25c1.33,0.76,2.29,2.18,2.52,3.74C309.64,256.1,306.57,254.42,303.91,254.83z" />
              <path d="M301.11,255.96c1.5-0.52,3.1-0.33,4.34,0.5c1.24,0.83,2.12,2.3,2.37,3.99C306.89,257.4,303.99,255.54,301.11,255.96z" />
              <path d="M299.01,256.6c2.44,0.63,4.06,3.47,3.45,6.01C302.41,260.15,301.07,257.81,299.01,256.6z" />
              <path d="M296.16,257.17c1.56,0.94,2.29,3.09,1.66,4.87C297.87,260.24,297.15,258.45,296.16,257.17z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.48,245.71c-1.9-0.77-4.09-0.68-5.89,0.23c-1.8,0.91-3.19,2.63-3.73,4.63C182.37,246.93,186.63,244.83,190.48,245.71z" />
              <path d="M191.28,247.3c-3.66-0.46-7.36,2.31-8.04,6.02C184.8,250.24,187.8,247.99,191.28,247.3z" />
              <path d="M192.68,249.1c-1.76-0.47-3.71,0.02-5.04,1.26c-1.33,1.24-2,3.18-1.73,5.04c0.04-1.74,0.79-3.43,2.03-4.58C189.18,249.66,190.89,249.07,192.68,249.1z" />
              <path d="M195.09,250.84c-1.41-0.67-3.1-0.66-4.46,0.04s-2.38,2.07-2.68,3.62C189.31,251.84,192.45,250.31,195.09,250.84z" />
              <path d="M197.83,252.1c-1.47-0.58-3.08-0.47-4.36,0.3c-1.28,0.77-2.22,2.2-2.55,3.88C192,253.27,194.98,251.54,197.83,252.1z" />
              <path d="M199.65,253.07c-2.46,0.52-4.22,3.28-3.72,5.84C196.09,256.47,197.55,254.19,199.65,253.07z" />
              <path d="M202.22,254.28c-1.6,0.87-2.42,2.98-1.87,4.79C200.37,257.27,201.18,255.51,202.22,254.28z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.52,245.71c1.9-0.77,4.09-0.68,5.89,0.23s3.19,2.63,3.73,4.63C317.63,246.93,313.37,244.83,309.52,245.71z" />
              <path d="M308.72,247.3c3.66-0.46,7.36,2.31,8.04,6.02C315.2,250.24,312.2,247.99,308.72,247.3z" />
              <path d="M307.32,249.1c1.76-0.47,3.71,0.02,5.04,1.26c1.33,1.24,2,3.18,1.73,5.04c-0.04-1.74-0.79-3.43-2.03-4.58C310.82,249.66,309.11,249.07,307.32,249.1z" />
              <path d="M304.91,250.84c1.41-0.67,3.1-0.66,4.46,0.04s2.38,2.07,2.68,3.62C310.69,251.84,307.55,250.31,304.91,250.84z" />
              <path d="M302.17,252.1c1.47-0.58,3.08-0.47,4.36,0.3c1.28,0.77,2.22,2.2,2.55,3.88C308,253.27,305.02,251.54,302.17,252.1z" />
              <path d="M300.35,253.07c2.46,0.52,4.22,3.28,3.72,5.84C303.91,256.47,302.45,254.19,300.35,253.07z" />
              <path d="M297.78,254.28c1.6,0.87,2.42,2.98,1.87,4.79C299.63,257.27,298.82,255.51,297.78,254.28z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.25,244.88c-1.8-0.97-3.99-1.12-5.88-0.41c-1.89,0.71-3.46,2.27-4.22,4.2C183.05,245.21,187.52,243.58,191.25,244.88z" />
              <path d="M191.87,246.55c-3.58-0.86-7.57,1.5-8.65,5.11C185.11,248.76,188.34,246.85,191.87,246.55z" />
              <path d="M193.06,248.49c-1.7-0.65-3.69-0.38-5.15,0.7s-2.33,2.95-2.27,4.82c0.23-1.72,1.16-3.33,2.52-4.34C189.53,248.66,191.29,248.26,193.06,248.49z" />
              <path d="M195.27,250.48c-1.33-0.82-3.01-0.99-4.44-0.44c-1.43,0.55-2.59,1.8-3.06,3.31C189.42,250.84,192.71,249.66,195.27,250.48z" />
              <path d="M197.86,252.02c-1.4-0.74-3.01-0.8-4.37-0.17c-1.36,0.63-2.45,1.95-2.96,3.57C191.94,252.56,195.09,251.16,197.86,252.02z" />
              <path d="M199.56,253.19c-2.5,0.25-4.55,2.8-4.33,5.4C195.66,256.19,197.35,254.08,199.56,253.19z" />
              <path d="M201.99,254.67c-1.68,0.69-2.73,2.7-2.38,4.56C199.83,257.44,200.82,255.79,201.99,254.67z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.75,244.88c1.8-0.97,3.99-1.12,5.88-0.41c1.89,0.71,3.46,2.27,4.22,4.2C316.95,245.21,312.48,243.58,308.75,244.88z" />
              <path d="M308.13,246.55c3.58-0.86,7.57,1.5,8.65,5.11C314.89,248.76,311.66,246.85,308.13,246.55z" />
              <path d="M306.94,248.49c1.7-0.65,3.69-0.38,5.15,0.7c1.45,1.08,2.33,2.95,2.27,4.82c-0.23-1.72-1.16-3.33-2.52-4.34S308.71,248.26,306.94,248.49z" />
              <path d="M304.73,250.48c1.33-0.82,3.01-0.99,4.44-0.44c1.43,0.55,2.59,1.8,3.06,3.31C310.58,250.84,307.29,249.66,304.73,250.48z" />
              <path d="M302.14,252.02c1.4-0.74,3.01-0.8,4.37-0.17c1.36,0.63,2.45,1.95,2.96,3.57C308.06,252.56,304.91,251.16,302.14,252.02z" />
              <path d="M300.44,253.19c2.5,0.25,4.55,2.8,4.33,5.4C304.34,256.19,302.65,254.08,300.44,253.19z" />
              <path d="M298.01,254.67c1.68,0.69,2.73,2.7,2.38,4.56C300.17,257.44,299.18,255.79,298.01,254.67z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.61,248.28c-1.94-0.66-4.12-0.46-5.87,0.54c-1.75,1-3.05,2.8-3.49,4.82C183.58,249.93,187.73,247.6,191.61,248.28z" />
              <path d="M192.5,249.83c-3.68-0.27-7.23,2.69-7.71,6.44C186.18,253.1,189.06,250.7,192.5,249.83z" />
              <path d="M193.99,251.55c-1.78-0.37-3.7,0.22-4.97,1.52c-1.26,1.3-1.83,3.28-1.46,5.12c-0.05-1.74,0.61-3.47,1.78-4.69C190.52,252.29,192.2,251.61,193.99,251.55z" />
              <path d="M196.49,253.16c-1.44-0.6-3.13-0.49-4.46,0.28s-2.27,2.19-2.49,3.76C190.76,254.46,193.82,252.77,196.49,253.16z" />
              <path d="M199.29,254.27c-1.5-0.5-3.1-0.31-4.34,0.53c-1.24,0.84-2.1,2.31-2.34,4C193.53,255.75,196.41,253.87,199.29,254.27z" />
              <path d="M201.16,255.15c-2.43,0.65-4.04,3.49-3.4,6.03C197.78,258.73,199.12,256.38,201.16,255.15z" />
              <path d="M203.79,256.22c-1.55,0.95-2.26,3.11-1.62,4.88C202.1,259.3,202.81,257.51,203.79,256.22z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.39,248.28c1.94-0.66,4.12-0.46,5.87,0.54s3.05,2.8,3.49,4.82C316.42,249.93,312.27,247.6,308.39,248.28z" />
              <path d="M307.5,249.83c3.68-0.27,7.23,2.69,7.71,6.44C313.82,253.1,310.94,250.7,307.5,249.83z" />
              <path d="M306.01,251.55c1.78-0.37,3.7,0.22,4.97,1.52s1.83,3.28,1.46,5.12c0.05-1.74-0.61-3.47-1.78-4.69C309.48,252.29,307.8,251.61,306.01,251.55z" />
              <path d="M303.51,253.16c1.44-0.6,3.13-0.49,4.46,0.28s2.27,2.19,2.49,3.76C309.24,254.46,306.18,252.77,303.51,253.16z" />
              <path d="M300.71,254.27c1.5-0.5,3.1-0.31,4.34,0.53c1.24,0.84,2.1,2.31,2.34,4C306.47,255.75,303.59,253.87,300.71,254.27z" />
              <path d="M298.84,255.15c2.43,0.65,4.04,3.49,3.4,6.03C302.22,258.73,300.88,256.38,298.84,255.15z" />
              <path d="M296.21,256.22c1.55,0.95,2.26,3.11,1.62,4.88C297.9,259.3,297.19,257.51,296.21,256.22z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fjawr = {
  name: "Eyelash 17",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M203.07,264.76c1.97-1.09,4.56-0.88,6.38,0.42c0.21,0.07,0.41,0.16,0.59,0.28c0.1-0.05,0.19-0.11,0.3-0.15c-0.07,0.07-0.14,0.14-0.21,0.21c0.09,0.06,0.18,0.12,0.26,0.2c-0.1,0-0.2,0.01-0.3,0.01c0.03,0.03,0.07,0.06,0.1,0.09c-0.09-0.03-0.19-0.05-0.28-0.08c-2.3,2.42-3.46,6.34-2.45,9.72c-2.12-3.04-1.02-7.72,2.05-9.7c-1.35,0.12-2.62,0.57-3.69,2.07c-1.3,1.83-1.28,4.46-1.22,7.14c-0.74-2.12-1.29-4.99-0.28-7.25c0.47-1.05,1.4-1.89,2.45-2.35c-1.03,0.05-2.05,0.28-2.97,0.74c-1.97,1-3.38,3.04-3.62,5.24C199.57,268.84,200.82,266,203.07,264.76z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M296.93,264.76c-1.97-1.09-4.56-0.88-6.38,0.42c-0.21,0.07-0.41,0.16-0.59,0.28c-0.1-0.05-0.19-0.11-0.3-0.15c0.07,0.07,0.14,0.14,0.21,0.21c-0.09,0.06-0.18,0.12-0.26,0.2c0.1,0,0.2,0.01,0.3,0.01c-0.03,0.03-0.07,0.06-0.1,0.09c0.09-0.03,0.19-0.05,0.28-0.08c2.3,2.42,3.46,6.34,2.45,9.72c2.12-3.04,1.02-7.72-2.05-9.7c1.35,0.12,2.62,0.57,3.69,2.07c1.3,1.83,1.28,4.46,1.22,7.14c0.74-2.12,1.29-4.99,0.28-7.25c-0.47-1.05-1.4-1.89-2.45-2.35c1.03,0.05,2.05,0.28,2.97,0.74c1.97,1,3.38,3.04,3.62,5.24C300.43,268.84,299.18,266,296.93,264.76z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.57,261.51c1.97-1.09,4.56-0.88,6.38,0.42c0.21,0.07,0.41,0.16,0.59,0.28c0.1-0.05,0.19-0.11,0.3-0.15c-0.07,0.07-0.14,0.14-0.21,0.21c0.09,0.06,0.18,0.12,0.26,0.2c-0.1,0-0.2,0.01-0.3,0.01c0.03,0.03,0.07,0.06,0.1,0.09c-0.09-0.03-0.19-0.05-0.28-0.08c-2.3,2.42-3.46,6.34-2.45,9.72c-2.12-3.04-1.02-7.72,2.05-9.7c-1.35,0.12-2.62,0.57-3.69,2.07c-1.3,1.83-1.28,4.46-1.22,7.14c-0.74-2.12-1.29-4.99-0.28-7.25c0.47-1.05,1.4-1.89,2.45-2.35c-1.03,0.05-2.05,0.28-2.97,0.74c-1.97,1-3.38,3.04-3.62,5.24C196.07,265.59,197.32,262.75,199.57,261.51z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.43,261.51c-1.97-1.09-4.56-0.88-6.38,0.42c-0.21,0.07-0.41,0.16-0.59,0.28c-0.1-0.05-0.19-0.11-0.3-0.15c0.07,0.07,0.14,0.14,0.21,0.21c-0.09,0.06-0.18,0.12-0.26,0.2c0.1,0,0.2,0.01,0.3,0.01c-0.03,0.03-0.07,0.06-0.1,0.09c0.09-0.03,0.19-0.05,0.28-0.08c2.3,2.42,3.46,6.34,2.45,9.72c2.12-3.04,1.02-7.72-2.05-9.7c1.35,0.12,2.62,0.57,3.69,2.07c1.3,1.83,1.28,4.46,1.22,7.14c0.74-2.12,1.29-4.99,0.28-7.25c-0.47-1.05-1.4-1.89-2.45-2.35c1.03,0.05,2.05,0.28,2.97,0.74c1.97,1,3.38,3.04,3.62,5.24C303.93,265.59,302.68,262.75,300.43,261.51z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M199.07,259.51c1.97-1.09,4.56-0.88,6.38,0.42c0.21,0.07,0.41,0.16,0.59,0.28c0.1-0.05,0.19-0.11,0.3-0.15c-0.07,0.07-0.14,0.14-0.21,0.21c0.09,0.06,0.18,0.12,0.26,0.2c-0.1,0-0.2,0.01-0.3,0.01c0.03,0.03,0.07,0.06,0.1,0.09c-0.09-0.03-0.19-0.05-0.28-0.08c-2.3,2.42-3.46,6.34-2.45,9.72c-2.12-3.04-1.02-7.72,2.05-9.7c-1.35,0.12-2.62,0.57-3.69,2.07c-1.3,1.83-1.28,4.46-1.22,7.14c-0.74-2.12-1.29-4.99-0.28-7.25c0.47-1.05,1.4-1.89,2.45-2.35c-1.03,0.05-2.05,0.28-2.97,0.74c-1.97,1-3.38,3.04-3.62,5.24C195.57,263.59,196.82,260.75,199.07,259.51z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M300.93,259.51c-1.97-1.09-4.56-0.88-6.38,0.42c-0.21,0.07-0.41,0.16-0.59,0.28c-0.1-0.05-0.19-0.11-0.3-0.15c0.07,0.07,0.14,0.14,0.21,0.21c-0.09,0.06-0.18,0.12-0.26,0.2c0.1,0,0.2,0.01,0.3,0.01c-0.03,0.03-0.07,0.06-0.1,0.09c0.09-0.03,0.19-0.05,0.28-0.08c2.3,2.42,3.46,6.34,2.45,9.72c2.12-3.04,1.02-7.72-2.05-9.7c1.35,0.12,2.62,0.57,3.69,2.07c1.3,1.83,1.28,4.46,1.22,7.14c0.74-2.12,1.29-4.99,0.28-7.25c-0.47-1.05-1.4-1.89-2.45-2.35c1.03,0.05,2.05,0.28,2.97,0.74c1.97,1,3.38,3.04,3.62,5.24C304.43,263.59,303.18,260.75,300.93,259.51z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.88,263.9c1.43-1.74,3.91-2.5,6.08-1.96c0.22-0.01,0.44,0,0.66,0.04c0.07-0.08,0.14-0.17,0.22-0.25c-0.04,0.09-0.08,0.18-0.12,0.28c0.11,0.02,0.21,0.05,0.32,0.08c-0.09,0.04-0.18,0.08-0.28,0.12c0.04,0.02,0.09,0.03,0.13,0.04c-0.1,0.01-0.2,0.02-0.29,0.03c-1.24,3.1-0.88,7.17,1.32,9.94c-3.09-2.04-3.8-6.8-1.68-9.77c-1.21,0.61-2.23,1.5-2.66,3.29c-0.53,2.18,0.46,4.62,1.51,7.08c-1.47-1.69-3.04-4.16-2.94-6.64c0.05-1.14,0.6-2.28,1.41-3.09c-0.94,0.43-1.8,1.02-2.48,1.79c-1.47,1.66-2.02,4.08-1.43,6.21C201.13,268.99,201.24,265.89,202.88,263.9z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.12,263.9c-1.43-1.74-3.91-2.5-6.08-1.96c-0.22-0.01-0.44,0-0.66,0.04c-0.07-0.08-0.14-0.17-0.22-0.25c0.04,0.09,0.08,0.18,0.12,0.28c-0.11,0.02-0.21,0.05-0.32,0.08c0.09,0.04,0.18,0.08,0.28,0.12c-0.04,0.02-0.09,0.03-0.13,0.04c0.1,0.01,0.2,0.02,0.29,0.03c1.24,3.1,0.88,7.17-1.32,9.94c3.09-2.04,3.8-6.8,1.68-9.77c1.21,0.61,2.23,1.5,2.66,3.29c0.53,2.18-0.46,4.62-1.51,7.08c1.47-1.69,3.04-4.16,2.94-6.64c-0.05-1.14-0.6-2.28-1.41-3.09c0.94,0.43,1.8,1.02,2.48,1.79c1.47,1.66,2.02,4.08,1.43,6.21C298.87,268.99,298.76,265.89,297.12,263.9z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.13,263.9c1.43-1.74,3.91-2.5,6.08-1.96c0.22-0.01,0.44,0,0.66,0.04c0.07-0.08,0.14-0.17,0.22-0.25c-0.04,0.09-0.08,0.18-0.12,0.28c0.11,0.02,0.21,0.05,0.32,0.08c-0.09,0.04-0.18,0.08-0.28,0.12c0.04,0.02,0.09,0.03,0.13,0.04c-0.1,0.01-0.2,0.02-0.29,0.03c-1.24,3.1-0.88,7.17,1.32,9.94c-3.09-2.04-3.8-6.8-1.68-9.77c-1.21,0.61-2.23,1.5-2.66,3.29c-0.53,2.18,0.46,4.62,1.51,7.08c-1.47-1.69-3.04-4.16-2.94-6.64c0.05-1.14,0.6-2.28,1.41-3.09c-0.94,0.43-1.8,1.02-2.48,1.79c-1.47,1.66-2.02,4.08-1.43,6.21C199.38,268.99,199.49,265.89,201.13,263.9z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.87,263.9c-1.43-1.74-3.91-2.5-6.08-1.96c-0.22-0.01-0.44,0-0.66,0.04c-0.07-0.08-0.14-0.17-0.22-0.25c0.04,0.09,0.08,0.18,0.12,0.28c-0.11,0.02-0.21,0.05-0.32,0.08c0.09,0.04,0.18,0.08,0.28,0.12c-0.04,0.02-0.09,0.03-0.13,0.04c0.1,0.01,0.2,0.02,0.29,0.03c1.24,3.1,0.88,7.17-1.32,9.94c3.09-2.04,3.8-6.8,1.68-9.77c1.21,0.61,2.23,1.5,2.66,3.29c0.53,2.18-0.46,4.62-1.51,7.08c1.47-1.69,3.04-4.16,2.94-6.64c-0.05-1.14-0.6-2.28-1.41-3.09c0.94,0.43,1.8,1.02,2.48,1.79c1.47,1.66,2.02,4.08,1.43,6.21C300.62,268.99,300.51,265.89,298.87,263.9z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.78,267.79c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.54,272.68,199.95,269.61,201.78,267.79z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.22,267.79c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.46,272.68,300.05,269.61,298.22,267.79z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.78,267.79c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.54,272.68,199.95,269.61,201.78,267.79z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.22,267.79c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.46,272.68,300.05,269.61,298.22,267.79z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.03,260.04c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.79,264.93,200.2,261.86,202.03,260.04z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.97,260.04c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.21,264.93,299.8,261.86,297.97,260.04z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.03,258.29c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.79,263.18,200.2,260.11,202.03,258.29z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.97,258.29c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.21,263.18,299.8,260.11,297.97,258.29z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.03,258.29c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.79,263.18,200.2,260.11,202.03,258.29z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.97,258.29c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.21,263.18,299.8,260.11,297.97,258.29z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M202.03,257.29c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.79,262.18,200.2,259.11,202.03,257.29z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M297.97,257.29c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.21,262.18,299.8,259.11,297.97,257.29z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.78,258.29c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.54,263.18,199.95,260.11,201.78,258.29z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.22,258.29c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.46,263.18,300.05,260.11,298.22,258.29z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M201.78,257.79c1.59-1.59,4.14-2.1,6.25-1.35c0.22,0.01,0.43,0.04,0.65,0.11c0.08-0.08,0.16-0.16,0.24-0.23c-0.05,0.09-0.1,0.18-0.15,0.26c0.1,0.03,0.21,0.07,0.31,0.12c-0.1,0.03-0.19,0.06-0.29,0.09c0.04,0.02,0.08,0.03,0.12,0.06c-0.1,0-0.2,0-0.29,0c-1.54,2.96-1.58,7.05,0.33,10.02c-2.87-2.34-3.11-7.15-0.7-9.89c-1.27,0.49-2.37,1.27-2.98,3.01c-0.74,2.12,0,4.64,0.8,7.2c-1.29-1.83-2.61-4.45-2.27-6.9c0.16-1.13,0.82-2.21,1.71-2.93c-0.97,0.33-1.9,0.83-2.65,1.53c-1.62,1.51-2.42,3.85-2.04,6.04C199.54,262.68,199.95,259.61,201.78,257.79z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M298.22,257.79c-1.59-1.59-4.14-2.1-6.25-1.35c-0.22,0.01-0.43,0.04-0.65,0.11c-0.08-0.08-0.16-0.16-0.24-0.23c0.05,0.09,0.1,0.18,0.15,0.26c-0.1,0.03-0.21,0.07-0.31,0.12c0.1,0.03,0.19,0.06,0.29,0.09c-0.04,0.02-0.08,0.03-0.12,0.06c0.1,0,0.2,0,0.29,0c1.54,2.96,1.58,7.05-0.33,10.02c2.87-2.34,3.11-7.15,0.7-9.89c1.27,0.49,2.37,1.27,2.98,3.01c0.74,2.12,0,4.64-0.8,7.2c1.29-1.83,2.61-4.45,2.27-6.9c-0.16-1.13-0.82-2.21-1.71-2.93c0.97,0.33,1.9,0.83,2.65,1.53c1.62,1.51,2.42,3.85,2.04,6.04C300.46,262.68,300.05,259.61,298.22,257.79z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_aorwi = {
  name: "Eyelash 18",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.09,251.74c-1.81,7.13-8.3,12.81-15.6,13.68c-7.3,0.86-14.94-3.15-18.36-9.66c-0.99-1.88-1.69-3.99-3.19-5.5c-1.5-1.51-4.23-2.12-5.69-0.58c1.69,9.96,10.97,18.08,21.07,18.43C218.39,268.46,228.21,261,230.09,251.74z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.91,251.74c1.81,7.13,8.3,12.81,15.6,13.68c7.3,0.86,14.94-3.15,18.36-9.66c0.99-1.88,1.69-3.99,3.19-5.5c1.5-1.51,4.23-2.12,5.69-0.58c-1.69,9.96-10.97,18.08-21.07,18.43C281.61,268.46,271.79,261,269.91,251.74z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.52,246.88c-3.4,6.41-10.45,12.07-18.33,14.23c-10.24,2.81-16.48-2.02-20.58-10.73c-1.04-2.21-4.38-2.81-6.37-1.37c4.1,9,15.28,15.54,24.92,13.36C221.61,259.79,228.01,253.62,230.52,246.88z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.48,246.88c3.4,6.41,10.45,12.07,18.33,14.23c10.24,2.81,16.48-2.02,20.58-10.73c1.04-2.21,4.38-2.81,6.37-1.37c-4.1,9-15.28,15.54-24.92,13.36C278.39,259.79,271.99,253.62,269.48,246.88z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.13,256.91c-7.01,7.77-12.98,7.87-21.12,4.41c-8.14-3.46-16.39-12-17.41-20.78c-0.42-3.64-5.56-5.67-8.35-3.3c1.86,6.33,3.56,10.88,7.87,15.31C210.2,272.15,224.75,266.18,230.13,256.91z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.87,256.91c7.01,7.77,12.98,7.87,21.12,4.41c8.14-3.46,16.39-12,17.41-20.78c0.42-3.64,5.56-5.67,8.35-3.3c-1.86,6.33-3.56,10.88-7.87,15.31C289.8,272.15,275.25,266.18,269.87,256.91z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M229.52,255.74c-5.56,8.38-24.55,6.97-33.23,4.69c-1.8-0.47-3.71-2.54-5.42-3.27c-1.71-0.73-5.34,0.24-6.16,1.91c3.15,1.16,6.63,4.76,12.87,5.56c12.5,1.6,25.06-0.63,29.43-5C230.97,255.67,231.07,253.41,229.52,255.74z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M270.48,255.74c5.56,8.38,24.55,6.97,33.23,4.69c1.8-0.47,3.71-2.54,5.42-3.27c1.71-0.73,5.34,0.24,6.16,1.91c-3.15,1.16-6.63,4.76-12.87,5.56c-12.5,1.6-25.06-0.63-29.43-5C269.03,255.67,268.93,253.41,270.48,255.74z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.98,259.75c-5.65,2.71-12.19,2.68-18.45,2.42c-3.83-0.16-7.72-0.39-11.34-1.64s-7.01-3.69-8.58-7.19c-0.72-1.6-1.05-4.14-2.03-5.6c-0.98-1.46-2.99-1.73-4.51-0.85c1.46,6.76,7.86,13.76,12.89,15.35c4.09,1.29,11.23,1.38,21.56,1.42C224.88,263.68,227.98,262.68,230.98,259.75z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.02,259.75c5.65,2.71,12.19,2.68,18.45,2.42c3.83-0.16,7.72-0.39,11.34-1.64c3.62-1.26,7.01-3.69,8.58-7.19c0.72-1.6,1.05-4.14,2.03-5.6c0.98-1.46,2.99-1.73,4.51-0.85c-1.46,6.76-7.86,13.76-12.89,15.35c-4.09,1.29-11.23,1.38-21.56,1.42C275.12,263.68,272.02,262.68,269.02,259.75z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M228.85,261.01c-2.15,2.91-2.73,2.96-4.71,3.93c-3.67,1.8-13.14,1.11-16.42,1.04c-3.4-0.07-7.09-0.75-9.7-2.87c-1.26-1.03-2.14-2.42-3.03-3.76c-0.89-1.34-1.86-2.69-3.23-3.58c-1.37-0.88-3.24-1.21-4.66-0.4c2.75,3.87,6.37,7.6,10.45,10.13c3.66,2.27,16.26,2.27,23.82,1.54C223.78,266.81,228.44,263.85,228.85,261.01z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M271.15,261.01c2.15,2.91,2.73,2.96,4.71,3.93c3.67,1.8,13.14,1.11,16.42,1.04c3.4-0.07,7.09-0.75,9.7-2.87c1.26-1.03,2.14-2.42,3.03-3.76s1.86-2.69,3.23-3.58c1.37-0.88,3.24-1.21,4.66-0.4c-2.75,3.87-6.37,7.6-10.45,10.13c-3.66,2.27-16.26,2.27-23.82,1.54C276.22,266.81,271.56,263.85,271.15,261.01z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.59,252.74c-1.97,7.13-9.01,12.81-16.93,13.68c-7.92,0.86-16.21-3.15-19.93-9.66c-1.08-1.88-1.83-3.99-3.46-5.5c-1.63-1.51-4.59-2.12-6.18-0.58c1.83,9.96,11.91,18.08,22.86,18.43C218.89,269.46,229.55,262,231.59,252.74z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.41,252.74c1.97,7.13,9.01,12.81,16.93,13.68c7.92,0.86,16.21-3.15,19.93-9.66c1.08-1.88,1.83-3.99,3.46-5.5c1.63-1.51,4.59-2.12,6.18-0.58c-1.83,9.96-11.91,18.08-22.86,18.43C281.11,269.46,270.45,262,268.41,252.74z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.21,251.98c-3.01,2.55-9.03,6.6-19.91,6.83c-5.06,0.11-12.35-1.91-16.04-5.38c-1.63-1.53-2.97-3.36-4.7-4.76c-1.73-1.41-4.04-2.38-6.19-1.79c4.14,3.93,8.36,7.91,13.43,10.53c5.45,2.82,12.29,2.93,18.36,2C222.21,258.47,229.69,255.24,231.21,251.98z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.79,251.98c3.01,2.55,9.03,6.6,19.91,6.83c5.06,0.11,12.35-1.91,16.04-5.38c1.63-1.53,2.97-3.36,4.7-4.76c1.73-1.41,4.04-2.38,6.19-1.79c-4.14,3.93-8.36,7.91-13.43,10.53c-5.45,2.82-12.29,2.93-18.36,2C277.79,258.47,270.31,255.24,268.79,251.98z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.21,251.38c-2.89,2.03-8.68,5.24-19.12,5.42c-4.86,0.08-11.87-1.52-15.41-4.27c-1.56-1.21-2.85-2.66-4.52-3.78s-3.88-1.89-5.95-1.42c3.98,3.12,8.03,6.28,12.9,8.36c5.24,2.24,11.81,2.33,17.63,1.59S228.75,253.97,230.21,251.38z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.79,251.38c2.89,2.03,8.68,5.24,19.12,5.42c4.86,0.08,11.87-1.52,15.41-4.27c1.56-1.21,2.85-2.66,4.52-3.78s3.88-1.89,5.95-1.42c-3.98,3.12-8.03,6.28-12.9,8.36c-5.24,2.24-11.81,2.33-17.63,1.59S271.25,253.97,269.79,251.38z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M232.21,252.48c-3.13,1.83-9.4,4.74-20.72,4.9c-5.27,0.08-12.86-1.37-16.69-3.86c-1.69-1.1-2.09-2.41-3.89-3.42c-1.8-1.01-5.2-1.71-7.45-1.29c4.31,2.82,8.7,5.68,13.97,7.56c5.67,2.02,12.79,2.11,19.1,1.44C222.85,257.14,230.63,254.83,232.21,252.48z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M267.79,252.48c3.13,1.83,9.4,4.74,20.72,4.9c5.27,0.08,12.86-1.37,16.69-3.86c1.69-1.1,2.09-2.41,3.89-3.42c1.8-1.01,5.2-1.71,7.44-1.29c-4.31,2.82-8.7,5.68-13.97,7.56c-5.67,2.02-12.79,2.11-19.1,1.44S269.37,254.83,267.79,252.48z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.72,252.7c-3.17,1.78-9.46,4.49-20.34,3.79c-5.07-0.33-12.23-2.51-15.7-5.55c-1.53-1.34-2.76-2.89-4.4-4.15c-1.64-1.25-3.89-2.21-6.08-1.92c3.89,3.44,7.85,6.93,12.76,9.41c5.27,2.67,12.1,3.31,18.23,3.07C222.31,257.1,230,255.16,231.72,252.7z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.28,252.7c3.17,1.78,9.46,4.49,20.34,3.79c5.07-0.33,12.23-2.51,15.7-5.55c1.53-1.34,2.76-2.89,4.4-4.15s3.89-2.21,6.08-1.92c-3.89,3.44-7.85,6.93-12.76,9.41c-5.27,2.67-12.1,3.31-18.23,3.07C277.69,257.1,270,255.16,268.28,252.7z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M230.41,254.75c-3.33,1.45-9.36,4.02-20.12,2.24c-5.01-0.83-11.92-3.72-15.06-7.09c-1.39-1.49-2.45-3.16-3.96-4.57s-3.65-2.59-5.86-2.52c3.53,3.81,7.12,7.68,11.75,10.64c4.98,3.18,11.71,4.51,17.83,4.88C221.11,258.69,228.45,257.02,230.41,254.75z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M269.59,254.75c3.33,1.45,9.36,4.02,20.12,2.24c5.01-0.83,11.92-3.72,15.06-7.09c1.39-1.49,2.45-3.16,3.96-4.57c1.51-1.41,3.65-2.59,5.86-2.52c-3.53,3.81-7.12,7.68-11.75,10.64c-4.98,3.18-11.71,4.51-17.83,4.88S271.55,257.02,269.59,254.75z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M231.71,250.8c-3.02,2.03-9.06,5.24-19.97,5.42c-5.08,0.08-12.39-1.52-16.1-4.27c-1.63-1.21-2.98-2.66-4.72-3.78s-4.05-1.89-6.21-1.42c4.16,3.12,8.38,6.28,13.47,8.36c5.47,2.24,12.33,2.33,18.42,1.59C222.69,255.95,230.19,253.39,231.71,250.8z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M268.29,250.8c3.02,2.03,9.06,5.24,19.97,5.42c5.08,0.08,12.39-1.52,16.1-4.27c1.63-1.21,2.98-2.66,4.72-3.78c1.74-1.12,4.05-1.89,6.21-1.42c-4.16,3.12-8.38,6.28-13.47,8.36c-5.47,2.24-12.33,2.33-18.42,1.59C277.31,255.95,269.81,253.39,268.29,250.8z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fa9sr = {
  name: "Eyelash 19",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M226.74,259.84c-6.55,4.67-11.98,6.35-18.41,4.84c-6.43-1.5-11.98-6.34-14.36-12.5c-0.92-2.38-4.07-3.6-6.35-2.45s-3.18,4.4-1.81,6.56c1.37,2.16,4.7,2.73,6.71,1.15c-2.23,1.87-2.78,3.16-2.19,5c0.59,1.85,2.21,2.57,4.12,2.86c1.92,0.28,3.86-0.22,5.67-0.93c-0.5,1.84,0.96,4.82,2.84,5.19C208.86,270.76,221.31,266.51,226.74,259.84z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M273.26,259.84c6.55,4.67,11.98,6.35,18.41,4.84s11.98-6.34,14.36-12.5c0.92-2.38,4.07-3.6,6.35-2.45c2.28,1.15,3.18,4.4,1.81,6.56c-1.37,2.16-4.7,2.73-6.71,1.15c2.23,1.87,2.78,3.16,2.19,5c-0.59,1.85-2.21,2.57-4.12,2.86s-3.86-0.22-5.67-0.93c0.5,1.84-0.96,4.82-2.84,5.19C291.14,270.76,278.69,266.51,273.26,259.84z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M228.96,249.8c-5.46,6.47-9.62,10.42-19.71,12c-7.73,1.21-15.77-3.86-17.65-11.86c-0.49-2.09-4.94-2.96-6.71-1.75c-1.77,1.22-2.06,4.09-0.57,5.64c1.49,1.54,4.38,1.35,5.65-0.38c-1.82,0.1-2.98,2.36-2.39,4.08c0.59,1.73,2.4,2.8,4.21,3.05c1.81,0.25,3.64-0.18,5.41-0.6c-1.77-0.08-3.45,1.49-3.48,3.27c-0.03,1.77,1.6,3.4,3.37,3.38C209.53,266.48,222.47,260.02,228.96,249.8z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M271.04,249.8c5.46,6.47,9.62,10.42,19.71,12c7.73,1.21,15.77-3.86,17.65-11.86c0.49-2.09,4.94-2.96,6.71-1.75c1.77,1.22,2.06,4.09,0.57,5.64s-4.38,1.35-5.65-0.38c1.82,0.1,2.98,2.36,2.39,4.08c-0.59,1.73-2.4,2.8-4.21,3.05c-1.81,0.25-3.64-0.18-5.41-0.6c1.77-0.08,3.45,1.49,3.48,3.27c0.03,1.77-1.6,3.4-3.37,3.38C290.47,266.48,277.53,260.02,271.04,249.8z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M220.2,263.33c-6.81,0.47-13.77-1.76-19.04-6.1c-5.27-4.34-8.79-10.75-9.63-17.52c-0.29-2.35-2.86-4.16-5.17-3.63c-2.31,0.52-3.85,3.26-3.1,5.51c0.75,2.25,3.62,3.51,5.79,2.54c-2.13-0.66-4.55,1.04-4.98,3.23c-0.43,2.19,0.9,4.5,2.86,5.57c1.96,1.07,4.39,1.03,6.49,0.28c-1.49-0.84-3.62-0.19-4.39,1.33c-0.77,1.53-0.03,3.63,1.53,4.33C199.88,263.08,210.3,264.85,220.2,263.33z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M279.8,263.33c6.81,0.47,13.77-1.76,19.04-6.1c5.27-4.34,8.79-10.75,9.63-17.52c0.29-2.35,2.86-4.16,5.17-3.63c2.31,0.52,3.85,3.26,3.1,5.51c-0.75,2.25-3.62,3.51-5.79,2.54c2.13-0.66,4.55,1.04,4.98,3.23c0.43,2.19-0.9,4.5-2.86,5.57c-1.96,1.07-4.39,1.03-6.49,0.28c1.49-0.84,3.62-0.19,4.39,1.33c0.77,1.53,0.03,3.63-1.53,4.33C300.12,263.08,289.7,264.85,279.8,263.33z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M225.45,259.51c-5.43,3.42-12.31,3.14-18.72,2.74c-3.57-0.22-7.18-0.45-10.62-1.46c-2.01-0.59-4.24-1.82-4.44-3.9c-1.22-0.29-2.46-0.57-3.71-0.51s-2.53,0.52-3.32,1.5s-0.92,2.51-0.09,3.45c0.6,0.67,1.53,0.92,2.42,1.07c2.04,0.33,4.14,0.27,6.16-0.18c-0.92,0.15-1.85,0.32-2.65,0.78c-0.8,0.47-1.45,1.31-1.42,2.24c0.05,1.44,1.61,2.33,3.02,2.64c3.52,0.78,7.41-0.33,9.98-2.85c-0.74,0.69-1.65,5.33,5.49,3.27c2.01-0.58,4.78-2.81,6.76-3.47C222.97,261.98,221.32,262.09,225.45,259.51z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M274.55,259.51c5.43,3.42,12.31,3.14,18.72,2.74c3.57-0.22,7.18-0.45,10.62-1.46c2.01-0.59,4.24-1.82,4.44-3.9c1.22-0.29,2.46-0.57,3.71-0.51s2.53,0.52,3.32,1.5c0.79,0.97,0.92,2.51,0.09,3.45c-0.6,0.67-1.53,0.92-2.42,1.07c-2.04,0.33-4.14,0.27-6.16-0.18c0.92,0.15,1.85,0.32,2.65,0.78s1.45,1.31,1.42,2.24c-0.05,1.44-1.61,2.33-3.02,2.64c-3.52,0.78-7.41-0.33-9.98-2.85c0.74,0.69,1.65,5.33-5.49,3.27c-2.01-0.58-4.78-2.81-6.76-3.47C277.03,261.98,278.68,262.09,274.55,259.51z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M222.44,261.26c-5.96,0.54-12.19,0.77-18.11-0.11c-5.92-0.88-10.64-5.25-12.55-10.92c-0.28-0.84-1.02-2.01-1.69-2.58c-0.74-0.63-1.81-0.66-2.78-0.59c-1.36,0.1-2.86,0.43-3.61,1.56c-0.95,1.45-0.11,3.52,1.32,4.49c1.43,0.97,3.25,1.13,4.98,1.27c-1.11-0.54-2.53-0.36-3.48,0.43s-1.37,2.16-1.04,3.35c0.48,1.71,2.33,2.75,4.11,2.86c1.78,0.1,3.51-0.52,5.17-1.14c-1.57,0.3-3.45,0.89-3.8,2.45c-0.21,0.95,0.3,1.95,1.06,2.54c0.77,0.59,1.75,0.84,2.71,0.93c3.92,0.38,7.68-1.46,11.56-2.11c2.81-0.47,4.68-0.57,7.53-0.73C216.66,262.8,220.59,262.56,222.44,261.26z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M277.56,261.26c5.96,0.54,12.19,0.77,18.11-0.11c5.92-0.88,10.64-5.25,12.55-10.92c0.28-0.84,1.02-2.01,1.69-2.58c0.74-0.63,1.81-0.66,2.78-0.59c1.36,0.1,2.86,0.43,3.61,1.56c0.95,1.45,0.11,3.52-1.32,4.49c-1.43,0.97-3.25,1.13-4.98,1.27c1.11-0.54,2.53-0.36,3.48,0.43s1.37,2.16,1.04,3.35c-0.48,1.71-2.33,2.75-4.11,2.86s-3.51-0.52-5.17-1.14c1.57,0.3,3.45,0.89,3.8,2.45c0.21,0.95-0.3,1.95-1.06,2.54c-0.77,0.59-1.75,0.84-2.71,0.93c-3.92,0.38-7.68-1.46-11.56-2.11c-2.81-0.47-4.68-0.57-7.53-0.73C283.34,262.8,279.41,262.56,277.56,261.26z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M221.44,266.08c-5.56,0.51-11.37,0.72-16.89-0.1c-5.52-0.82-9.91-4.88-11.7-10.16c-0.26-0.78-0.95-1.87-1.58-2.4c-0.69-0.59-1.69-0.62-2.59-0.55c-1.26,0.09-2.67,0.4-3.37,1.45c-0.89,1.35-0.11,3.28,1.23,4.18c1.34,0.9,3.03,1.06,4.64,1.18c-1.04-0.5-2.36-0.34-3.24,0.4c-0.88,0.74-1.28,2.01-0.97,3.11c0.45,1.6,2.17,2.56,3.83,2.66c1.66,0.1,3.27-0.49,4.82-1.06c-1.47,0.28-3.22,0.82-3.54,2.28c-0.19,0.88,0.28,1.81,0.99,2.36c0.71,0.55,1.63,0.78,2.53,0.87c3.65,0.35,7.16-1.35,10.78-1.96c2.62-0.44,4.36-0.53,7.01-0.68C216.05,267.51,219.72,267.28,221.44,266.08z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M278.56,266.08c5.56,0.51,11.37,0.72,16.89-0.1c5.52-0.82,9.91-4.88,11.7-10.16c0.26-0.78,0.95-1.87,1.58-2.4c0.69-0.59,1.69-0.62,2.59-0.55c1.26,0.09,2.67,0.4,3.37,1.45c0.89,1.35,0.11,3.28-1.23,4.18c-1.34,0.9-3.03,1.06-4.64,1.18c1.04-0.5,2.36-0.34,3.24,0.4c0.88,0.74,1.28,2.01,0.97,3.11c-0.45,1.6-2.17,2.56-3.83,2.66c-1.66,0.1-3.27-0.49-4.82-1.06c1.47,0.28,3.22,0.82,3.54,2.28c0.19,0.88-0.28,1.81-0.99,2.36c-0.71,0.55-1.63,0.78-2.53,0.87c-3.65,0.35-7.16-1.35-10.78-1.96c-2.62-0.44-4.36-0.53-7.01-0.68C283.95,267.51,280.28,267.28,278.56,266.08z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M226.24,261.09c-6.55,4.67-11.98,6.35-18.41,4.84c-6.43-1.5-11.98-6.34-14.36-12.5c-0.92-2.38-4.07-3.6-6.35-2.45s-3.18,4.4-1.81,6.56c1.37,2.16,4.7,2.73,6.71,1.15c-2.23,1.87-2.78,3.16-2.19,5c0.59,1.85,2.21,2.57,4.12,2.86c1.92,0.28,3.86-0.22,5.67-0.93c-0.5,1.84,0.96,4.82,2.84,5.19C208.36,272.01,220.81,267.76,226.24,261.09z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M273.76,261.09c6.55,4.67,11.98,6.35,18.41,4.84s11.98-6.34,14.36-12.5c0.92-2.38,4.07-3.6,6.35-2.45c2.28,1.15,3.18,4.4,1.81,6.56c-1.37,2.16-4.7,2.73-6.71,1.15c2.23,1.87,2.78,3.16,2.19,5c-0.59,1.85-2.21,2.57-4.12,2.86s-3.86-0.22-5.67-0.93c0.5,1.84-0.96,4.82-2.84,5.19C291.64,272.01,279.19,267.76,273.76,261.09z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.67,249c-1.23-0.59-2.55-1.12-3.91-1.05c-1.36,0.07-2.77,0.91-3.1,2.24c-0.41,1.67,1.02,3.29,2.6,3.96c2.12,0.89,4.68,0.63,6.58-0.66c-1.1-0.02-2.24,0.19-3.14,0.83c-0.9,0.64-1.5,1.76-1.29,2.84c0.29,1.53,1.97,2.39,3.52,2.55c2.39,0.24,4.88-0.71,6.5-2.49c-1.56,0.39-2.16,2.57-1.32,3.94c0.85,1.37,2.65,1.91,4.24,1.69s3.03-1.06,4.38-1.94c1.35-0.87,2.7-1.81,4.25-2.26C203.38,259.52,195.42,255.51,191.67,249z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.33,249c1.23-0.59,2.55-1.12,3.91-1.05c1.36,0.07,2.77,0.91,3.1,2.24c0.41,1.67-1.02,3.29-2.6,3.96c-2.12,0.89-4.68,0.63-6.58-0.66c1.1-0.02,2.24,0.19,3.14,0.83c0.9,0.64,1.5,1.76,1.29,2.84c-0.29,1.53-1.97,2.39-3.52,2.55c-2.39,0.24-4.88-0.71-6.5-2.49c1.56,0.39,2.16,2.57,1.32,3.94c-0.85,1.37-2.65,1.91-4.24,1.69c-1.6-0.22-3.03-1.06-4.38-1.94c-1.35-0.87-2.7-1.81-4.25-2.26C296.62,259.52,304.58,255.51,308.33,249z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.42,247.25c-1.23-0.59-2.55-1.12-3.91-1.05c-1.36,0.07-2.77,0.91-3.1,2.24c-0.41,1.67,1.02,3.29,2.6,3.96c2.12,0.89,4.68,0.63,6.58-0.66c-1.1-0.02-2.24,0.19-3.14,0.83c-0.9,0.64-1.5,1.76-1.29,2.84c0.29,1.53,1.97,2.39,3.52,2.55c2.39,0.24,4.88-0.71,6.5-2.49c-1.56,0.39-2.16,2.57-1.32,3.94c0.85,1.37,2.65,1.91,4.24,1.69s3.03-1.06,4.38-1.94c1.35-0.87,2.7-1.81,4.25-2.26C203.13,257.77,195.17,253.76,191.42,247.25z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.58,247.25c1.23-0.59,2.55-1.12,3.91-1.05c1.36,0.07,2.77,0.91,3.1,2.24c0.41,1.67-1.02,3.29-2.6,3.96c-2.12,0.89-4.68,0.63-6.58-0.66c1.1-0.02,2.24,0.19,3.14,0.83c0.9,0.64,1.5,1.76,1.29,2.84c-0.29,1.53-1.97,2.39-3.52,2.55c-2.39,0.24-4.88-0.71-6.5-2.49c1.56,0.39,2.16,2.57,1.32,3.94c-0.85,1.37-2.65,1.91-4.24,1.69c-1.6-0.22-3.03-1.06-4.38-1.94c-1.35-0.87-2.7-1.81-4.25-2.26C296.87,257.77,304.83,253.76,308.58,247.25z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.78,249.93c-1.29-0.46-2.65-0.85-4-0.63c-1.35,0.22-2.66,1.2-2.85,2.55c-0.24,1.7,1.36,3.17,3,3.66c2.2,0.66,4.72,0.14,6.47-1.34c-1.1,0.1-2.21,0.42-3.03,1.15c-0.83,0.73-1.3,1.91-0.99,2.96c0.45,1.49,2.22,2.17,3.77,2.16c2.41-0.01,4.78-1.22,6.2-3.16c-1.51,0.55-1.88,2.78-0.89,4.05c0.99,1.27,2.83,1.62,4.4,1.23c1.56-0.39,2.9-1.38,4.16-2.39c1.25-1.01,2.5-2.08,3.99-2.69C202.07,256.56,195.19,256.01,190.78,249.93z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.22,249.93c1.29-0.46,2.65-0.85,4-0.63c1.35,0.22,2.66,1.2,2.85,2.55c0.24,1.7-1.36,3.17-3,3.66c-2.2,0.66-4.72,0.14-6.47-1.34c1.1,0.1,2.21,0.42,3.03,1.15c0.83,0.73,1.3,1.91,0.99,2.96c-0.45,1.49-2.22,2.17-3.77,2.16c-2.41-0.01-4.78-1.22-6.2-3.16c1.51,0.55,1.88,2.78,0.89,4.05c-0.99,1.27-2.83,1.62-4.4,1.23s-2.9-1.38-4.16-2.39c-1.25-1.01-2.5-2.08-3.99-2.69C297.93,256.56,304.81,256.01,309.22,249.93z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.67,246c-1.23-0.59-2.55-1.12-3.91-1.05c-1.36,0.07-2.77,0.91-3.1,2.24c-0.41,1.67,1.02,3.29,2.6,3.96c2.12,0.89,4.68,0.63,6.58-0.66c-1.1-0.02-2.24,0.19-3.14,0.83c-0.9,0.64-1.5,1.76-1.29,2.84c0.29,1.53,1.97,2.39,3.52,2.55c2.39,0.24,4.88-0.71,6.5-2.49c-1.56,0.39-2.16,2.57-1.32,3.94c0.85,1.37,2.65,1.91,4.24,1.69c1.6-0.22,3.03-1.06,4.38-1.94c1.35-0.87,2.7-1.06,4.25-1.51C201.2,254.53,196.78,252.4,190.67,246z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.33,246c1.23-0.59,2.55-1.12,3.91-1.05c1.36,0.07,2.77,0.91,3.1,2.24c0.41,1.67-1.02,3.29-2.6,3.96c-2.12,0.89-4.68,0.63-6.58-0.66c1.1-0.02,2.24,0.19,3.14,0.83c0.9,0.64,1.5,1.76,1.29,2.84c-0.29,1.53-1.97,2.39-3.52,2.55c-2.39,0.24-4.88-0.71-6.5-2.49c1.56,0.39,2.16,2.57,1.32,3.94c-0.85,1.37-2.65,1.91-4.24,1.69c-1.6-0.22-3.03-1.06-4.38-1.94c-1.35-0.87-2.7-1.06-4.25-1.51C298.8,254.53,303.22,252.4,309.33,246z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.08,244.93c-1.17-0.71-2.43-1.37-3.79-1.43c-1.36-0.06-2.85,0.64-3.3,1.93c-0.57,1.62,0.69,3.37,2.2,4.19c2.02,1.09,4.59,1.09,6.61-0.01c-1.09-0.13-2.25-0.03-3.2,0.52c-0.95,0.55-1.66,1.61-1.56,2.7c0.14,1.55,1.73,2.57,3.26,2.88c2.36,0.47,4.93-0.23,6.71-1.84c-1.59,0.24-2.4,2.34-1.69,3.79c0.71,1.45,2.45,2.16,4.06,2.09c1.61-0.07,3.12-0.76,4.55-1.5c1.43-0.74,2.8-0.79,4.38-1.09C200.72,254.45,196.53,251.9,191.08,244.93z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.92,244.93c1.17-0.71,2.43-1.37,3.79-1.43c1.36-0.06,2.85,0.64,3.3,1.93c0.57,1.62-0.69,3.37-2.2,4.19c-2.02,1.09-4.59,1.09-6.61-0.01c1.09-0.13,2.25-0.03,3.2,0.52c0.95,0.55,1.66,1.61,1.56,2.7c-0.14,1.55-1.73,2.57-3.26,2.88c-2.36,0.47-4.93-0.23-6.71-1.84c1.59,0.24,2.4,2.34,1.69,3.79s-2.45,2.16-4.06,2.09c-1.61-0.07-3.12-0.76-4.55-1.5c-1.43-0.74-2.8-0.79-4.38-1.09C299.28,254.45,303.47,251.9,308.92,244.93z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.23,247.94c-1.27-0.51-3.12-0.94-4.48-0.77c-1.36,0.17-2.7,1.11-2.94,2.45c-0.29,1.7,1.25,3.21,2.87,3.76c2.18,0.74,4.71,0.3,6.52-1.12c-1.1,0.06-2.22,0.35-3.07,1.04s-1.37,1.86-1.09,2.93c0.39,1.5,2.14,2.24,3.69,2.29c2.41,0.07,4.82-1.05,6.31-2.94c-1.53,0.5-1.98,2.71-1.04,4.02s2.78,1.72,4.35,1.38c1.58-0.33,2.95-1.27,4.24-2.24c1.29-0.97,3.12-1.5,4.63-2.05C202.34,255.47,197.68,254.54,191.23,247.94z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.77,247.94c1.27-0.51,3.12-0.94,4.48-0.77c1.36,0.17,2.7,1.11,2.94,2.45c0.29,1.7-1.25,3.21-2.87,3.76c-2.18,0.74-4.71,0.3-6.52-1.12c1.1,0.06,2.22,0.35,3.07,1.04s1.37,1.86,1.09,2.93c-0.39,1.5-2.14,2.24-3.69,2.29c-2.41,0.07-4.82-1.05-6.31-2.94c1.53,0.5,1.98,2.71,1.04,4.02s-2.78,1.72-4.35,1.38c-1.58-0.33-2.95-1.27-4.24-2.24c-1.29-0.97-3.12-1.5-4.63-2.05C297.66,255.47,302.32,254.54,308.77,247.94z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_fmalq = {
  name: "Eyelash 20",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.67,249c-0.8-0.93-2.2-1.32-3.38-0.93s-2.06,1.52-2.13,2.74c0.4-0.97,1.26-1.75,2.27-2.07S192.61,248.59,193.67,249z" />
              <path d="M194.28,251.82c-1.04-0.56-2.38-0.52-3.38,0.11s-1.61,1.8-1.54,2.96C189.86,252.81,192.34,251.44,194.28,251.82z" />
              <path d="M195.41,254.15c-0.83-0.32-1.83-0.16-2.51,0.41c-0.68,0.57-1.01,1.51-0.83,2.37C192.45,255.44,193.98,254.32,195.41,254.15z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.33,249c0.8-0.93,2.2-1.32,3.38-0.93s2.06,1.52,2.13,2.74c-0.4-0.97-1.26-1.75-2.27-2.07C308.55,248.43,307.39,248.59,306.33,249z" />
              <path d="M305.72,251.82c1.04-0.56,2.38-0.52,3.38,0.11s1.61,1.8,1.54,2.96C310.14,252.81,307.66,251.44,305.72,251.82z" />
              <path d="M304.59,254.15c0.83-0.32,1.83-0.16,2.51,0.41c0.68,0.57,1.01,1.51,0.83,2.37C307.55,255.44,306.02,254.32,304.59,254.15z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.92,249.25c-0.8-0.93-2.2-1.32-3.38-0.93s-2.06,1.52-2.13,2.74c0.4-0.97,1.26-1.75,2.27-2.07S190.86,248.84,191.92,249.25z" />
              <path d="M192.53,252.07c-1.04-0.56-2.38-0.52-3.38,0.11s-1.61,1.8-1.54,2.96C188.11,253.06,190.59,251.69,192.53,252.07z" />
              <path d="M193.66,254.4c-0.83-0.32-1.83-0.16-2.51,0.41c-0.68,0.57-1.01,1.51-0.83,2.37C190.7,255.69,192.23,254.57,193.66,254.4z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.08,249.25c0.8-0.93,2.2-1.32,3.38-0.93s2.06,1.52,2.13,2.74c-0.4-0.97-1.26-1.75-2.27-2.07C310.3,248.68,309.14,248.84,308.08,249.25z" />
              <path d="M307.47,252.07c1.04-0.56,2.38-0.52,3.38,0.11s1.61,1.8,1.54,2.96C311.89,253.06,309.41,251.69,307.47,252.07z" />
              <path d="M306.34,254.4c0.83-0.32,1.83-0.16,2.51,0.41c0.68,0.57,1.01,1.51,0.83,2.37C309.3,255.69,307.77,254.57,306.34,254.4z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.59,238.77c-0.68-1.03-2.01-1.58-3.23-1.35c-1.22,0.23-2.24,1.25-2.46,2.44c0.52-0.91,1.47-1.58,2.52-1.76C189.47,237.92,190.59,238.22,191.59,238.77z" />
              <path d="M191.84,241.64c-0.96-0.69-2.29-0.82-3.36-0.33c-1.07,0.49-1.83,1.58-1.9,2.74C187.32,242.06,189.96,241.02,191.84,241.64z" />
              <path d="M192.66,244.1c-0.78-0.42-1.8-0.39-2.54,0.09s-1.2,1.37-1.13,2.24C189.56,245,191.22,244.08,192.66,244.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.41,238.77c0.68-1.03,2.01-1.58,3.23-1.35c1.22,0.23,2.24,1.25,2.46,2.44c-0.52-0.91-1.47-1.58-2.52-1.76S309.41,238.22,308.41,238.77z" />
              <path d="M308.16,241.64c0.96-0.69,2.29-0.82,3.36-0.33c1.07,0.49,1.83,1.58,1.9,2.74C312.68,242.06,310.04,241.02,308.16,241.64z" />
              <path d="M307.34,244.1c0.78-0.42,1.8-0.39,2.54,0.09s1.2,1.37,1.13,2.24C310.44,245,308.78,244.08,307.34,244.1z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.4,257.79c-1.19-0.31-2.56,0.16-3.31,1.15c-0.75,0.99-0.83,2.42-0.2,3.46c-0.22-1.03,0.04-2.16,0.7-2.99C190.25,258.58,191.29,258.05,192.4,257.79z" />
              <path d="M194.5,259.76c-1.17,0.13-2.25,0.92-2.72,2c-0.47,1.08-0.31,2.39,0.41,3.31C191.42,263.09,192.68,260.55,194.5,259.76z" />
              <path d="M196.75,261.04c-0.86,0.21-1.6,0.91-1.84,1.76c-0.24,0.85,0.02,1.82,0.66,2.42C195.04,263.78,195.67,261.99,196.75,261.04z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.6,257.79c1.19-0.31,2.56,0.16,3.31,1.15c0.75,0.99,0.83,2.42,0.2,3.46c0.22-1.03-0.04-2.16-0.7-2.99S308.71,258.05,307.6,257.79z" />
              <path d="M305.5,259.76c1.17,0.13,2.25,0.92,2.72,2s0.31,2.39-0.41,3.31C308.58,263.09,307.32,260.55,305.5,259.76z" />
              <path d="M303.25,261.04c0.86,0.21,1.6,0.91,1.84,1.76c0.24,0.85-0.02,1.82-0.66,2.42C304.96,263.78,304.33,261.99,303.25,261.04z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.67,249c-0.8-0.93-2.2-1.32-3.38-0.93s-2.06,1.52-2.13,2.74c0.4-0.97,1.26-1.75,2.27-2.07C189.45,248.43,190.61,248.59,191.67,249z" />
              <path d="M192.28,251.82c-1.04-0.56-2.38-0.52-3.38,0.11c-1,0.62-1.61,1.8-1.54,2.96C187.86,252.81,190.34,251.44,192.28,251.82z" />
              <path d="M193.41,254.15c-0.83-0.32-1.83-0.16-2.51,0.41c-0.68,0.57-1.01,1.51-0.83,2.37C190.45,255.44,191.98,254.32,193.41,254.15z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.33,249c0.8-0.93,2.2-1.32,3.38-0.93c1.18,0.39,2.06,1.52,2.13,2.74c-0.4-0.97-1.26-1.75-2.27-2.07C310.55,248.43,309.39,248.59,308.33,249z" />
              <path d="M307.72,251.82c1.04-0.56,2.38-0.52,3.38,0.11c1,0.62,1.61,1.8,1.54,2.96C312.14,252.81,309.66,251.44,307.72,251.82z" />
              <path d="M306.59,254.15c0.83-0.32,1.83-0.16,2.51,0.41c0.68,0.57,1.01,1.51,0.83,2.37C309.55,255.44,308.02,254.32,306.59,254.15z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.92,255.5c-0.8-0.93-2.2-1.32-3.38-0.93s-2.06,1.52-2.13,2.74c0.4-0.97,1.26-1.75,2.27-2.07S191.86,255.09,192.92,255.5z" />
              <path d="M194.03,258.32c-1.04-0.56-2.38-0.52-3.38,0.11c-1,0.62-1.61,1.8-1.54,2.96C189.61,259.31,192.09,257.94,194.03,258.32z" />
              <path d="M195.16,260.65c-0.83-0.32-1.83-0.16-2.51,0.41c-0.68,0.57-1.01,1.51-0.83,2.37C192.2,261.94,193.73,260.82,195.16,260.65z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.08,255.5c0.8-0.93,2.2-1.32,3.38-0.93s2.06,1.52,2.13,2.74c-0.4-0.97-1.26-1.75-2.27-2.07C309.3,254.93,308.14,255.09,307.08,255.5z" />
              <path d="M305.97,258.32c1.04-0.56,2.38-0.52,3.38,0.11c1,0.62,1.61,1.8,1.54,2.96C310.39,259.31,307.91,257.94,305.97,258.32z" />
              <path d="M304.84,260.65c0.83-0.32,1.83-0.16,2.51,0.41c0.68,0.57,1.01,1.51,0.83,2.37C307.8,261.94,306.27,260.82,304.84,260.65z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.67,250c-0.8-0.93-2.2-1.32-3.38-0.93s-2.06,1.52-2.13,2.74c0.4-0.97,1.26-1.75,2.27-2.07S191.61,249.59,192.67,250z" />
              <path d="M193.03,252.32c-1.04-0.56-2.38-0.52-3.38,0.11s-1.61,1.8-1.54,2.96C188.61,253.31,191.09,251.94,193.03,252.32z" />
              <path d="M193.66,254.65c-0.83-0.32-1.83-0.16-2.51,0.41c-0.68,0.57-1.01,1.51-0.83,2.37C190.7,255.94,192.23,254.82,193.66,254.65z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.33,250c0.8-0.93,2.2-1.32,3.38-0.93s2.06,1.52,2.13,2.74c-0.4-0.97-1.26-1.75-2.27-2.07C309.55,249.43,308.39,249.59,307.33,250z" />
              <path d="M306.97,252.32c1.04-0.56,2.38-0.52,3.38,0.11s1.61,1.8,1.54,2.96C311.39,253.31,308.91,251.94,306.97,252.32z" />
              <path d="M306.34,254.65c0.83-0.32,1.83-0.16,2.51,0.41c0.68,0.57,1.01,1.51,0.83,2.37C309.3,255.94,307.77,254.82,306.34,254.65z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.92,249.37c-1.02-0.68-2.47-0.67-3.5,0.02c-1.03,0.69-1.57,2.02-1.31,3.21c0.12-1.04,0.74-2.03,1.63-2.61C189.64,249.42,190.79,249.26,191.92,249.37z" />
              <path d="M193.27,251.92c-1.15-0.26-2.43,0.15-3.22,1.02c-0.79,0.87-1.06,2.17-0.68,3.27C189.29,254.07,191.3,252.08,193.27,251.92z" />
              <path d="M194.99,253.85c-0.89-0.08-1.81,0.35-2.31,1.07c-0.5,0.73-0.57,1.73-0.16,2.51C192.49,255.89,193.66,254.4,194.99,253.85z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.08,249.37c1.02-0.68,2.47-0.67,3.5,0.02c1.03,0.69,1.57,2.02,1.31,3.21c-0.12-1.04-0.74-2.03-1.63-2.61C310.36,249.42,309.21,249.26,308.08,249.37z" />
              <path d="M306.73,251.92c1.15-0.26,2.43,0.15,3.22,1.02c0.79,0.87,1.06,2.17,0.68,3.27C310.71,254.07,308.7,252.08,306.73,251.92z" />
              <path d="M305.01,253.85c0.89-0.08,1.81,0.35,2.31,1.07c0.5,0.73,0.57,1.73,0.16,2.51C307.51,255.89,306.34,254.4,305.01,253.85z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.17,248.37c-1.02-0.68-2.47-0.67-3.5,0.02c-1.03,0.69-1.57,2.02-1.31,3.21c0.12-1.04,0.74-2.03,1.63-2.61C189.89,248.42,191.04,248.26,192.17,248.37z" />
              <path d="M193.52,250.92c-1.15-0.26-2.43,0.15-3.22,1.02c-0.79,0.87-1.06,2.17-0.68,3.27C189.54,253.07,191.55,251.08,193.52,250.92z" />
              <path d="M195.24,252.85c-0.89-0.08-1.81,0.35-2.31,1.07c-0.5,0.73-0.57,1.73-0.16,2.51C192.74,254.89,193.91,253.4,195.24,252.85z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.83,248.37c1.02-0.68,2.47-0.67,3.5,0.02c1.03,0.69,1.57,2.02,1.31,3.21c-0.12-1.04-0.74-2.03-1.63-2.61C310.11,248.42,308.96,248.26,307.83,248.37z" />
              <path d="M306.48,250.92c1.15-0.26,2.43,0.15,3.22,1.02c0.79,0.87,1.06,2.17,0.68,3.27C310.46,253.07,308.45,251.08,306.48,250.92z" />
              <path d="M304.76,252.85c0.89-0.08,1.81,0.35,2.31,1.07c0.5,0.73,0.57,1.73,0.16,2.51C307.26,254.89,306.09,253.4,304.76,252.85z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.71,250.58c-1.06-0.62-2.51-0.53-3.5,0.21c-0.99,0.75-1.46,2.1-1.13,3.28c0.06-1.05,0.62-2.06,1.48-2.69S190.58,250.53,191.71,250.58z" />
              <path d="M193.2,253.05c-1.16-0.19-2.42,0.28-3.16,1.19c-0.74,0.91-0.94,2.22-0.5,3.3C189.34,255.42,191.24,253.32,193.2,253.05z" />
              <path d="M195.03,254.89c-0.89-0.03-1.78,0.44-2.25,1.2c-0.46,0.76-0.47,1.76-0.03,2.51C192.64,257.06,193.72,255.51,195.03,254.89z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.29,250.58c1.06-0.62,2.51-0.53,3.5,0.21c0.99,0.75,1.46,2.1,1.13,3.28c-0.06-1.05-0.62-2.06-1.48-2.69S309.42,250.53,308.29,250.58z" />
              <path d="M306.8,253.05c1.16-0.19,2.42,0.28,3.16,1.19c0.74,0.91,0.94,2.22,0.5,3.3C310.66,255.42,308.76,253.32,306.8,253.05z" />
              <path d="M304.97,254.89c0.89-0.03,1.78,0.44,2.25,1.2c0.46,0.76,0.47,1.76,0.03,2.51C307.36,257.06,306.28,255.51,304.97,254.89z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M189.88,245.52c-1.14-0.46-2.56-0.15-3.42,0.73c-0.87,0.89-1.13,2.3-0.63,3.41c-0.09-1.05,0.31-2.13,1.06-2.88C187.65,246.04,188.75,245.64,189.88,245.52z" />
              <path d="M191.72,247.74c-1.18-0.02-2.35,0.64-2.95,1.65s-0.6,2.34,0,3.34C188.26,250.66,189.82,248.3,191.72,247.74z" />
              <path d="M193.8,249.28c-0.88,0.1-1.7,0.71-2.04,1.52s-0.2,1.81,0.35,2.49C191.76,251.79,192.61,250.09,193.8,249.28z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M310.12,245.52c1.14-0.46,2.56-0.15,3.42,0.73s1.13,2.3,0.63,3.41c0.09-1.05-0.31-2.13-1.06-2.88C312.35,246.04,311.25,245.64,310.12,245.52z" />
              <path d="M308.28,247.74c1.18-0.02,2.35,0.64,2.95,1.65s0.6,2.34,0,3.34C311.74,250.66,310.18,248.3,308.28,247.74z" />
              <path d="M306.2,249.28c0.88,0.1,1.7,0.71,2.04,1.52c0.34,0.82,0.2,1.81-0.35,2.49C308.24,251.79,307.39,250.09,306.2,249.28z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.33,244.49c-0.95-0.78-2.39-0.93-3.48-0.36s-1.78,1.84-1.65,3.05c0.23-1.03,0.95-1.94,1.9-2.42S190.21,244.25,191.33,244.49z" />
              <path d="M192.4,247.16c-1.11-0.38-2.43-0.11-3.31,0.66c-0.88,0.78-1.29,2.04-1.03,3.17C188.2,248.88,190.42,247.11,192.4,247.16z" />
              <path d="M193.9,249.27c-0.87-0.18-1.83,0.15-2.41,0.82c-0.58,0.67-0.75,1.66-0.43,2.47C191.19,251.03,192.51,249.68,193.9,249.27z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.67,244.49c0.95-0.78,2.39-0.93,3.48-0.36c1.1,0.58,1.78,1.84,1.65,3.05c-0.23-1.03-0.95-1.94-1.9-2.42S309.79,244.25,308.67,244.49z" />
              <path d="M307.6,247.16c1.11-0.38,2.43-0.11,3.31,0.66c0.88,0.78,1.29,2.04,1.03,3.17C311.8,248.88,309.58,247.11,307.6,247.16z" />
              <path d="M306.1,249.27c0.87-0.18,1.83,0.15,2.41,0.82c0.58,0.67,0.75,1.66,0.43,2.47C308.81,251.03,307.49,249.68,306.1,249.27z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.15,248.04c-1.11-0.53-2.54-0.33-3.47,0.49c-0.92,0.82-1.28,2.22-0.86,3.36c-0.02-1.05,0.45-2.11,1.26-2.8S190.01,248.08,191.15,248.04z" />
              <path d="M192.83,250.38c-1.17-0.1-2.39,0.48-3.05,1.44c-0.67,0.97-0.76,2.29-0.23,3.33C189.17,253.05,190.9,250.8,192.83,250.38z" />
              <path d="M194.8,252.06c-0.89,0.04-1.74,0.59-2.14,1.38c-0.4,0.79-0.33,1.79,0.18,2.5C192.59,254.42,193.55,252.79,194.8,252.06z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.85,248.04c1.11-0.53,2.54-0.33,3.47,0.49s1.28,2.22,0.86,3.36c0.02-1.05-0.45-2.11-1.26-2.8C311.11,248.4,309.99,248.08,308.85,248.04z" />
              <path d="M307.17,250.38c1.17-0.1,2.39,0.48,3.05,1.44c0.67,0.97,0.76,2.29,0.23,3.33C310.83,253.05,309.1,250.8,307.17,250.38z" />
              <path d="M305.2,252.06c0.89,0.04,1.74,0.59,2.14,1.38c0.4,0.79,0.33,1.79-0.18,2.5C307.41,254.42,306.45,252.79,305.2,252.06z" />
            </>
          ),
        },
      },
    },
  },
};

const eyelash_shape_llaof = {
  name: "Eyelash 21",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    // Eye shape 1
    eye_shape_Uugu5: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.84,249.32c-3.16-1.21-7.14,0.47-8.47,3.58C187.95,251.06,191.06,249.96,193.84,249.32z" />
              <path d="M194.83,252.54c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C188.97,254.64,191.68,252.76,194.83,252.54z" />
              <path d="M229.25,253.94c2.09-0.4,4.24,1.74,4.01,3.99C232.66,255.93,230.91,254.39,229.25,253.94z" />
              <path d="M228.28,255.6c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C231.27,258.14,229.78,256.45,228.28,255.6z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.16,249.32c3.16-1.21,7.14,0.47,8.47,3.58C312.05,251.06,308.94,249.96,306.16,249.32z" />
              <path d="M305.17,252.54c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C311.03,254.64,308.32,252.76,305.17,252.54z" />
              <path d="M270.75,253.94c-2.09-0.4-4.24,1.74-4.01,3.99C267.34,255.93,269.09,254.39,270.75,253.94z" />
              <path d="M271.72,255.6c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C268.73,258.14,270.22,256.45,271.72,255.6z" />
            </>
          ),
        },
      },
    },
    // Eye shape 2
    eye_shape_rGwCe: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.84,249.82c-3.16-1.21-7.14,0.47-8.47,3.58C185.95,251.56,189.06,250.46,191.84,249.82z" />
              <path d="M192.83,253.04c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C186.97,255.14,189.68,253.26,192.83,253.04z" />
              <path d="M230,248.19c2.09-0.4,4.24,1.74,4.01,3.99C233.41,250.18,231.66,248.64,230,248.19z" />
              <path d="M229.03,249.85c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C232.02,252.39,230.53,250.7,229.03,249.85z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.16,249.82c3.16-1.21,7.14,0.47,8.47,3.58C314.05,251.56,310.94,250.46,308.16,249.82z" />
              <path d="M307.17,253.04c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C313.03,255.14,310.32,253.26,307.17,253.04z" />
              <path d="M270,248.19c-2.09-0.4-4.24,1.74-4.01,3.99C266.59,250.18,268.34,248.64,270,248.19z" />
              <path d="M270.97,249.85c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C267.98,252.39,269.47,250.7,270.97,249.85z" />
            </>
          ),
        },
      },
    },
    // Eye shape 3
    eye_shape_r3r2a: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.59,238.32c-3.16-1.21-7.14,0.47-8.47,3.58C185.7,240.06,188.81,238.96,191.59,238.32z" />
              <path d="M191.83,242.04c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C185.97,244.14,188.68,242.26,191.83,242.04z" />
              <path d="M230,256.69c2.09-0.4,4.24,1.74,4.01,3.99C233.41,258.68,231.66,257.14,230,256.69z" />
              <path d="M229.03,258.35c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C232.02,260.89,230.53,259.2,229.03,258.35z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.41,238.32c3.16-1.21,7.14,0.47,8.47,3.58C314.3,240.06,311.19,238.96,308.41,238.32z" />
              <path d="M308.17,242.04c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C314.03,244.14,311.32,242.26,308.17,242.04z" />
              <path d="M270,256.69c-2.09-0.4-4.24,1.74-4.01,3.99C266.59,258.68,268.34,257.14,270,256.69z" />
              <path d="M270.97,258.35c-1.08-0.13-2.21,0.36-2.9,1.26s-0.91,2.18-0.57,3.28C267.98,260.89,269.47,259.2,270.97,258.35z" />
            </>
          ),
        },
      },
    },
    // Eye shape 4
    eye_shape_qiCEL: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.34,257.57c-3.16-1.21-7.14,0.47-8.47,3.58C186.45,259.31,189.56,258.21,192.34,257.57z" />
              <path d="M194.08,259.79c-1.56-0.79-3.53-0.68-5,0.27s-2.38,2.7-2.3,4.45C188.22,261.89,190.93,260.01,194.08,259.79z" />
              <path d="M231.75,253.19c2.09-0.4,4.24,1.74,4.01,3.99C235.16,255.18,233.41,253.64,231.75,253.19z" />
              <path d="M230.78,254.85c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.77,257.39,232.28,255.7,230.78,254.85z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.66,257.57c3.16-1.21,7.14,0.47,8.47,3.58C313.55,259.31,310.44,258.21,307.66,257.57z" />
              <path d="M305.92,259.79c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C311.78,261.89,309.07,260.01,305.92,259.79z" />
              <path d="M268.25,253.19c-2.09-0.4-4.24,1.74-4.01,3.99C264.84,255.18,266.59,253.64,268.25,253.19z" />
              <path d="M269.22,254.85c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.23,257.39,267.72,255.7,269.22,254.85z" />
            </>
          ),
        },
      },
    },
    // Eye shape 5
    eye_shape_sSpzY: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.59,248.32c-3.16-1.21-7.14,0.47-8.47,3.58C185.7,250.06,188.81,248.96,191.59,248.32z" />
              <path d="M192.08,251.54c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C186.22,253.64,188.93,251.76,192.08,251.54z" />
              <path d="M231.25,258.69c2.09-0.4,4.24,1.74,4.01,3.99C234.66,260.68,232.91,259.14,231.25,258.69z" />
              <path d="M229.78,260.1c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C232.77,262.64,231.28,260.95,229.78,260.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.41,248.32c3.16-1.21,7.14,0.47,8.47,3.58C314.3,250.06,311.19,248.96,308.41,248.32z" />
              <path d="M307.92,251.54c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C313.78,253.64,311.07,251.76,307.92,251.54z" />
              <path d="M268.75,258.69c-2.09-0.4-4.24,1.74-4.01,3.99C265.34,260.68,267.09,259.14,268.75,258.69z" />
              <path d="M270.22,260.1c-1.08-0.13-2.21,0.36-2.9,1.26s-0.91,2.18-0.57,3.28C267.23,262.64,268.72,260.95,270.22,260.1z" />
            </>
          ),
        },
      },
    },
    // Eye shape 6
    eye_shape_LfHiz: {
      left: {
        lower: {
          base: (
            <>
              <path d="M193.09,255.32c-3.16-1.21-7.14,0.47-8.47,3.58C187.2,257.06,190.31,255.96,193.09,255.32z" />
              <path d="M194.08,258.54c-1.56-0.79-3.53-0.68-5,0.27s-2.38,2.7-2.3,4.45C188.22,260.64,190.93,258.76,194.08,258.54z" />
              <path d="M230.75,258.44c2.09-0.4,4.24,1.74,4.01,3.99C234.16,260.43,232.41,258.89,230.75,258.44z" />
              <path d="M229.78,260.1c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C232.77,262.64,231.28,260.95,229.78,260.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M306.91,255.32c3.16-1.21,7.14,0.47,8.47,3.58C312.8,257.06,309.69,255.96,306.91,255.32z" />
              <path d="M305.92,258.54c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C311.78,260.64,309.07,258.76,305.92,258.54z" />
              <path d="M269.25,258.44c-2.09-0.4-4.24,1.74-4.01,3.99C265.84,260.43,267.59,258.89,269.25,258.44z" />
              <path d="M270.22,260.1c-1.08-0.13-2.21,0.36-2.9,1.26s-0.91,2.18-0.57,3.28C267.23,262.64,268.72,260.95,270.22,260.1z" />
            </>
          ),
        },
      },
    },
    // Eye shape 7
    eye_shape_aXygD: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.59,249.82c-3.16-1.21-7.14,0.47-8.47,3.58C186.7,251.56,189.81,250.46,192.59,249.82z" />
              <path d="M193.58,253.04c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C187.72,255.14,190.43,253.26,193.58,253.04z" />
              <path d="M231.25,252.94c2.09-0.4,4.24,1.74,4.01,3.99C234.66,254.93,232.91,253.39,231.25,252.94z" />
              <path d="M230.28,254.6c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.27,257.14,231.78,255.45,230.28,254.6z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.41,249.82c3.16-1.21,7.14,0.47,8.47,3.58C313.3,251.56,310.19,250.46,307.41,249.82z" />
              <path d="M306.42,253.04c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C312.28,255.14,309.57,253.26,306.42,253.04z" />
              <path d="M268.75,252.94c-2.09-0.4-4.24,1.74-4.01,3.99C265.34,254.93,267.09,253.39,268.75,252.94z" />
              <path d="M269.72,254.6c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.73,257.14,268.22,255.45,269.72,254.6z" />
            </>
          ),
        },
      },
    },
    // Eye shape 8
    eye_shape_nWP1r: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.09,250.07c-3.16-1.21-7.14,0.47-8.47,3.58C186.2,251.81,189.31,250.71,192.09,250.07z" />
              <path d="M193.58,253.04c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C187.72,255.14,190.43,253.26,193.58,253.04z" />
              <path d="M231.25,251.44c2.09-0.4,4.24,1.74,4.01,3.99C234.66,253.43,232.91,251.89,231.25,251.44z" />
              <path d="M230.28,253.1c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.27,255.64,231.78,253.95,230.28,253.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.91,250.07c3.16-1.21,7.14,0.47,8.47,3.58C313.8,251.81,310.69,250.71,307.91,250.07z" />
              <path d="M306.42,253.04c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C312.28,255.14,309.57,253.26,306.42,253.04z" />
              <path d="M268.75,251.44c-2.09-0.4-4.24,1.74-4.01,3.99C265.34,253.43,267.09,251.89,268.75,251.44z" />
              <path d="M269.72,253.1c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.73,255.64,268.22,253.95,269.72,253.1z" />
            </>
          ),
        },
      },
    },
    // Eye shape 9
    eye_shape_9Nzgw: {
      left: {
        lower: {
          base: (
            <>
              <path d="M192.59,248.82c-3.16-1.21-7.14,0.47-8.47,3.58C186.7,250.56,189.81,249.46,192.59,248.82z" />
              <path d="M194.08,251.54c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C188.22,253.64,190.93,251.76,194.08,251.54z" />
              <path d="M231,250.44c2.09-0.4,4.24,1.74,4.01,3.99C234.41,252.43,232.66,250.89,231,250.44z" />
              <path d="M230.03,252.1c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.02,254.64,231.53,252.95,230.03,252.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M307.41,248.82c3.16-1.21,7.14,0.47,8.47,3.58C313.3,250.56,310.19,249.46,307.41,248.82z" />
              <path d="M305.92,251.54c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C311.78,253.64,309.07,251.76,305.92,251.54z" />
              <path d="M269,250.44c-2.09-0.4-4.24,1.74-4.01,3.99C265.59,252.43,267.34,250.89,269,250.44z" />
              <path d="M269.97,252.1c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.98,254.64,268.47,252.95,269.97,252.1z" />
            </>
          ),
        },
      },
    },
    // Eye shape 10
    eye_shape_l7N07: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.84,251.07c-3.16-1.21-7.14,0.47-8.47,3.58C185.95,252.81,189.06,251.71,191.84,251.07z" />
              <path d="M194.08,253.79c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C188.22,255.89,190.93,254.01,194.08,253.79z" />
              <path d="M231.5,252.69c2.09-0.4,4.24,1.74,4.01,3.99C234.91,254.68,233.16,253.14,231.5,252.69z" />
              <path d="M230.03,254.35c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.02,256.89,231.53,255.2,230.03,254.35z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.16,251.07c3.16-1.21,7.14,0.47,8.47,3.58C314.05,252.81,310.94,251.71,308.16,251.07z" />
              <path d="M305.92,253.79c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C311.78,255.89,309.07,254.01,305.92,253.79z" />
              <path d="M268.5,252.69c-2.09-0.4-4.24,1.74-4.01,3.99C265.09,254.68,266.84,253.14,268.5,252.69z" />
              <path d="M269.97,254.35c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.98,256.89,268.47,255.2,269.97,254.35z" />
            </>
          ),
        },
      },
    },
    // Eye shape 11
    eye_shape_E2knT: {
      left: {
        lower: {
          base: (
            <>
              <path d="M190.34,245.82c-3.16-1.21-7.14,0.47-8.47,3.58C184.45,247.56,187.56,246.46,190.34,245.82z" />
              <path d="M192.58,248.54c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C186.72,250.64,189.43,248.76,192.58,248.54z" />
              <path d="M232,252.94c2.09-0.4,4.24,1.74,4.01,3.99C235.41,254.93,233.66,253.39,232,252.94z" />
              <path d="M230.03,254.35c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.02,256.89,231.53,255.2,230.03,254.35z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M309.66,245.82c3.16-1.21,7.14,0.47,8.47,3.58C315.55,247.56,312.44,246.46,309.66,245.82z" />
              <path d="M307.42,248.54c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C313.28,250.64,310.57,248.76,307.42,248.54z" />
              <path d="M268,252.94c-2.09-0.4-4.24,1.74-4.01,3.99C264.59,254.93,266.34,253.39,268,252.94z" />
              <path d="M269.97,254.35c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.98,256.89,268.47,255.2,269.97,254.35z" />
            </>
          ),
        },
      },
    },
    // Eye shape 12
    eye_shape_cN3Qm: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.34,245.07c-3.16-1.21-7.14,0.47-8.47,3.58C185.45,246.81,188.56,245.71,191.34,245.07z" />
              <path d="M192.83,247.79c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C186.97,249.89,189.68,248.01,192.83,247.79z" />
              <path d="M232,253.44c2.09-0.4,4.24,1.74,4.01,3.99C235.41,255.43,233.66,253.89,232,253.44z" />
              <path d="M230.03,254.85c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.02,257.39,231.53,255.7,230.03,254.85z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.66,245.07c3.16-1.21,7.14,0.47,8.47,3.58C314.55,246.81,311.44,245.71,308.66,245.07z" />
              <path d="M307.17,247.79c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C313.03,249.89,310.32,248.01,307.17,247.79z" />
              <path d="M268,253.44c-2.09-0.4-4.24,1.74-4.01,3.99C264.59,255.43,266.34,253.89,268,253.44z" />
              <path d="M269.97,254.85c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.98,257.39,268.47,255.7,269.97,254.85z" />
            </>
          ),
        },
      },
    },
    // Eye shape 13
    eye_shape_NTvVF: {
      left: {
        lower: {
          base: (
            <>
              <path d="M191.59,248.82c-3.16-1.21-7.14,0.47-8.47,3.58C185.7,250.56,188.81,249.46,191.59,248.82z" />
              <path d="M193.83,251.54c-1.56-0.79-3.53-0.68-5,0.27c-1.47,0.95-2.38,2.7-2.3,4.45C187.97,253.64,190.68,251.76,193.83,251.54z" />
              <path d="M231.75,250.44c2.09-0.4,4.24,1.74,4.01,3.99C235.16,252.43,233.41,250.89,231.75,250.44z" />
              <path d="M230.28,252.1c1.08-0.13,2.21,0.36,2.9,1.26c0.69,0.9,0.91,2.18,0.57,3.28C233.27,254.64,231.78,252.95,230.28,252.1z" />
            </>
          ),
        },
      },
      right: {
        lower: {
          base: (
            <>
              <path d="M308.41,248.82c3.16-1.21,7.14,0.47,8.47,3.58C314.3,250.56,311.19,249.46,308.41,248.82z" />
              <path d="M306.17,251.54c1.56-0.79,3.53-0.68,5,0.27c1.47,0.95,2.38,2.7,2.3,4.45C312.03,253.64,309.32,251.76,306.17,251.54z" />
              <path d="M268.25,250.44c-2.09-0.4-4.24,1.74-4.01,3.99C264.84,252.43,266.59,250.89,268.25,250.44z" />
              <path d="M269.72,252.1c-1.08-0.13-2.21,0.36-2.9,1.26c-0.69,0.9-0.91,2.18-0.57,3.28C266.73,254.64,268.22,252.95,269.72,252.1z" />
            </>
          ),
        },
      },
    },
  },
};

export const eyelashPack2Lower = {
  eyelash_shape_9gntb,
  eyelash_shape_vmlhi,
  eyelash_shape_ealwo,
  eyelash_shape_gnask,
  eyelash_shape_glaww,
  eyelash_shape_fjawr,
  eyelash_shape_aorwi,
  eyelash_shape_fa9sr,
  eyelash_shape_fmalq,
  eyelash_shape_llaof,
};
