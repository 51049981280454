const colour_pink_t9StY = {
  id: "colour_pink_t9StY",
  name: "Magenta",
  outline: "hsl(330, 66%, 32%)",
  darker: "hsl(330, 69%, 39%)",
  dark: "hsl(330, 64%, 48%)",
  base: "hsl(330, 65%, 56%)",
  light: "hsl(330, 85%, 64%)",
  lighter: "hsl(330, 94%, 72%)",
};

const colour_pink_ojL6o = {
  id: "colour_pink_ojL6o",
  name: "Mid Pink",
  outline: "#662D33",
  darker: "#942D3A",
  dark: "#C06B81",
  base: "#E0A1B8",
  light: "#FABED8",
  lighter: "#F8D7EA",
};

const colour_pink_1o5sM = {
  id: "colour_pink_1o5sM",
  name: "Dusky Pink",
  outline: "#2B211D",
  darker: "#704752",
  dark: "#8A5C63",
  base: "#A07C80",
  light: "#B08D93",
  lighter: "#CDAFAD",
};

const colour_pink_0Fn7E = {
  id: "colour_pink_0Fn7E",
  name: "Rose Brown",
  outline: "#804B49",
  darker: "#D15656",
  dark: "#E38881",
  base: "#EFA7A7",
  light: "#FCBCB8",
  lighter: "#FDDBD8",
};

const colour_pink_jok6E = {
  id: "colour_pink_jok6E",
  name: "Light Rose Brown",
  outline: "#B8827F",
  darker: "#E89A95",
  dark: "#FAADA8",
  base: "#FECCC9",
  light: "#FFDBD9",
  lighter: "#FBE9E8",
};

const colour_pink_jl42E = {
  id: "colour_pink_jl42E",
  name: "Coral Pink",
  outline: "#8E3532",
  darker: "#AE3532",
  dark: "#DB4543",
  base: "#F66765",
  light: "#F9A09F",
  lighter: "#FBC6C5",
};

export const AllPinks = {
  colour_pink_t9StY: colour_pink_t9StY,
  colour_pink_ojL6o: colour_pink_ojL6o,
  colour_pink_1o5sM: colour_pink_1o5sM,
  colour_pink_0Fn7E: colour_pink_0Fn7E,
  colour_pink_jok6E: colour_pink_jok6E,
  colour_pink_jl42E: colour_pink_jl42E,
};

export default AllPinks;
