import React from "react";
import EyeAccessories from "./EyeAccessories";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <EyeAccessories
        preview={true}
        previewId={previewId}
        accessory={component}
      />
    </>
  );
};

export const ShinePreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <EyeAccessories preview={true} previewId={previewId} shine={component} />
    </>
  );
};

export const BridgePreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <EyeAccessories preview={true} previewId={previewId} bridge={component} />
    </>
  );
};

export const viewbox = "170 170 160 160";
