import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import React from "react";
import CharacterHairMid from "../CharacterHairMid";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHairMid preview={true} previewId={previewId} hair={component} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewbox = "160 200 300 300";
