import { allColours } from "character-creator/colours/colourSchemes";
import { undereyeShapeList } from "character-creator/components/head/markings/undereye/UndereyeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyePath = "base.head.face.eyes.";
const eyeBasePath = eyePath + "base.";
const undereyePath = eyePath + "undereye.";

const EyesUndereyePage = {
  id: "undereyePage",
  name: "Undereye",
  description: "",
  symmetryAttribute: undereyePath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "undereyeShape",
        name: "Shape",
        description: "",
        dataAttribute: [
          undereyePath + "left.shape",
          undereyePath + "right.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        options: undereyeShapeList,
        colourControls: [
          {
            id: "undereyeColour",
            name: "Colour",
            description: "",
            dataAttribute: [
              undereyePath + "left.colour",
              undereyePath + "right.colour",
            ],
            attributeType: "multi",
            previewType: "swatch",
            options: allColours,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "undereyeLeftShape",
        name: "Left Shape",
        description: "",
        dataAttribute: [undereyePath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: undereyeShapeList,
        dependencies: [
          {
            description: "Eye shape",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "undereyeLeftColour",
            name: "Left Colour",
            description: "",
            dataAttribute: [undereyePath + "left.colour"],
            attributeType: "multi",
            previewType: "swatch",
            options: allColours,
          },
        ],
      },
      {
        id: "undereyeRightShape",
        name: "Right Shape",
        description: "",
        dataAttribute: [undereyePath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: undereyeShapeList,
        dependencies: [
          {
            description: "Eye shape",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "undereyeRightColour",
            name: "Right Colour",
            description: "",
            dataAttribute: [undereyePath + "right.colour"],
            attributeType: "multi",
            previewType: "swatch",
            options: allColours,
          },
        ],
      },
    ],
  },
};

export default EyesUndereyePage;
