// Sections

import EarAccessoriesPage from "./EarAccessoriesPage";
import EyeAccessoriesFullPage from "./pages/eyes/EyeAccessoriesFullPage";
import NeckAccessoriesPage from "./pages/neck/NeckAccessoriesPage";
import NoseAccessoriesSidePage from "./pages/NoseAccessoriesPage";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

// Attribute paths
// export const basePath = "base.";

const AccessoriesMenuConfig = {
  id: "Accessories",
  name: "Accessories",
  // icon: icon,
  description: "Time to accessorise",
  groups: [
    {
      id: "EyeAccessories",
      name: "Eyes",
      pages: { eyeAccessoriesFullPage: EyeAccessoriesFullPage },
    },
    {
      id: "FaceAccessories",
      name: "Face",
      pages: { noseAccessoriesSidePage: NoseAccessoriesSidePage },
    },
    {
      id: "NeckAccessories",
      name: "Neck",
      pages: { neckAccessoriesPage: NeckAccessoriesPage },
    },
    {
      id: "EarAccessories",
      name: "Ear",
      pages: { earAccessoriesPage: EarAccessoriesPage },
    },
  ],
};

export default AccessoriesMenuConfig;
