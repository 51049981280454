import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const base_body_shape_lcUGV = {
  name: "Body 1A",
  description: "A mid-weight body with smooth skin",
  tags: ["medium", "humanoid", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M440.17,500v-38.92h-0.16c-1.69-23.1-18.57-42.33-41.49-49.25c-15.3-6.91-35.42-12.7-58.86-16.9c-16.1-4.2-37.87-5.65-40.54-24.87v-57.34h-98.26v57.34c-2.67,19.22-24.44,20.67-40.54,24.87c-23.44,4.2-43.56,9.99-58.86,16.9c-22.92,6.92-39.79,26.16-41.49,49.25h-0.16V500H440.17z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

const base_body_shape_taw74 = {
  name: "Body 1B",
  description: "A heavier-weight body with smooth skin",
  tags: ["heavier", "humanoid", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path
        d="M440.17,500v-15.94h-0.16c-4.06-67.59-45.46-86.95-100.35-104.78
			c-40.05-11.3-42.36-27.15-40.54-70.71h-98.26c1.78,43.76-0.44,59.32-40.54,70.71c-54.87,17.82-96.3,37.21-100.35,104.78h-0.16V500
			H440.17z"
      />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

const base_body_shape_jnr29 = {
  name: "Body 1C",
  description: "A lighter-weight body with smooth skin",
  tags: ["lighter", "humanoid", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M440.96 500c-.87-11.43.21-22.89-1.36-34.34-1.81-13.18-6.08-26.29-14.26-36.78-5-6.42-11.69-11.89-19.63-13.67-6.36-1.43-13.08-.42-19.41-1.99-7.93-1.96-14.32-7.7-21.74-11.1-10.68-4.9-22.89-4.74-34.46-6.8-9.87-1.75-19.44-5.17-28.18-10.07-2.85-1.6-5.7-3.44-7.44-6.2-1.53-2.43-2.05-5.35-2.4-8.2-2.09-17.33 1.27-34.99-1.11-52.28-.83-.05-1.67-.11-2.5-.16v-.23h-79.1v.38c-.11.01-.23.02-.34.02-2.38 17.29.98 34.96-1.11 52.28-.34 2.85-.87 5.77-2.4 8.2-1.74 2.76-4.59 4.6-7.44 6.2-8.74 4.9-18.31 8.32-28.18 10.07-11.57 2.05-23.78 1.89-34.46 6.8-7.42 3.41-13.82 9.14-21.74 11.1-6.32 1.56-13.05.56-19.41 1.99-7.94 1.79-14.63 7.26-19.63 13.67-8.18 10.49-12.45 23.6-14.26 36.78-1.57 11.45-.5 22.91-1.36 34.34h381.92z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

export const BodyShapePack1 = {
  base_body_shape_lcUGV,
  base_body_shape_taw74,
  base_body_shape_jnr29,
};
