import React from "react";

import { viewbox, PreviewBase } from "../../Preview";

// Shirt 1

const shirt_qpvDGd2dca_AC_back = {
  base: <path d="M170.418 373.186h159.163v26.417H170.418z" />,
  basic: {
    darker: <path d="M170.418 377.306h159.163v22.298H170.418z" />,
  },
};

const shirt_qpvDGd2dca_AC_front = {
  base: (
    <path d="m368.632 395.452.167-3.051c.065-1.18-.471-2.568-1.687-2.759-7.612-1.19-29.195-10.781-36.461-16.21-.641-.479-5.165-.246-5.165-.246s-.268 2.54.8 4.101c6.013 8.793-.122 35.671-1.935 43.28-.852 3.574 3.491 7.117 4.751 7.757V500h118.207s.242-14.677.242-40.699c0-13.803-5.483-27.041-15.244-36.801-9.76-9.76-24.239-17.152-37.578-20.702-10.081-2.683-18.719-4.752-26.097-6.346Zm-237.264 0-.167-3.051c-.065-1.18.471-2.568 1.687-2.759 7.612-1.19 29.195-10.781 36.461-16.21.641-.479 5.165-.246 5.165-.246s.268 2.54-.8 4.101c-6.013 8.793.122 35.671 1.935 43.28.852 3.574-3.491 7.117-4.751 7.757V500H52.691s-.242-14.677-.242-40.699c0-13.803 5.483-27.041 15.244-36.801 9.76-9.76 24.239-17.152 37.578-20.702 10.081-2.683 18.719-4.752 26.097-6.346Z" />
  ),
  basic: {
    light: (
      <path d="M362.8 442.168c.673.138 1.383.03 1.994-.321.835-.481 1.371-1.351 1.424-2.314.601-10.984 2.158-39.409 2.581-47.132.065-1.18-.471-2.568-1.687-2.759-7.612-1.19-29.195-10.781-36.461-16.21-.641-.479-5.165-.246-5.165-.246s7.391 3.081 6.705 22.105c-.338 9.357-4.159 19.156 2.427 26.839 5.619 6.556 13.614 11.13 21.308 14.836 1.127.543 4.08 2.707 6.874 5.202Zm-225.6 0c-.673.138-1.383.03-1.994-.321-.835-.481-1.371-1.351-1.424-2.314-.601-10.984-2.158-39.409-2.581-47.132-.065-1.18.471-2.568 1.687-2.759 7.612-1.19 29.195-10.781 36.461-16.21.641-.479 5.165-.246 5.165-.246s-7.391 3.081-6.705 22.105c.338 9.357 4.159 19.156-2.427 26.839-5.619 6.556-13.614 11.13-21.308 14.836-1.127.543-4.08 2.707-6.874 5.202Z" />
    ),
    dark: (
      <path d="M393.831 475.601c-9.875-12.424-15.163-72.88 4.605-85.554 1.026-.658-12.159 4.423-12.956 9.22-5.972 35.954.309 103.838.309 103.838l65.769-1.254s8.409-38.725-8.499-14.664c-12.294 17.495-42.627-3.281-49.228-11.586Zm-287.662 0c9.875-12.424 15.163-72.88-4.605-85.554-1.026-.658 12.159 4.423 12.956 9.22 5.972 35.954-.309 103.838-.309 103.838l-65.769-1.254s-8.409-38.725 8.499-14.664c12.294 17.495 42.627-3.281 49.228-11.586Zm235.144-40.639h1.139l.195 65.038h-2.267l-.413-65.526c-5.481 6.172-11.741 31.313-12.462 31.735-11.709 6.841-14.848-27.954-2.019-39.158l15.827 7.911Zm-182.626 0h-1.139L157.353 500h2.267l.413-65.526c5.481 6.172 11.741 31.313 12.462 31.735 11.709 6.841 14.848-27.954 2.019-39.158l-15.827 7.911Z" />
    ),
    darker: (
      <path
        d="M388.878 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-277.756 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm213.401-80.159c-2.832-.126-4.072.854-2.538 3.669 3.655 6.706 27.72 16.324 37.202 21.163.858.439 1.88.413 2.715-.068.836-.48 1.372-1.351 1.424-2.313l.004-.066c-.429-.006-.858-.11-1.252-.311-8.262-4.217-24.447-9.255-32.976-13.591-1.26-.64-5.603-4.183-4.751-7.757l.172-.726Zm-149.046 0c2.832-.126 4.072.854 2.538 3.669-3.655 6.706-27.72 16.324-37.202 21.163-.858.439-1.88.413-2.715-.068-.836-.48-1.372-1.351-1.424-2.313l-.004-.066c.429-.006.858-.11 1.252-.311 8.262-4.217 24.447-9.255 32.976-13.591 1.26-.64 5.603-4.183 4.751-7.757l-.172-.726Z"
        fillRule="nonzero"
      />
    ),
  },
};

const shirt_qpvDGd2dca_B_back = {
  base: <path d="M170.418 356.004h159.163v26.417H170.418z" />,
  basic: {
    darker: <path d="M170.418 360.124h159.163v22.298H170.418z" />,
  },
};

const shirt_qpvDGd2dca_B_front = {
  base: (
    <path d="m131.951 378.216-.167-3.051c-.065-1.181.471-2.569 1.687-2.759 7.612-1.191 29.195-10.781 36.461-16.21.64-.479 5.165-.246 5.165-.246s.268 2.539-.8 4.101c-6.013 8.793.122 35.671 1.935 43.28.851 3.574-3.491 7.116-4.751 7.757L170.898 500H52.691s-.242-14.677-.242-40.699c0-13.803 4.734-27.667 12.519-39.065 8.917-13.054 18.493-22.252 38.333-32.95 9.182-4.951 21.272-7.477 28.65-9.07Zm236.098 0 .167-3.051c.065-1.181-.471-2.569-1.687-2.759-7.612-1.191-29.195-10.781-36.461-16.21-.64-.479-5.165-.246-5.165-.246s-.268 2.539.8 4.101c6.013 8.793-.122 35.671-1.935 43.28-.851 3.574 3.491 7.116 4.751 7.757l.583 88.912h118.207s.242-14.677.242-40.699c0-13.803-4.734-27.667-12.519-39.065-8.917-13.054-18.493-22.252-38.333-32.95-9.182-4.951-21.272-7.477-28.65-9.07Z" />
  ),
  basic: {
    light: (
      <path d="M137.787 424.901c-.673.138-1.383.029-1.994-.322-.835-.481-1.371-1.351-1.424-2.314-.601-10.984-2.158-39.409-2.581-47.131-.065-1.181.471-2.569 1.687-2.759 7.612-1.191 29.195-10.782 36.461-16.211.64-.479 5.165-.246 5.165-.246s-7.391 3.081-6.705 22.105c.338 9.357 4.159 19.157-2.427 26.84-5.619 6.556-13.614 11.129-21.308 14.836-1.127.543-4.08 2.707-6.874 5.202Zm224.426 0c.673.138 1.383.029 1.994-.322.835-.481 1.371-1.351 1.424-2.314.601-10.984 2.158-39.409 2.581-47.131.065-1.181-.471-2.569-1.687-2.759-7.612-1.191-29.195-10.782-36.461-16.211-.64-.479-5.165-.246-5.165-.246s7.391 3.081 6.705 22.105c-.338 9.357-4.159 19.157 2.427 26.84 5.619 6.556 13.614 11.129 21.308 14.836 1.127.543 4.08 2.707 6.874 5.202Z" />
    ),
    dark: (
      <path d="M106.169 475.601c9.875-12.424 15.207-87.555-4.561-100.23-1.027-.658 12.159 4.423 12.955 9.22 5.973 35.955-.352 118.514-.352 118.514l-65.769-1.254s-8.409-38.725 8.499-14.664c12.294 17.495 42.627-3.281 49.228-11.586Zm287.662 0c-9.875-12.424-15.207-87.555 4.561-100.23 1.027-.658-12.159 4.423-12.955 9.22-5.973 35.955.352 118.514.352 118.514l65.769-1.254s8.409-38.725-8.499-14.664c-12.294 17.495-42.627-3.281-49.228-11.586Zm-235.036-57.87h-1.139L157.353 500h2.267l.522-82.758c5.481 6.172 11.632 48.545 12.353 48.967 11.709 6.841 14.957-45.185 2.128-56.389l-15.828 7.911Zm182.41 0h1.139l.303 82.269h-2.267l-.522-82.758c-5.481 6.172-11.632 48.545-12.353 48.967-11.709 6.841-14.957-45.185-2.128-56.389l15.828 7.911Z" />
    ),
    darker: (
      <path
        d="m116.2 446.908.109-.586c-.036.188-.072.384-.109.586l-5.748 30.902s-1.25 9.19-2.45 22.19h3.12c.755-13.395 3.219-42.817 5.078-53.092Zm267.6 0-.109-.586c.036.188.072.384.109.586l5.748 30.902s1.25 9.19 2.45 22.19h-3.12c-.755-13.395-3.219-42.817-5.078-53.092Zm-207.736-44.335c2.832-.126 4.071.854 2.538 3.669-3.655 6.707-27.72 16.324-37.202 21.164-.858.438-1.88.412-2.716-.068-.835-.481-1.371-1.352-1.424-2.314l-.003-.066c.429-.006.858-.11 1.252-.311 8.262-4.216 24.447-9.254 32.976-13.59 1.26-.641 5.602-4.184 4.751-7.758l-.172-.726Zm147.872 0c-2.832-.126-4.071.854-2.538 3.669 3.655 6.707 27.72 16.324 37.202 21.164.858.438 1.88.412 2.716-.068.835-.481 1.371-1.352 1.424-2.314l.003-.066c-.429-.006-.858-.11-1.252-.311-8.262-4.216-24.447-9.254-32.976-13.59-1.26-.641-5.602-4.184-4.751-7.758l.172-.726Z"
        fillRule="nonzero"
      />
    ),
  },
};

const shirt_qpvDGd2dca = {
  name: "Shirt 1",
  description: "An open shirt",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 1B
    base_body_shape_taw74: {
      front: { ...shirt_qpvDGd2dca_B_front },
      back: { ...shirt_qpvDGd2dca_B_back },
    },
    // Body 1C
    base_body_shape_jnr29: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 2A
    base_body_shape_g825h: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 2B
    base_body_shape_fa048: {
      front: { ...shirt_qpvDGd2dca_B_front },
      back: { ...shirt_qpvDGd2dca_B_back },
    },
    // Body 2C
    base_body_shape_mge5o: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 3A
    base_body_shape_gj245: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 3B
    base_body_shape_942n5: {
      front: { ...shirt_qpvDGd2dca_B_front },
      back: { ...shirt_qpvDGd2dca_B_back },
    },
    // Body 3C
    base_body_shape_0424n: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 4A
    base_body_shape_fds8g: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
    // Body 4B
    base_body_shape_sg894: {
      front: { ...shirt_qpvDGd2dca_B_front },
      back: { ...shirt_qpvDGd2dca_B_back },
    },
    // Body 4C
    base_body_shape_fa84m: {
      front: { ...shirt_qpvDGd2dca_AC_front },
      back: { ...shirt_qpvDGd2dca_AC_back },
    },
  },
};

// Shirt 2
const shirt_bhwrzNBg44_AC = {
  base: (
    <path d="M53.617 500v-34.631c0-36.169 32.069-55.715 68.81-65.49 19.143-5.092 58.692-16.375 64.53-18.18.479-.148-.554-.629-.243-1.69.573-1.955 6.73-16.051 8.551-19.362.46-.837 1.877-1.362 3.049.141 5.831 7.481 29.815 26.467 48.646 27.827 1.808.131 1.661 2.321 2.571 2.402l.469.041.469-.041c.91-.081.763-2.271 2.571-2.402 18.831-1.36 42.815-20.346 48.646-27.827 1.172-1.503 2.589-.978 3.049-.141 1.821 3.311 7.978 17.407 8.551 19.362.311 1.061-.722 1.542-.243 1.69 5.838 1.805 45.387 13.088 64.53 18.18 36.741 9.775 68.81 29.321 68.81 65.49V500H53.617Z" />
  ),
  basic: {
    dark: (
      <path d="M242.568 421.11c-17.503-4.705-58.22-32.239-56.01-39.706 0 0 32.043 28.28 52.211 34.64.522.165 1.569.527 1.775-.83 4.471-29.432 8.059-22.683 7.981-21.447-.058.908-1.274 5.368-3.446 12.304v11.216c4.742-.298 5.144 4.535 0 4.351v81.604h-2.511V421.11Zm14.864 0c17.503-4.705 58.22-32.239 56.01-39.706 0 0-32.043 28.28-52.211 34.64-.522.165-1.569.527-1.775-.83-4.471-29.432-8.059-22.683-7.981-21.447.058.908 1.274 5.368 3.446 12.304v11.216c-4.742-.298-5.144 4.535 0 4.351v81.604h2.511V421.11Zm136.399 54.491c-9.875-12.424-15.163-72.88 4.605-85.554 1.026-.658-12.159 4.423-12.956 9.22-5.972 35.954.309 103.838.309 103.838l65.769-1.254s8.409-38.725-8.499-14.664c-12.294 17.495-42.627-3.281-49.228-11.586Zm-287.662 0c9.875-12.424 15.163-72.88-4.605-85.554-1.026-.658 12.159 4.423 12.956 9.22 5.972 35.954-.309 103.838-.309 103.838l-65.769-1.254s-8.409-38.725 8.499-14.664c12.294 17.495 42.627-3.281 49.228-11.586Zm143.958 13.841c1.861 0 3.371 1.63 3.371 3.639s-1.51 3.639-3.371 3.639-3.371-1.63-3.371-3.639 1.51-3.639 3.371-3.639Zm0-20.434c1.861 0 3.371 1.631 3.371 3.639 0 2.009-1.51 3.64-3.371 3.64s-3.371-1.631-3.371-3.64c0-2.008 1.51-3.639 3.371-3.639Zm0-20.434c1.861 0 3.371 1.631 3.371 3.64 0 2.008-1.51 3.639-3.371 3.639s-3.371-1.631-3.371-3.639c0-2.009 1.51-3.64 3.371-3.64Zm0-20.433c1.861 0 3.371 1.63 3.371 3.639s-1.51 3.639-3.371 3.639-3.371-1.63-3.371-3.639 1.51-3.639 3.371-3.639Z" />
    ),
    darker: (
      <path d="M388.878 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-277.756 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm106.101-95.135c7.437 4.872 15.098 9.146 21.546 11.179.522.165 1.569.527 1.775-.83 4.471-29.432 8.059-22.683 7.981-21.447-.012.186-.072.521-.179.995-7.268 6.797-1.641 27.943-10.37 23.931-9.814-4.511-18.154-10.773-20.753-13.828Zm65.554 0c-7.437 4.872-15.098 9.146-21.546 11.179-.522.165-1.569.527-1.775-.83-4.471-29.432-8.059-22.683-7.981-21.447.012.186.072.521.179.995 7.268 6.797 1.641 27.943 10.37 23.931 9.814-4.511 18.154-10.773 20.753-13.828Z" />
    ),
    light: (
      <path d="M250.127 488.635c1.861 0 3.371 1.511 3.371 3.371 0 1.861-1.51 3.372-3.371 3.372s-3.371-1.511-3.371-3.372c0-1.86 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.319-.659.659-1.318-1.319-1.318 1.319-.659-.659 1.318-1.319-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.827c1.861 0 3.371 1.51 3.371 3.371 0 1.86-1.51 3.371-3.371 3.371s-3.371-1.511-3.371-3.371c0-1.861 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.318-.659.659-1.318-1.318-1.318 1.318-.659-.659 1.318-1.318-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.828c1.861 0 3.371 1.511 3.371 3.371 0 1.861-1.51 3.371-3.371 3.371s-3.371-1.51-3.371-3.371c0-1.86 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.318-.659.659-1.318-1.318-1.318 1.318-.659-.659 1.318-1.318-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.828c1.861 0 3.371 1.511 3.371 3.372 0 1.86-1.51 3.371-3.371 3.371s-3.371-1.511-3.371-3.371c0-1.861 1.51-3.372 3.371-3.372Zm1.318 1.394.659.659-1.318 1.319 1.318 1.318-.659.659-1.318-1.318-1.318 1.318-.659-.659 1.318-1.318-1.318-1.319.659-.659 1.318 1.318 1.318-1.318Zm-25.828-21.378c-17.251-9.965-34.569-22.248-38.25-29.815-3.125-6.423 5.144-13.004 6.964-16.315.461-.837 1.884-.935 3.055.568 2.417 3.1 11.483 12.097 22.233 18.828 13.49 8.448 27.821 7.664 24.954 11.279-1.609 2.03-2.799 8.81-3.273 11.367-1.261 6.812-11.431 6.545-15.683 4.088Zm48.766 0c17.251-9.965 34.569-22.248 38.25-29.815 3.125-6.423-5.144-13.004-6.964-16.315-.461-.837-1.884-.935-3.055.568-2.417 3.1-11.483 12.097-22.233 18.828-13.49 8.448-27.821 7.664-24.954 11.279 1.609 2.03 2.799 8.81 3.273 11.367 1.261 6.812 11.431 6.545 15.683 4.088Z" />
    ),
  },
};

const shirt_bhwrzNBg44_B = {
  base: (
    <path d="M53.617 500v-34.631c0-36.169 34.442-70.699 71.183-80.473 19.143-5.093 48.519-16.044 55.045-18.201.535-.177-.619-.751-.272-2.019.641-2.336 7.49-13.897 9.516-17.208.512-.836 2.089-1.362 3.393.141 6.489 7.481 33.386 25.726 54.435 27.351 2.021.156 1.856 2.774 2.874 2.869l.524.05.524-.05c1.018-.095.853-2.713 2.874-2.869 21.049-1.625 47.316-19.87 53.805-27.351 1.304-1.503 2.881-.977 3.393-.141 2.026 3.311 9.505 14.872 10.146 17.208.347 1.268-.807 1.842-.272 2.019 6.526 2.157 40.017 13.108 59.16 18.201 36.741 9.774 66.438 44.304 66.438 80.473V500H53.617Z" />
  ),
  basic: {
    dark: (
      <path d="M242.568 406.759c-17.503-4.706-67.008-32.239-64.51-39.706 0 0 40.543 28.279 60.711 34.639.522.165 1.569.528 1.775-.83 4.471-29.432 8.059-22.683 7.981-21.447-.058.908-1.274 5.369-3.446 12.304v11.216c4.742-.298 5.144 4.536 0 4.351v95.956h-2.511v-96.483Zm14.864 0c17.503-4.706 66.585-32.239 64.087-39.706 0 0-40.12 28.279-60.288 34.639-.522.165-1.569.528-1.775-.83-4.471-29.432-8.059-22.683-7.981-21.447.058.908 1.274 5.369 3.446 12.304v11.216c-4.742-.298-5.144 4.536 0 4.351v95.956h2.511v-96.483Zm136.399 68.842c-9.875-12.424-15.163-72.88 4.605-85.554 1.026-.658-12.159 4.423-12.956 9.22-5.972 35.954.309 103.838.309 103.838l65.769-1.254s8.409-38.725-8.499-14.664c-12.294 17.495-42.627-3.281-49.228-11.586Zm-287.662 0c9.875-12.424 15.163-72.88-4.605-85.554-1.026-.658 12.159 4.423 12.956 9.22 5.972 35.954-.309 103.838-.309 103.838l-65.769-1.254s-8.409-38.725 8.499-14.664c12.294 17.495 42.627-3.281 49.228-11.586Zm143.958 13.841c1.861 0 3.371 1.63 3.371 3.639s-1.51 3.639-3.371 3.639-3.371-1.63-3.371-3.639 1.51-3.639 3.371-3.639Zm0-19.937c1.861 0 3.371 1.631 3.371 3.639 0 2.009-1.51 3.64-3.371 3.64s-3.371-1.631-3.371-3.64c0-2.008 1.51-3.639 3.371-3.639Zm0-19.937c1.861 0 3.371 1.631 3.371 3.639 0 2.009-1.51 3.64-3.371 3.64s-3.371-1.631-3.371-3.64c0-2.008 1.51-3.639 3.371-3.639Zm0-19.937c1.861 0 3.371 1.631 3.371 3.64 0 2.008-1.51 3.639-3.371 3.639s-3.371-1.631-3.371-3.639c0-2.009 1.51-3.64 3.371-3.64Zm0-19.936c1.861 0 3.371 1.63 3.371 3.639s-1.51 3.639-3.371 3.639-3.371-1.63-3.371-3.639 1.51-3.639 3.371-3.639Z" />
    ),
    darker: (
      <path d="M388.878 500c-.77-13.66-1.94-26.72-3.81-36.41l4.48 14.22s1.25 9.19 2.45 22.19h-3.12Zm-277.756 0c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm106.101-108.353c7.437 4.872 15.098 8.012 21.546 10.045.522.165 1.569.528 1.775-.83 4.471-29.432 8.059-22.683 7.981-21.447-.012.186-.072.521-.179.996-7.268 6.796-1.641 27.943-10.37 23.93-9.814-4.511-18.154-9.64-20.753-12.694Zm65.554 0c-7.437 4.872-15.098 8.012-21.546 10.045-.522.165-1.569.528-1.775-.83-4.471-29.432-8.059-22.683-7.981-21.447.012.186.072.521.179.996 7.268 6.796 1.641 27.943 10.37 23.93 9.814-4.511 18.154-9.64 20.753-12.694Z" />
    ),
    light: (
      <path d="M250.127 488.635c1.861 0 3.371 1.511 3.371 3.371 0 1.861-1.51 3.372-3.371 3.372s-3.371-1.511-3.371-3.372c0-1.86 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.319-.659.659-1.318-1.319-1.318 1.319-.659-.659 1.318-1.319-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.33c1.861 0 3.371 1.51 3.371 3.371 0 1.86-1.51 3.371-3.371 3.371s-3.371-1.511-3.371-3.371c0-1.861 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.318-.659.659-1.318-1.318-1.318 1.318-.659-.659 1.318-1.318-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.331c1.861 0 3.371 1.51 3.371 3.371 0 1.86-1.51 3.371-3.371 3.371s-3.371-1.511-3.371-3.371c0-1.861 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.318-.659.659-1.318-1.318-1.318 1.318-.659-.659 1.318-1.318-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.331c1.861 0 3.371 1.511 3.371 3.371 0 1.861-1.51 3.371-3.371 3.371s-3.371-1.51-3.371-3.371c0-1.86 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.318-.659.659-1.318-1.318-1.318 1.318-.659-.659 1.318-1.318-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-1.318-21.331c1.861 0 3.371 1.511 3.371 3.371 0 1.861-1.51 3.371-3.371 3.371s-3.371-1.51-3.371-3.371c0-1.86 1.51-3.371 3.371-3.371Zm1.318 1.394.659.659-1.318 1.318 1.318 1.319-.659.659-1.318-1.319-1.318 1.319-.659-.659 1.318-1.319-1.318-1.318.659-.659 1.318 1.318 1.318-1.318Zm-27.78-17.497c-18.632-10.546-37.337-23.544-41.313-31.552-3.375-6.798 5.556-13.763 7.522-17.266.497-.886 2.034-.99 3.3.601 2.61 3.28 12.401 12.801 24.013 19.925 14.57 8.94 30.048 8.11 26.952 11.936-1.739 2.149-3.024 9.324-3.535 12.03-1.362 7.209-12.346 6.926-16.939 4.326Zm52.67 0c18.632-10.546 37.337-23.544 41.313-31.552 3.375-6.798-5.556-13.763-7.522-17.266-.497-.886-2.034-.99-3.3.601-2.61 3.28-12.401 12.801-24.013 19.925-14.57 8.94-30.048 8.11-26.952 11.936 1.739 2.149 3.024 9.324 3.535 12.03 1.362 7.209 12.346 6.926 16.939 4.326Z" />
    ),
  },
};

const shirt_bhwrzNBg44 = {
  name: "Shirt 2",
  description: "Some clothing",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 1B
    base_body_shape_taw74: {
      ...shirt_bhwrzNBg44_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 2A
    base_body_shape_g825h: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 2B
    base_body_shape_fa048: {
      ...shirt_bhwrzNBg44_B,
    },
    // Body 2C
    base_body_shape_mge5o: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 3A
    base_body_shape_gj245: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 3B
    base_body_shape_942n5: {
      ...shirt_bhwrzNBg44_B,
    },
    // Body 3C
    base_body_shape_0424n: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 4A
    base_body_shape_fds8g: {
      ...shirt_bhwrzNBg44_AC,
    },
    // Body 4B
    base_body_shape_sg894: {
      ...shirt_bhwrzNBg44_B,
    },
    // Body 4C
    base_body_shape_fa84m: {
      ...shirt_bhwrzNBg44_AC,
    },
  },
};

export const ShirtsPack1 = {
  shirt_qpvDGd2dca,
  shirt_bhwrzNBg44,
};
