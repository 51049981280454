import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { buildTransform } from "character-creator/menu/menu-helper";
import { BodyExtrasList } from "./BodyExtrasList";

function mapStateToProps(state, ownProps) {
  const bodyShape = state.config.base.body.shape;
  const extrasConfig = state.config.base.body.extras;

  // ////////////////////////////////
  // Extras 1
  // ////////////////////////////////

  const extras1Config = extrasConfig.extras1;
  const extras1Object =
    ownProps.preview && ownProps.extras1
      ? ownProps.extras1
      : BodyExtrasList[extras1Config.shape];

  let extras1Shape = extras1Object.shape[bodyShape] ?? extras1Object.shape;

  if (extras1Shape.left) {
    let newShape = {
      base: (
        <>
          {extras1Shape.left.base}
          {extras1Shape.right.base}
        </>
      ),
      light: (
        <>
          {extras1Shape.left.light}
          {extras1Shape.right.light}
        </>
      ),
      dark: (
        <>
          {extras1Shape.left.dark}
          {extras1Shape.right.dark}
        </>
      ),
    };

    extras1Shape = newShape;
  }

  const extras1Transform = buildTransform(extras1Config.transform);

  const extras1Colours = extras1Config.colour
    ? extras1Config.colour
    : { base: state.config.base.skintone.light };

  // ////////////////////////////////
  // Extras 2
  // ////////////////////////////////

  const extras2Config = extrasConfig.extras2;
  const extras2Object = BodyExtrasList[extras2Config.shape];

  let extras2Shape = extras2Object.shape;
  if (extras2Object.shape.left) {
    extras2Shape = {
      base: (
        <>
          {extras2Object.shape.left.base}
          {extras2Object.shape.right.base}
        </>
      ),
    };
  }

  const extras2Transform = buildTransform(extras2Config.transform);

  const extras2Colours = extras2Config.colour
    ? extras2Config.colour
    : { base: state.config.base.skintone.light };

  // ////////////////////////////////
  // Extras 3
  // ////////////////////////////////

  const extras3Config = extrasConfig.extras3;
  const extras3Object = BodyExtrasList[extras3Config.shape];

  let extras3Shape = extras3Object.shape;
  if (extras3Object.shape.left) {
    extras3Shape = {
      base: (
        <>
          {extras3Object.shape.left.base}
          {extras3Object.shape.right.base}
        </>
      ),
    };
  }

  const extras3Transform = buildTransform(extras3Config.transform);

  const extras3Colours = extras3Config.colour
    ? extras3Config.colour
    : { base: state.config.base.skintone.light };

  // TODO: Handle markings that are left/right split

  return {
    Extra1: {
      shape: { shape: extras1Shape },
      colour: extras1Colours,
      transform: extras1Transform,
    },
    Extra2: {
      shape: { shape: extras2Shape },
      colour: extras2Colours,
      transform: extras2Transform,
    },
    Extra3: {
      shape: { shape: extras3Shape },
      colour: extras3Colours,
      transform: extras3Transform,
    },
    previewId: ownProps.previewId,
  };
}

const BodyExtras = (props) => {
  const { Extra1, Extra2, Extra3, previewId } = props;

  if (Extra1.shape || Extra2.shape || Extra3.shape) {
    return (
      <g id={previewId ? `g-body-extras~${previewId}` : "g-body-extras"}>
        {Extra1.shape && (
          <Component
            id={previewId ? `body-extras1~${previewId}` : "body-extras1"}
            component={Extra1.shape}
            colour={Extra1.colour}
            preview={previewId ? true : false}
            transform={Extra1.transform}
            strokeOverride={false}
          />
        )}

        {!previewId && Extra2.shape && (
          <Component
            id={previewId ? `body-extras2~${previewId}` : "body-extras2"}
            component={Extra2.shape}
            colour={Extra2.colour}
            preview={previewId ? true : false}
            transform={Extra2.transform}
            strokeOverride={false}
          />
        )}

        {!previewId && Extra3.shape && (
          <Component
            id={previewId ? `body-extras3~${previewId}` : "body-extras3"}
            component={Extra3.shape}
            colour={Extra3.colour}
            preview={previewId ? true : false}
            transform={Extra3.transform}
            strokeOverride={false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BodyExtras);
