const teethColours = {
  teeth_colour_jf249: {
    id: "teeth_colour_jf249",
    name: "White",
    dark: "#DDDDDD",
    light: "#FEFEFE",
  },
  teeth_colour_jf2gd: {
    id: "teeth_colour_jf2gd",
    name: "Yellow",
    dark: "#C5AE81",
    light: "#DDD587",
  },
};

export default teethColours;
