import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const pattern_AHDfYm2GP5 = {
  name: "Horizontal stripes (Small)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M500 6.25V0H0v6.25h500Zm0 12.5V12.5H0v6.25h500Zm0 12.5V25H0v6.25h500Zm0 12.5V37.5H0v6.25h500Zm0 12.5V50H0v6.25h500Zm0 12.5V62.5H0v6.25h500ZM0 81.25h500V75H0v6.25Zm0 375h500V450H0v6.25Zm500 12.5v-6.25H0v6.25h500Zm0 12.5V475H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0-400V87.5H0v6.25h500Zm0 12.5V100H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0 12.5V125H0v6.25h500Zm0 12.5v-6.25H0v6.25h500ZM0 156.25h500V150H0v6.25Zm0 75h500V225H0v6.25Zm500 12.5v-6.25H0v6.25h500Zm0 12.5V250H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0 12.5V275H0v6.25h500Zm0 12.5v-6.25H0v6.25h500ZM0 306.25h500V300H0v6.25Zm500-137.5v-6.25H0v6.25h500Zm0 12.5V175H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0 12.5V200H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0 100v-6.25H0v6.25h500Zm0 12.5V325H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0 12.5V350H0v6.25h500Zm0 12.5v-6.25H0v6.25h500ZM0 381.25h500V375H0v6.25Zm500 12.5v-6.25H0v6.25h500Zm0 12.5V400H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Zm0 12.5V425H0v6.25h500Zm0 12.5v-6.25H0v6.25h500Z" />
    ),
  },
};

const pattern_grFUryC9kK = {
  name: "Horizontal stripes (Medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path
        id="stripes_horizontal_medium"
        d="M500 12.5V0H0v12.5h500Zm0 25V25H0v12.5h500Zm0 25V50H0v12.5h500Zm0 25V75H0v12.5h500Zm0 25V100H0v12.5h500Zm0 25V125H0v12.5h500Zm0 25V150H0v12.5h500Zm0 25V175H0v12.5h500Zm0 25V200H0v12.5h500Zm0 25V225H0v12.5h500Zm0 25V250H0v12.5h500Zm0 25V275H0v12.5h500Zm0 25V300H0v12.5h500Zm0 25V325H0v12.5h500Zm0 25V350H0v12.5h500Zm0 25V375H0v12.5h500Zm0 25V400H0v12.5h500Zm0 25V425H0v12.5h500Zm0 25V450H0v12.5h500Zm0 25V475H0v12.5h500Z"
      />
    ),
  },
};

const pattern_VVwbnANqvZ = {
  name: "Horizontal stripes (Large)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path
        id="stripes_horizontal_large"
        d="M500 25V0H0v25h500Zm0 50V50H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Zm0 50v-25H0v25h500Z"
      />
    ),
  },
};

const pattern_jqLgyWzjSb = {
  name: "Vertical stripes (Small)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M6.25 0H0v500h6.25V0Zm12.5 0H12.5v500h6.25V0Zm12.5 0H25v500h6.25V0Zm12.5 0H37.5v500h6.25V0Zm12.5 0H50v500h6.25V0Zm12.5 0H62.5v500h6.25V0Zm12.5 500V0H75v500h6.25Zm375 0V0H450v500h6.25Zm12.5-500h-6.25v500h6.25V0Zm12.5 0H475v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm-400 0H87.5v500h6.25V0Zm12.5 0H100v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 0H125v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 500V0H150v500h6.25Zm75 0V0H225v500h6.25Zm12.5-500h-6.25v500h6.25V0Zm12.5 0H250v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 0H275v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 500V0H300v500h6.25ZM168.75 0h-6.25v500h6.25V0Zm12.5 0H175v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 0H200v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm100 0h-6.25v500h6.25V0Zm12.5 0H325v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 0H350v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 500V0H375v500h6.25Zm12.5-500h-6.25v500h6.25V0Zm12.5 0H400v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Zm12.5 0H425v500h6.25V0Zm12.5 0h-6.25v500h6.25V0Z" />
    ),
  },
};

const pattern_NH9JLES665 = {
  name: "Vertical stripes (Medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M12.5 0H0v500h12.5V0Zm25 0H25v500h12.5V0Zm25 0H50v500h12.5V0Zm25 0H75v500h12.5V0Zm25 0H100v500h12.5V0Zm25 0H125v500h12.5V0Zm25 0H150v500h12.5V0Zm25 0H175v500h12.5V0Zm25 0H200v500h12.5V0Zm25 0H225v500h12.5V0Zm25 0H250v500h12.5V0Zm25 0H275v500h12.5V0Zm25 0H300v500h12.5V0Zm25 0H325v500h12.5V0Zm25 0H350v500h12.5V0Zm25 0H375v500h12.5V0Zm25 0H400v500h12.5V0Zm25 0H425v500h12.5V0Zm25 0H450v500h12.5V0Zm25 0H475v500h12.5V0Z" />
    ),
  },
};

const pattern_JYyFqK5cZX = {
  name: "Vertical stripes (Large)",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M25 0H0v500h25V0Zm50 0H50v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Zm50 0h-25v500h25V0Z" />
    ),
  },
};

export const PatternStripesPack1 = {
  pattern_AHDfYm2GP5,
  pattern_grFUryC9kK,
  pattern_VVwbnANqvZ,
  pattern_jqLgyWzjSb,
  pattern_NH9JLES665,
  pattern_JYyFqK5cZX,
};
