import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TabList from "./TabList";
import SubPage from "character-creator/menu/elements/SubPage";

const mapStateToProps = (state) => {
  return {
    menuMeta: state.menu,
  };
};

const TabPanel = (props) => {
  const { id, isSelected } = props;
  const content = React.Children.map(props.children, (Child) => <>{Child}</>);

  return (
    <section
      id={`panel${id}`}
      className={`menu-page ${isSelected ? "selected" : "hidden"}`}
      role="tabpanel"
      aria-hidden={!isSelected}
    >
      {content}
    </section>
  );
};

const getSubPage = (page, subPageId) => {
  // const { page, subPageId } = props;

  const pageGroups = page.groups;

  let containingGroupId = pageGroups.findIndex((group, index) => {
    return Object.keys(group.pages).includes(subPageId);
  });

  if (containingGroupId === -1) {
    return <></>;
  } else {
    return (
      <SubPage
        id={subPageId}
        page={pageGroups[containingGroupId].pages[subPageId]}
      />
    );
  }
};

const TabbedNavigation = (props) => {
  const { menus } = props;

  const menusKeys = Object.keys(menus);
  // const menusValues = Object.values(menus);

  const [openMenuId, setOpenMenu] = useState(menusKeys[0]);

  let firstMenuGroup =
    menus[openMenuId].groups[Object.keys(menus[openMenuId].groups)[0]];
  let firstPageId = Object.keys(firstMenuGroup.pages)[0];

  const [openPageId, setOpenPage] = useState(firstPageId);

  useEffect(() => {
    let firstMenuGroupId =
      menus[openMenuId].groups[Object.keys(menus[openMenuId].groups)[0]];

    let firstPageId = Object.keys(firstMenuGroupId.pages)[0];

    setOpenPage(firstPageId);
  }, [openMenuId, menus]);

  const panelList = menusKeys.map((menuKey, index) => {
    const menuObject = menus[menuKey];

    return (
      // This is the MenuPage
      <TabPanel key={index} id={menuKey} isSelected={menuKey === openMenuId}>
        {/* Nav of subpages */}
        <nav className="nav-secondary">
          <TabList
            tabs={menuObject.groups}
            openTab={openPageId}
            setOpenTab={setOpenPage}
            useGroups={true}
          />
        </nav>
        <div className="panel-body">
          {/* Display chosen subpage */}
          {getSubPage(menuObject, openPageId)}
        </div>
      </TabPanel>
    );
  });

  return (
    <div className="menu-block panel">
      <nav className={"nav-primary nav-inline"}>
        <TabList tabs={menus} openTab={openMenuId} setOpenTab={setOpenMenu} />
      </nav>
      {panelList}
    </div>
  );
};

export default connect(mapStateToProps)(TabbedNavigation);
