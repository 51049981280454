import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const cheek_shape_3123j = {
  name: "Cheek 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <circle cx="198" cy="277.53" r="18" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <circle cx="302" cy="277.53" r="18" />
        </>
      ),
    },
  },
};

const cheek_shape_fa942 = {
  name: "Cheek 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <ellipse cx="198" cy="267.03" rx="18" ry="7.5" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse cx="302" cy="267.03" rx="18" ry="7.5" />
        </>
      ),
    },
  },
};

const cheek_shape_fsd84 = {
  name: "Cheek 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <ellipse cx="180.81" cy="288.33" rx="30.76" ry="32.5" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse cx="319.19" cy="288.33" rx="30.76" ry="32.5" />
        </>
      ),
    },
  },
};

const cheek_shape_a92mn = {
  name: "Cheek 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <ellipse
          transform="matrix(0.8814 -0.4723 0.4723 0.8814 -109.4489 123.8373)"
          cx="191.92"
          cy="279.9"
          rx="15.06"
          ry="23.75"
        />
      ),
    },
    right: {
      base: (
        <ellipse
          transform="matrix(0.4723 -0.8814 0.8814 0.4723 -84.145 419.2491)"
          cx="308.08"
          cy="279.9"
          rx="23.75"
          ry="15.06"
        />
      ),
    },
  },
};

const cheek_shape_00nak = {
  name: "Cheek 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -138.2359 215.4674)"
            cx="190.97"
            cy="274.6"
            rx="11.5"
            ry="18"
          />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -103.6593 298.9424)"
            cx="309.03"
            cy="274.6"
            rx="18"
            ry="11.5"
          />
        </>
      ),
    },
  },
};

const cheek_shape_dao21 = {
  name: "Cheek 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M237.04,264.67c0-8.56-11.08-2-24.75-2c-13.67,0-24.75-6.56-24.75,2c0,8.56,11.08,15.5,24.75,15.5S237.04,273.23,237.04,264.67z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M312.46,264.67c0-8.56-11.8-2-24.75-2c-13.67,0-24.75-6.56-24.75,2c0,8.56,11.08,15.5,24.75,15.5C301.38,280.17,312.46,273.23,312.46,264.67z" />
        </>
      ),
    },
  },
};

const cheek_shape_da94n = {
  name: "Cheek 7",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M209.27,293.28c10.06-12.83-8.08-11.18-20.96-21.27s-15.6-28.1-25.66-15.27c-10.06,12.83-7.77,31.4,5.1,41.49S199.21,306.11,209.27,293.28z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M290.73,293.28c-10.06-12.83,8.08-11.18,20.96-21.27s15.6-28.1,25.66-15.27c10.06,12.83,7.77,31.4-5.1,41.49C319.37,308.33,300.79,306.11,290.73,293.28z" />
        </>
      ),
    },
  },
};

const cheek_shape_fakei = {
  name: "Cheek 8",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <ellipse
            transform="matrix(0.8814 -0.4723 0.4723 0.8814 -115.0375 123.0984)"
            cx="187.65"
            cy="290.66"
            rx="20.26"
            ry="35.63"
          />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse
            transform="matrix(0.4723 -0.8814 0.8814 0.4723 -91.379 428.6874)"
            cx="312.35"
            cy="290.66"
            rx="35.63"
            ry="20.26"
          />
        </>
      ),
    },
  },
};

export const CheeksPack1 = {
  cheek_shape_3123j,
  cheek_shape_fa942,
  cheek_shape_fsd84,
  cheek_shape_a92mn,
  cheek_shape_00nak,
  cheek_shape_dao21,
  cheek_shape_da94n,
  cheek_shape_fakei,
};
