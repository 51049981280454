import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { hairSidePack1Left } from "./packs/HairSideLeftPack1";
import { hairSidePack2Left } from "./packs/HairSideLeftPack2";
import { hairSidePack3Left } from "./packs/HairSideLeftPack3";

export const hairSideListLeft = {
  "0": BlankListOption,
  ...hairSidePack1Left,
  ...hairSidePack2Left,
  ...hairSidePack3Left,
};
