// Components
import LipColours from "character-creator/colours/lips";
import { mouthShapeList } from "character-creator/components/head/face/mouth/MouthShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const mouthPath = "base.head.face.mouth.";
const transformPath = mouthPath + "transform.";

const MouthShapePage = {
  id: "mouthShapePage",
  name: "Mouths and Beaks",
  description: "",
  controls: [
    {
      id: "mouthShape",
      name: "Shape",
      description: "",
      dataAttribute: [mouthPath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: mouthShapeList,
      colourControls: [
        {
          id: "lipColour",
          name: "Lip Colour",
          description: "",
          dataAttribute: [mouthPath + "colour.lips"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: LipColours,
        },
        // {
        //   id: "presetTeethColour",
        //   name: "Teeth Colour",
        //   description: "",
        //   dataAttribute: [teethPath + "colour"],
        //   attributeType: "multi",
        //   variationAttribute: "",
        //   previewType: "swatch",
        //   options: teethColours,
        //   dependencies: [
        //     {
        //       description: "Teeth present",
        //       attribute: [mouthPath + "shape"],
        //       value: [
        //         "mouth_shape_Pf59F",
        //         "mouth_shape_Z5QrA",
        //         "mouth_shape_VCgvh",
        //       ],
        //     },
        //   ],
        // },
        {
          id: "tongueColour",
          name: "Tongue Colour",
          description: "",
          dataAttribute: [mouthPath + "colour.tongue"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: LipColours,
          dependencies: [
            {
              description: "Tongue present",
              attribute: [mouthPath + "shape"],
              value: ["mouth_shape_6hpUa"],
            },
          ],
        },
      ],
      transformControls: [
        {
          id: "mouthTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [transformPath + "y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "mouthTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [transformPath + "scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default MouthShapePage;
