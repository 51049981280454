import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Moustache from "./Moustache";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Moustache moustache={component} preview={true} previewId={previewId} />
    </>
  );
};
// 170 180 160 160
export const viewbox = "170 230 160 160";
