import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const pupil_shape_3ugyz = {
  name: "Pupil 1",
  description: "Standard small round pupils",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <circle cx="212.25" cy="246.78" r="3.26" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <circle cx="287.75" cy="246.78" r="3.26" />
        </>
      ),
    },
  },
};

const pupil_shape_gufm8 = {
  name: "Pupil 2",
  description: "Standard small vertical slit pupils",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <ellipse cx="212.25" cy="246.78" rx="1.24" ry="3.83" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse cx="287.75" cy="246.78" rx="1.24" ry="3.83" />
        </>
      ),
    },
  },
};

const pupil_shape_35BUu = {
  name: "Pupil 3",
  description: "Small star-shaped pupils",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="m212.61 242.41 1.18 2.39c.06.12.17.2.3.22l2.63.38c.32.05.45.44.22.67l-1.91 1.86c-.09.09-.14.22-.11.35l.45 2.62c.06.32-.28.57-.57.41l-2.36-1.24c-.11-.06-.25-.06-.37 0l-2.36 1.24c-.29.15-.63-.09-.57-.41l.45-2.62c.02-.13-.02-.26-.11-.35l-1.91-1.86c-.23-.23-.1-.62.22-.67l2.63-.38c.13-.02.24-.1.3-.22l1.18-2.39c.14-.29.56-.29.71 0z" />
      ),
    },
    right: {
      base: (
        <path d="m288.1 242.41 1.18 2.39c.06.12.17.2.3.22l2.63.38c.32.05.45.44.22.67l-1.91 1.86c-.09.09-.14.22-.11.35l.45 2.62c.06.32-.28.57-.57.41l-2.36-1.24c-.11-.06-.25-.06-.37 0l-2.36 1.24c-.29.15-.63-.09-.57-.41l.45-2.62c.02-.13-.02-.26-.11-.35l-1.91-1.86c-.23-.23-.1-.62.22-.67l2.63-.38c.13-.02.24-.1.3-.22l1.18-2.39c.15-.29.57-.29.71 0z" />
      ),
    },
  },
};

const pupil_shape_4238g = {
  name: "Pupil 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M212.25 242.19c-2.53 0-4.58 2.05-4.58 4.58 0 2.53 2.05 4.58 4.58 4.58 2.53 0 4.58-2.05 4.58-4.58 0-2.52-2.05-4.58-4.58-4.58zm0 7.59c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
      ),
    },
    right: {
      base: (
        <path d="M287.75 242.19c-2.53 0-4.58 2.05-4.58 4.58 0 2.53 2.05 4.58 4.58 4.58 2.53 0 4.58-2.05 4.58-4.58 0-2.52-2.05-4.58-4.58-4.58zm0 7.59c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.35 3-3 3z" />
      ),
    },
  },
};

const pupil_shape_da1jk = {
  name: "Pupil 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M214.33 244.13c-.9 0-1.7.43-2.22 1.09-.52-.66-1.32-1.09-2.22-1.09-1.57 0-2.84 1.27-2.84 2.84 0 1.57 1.27 2.84 2.84 2.84.9 0 1.7-.43 2.22-1.09.52.66 1.32 1.09 2.22 1.09 1.57 0 2.84-1.27 2.84-2.84 0-1.57-1.27-2.84-2.84-2.84z" />
      ),
    },
    right: {
      base: (
        <path d="M290.11 244.13c-.9 0-1.7.43-2.22 1.09-.52-.66-1.32-1.09-2.22-1.09-1.57 0-2.84 1.27-2.84 2.84 0 1.57 1.27 2.84 2.84 2.84.9 0 1.7-.43 2.22-1.09.52.66 1.32 1.09 2.22 1.09 1.57 0 2.84-1.27 2.84-2.84 0-1.57-1.27-2.84-2.84-2.84z" />
      ),
    },
  },
};

const pupil_shape_jfa92 = {
  name: "Pupil 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <ellipse cx="212.25" cy="245.85" rx="1.24" ry="27" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse cx="287.75" cy="245.85" rx="1.24" ry="27" />
        </>
      ),
    },
  },
};

export const PupilPack1 = {
  pupil_shape_3ugyz,
  pupil_shape_gufm8,
  pupil_shape_35BUu,
  pupil_shape_4238g,
  pupil_shape_da1jk,
  pupil_shape_jfa92,
};
