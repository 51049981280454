import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { ClothingList } from "./ClothingList";
import AllBlues from "character-creator/colours/collections/Blues";

function mapStateToProps(state, ownProps) {
  const bodyShape = state.config.base.body.shape;
  const layerConfig = ownProps.layerConfig;
  let layerObject = null;

  if (ownProps.preview && ownProps.clothing) {
    layerObject = ownProps.clothing.shape[bodyShape].back
      ? ownProps.clothing.shape[bodyShape].back
      : ownProps.clothing.shape[bodyShape];
  } else if (layerConfig && layerConfig.shape !== "0") {
    layerObject = ClothingList[layerConfig.shape].shape[bodyShape].back
      ? ClothingList[layerConfig.shape].shape[bodyShape].back
      : ClothingList[layerConfig.shape].shape[bodyShape];
  }

  const colour =
    layerConfig && layerConfig.colour !== ""
      ? layerConfig.colour
      : AllBlues[Object.keys(AllBlues)[3]];

  const ClothingLayer = {
    shape: layerObject,
    colour: colour,
  };

  return {
    ClothingLayer,
  };
}

const ClothingBack = (props) => {
  const { ClothingLayer, previewId, layer } = props;

  if (ClothingLayer.shape) {
    return (
      <Component
        id={
          previewId
            ? `clothing-layer-back-${layer}~${previewId}`
            : `clothing-layer-back-${layer}`
        }
        component={ClothingLayer}
        colour={ClothingLayer.colour}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(ClothingBack);
