import React from "react";
import {
  viewbox,
  PreviewBase,
} from "character-creator/components/head/ears/Preview";

const ear_shape_1TEKWA = {
  name: "Ears 1 (Furry)",
  description: "Furry human-like ears",
  tags: ["side", "rounded", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M173.53 234.95c-.78-5.26-3.94-10.13-8.43-12.99.24.8.48 1.61.72 2.41-6.34-4.17-13.17-7.6-20.3-10.18-.52 2.34-.01 4.89 1.37 6.86-1.46.41-3.16-.22-4-1.48-.64 1.67-.86 3.5-.62 5.27-.25-.58-1.14-.58-1.61-.15s-.61 1.09-.73 1.7c-1.67 8.44-3.35 16.87-5.02 25.31.76-1.83 1.51-3.66 2.27-5.49-.34 10.5 4.59 21.04 12.87 27.51-.58-.85-1.17-1.7-1.75-2.55 5.64 3.7 10.54 8.53 14.32 14.12l1.08-4.05c1.62 2.91 4.39 5.16 7.57 6.14-1.93-1.02-3.31-3.02-3.58-5.18 1.18.45 2.35.91 3.53 1.36-.73-1.77-.39-3.83.36-5.59.75-1.77 1.87-3.35 2.75-5.05 3.01-5.8 3.13-12.69 2.17-19.16s-2.07-12.8-2.97-18.81z" />
      ),
      basic: {
        dark: (
          <path d="M174.06 273.44c-2.16-5.82 1.22-65.52-6.74-37.11-4.06-11.89-26.06-15.06-21.73-.44 3.72-11.06 20.35-.65 10.33 8.31-6.59 9.68-4.37 28.28 9.76 17.81 4.23 3.39 2.14 12.99 6.46 17.48 3.75 2.97 2.07-4.79 1.92-6.05z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M326.48 234.95c.78-5.26 3.94-10.13 8.43-12.99-.24.8-.48 1.61-.72 2.41 6.34-4.17 13.17-7.6 20.3-10.18.52 2.34.01 4.89-1.37 6.86 1.46.41 3.16-.22 4-1.48.64 1.67.86 3.5.62 5.27.25-.58 1.14-.58 1.61-.15.46.43.61 1.09.73 1.7 1.67 8.44 3.35 16.87 5.02 25.31-.76-1.83-1.51-3.66-2.27-5.49.34 10.5-4.59 21.04-12.87 27.51.58-.85 1.17-1.7 1.75-2.55-5.64 3.7-10.54 8.53-14.32 14.12l-1.08-4.05c-1.62 2.91-4.39 5.16-7.57 6.14 1.93-1.02 3.31-3.02 3.58-5.18-1.18.45-2.35.91-3.53 1.36.73-1.77.39-3.83-.36-5.59-.75-1.77-1.87-3.35-2.75-5.05-3.01-5.8-3.13-12.69-2.17-19.16.98-6.45 2.08-12.8 2.97-18.81z" />
      ),
      basic: {
        dark: (
          <path d="M325.94 273.44c2.16-5.82-1.22-65.52 6.74-37.11 4.06-11.89 26.06-15.06 21.73-.44-3.72-11.06-20.35-.65-10.33 8.31 6.59 9.68 4.37 28.28-9.76 17.81-4.23 3.39-2.14 12.99-6.46 17.48-3.75 2.97-2.07-4.79-1.92-6.05z" />
        ),
      },
    },
  },
};

const ear_shape_10775v = {
  name: "Ears 2 (Furry)",
  description: "Furry human-like ears with a long dangling lobe",
  tags: ["rounded", "side", "long", "furry"],
  preview: {
    viewbox: "110 210 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M172.42 243.8c-2.25-10.05-9.97-17.84-17.34-25.03-.47 1.51-.46 3.17.03 4.67-3.11-2.14-4.45-6.52-3.07-10.03-3.64 2.99-5.48 8.02-4.63 12.65-.86-.74-1.72-1.49-2.58-2.23-.61 1.55-.76 3.27-.45 4.9-.93-.67-1.87-1.34-2.8-2.01.23 6.35-.99 12.75-3.55 18.57.62-.62 1.25-1.24 1.87-1.86-2.14 6.43-1.75 13.67 1.08 19.83.77 1.67 1.71 3.28 2.2 5.05.49 1.77.47 3.8-.6 5.3 1.49-.99 2.59-2.56 3.02-4.3-.54 5.92 1.48 11.84 4.57 16.92s7.21 9.43 11.3 13.74c-.07-.58-.14-1.16-.22-1.74l4.68 3.36c-.34-.84-.69-1.68-1.03-2.53 2.33.25 4.66.5 6.99.74-2.23-.3-4.14-2.24-4.4-4.48.35.81 1.53 1.12 2.24.59-1.23-4.91.7-9.97 2.04-14.84 3.52-12.75 3.05-26.54.65-37.27z" />
      ),
      basic: {
        dark: (
          <path d="M158.26 288.29c13.18 4.92 9.92-22.62 7.8-29.46-2.63 2.28-.62 4.96 1.03 6.99-.43 9.53-14.17 2.1-11.73-5.21 8.86 11.17 11.47-5.77 5.64-11.55-2.21-3.4-7.04 1.65-7.72-1.93-1.23-2.82 1.55-4.51 2.02-7.27.23-3.38-7.68 3.47-6.82-1.58 3.24-13.36 12.91-5.32 17.31 4.82.6-3.56 1.73-7.01 3.52-10.15 4.3 8.73-.45 19.83 1.79 29.33.6 5.21 4.94 13.03 1.09 17.39-.5-.65-.84-1.38-1.13-2.15-1.89 24.91-12.34 24.94-20.19 2.17 2.72 2.6 4.03 6.64 7.39 8.6zm-16.13-44.73c1.8 7.79 5.14 14.96 8.21 22.32.15.41-.16.68-.54.81-1.66.75-1.36 2.25-1.69 3.61-3.02-8.19-8.75-17.82-5.98-26.74z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M327.58 243.77c2.25-10.05 9.97-17.84 17.34-25.03.47 1.51.46 3.17-.03 4.67 3.11-2.14 4.45-6.52 3.07-10.03 3.64 2.99 5.48 8.02 4.63 12.65.86-.74 1.72-1.49 2.58-2.23.61 1.55.76 3.27.45 4.9.93-.67 1.87-1.34 2.8-2.01-.23 6.35.99 12.75 3.55 18.57-.62-.62-1.25-1.24-1.87-1.86 2.14 6.43 1.75 13.67-1.08 19.83-.77 1.67-1.71 3.28-2.2 5.05-.49 1.77-.47 3.8.6 5.3-1.49-.99-2.59-2.56-3.02-4.3.54 5.92-1.48 11.84-4.57 16.92s-7.21 9.43-11.3 13.74c.07-.58.14-1.16.22-1.74l-4.68 3.36c.34-.84.69-1.69 1.03-2.53-2.33.25-4.66.5-6.99.74 2.23-.3 4.14-2.24 4.4-4.48-.35.81-1.53 1.12-2.24.59 1.23-4.91-.7-9.97-2.04-14.84-3.52-12.74-3.05-26.54-.65-37.27z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M341.74 288.29c-13.18 4.92-9.92-22.62-7.8-29.46 2.63 2.28.62 4.96-1.03 6.99.43 9.53 14.17 2.1 11.73-5.21-8.86 11.17-11.47-5.77-5.64-11.55 2.21-3.4 7.04 1.65 7.72-1.93 1.23-2.82-1.55-4.51-2.02-7.27-.23-3.38 7.68 3.47 6.82-1.58-3.24-13.36-12.91-5.32-17.31 4.82-.6-3.56-1.73-7.01-3.52-10.15-4.3 8.73.45 19.83-1.79 29.33-.6 5.21-4.94 13.03-1.09 17.39.5-.65.84-1.38 1.13-2.15 1.89 24.91 12.34 24.94 20.19 2.17-2.72 2.6-4.03 6.64-7.39 8.6z" />
            <path d="M357.87 243.56c-1.8 7.79-5.14 14.96-8.21 22.32-.15.41.16.68.54.81 1.66.75 1.36 2.25 1.69 3.61 3.02-8.19 8.75-17.82 5.98-26.74z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_1HOZ9g = {
  name: "Ears 3 (Furry)",
  description: "Furry, tall and large double-pointed ears",
  tags: ["pointed", "large", "side", "furry"],
  preview: {
    viewbox: "80 130 170 170",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M183.54 252.22c-11.05-15.58-20.62-32.2-28.56-49.56-.43 1.63-.41 3.37.06 4.99-4.97-6.41-8-14.31-8.57-22.41-.51 1.11-1.01 2.22-1.52 3.33-10.84-15.77-17.35-34.49-18.64-53.58-2.58 3.1-4.52 6.72-5.68 10.58-.4-1.18-.8-2.37-1.21-3.55-1.96 1.93-2.55 5.12-1.41 7.63-1.41-.99-2.83-1.97-4.24-2.96.84 9.15 1.68 18.29 2.52 27.44-1.32-1.66-2.64-3.33-3.96-4.99 1.97 5.95 3.34 12.09 4.09 18.31.53 4.4.71 9.1-1.34 13.03s-7.01 6.67-11.05 4.83c.56 1.63 2.43 2.7 4.13 2.35-1.48 3.39-6.57 4.48-9.31 1.99-.21 1.73 1.06 3.52 2.76 3.89-1.18 1.84-3.86 2.5-5.76 1.42 2.01 4.24 6.55 7.15 11.23 7.21-1.63.55-3.25 1.11-4.88 1.66 7.23 1.76 14.73 3.66 20.45 8.42 5.72 4.76 9.02 13.3 5.51 19.86 1.95-2.27 3.89-4.55 5.84-6.82-.38 9.9 1.12 19.86 4.4 29.21-.45-2.06-.12-4.28.91-6.13 3.83 9.92 12.88 17.65 23.27 19.88-1.1-1.32-2.19-2.63-3.29-3.95 3.51 2.12 7.1 4.28 11.11 5.09 4.01.82 8.6.05 11.37-2.97 2.26-2.47 2.96-5.96 3.32-9.29 1-8.91 2.56-19.12-1.55-24.91z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M160.87 280.98c2.88 1.41 6.56 2.29 9.18-.11-3.74-16.22-3.8-33.29-.14-49.53 4.49-1.93 1.04 27.72 2.64 32.17.36 4.89.96 9.76 2 14.55.4 1.84 3.99 10.92 2.42 11.78-1.72.94-3.27-.59-4.46-2.61-14.61 1.78-31.17-12.03-30.54-27.26 3.14 9.17 10.13 16.93 18.9 21.01zm-12-46.92c-.11 2.24-1.36 4.49-2.13 6.59-1.24-1.98-.97-4.96-2.06-7.08-1.19-2.71-3.72-4.64-4.96-7.33 2.64 2.96 7.26 4.42 9.15 7.82zm-36.27-13.02c7.91 1.81 15.87-.47 18.49 9.7 1.13 4.06 4.63 8.68 4.78 12.75-7.16-12.83-25.31-14.42-31.37-27.9 1.78 2.81 4.91 4.51 8.1 5.45z" />
            <path d="M139.35 204.81c10.07 17.1 26.27 33.15 27.01 53.97-1.14 1.83-3.98.27-5.74.99-.49.16-1.62 2.19-2.16 2.07-1.28-.28-1.14-3.89-1.38-5.06.3-4.75-10.07-5.37-4.77-9.8 21.85-3.93-11.22-23.56-13.17-33.39-2.47-5.17-9.61-27.06-9.57-32.79 1.89 2.32 2.94 12.14 9.78 24.01z" />
          </>
        ),
        light: (
          <path d="M153.77 265.98c1.19.39 2.49-.1 3.56-.75 1.07-.65 2.07-1.47 3.27-1.82 1.2-.35 2.73-.05 3.29 1.08.37.74.22 1.63 0 2.43-.41 1.49-1.05 2.92-1.88 4.21-.58.9-1.28 1.76-2.23 2.24-1.87.95-4.25.16-5.74-1.32-1.23-1.22-2.7-3.43-3.31-5.06-1.06-2.81 1.57-1.49 3.04-1.01zm-20.85-48.02c-.68-2.36-.84-4.84-1-7.29-1.65 3.58-1.11 7.85.42 11.48 1.53 3.64 3.95 6.81 6.14 10.09 1.58 2.36 3.08 4.84 4 7.54.64 1.89.37 4.87 1.45 6.44 2.51-3.5-.55-10.4-2.35-13.5-2.87-4.93-7.05-9.18-8.66-14.76zm-10.71-69.41c-.25-.31-.53-.64-.92-.71-.88-.18-1.4.97-1.47 1.87-.17 2.51.57 4.98 1.3 7.38 3.37 11.03 6.87 22.13 12.6 32.13 5.37 9.36 12.58 17.54 20.3 25.08-8.96-12.91-14.28-27.93-19.53-42.74-1.55-4.36-3.11-8.74-5.21-12.88-1.89-3.74-4.48-6.87-7.07-10.13z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M316.46 252.22c11.05-15.58 20.62-32.2 28.56-49.56.43 1.63.41 3.37-.06 4.99 4.97-6.41 8-14.31 8.57-22.41.51 1.11 1.01 2.22 1.52 3.33 10.84-15.77 17.35-34.49 18.64-53.58 2.58 3.1 4.52 6.72 5.68 10.58.4-1.18.8-2.37 1.21-3.55 1.96 1.93 2.55 5.12 1.41 7.63 1.41-.99 2.83-1.97 4.24-2.96-.84 9.15-1.68 18.29-2.52 27.44 1.32-1.66 2.64-3.33 3.96-4.99-1.97 5.95-3.34 12.09-4.09 18.31-.53 4.4-.71 9.1 1.34 13.03s7.01 6.67 11.05 4.83c-.56 1.63-2.43 2.7-4.13 2.35 1.48 3.39 6.57 4.48 9.31 1.99.21 1.73-1.06 3.52-2.76 3.89 1.18 1.84 3.86 2.5 5.76 1.42-2.01 4.24-6.55 7.15-11.23 7.21 1.63.55 3.25 1.11 4.88 1.66-7.23 1.76-14.73 3.66-20.45 8.42-5.72 4.76-9.02 13.3-5.51 19.86-1.95-2.27-3.89-4.55-5.84-6.82.38 9.9-1.12 19.86-4.4 29.21.45-2.06.12-4.28-.91-6.13-3.83 9.92-12.88 17.65-23.27 19.88 1.1-1.32 2.19-2.63 3.29-3.95-3.51 2.12-7.1 4.28-11.11 5.09-4.01.82-8.6.05-11.37-2.97-2.26-2.47-2.96-5.96-3.32-9.29-1-8.91-2.56-19.12 1.55-24.91z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M339.13 280.98c-2.88 1.41-6.56 2.29-9.18-.11 3.74-16.22 3.8-33.29.14-49.53-4.49-1.93-1.04 27.72-2.64 32.17-.36 4.89-.96 9.76-2 14.55-.4 1.84-3.99 10.92-2.42 11.78 1.72.94 3.27-.59 4.46-2.61 14.61 1.78 31.17-12.03 30.54-27.26-3.14 9.17-10.13 16.93-18.9 21.01zm12-46.92c.11 2.24 1.36 4.49 2.13 6.59 1.24-1.98.97-4.96 2.06-7.08 1.19-2.71 3.72-4.64 4.96-7.33-2.64 2.96-7.26 4.42-9.15 7.82zm36.27-13.02c-7.91 1.81-15.87-.47-18.49 9.7-1.13 4.06-4.63 8.68-4.78 12.75 7.16-12.83 25.31-14.42 31.37-27.9-1.78 2.81-4.91 4.51-8.1 5.45z" />
            <path d="M360.65 204.81c-10.07 17.1-26.27 33.15-27.01 53.97 1.14 1.83 3.98.27 5.74.99.49.16 1.62 2.19 2.16 2.07 1.28-.28 1.14-3.89 1.38-5.06-.3-4.75 10.07-5.37 4.77-9.8-21.85-3.93 11.22-23.56 13.17-33.39 2.47-5.17 9.61-27.06 9.57-32.79-1.89 2.32-2.94 12.14-9.78 24.01z" />
          </>
        ),
        light: (
          <path d="M346.23 265.98c-1.19.39-2.49-.1-3.56-.75-1.07-.65-2.07-1.47-3.27-1.82-1.2-.35-2.73-.05-3.29 1.08-.37.74-.22 1.63 0 2.43.41 1.49 1.05 2.92 1.88 4.21.58.9 1.28 1.76 2.23 2.24 1.87.95 4.25.16 5.74-1.32 1.23-1.22 2.7-3.43 3.31-5.06 1.06-2.81-1.57-1.49-3.04-1.01zm20.85-48.02c.68-2.36.84-4.84 1-7.29 1.65 3.58 1.11 7.85-.42 11.48-1.53 3.64-3.95 6.81-6.14 10.09-1.58 2.36-3.08 4.84-4 7.54-.64 1.89-.37 4.87-1.45 6.44-2.51-3.5.55-10.4 2.35-13.5 2.87-4.93 7.05-9.18 8.66-14.76zm10.71-69.41c.25-.31.53-.64.92-.71.88-.18 1.4.97 1.47 1.87.17 2.51-.57 4.98-1.3 7.38-3.37 11.03-6.87 22.13-12.6 32.13-5.37 9.36-12.58 17.54-20.3 25.08 8.96-12.91 14.28-27.93 19.53-42.74 1.55-4.36 3.11-8.74 5.21-12.88 1.89-3.74 4.48-6.87 7.07-10.13z" />
        ),
      },
    },
  },
};

const ear_shape_1vBJ7j = {
  name: "Ears 4 (Furry)",
  description: "Furry sharp-pointed ears that point up and out",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "100 160 140 140",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M178.29 222.85c.51-6.51-4.61-12.05-9.89-15.89-5.27-3.85-11.34-7.23-14.35-13.03-.1 1.54.53 3.11 1.67 4.14-5.13-6.31-12.35-10.49-19.16-14.94s-13.62-9.59-17.16-16.91c-.45 3.31-.31 6.7.39 9.96-2.12-.21-4.16-1.11-5.74-2.54-.43 3.17 1.29 6.51 4.12 8-1.39.37-2.79.74-4.18 1.11 1.4 1.02 3.11 1.61 4.84 1.67-.85.61-1.71 1.23-2.56 1.84 8.11 5.81 12.21 15.58 15.91 24.85 2 5.01 3.96 10.89 1.22 15.53 1.95-.77 3.53-2.45 4.16-4.45 4.38 11.73 5.27 24.74 2.54 36.96 1.14-.78 2.28-1.55 3.42-2.33-.86 3.97.12 8.31 2.61 11.52.03-1.3.06-2.59.09-3.89 4.11 13.22 15.98 23.7 29.61 26.15-1.04-.93-2.08-1.85-3.11-2.78 2.61.86 4.88-1.97 5.96-4.49 4.21-9.89 4.26-21.06 2.96-31.73-1.29-10.65-3.94-21.17-3.35-28.75z" />
      ),
      basic: {
        dark: (
          <path d="M175.74 280.82c-6.14-15.78-.82-30.32-2.45-46.86.72-5.69 3.37-11.25 3.21-16.95-3.41-7.77-5.26 4.3-6.4 7.47-8.78-8.99-17.94-19.58-29.18-25.37 3.77 9.4 15.01 15.34 17.01 25.98 2.56 12.17-9.97 35.14 8.84 38.45.75 7.78 2.32 15.48 4.75 22.91 4.48 8.66 6.65-.91 4.22-5.63z" />
        ),
        light: (
          <path d="M161.94 263.6c1.1.63 2.34 1.19 2.95 2.3s-.01 2.91-1.28 2.84c-.48-.03-.9-.32-1.29-.61-4.67-3.48-8.4-8.18-10.84-13.46-1.79-3.88-4.32-10.43-3.08-14.72 1.97 2.35 2.45 6.68 3.47 9.56 1.95 5.49 4.85 11.08 10.07 14.09zm1.39-57.02c1.31 1.24 2.71 2.87 2.26 4.62-4.97-2.6-9.44-6.03-13.98-9.33-6.66-4.85-13.5-9.45-20.5-13.8-2.16-1.34-17.58-8.63-16.6-11.31 2.12-5.78 26.61 11.58 28.56 12.99 7.13 5.15 13.9 10.78 20.26 16.83z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M321.71 222.85c-.51-6.51 4.61-12.05 9.89-15.89 5.27-3.85 11.34-7.23 14.35-13.03.1 1.54-.53 3.11-1.67 4.14 5.13-6.31 12.35-10.49 19.16-14.94 6.81-4.45 13.62-9.59 17.16-16.91.45 3.31.31 6.7-.39 9.96 2.12-.21 4.16-1.11 5.74-2.54.43 3.17-1.29 6.51-4.12 8 1.39.37 2.79.74 4.18 1.11-1.4 1.02-3.11 1.61-4.84 1.67.85.61 1.71 1.23 2.56 1.84-8.11 5.81-12.21 15.58-15.91 24.85-2 5.01-3.96 10.89-1.22 15.53-1.95-.77-3.53-2.45-4.16-4.45-4.38 11.73-5.27 24.74-2.54 36.96-1.14-.78-2.28-1.55-3.42-2.33.86 3.97-.12 8.31-2.61 11.52-.03-1.3-.06-2.59-.09-3.89-4.11 13.22-15.98 23.7-29.61 26.15 1.04-.93 2.08-1.85 3.11-2.78-2.61.86-4.88-1.97-5.96-4.49-4.21-9.89-4.26-21.06-2.96-31.73 1.29-10.65 3.94-21.17 3.35-28.75z" />
      ),
      basic: {
        dark: (
          <path d="M324.26 280.82c6.14-15.78.82-30.32 2.45-46.86-.72-5.69-3.37-11.25-3.21-16.95 3.41-7.77 5.26 4.3 6.4 7.47 8.78-8.99 17.94-19.58 29.18-25.37-3.77 9.4-15.01 15.34-17.01 25.98-2.56 12.17 9.97 35.14-8.84 38.45-.75 7.78-2.32 15.48-4.75 22.91-4.48 8.66-6.65-.91-4.22-5.63z" />
        ),
        light: (
          <path d="M338.06 263.6c-1.1.63-2.34 1.19-2.95 2.3-.61 1.11.01 2.91 1.28 2.84.48-.03.9-.32 1.29-.61 4.67-3.48 8.4-8.18 10.84-13.46 1.79-3.88 4.32-10.43 3.08-14.72-1.97 2.35-2.45 6.68-3.47 9.56-1.95 5.49-4.85 11.08-10.07 14.09zm-1.39-57.02c-1.31 1.24-2.71 2.87-2.26 4.62 4.97-2.6 9.44-6.03 13.98-9.33 6.66-4.85 13.5-9.45 20.5-13.8 2.16-1.34 17.58-8.63 16.6-11.31-2.12-5.78-26.61 11.58-28.56 12.99-7.13 5.15-13.9 10.78-20.26 16.83z" />
        ),
      },
    },
  },
};

const ear_shape_1FYH9c = {
  name: "Ears 5 (Furry)",
  description: "Furry, wide rounded ears",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M175.55 243.62c.68-3.69-.99-7.47-3.42-10.32-2.43-2.85-5.56-4.99-8.49-7.33-2.93-2.34-5.76-5.01-7.21-8.47-.74 1.13-.75 2.69-.04 3.84-4.33-1.4-7.45-5.88-7.27-10.42-2.54 1.52-4.3 4.28-4.6 7.23-1.01-.24-1.86-1.07-2.12-2.08-1.28.89-1.95 2.59-1.6 4.12-.62-.52-1.25-1.04-1.87-1.55-4.78 9-9.68 18.46-9.69 28.65-.01 4.98 1.09 10.37-1.46 14.65 1.37-.48 2.59-1.39 3.43-2.57-1.39 3.53-.14 7.61 1.99 10.75s5.07 5.63 7.55 8.5c2.48 2.87 4.59 6.38 4.5 10.17.38-.78.77-1.56 1.15-2.34 1 1.94 2.72 3.5 4.76 4.29l-1.14-2.85c6.35 4.13 16.02.3 21.69 5.33-.19-1.24-.83-2.41-1.77-3.25 2.45.73 4.9 1.47 7.35 2.2 2-7.03 2.68-14.44 1.99-21.72-.91-9.42-5.22-18.71-3.73-26.83z" />
      ),
      basic: {
        dark: (
          <path d="M160.29 243.26c-.27 6.59-7.47 8.22-12.75 5.84-19.7-11.92-2.04-35 19.12-12.64-.21-7.1 7.02-11.19 6.59-1.69-2.36 13.39 1.11 26.73 1.8 39.9-.46-.48-.95-.94-1.46-1.37.26 5.3 4.7 11.02 3.21 16.07-6.96 1.15-17.6 1.9-23.24-3.52 9.48 2.48 17.53-6.14 12.8-14.86-6.72 2.29-15.99 8.57-19.47-1.59-4.18-11.07 17.59-16.24 13.28-28.32.12.73.17 1.45.12 2.18z" />
        ),
        light: (
          <path d="M155.47 273.33c1.84-1.11 3.68-3.02 5.75-2.43 1.61.46 2.45 2.36 2.28 4.03-.34 3.45-4.03 5.83-7.5 5.77s-6.63-1.96-9.32-4.15c-2.6-2.11-4.99-4.61-6.36-7.67-.37-.83-.67-1.74-.53-2.63.14-.9.86-1.76 1.77-1.79 1.43-.05 2.12 1.67 2.54 3.03 1.61 5.24 5.97 9.1 11.37 5.84zm9.33-42.85c-.27.25-.7.14-1.04.02-3.94-1.46-7.6-3.58-11.58-4.91-2.03-.68-4.13-1.18-6.29-1.2-1.39-.02-5.04 1.06-6.15.05-1.8-1.63 2.78-3 4.07-3.31 3.41-.81 7.2.08 10.4 1.34 4.18 1.62 7.91 4.4 10.59 8.01z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M324.45 243.62c-.68-3.69.99-7.47 3.42-10.32s5.56-4.99 8.49-7.33c2.93-2.34 5.76-5.01 7.21-8.47.74 1.13.75 2.69.04 3.84 4.33-1.4 7.45-5.88 7.27-10.42 2.54 1.52 4.3 4.28 4.6 7.23 1.01-.24 1.86-1.07 2.12-2.08 1.28.89 1.95 2.59 1.6 4.12.62-.52 1.25-1.04 1.87-1.55 4.78 9 9.68 18.46 9.69 28.65.01 4.98-1.09 10.37 1.46 14.65-1.37-.48-2.59-1.39-3.43-2.57 1.39 3.53.14 7.61-1.99 10.75-2.13 3.14-5.07 5.63-7.55 8.5-2.48 2.87-4.59 6.38-4.5 10.17-.38-.78-.77-1.56-1.15-2.34-1 1.94-2.72 3.5-4.76 4.29l1.14-2.85c-6.35 4.13-16.02.3-21.69 5.33.19-1.24.83-2.41 1.77-3.25-2.45.73-4.9 1.47-7.35 2.2-2-7.03-2.68-14.44-1.99-21.72.91-9.42 5.22-18.71 3.73-26.83z" />
      ),
      basic: {
        dark: (
          <path d="M339.71 243.26c.27 6.59 7.47 8.22 12.75 5.84 19.7-11.92 2.04-35-19.12-12.64.21-7.1-7.02-11.19-6.59-1.69 2.36 13.39-1.11 26.73-1.8 39.9.46-.48.95-.94 1.46-1.37-.26 5.3-4.7 11.02-3.21 16.07 6.96 1.15 17.6 1.9 23.24-3.52-9.48 2.48-17.53-6.14-12.8-14.86 6.72 2.29 15.99 8.57 19.47-1.59 4.18-11.07-17.59-16.24-13.28-28.32-.12.73-.17 1.45-.12 2.18z" />
        ),
        light: (
          <path d="M344.53 273.33c-1.85-1.11-3.68-3.02-5.75-2.43-1.61.46-2.45 2.36-2.28 4.03.34 3.45 4.03 5.83 7.5 5.77 3.46-.06 6.63-1.96 9.32-4.15 2.6-2.11 4.99-4.61 6.36-7.67.37-.83.67-1.74.53-2.63-.14-.9-.86-1.76-1.77-1.79-1.43-.05-2.12 1.67-2.54 3.03-1.61 5.24-5.97 9.1-11.37 5.84zm-9.33-42.85c.27.25.7.14 1.04.02 3.94-1.46 7.6-3.58 11.58-4.91 2.03-.68 4.13-1.18 6.29-1.2 1.39-.02 5.04 1.06 6.15.05 1.8-1.63-2.78-3-4.07-3.31-3.41-.81-7.2.08-10.4 1.34-4.18 1.62-7.91 4.4-10.59 8.01z" />
        ),
      },
    },
  },
};

const ear_shape_1aoGZG = {
  name: "Ears 6 (Furry)",
  description: "Furry, very wide rounded ears",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "90 190 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M177.35 241.53c-1.53-7.53-7.23-13.4-12.72-18.78-5.49-5.38-11.26-11.14-13.02-18.61-1.19 1.58-1.83 3.56-1.81 5.54-3.05-3.34-3.37-8.85-.73-12.52-4.65 1.59-8.19 6.01-8.74 10.89-1.08-.97-2.15-1.95-3.23-2.92-.88 1.62-1.75 3.23-2.63 4.85-.45-.64-.89-1.28-1.34-1.92-4.86 7.46-14.09 11.84-22.94 10.87 1.07 1.65 3.22 2.5 5.12 2.04-2.47 2.75-5.46 5.02-8.76 6.67 1.53 1 3.77.72 5-.62-3.2 4.3-3.52 10.05-3.33 15.41.19 5.36.73 10.9-1.12 15.93 1.6-1.42 2.86-3.21 3.66-5.18.95 4.65 3.75 8.89 7.66 11.57-1.03-1.13-1.52-2.73-1.32-4.24 4.63 4.78 10.86 7.6 16.76 10.67 5.9 3.07 11.86 6.75 15.22 12.5-.18-1.77-.36-3.53-.54-5.3 4.73 1.65 9.06 4.46 12.48 8.12.53-1.71.55-3.57.06-5.29 5.52.69 11.05 1.38 16.57 2.07.5.06 1.07.1 1.42-.26.32-.33.3-.84.27-1.29-1.09-14.08.42-28.38-1.99-40.2z" />
      ),
      basic: {
        dark: (
          <path d="M172.8 265.28c-1.5-6.3 1.87-54.72-5.12-26.87-6.77-7.92-23.53-27.41-33.24-14.6 8.09-.27 15.28 1.32 20.49 8.5.75.67.94 1.68-.04 2.17-15.64 11.11-3.23 38.76 14.02 39.38 6.28 21.4 6.82-.81 3.89-8.58z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M322.65 241.53c1.53-7.53 7.23-13.4 12.72-18.78 5.49-5.38 11.26-11.14 13.02-18.61 1.19 1.58 1.83 3.56 1.81 5.54 3.05-3.34 3.37-8.85.73-12.52 4.65 1.59 8.19 6.01 8.74 10.89 1.08-.97 2.15-1.95 3.23-2.92.88 1.62 1.75 3.23 2.63 4.85.45-.64.89-1.28 1.34-1.92 4.86 7.46 14.09 11.84 22.94 10.87-1.07 1.65-3.22 2.5-5.12 2.04 2.47 2.75 5.46 5.02 8.76 6.67-1.53 1-3.77.72-5-.62 3.2 4.3 3.52 10.05 3.33 15.41-.19 5.36-.73 10.9 1.12 15.93-1.6-1.42-2.86-3.21-3.66-5.18-.95 4.65-3.75 8.89-7.66 11.57 1.03-1.13 1.52-2.73 1.32-4.24-4.63 4.78-10.86 7.6-16.76 10.67-5.9 3.07-11.86 6.75-15.22 12.5.18-1.77.36-3.53.54-5.3-4.73 1.65-9.06 4.46-12.48 8.12-.53-1.71-.55-3.57-.06-5.29-5.52.69-11.05 1.38-16.57 2.07-.5.06-1.07.1-1.42-.26-.32-.33-.3-.84-.27-1.29 1.09-14.08-.42-28.38 1.99-40.2z" />
      ),
      basic: {
        dark: (
          <path d="M327.46 265.28c1.5-6.3-1.87-54.72 5.12-26.87 6.77-7.92 23.53-27.41 33.24-14.6-8.09-.27-15.28 1.32-20.49 8.5-.75.67-.94 1.68.04 2.17 15.64 11.11 3.23 38.76-14.02 39.38-6.28 21.4-6.82-.81-3.89-8.58z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1ZgR2p = {
  name: "Ears 7 (Furry)",
  description: "Furry, long and very wide pointed ears",
  tags: ["pointed", "side", "long", "furry"],
  preview: {
    viewbox: "60 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M182.95,250.61
						c-6.88-12.64-17.78-23.06-30.72-29.37c0.23,1.4,0.76,2.75,1.55,3.92c-5.28-1.36-9.71-5.63-11.26-10.87
						c-1.28,2.05-1.59,4.68-0.82,6.97c-2.22-0.88-4.44-1.76-6.66-2.63c0.2,0.59,0.4,1.19,0.6,1.78c-13.21-0.3-26.43-0.6-39.64-0.9
						c0.9,1.06,2.15,1.82,3.5,2.14c-7.65,0.47-15.25,1.83-22.6,4.03c-2.06,0.62-4.15,1.31-6.29,1.22c-2.15-0.09-4.4-1.16-5.23-3.14
						c0.24,2.7,2.67,5.02,5.38,5.14c-2.57,1.77-5.55,2.95-8.64,3.42c2.38,2.96,7.37,3.29,10.12,0.67c-0.66,2.05-2.08,3.85-3.92,4.96
						c2.49,0.51,5.19-0.19,7.12-1.84c0.4,1.11,0.26,2.4-0.37,3.4c3.62,0.59,7.38,0.36,10.91-0.65c-0.11,2.43-2.35,4.56-4.78,4.55
						c4.23,0.67,8.63-0.17,12.81,0.78c4.18,0.95,8.31,4.86,7.14,8.98c0.67-1.52,1.35-3.03,2.02-4.55
						c8.21,7.53,16.43,15.06,24.64,22.59c-0.5-1.51-0.99-3.01-1.49-4.52c6.06,4.78,13.06,8.35,20.49,10.45
						c2.24,0.63,4.56,1.15,6.6,2.28s3.81,3.03,4.12,5.34c0.61-1.65,0.49-3.55-0.33-5.11c2.4,0.17,4.46,2.42,4.43,4.83
						c0.23-1.32,0.45-2.64,0.68-3.97c2.57-1.1,5.5-0.39,8.28-0.06c2.78,0.33,6.06,0.05,7.64-2.26c0.79-1.14,0.99-2.57,1.18-3.95
						C180.55,265.55,181.72,256.82,182.95,250.61z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M174.14,270.15c4.33-3.11,1.14-9.07-1.05-12.84c-1.74-5.88,2.39-24.49-4.73-25.4c-2,5.44-3.01,11.11-3.29,16.86c-9.48-10.78-23.36-17.63-37.71-18.42c-1.19-0.25-1.82,1.17-1.16,2.13c4.2,2.96,10.64,2.94,13.87,7.37c4.54,5.51,2.41,13.91,5.08,20.54c3.48,8.55,13.57,11.97,22.24,11.19c1.31,4.02,1.5,9.03,4.45,12.18c178.19,288.01,173.78,272.79,174.14,270.15z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M317.05,250.61
						c6.88-12.64,17.78-23.06,30.72-29.37c-0.23,1.4-0.76,2.75-1.55,3.92c5.28-1.36,9.71-5.63,11.26-10.87
						c1.28,2.05,1.59,4.68,0.82,6.97c2.22-0.88,4.44-1.76,6.66-2.63c-0.2,0.59-0.4,1.19-0.6,1.78c13.21-0.3,26.43-0.6,39.64-0.9
						c-0.9,1.06-2.15,1.82-3.5,2.14c7.65,0.47,15.25,1.83,22.6,4.03c2.06,0.62,4.15,1.31,6.29,1.22c2.15-0.09,4.4-1.16,5.23-3.14
						c-0.24,2.7-2.67,5.02-5.38,5.14c2.57,1.77,5.55,2.95,8.64,3.42c-2.38,2.96-7.37,3.29-10.12,0.67c0.66,2.05,2.08,3.85,3.92,4.96
						c-2.49,0.51-5.19-0.19-7.12-1.84c-0.4,1.11-0.26,2.4,0.37,3.4c-3.62,0.59-7.38,0.36-10.91-0.65c0.11,2.43,2.35,4.56,4.78,4.55
						c-4.23,0.67-8.63-0.17-12.81,0.78c-4.18,0.95-8.31,4.86-7.14,8.98c-0.67-1.52-1.35-3.03-2.02-4.55
						c-8.21,7.53-16.43,15.06-24.64,22.59c0.5-1.51,0.99-3.01,1.49-4.52c-6.06,4.78-13.06,8.35-20.49,10.45
						c-2.24,0.63-4.56,1.15-6.6,2.28c-2.04,1.13-3.81,3.03-4.12,5.34c-0.61-1.65-0.49-3.55,0.33-5.11c-2.4,0.17-4.46,2.42-4.43,4.83
						c-0.23-1.32-0.45-2.64-0.68-3.97c-2.57-1.1-5.5-0.39-8.28-0.06c-2.78,0.33-6.06,0.05-7.64-2.26c-0.79-1.14-0.99-2.57-1.18-3.95
						C319.45,265.55,318.28,256.82,317.05,250.61z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M325.86,270.15c-4.33-3.11-1.14-9.07,1.05-12.84c1.74-5.88-2.39-24.49,4.73-25.4c2,5.44,3.01,11.11,3.29,16.86c9.48-10.78,23.36-17.63,37.71-18.42c1.19-0.25,1.82,1.17,1.16,2.13c-4.2,2.96-10.64,2.94-13.87,7.37c-4.54,5.51-2.41,13.91-5.08,20.54c-3.48,8.55-13.57,11.97-22.24,11.19c-1.31,4.02-1.5,9.03-4.45,12.18C321.81,288.01,326.22,272.79,325.86,270.15z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1vdyix = {
  name: "Ears 8 (Furry)",
  description: "Furry, long drooping ears",
  tags: ["long", "side", "furry"],
  preview: {
    viewbox: "90 170 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M191.91,200
						c0.57-1.02,1.14-2.04,1.7-3.06c-6.84-3.46-12.99-8.27-17.99-14.08c-1.2,1.2-1.04,3.46,0.31,4.48
						c-6.5-1.33-10.69-9.28-8.12-15.39c-5.1,1.98-8.14,8.15-6.62,13.4c-2.24-0.64-4.11-2.44-4.83-4.66
						c-1.96,1.16-2.6,4.07-1.31,5.94c-1.64-0.47-3.29-0.94-4.93-1.41c-0.87,1.24,0.32,3.27,1.82,3.13
						c-5.79,3.63-10.86,8.4-14.85,13.95c1.42,0.43,3.11-0.62,3.34-2.09c-2.53,12.64-6.47,24.99-11.72,36.77
						c1.51-1.27,3.01-2.54,4.52-3.82c-0.84,3.64-2.76,7.03-5.44,9.64c1.29,0.24,2.69-0.7,2.96-1.99
						c-0.94,20.28-7.34,40.28-18.33,57.34c1.89-0.76,3.48-2.25,4.35-4.1c-1.19,7.48-4.88,14.55-10.33,19.8
						c4.72,1.17,9.85,0.6,14.2-1.58c-1.05,2.37-3.29,4.17-5.83,4.67c8.04,0.1,16.08-2.72,22.3-7.81c-0.15,1.92-1.21,3.76-2.8,4.84
						c8.12-5.48,14.75-13.15,19-21.98c0.31,2.11-0.32,4.35-1.69,5.99c7.01-8.6,11.94-18.88,14.26-29.72
						c0.22,2.76,1.97,5.35,4.44,6.59c-2.19-3.71-2.19-8.38-1.06-12.53c1.13-4.15,3.3-7.94,5.29-11.75
						C182.95,234.53,183.44,215.19,191.91,200z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M187.35,193.02c-2.26-7.17-15.43,21.86-15.83,24.32c-14.49,22.35-20.09,49.22-32.1,72.9c-0.55,1.1-1.1,2.44-0.48,3.5c2.33,2.77,5.89-0.69,7.32-2.9c7.48-10.01,14.74-20.24,20.65-31.23c0.27,1.99,1.92,9.11,4.65,5.61c0.73-1.27-0.42-5.5-0.47-7.02c-0.08-2.44-0.02-4.89,0.15-7.33c4.5-11.08,9.38-23.54,7.97-35.55c1.56-4.88,2.96-9.9,5.51-14.37c186.12,198.47,187.97,195.81,187.35,193.02z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M308.09,200
						c-0.57-1.02-1.14-2.04-1.7-3.06c6.84-3.46,12.99-8.27,17.99-14.08c1.2,1.2,1.04,3.46-0.31,4.48c6.5-1.33,10.69-9.28,8.12-15.39
						c5.1,1.98,8.14,8.15,6.62,13.4c2.24-0.64,4.11-2.44,4.83-4.66c1.96,1.16,2.6,4.07,1.31,5.94c1.64-0.47,3.29-0.94,4.93-1.41
						c0.87,1.24-0.32,3.27-1.82,3.13c5.79,3.63,10.86,8.4,14.85,13.95c-1.42,0.43-3.11-0.62-3.34-2.09
						c2.53,12.64,6.47,24.99,11.72,36.77c-1.51-1.27-3.01-2.54-4.52-3.82c0.84,3.64,2.76,7.03,5.44,9.64
						c-1.29,0.24-2.69-0.7-2.96-1.99c0.94,20.28,7.34,40.28,18.33,57.34c-1.89-0.76-3.48-2.25-4.35-4.1
						c1.19,7.48,4.88,14.55,10.33,19.8c-4.72,1.17-9.85,0.6-14.2-1.58c1.05,2.37,3.29,4.17,5.83,4.67
						c-8.04,0.1-16.08-2.72-22.3-7.81c0.15,1.92,1.21,3.76,2.8,4.84c-8.12-5.48-14.75-13.15-19-21.98
						c-0.31,2.11,0.32,4.35,1.69,5.99c-7.01-8.6-11.94-18.88-14.26-29.72c-0.22,2.76-1.97,5.35-4.44,6.59
						c2.19-3.71,2.19-8.38,1.06-12.53c-1.13-4.15-3.3-7.94-5.29-11.75C317.05,234.53,316.56,215.19,308.09,200z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M312.65,193.02c2.26-7.17,15.43,21.86,15.83,24.32c14.49,22.35,20.09,49.22,32.1,72.9c0.55,1.1,1.1,2.44,0.48,3.5c-2.33,2.77-5.89-0.69-7.32-2.9c-7.48-10.01-14.74-20.24-20.65-31.23c-0.27,1.99-1.92,9.11-4.65,5.61c-0.73-1.27,0.42-5.5,0.47-7.02c0.08-2.44,0.02-4.89-0.15-7.33c-4.5-11.08-9.38-23.54-7.97-35.55c-1.56-4.88-2.96-9.9-5.51-14.37C313.88,198.47,312.03,195.81,312.65,193.02z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_1TbLPe = {
  name: "Ears 9 (Furry)",
  description: "Furry, large pointed ears on the top of the head",
  tags: ["pointed", "top", "furry"],
  preview: {
    viewbox: "110 110 120 120",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M205.35 185.69c2.24-3.23.45-7.62-1.36-11.11-1.81-3.49-3.54-7.92-1.23-11.1-2.04.25-3.61 2.47-3.16 4.48-2.35-3.95-2.63-9.06-.74-13.25-2.42.86-4.07 3.5-3.77 6.05-4.34-5.31-8.67-10.62-13.01-15.92-.8 1.91-.13 4.32 1.53 5.54-7.5-5.01-15.06-10.08-21.24-16.64-6.18-6.56-10.95-14.84-11.65-23.83-2.71 2.35-4.46 5.78-4.76 9.35-2.24-.9-4.02-2.9-4.64-5.23-1.57 2.47-2.21 5.5-1.79 8.4-.62-.2-1.24-.4-1.85-.6.16.93.32 1.87.48 2.8-1.89-.2-3.51-1.89-3.64-3.79-1.16 3.72-.09 8.04 2.67 10.79-1.2-.14-4.54 3.67-4.54 3.67s2.8 1.8 3.56.87c-1.92 2.23-3.83 4.46-5.75 6.68 1.47-.36 2.94-.73 4.41-1.09-3.45 10.05-3.75 21.16-.85 31.38.82 2.88 1.88 5.71 2.31 8.67.43 2.96.15 6.17-1.53 8.64 1.47-.75 2.69-1.98 3.42-3.46.35 5.02 4.41 8.89 8.25 12.15s8 6.92 8.7 11.91c.27-1.52.53-3.04.8-4.56 3.06 1.75 4.65 5.71 3.65 9.09l3.21-4.23c.03 1.52.06 3.05.09 4.57 3.54-2.84 7.75-4.83 12.19-5.74-.89 1.97-2.3 3.71-4.04 4.99 8.01-1.34 15.14-6.07 20.77-11.91 5.65-5.84 10.13-12.69 13.51-17.57z" />
      ),
      basic: {
        dark: (
          <path d="M203.39 172.86c-1.76-1.27-4.14.28-5.7 1.79-5.51 1.85-10.26-12.36-18.28-12.01-24.6-5.96-19.63 34.87-2.55 39.13-3.88 4.48-4.43 20.47 3.16 10.29 5.13-13.6 24.54-25.32 23.37-39.2z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M294.65 185.68c-2.24-3.23-.45-7.62 1.36-11.11 1.81-3.49 3.54-7.92 1.23-11.1 2.04.25 3.61 2.47 3.16 4.48 2.35-3.95 2.63-9.06.74-13.25 2.42.86 4.07 3.5 3.77 6.05 4.34-5.31 8.67-10.62 13.01-15.92.8 1.91.13 4.32-1.53 5.54 7.5-5.01 15.06-10.08 21.24-16.64 6.18-6.56 10.95-14.84 11.65-23.83 2.71 2.35 4.45 5.78 4.76 9.35 2.24-.9 4.02-2.9 4.64-5.23 1.57 2.47 2.21 5.5 1.79 8.4.62-.2 1.24-.4 1.85-.6-.16.93-.32 1.87-.48 2.8 1.89-.2 3.51-1.89 3.64-3.79 1.16 3.72.09 8.04-2.67 10.79 1.2-.14 4.54 3.67 4.54 3.67s-2.8 1.8-3.56.87c1.92 2.23 3.83 4.46 5.75 6.68-1.47-.36-2.94-.73-4.41-1.09 3.45 10.05 3.75 21.16.85 31.38-.82 2.88-1.88 5.71-2.31 8.67-.43 2.96-.15 6.17 1.53 8.64-1.47-.75-2.69-1.98-3.42-3.46-.35 5.02-4.41 8.89-8.25 12.15s-8 6.92-8.7 11.91c-.27-1.52-.53-3.04-.8-4.56-3.06 1.75-4.65 5.71-3.65 9.09l-3.21-4.23c-.03 1.52-.06 3.05-.09 4.57-3.54-2.84-7.75-4.83-12.19-5.74.89 1.97 2.3 3.71 4.04 4.99-8.01-1.34-15.14-6.07-20.77-11.91s-10.13-12.69-13.51-17.57z" />
      ),
      basic: {
        dark: (
          <path d="M296.61 172.86c1.76-1.27 4.14.28 5.7 1.79 5.51 1.85 10.26-12.36 18.28-12.01 24.6-5.96 19.63 34.87 2.55 39.13 3.88 4.48 4.43 20.47-3.16 10.29-5.13-13.6-24.54-25.32-23.37-39.2z" />
        ),
      },
    },
  },
};

const ear_shape_13wnpn = {
  name: "Ears 10 (Furry)",
  description: "Furry, large round ears on the top of the head",
  tags: ["rounded", "top", "furry"],
  preview: {
    viewbox: "110 110 120 120",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M192.18 200.56c-4.31 5.87-9.32 12.19-16.49 13.46-10.95 1.93-20.91-8.89-31.97-7.69 1.51-1.09 3.38-1.66 5.24-1.6-7.24-3.43-13.42-9.04-17.53-15.91-2.71-4.53-4.73-9.82-9.1-12.79 1.68-.14 3.4.3 4.81 1.23-3.55-3.28-4.44-8.65-3.66-13.42s2.97-9.17 4.78-13.64c1.81-4.48 3.28-9.31 2.53-14.08 1.34 1.48 2.01 3.54 1.78 5.52 3.18-4.53 2.93-11.17-.58-15.46 3.78 1.35 6.64 4.97 7.08 8.95 2.52-5.66.99-12.86-3.6-17.01 4.56 4.75 10.02 8.64 16.01 11.38-3.26-1.78-5.13-5.8-4.37-9.44 2.86 5.02 8.56 8.3 14.34 8.23-1.88-1.01-2.93-3.35-2.44-5.43 5.92 7.17 18.7 5.39 24.18 12.9-1.91-2.57-2.92-5.79-2.82-8.99 2.05 4.76 6.33 8.11 10.02 11.75 3.69 3.65 7.09 8.37 6.54 13.52-.39-2.99.47-6.13 2.33-8.51-.97 6.55-1 13.24-.11 19.81-.1-2.82 2.12-5.54 4.9-6-4.06 5.51-2.57 13.13-3.29 19.93-.96 9.12-4.68 17.99-8.58 23.29z" />
      ),
      basic: {
        dark: (
          <path d="M200.96 176.58c-1.42-4.06-6.29 3.66-8.04 4.85-11.39-49.86-64.66 11.61-16.31 23.22-1.14 2.73-3.44 5.54-3.02 8.6 1.66 6.93 8.61-5.71 9.53-7.09 5.4-9.92 15.19-18.67 17.84-29.58z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M307.81 200.57c4.31 5.87 9.32 12.19 16.49 13.46 10.95 1.93 20.91-8.89 31.97-7.69-1.51-1.09-3.38-1.66-5.24-1.6 7.24-3.43 13.42-9.04 17.53-15.91 2.71-4.53 4.73-9.82 9.1-12.79-1.68-.14-3.4.3-4.81 1.23 3.55-3.28 4.44-8.65 3.66-13.42-.78-4.77-2.97-9.17-4.78-13.64s-3.28-9.31-2.53-14.08c-1.34 1.48-2.01 3.54-1.78 5.52-3.18-4.53-2.93-11.17.58-15.46-3.78 1.35-6.64 4.97-7.08 8.95-2.52-5.66-.99-12.86 3.6-17.01-4.56 4.75-10.02 8.64-16.01 11.38 3.26-1.78 5.13-5.8 4.37-9.44-2.86 5.02-8.56 8.3-14.34 8.23 1.88-1.01 2.93-3.35 2.44-5.43-5.92 7.17-18.7 5.39-24.18 12.9 1.91-2.57 2.92-5.79 2.82-8.99-2.05 4.76-6.33 8.11-10.02 11.75-3.69 3.65-7.09 8.37-6.54 13.52.39-2.99-.47-6.13-2.33-8.51.97 6.55 1 13.24.11 19.81.1-2.82-2.12-5.54-4.9-6 4.06 5.51 2.57 13.13 3.29 19.93.97 9.12 4.69 17.99 8.58 23.29z" />
      ),
      basic: {
        dark: (
          <path d="M299.04 176.58c1.42-4.06 6.29 3.66 8.04 4.85 11.39-49.86 64.66 11.61 16.31 23.22 1.14 2.73 3.44 5.54 3.02 8.6-1.66 6.93-8.61-5.71-9.53-7.09-5.4-9.92-15.19-18.67-17.84-29.58z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1PDULw = {
  name: "Ears 11 (Furry)",
  description: "Furry, small finned ears with three points",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M180.45,250.86
						c-0.82-6.83-6.32-12.04-11.65-16.39s-11.15-8.91-13.12-15.5c-1.02,1.49-1.19,3.53-0.43,5.17c-4.91-5.48-11.31-9.62-18.32-11.86
						c-3.3-1.05-6.75-1.7-9.9-3.15s-6.07-3.9-7.01-7.23c-0.76,2.91-0.21,6.14,1.47,8.63c-1.57,0.71-3.52,0.46-4.87-0.61
						c0.5,3.03,2.42,5.79,5.08,7.33c-1.43,0.22-2.86,0.44-4.29,0.66c6.61,3.74,10.26,12.05,8.55,19.44c-0.5,2.16-1.5,4.34-3.37,5.54
						c-1.86,1.2-4.72,1.01-5.93-0.84c-0.49,1.57,0.17,3.43,1.54,4.34c-2.13,1.16-4.78,1.32-7.04,0.42c1.08,1.93,2.68,3.58,4.58,4.71
						c-0.84,1.24-2.29,2.05-3.79,2.1c3.97,3.07,7.95,6.14,11.92,9.2c2.06,1.59,4.16,3.22,5.53,5.43s1.87,5.15,0.57,7.4
						c-1.3,2.25-4.72,3.21-6.63,1.45c1.08,2.2,4.04,3.25,6.26,2.2c-0.83,3.42-4.53,5.87-8,5.3c4.8,3.01,11.08,3.54,16.31,1.36
						c-0.01,1-0.89,1.92-1.89,1.97c7.27-1.65,14.4-3.91,21.3-6.73c-0.01,1.35-0.91,2.64-2.17,3.12c8.03-1.46,15.37-6.34,19.82-13.18
						C179.45,264.32,181.02,255.63,180.45,250.86z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M171.49,261.13c0.42-7.47,3.21-47.18-5.12-19.35c-3.31-2.88-30.41-23.21-26.49-15.66c4.12,3.13,7.75,6.89,10.73,11.12c5.4,6.04,2.3,13.19-1.85,19.1c-4.59,6.34-2.18,16.62,6.78,14.4c4.28-0.62,6.79-5.44,10.86-6.09c169.54,289.57,176.14,279.22,171.49,261.13z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M319.55,250.86
						c0.82-6.83,6.32-12.04,11.65-16.39c5.33-4.35,11.15-8.91,13.12-15.5c1.02,1.49,1.19,3.53,0.43,5.17
						c4.91-5.48,11.31-9.62,18.32-11.86c3.3-1.05,6.75-1.7,9.9-3.15s6.07-3.9,7.01-7.23c0.76,2.91,0.21,6.14-1.47,8.63
						c1.57,0.71,3.52,0.46,4.87-0.61c-0.5,3.03-2.42,5.79-5.08,7.33c1.43,0.22,2.86,0.44,4.29,0.66
						c-6.61,3.74-10.26,12.05-8.55,19.44c0.5,2.16,1.5,4.34,3.37,5.54c1.86,1.2,4.72,1.01,5.93-0.84c0.49,1.57-0.17,3.43-1.54,4.34
						c2.13,1.16,4.78,1.32,7.04,0.42c-1.08,1.93-2.68,3.58-4.58,4.71c0.84,1.24,2.29,2.05,3.79,2.1c-3.97,3.07-7.95,6.14-11.92,9.2
						c-2.06,1.59-4.16,3.22-5.53,5.43s-1.87,5.15-0.57,7.4s4.72,3.21,6.63,1.45c-1.08,2.2-4.04,3.25-6.26,2.2
						c0.83,3.42,4.53,5.87,7.99,5.3c-4.8,3.01-11.08,3.54-16.31,1.36c0.01,1,0.89,1.92,1.89,1.97c-7.27-1.65-14.4-3.91-21.3-6.73
						c0.01,1.35,0.91,2.64,2.17,3.12c-8.03-1.46-15.37-6.34-19.82-13.18C320.55,264.32,318.98,255.63,319.55,250.86z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M328.51,261.13c-0.42-7.47-3.21-47.18,5.12-19.35c3.31-2.88,30.41-23.21,26.49-15.66c-4.12,3.13-7.75,6.89-10.73,11.12c-5.4,6.04-2.3,13.19,1.85,19.1c4.59,6.34,2.18,16.62-6.78,14.4c-4.28-0.62-6.79-5.44-10.86-6.09C330.46,289.57,323.86,279.22,328.51,261.13z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_1kOBSt = {
  name: "Ears 12 (Furry)",
  description: "Furry, tall finned ears with three points",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "100 170 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M176.22 252.02c1.36-4.43-.11-9.28-2.42-13.3-2.31-4.02-5.44-7.52-7.78-11.52-2.35-4-3.9-8.81-2.64-13.27-1.85.73-3.11 2.75-2.95 4.73-.53-6.51-4.96-11.94-8.91-17.14-3.95-5.21-7.78-11.34-6.84-17.81-1.52 1.09-2.37 3.04-2.15 4.89-2.57-3.9-3.54-8.82-2.64-13.4-3.42 2.84-5.45 7.29-5.37 11.73-.98-.61-1.96-1.21-2.94-1.82 1.73 10 3.47 19.99 5.2 29.99-.59-2.72-1.91-5.27-3.78-7.34 1.1 4.04 2.2 8.12 2.36 12.31.17 4.18-.69 8.54-3.17 11.91-2.49 3.37-6.81 5.54-10.92 4.75.56 1.39 1.87 2.44 3.34 2.7-1.96 2.54-6.1 3.01-8.58.97 1.25 3.58 4.11 6.42 7.36 8.38 3.25 1.96 6.9 3.14 10.51 4.31-2.73.47-5.61-.01-8.05-1.33 2.52 2.79 6.81 2.79 10.36 4.03 4.63 1.61 8.3 5.74 9.37 10.52 1.07 4.79-.51 10.08-4.02 13.5 1.66-.19 3.27-.87 4.56-1.94.6 2.52-1.51 5.31-4.09 5.43 9.37 1.52 19.4-1.84 25.97-8.69 6.56-6.86 9.48-17.02 8.22-22.59z" />
      ),
      basic: {
        dark: (
          <path d="M172 262.52c-.52-6.61-.56-13.25-.14-19.87.28-4.29.68-8.9-1.51-12.6-1.6 3.78-2.79 7.73-3.56 11.76-5.23-8.32-10.95-16.34-16.37-24.54.04 6.81 4.75 12.83 5.38 19.61.99 8.48-5.69 17.86-.77 25.81 1.19 1.59 3.22 2.65 5.17 2.29 2.36-.57 4.3-2.63 6.6-.74.29 1.88.61 3.76.94 5.64.26 1.47.55 3.03 1.57 4.12 2.31 2.48 3.76.47 3.78-1.76.05-3.16-.84-6.55-1.09-9.72z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M323.78 252.02c-1.36-4.43.11-9.28 2.42-13.3 2.31-4.02 5.44-7.52 7.78-11.52 2.35-4 3.9-8.81 2.64-13.27 1.85.73 3.11 2.75 2.95 4.73.53-6.51 4.96-11.94 8.91-17.14 3.95-5.21 7.78-11.34 6.84-17.81 1.52 1.09 2.37 3.04 2.15 4.89 2.57-3.9 3.54-8.82 2.64-13.4 3.42 2.84 5.45 7.29 5.37 11.73.98-.61 1.96-1.21 2.94-1.82-1.73 10-3.47 19.99-5.2 29.99.59-2.72 1.91-5.27 3.78-7.34-1.1 4.04-2.2 8.12-2.36 12.31-.17 4.18.69 8.54 3.17 11.91s6.81 5.54 10.92 4.75c-.56 1.39-1.87 2.44-3.34 2.7 1.96 2.54 6.1 3.01 8.58.97-1.25 3.58-4.11 6.42-7.36 8.38-3.25 1.96-6.9 3.14-10.51 4.31 2.73.47 5.61-.01 8.05-1.33-2.52 2.79-6.81 2.79-10.36 4.03-4.63 1.61-8.3 5.74-9.37 10.52s.51 10.08 4.02 13.5c-1.66-.19-3.27-.87-4.56-1.94-.6 2.52 1.51 5.31 4.09 5.43-9.37 1.52-19.4-1.84-25.97-8.69-6.56-6.86-9.48-17.02-8.22-22.59z" />
      ),
      basic: {
        dark: (
          <path d="M328 262.52c.52-6.61.56-13.25.14-19.87-.28-4.29-.68-8.9 1.51-12.6 1.6 3.78 2.79 7.73 3.56 11.76 5.23-8.32 10.95-16.34 16.37-24.54-.04 6.81-4.75 12.83-5.38 19.61-.99 8.48 5.69 17.86.77 25.81-1.19 1.59-3.22 2.65-5.17 2.29-2.36-.57-4.3-2.63-6.6-.74-.29 1.88-.61 3.76-.94 5.64-.26 1.47-.55 3.03-1.57 4.12-2.31 2.48-3.76.47-3.78-1.76-.05-3.16.84-6.55 1.09-9.72z" />
        ),
      },
    },
  },
};

const ear_shape_1yAT34 = {
  name: "Ears 13 (Furry)",
  description: "Furry, large and wide pointed ears that droop down",
  tags: ["pointed", "side", "drooping", "large", "furry"],
  preview: {
    viewbox: "80 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M184.08 235.13c3.3-9.36 1.56-20.34-4.49-28.21-2.62-3.41-6.13-6.6-6.51-10.88-2.36 1.45-3.19 4.91-1.74 7.27-4.75-.34-9.14-3.72-10.68-8.22-2.01 2.55-1.97 6.51.1 9.02-3.43.09-6.89-.91-9.74-2.83-.45 1.84.32 3.92 1.87 5.01-6.64.2-12.22 4.87-16.9 9.58-4.68 4.71-9.39 9.93-15.77 11.79 1.16 1.01 2.98 1.16 4.3.36-14.19 12.48-13.18 36.56-27.64 48.72 1.38.21 2.85-.24 3.88-1.19-7.21 13.33-8.52 29.72-3.53 44.03-.58-1.98-.58-4.13 0-6.12.58 2.84 1.84 5.54 3.66 7.8.64-4.87 3.9-9.31 8.36-11.38-.67 1.42-1.34 2.85-2.01 4.27 4.7-3.96 9.49-7.97 15.15-10.34s12.44-2.89 17.79.12c-2.21.51-4.42 1.03-6.63 1.54 5.1 2.74 11.74 2.33 16.46-1.03-1.27 1.29-1.9 3.19-1.63 4.98 6.21-11.61 19.6-17.56 27.4-28.17 4.84-6.59 7.28-14.7 8.23-22.83.94-8.12-2.25-16.72.07-23.29z" />
      ),
      basic: {
        dark: (
          <path d="M182.35 204.02c-7.83-2.79-10.5 10.27-12.57 15.8-18.91 2.78-46.55 38.88-44.84 57.34 25.25-39.65 20.5-6.61 40.79-16.35 1.42 8.25 1.52 18.36 6.69 25.12 5.73 3.42 2.57-9.97 2.34-11.78-.81-6.4-1.46-12.83-1.7-19.28-.47-12.69.65-25.58 5.19-37.53 1.4-4.34 4.35-8.75 4.1-13.32z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.92 235.13c-3.3-9.36-1.56-20.34 4.49-28.21 2.62-3.41 6.13-6.6 6.51-10.88 2.36 1.45 3.19 4.91 1.74 7.27 4.75-.34 9.14-3.72 10.68-8.22 2.01 2.55 1.97 6.51-.1 9.02 3.43.09 6.89-.91 9.74-2.83.45 1.84-.32 3.92-1.87 5.01 6.64.2 12.22 4.87 16.9 9.58 4.68 4.71 9.39 9.93 15.77 11.79-1.16 1.01-2.98 1.16-4.3.36 14.19 12.48 13.18 36.56 27.64 48.72-1.38.21-2.85-.24-3.88-1.19 7.21 13.33 8.52 29.72 3.53 44.03.58-1.98.58-4.13 0-6.12-.58 2.84-1.84 5.54-3.66 7.8-.64-4.87-3.9-9.31-8.36-11.38.67 1.42 1.34 2.85 2.01 4.27-4.7-3.96-9.49-7.97-15.15-10.34s-12.44-2.89-17.79.12c2.21.51 4.42 1.03 6.63 1.54-5.1 2.74-11.74 2.33-16.46-1.03 1.27 1.29 1.9 3.19 1.63 4.98-6.21-11.61-19.6-17.56-27.4-28.17-4.84-6.59-7.28-14.7-8.23-22.83-.94-8.12 2.25-16.72-.07-23.29z" />
      ),
      basic: {
        dark: (
          <path d="M317.65 204.02c7.83-2.79 10.5 10.27 12.57 15.8 18.91 2.78 46.55 38.88 44.84 57.34-25.25-39.65-20.5-6.61-40.79-16.35-1.42 8.25-1.52 18.36-6.69 25.12-5.73 3.42-2.57-9.97-2.34-11.78.81-6.4 1.46-12.83 1.7-19.28.47-12.69-.65-25.58-5.19-37.53-1.4-4.34-4.35-8.75-4.1-13.32z" />
        ),
      },
    },
  },
};

const ear_shape_18wAS4 = {
  name: "Ears 14 (Furry)",
  description: "Furry, wide ears with a long dangling lobe",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "100 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M180.73 252.7c1.83-3.86.76-8.56-1.45-12.21-2.22-3.65-5.45-6.55-8.32-9.72-2.88-3.16-5.51-6.82-6.08-11.06-1.58.65-1.94 3.13-.63 4.21-5.92-2.45-10.45-8-11.66-14.28-2.41 1.29-3.4 4.68-2.08 7.07-2.36-.63-4.33-2.55-5.01-4.89-1.88 1.56-2.78 4.22-2.23 6.6-1.17.11-2.38-.21-3.34-.89-4.41 7.65-12.04 13.39-20.61 15.5.45.92 1.5 1.5 2.52 1.41-.99 2.19-3.5 3.59-5.88 3.28-.17 1.63 1.38 3.21 3.01 3.07-.32 5.21-.64 10.46.14 15.62.78 5.16 2.76 10.28 6.45 13.96-2.02-.95-3.78-2.45-5.05-4.29-.31 2 .77 3.99 2.24 5.38s3.29 2.33 4.98 3.43c9.54 6.19 14.91 18.23 13.16 29.46 1.74.24 3.59-.68 4.45-2.21.75 2-.36 4.51-2.36 5.29 6.79 3.29 15.81.94 20.12-5.25l-.57 3.75c6.7-7.11 9.38-17.04 11.21-26.64 1.82-9.58 3.53-19.33 6.99-26.59z" />
      ),
      basic: {
        dark: (
          <path d="M173.79 265.4c-.58-6.18 2.73-51.43-5.77-31.7-10.93-9.09-27.52-11.09-34.32 3.46-.08.81.57 1.73 1.38 1.6 14.71-18.41 30.18 8.4 14.75 20.93-7.51 5.69-6.5 20.01 2.64 22.88 3.85-.65 3.57-5.57 4.81-8.43 8-10.93 10.86 4.09 13.61 10.1 6.06-5.2 2.92-11.63 2.9-18.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M319.28 252.7c-1.83-3.86-.76-8.56 1.45-12.21 2.22-3.65 5.45-6.55 8.32-9.72 2.88-3.16 5.51-6.82 6.08-11.06 1.58.65 1.94 3.13.63 4.21 5.92-2.45 10.45-8 11.66-14.28 2.41 1.29 3.4 4.68 2.08 7.07 2.36-.63 4.33-2.55 5.01-4.89 1.88 1.56 2.78 4.22 2.23 6.6 1.17.11 2.38-.21 3.34-.89 4.41 7.65 12.04 13.39 20.61 15.5-.45.92-1.5 1.5-2.52 1.41.99 2.19 3.5 3.59 5.88 3.28.17 1.63-1.38 3.21-3.01 3.07.32 5.21.64 10.46-.14 15.62-.78 5.16-2.76 10.28-6.45 13.96 2.02-.95 3.78-2.45 5.05-4.29.31 2-.77 3.99-2.24 5.38s-3.29 2.33-4.98 3.43c-9.54 6.19-14.91 18.23-13.16 29.46-1.74.24-3.59-.68-4.45-2.21-.75 2 .36 4.51 2.36 5.29-6.79 3.29-15.81.94-20.12-5.25l.57 3.75c-6.7-7.11-9.38-17.04-11.21-26.64-1.83-9.59-3.54-19.33-6.99-26.59z" />
      ),
      basic: {
        dark: (
          <path d="M326.21 265.4c.58-6.18-2.73-51.43 5.77-31.7 10.93-9.09 27.52-11.09 34.32 3.46.08.81-.57 1.73-1.38 1.6-14.71-18.41-30.18 8.4-14.75 20.93 7.51 5.69 6.5 20.01-2.64 22.88-3.85-.65-3.57-5.57-4.81-8.43-8-10.93-10.86 4.09-13.61 10.1-6.06-5.2-2.92-11.63-2.9-18.84z" />
        ),
      },
    },
  },
};

// TODO: Tweak right manually - looked strange on SVGomg
const ear_shape_1gcoC9 = {
  name: "Ears 15 (Furry)",
  description: "Furry, wide ears with a slight point",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "90 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M182.87 249.52c.48-4.33-1.68-8.59-4.64-11.78s-6.69-5.54-10.2-8.12c-3.51-2.57-6.93-5.52-8.86-9.42-1.21 1.42-1.37 3.64-.36 5.21-4.41-1.41-8.37-4.23-11.14-7.94-.56 1.32-.02 3.01 1.19 3.78-1.97-.46-3.93-.91-5.9-1.37.38.41.75.83 1.13 1.24-6.34-1.9-13.16-.46-19.54 1.29-6.38 1.75-12.89 3.81-19.48 3.14.73 1.79 2.42 3.16 4.33 3.48-1.84 2.24-5.48 2.72-7.84 1.04 1.68 3.69 4.84 6.69 8.61 8.18-2 .45-4.18.05-5.88-1.09 3.47 4.25 7.81 7.79 12.66 10.34-2.13-.61-4.26-1.21-6.39-1.82 6.95 3.49 11.65 10.21 15.6 16.9 3.96 6.7 7.59 13.8 13.4 18.97 1.93 1.72 4.11 3.24 5.58 5.37 1.46 2.14 2.04 5.17.53 7.27 2.78-.42 5.41-1.74 7.41-3.72.57 1.32.46 2.91-.28 4.14 10.01-1.89 18.25-9.55 22.89-18.62 4.63-9.07 6.4-19.39 7.18-26.47z" />
      ),
      basic: {
        dark: (
          <path d="M176 279.78c-2.14-12.51-3.22-25.21-3.23-37.9 0-2.77 1.98-13.57-1.11-14.73-3.71-1.39-5.3 5.41-5.89 9.55-6.21-4.5-28.93-13.13-34.78-7.29 36.84-.49 6.66 33.35 17.02 43.56 7.66 5.7 12-6.43 18.41-7.33 1.09 7.26 2.03 16.3 8.47 20.62 1.76-1.49 1.49-4.21 1.11-6.48z" />
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M317.13,249.52
						c-0.48-4.33,1.68-8.59,4.64-11.78c2.96-3.19,6.69-5.54,10.2-8.12s6.93-5.52,8.86-9.42c1.21,1.42,1.37,3.64,0.36,5.21
						c4.41-1.41,8.37-4.23,11.14-7.94c0.56,1.32,0.02,3.01-1.19,3.78c1.97-0.46,3.93-0.91,5.9-1.37c-0.38,0.41-0.75,0.83-1.13,1.24
						c6.34-1.9,13.16-0.46,19.54,1.29c6.38,1.75,12.89,3.81,19.48,3.14c-0.73,1.79-2.42,3.16-4.33,3.48
						c1.84,2.24,5.48,2.72,7.84,1.04c-1.68,3.69-4.84,6.69-8.61,8.18c2,0.45,4.18,0.05,5.88-1.09c-3.47,4.25-7.81,7.79-12.66,10.34
						c2.13-0.61,4.26-1.21,6.39-1.82c-6.95,3.49-11.65,10.21-15.6,16.9c-3.96,6.7-7.59,13.8-13.4,18.97
						c-1.93,1.72-4.11,3.24-5.58,5.37c-1.46,2.14-2.04,5.17-0.53,7.27c-2.78-0.42-5.41-1.74-7.41-3.72
						c-0.57,1.32-0.46,2.91,0.28,4.14c-10.01-1.89-18.25-9.55-22.89-18.62C319.68,266.92,317.91,256.6,317.13,249.52z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324,279.78c2.14-12.51,3.22-25.21,3.23-37.9c0-2.77-1.98-13.57,1.11-14.73c3.71-1.39,5.3,5.41,5.89,9.55c6.21-4.5,28.93-13.13,34.78-7.29c-36.84-0.49-6.66,33.35-17.02,43.56c-7.66,5.7-12-6.43-18.41-7.33c-1.09,7.26-2.03,16.3-8.47,20.62c323.35,284.77,323.62,282.05,324,279.78z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_1bPDFx = {
  name: "Ears 16 (Furry)",
  description: "Furry, small ears with a slight point that point upwards",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "110 190 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M178.57 256.31c.88-6.75-2.06-13.39-5.31-19.37-3.26-5.98-6.99-11.95-7.93-18.7-1.07 1.52-1.53 3.46-1.25 5.3-3.78-6.8-8.99-12.81-15.19-17.51-2.34-1.78-4.84-3.39-6.78-5.6-1.94-2.21-3.27-5.18-2.71-8.06-2.22 1.61-3.28 4.64-2.56 7.28-2.02-.25-3.62-2.32-3.37-4.33-2.02 3.28-2.75 7.33-2.02 11.11-1.03-.35-2.06-.69-3.09-1.04 3.81 5.96 4.37 13.86 1.44 20.3.75-.45 1.51-.9 2.26-1.35 1.37 8.92 2.76 17.87 5.44 26.49s6.75 16.94 12.9 23.54c.94 1.01 1.96 2.02 2.44 3.31s.26 2.98-.9 3.72c1.93-.25 3.87-.5 5.8-.75 1.06 1.08.35 3.26-1.14 3.51 4.95.99 10.21-.97 13.88-4.44 3.67-3.47 5.86-8.28 6.9-13.22s1.01-10.06 1.19-10.19z" />
      ),
      basic: {
        dark: (
          <path d="M175.05 279.45c-3.65-14.96-2.45-30.34-2.38-45.53-.15-4.23-4.02-3.05-4.54.61-.52 2.49-.95 5.01-1.3 7.54-4.75-12.6-13.01-26.72-25.46-32.51 20.85 19.68-.34 52.86 17.15 57.43 2.73.2 5.84-1.4 8.09-.08 1.37 5.69 1.55 13.55 6.33 17.42 4.07 1.63 2.48-3.27 2.11-4.88z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M321.43 256.31c-.88-6.75 2.06-13.39 5.31-19.37 3.26-5.98 6.99-11.95 7.93-18.7 1.07 1.52 1.53 3.46 1.25 5.3 3.78-6.8 8.99-12.81 15.19-17.51 2.34-1.78 4.84-3.39 6.78-5.6s3.27-5.18 2.71-8.06c2.22 1.61 3.28 4.64 2.56 7.28 2.02-.25 3.62-2.32 3.37-4.33 2.02 3.28 2.75 7.33 2.02 11.11 1.03-.35 2.06-.69 3.09-1.04-3.81 5.96-4.37 13.86-1.44 20.3-.75-.45-1.51-.9-2.26-1.35-1.37 8.92-2.76 17.87-5.44 26.49-2.69 8.61-6.75 16.94-12.9 23.54-.94 1.01-1.96 2.02-2.44 3.31s-.26 2.98.9 3.72c-1.93-.25-3.87-.5-5.8-.75-1.06 1.08-.35 3.26 1.14 3.51-4.95.99-10.21-.97-13.88-4.44-3.67-3.47-5.86-8.28-6.9-13.22-1.04-4.96-1.01-10.06-1.19-10.19z" />
      ),
      basic: {
        dark: (
          <path d="M324.95 279.45c3.65-14.96 2.45-30.34 2.38-45.53.15-4.23 4.02-3.05 4.54.61.52 2.49.95 5.01 1.3 7.54 4.75-12.6 13.01-26.72 25.46-32.51-20.85 19.68.34 52.86-17.15 57.43-2.73.2-5.84-1.4-8.09-.08-1.37 5.69-1.55 13.55-6.33 17.42-4.07 1.63-2.48-3.27-2.11-4.88z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1oEAG6 = {
  name: "Ears 17 (Furry)",
  description: "Furry, small ears with a slight point",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M177.72,254.34
						c0.63-3.55,1.26-7.13,1.06-10.73c-0.19-3.6-1.29-7.26-3.73-9.92c-3.61-3.93-9.86-5.46-11.91-10.39
						c-1.19,1.64-0.93,4.16,0.57,5.52c-4.18-0.66-7.92-3.65-9.48-7.59c-0.99,2.12-0.49,4.83,1.19,6.46
						c-4.63-2.75-10.4-2.27-15.77-1.88s-11.26,0.46-15.51-2.84c-0.29,2.89,0.98,5.88,3.26,7.68c-1.53,0.5-3.31,0.17-4.56-0.84
						c1.2,3.56,4.2,6.45,7.8,7.51c-1.37,0.16-2.74,0.33-4.11,0.49c6.68,4.17,10.94,11.28,14.15,18.46
						c3.22,7.18,5.69,14.77,9.99,21.36c1.26,1.93,2.69,3.79,3.6,5.91s1.27,4.62,0.32,6.72c1.06,0.43,2.25-0.1,3.28-0.61
						s2.24-1.02,3.28-0.54c1.04,0.48,1.11,2.45-0.03,2.53c4.61-0.79,9.51-1.72,12.91-4.94c3.77-3.58,4.79-9.18,4.89-14.38
						C179.05,265.54,176.94,258.76,177.72,254.34z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M174.6,261.03c-3-11.42-3.38-22.02-0.77-33.5c-0.45-0.74-1.03-1.4-1.81-1.91c-2.59,1.45-3.7,4.56-4.44,7.43c-0.28,1.07-0.53,2.15-0.76,3.24c-2.24-1.11-4.72-1.67-7.19-2.07c-6.12-0.98-12.4-1.02-18.53-0.13c0.73,2.02,3.19,2.64,5.13,3.55c4.08,1.92,6.7,6.09,7.97,10.41c1.27,4.32,1.4,8.88,1.83,13.37c0.22,2.37,0.56,4.82,1.81,6.85c1.24,2.03,3.63,3.55,5.97,3.08c0.96-0.2,1.89-0.71,2.87-0.6c0.19,0.02,0.36,0.07,0.53,0.13c0.77,3.32,1.73,6.6,2.92,9.8c1.01,2.71,7.41,17.89,7.9,7.98c0.02-0.38,0.03-0.76,0.03-1.13c172.62,275.76,177.63,272.54,174.6,261.03z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M322.28,254.34
						c-0.63-3.55-1.26-7.13-1.06-10.73c0.19-3.6,1.29-7.26,3.73-9.92c3.61-3.93,9.86-5.46,11.91-10.39
						c1.19,1.64,0.93,4.16-0.57,5.52c4.18-0.66,7.92-3.65,9.48-7.59c0.99,2.12,0.49,4.83-1.19,6.46c4.63-2.75,10.4-2.27,15.77-1.88
						c5.37,0.39,11.26,0.46,15.51-2.84c0.29,2.89-0.98,5.88-3.26,7.68c1.53,0.5,3.31,0.17,4.56-0.84c-1.2,3.56-4.2,6.45-7.8,7.51
						c1.37,0.16,2.74,0.33,4.11,0.49c-6.68,4.17-10.94,11.28-14.15,18.46c-3.22,7.18-5.69,14.77-9.99,21.36
						c-1.26,1.93-2.69,3.79-3.6,5.91s-1.27,4.62-0.32,6.72c-1.06,0.43-2.25-0.1-3.28-0.61s-2.24-1.02-3.28-0.54
						c-1.04,0.48-1.11,2.45,0.03,2.53c-4.61-0.79-9.51-1.72-12.91-4.94c-3.77-3.58-4.79-9.18-4.89-14.38
						C320.95,265.54,323.06,258.76,322.28,254.34z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M325.4,261.03c3-11.42,3.38-22.02,0.77-33.5c0.45-0.74,1.03-1.4,1.81-1.91c2.59,1.45,3.7,4.56,4.44,7.43c0.28,1.07,0.53,2.15,0.76,3.24c2.24-1.11,4.72-1.67,7.19-2.07c6.12-0.98,12.4-1.02,18.53-0.13c-0.73,2.02-3.19,2.64-5.13,3.55c-4.08,1.92-6.7,6.09-7.97,10.41c-1.27,4.32-1.4,8.88-1.83,13.37c-0.22,2.37-0.56,4.82-1.81,6.85c-1.24,2.03-3.63,3.55-5.97,3.08c-0.96-0.2-1.89-0.71-2.87-0.6c-0.19,0.02-0.36,0.07-0.53,0.13c-0.77,3.32-1.73,6.6-2.92,9.8c-1.01,2.71-7.41,17.89-7.9,7.98c-0.02-0.38-0.03-0.76-0.03-1.13C327.38,275.76,322.37,272.54,325.4,261.03z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_1Dp6oZ = {
  name: "Ears 18 (Furry)",
  description:
    "Furry, wide ears with a slight point that reach slightly upwards",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.73 264.76c-.15-9.32-3.53-18.55-9.43-25.76-2.68-3.27-5.84-6.11-8.57-9.33-2.73-3.22-5.09-6.95-5.71-11.13-1.58 1.32-1.85 3.94-.58 5.56-4.4-3.1-8.83-6.23-12.54-10.13-3.71-3.9-6.71-8.66-7.65-13.96-3.08 2.57-4.83 6.66-4.58 10.66-1.75-.88-2.94-2.78-2.98-4.75-2.24 2.56-2.97 6.35-1.85 9.56-.74-.3-1.49-.61-2.23-.91l.24 2.73c-.82-.35-1.63-.7-2.45-1.05 2.8 5.36 3.4 11.84 1.62 17.63.56-.6 1.12-1.21 1.68-1.81.29 7.1.59 14.23 1.99 21.19 1.4 6.96 3.97 13.81 8.38 19.37 2.08 2.62 4.72 5.59 3.86 8.81.71-.86 1.42-1.73 2.12-2.59 1.74 4.58 6.58 7.05 10.93 9.31 4.35 2.26 9.03 5.35 9.85 10.18 1.82-1.64 3.64-3.27 5.45-4.91l.54 3.9c8.75-9.88 12.05-23.49 11.91-32.57z" />
      ),
      basic: {
        dark: (
          <path d="M176.9 284.23c-1.21-4.1-2.79-8.36-3.01-12.36-5.07-11.16-4.58-23.8-2-35.8-.77-5.21-4.28-6.51-5.24.94-6.7-4.73-12.26-14.39-20.9-14.87 7.8 8.65 11.84 19.51 7.87 30.8-4.48 11.03 2.28 25.96 15.55 22.1 1.86 6.36 2.46 13.96 7.19 18.92 5.6 3.18 1.27-7.35.54-9.73z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.27 264.76c.15-9.32 3.53-18.55 9.43-25.76 2.68-3.27 5.84-6.11 8.57-9.33 2.73-3.22 5.09-6.95 5.71-11.13 1.58 1.32 1.85 3.94.58 5.56 4.4-3.1 8.83-6.23 12.54-10.13 3.71-3.9 6.71-8.66 7.65-13.96 3.08 2.57 4.83 6.66 4.58 10.66 1.75-.88 2.94-2.78 2.98-4.75 2.24 2.56 2.97 6.35 1.85 9.56.74-.3 1.49-.61 2.23-.91l-.24 2.73c.82-.35 1.63-.7 2.45-1.05-2.8 5.36-3.4 11.84-1.62 17.63-.56-.6-1.12-1.21-1.68-1.81-.29 7.1-.59 14.23-1.99 21.19-1.4 6.96-3.97 13.81-8.38 19.37-2.08 2.62-4.72 5.59-3.86 8.81-.71-.86-1.42-1.73-2.12-2.59-1.74 4.58-6.58 7.05-10.93 9.31-4.35 2.26-9.03 5.35-9.85 10.18-1.82-1.64-3.64-3.27-5.45-4.91l-.54 3.9c-8.75-9.88-12.05-23.49-11.91-32.57z" />
      ),
      basic: {
        dark: (
          <path d="M323.1 284.23c1.21-4.1 2.79-8.36 3.01-12.36 5.07-11.16 4.58-23.8 2-35.8.77-5.21 4.28-6.51 5.24.94 6.7-4.73 12.26-14.39 20.9-14.87-7.8 8.65-11.84 19.51-7.87 30.8 4.48 11.03-2.28 25.96-15.55 22.1-1.86 6.36-2.46 13.96-7.19 18.92-5.6 3.18-1.27-7.35-.54-9.73z" />
        ),
      },
    },
  },
};

const ear_shape_1kf82L = {
  name: "Ears 19 (Furry)",
  description: "Furry, large and wavy ears on the top of the head",
  tags: ["top", "furry"],
  preview: {
    viewbox: "90 75 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="m196.88 197.36 1.05-37.59c-1.9.89-3.24 2.88-3.34 4.97-1.74-6.72-1.81-13.86-.2-20.61-1.18.97-2.35 1.94-3.53 2.91-3.64-6.25-8.47-11.71-12.9-17.44s-8.53-11.9-10.38-18.9-1.11-15 3.41-20.65c-2.34.09-4.47 2.05-4.76 4.38-2.43-6.21-1.33-13.68 2.79-18.93-5.06 1.73-9.45 5.38-12.06 10.05-.47-1.96-.95-3.92-1.42-5.88-7.47 8.64-11.77 19.96-11.92 31.38-1.17-3.09-1.39-6.53-.62-9.74-2.65 3.5-3.95 8-3.58 12.38.17 2.01.68 3.99.65 6s-.71 4.17-2.39 5.29c-1.98 1.33-4.78.76-6.67-.69-1.89-1.45-3.09-3.61-4.24-5.71-1.94 3.04-2.63 6.84-1.89 10.37-2.05.92-4.7.29-6.1-1.47-.28 4.84 1.3 9.75 4.34 13.52-1.08.46-2.41.29-3.34-.43 2.34 5.23 4.69 10.46 7.03 15.69 1.13 2.51 2.27 5.1 2.34 7.86.07 2.75-1.2 5.73-3.72 6.83-2.52 1.1-6.08-.63-6.09-3.38-2.48 4.14-2.48 9.64.01 13.77.5.83 1.12 1.72.93 2.67-.2 1.06-1.38 1.7-2.46 1.67-1.08-.03-2.07-.55-3.02-1.06 3.02 4.91 7.87 8.66 13.38 10.34.57 3.29-3.67 6.26-6.56 4.59 12.46 7.88 27.59 11.44 42.26 9.96-.88 1.72-2.63 2.96-4.54 3.21 7.31-.12 14.75-.25 21.69-2.56 6.94-2.31 15.68-6.66 15.85-12.8z" />
      ),
      basic: {
        dark: (
          <path d="M194.86 177.98c-2.61.55-4.42 2.9-6.06 4.93-1.32-13.62-14.29-32.58-29.41-23.48-17.13 15.81-8.48 45.96 17.62 39.1-4.98 6.32-4.91 16.44 4.49 8.6 7.3-7.84 14.42-17.95 13.36-29.15z" />
        ),
      },
    },
    right: {
      base: (
        <path d="m303.13 197.36-1.05-37.59c1.9.89 3.24 2.88 3.34 4.97 1.74-6.72 1.81-13.86.2-20.61 1.18.97 2.35 1.94 3.53 2.91 3.64-6.25 8.47-11.71 12.9-17.44s8.53-11.9 10.38-18.9 1.11-15-3.41-20.65c2.34.09 4.47 2.05 4.76 4.38 2.43-6.21 1.33-13.68-2.79-18.93 5.06 1.73 9.45 5.38 12.06 10.05.47-1.96.95-3.92 1.42-5.88 7.47 8.64 11.77 19.96 11.92 31.38 1.17-3.09 1.39-6.53.62-9.74 2.65 3.5 3.95 8 3.58 12.38-.17 2.01-.68 3.99-.65 6 .03 2.01.71 4.17 2.39 5.29 1.98 1.33 4.78.76 6.67-.69 1.89-1.45 3.09-3.61 4.24-5.71 1.94 3.04 2.63 6.84 1.89 10.37 2.05.92 4.7.29 6.1-1.47.28 4.84-1.3 9.75-4.34 13.52 1.08.46 2.41.29 3.34-.43-2.34 5.23-4.69 10.46-7.03 15.69-1.13 2.51-2.27 5.1-2.34 7.86-.07 2.75 1.2 5.73 3.72 6.83 2.52 1.1 6.08-.63 6.09-3.38 2.48 4.14 2.48 9.64-.01 13.77-.5.83-1.12 1.72-.93 2.67.2 1.06 1.38 1.7 2.46 1.67 1.08-.03 2.07-.55 3.02-1.06-3.02 4.91-7.87 8.66-13.38 10.34-.57 3.29 3.67 6.26 6.56 4.59-12.46 7.88-27.59 11.44-42.26 9.96.88 1.72 2.63 2.96 4.54 3.21-7.31-.12-14.75-.25-21.69-2.56-6.94-2.31-15.68-6.66-15.85-12.8z" />
      ),
      basic: {
        dark: (
          <path d="M305.14 177.98c2.61.55 4.42 2.9 6.06 4.93 1.32-13.62 14.29-32.58 29.41-23.48 17.13 15.81 8.48 45.96-17.62 39.1 4.98 6.32 4.91 16.44-4.49 8.6-7.3-7.84-14.42-17.95-13.36-29.15z" />
        ),
      },
    },
  },
};

const ear_shape_18sDmX = {
  name: "Ears 20 (Furry)",
  description: "Furry, wide ears that droop downwards from the top of the head",
  tags: ["rounded", "side", "drooping", "large", "furry"],
  preview: {
    viewbox: "90 140 220 220",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M203.97 184.64c2.68-2.08 2.42-6.4.55-9.24-1.87-2.83-4.87-4.67-7.46-6.86-2.59-2.19-4.97-5.17-4.8-8.56-2.96 1.27-4.45 5.18-3.08 8.1-5.97-.35-10.79-6.82-9.39-12.64-5.45 3.99-6.64 12.67-2.48 17.98-4.19.3-8.43-.24-12.41-1.59-1.07 2.15.77 5.13 3.17 5.12-9.8 3.52-16.11 12.95-20.85 22.21-4.74 9.27-8.87 19.29-16.75 26.09 2.32.12 4.66-.92 6.13-2.71-4.67 11.39-9.34 22.79-14.01 34.18 2.18-1.08 4.06-2.76 5.38-4.8-6.99 13-8.5 28.24-8.49 43 .01 14.76 1.4 29.59-.36 44.25 2.39-.94 4.51-2.58 6-4.68.61 5.63-1.61 11.49-5.8 15.29 8.13-1.07 15.83-5.13 21.3-11.24.42 2.88-.49 5.92-2.43 8.09 5.92-4.92 10.97-10.89 14.83-17.55-.47 3.92.86 8.02 3.53 10.93-1.87-4.13-.98-9.07 1.12-13.1 2.09-4.03 5.26-7.37 8.18-10.84 14.75-17.56 23.67-39.41 28.85-61.75s6.83-45.31 9.27-69.68z" />
      ),
      basic: {
        dark: (
          <path d="M203.4 174.95c-13.18-5.84-40.86 53.43-44.22 64.97-4.69 16.25-18.7 54.51-7.77 68.37 9.77 1.34 17.32-8.75 20.8-17.17 6.74 14.21 11.83 6.91 5.84-3.64-4.3-11.85-2-24.65-2.44-37.07.86-14.74 3.24-25.84 8.23-40.39 3.92-8.71 8.81-16.99 14.49-24.66 1.87-3.14 6.1-6.51 5.07-10.41z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M296.03 184.65c-2.68-2.08-2.42-6.4-.55-9.24 1.87-2.83 4.87-4.67 7.46-6.86s4.97-5.17 4.8-8.56c2.96 1.27 4.45 5.18 3.08 8.1 5.97-.35 10.79-6.82 9.39-12.64 5.45 3.99 6.64 12.67 2.48 17.98 4.19.3 8.43-.24 12.41-1.59 1.07 2.15-.77 5.13-3.17 5.12 9.8 3.51 16.11 12.95 20.85 22.21 4.74 9.27 8.87 19.29 16.75 26.09-2.32.12-4.66-.92-6.13-2.71 4.67 11.39 9.34 22.79 14.01 34.18-2.18-1.08-4.06-2.76-5.38-4.8 6.99 13 8.5 28.24 8.49 43-.01 14.76-1.4 29.59.36 44.25-2.39-.94-4.51-2.58-6-4.68-.61 5.63 1.61 11.49 5.8 15.29-8.13-1.07-15.83-5.13-21.3-11.24-.42 2.88.49 5.92 2.43 8.09-5.92-4.92-10.97-10.89-14.83-17.55.47 3.92-.86 8.02-3.53 10.93 1.87-4.13.98-9.07-1.12-13.1-2.09-4.03-5.26-7.37-8.18-10.84-14.75-17.56-23.67-39.41-28.85-61.75-5.19-22.32-6.83-45.31-9.27-69.68z" />
      ),
      basic: {
        dark: (
          <path d="M296.6 174.95c13.18-5.84 40.86 53.43 44.22 64.97 4.69 16.25 18.7 54.51 7.77 68.37-9.77 1.34-17.32-8.75-20.8-17.17-6.74 14.21-11.83 6.91-5.84-3.64 4.3-11.85 2-24.65 2.44-37.07-.86-14.74-3.24-25.84-8.23-40.39-3.92-8.71-8.81-16.99-14.49-24.66-1.87-3.14-6.1-6.51-5.07-10.41z" />
        ),
      },
    },
  },
};

const ear_shape_1k9vVR = {
  name: "Ears 21 (Furry)",
  description: "Furry, large finned ears with three sharp points",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "100 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M194.83 249.47c-1.64-9.11.09-18.46-.04-27.72-.13-9.26-2.77-19.41-10.5-24.51-3.57-2.36-8.52-4.15-9.09-8.4-2.06 2.06-2.04 5.82.04 7.85-2.63 1.28-6.04.75-8.15-1.28-.53 1.97.46 4.24 2.26 5.18-1.43.38-2.85.75-4.28 1.13-.21 1.01.82 2.07 1.84 1.89-4.46 5.67-9.17 11.54-15.69 14.62-6.53 3.08-15.44 2.41-19.64-3.46-.9 2.12.09 4.86 2.14 5.92-5.7-.17-11.23-3.41-14.16-8.3-2.52 6.41.95 14.54 7.33 17.15-2.16.48-4.41.58-6.6.29 7.55 2.48 12.74 10.72 11.72 18.6-1.02 7.88-8.15 14.52-16.08 14.99.33 1.28 1.51 2.29 2.83 2.42-1.93 1.87-4.93 2.55-7.48 1.68 4.36 4.94 11.55 7.21 17.96 5.66-.48 2.03-1.98 3.8-3.9 4.61 6.92-2.93 15.87.49 19.07 7.3 3.2 6.81.12 15.88-6.56 19.33 2 .38 4.17-.33 5.55-1.83.47 3.51-3.06 6.96-6.56 6.4 5.73 2.06 12.63.24 16.6-4.37 0 2.32-1.19 4.61-3.09 5.93 7.99-3.6 17.2-4.41 25.7-2.25 5.22 1.32 11.1 3.63 15.61.69 4.14-2.7 4.78-8.41 4.83-13.35.15-16.59.64-33.36-1.66-46.17z" />
      ),
      basic: {
        dark: (
          <path d="M186.51 303.37c-20.53-33.61-14.43-74.59 1.24-107.74-8.16-3.17-12.92 17.75-16.13 23.55-17.69 15.77-21.87 39.02-11.61 60.37 2.92 5.72 7.69 10.71 13.56 13.14 2.76 6.18 5.42 12.81 9.85 17.97 7.06 3.1 7.44-2.91 3.09-7.29z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M305.17 249.48c1.64-9.11-.09-18.46.04-27.72.13-9.26 2.77-19.41 10.5-24.51 3.57-2.36 8.52-4.15 9.09-8.4 2.06 2.06 2.04 5.82-.04 7.85 2.63 1.28 6.04.75 8.15-1.28.53 1.97-.46 4.24-2.26 5.18 1.43.38 2.85.75 4.28 1.13.21 1.01-.82 2.07-1.84 1.89 4.46 5.67 9.17 11.54 15.69 14.62 6.53 3.08 15.44 2.41 19.64-3.46.9 2.12-.09 4.86-2.14 5.92 5.7-.17 11.23-3.41 14.16-8.3 2.52 6.41-.95 14.54-7.33 17.15 2.16.48 4.41.58 6.6.29-7.55 2.48-12.74 10.72-11.72 18.6 1.02 7.88 8.15 14.52 16.08 14.99-.33 1.28-1.51 2.29-2.83 2.42 1.93 1.87 4.93 2.55 7.48 1.68-4.36 4.94-11.55 7.21-17.96 5.66.48 2.03 1.98 3.8 3.9 4.61-6.92-2.93-15.87.49-19.07 7.3s-.12 15.88 6.56 19.33c-2 .38-4.17-.33-5.55-1.83-.47 3.51 3.06 6.96 6.56 6.4-5.73 2.06-12.63.24-16.6-4.37 0 2.32 1.19 4.61 3.09 5.93-7.99-3.6-17.2-4.41-25.7-2.25-5.22 1.32-11.1 3.63-15.61.69-4.14-2.7-4.78-8.41-4.83-13.35-.16-16.6-.64-33.37 1.66-46.17z" />
      ),
      basic: {
        dark: (
          <path d="M313.49 303.37c20.53-33.61 14.43-74.59-1.24-107.74 8.16-3.17 12.92 17.75 16.13 23.55 17.69 15.77 21.87 39.02 11.61 60.37-2.92 5.72-7.69 10.71-13.56 13.14-2.76 6.18-5.42 12.81-9.85 17.97-7.06 3.1-7.44-2.91-3.09-7.29z" />
        ),
      },
    },
  },
};

const ear_shape_1aKjrI = {
  name: "Ears 22 (Furry)",
  description: "Furry, large finned ears with three rounded points",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "100 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.32 252.79c-1.65-14.41-1.5-29.02.43-43.39.66-4.93 1.2-10.81-2.61-14.01-1.93-1.62-4.55-2.09-6.9-3.02-2.35-.93-4.73-2.76-4.8-5.28-1.45 1.77-1.42 4.57.05 6.31-3.15-.1-5.73-3.56-4.92-6.6-3.27 3.56-3.49 9.52-.49 13.31-2.12.93-4.51 1.23-6.79.85.15 1.54 1.82 2.74 3.32 2.39-4.49 6.02-9.1 12.16-15.31 16.39-6.21 4.23-14.43 6.27-21.35 3.35-.4 1.47.24 3.17 1.52 4.01-3.62.34-7.3-2.2-8.27-5.71-2.11 7.37-.49 15.71 4.22 21.76-2.09 1.43-5.33.7-6.59-1.49.83 2.96 3.6 4.94 6.35 6.32 2.75 1.39 5.75 2.49 7.87 4.72 3.4 3.56 3.52 9.75.26 13.44-3.26 3.69-9.41 4.34-13.37 1.41-.05 1.87.8 3.74 2.23 4.93-3.14 2.29-8.1 1.47-10.34-1.7 3.48 6.64 9.87 11.68 17.14 13.52-1.59 1.33-3.61 2.14-5.68 2.28 3.49.37 7.03.75 10.29 2.04 3.26 1.3 6.26 3.65 7.53 6.92 1.27 3.27.38 7.48-2.57 9.37-2.25 1.44-5.41 1.27-7.48-.41 2.7 4.26 7.28 7.28 12.25 8.11-1.38 3.05-6.03 3.91-8.41 1.57 6.62 5.6 17 6.18 24.2 1.34-.62 1.71-1.24 3.43-1.86 5.14 5.62-5.7 13.75-8.83 21.74-8.37 4.47.26 9.49 1.44 13.03-1.3 3.27-2.52 3.7-7.24 3.62-11.37-.35-17.83-6.94-34.88-8.31-46.83z" />
      ),
      basic: {
        dark: (
          <path d="M185.2 295.84c-14.66-30.6-13.85-67.22 2.3-97.03 1.34-2.85-.26-6.85-3.24-4.4-9.39 8.18-12.43 21.73-15.63 33.32-24.16 17.2-21.35 50.71 4.48 64.71 2.39 5.38 9.82 27.51 17.67 18.15 2.01-3.93-3.99-11.3-5.58-14.75z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.68 252.79c1.65-14.41 1.5-29.02-.43-43.39-.66-4.93-1.2-10.81 2.61-14.01 1.93-1.62 4.55-2.09 6.9-3.02 2.35-.93 4.73-2.76 4.8-5.28 1.45 1.77 1.42 4.57-.05 6.31 3.15-.1 5.73-3.56 4.92-6.6 3.27 3.56 3.49 9.52.49 13.31 2.12.93 4.51 1.23 6.79.85-.15 1.54-1.82 2.74-3.32 2.39 4.49 6.02 9.1 12.16 15.31 16.39 6.21 4.23 14.43 6.27 21.35 3.35.4 1.47-.24 3.17-1.52 4.01 3.62.34 7.3-2.2 8.27-5.71 2.11 7.37.49 15.71-4.22 21.76 2.09 1.43 5.33.7 6.59-1.49-.83 2.96-3.6 4.94-6.35 6.32-2.75 1.39-5.75 2.49-7.87 4.72-3.4 3.56-3.52 9.75-.26 13.44 3.26 3.69 9.41 4.34 13.37 1.41.05 1.87-.8 3.74-2.23 4.93 3.14 2.29 8.1 1.47 10.34-1.7-3.48 6.64-9.87 11.68-17.14 13.52 1.59 1.33 3.61 2.14 5.68 2.28-3.49.37-7.03.75-10.29 2.04-3.26 1.3-6.26 3.65-7.53 6.92s-.38 7.48 2.57 9.37c2.25 1.44 5.41 1.27 7.48-.41-2.7 4.26-7.28 7.28-12.25 8.11 1.38 3.05 6.03 3.91 8.41 1.57-6.62 5.6-17 6.18-24.2 1.34.62 1.71 1.24 3.43 1.86 5.14-5.62-5.7-13.75-8.83-21.74-8.37-4.47.26-9.49 1.44-13.03-1.3-3.27-2.52-3.7-7.24-3.62-11.37.35-17.83 6.94-34.88 8.31-46.83z" />
      ),
      basic: {
        dark: (
          <path d="M314.8 295.84c14.66-30.6 13.85-67.22-2.3-97.03-1.34-2.85.26-6.85 3.24-4.4 9.39 8.18 12.43 21.73 15.63 33.32 24.16 17.2 21.35 50.71-4.48 64.71-2.39 5.38-9.82 27.51-17.67 18.15-2.01-3.93 3.99-11.3 5.58-14.75z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_12rsVH = {
  name: "Ears 23 (Furry)",
  description: "Furry, wide rounded ears that droop slightly downwards",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "100 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M179.57,247.99
						c-0.66-5.16,1.17-10.27,2.45-15.31c1.28-5.04,1.94-10.72-0.76-15.17c-2.14-3.52-5.97-5.57-9.42-7.83s-6.92-5.27-7.5-9.35
						c-2.64,0.84-3.68,4.71-1.81,6.75c-4.44-0.06-8.84-1.79-12.14-4.76c-1.44,1.71-0.98,4.66,0.92,5.85
						c-7.29,1.18-13.02,6.84-17.49,12.72c-4.47,5.88-8.38,12.42-14.33,16.79c1.37,0.8,3.37,0.19,4.05-1.24
						c-7.88,13.33-9.87,30.01-5.34,44.81c-0.47-2.32-0.36-4.75,0.3-7.02c3,7.72,10.09,13.7,18.2,15.36
						c-3.03-1.04-5.26-4.09-5.33-7.3c1.35,1.24,3.28,1.82,5.09,1.54c-0.85-0.5-1.43-1.43-1.5-2.41c2.68,0.11,5.52,0.27,7.76,1.76
						s3.47,4.82,1.82,6.94c2.51-0.51,4.87-1.77,6.69-3.57c0.86,1.3,0.75,3.16-0.26,4.35c4.25-2.2,8.99-3.21,13.54-4.68
						c4.55-1.47,9.13-3.57,12.08-7.34c3.62-4.62,4.19-10.92,3.83-16.78C180.06,256.25,179.89,250.43,179.57,247.99z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M173.88,210.32c-2.57,1.88-3.38,6.83-4.68,9.99c-0.26,0.62-0.9,1-1.57,0.94c-14.64-1.36-26.59,20.11-29.6,34.89c-0.33,1.61,1.74,2.56,2.78,1.3c15.87-19.18,10.48,10.05,22.34,7.75c0.83-0.16,1.64,0.39,1.81,1.22c1.41,6.8,2.5,22.65,10.05,18.4c0.55-0.31,0.86-0.92,0.79-1.55c-0.78-7.28-2.44-14.99-2.17-22.5c0-0.09,0-0.18-0.02-0.27c-2.71-15.89,7.16-34.91,2.73-49.34c176.02,210.1,174.76,209.68,173.88,210.32z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M320.49,247.99
						c0.66-5.16-1.17-10.27-2.45-15.31c-1.28-5.04-1.94-10.72,0.76-15.17c2.14-3.52,5.97-5.57,9.42-7.83
						c3.45-2.26,6.92-5.27,7.5-9.35c2.64,0.84,3.68,4.71,1.81,6.75c4.44-0.06,8.84-1.79,12.14-4.76c1.44,1.71,0.98,4.66-0.92,5.85
						c7.29,1.18,13.02,6.84,17.49,12.72c4.47,5.88,8.38,12.42,14.33,16.79c-1.37,0.8-3.37,0.19-4.05-1.24
						c7.88,13.33,9.87,30.01,5.34,44.81c0.47-2.32,0.36-4.75-0.3-7.02c-3,7.72-10.09,13.7-18.2,15.36c3.03-1.04,5.26-4.09,5.33-7.3
						c-1.35,1.24-3.28,1.82-5.09,1.54c0.85-0.5,1.43-1.43,1.5-2.41c-2.68,0.11-5.52,0.27-7.76,1.76c-2.23,1.49-3.47,4.82-1.82,6.94
						c-2.51-0.51-4.87-1.77-6.69-3.57c-0.86,1.3-0.75,3.16,0.26,4.35c-4.25-2.2-8.99-3.21-13.54-4.68
						c-4.55-1.47-9.13-3.57-12.08-7.34c-3.62-4.62-4.19-10.92-3.83-16.78S320.18,250.43,320.49,247.99z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M326.12,210.32c2.57,1.88,3.38,6.83,4.68,9.99c0.26,0.62,0.9,1,1.57,0.94c14.64-1.36,26.59,20.11,29.6,34.89c0.33,1.61-1.74,2.56-2.78,1.3c-15.87-19.18-10.48,10.05-22.34,7.75c-0.83-0.16-1.64,0.39-1.81,1.22c-1.41,6.8-2.5,22.65-10.05,18.4c-0.55-0.31-0.86-0.92-0.79-1.55c0.78-7.28,2.44-14.99,2.17-22.5c0-0.09,0-0.18,0.02-0.27c2.71-15.89-7.16-34.91-2.73-49.34C323.98,210.1,325.24,209.68,326.12,210.32z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1axjXx = {
  name: "Ears 24 (Furry)",
  description:
    "Furry, very wide ears with double points that droop slightly downwards",
  tags: ["pointed", "side", "drooping", "large", "furry"],
  preview: {
    viewbox: "60 190 140 140",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M187.68,240.6
						c1.41-7.41-4.37-14.15-10.15-19c-5.78-4.84-12.47-9.84-13.89-17.24c-2.98,2.13-2.83,7.35,0.26,9.32
						c-5.4-3.51-7.88-10.91-5.69-16.96c-4.66,2.02-6.16,8.99-2.75,12.75c-3.77-1.9-6.72-5.37-7.99-9.4
						c-1.44,1.71-1.72,4.31-0.68,6.29c-7.85-5.55-19.45-5-26.74,1.25c-3.23,2.77-5.57,6.41-8.34,9.63
						c-2.77,3.23-6.27,6.18-10.48,6.79c1.32,1.66,4.22,1.66,5.54,0c-2.25,5.72-5.49,11.04-9.52,15.68c1.44,0.96,3.72,0.06,4.11-1.63
						c-4.01,16.96-15.02,32.15-29.89,41.22c1.32,1.43,3.86,1.45,5.2,0.04c-2.05,3.78-6.41,6.18-10.7,5.89
						c6.44,4.38,15.27,4.97,22.24,1.49c-0.12,2.44-2.32,4.59-4.76,4.66c8.67-0.45,17.21-3.3,24.41-8.17
						c-0.6,7.41-7.26,13.83-14.68,14.17c2.29,1.53,5.2,2.11,7.91,1.57c-0.81,1.99-2.99,3.34-5.14,3.18
						c7.15,2.45,15.09,2.51,22.27,0.16c-0.26,2.05-1.88,3.85-3.89,4.32c13.28-1.07,26.24-5.96,36.92-13.93
						c-0.64,3.03-3.05,5.62-6.03,6.47c13.57-1.68,24.81-12.07,31.16-24.18C182.72,262.89,186,249.38,187.68,240.6z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M175.41,224.77c0.65-2.48,0.18-6.77-3.16-6.43c-4.3,2.67-4.76,9.48-6.49,13.99c-20.39-4.97-26,21.71-33.39,35.26c-6.38,16.04,27.66,5.55,33.06,0.62c1.4,5.96,3.54,11.74,6.41,17.16c4.27-1.77,2.02-3.77,1.49-7.91c172.42,259.94,173.12,242.16,175.41,224.77z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M312.33,240.6
						c-1.41-7.41,4.37-14.15,10.15-19s12.47-9.84,13.89-17.24c2.98,2.13,2.83,7.35-0.26,9.32c5.4-3.51,7.88-10.91,5.69-16.96
						c4.66,2.02,6.16,8.99,2.75,12.75c3.77-1.9,6.72-5.37,7.99-9.4c1.44,1.71,1.72,4.31,0.68,6.29c7.85-5.55,19.45-5,26.74,1.25
						c3.23,2.77,5.57,6.41,8.34,9.63s6.27,6.18,10.48,6.79c-1.32,1.66-4.22,1.66-5.54,0c2.25,5.72,5.49,11.04,9.52,15.68
						c-1.44,0.96-3.72,0.06-4.11-1.63c4.01,16.96,15.02,32.15,29.89,41.22c-1.32,1.43-3.86,1.45-5.2,0.04
						c2.05,3.78,6.41,6.18,10.7,5.89c-6.44,4.38-15.27,4.97-22.24,1.49c0.12,2.44,2.32,4.59,4.76,4.66
						c-8.67-0.45-17.21-3.3-24.41-8.17c0.6,7.41,7.26,13.83,14.68,14.17c-2.29,1.53-5.2,2.11-7.91,1.57
						c0.81,1.99,2.99,3.34,5.14,3.18c-7.15,2.45-15.09,2.51-22.27,0.16c0.26,2.05,1.88,3.85,3.89,4.32
						c-13.28-1.07-26.24-5.96-36.92-13.93c0.64,3.03,3.05,5.62,6.03,6.47c-13.57-1.68-24.81-12.07-31.16-24.18
						C317.28,262.89,314,249.38,312.33,240.6z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324.59,224.77c-0.65-2.48-0.18-6.77,3.16-6.43c4.3,2.67,4.76,9.48,6.49,13.99c20.39-4.97,26,21.71,33.39,35.26c6.38,16.04-27.66,5.55-33.06,0.62c-1.4,5.96-3.54,11.74-6.41,17.16c-4.27-1.77-2.02-3.77-1.49-7.91C327.58,259.94,326.88,242.16,324.59,224.77z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_1Zxpa2 = {
  name: "Ears 25 (Furry)",
  description: "Furry, swooping ears on the top of the head",
  tags: ["pointed", "top", "large", "furry"],
  preview: {
    viewbox: "100 70 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M205.21 191.11c2.82-3.39 1.95-8.62-.41-12.34-2.37-3.72-5.94-6.49-8.76-9.87s-4.95-7.94-3.6-12.13c-2.86.55-4.19 4.72-2.17 6.82-9.95-7.6-15.5-20.6-14.1-33.05-2.37 1.32-4.13 3.66-4.75 6.3-3.14-9.62-6.31-19.32-11.27-28.15-3.09-5.5-6.86-10.66-9.23-16.51-2.37-5.85-3.17-12.75-.24-18.33-4.91 1.96-9.05 5.77-11.43 10.49-2.34-3.85-2.31-9.02.07-12.85-8.41 8.77-12.75 21.28-11.58 33.37-1.53-2.52-3.05-5.03-4.58-7.55-.17 10.64-.33 21.27-.5 31.91-.17 10.64-.29 21.53 3.2 31.58-3.36-2.15-5.7-5.84-6.22-9.8-.46 14.1 4.93 28.3 14.62 38.55-3.26 0-6.51-1.3-8.87-3.56 2.68 5.46 7.8 9.67 13.67 11.25l-3.84 2.07c12.42 4.89 25.53 9.05 38.86 8.29 13.33-.76 24.71-8.78 31.13-16.49z" />
      ),
      basic: {
        dark: (
          <path d="M200.57 173.09c-2.39 2.06-4.68 4.23-6.88 6.49-13.18-6.2-25.32-27.44-35.23-27.04-9.41 17.21-.9 45.83 21.63 44.09-1.01 2.93-7.95 9.15-3.77 11.1 7.07 1.1 8.02-6.54 11.27-11.41 2.34-6.88 19.67-16.5 12.98-23.23z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M294.79 191.11c-2.82-3.39-1.95-8.62.41-12.34 2.37-3.72 5.94-6.49 8.76-9.87 2.82-3.38 4.95-7.94 3.6-12.13 2.86.55 4.19 4.72 2.17 6.82 9.95-7.6 15.5-20.6 14.1-33.05 2.37 1.32 4.13 3.66 4.75 6.3 3.14-9.62 6.31-19.32 11.27-28.15 3.09-5.5 6.86-10.66 9.23-16.51 2.37-5.85 3.17-12.75.24-18.33 4.91 1.96 9.05 5.77 11.43 10.49 2.34-3.85 2.31-9.02-.07-12.85 8.41 8.77 12.75 21.28 11.58 33.37 1.53-2.52 3.05-5.03 4.58-7.55.17 10.64.33 21.27.5 31.91.17 10.64.29 21.53-3.2 31.58 3.36-2.15 5.7-5.84 6.22-9.8.46 14.1-4.93 28.3-14.62 38.55 3.26 0 6.51-1.3 8.87-3.56-2.68 5.46-7.8 9.67-13.67 11.25l3.84 2.07c-12.42 4.89-25.53 9.05-38.86 8.29-13.33-.76-24.71-8.78-31.13-16.49z" />
      ),
      basic: {
        dark: (
          <path d="M299.43 173.09c2.39 2.06 4.68 4.23 6.88 6.49 13.18-6.2 25.32-27.44 35.23-27.04 9.41 17.21.9 45.83-21.63 44.09 1.01 2.93 7.95 9.15 3.77 11.1-7.07 1.1-8.02-6.54-11.27-11.41-2.34-6.88-19.67-16.5-12.98-23.23z" />
        ),
      },
    },
  },
};

const ear_shape_1jWOL0 = {
  name: "Ears 26 (Furry)",
  description: "Furry, wide and long drooping ears",
  tags: ["side", "large", "drooping", "furry"],
  preview: {
    viewbox: "90 170 190 190",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M187.16 237.93c3.94-7.22.45-16.17-3.77-23.23-3.79-6.34-8.33-12.94-8.7-20.15-.05-.94-1.29-1.24-1.77-.42-.49.84-.8 1.79-.92 2.76-.09.76-.96 1.16-1.54.65-3.65-3.22-5.91-7.99-6.1-12.86-.04-.92-1.24-1.22-1.75-.44-.48.73-.81 1.56-.97 2.42-.14.75-1.07 1.03-1.6.48l-1.73-1.8c-.08.31-.15.62-.23.93-7.48-3.59-14.96-7.18-22.45-10.78-.72-.34-1.51.3-1.33 1.08.58 2.56 2.2 4.88 4.42 6.28.57.36.55 1.19-.01 1.57-3.97 2.73-8.74 4.3-13.56 4.45-.79.02-1.18.94-.68 1.55.5.62 1.12 1.15 1.81 1.55.68.4.6 1.38-.12 1.69-1.21.51-11.76 24.11-10.58 51.13.04.94 1.21 1.31 1.73.53.55-.83 1.83-.35 1.72.64-2.14 18.8 9.02 38.54 4.49 56.88-.21.85.75 1.51 1.45 1 1.33-.96 2.48-2.15 3.4-3.5.55-.81 1.79-.4 1.74.58-.12 2.24-.66 4.46-1.59 6.5-.38.83.52 1.66 1.32 1.21l1.85-1.04c.6-.34 1.34.06 1.4.75 1.54 18.32 12.57 35.56 28.38 44.88.92.54 1.89-.59 1.22-1.41-1.25-1.53-2.35-3.19-3.27-4.94-.44-.84.52-1.72 1.34-1.23 2.15 1.3 4.6 2.11 7.1 2.35.88.08 1.37-1.02.72-1.62-1.36-1.26-2.46-2.83-3.18-4.54-.33-.79.45-1.55 1.25-1.24.19.07.39.13.59.17.59.11 1.13-.38 1.1-.99l-2.78-53.18c.1 3.22 1.16 6.38 2.93 9.07.62.94 2.1.21 1.68-.85-4.27-10.86-3.33-23.76 2.8-33.73 2.91-4.74 6.84-8.77 9.88-13.43 3.04-4.64 5.21-10.3 4.31-9.72z" />
      ),
      basic: {
        dark: (
          <path d="M175.35 208.8c-2.16 3.89-3.96 7.97-5.45 12.15-26.54-43.66-33.82 81.39-17.07 82.77 6.29-15.47 4.93-33.86 12.43-49.39 3.72 30.36 7.65 9.33 6.64-6.29-1.98-12.93 9.68-28.04 3.45-39.24z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M312.84 237.92c-3.94-7.22-.45-16.17 3.77-23.23 3.79-6.34 8.33-12.94 8.7-20.15.05-.94 1.29-1.24 1.77-.42.49.84.8 1.79.92 2.76.09.76.96 1.16 1.54.65 3.65-3.22 5.91-7.99 6.1-12.86.04-.92 1.24-1.22 1.75-.44.48.73.81 1.56.97 2.42.14.75 1.07 1.03 1.6.48l1.73-1.8c.08.31.15.62.23.93 7.48-3.59 14.96-7.18 22.45-10.78.72-.34 1.51.3 1.33 1.08-.58 2.56-2.2 4.88-4.42 6.28-.57.36-.55 1.19.01 1.57 3.97 2.73 8.74 4.3 13.56 4.45.79.02 1.18.94.68 1.55-.5.62-1.12 1.15-1.81 1.55-.68.4-.6 1.38.12 1.69 1.21.51 11.76 24.11 10.58 51.13-.04.94-1.21 1.31-1.73.53-.55-.83-1.83-.35-1.72.64 2.14 18.8-9.02 38.54-4.49 56.88.21.85-.75 1.51-1.45 1-1.33-.96-2.48-2.15-3.4-3.5-.55-.81-1.79-.4-1.74.58.12 2.24.66 4.46 1.59 6.5.38.83-.52 1.66-1.32 1.21l-1.85-1.04c-.6-.34-1.34.06-1.4.75-1.54 18.32-12.57 35.56-28.38 44.88-.92.54-1.89-.59-1.22-1.41 1.25-1.53 2.35-3.19 3.27-4.94.44-.84-.52-1.72-1.34-1.23-2.15 1.3-4.6 2.11-7.1 2.35-.88.08-1.37-1.02-.72-1.62 1.36-1.26 2.46-2.83 3.18-4.54.33-.79-.45-1.55-1.25-1.24-.19.07-.39.13-.59.17-.59.11-1.13-.38-1.1-.99l2.78-53.18c-.1 3.22-1.16 6.38-2.93 9.07-.62.94-2.1.21-1.68-.85 4.27-10.86 3.33-23.76-2.8-33.73-2.91-4.74-6.84-8.77-9.88-13.43s-5.21-10.29-4.31-9.72z" />
      ),
      basic: {
        dark: (
          <path d="M324.65 208.8c2.16 3.89 3.96 7.97 5.45 12.15 26.54-43.66 33.82 81.39 17.07 82.77-6.29-15.47-4.93-33.86-12.43-49.39-3.72 30.36-7.65 9.33-6.64-6.29 1.98-12.93-9.68-28.04-3.45-39.24z" />
        ),
      },
    },
  },
};

const ear_shape_1TUUUj = {
  name: "Ears 27 (Furry)",
  description:
    "Furry, wide ears that have a bump on the top and a slight point to the side",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "90 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M180.22 266.4c1.06-5.73-.99-11.6-3.84-16.67-2.85-5.07-6.53-9.66-9.19-14.84-2.66-5.18-4.27-11.26-2.59-16.83-2.09 1.01-3.31 3.56-2.77 5.83-7.3-7.68-11.42-18.28-11.24-28.86-4.17 1.16-7.77 4.24-9.57 8.18-2.09-1.3-3.14-4.08-2.44-6.44-2.97 1.83-5.4 4.55-6.87 7.71-.3-.97-.61-1.94-.91-2.92-4.78 4.33-7.15 10.67-8.91 16.87-1.76 6.2-3.13 12.65-6.52 18.13-3.38 5.49-9.37 9.94-15.79 9.35.97 1.95 3.59 2.88 5.57 1.97-2.01 6.18-8.54 10.57-15.02 10.1 4.58 5.62 10.71 9.96 17.53 12.43-2.54 1.75-6.11 1.85-8.75.25 12.06 9.44 26.12 16.32 40.98 20.05-3.02 1.97-7.19 2-10.24.08 11.75 6.21 26.85 5.49 37.96-1.81-.13 2.32-.88 4.61-2.17 6.55 5.56-3.09 9.26-8.83 11.2-14.89 1.94-6.04 3.19-12.12 3.58-14.24z" />
      ),
      basic: {
        dark: (
          <path d="M174 274.4c-.75-6.18-1.12-12.41-1.09-18.63-.44-5.01 1.34-11.52-1.85-15.68-5.93-1.39-4.4 7.92-5.2 11.52-3.8-6.2-9.31-23.81-16.9-24.09 7.21 17.5 10.8 18.62-4.66 32.62-5.58 5.99-3.85 18.2 5.38 18.73 5.94.04 10.65-5.63 16.82-4.3.86 4.26 2.25 8.39 4.38 12.16 7.73 1.85 3.62-8.19 3.12-12.33z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M319.78 266.41c-1.06-5.73.99-11.6 3.84-16.67s6.53-9.66 9.19-14.84c2.66-5.18 4.27-11.26 2.59-16.83 2.09 1.01 3.31 3.56 2.77 5.83 7.3-7.68 11.42-18.28 11.24-28.86 4.17 1.16 7.77 4.24 9.57 8.18 2.09-1.3 3.14-4.08 2.44-6.44 2.97 1.83 5.4 4.55 6.87 7.71.3-.97.61-1.94.91-2.92 4.78 4.33 7.15 10.67 8.91 16.87 1.76 6.2 3.13 12.65 6.52 18.13 3.38 5.49 9.37 9.94 15.79 9.35-.97 1.95-3.59 2.88-5.57 1.97 2.01 6.18 8.54 10.57 15.02 10.1-4.58 5.62-10.71 9.96-17.53 12.43 2.54 1.75 6.11 1.85 8.75.25-12.06 9.44-26.12 16.32-40.98 20.05 3.02 1.97 7.19 2 10.24.08-11.75 6.21-26.85 5.49-37.96-1.81.13 2.32.88 4.61 2.17 6.55-5.56-3.09-9.26-8.83-11.2-14.89-1.95-6.05-3.19-12.12-3.58-14.24z" />
      ),
      basic: {
        dark: (
          <path d="M326 274.4c.75-6.18 1.12-12.41 1.09-18.63.44-5.01-1.34-11.52 1.85-15.68 5.93-1.39 4.4 7.92 5.2 11.52 3.8-6.2 9.31-23.81 16.9-24.09-7.21 17.5-10.8 18.62 4.66 32.62 5.58 5.99 3.85 18.2-5.38 18.73-5.94.04-10.65-5.63-16.82-4.3-.86 4.26-2.25 8.39-4.38 12.16-7.73 1.85-3.62-8.19-3.12-12.33z" />
        ),
      },
    },
  },
};

const ear_shape_1Wk3es = {
  name: "Ears 28 (Furry)",
  description:
    "Furry, long rounded ears that stand diagonally from the top of the head",
  tags: ["rounded", "top", "large", "furry"],
  preview: {
    viewbox: "80 80 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M201.3 212.22c-2.86 3.9-8.27 5.22-13.05 4.5-4.78-.73-9.12-3.14-13.33-5.52-5.75-3.24-11.51-6.48-17.26-9.72 1.84.18 3.68.36 5.52.55-4.34-2.13-9.03-3.51-13.83-4.08 2.51-1.37 5.38-2.11 8.24-2.11-19.23-8.61-27.35-32.72-45.97-42.56 2.33.05 4.65.09 6.98.14-9.48-4.89-15.11-14.95-18.86-24.93-3.75-9.99-6.24-20.59-11.95-29.6 2.76 1.41 5.07 3.67 6.54 6.4.96-7.21-.77-14.76-4.79-20.83 3.25 4.92 7.81 8.96 13.08 11.6-2.63-2.25-4.35-5.54-4.68-8.99 3.88 3.87 8.79 6.7 14.08 8.12-1.57-1.16-2.6-3.03-2.73-4.98 11.2 4.73 22.19 10.18 31.85 17.57 9.66 7.38 17.98 16.82 22.71 28.02-.66-3.82-1.31-7.64-1.97-11.46 8.47 13.28 16.93 26.57 25.4 39.85-1.12-3.14-.64-6.81 1.26-9.55-.42 6.29 1.22 12.7 4.61 18.02-.29-.93-.28-1.95.02-2.87 8.43 12.84 15.55 32.32 8.13 42.43z" />
      ),
      basic: {
        dark: (
          <path d="M197.24 181.1c-2.84-3.16-6.32 1.56-8.36 3.51-53.52-74.39-71.29-30.97-10.79 14.6-1.75 3.59-8.98 14.67-1.27 15.25 3.16-.4 4.67-6.2 5.95-8.53 2.51-6.87 15.15-19.25 14.47-24.83z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M298.7 212.22c2.86 3.9 8.27 5.22 13.05 4.5s9.12-3.14 13.33-5.52c5.75-3.24 11.51-6.48 17.26-9.72-1.84.18-3.68.36-5.52.55 4.34-2.13 9.03-3.51 13.83-4.08-2.51-1.37-5.38-2.11-8.24-2.11 19.23-8.61 27.35-32.72 45.97-42.56-2.33.05-4.65.09-6.98.14 9.48-4.89 15.11-14.95 18.86-24.93 3.75-9.99 6.24-20.59 11.95-29.6-2.76 1.41-5.07 3.67-6.54 6.4-.96-7.21.77-14.76 4.79-20.83-3.25 4.92-7.81 8.96-13.08 11.6 2.63-2.25 4.35-5.54 4.68-8.99-3.88 3.87-8.79 6.7-14.08 8.12 1.57-1.16 2.6-3.03 2.73-4.98-11.2 4.73-22.19 10.18-31.85 17.57-9.66 7.38-17.98 16.82-22.71 28.02.66-3.82 1.31-7.64 1.97-11.46-8.47 13.28-16.93 26.57-25.4 39.85 1.12-3.14.64-6.81-1.26-9.55.42 6.29-1.22 12.7-4.61 18.02.29-.93.28-1.95-.02-2.87-8.43 12.84-15.55 32.32-8.13 42.43z" />
      ),
      basic: {
        dark: (
          <path d="M302.76 181.1c2.84-3.16 6.32 1.56 8.36 3.51 53.52-74.39 71.29-30.97 10.79 14.6 1.75 3.59 8.98 14.67 1.27 15.25-3.16-.4-4.67-6.2-5.95-8.53-2.51-6.87-15.15-19.25-14.47-24.83z" />
        ),
      },
    },
  },
};

const ear_shape_1XkDvO = {
  name: "Ears 29 (Furry)",
  description:
    "Furry, long rounded ears that stand diagonally from the side of the head",
  tags: ["rounded", "side", "large", "furry"],
  preview: {
    viewbox: "20 120 180 180",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M185.78 245.42c-2.1 6.2-9.26 9.43-15.8 9.31s-12.72-2.81-18.87-5.02c-6.16-2.22-12.86-4.02-19.18-2.33 2.46-.79 4.93-1.58 7.39-2.37-13.69-6.52-28.54-10.6-43.64-11.98 2.01-1.33 4.38-2.11 6.79-2.23-3.36-1.5-7.17-1.95-10.79-1.26 1.17-1.04 2.34-2.07 3.51-3.11-12.2-1.25-22.8-8.81-31.91-17.01-9.12-8.2-17.57-17.42-28.31-23.33 2.77.9 5.54 1.8 7.97 3.07-3.19-10.24-9.98-19.32-18.9-25.28 5.45.83 10.89 1.66 16.34 2.49-3.64-1.22-6.67-4.14-8.03-7.72 6.61 4.47 15.36 5.58 22.88 2.91-3.34-.59-6.49-2.24-8.87-4.65 8.86.17 17.69 1.77 26.05 4.71-2.67-1.04-4.63-3.69-4.85-6.54 9.52 7.45 21.39 11.03 32.69 15.31s22.82 9.85 29.73 19.76c-.96-3.29-1.93-6.57-2.89-9.86 10.78 12.13 23.23 22.77 36.9 31.51-2.38-2.18-3.9-5.29-4.15-8.51 2.18 5.71 6.46 10.34 10.78 14.66 4.32 4.32 8.86 8.59 11.79 13.96s3.84 12.13 3.37 13.51z" />
      ),
      basic: {
        dark: (
          <path d="M174.82 222.5c-4.35-5.59-5.59 3.22-6.31 6.36-15.25-10.36-31.02-19.97-46.45-30.06-5.28-3.39-11.46-6.93-17.47-5.12-28.29 16.76 49.06 45.51 61.67 48.46.06 3.37-2.95 14.76 2.89 13.9 1.72-.76 1.76-6.1 2.03-7.62.51-2.85 1.01-5.71 1.52-8.56.79-5.49 3.07-11.83 2.12-17.36z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M314.22 245.42c2.1 6.2 9.26 9.43 15.8 9.31s12.72-2.81 18.87-5.02c6.16-2.22 12.86-4.02 19.18-2.33-2.46-.79-4.93-1.58-7.39-2.37 13.69-6.52 28.54-10.6 43.64-11.98-2.01-1.33-4.38-2.11-6.79-2.23 3.36-1.5 7.17-1.95 10.79-1.26-1.17-1.04-2.34-2.07-3.51-3.11 12.2-1.25 22.8-8.81 31.91-17.01 9.12-8.2 17.57-17.42 28.31-23.33-2.77.9-5.54 1.8-7.97 3.07 3.19-10.24 9.98-19.32 18.9-25.28-5.45.83-10.89 1.66-16.34 2.49 3.64-1.22 6.67-4.14 8.03-7.72-6.61 4.47-15.36 5.58-22.88 2.91 3.34-.59 6.49-2.24 8.87-4.65-8.86.17-17.69 1.77-26.05 4.71 2.67-1.04 4.63-3.69 4.85-6.54-9.52 7.45-21.39 11.03-32.69 15.31s-22.82 9.85-29.73 19.76c.96-3.29 1.93-6.57 2.89-9.86-10.78 12.13-23.23 22.77-36.9 31.51 2.38-2.18 3.9-5.29 4.15-8.51-2.18 5.71-6.46 10.34-10.78 14.66-4.32 4.32-8.86 8.59-11.79 13.96-2.93 5.4-3.84 12.13-3.37 13.51z" />
      ),
      basic: {
        dark: (
          <path d="M325.18 222.5c4.35-5.59 5.59 3.22 6.31 6.36 15.25-10.36 31.02-19.97 46.45-30.06 5.28-3.39 11.46-6.93 17.47-5.12 28.29 16.76-49.06 45.51-61.67 48.46-.06 3.37 2.95 14.76-2.89 13.9-1.72-.76-1.76-6.1-2.03-7.62-.51-2.85-1.01-5.71-1.52-8.56-.79-5.49-3.07-11.83-2.12-17.36z" />
        ),
      },
    },
  },
};

const ear_shape_1xyzCw = {
  name: "Ears 30 (Furry)",
  description: "Furry, skinny tapered ears that stand from the top of the head",
  tags: ["rounded", "top", "furry"],
  preview: {
    viewbox: "90 75 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M196.57 204.63c-.82 4.81-6.96 7.04-11.64 5.67-4.68-1.37-8.22-5.1-11.74-8.48s-7.64-6.72-12.51-6.94c2.61.24 5.22.47 7.83.71-4.43-3.55-9.68-6.08-15.21-7.32l7.32-1.59c-6.52-2.52-11.9-7.84-14.5-14.32 1.52 2.01 3.9 3.35 6.41 3.61-6.07-3.83-8.35-11.56-9.07-18.7-.72-7.14-.42-14.59-3.26-21.19l4.23 5.97c-1.78-21.01 4.05-42.58 16.16-59.84-1.34 1.3-1.84 3.39-1.24 5.16 2.35-5.41 6.9-9.84 12.37-12.04-4.32 3.96-6.42 10.21-5.38 15.98.86-2.6 3.48-4.51 6.22-4.53-3.36 5.58-5.21 12.06-5.31 18.57.25-1.86 1.49-3.56 3.19-4.36-3.58 14.55 1.7 30.91 13.09 40.63-2.03-2.58-2.93-6.02-2.41-9.26 2.05 6.94 6.64 12.78 10.34 19 3.69 6.22 6.61 13.57 4.8 20.58 2.05-2.14 3.47-4.89 4.03-7.8.96 14.3-1.67 28.5-3.72 40.49z" />
      ),
      basic: {
        dark: (
          <path d="M196.51 176.81c-2.18.29-3.64 2.44-5.22 3.81-17.87-54.07-47.08-21.46-9.26 11.34-2.24 3.16-5.2 7.5-3.04 11.12 4.7 4.57 8.09-4.31 10-6.95 2.06-4.89 15.96-15.7 7.52-19.32z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M303.43 204.63c.82 4.81 6.96 7.04 11.64 5.67 4.68-1.37 8.22-5.1 11.74-8.48s7.64-6.72 12.51-6.94c-2.61.24-5.22.47-7.83.71 4.43-3.55 9.68-6.08 15.21-7.32l-7.32-1.59c6.52-2.52 11.9-7.84 14.5-14.32-1.52 2.01-3.9 3.35-6.41 3.61 6.07-3.83 8.35-11.56 9.07-18.7s.42-14.59 3.26-21.19l-4.23 5.97c1.78-21.01-4.05-42.58-16.16-59.84 1.34 1.3 1.84 3.39 1.24 5.16-2.35-5.41-6.9-9.84-12.37-12.04 4.32 3.96 6.42 10.21 5.38 15.98-.86-2.6-3.48-4.51-6.22-4.53 3.36 5.58 5.21 12.06 5.31 18.57-.25-1.86-1.49-3.56-3.19-4.36 3.58 14.55-1.7 30.91-13.09 40.63 2.03-2.58 2.93-6.02 2.41-9.26-2.05 6.94-6.64 12.78-10.34 19-3.69 6.22-6.61 13.57-4.8 20.58-2.05-2.14-3.47-4.89-4.03-7.8-.96 14.3 1.67 28.5 3.72 40.49z" />
      ),
      basic: {
        dark: (
          <path d="M303.49 176.81c2.18.29 3.64 2.44 5.22 3.81 17.87-54.07 47.08-21.46 9.26 11.34 2.24 3.16 5.2 7.5 3.04 11.12-4.7 4.57-8.09-4.31-10-6.95-2.06-4.89-15.96-15.7-7.52-19.32z" />
        ),
      },
    },
  },
};

const ear_shape_1epGZS = {
  name: "Ears 31 (Furry)",
  description:
    "Furry, large and wide ears that look a little like a butterfly wing",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "80 170 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M184.21 261.89c2.26 7.23-2.68 14.56-7.34 20.54-4.63 5.94-9.26 11.87-13.89 17.81-.56-1.99-.44-4.17.35-6.08-11.96 5.76-23.33 12.77-33.84 20.87.31-2.05.89-4.05 1.73-5.95-3.46 1.46-5.99 4.91-6.33 8.65-2.14-5.9-1.9-12.62.66-18.35-2.2.18-4 2.42-3.71 4.61-.99-4.38.29-8.96 2.15-13.04 1.86-4.08 4.3-7.91 5.82-12.13 1.52-4.22 2.04-9.08.04-13.09-2.01-4.01-7.13-6.63-11.2-4.75 1.7-1.33 3.95-1.95 6.09-1.67-4.45-3.68-9.99-6.03-15.73-6.67 2.73-.15 5.45-.3 8.18-.45-6.76-2.56-10.29-9.96-12.21-16.93-1.93-6.97-3.08-14.5-7.65-20.1 2.14 1.3 4.28 2.61 6.42 3.91-3.5-6.59-1.88-14.57-.88-21.96 1-7.39.8-16.05-4.93-20.83 5.15 2.91 9.77 6.77 13.56 11.32-.27-4.18-2.13-8.24-5.13-11.17 8.04 8.38 17.58 15.31 28.03 20.37-2.48-2.86-4.45-6.18-5.77-9.73 16.32 11.18 29.41 27 37.32 45.13-1.81-3.56-2.15-7.83-.93-11.63 1.32 8.15 6.5 15.05 11.14 21.87s9.05 14.51 8.05 19.45z" />
      ),
      basic: {
        dark: (
          <path d="M172.98 269.81c-2.45-11.56-1.64-23.24-1.91-34.81-.4-.98-1.46-1.79-2.49-1.54-2.74 1.3-1.81 5.7-2.38 8.26-5.04-5.74-10.31-11.48-16.14-16.46-1.63-1.42-13.98-9.16-13.98-10.56 1.35 11.55 20.86 16.94 18.45 34.51-.97 5.62-5.25 10.9-3.98 16.8.64 2.85 3.05 5.47 5.97 5.47 4.42-.77 7.37-2.17 11 1.61.4 2.38.86 4.75 1.35 7.11.59 2.85 4.72 8.68 6.8 3.54 1.26-3.1-2.11-10.61-2.69-13.93z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.78 261.9c-2.26 7.23 2.68 14.56 7.34 20.54 4.63 5.94 9.26 11.87 13.89 17.81.56-1.99.44-4.17-.35-6.08 11.96 5.76 23.33 12.77 33.84 20.87-.31-2.05-.89-4.05-1.73-5.95 3.46 1.46 5.99 4.91 6.33 8.65 2.14-5.9 1.9-12.62-.66-18.35 2.2.18 4 2.42 3.71 4.61.99-4.38-.29-8.96-2.15-13.04-1.86-4.08-4.3-7.91-5.82-12.13-1.52-4.22-2.04-9.08-.04-13.09 2.01-4.01 7.13-6.63 11.2-4.75-1.7-1.33-3.95-1.95-6.09-1.67 4.45-3.68 9.99-6.03 15.73-6.67-2.73-.15-5.45-.3-8.18-.45 6.76-2.56 10.29-9.96 12.21-16.93 1.93-6.97 3.08-14.5 7.65-20.1-2.14 1.3-4.28 2.61-6.42 3.91 3.5-6.59 1.88-14.57.88-21.96s-.8-16.05 4.93-20.83c-5.15 2.91-9.77 6.77-13.56 11.32.27-4.18 2.13-8.24 5.13-11.17-8.04 8.38-17.58 15.31-28.03 20.37 2.48-2.86 4.45-6.18 5.77-9.73-16.32 11.18-29.41 27-37.32 45.13 1.81-3.56 2.15-7.83.93-11.63-1.32 8.15-6.5 15.05-11.14 21.87-4.63 6.81-9.04 14.5-8.05 19.45z" />
      ),
      basic: {
        dark: (
          <path d="M327.02 269.81c2.45-11.56 1.64-23.24 1.91-34.81.4-.98 1.46-1.79 2.49-1.54 2.74 1.3 1.81 5.7 2.38 8.26 5.04-5.74 10.31-11.48 16.14-16.46 1.63-1.42 13.98-9.16 13.98-10.56-1.35 11.55-20.86 16.94-18.45 34.51.97 5.62 5.25 10.9 3.98 16.8-.64 2.85-3.05 5.47-5.97 5.47-4.42-.77-7.37-2.17-11 1.61-.4 2.38-.86 4.75-1.35 7.11-.59 2.85-4.72 8.68-6.8 3.54-1.26-3.1 2.11-10.61 2.69-13.93z" />
        ),
      },
    },
  },
};

const ear_shape_14qBKl = {
  name: "Ears 32 (Furry)",
  description: "Furry, wide ears that sweep down and outwards",
  tags: ["pointed", "side", "drooping", "furry"],
  preview: {
    viewbox: "70 180 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M184.59 235.14c1.28-7.61-2.66-15.83-9.39-19.6-4.07-2.28-8.83-2.96-13.16-4.68-4.34-1.72-8.6-5.08-9.08-9.72-2.19 2.29-2.19 6.33.01 8.61-4.15-.54-7.99-3.08-10.09-6.69-2.12 2.43-2.03 6.48.19 8.82-2.97 1.23-6.32 1.5-9.45.76.22 2.07 2.37 3.71 4.42 3.39-5.82 2.24-9.39 7.95-13.05 13-3.67 5.05-8.67 10.15-14.9 10.05.66 1.89 3.63 2.37 4.86.8-7.88 9.87-22.2 14.02-34.13 9.89.24 5.81 4.61 11.21 10.24 12.66-2.65.87-5.59.84-8.22-.09 3.76 5.01 9.49 8.49 15.67 9.53-1.27 1.46-3.7 1.73-5.25.57 12.96 8.02 26.58 16.26 41.78 17.43-2.18 1.32-4.58 2.3-7.07 2.87 6.93.95 14.19-.75 19.98-4.68 5.79-3.93 10.04-10.06 11.71-16.85.41 2.49 2.14 4.73 4.45 5.76-1.74-2.68-1.82-6.17-.94-9.25.87-3.07 2.59-5.83 4.29-8.53 5.82-9.34 11.68-18.67 17.13-24.05z" />
      ),
      basic: {
        dark: (
          <path d="M175.57 218.76c-3.36-6.54-5.5 4.13-5.97 7.02-26.63 2.56-17.27 45.34-40.73 53.2 20.29 9.61 24.84-22.86 37.32-31.94.24 2.97-.13 14.72 4.95 10.95.37-12.71.68-26.7 4.43-39.23z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.41 235.14c-1.28-7.61 2.66-15.83 9.39-19.6 4.07-2.28 8.83-2.96 13.16-4.68s8.6-5.08 9.08-9.72c2.19 2.29 2.19 6.33-.01 8.61 4.15-.54 7.99-3.08 10.09-6.69 2.12 2.43 2.03 6.48-.19 8.82 2.97 1.23 6.32 1.5 9.45.76-.22 2.07-2.37 3.71-4.42 3.39 5.82 2.24 9.39 7.95 13.05 13 3.67 5.05 8.67 10.15 14.9 10.05-.66 1.89-3.63 2.37-4.86.8 7.88 9.87 22.2 14.02 34.13 9.89-.24 5.81-4.61 11.21-10.24 12.66 2.65.87 5.59.84 8.22-.09-3.76 5.01-9.49 8.49-15.67 9.53 1.27 1.46 3.7 1.73 5.25.57-12.96 8.02-26.58 16.26-41.78 17.43 2.18 1.32 4.58 2.3 7.07 2.87-6.93.95-14.19-.75-19.98-4.68-5.79-3.93-10.04-10.06-11.71-16.85-.41 2.49-2.14 4.73-4.45 5.76 1.74-2.68 1.82-6.17.94-9.25s-2.59-5.83-4.29-8.53c-5.82-9.34-11.68-18.67-17.13-24.05z" />
      ),
      basic: {
        dark: (
          <path d="M324.43 218.76c3.36-6.54 5.5 4.13 5.97 7.02 26.63 2.56 17.27 45.34 40.73 53.2-20.29 9.61-24.84-22.86-37.32-31.94-.24 2.97.13 14.72-4.95 10.95-.37-12.71-.68-26.7-4.43-39.23z" />
        ),
      },
    },
  },
};

const ear_shape_1zwyyL = {
  name: "Ears 33 (Furry)",
  description: "Furry, small but wide ears that bump outwards at the top",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "100 190 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M179.52 259.35c.13-6.76-4.33-12.62-8.4-18.01-4.08-5.39-8.18-11.67-7.2-18.36-1.58.65-2.54 2.53-2.15 4.19-1.76-6.81-7.92-11.38-13.44-15.74-5.52-4.36-11.21-9.92-11.31-16.95-3.49 2.54-5.41 7.08-4.78 11.35-1.63-1.04-2.71-2.89-2.81-4.82-1.91 2.61-2.95 5.84-2.94 9.07-.74-.97-1.49-1.94-2.23-2.91-1.74 8.25-3.47 16.51-5.21 24.76-.8-2.27-1.61-4.54-2.41-6.81-2.56 9.57 1 20.49 8.7 26.72 3.03 2.45 6.57 4.18 9.83 6.3 3.27 2.12 6.38 4.79 7.9 8.38s1.01 8.26-2.02 10.7c3.05.98 6.32 1.26 9.49.81.22 1.13-.93 2.3-2.07 2.09 6.29 1.37 13-.57 18.27-4.26s12.61-8.2 12.78-16.51z" />
      ),
      basic: {
        dark: (
          <path d="M171.57 266.17c1.2-6.44-1.49-45.81-5.58-18-4.43-10-15.87-21.33-22.87-28.59.44 7.35 10.58 12.47 9.57 20.83-3.21 12.4-5.65 30.78 13.67 26.78.46 14.02 8.46 11.89 5.21-1.02z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M320.54 259.35c-.13-6.76 4.33-12.62 8.4-18.01s8.18-11.67 7.2-18.36c1.58.65 2.54 2.53 2.15 4.19 1.76-6.81 7.92-11.38 13.44-15.74 5.52-4.36 11.21-9.92 11.31-16.95 3.49 2.54 5.41 7.08 4.78 11.35 1.63-1.04 2.71-2.89 2.81-4.82 1.91 2.61 2.95 5.84 2.94 9.07.74-.97 1.49-1.94 2.23-2.91 1.74 8.25 3.47 16.51 5.21 24.76.8-2.27 1.61-4.54 2.41-6.81 2.56 9.57-1 20.49-8.7 26.72-3.03 2.45-6.57 4.18-9.83 6.3-3.27 2.12-6.38 4.79-7.9 8.38s-1.01 8.26 2.02 10.7c-3.05.98-6.32 1.26-9.49.81-.22 1.13.93 2.3 2.07 2.09-6.29 1.37-13-.57-18.27-4.26-5.28-3.69-12.62-8.2-12.78-16.51z" />
      ),
      basic: {
        dark: (
          <path d="M328.43 266.18c-1.2-6.44 1.49-45.81 5.58-18 4.43-10 15.87-21.33 22.87-28.59-.44 7.35-10.58 12.47-9.57 20.83 3.21 12.4 5.65 30.78-13.67 26.78-.46 14.02-8.46 11.89-5.21-1.02z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1kDDB4 = {
  name: "Ears 34 (Furry)",
  description: "Furry, wide ears that droop downwards into a slight point",
  tags: ["pointed", "side", "drooping", "furry"],
  preview: {
    viewbox: "110 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M189.04,239.52
						c0.6-5.42,1.15-10.92,0.24-16.29c-0.91-5.37-3.48-10.69-7.97-13.77c-4.63-3.19-11.3-4.18-13.73-9.25
						c-1.1,2.2-0.21,5.19,1.91,6.44c-3.52,0.45-7.17-0.23-10.3-1.9c-0.85,1.62,0.05,3.9,1.78,4.49c-3.16,2.53-7.32,3.78-11.36,3.41
						c0.5,1.53,2.27,2.51,3.83,2.13c-9.02,3.55-14.02,13.11-17.32,22.22c-3.3,9.11-5.97,18.98-12.92,25.72
						c2.19-0.95,4.37-1.91,6.56-2.86c-7.51,17.36-5.27,38.57,5.7,53.98c0.02-2.62,0.04-5.24,0.07-7.87c1.2,1.79,2.4,3.57,3.6,5.36
						c0.33-7.21,4.62-14.11,10.93-17.6c-0.59,2.13-1.18,4.26-1.77,6.38c5.69-9.27,13.07-17.49,21.68-24.13
						c-1.19,2.4-2.4,5.2-1.31,7.64c0.39-3.63,3.38-6.34,5.93-8.95C184.73,264.23,187.91,249.63,189.04,239.52z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M180.18,209.14c0.33-1.11-0.08-2.36-1.1-2.9c-4.94-2.64-7.22,17.25-11.93,18.47c-7.6,2.74-36.8,61.73-19.26,54.87c0.35-0.14,0.67-0.36,0.93-0.64c3.2-3.56,4.41-8.85,8.28-11.66c0.09-0.07,0.19-0.13,0.29-0.19c1.77-1.03,3.73-1.86,5.41-3.02c1.51-1.04,3.58-0.22,3.95,1.58c3.9,18.59,9.88,5.21,5.75-9.1c-0.05-0.17-0.08-0.35-0.1-0.53c171.31,239.9,175.63,224.27,180.18,209.14z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M310.96,239.52
						c-0.6-5.42-1.15-10.92-0.24-16.29c0.91-5.37,3.48-10.69,7.97-13.77c4.63-3.19,11.3-4.18,13.73-9.25
						c1.1,2.2,0.21,5.19-1.91,6.44c3.52,0.45,7.17-0.23,10.3-1.9c0.85,1.62-0.05,3.9-1.78,4.49c3.16,2.53,7.32,3.78,11.36,3.41
						c-0.5,1.53-2.27,2.51-3.83,2.13c9.02,3.55,14.02,13.11,17.32,22.22c3.3,9.11,5.97,18.98,12.92,25.72
						c-2.19-0.95-4.37-1.91-6.56-2.86c7.51,17.36,5.27,38.57-5.7,53.98c-0.02-2.62-0.04-5.24-0.07-7.87c-1.2,1.79-2.4,3.57-3.6,5.36
						c-0.33-7.21-4.62-14.11-10.93-17.6c0.59,2.13,1.18,4.26,1.77,6.38c-5.69-9.27-13.07-17.49-21.68-24.13
						c1.19,2.4,2.4,5.2,1.31,7.64c-0.39-3.63-3.38-6.34-5.93-8.95C315.27,264.23,312.09,249.63,310.96,239.52z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M319.82,209.14c-0.33-1.11,0.08-2.36,1.1-2.9c4.94-2.64,7.22,17.25,11.93,18.47c7.6,2.74,36.8,61.73,19.26,54.87c-0.35-0.14-0.67-0.36-0.93-0.64c-3.2-3.56-4.41-8.85-8.28-11.66c-0.09-0.07-0.19-0.13-0.29-0.19c-1.77-1.03-3.73-1.86-5.41-3.02c-1.51-1.04-3.58-0.22-3.95,1.58c-3.9,18.59-9.88,5.21-5.75-9.1c0.05-0.17,0.08-0.35,0.1-0.53C328.69,239.9,324.37,224.27,319.82,209.14z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_1AwTvI = {
  name: "Ears 35 (Furry)",
  description:
    "Furry, long ears that sweep upwards to a slight point, with another point/fin shape partway down",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "90 175 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path
            d="M182.8,276.59
						c0.47-12.66-3.4-25.43-10.83-35.69c-4.54-6.28-7.18-13.93-7.47-21.67c-1.33,1.53-1.94,3.66-1.62,5.66
						c-3.2-9.79-8.44-18.91-15.28-26.61c-2.57-2.89-5.38-5.61-7.39-8.92c-2-3.31-3.13-7.39-1.95-11.07
						c-3.96,3.09-6.3,8.14-6.11,13.16c-1.64-0.9-2.8-2.64-3-4.5c-1.4,4.23-1.09,9.01,0.86,13.02c-1.16-0.46-2.33-0.91-3.49-1.37
						c2.96,8.73,3.96,18.12,2.92,27.28c-0.35,3.11-1.02,6.36-3.06,8.73s-5.88,3.46-8.39,1.59c0.38,1.56,1.79,2.81,3.38,3.02
						c-3.55,3.24-8.93,4.31-13.45,2.68c1.41,7.79,9.22,13.88,17.12,13.35c-1.96,1.25-4.43,1.66-6.69,1.12
						c5.36,1.64,10.75,3.42,15.43,6.49c8.23,5.4,13.44,14.2,19.52,21.95c6.07,7.75,14.25,15.1,24.09,15.33
						c2.21,0.05,4.5-0.3,6.34-1.52s3.11-3.46,2.71-5.64C185.35,287.18,182.61,281.59,182.8,276.59z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M178.95,291.52c-6.5-17.48-7.11-36.82-6.85-55.3c-0.9,0.3-1.98-0.05-2.52-0.82c-4.29,2.17-3.49,8.83-4.35,12.98c-5.71-12.06-9.62-29.96-20.7-37.38c2.44,8.6,10.58,15.44,10.46,24.38c-0.07,5.25-3.05,9.97-4.36,15.05c-3.01,10.6,3.04,27.88,16.44,24.22c2.03,8.65,5.44,16.97,10.16,24.5c1.18,1.88,5.14,5.85,5.59,1.37c183.1,297.83,180.03,293.88,178.95,291.52z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path
            d="M317.16,276.59
						c-0.47-12.66,3.4-25.43,10.83-35.69c4.54-6.28,7.18-13.93,7.47-21.67c1.33,1.53,1.94,3.66,1.62,5.66
						c3.2-9.79,8.44-18.91,15.28-26.61c2.57-2.89,5.38-5.61,7.39-8.92c2-3.31,3.13-7.39,1.95-11.07c3.96,3.09,6.3,8.14,6.11,13.16
						c1.64-0.9,2.8-2.64,3-4.5c1.4,4.23,1.09,9.01-0.86,13.02c1.16-0.46,2.33-0.91,3.49-1.37c-2.96,8.73-3.96,18.12-2.92,27.28
						c0.35,3.11,1.02,6.36,3.06,8.73s5.88,3.46,8.39,1.59c-0.38,1.56-1.79,2.81-3.38,3.02c3.55,3.24,8.93,4.31,13.45,2.68
						c-1.41,7.79-9.22,13.88-17.12,13.35c1.96,1.25,4.43,1.66,6.69,1.12c-5.35,1.64-10.75,3.42-15.43,6.49
						c-8.23,5.4-13.44,14.2-19.52,21.95s-14.25,15.1-24.09,15.33c-2.21,0.05-4.5-0.3-6.34-1.52s-3.11-3.46-2.71-5.64
						C314.61,287.18,317.35,281.59,317.16,276.59z"
          />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M321.05,291.52c6.5-17.48,7.11-36.82,6.85-55.3c0.9,0.3,1.98-0.05,2.52-0.82c4.29,2.17,3.49,8.83,4.35,12.98c5.71-12.06,9.62-29.96,20.7-37.38c-2.44,8.6-10.58,15.44-10.46,24.38c0.07,5.25,3.05,9.97,4.36,15.05c3.01,10.6-3.04,27.88-16.44,24.22c-2.03,8.65-5.44,16.97-10.16,24.5c-1.18,1.88-5.14,5.85-5.59,1.37C316.9,297.83,319.97,293.88,321.05,291.52z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_10U6iQ = {
  name: "Ears 36 (Furry)",
  description:
    "Furry, extra wide ears that reach outwards and droop slightly at the tip",
  tags: ["pointed", "side", "furry"],
  preview: {
    viewbox: "70 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M184.79 261.88c.37-6.16.74-12.39-.32-18.47-1.06-6.08-3.69-12.1-8.4-16.08-5.77-4.88-14.55-6.81-17.6-13.72-1.44 1.77-.92 4.77 1.03 5.95-4.4-.96-8.48-3.32-11.5-6.66-.51 1.68-.14 3.6.95 4.98-11.58-4.92-24.98-5.43-36.9-1.39-6.57 2.23-12.9 5.81-19.83 5.95 1.11 1.87 3.75 2.64 5.7 1.66-3.64 5.59-10.11 9.23-16.78 9.44 3.58 4.74 10.35 5.46 16.29 5.37 5.94-.09 12.45-.43 17.18 3.16-3.92.27-7.83.53-11.75.8 6.88 1.15 14.04 2.41 19.66 6.54 11.25 8.26 13.09 25.43 24.54 33.4-2.88.21-5.82-1.02-7.7-3.2 7.06 12.66 21.21 21.03 35.71 21.11.19.96-.88 1.92-1.81 1.62 3.39 2.34 8.39.6 10.85-2.71 2.46-3.31 2.97-7.65 3.11-11.77.3-9.59-2.85-19-2.43-25.98z" />
      ),
      basic: {
        dark: (
          <path d="M177.59 278.28c-10.72-4.89-.02-82.77-9.82-46.39-16.09-3.61-36.87-13.06-52.13-4.05 27.88-4.53 34.98 20.64 38.56 42.68 1.88 3.72 5.79 6.97 10.16 6.61 8.45-1.02 7.73 20.98 17.71 26.08 4.08-8.31-3.07-16.42-4.48-24.93z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.21 261.88c-.37-6.16-.74-12.39.32-18.47 1.06-6.08 3.69-12.1 8.4-16.08 5.77-4.88 14.55-6.81 17.6-13.72 1.44 1.77.92 4.77-1.03 5.95 4.4-.96 8.48-3.32 11.5-6.66.51 1.68.14 3.6-.95 4.98 11.58-4.92 24.98-5.43 36.9-1.39 6.57 2.23 12.9 5.81 19.83 5.95-1.11 1.87-3.75 2.64-5.7 1.66 3.64 5.59 10.11 9.23 16.78 9.44-3.58 4.74-10.35 5.46-16.29 5.37-5.94-.09-12.45-.43-17.18 3.16 3.92.27 7.83.53 11.75.8-6.88 1.15-14.04 2.41-19.66 6.54-11.25 8.26-13.09 25.43-24.54 33.4 2.88.21 5.82-1.02 7.7-3.2-7.06 12.66-21.21 21.03-35.71 21.11-.19.96.88 1.92 1.81 1.62-3.39 2.34-8.39.6-10.85-2.71-2.46-3.31-2.97-7.65-3.11-11.77-.3-9.59 2.85-19 2.43-25.98z" />
      ),
      basic: {
        dark: (
          <path d="M322.41 278.28c10.72-4.89.02-82.77 9.82-46.39 16.09-3.61 36.87-13.06 52.13-4.05-27.88-4.53-34.98 20.64-38.56 42.68-1.88 3.72-5.79 6.97-10.16 6.61-8.45-1.02-7.73 20.98-17.71 26.08-4.08-8.31 3.07-16.42 4.48-24.93z" />
        ),
      },
    },
  },
};

const ear_shape_1BTIMM = {
  name: "Ears 37 (Furry)",
  description: "Furry, large ears that bump slightly outwards at the top",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "100 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.1 260.34c1.19-8.9-2.33-17.75-6.64-25.62s-9.55-15.35-12.31-23.9c-1.01 1.28-1.52 2.95-1.38 4.57-1.65-4.54-6.22-7.26-10.51-9.49s-8.93-4.64-11.06-8.98c-.47-.96-1.14-2.19-2.19-2.01-.63.11-1.04.73-1.33 1.3-1.01 2.02-1.54 4.28-1.54 6.54-2.27-.5-4.29-2.03-5.4-4.07-1.47 2.82-2.46 5.88-2.93 9.03-.62-.36-1.24-.72-1.86-1.07-1.58 5.98-3.17 12.01-3.67 18.18s.15 12.56 2.87 18.12c-2.12-.12-4.11-1.56-4.89-3.54.13 3.75 2.4 7.09 5.05 9.75 2.64 2.66 5.73 4.87 8.16 7.72 6.5 7.62 7.34 18.41 7.89 28.41.1 1.82.19 3.69-.3 5.44-.49 1.76-1.7 3.42-3.43 3.98 3.29 1.99 7.17 2.98 11.01 2.82.85-.04 1.73-.12 2.53.17s1.47 1.13 1.27 1.96c-.21.82-1.59 1.1-1.9.31 9.92 1.99 20.35-3.52 26.1-11.85 5.75-8.32 5.28-18.93 6.46-27.77z" />
      ),
      basic: {
        dark: (
          <path d="M173.95 235.54c-1.06-5.16 1.33-12.55-3.24-15.66-1.28 2.85-2.06 5.92-2.79 8.92-6.77-6.2-16.17-17.62-25.83-16.08 16.13 11.18 18.68 33.6 13.08 51.25-6.77 13.48 5.33 26 14.24 10.13 1.95 6.89 2.52 14.69 6.41 20.74 8.11-6.61-4.8-22.12-2.8-32.46-.82-8.94.9-17.89.93-26.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.9 260.34c-1.19-8.9 2.33-17.75 6.64-25.62 4.32-7.87 9.55-15.35 12.31-23.9 1.01 1.28 1.52 2.95 1.38 4.57 1.65-4.54 6.22-7.26 10.51-9.49s8.93-4.64 11.06-8.98c.47-.96 1.14-2.19 2.19-2.01.63.11 1.04.73 1.33 1.3 1.01 2.02 1.54 4.28 1.54 6.54 2.27-.5 4.29-2.03 5.4-4.07 1.47 2.82 2.46 5.88 2.93 9.03.62-.36 1.24-.72 1.86-1.07 1.58 5.98 3.17 12.01 3.67 18.18s-.15 12.56-2.87 18.12c2.12-.12 4.11-1.56 4.89-3.54-.13 3.75-2.4 7.09-5.05 9.75-2.64 2.66-5.73 4.87-8.16 7.72-6.5 7.62-7.34 18.41-7.89 28.41-.1 1.82-.19 3.69.3 5.44.49 1.76 1.7 3.42 3.43 3.98-3.29 1.99-7.17 2.98-11.01 2.82-.85-.04-1.73-.12-2.53.17-.8.3-1.47 1.13-1.27 1.96.21.82 1.59 1.1 1.9.31-9.92 1.99-20.35-3.52-26.1-11.85-5.75-8.32-5.28-18.93-6.46-27.77z" />
      ),
      basic: {
        dark: (
          <path d="M326.05 235.54c1.06-5.16-1.33-12.55 3.24-15.66 1.28 2.85 2.06 5.92 2.79 8.92 6.77-6.2 16.17-17.62 25.83-16.08-16.13 11.18-18.68 33.6-13.08 51.25 6.77 13.48-5.33 26-14.24 10.13-1.95 6.89-2.52 14.69-6.41 20.74-8.11-6.61 4.8-22.12 2.8-32.46.82-8.94-.9-17.89-.93-26.84z" />
        ),
      },
    },
  },
};

const ear_shape_1cnX1o = {
  name: "Ears 38 (Furry)",
  description:
    "Furry, tall ears that sweep upwards and slightly out from the side of the head into a slight point",
  tags: ["pointed", "side", "large", "furry"],
  preview: {
    viewbox: "80 150 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.08 271.25c-2.47 9.13-7.25 17.63-13.78 24.47-.14-1.72-.28-3.44-.43-5.16-4.04 3.09-9.72 3.88-14.44 2.01 2.63.09 5.27-1.18 6.85-3.28-7.28.85-14.33-3.63-18.76-9.46s-6.82-12.93-9.49-19.75c-2.67-6.83-5.85-13.75-11.38-18.56 2.4-.1 4.81 1.12 6.16 3.11-2.24-7.22-1.59-15.01-3.04-22.43-2.18-11.15-9.36-21.22-19.18-26.93 2.1-.14 4.2-.27 6.3-.41-4.63-8.68-10.35-16.78-16.98-24.06 3.42.18 6.78 1.45 9.46 3.58-4.39-6.71-5.53-15.45-3.01-23.07 1.18 6.47 5.1 12.4 10.6 16-2.03-3.64-2.11-8.3-.21-12.01.52 7.58 4.15 14.89 9.87 19.89-1.53-2.37-1.6-5.6-.19-8.05 3.19 7.79 9.79 13.57 15.85 19.41 6.06 5.84 12.07 12.56 13.42 20.87-.39-3.37.01-6.84 1.17-10.03.39 9.4 4.99 18.11 10.32 25.87 5.33 7.75 11.53 14.97 15.83 23.34s6.74 18.42 5.06 24.65z" />
      ),
      basic: {
        dark: (
          <path d="M178.75 285.07c-4.33-17.6-3-35.86-3.49-53.81.41-6.18-5.53-6.33-7.62-.93-1.04 2.46-1.66 5.07-2.09 7.71-7.17-12.55-21.4-43.01-34.31-48.12 17.77 17.71 20.35 43.22 17.25 66.85.34 5.57 3.41 13.77 9.92 13.44 2.46-.93 4.38-3.77 7.39-2.83 2.04 9.15 3.77 19.64 9.95 26.93 8.96 4.54 4.72-5 3-9.24z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.42 271.22c2.47 9.13 7.25 17.63 13.78 24.47.14-1.72.28-3.44.43-5.16 4.04 3.09 9.72 3.88 14.44 2.01-2.63.09-5.27-1.18-6.85-3.28 7.28.85 14.33-3.63 18.76-9.46s6.82-12.93 9.49-19.75c2.67-6.83 5.85-13.75 11.38-18.56-2.4-.1-4.81 1.12-6.16 3.11 2.24-7.22 1.59-15.01 3.04-22.43 2.18-11.15 9.36-21.22 19.18-26.93-2.1-.14-4.2-.27-6.3-.41 4.63-8.68 10.35-16.78 16.98-24.06-3.42.18-6.78 1.45-9.46 3.58 4.39-6.71 5.53-15.45 3.01-23.07-1.18 6.47-5.1 12.4-10.6 16 2.03-3.64 2.11-8.3.21-12.01-.52 7.58-4.15 14.89-9.87 19.89 1.53-2.37 1.6-5.6.19-8.05-3.19 7.79-9.79 13.57-15.85 19.41-6.06 5.84-12.07 12.56-13.42 20.87.39-3.37-.01-6.84-1.17-10.03-.39 9.4-4.99 18.11-10.32 25.87-5.33 7.75-11.53 14.97-15.83 23.34s-6.74 18.42-5.06 24.65z" />
      ),
      basic: {
        dark: (
          <path d="M321.25 285.07c4.33-17.6 3-35.86 3.49-53.81-.41-6.18 5.53-6.33 7.62-.93 1.04 2.46 1.66 5.07 2.09 7.71 7.17-12.55 21.4-43.01 34.31-48.12-17.77 17.71-20.35 43.22-17.25 66.85-.34 5.57-3.41 13.77-9.92 13.44-2.46-.93-4.38-3.77-7.39-2.83-2.04 9.15-3.77 19.64-9.95 26.93-8.96 4.54-4.72-5-3-9.24z" />
        ),
      },
    },
  },
};

const ear_shape_1UEVQI = {
  name: "Ears 39 (Furry)",
  description:
    "Furry, tall and rounded ears that sweep upwards and slightly out from the side of the head",
  tags: ["rounded", "side", "furry"],
  preview: {
    viewbox: "80 150 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M190.24 283.37c-1.7 6.08-8.12 10.05-14.43 10.27-6.31.21-12.38-2.72-17.31-6.68-4.93-3.95-8.93-8.91-13.19-13.58-4.26-4.66-8.93-9.15-14.67-11.78 2.74.61 5.48 1.21 8.22 1.82-9.21-7.12-13.6-18.67-17.59-29.6-3.99-10.94-8.46-22.47-17.76-29.48 2.11-.25 4.3.38 5.95 1.71-6.44-9.01-8.71-20.87-6.06-31.62.13 2.18.94 4.32 2.3 6.04.51-6.84 2.67-13.55 6.24-19.41-.95 7.29 2.57 14.98 8.71 19.02-.51-2-1.03-4.01-1.54-6.01 8.34 5.24 16.74 10.52 23.98 17.2s13.35 14.91 16.02 24.39c-.03-2.63.32-5.27 1.03-7.8 5.18 13.56 14.67 24.96 21.85 37.57 7.18 12.6 11.13 27.61 8.25 37.94z" />
      ),
      basic: {
        dark: (
          <path d="M183.51 287.65c-5.48-20.85-8.15-42.7-6.91-63.78-8.48-4.05-9.28 9.02-9.81 14.57-4.27-8.51-42.68-55.73-51.29-48.2 12.92 7.35 26.35 17.47 28.62 33.42 1.37 8.2-.23 16.69 1.22 24.88 1.22 8.59 12.67 30.44 20.86 16.48 1.93 9.9 1.36 32.41 13.13 34.93 5.49-1.05 5.44-7.97 4.18-12.3z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M309.76 283.37c1.7 6.08 8.12 10.05 14.43 10.27 6.31.21 12.38-2.72 17.31-6.68 4.93-3.95 8.93-8.91 13.19-13.58 4.26-4.66 8.93-9.15 14.67-11.78-2.74.61-5.48 1.21-8.22 1.82 9.21-7.12 13.6-18.67 17.59-29.6 3.99-10.94 8.46-22.47 17.76-29.48-2.11-.25-4.3.38-5.95 1.71 6.44-9.01 8.71-20.87 6.06-31.62-.13 2.18-.94 4.32-2.3 6.04-.51-6.84-2.67-13.55-6.24-19.41.95 7.29-2.57 14.98-8.71 19.02.51-2 1.03-4.01 1.54-6.01-8.34 5.24-16.74 10.52-23.98 17.2s-13.35 14.91-16.02 24.39c.03-2.63-.32-5.27-1.03-7.8-5.18 13.56-14.67 24.96-21.85 37.57-7.18 12.6-11.13 27.61-8.25 37.94z" />
      ),
      basic: {
        dark: (
          <path d="M316.49 287.65c5.48-20.85 8.15-42.7 6.91-63.78 8.48-4.05 9.28 9.02 9.81 14.57 4.27-8.51 42.68-55.73 51.29-48.2-12.92 7.35-26.35 17.47-28.62 33.42-1.37 8.2.23 16.69-1.22 24.88-1.22 8.59-12.67 30.44-20.86 16.48-1.93 9.9-1.36 32.41-13.13 34.93-5.49-1.05-5.44-7.97-4.18-12.3z" />
        ),
      },
    },
  },
};

export const EarShapePack4 = {
  ear_shape_1TEKWA,
  ear_shape_10775v,
  ear_shape_1HOZ9g,
  ear_shape_1vBJ7j,
  ear_shape_1FYH9c,
  ear_shape_1aoGZG,
  ear_shape_1ZgR2p,
  ear_shape_1vdyix,
  ear_shape_1TbLPe,
  ear_shape_13wnpn,
  ear_shape_1PDULw,
  ear_shape_1kOBSt,
  ear_shape_1yAT34,
  ear_shape_18wAS4,
  ear_shape_1gcoC9,
  ear_shape_1bPDFx,
  ear_shape_1oEAG6,
  ear_shape_1Dp6oZ,
  ear_shape_1kf82L,
  ear_shape_18sDmX,
  ear_shape_1k9vVR,
  ear_shape_1aKjrI,
  ear_shape_12rsVH,
  ear_shape_1axjXx,
  ear_shape_1Zxpa2,
  ear_shape_1jWOL0,
  ear_shape_1TUUUj,
  ear_shape_1Wk3es,
  ear_shape_1XkDvO,
  ear_shape_1xyzCw,
  ear_shape_1epGZS,
  ear_shape_14qBKl,
  ear_shape_1zwyyL,
  ear_shape_1kDDB4,
  ear_shape_1AwTvI,
  ear_shape_10U6iQ,
  ear_shape_1BTIMM,
  ear_shape_1cnX1o,
  ear_shape_1UEVQI,
};
