import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { BodyShapeList } from "./shape/BodyShapeList";
import BodyMarkings from "./markings/BodyMarkings";
import { getGlobalStyle } from "../StyleOptions";
import { outlineOnly } from "character-creator/colours/colourSchemes";
import CharacterHeadShadow from "../head/CharacterHeadShadow";
import BodyExtras from "./extras/BodyExtras";
import BodyScars from "./markings/Scars/BodyScar";

function mapStateToProps(state, ownProps) {
  const useBodyOutline = getGlobalStyle(state).outline;

  const bodyConfig = state.config.base.body;
  const bodyObject =
    ownProps.preview && ownProps.body
      ? ownProps.body
      : BodyShapeList[bodyConfig.shape];
  const colour = bodyConfig.colour
    ? bodyConfig.colour
    : state.config.base.skintone;

  return {
    Body: bodyObject,
    colour: colour,
    useBodyOutline,
  };
}

const CharacterBody = (props) => {
  const { Body, colour, preview, previewId, useBodyOutline } = props;

  return (
    <g id={previewId ? `g-body~${previewId}` : "g-body"}>
      <Component
        id={previewId ? `body~${previewId}` : "body"}
        component={Body}
        colour={colour}
        preview={previewId ? true : false}
      />

      {!preview && <BodyExtras />}

      {!preview && <BodyMarkings />}

      {!preview && <BodyScars />}

      {useBodyOutline && (
        <Component
          id={previewId ? `bodyOutline~${previewId}` : "bodyOutline"}
          component={Body}
          linework={true}
          colour={{ ...outlineOnly, outline: colour.outline }}
          preview={previewId ? true : false}
        />
      )}

      {!preview && (
        <CharacterHeadShadow id="body" maskId={"body_mask"} colour={colour} />
      )}
    </g>
  );
};

export default connect(mapStateToProps)(CharacterBody);
