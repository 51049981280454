// Components
import HairColours from "character-creator/colours/hair";
import eyebrowShapeList from "character-creator/components/head/face/facialHair/eyebrows/EyebrowShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyebrowPath = "base.head.face.eyebrows.";
const eyebrowTransformPathLeft = eyebrowPath + "left.transform.";
const eyebrowTransformPathRight = eyebrowPath + "right.transform.";

const EyebrowsPage = {
  id: "eyebrowsPage",
  name: "Eyebrows",
  description: "I don't window shop, eyebrows",
  symmetryAttribute: eyebrowPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "eyebrowShape",
        name: "Shape",
        description: "",
        dataAttribute: [
          eyebrowPath + "left.shape",
          eyebrowPath + "right.shape",
        ],
        variationAttribute: "",
        previewType: "preview",
        previewSide: "L",
        previewViewbox: "160 180 100 100",
        options: eyebrowShapeList,
        colourControls: [
          {
            id: "eyebrowColour",
            name: "Eyebrow Colour",
            description: "",
            dataAttribute: [
              eyebrowPath + "right.colour",
              eyebrowPath + "left.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            previewSide: "R",
            options: HairColours,
          },
        ],
        transformControls: [
          {
            id: "eyebrowTransformY",
            name: "Height",
            description: "",
            dataAttribute: [
              eyebrowTransformPathLeft + "y",
              eyebrowTransformPathRight + "y",
            ],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "eyebrowTransformX",
            name: "Distance",
            description: "",
            transformOpposite: true,
            dataAttribute: [
              eyebrowTransformPathLeft + "x",
              eyebrowTransformPathLeft + ".x",
            ],
            range: {
              min: -25,
              max: 25,
              step: 5,
            },
          },
          {
            id: "eyebrowTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [
              eyebrowTransformPathLeft + "scale",
              eyebrowTransformPathRight + "scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "eyebrowLeftShape",
        name: "Left Shape",
        description: "",
        dataAttribute: [eyebrowPath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        previewSide: "L",
        previewViewbox: "160 180 100 100",
        options: eyebrowShapeList,
        colourControls: [
          {
            id: "eyebrowLeftColour",
            name: "Left Colour",
            description: "",
            dataAttribute: [eyebrowPath + "left.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            previewSide: "L",
            options: HairColours,
          },
        ],
        transformControls: [
          {
            id: "eyebrowLeftTransformY",
            name: "Height",
            description: "",
            dataAttribute: [eyebrowTransformPathLeft + "y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "eyebrowLeftTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [eyebrowTransformPathLeft + "scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "eyebrowRightShape",
        name: "Right Shape",
        description: "",
        dataAttribute: [eyebrowPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        previewSide: "R",
        previewViewbox: "235 180 100 100",
        options: eyebrowShapeList,
        colourControls: [
          {
            id: "eyebrowRightColour",
            name: "Right Colour",
            description: "",
            dataAttribute: [eyebrowPath + "right.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            previewSide: "R",
            options: HairColours,
          },
        ],
        transformControls: [
          {
            id: "eyebrowRightTransformY",
            name: "Height",
            description: "",
            dataAttribute: [eyebrowTransformPathRight + "y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "eyebrowRightTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [eyebrowTransformPathRight + "scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
  },
};

export default EyebrowsPage;
