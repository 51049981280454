import React from "react";

import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterBody preview={true} previewId={previewId} body={component} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewbox = "30 300 200 200";
