/**
 * Variations/options
 */
import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { hairBackFullPack1 } from "./packs/HairBackFullPack1";
import { hairBackFullPack2 } from "./packs/HairBackFullPack2";

export const hairBackFullList = {
  "0": BlankListOption,
  ...hairBackFullPack1,
  ...hairBackFullPack2,
};

export default hairBackFullList;
