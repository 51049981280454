import AllSkintones from "character-creator/colours/skin";
import { BodyShapeList } from "character-creator/components/body/shape/BodyShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";
const bodyPath = basePath + "body.";

const BodyShapePage = {
  id: "bodyShapePage",
  name: "Shape",
  description: "Start your new career as a bodybuilder",
  controls: [
    {
      id: "bodyShape",
      name: "Shape and texture",
      description: "",
      dataAttribute: [bodyPath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyShapeList,
      colourControls: [
        {
          id: "bodyColour",
          name: "Colour",
          description: "",
          dataAttribute: [bodyPath + "colour", "base.skintone"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
    },
  ],
};

export default BodyShapePage;
