import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const mouth_shape_vZ4PzE4pcv = {
  name: "Mouth 1",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M285.511 305.22c1.846-1.233 3.773-2.285 5.583-2.695 2.346-.532 4.566 2.902 6.495.891 1.886-1.967.413-5.176 2.287-7.317 5.214-5.96 12.674-9.81 17.838-15.835 1.602-1.869 3.129-4.005 3.464-6.506.237-1.772-.353-4.959.872-6.441.849-1.029 1.549 1.864 1.524 2.286-.181 3.124-.644 7.692-2.23 10.435-1.294 2.236-3.721 3.903-5.619 5.592-4.097 3.646-8.624 7.086-12.185 11.284-2.263 2.666-1.145 7.852-4.261 9.792-2.353 1.466-4.554-.684-6.815-.339-3.044.464-6.377 3.283-8.8 5.045-2.019 1.469-3.517 3.354-4.102 5.815-.531 2.232-.358 5.411-2.612 6.729-2.431 1.422-5.536-.07-7.856-.94-2.185-.819-4.368-1.151-6.695-1.094-2.421.059-5.008-.135-7.2 1.087-1.377.767-2.921 3.511-4.747 3.23a5.248 5.248 0 0 0-.904 0c-1.826.281-3.37-2.463-4.747-3.23-2.192-1.222-4.779-1.028-7.2-1.087-2.327-.057-4.51.275-6.695 1.094-2.32.87-5.425 2.362-7.856.94-2.254-1.318-2.081-4.497-2.612-6.729-.585-2.461-2.083-4.346-4.102-5.815-2.423-1.762-5.756-4.581-8.8-5.045-2.261-.345-4.462 1.805-6.815.339-3.116-1.94-1.998-7.126-4.261-9.792-3.561-4.198-8.088-7.638-12.185-11.284-1.898-1.689-4.325-3.356-5.619-5.592-1.586-2.743-2.049-7.311-2.23-10.435-.025-.422.675-3.315 1.524-2.286 1.225 1.482.635 4.669.872 6.441.335 2.501 1.862 4.637 3.464 6.506 5.164 6.025 12.624 9.875 17.838 15.835 1.874 2.141.401 5.35 2.287 7.317 1.929 2.011 4.149-1.423 6.495-.891 1.81.41 3.737 1.462 5.583 2.695 2.313-.341 6.284-.824 7.604-.112 3.302 1.781 5.911 5.108 8.447 6.66 4.265 2.612 13.414.818 16.677.818h5.566c3.263 0 12.412 1.794 16.677-.818 2.536-1.552 5.145-4.879 8.447-6.66 1.32-.712 5.291-.229 7.604.112Z" />
          ),
          darker: (
            <path d="M248.89 320.293h2.22c.858-.841 2.48-1.285 3.572-1.669 3.866-1.36 8.486-1.747 12.37-.374 1.711.605 3.432 1.236 5.102 1.947.663.283 1.544.906 2.302.721 1.209-.295.269-3.79.536-4.781.761-2.827 2.797-5.14 5.083-6.872 2.994-2.268 7.26-5.888 11.019-6.74 2.346-.532 4.566 2.902 6.495.891 1.886-1.967.413-5.176 2.287-7.317 5.214-5.96 12.674-9.81 17.838-15.835 1.602-1.869 3.129-4.005 3.464-6.506.237-1.772-.353-4.959.872-6.441.849-1.029 1.549 1.864 1.524 2.286-.181 3.124-.644 7.692-2.23 10.435-1.294 2.236-3.721 3.903-5.619 5.592-4.097 3.646-8.624 7.086-12.185 11.284-2.263 2.666-1.145 7.852-4.261 9.792-2.353 1.466-4.554-.684-6.815-.339-3.044.464-6.377 3.283-8.8 5.045-2.019 1.469-3.517 3.354-4.102 5.815-.531 2.232-.358 5.411-2.612 6.729-2.431 1.422-5.536-.07-7.856-.94-2.185-.819-4.368-1.151-6.695-1.094-2.421.059-5.008-.135-7.2 1.087-1.377.767-2.921 3.511-4.747 3.23a5.248 5.248 0 0 0-.904 0c-1.826.281-3.37-2.463-4.747-3.23-2.192-1.222-4.779-1.028-7.2-1.087-2.327-.057-4.51.275-6.695 1.094-2.32.87-5.425 2.362-7.856.94-2.254-1.318-2.081-4.497-2.612-6.729-.585-2.461-2.083-4.346-4.102-5.815-2.423-1.762-5.756-4.581-8.8-5.045-2.261-.345-4.462 1.805-6.815.339-3.116-1.94-1.998-7.126-4.261-9.792-3.561-4.198-8.088-7.638-12.185-11.284-1.898-1.689-4.325-3.356-5.619-5.592-1.586-2.743-2.049-7.311-2.23-10.435-.025-.422.675-3.315 1.524-2.286 1.225 1.482.635 4.669.872 6.441.335 2.501 1.862 4.637 3.464 6.506 5.164 6.025 12.624 9.875 17.838 15.835 1.874 2.141.401 5.35 2.287 7.317 1.929 2.011 4.149-1.423 6.495-.891 3.759.852 8.025 4.472 11.019 6.74 2.286 1.732 4.322 4.045 5.083 6.872.267.991-.673 4.486.536 4.781.758.185 1.639-.438 2.302-.721 1.67-.711 3.391-1.342 5.102-1.947 3.884-1.373 8.504-.986 12.37.374 1.092.384 2.714.828 3.572 1.669Z" />
          ),
        },
      },
      lower: {
        shape: {
          base: (
            <path d="M226.881 324.375c-1.324.275-2.656.268-3.831-.419-2.254-1.318-2.081-4.497-2.612-6.729-.585-2.461-2.083-4.346-4.102-5.815-2.423-1.762-5.756-4.581-8.8-5.045-2.261-.345-4.462 1.805-6.815.339-3.116-1.94-1.998-7.126-4.261-9.792-3.561-4.198-8.088-7.638-12.185-11.284-1.898-1.689-4.325-3.356-5.619-5.592-1.586-2.743-2.049-7.311-2.23-10.435-.025-.422.675-3.315 1.524-2.286 1.225 1.482.635 4.669.872 6.441.335 2.501 1.862 4.637 3.464 6.506 5.164 6.025 12.624 9.875 17.838 15.835 1.874 2.141.401 5.35 2.287 7.317 1.929 2.011 4.149-1.423 6.495-.891 3.759.852 8.025 4.472 11.019 6.74 2.286 1.732 4.322 4.045 5.083 6.872.267.991-.673 4.486.536 4.781.758.185 1.639-.438 2.302-.721 1.67-.711 3.391-1.342 5.102-1.947 3.884-1.373 8.504-.986 12.37.374.437.153.959.317 1.482.511 2.131-.379 3.562-.623 3.624-.607.056-.015 1.241.185 3.054.506.428-.151.845-.284 1.204-.41 3.866-1.36 8.486-1.747 12.37-.374 1.711.605 3.432 1.236 5.102 1.947.663.283 1.544.906 2.302.721 1.209-.295.269-3.79.536-4.781.761-2.827 2.797-5.14 5.083-6.872 2.994-2.268 7.26-5.888 11.019-6.74 2.346-.532 4.566 2.902 6.495.891 1.886-1.967.413-5.176 2.287-7.317 5.214-5.96 12.674-9.81 17.838-15.835 1.602-1.869 3.129-4.005 3.464-6.506.237-1.772-.353-4.959.872-6.441.849-1.029 1.549 1.864 1.524 2.286-.181 3.124-.644 7.692-2.23 10.435-1.294 2.236-3.721 3.903-5.619 5.592-4.097 3.646-8.624 7.086-12.185 11.284-2.263 2.666-1.145 7.852-4.261 9.792-2.353 1.466-4.554-.684-6.815-.339-3.044.464-6.377 3.283-8.8 5.045-2.019 1.469-3.517 3.354-4.102 5.815-.531 2.232-.358 5.411-2.612 6.729-.953.557-2.009.667-3.082.542l-.197.244c-3.761 4.637-19.183 7.356-22.437 6.292-.283-.092-1.337-.092-1.62 0-3.254 1.064-18.676-1.655-22.437-6.292-.089-.11-.191-.235-.296-.367Z" />
          ),
          darker: (
            <path d="M248.89 320.293h2.22c.858-.841 2.48-1.285 3.572-1.669 3.866-1.36 8.486-1.747 12.37-.374 1.711.605 3.432 1.236 5.102 1.947.663.283 1.544.906 2.302.721 1.209-.295.269-3.79.536-4.781.761-2.827 2.797-5.14 5.083-6.872 2.994-2.268 7.26-5.888 11.019-6.74 2.346-.532 4.566 2.902 6.495.891 1.886-1.967.413-5.176 2.287-7.317 5.214-5.96 12.674-9.81 17.838-15.835 1.602-1.869 3.129-4.005 3.464-6.506.237-1.772-.353-4.959.872-6.441.849-1.029 1.549 1.864 1.524 2.286-.181 3.124-.644 7.692-2.23 10.435-1.294 2.236-3.721 3.903-5.619 5.592-4.097 3.646-8.624 7.086-12.185 11.284-2.263 2.666-1.145 7.852-4.261 9.792-2.353 1.466-4.554-.684-6.815-.339-3.044.464-6.377 3.283-8.8 5.045-2.019 1.469-3.517 3.354-4.102 5.815-.531 2.232-.358 5.411-2.612 6.729-2.431 1.422-5.536-.07-7.856-.94-2.185-.819-4.368-1.151-6.695-1.094-2.421.059-5.008-.135-7.2 1.087-1.377.767-2.921 3.511-4.747 3.23a5.248 5.248 0 0 0-.904 0c-1.826.281-3.37-2.463-4.747-3.23-2.192-1.222-4.779-1.028-7.2-1.087-2.327-.057-4.51.275-6.695 1.094-2.32.87-5.425 2.362-7.856.94-2.254-1.318-2.081-4.497-2.612-6.729-.585-2.461-2.083-4.346-4.102-5.815-2.423-1.762-5.756-4.581-8.8-5.045-2.261-.345-4.462 1.805-6.815.339-3.116-1.94-1.998-7.126-4.261-9.792-3.561-4.198-8.088-7.638-12.185-11.284-1.898-1.689-4.325-3.356-5.619-5.592-1.586-2.743-2.049-7.311-2.23-10.435-.025-.422.675-3.315 1.524-2.286 1.225 1.482.635 4.669.872 6.441.335 2.501 1.862 4.637 3.464 6.506 5.164 6.025 12.624 9.875 17.838 15.835 1.874 2.141.401 5.35 2.287 7.317 1.929 2.011 4.149-1.423 6.495-.891 3.759.852 8.025 4.472 11.019 6.74 2.286 1.732 4.322 4.045 5.083 6.872.267.991-.673 4.486.536 4.781.758.185 1.639-.438 2.302-.721 1.67-.711 3.391-1.342 5.102-1.947 3.884-1.373 8.504-.986 12.37.374 1.092.384 2.714.828 3.572 1.669Z" />
          ),
        },
      },
    },
  },
};

const mouth_shape_JVri7nuEFy = {
  name: "Mouth 1",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M284.024 305.196c.282-1.777.621-3.513 1.656-4.65 3.718-4.085 10.364-4.303 14.938-7.139 3.758-2.329 4.536-5.13 4.769-9.345.079-1.432.607-5.704.939-5.377 1.673 1.648.192 12.139-1.295 14.179-2.54 3.484-8.044 5.298-11.798 7.162-1.508.748-3.406 1.393-4.5 2.726-2.882 3.511-1.076 9.9-5.799 12.192-2.154 1.046-4.96.945-7.293 1.113-3.264.234-6.647.895-9.484 2.583-4.342 2.584-7.03 8.446-9.074 12.96-.841 1.856-1.278 3.877-2.203 5.698-.533 1.05-3.253 2.046-4.88 2.046-1.627 0-4.347-.996-4.88-2.046-.925-1.821-1.362-3.842-2.203-5.698-2.044-4.514-4.732-10.376-9.074-12.96-2.837-1.688-6.22-2.349-9.484-2.583-2.333-.168-5.139-.067-7.293-1.113-4.723-2.292-2.917-8.681-5.799-12.192-1.094-1.333-2.992-1.978-4.5-2.726-3.754-1.864-9.258-3.678-11.798-7.162-1.487-2.04-2.968-12.531-1.295-14.179.332-.327.86 3.945.939 5.377.233 4.215 1.011 7.016 4.769 9.345 4.574 2.836 11.22 3.054 14.938 7.139 1.035 1.137 1.374 2.873 1.656 4.65 4.118 2.217 8.649 1.672 11.798 1.672 1.953 0 3.884-.309 5.808.076 6.354 1.271 10.098 7.313 15.993 9.278.136.046.714.046.85 0 5.895-1.965 9.639-8.007 15.993-9.278 1.924-.385 3.855-.076 5.808-.076 3.149 0 7.68.545 11.798-1.672Z" />
          ),
          darker: (
            <path d="M250 336.136c.871 0 2.614-3.434 2.614-3.434.413.15 6.207-13.315 10.239-16.327 2.69-2.01 6.774-4.42 10.322-3.971 2.554.322 5.433.409 8.089-.952 3.507-1.797 1.892-8.134 4.416-10.906 3.718-4.085 10.364-4.303 14.938-7.139 3.758-2.329 4.536-5.13 4.769-9.345.079-1.432.607-5.704.939-5.377 1.673 1.648.192 12.139-1.295 14.179-2.54 3.484-8.044 5.298-11.798 7.162-1.508.748-3.406 1.393-4.5 2.726-2.882 3.511-1.076 9.9-5.799 12.192-2.154 1.046-4.96.945-7.293 1.113-3.264.234-6.647.895-9.484 2.583-4.342 2.584-7.03 8.446-9.074 12.96-.841 1.856-1.278 3.877-2.203 5.698-.533 1.05-3.253 2.046-4.88 2.046-1.627 0-4.347-.996-4.88-2.046-.925-1.821-1.362-3.842-2.203-5.698-2.044-4.514-4.732-10.376-9.074-12.96-2.837-1.688-6.22-2.349-9.484-2.583-2.333-.168-5.139-.067-7.293-1.113-4.723-2.292-2.917-8.681-5.799-12.192-1.094-1.333-2.992-1.978-4.5-2.726-3.754-1.864-9.258-3.678-11.798-7.162-1.487-2.04-2.968-12.531-1.295-14.179.332-.327.86 3.945.939 5.377.233 4.215 1.011 7.016 4.769 9.345 4.574 2.836 11.22 3.054 14.938 7.139 2.524 2.772.909 9.109 4.416 10.906 2.656 1.361 5.535 1.274 8.089.952 3.548-.449 7.632 1.961 10.322 3.971 4.032 3.012 9.826 16.477 10.239 16.327 0 0 1.743 3.434 2.614 3.434Z" />
          ),
        },
      },
      lower: {
        shape: {
          base: (
            <path d="M216.97 314.897c-4.602-2.333-2.841-8.658-5.703-12.145-1.094-1.333-2.992-1.978-4.5-2.726-3.754-1.864-9.258-3.678-11.798-7.162-1.487-2.04-2.968-12.531-1.295-14.179.332-.327.86 3.945.939 5.377.233 4.215 1.011 7.016 4.769 9.345 4.574 2.836 11.22 3.054 14.938 7.139 2.119 2.328 1.32 7.17 3.087 9.726.337.488.767.892 1.329 1.18 2.656 1.361 5.535 1.274 8.089.952 3.548-.449 7.632 1.961 10.322 3.971 1.393 1.04 2.996 3.327 4.514 5.869 2.878 4.819 5.455 10.556 5.725 10.458 0 0 1.743 3.434 2.614 3.434.871 0 2.614-3.434 2.614-3.434.274.099 2.922-5.806 5.85-10.667 1.481-2.458 3.034-4.649 4.389-5.66 2.69-2.01 6.774-4.42 10.322-3.971 2.554.322 5.433.409 8.089-.952a3.624 3.624 0 0 0 1.428-1.33c1.638-2.592.91-7.293 2.988-9.576 3.718-4.085 10.364-4.303 14.938-7.139 3.758-2.329 4.536-5.13 4.769-9.345.079-1.432.607-5.704.939-5.377 1.673 1.648.192 12.139-1.295 14.179-2.54 3.484-8.044 5.298-11.798 7.162-1.508.748-3.406 1.393-4.5 2.726-2.882 3.511-1.076 9.9-5.799 12.192l-.026.013c-.086.2-.18.406-.284.618-1.35 2.767-5.149 5.396-8.025 5.796-5.575.774-9.594 1.057-12.452 1.009-2.143 2.799-3.739 6.293-5.064 9.22-.841 1.856-1.278 3.877-2.203 5.698-.533 1.05-3.253 2.046-4.88 2.046-1.627 0-4.347-.996-4.88-2.046-.925-1.821-1.362-3.842-2.203-5.698-1.289-2.847-2.834-6.229-4.888-8.988-2.868.064-6.938-.215-12.628-1.005-2.876-.4-6.675-3.029-8.025-5.796a13.5 13.5 0 0 1-.406-.914Z" />
          ),
          darker: (
            <path d="M250 336.136c.871 0 2.614-3.434 2.614-3.434.413.15 6.207-13.315 10.239-16.327 2.69-2.01 6.774-4.42 10.322-3.971 2.554.322 5.433.409 8.089-.952 3.507-1.797 1.892-8.134 4.416-10.906 3.718-4.085 10.364-4.303 14.938-7.139 3.758-2.329 4.536-5.13 4.769-9.345.079-1.432.607-5.704.939-5.377 1.673 1.648.192 12.139-1.295 14.179-2.54 3.484-8.044 5.298-11.798 7.162-1.508.748-3.406 1.393-4.5 2.726-2.882 3.511-1.076 9.9-5.799 12.192-2.154 1.046-4.96.945-7.293 1.113-3.264.234-6.647.895-9.484 2.583-4.342 2.584-7.03 8.446-9.074 12.96-.841 1.856-1.278 3.877-2.203 5.698-.533 1.05-3.253 2.046-4.88 2.046-1.627 0-4.347-.996-4.88-2.046-.925-1.821-1.362-3.842-2.203-5.698-2.044-4.514-4.732-10.376-9.074-12.96-2.837-1.688-6.22-2.349-9.484-2.583-2.333-.168-5.139-.067-7.293-1.113-4.723-2.292-2.917-8.681-5.799-12.192-1.094-1.333-2.992-1.978-4.5-2.726-3.754-1.864-9.258-3.678-11.798-7.162-1.487-2.04-2.968-12.531-1.295-14.179.332-.327.86 3.945.939 5.377.233 4.215 1.011 7.016 4.769 9.345 4.574 2.836 11.22 3.054 14.938 7.139 2.524 2.772.909 9.109 4.416 10.906 2.656 1.361 5.535 1.274 8.089.952 3.548-.449 7.632 1.961 10.322 3.971 4.032 3.012 9.826 16.477 10.239 16.327 0 0 1.743 3.434 2.614 3.434Z" />
          ),
        },
      },
    },
  },
};

const mouth_shape_jmfUyv4AXT = {
  name: "Mouth 1",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    lips: {
      upper: {
        shape: {
          base: (
            <path d="M297.736 301.924c2.075-1.6 4.412-3.643 7.066-6.374 1.562-1.607 1.93-4.879 1.934-7.622 1.207.482 1.871 1.041 1.898 1.685.511 12.14-20.177 16.384-24.711 27.573-1.292 4.974-5.815 8.651-11.19 8.651h-45.466c-5.375 0-9.898-3.677-11.19-8.651-4.534-11.189-25.222-15.433-24.711-27.573.027-.644.691-1.203 1.898-1.685.004 2.743.372 6.015 1.934 7.622 2.654 2.731 4.991 4.774 7.066 6.374 3.289.294 13.224 1.828 15.192 3.923 4.09 4.358 3.702 11.153 9.651 14.128 2.794 1.396 6.373.999 9.388 1.037 4.498.057 22.512.057 27.01 0 3.015-.038 6.594.359 9.388-1.037 5.949-2.975 5.561-9.77 9.651-14.128 1.968-2.095 11.903-3.629 15.192-3.923Z" />
          ),
          darker: (
            <path d="M306.736 287.928c1.207.482 1.871 1.041 1.898 1.685.511 12.14-20.177 16.384-24.711 27.573-1.292 4.974-5.815 8.651-11.19 8.651h-45.466c-5.375 0-9.898-3.677-11.19-8.651-4.534-11.189-25.222-15.433-24.711-27.573.027-.644.691-1.203 1.898-1.685.004 2.743.372 6.015 1.934 7.622 13.469 13.862 18.775 9.988 23.032 20.494 1.212 4.669 5.458 8.121 10.505 8.121h42.53c5.047 0 9.293-3.452 10.505-8.121 4.257-10.506 9.563-6.632 23.032-20.494 1.562-1.607 1.93-4.879 1.934-7.622Z" />
          ),
        },
      },
      lower: {
        shape: {
          base: (
            <path d="M205.753 306.479c-7.024-4.935-14.701-9.412-14.387-16.866.027-.644.691-1.203 1.898-1.685.004 2.743.372 6.015 1.934 7.622 13.469 13.862 18.775 9.988 23.032 20.494 1.212 4.669 5.458 8.121 10.505 8.121h42.53c5.047 0 9.293-3.452 10.505-8.121 4.257-10.506 9.563-6.632 23.032-20.494 1.562-1.607 1.93-4.879 1.934-7.622 1.207.482 1.871 1.041 1.898 1.685.314 7.454-7.363 11.931-14.387 16.866-2.477 3.787-4.406 8.467-5.853 12.646-.786 2.27-1.2 4.709-2.853 6.561-5.676 6.357-15.181 5.452-22.797 5.381-3.391-.032-22.097-.032-25.488 0-7.616.071-17.121.976-22.797-5.381-1.653-1.852-2.067-4.291-2.853-6.561-1.447-4.179-3.376-8.859-5.853-12.646Z" />
          ),
          darker: (
            <path d="M306.736 287.928c1.207.482 1.871 1.041 1.898 1.685.511 12.14-20.177 16.384-24.711 27.573-1.292 4.974-5.815 8.651-11.19 8.651h-45.466c-5.375 0-9.898-3.677-11.19-8.651-4.534-11.189-25.222-15.433-24.711-27.573.027-.644.691-1.203 1.898-1.685.004 2.743.372 6.015 1.934 7.622 13.469 13.862 18.775 9.988 23.032 20.494 1.212 4.669 5.458 8.121 10.505 8.121h42.53c5.047 0 9.293-3.452 10.505-8.121 4.257-10.506 9.563-6.632 23.032-20.494 1.562-1.607 1.93-4.879 1.934-7.622Z" />
          ),
        },
      },
    },
  },
};

export const MouthsPack2 = {
  mouth_shape_vZ4PzE4pcv,
  mouth_shape_JVri7nuEFy,
  mouth_shape_jmfUyv4AXT,
};
