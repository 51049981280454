import React from "react";

import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import ClothingFront from "./ClothingFront";
import ClothingBack from "./ClothingBack";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <ClothingBack
        preview={true}
        previewId={previewId}
        previewTransform={false}
        clothing={component}
      />
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
      <ClothingFront
        preview={true}
        previewId={previewId}
        previewTransform={false}
        clothing={component}
      />
    </>
  );
};

export const viewbox = "0 0 500 500";
