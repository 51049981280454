import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { WhiskersShapeList } from "./WhiskersShapeList";
import {
  translateTransformObject,
  buildTransform,
} from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const whiskersConfig = state.config.base.head.face.extras.whiskers;

  let whiskersObject = null;
  if (ownProps.preview && ownProps.whiskers) {
    whiskersObject = ownProps.whiskers;
  } else if (whiskersConfig.shape !== "0") {
    whiskersObject = WhiskersShapeList[whiskersConfig.shape];
  }
  const colour = whiskersConfig.colour
    ? {
        base: whiskersConfig.colour.lighter,
        outline: whiskersConfig.colour.outline,
      }
    : {
        base: state.config.base.skintone.lighter,
        outline: state.config.base.skintone.outline,
      };

  const transform = !ownProps.preview
    ? translateTransformObject(buildTransform(whiskersConfig.transform))
    : "";

  return {
    colour: colour,
    Whiskers: whiskersObject,
    transform,
  };
}

const Whiskers = (props) => {
  const { colour, Whiskers, transform, previewId } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (Whiskers) {
    return (
      <g
        id={previewId ? `g-whiskers~${previewId}` : "g-whiskers"}
        transform-origin="50% 50%"
        {...transform}
      >
        <Component
          id={previewId ? `whiskers~${previewId}` : "whiskers"}
          component={Whiskers}
          colour={colour}
          strokeOverride={true}
          linework={Whiskers.linework}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Whiskers);
