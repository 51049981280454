import React from "react";
import CharacterHead from "../../CharacterHead";
import HeadScar from "./HeadScar";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <HeadScar scar={component} preview={true} previewId={previewId} />
    </>
  );
};

export const previewViewbox = "150 160 200 180";
