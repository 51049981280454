// Components
import HairColours from "character-creator/colours/hair";
import MoustacheList from "character-creator/components/head/face/facialHair/moustache/MoustacheList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const moustachePath = "base.head.face.moustache.";
const moustacheTransformPath = moustachePath + "transform.";

const MoustachePage = {
  id: "moustachePage",
  name: "Moustache",
  description: "I moustache you which style do you prefer?",
  controls: [
    {
      id: "moustacheShape",
      name: "Moustache",
      description: "",
      dataAttribute: [moustachePath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: MoustacheList,
      colourControls: [
        {
          id: "moustacheColour",
          name: "Moustache Colour",
          description: "",
          dataAttribute: [moustachePath + "colour"],
          variationAttribute: "",
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
      transformControls: [
        {
          id: "moustacheTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [moustacheTransformPath + "y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "moustacheTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [moustacheTransformPath + "scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default MoustachePage;
