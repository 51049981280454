import { createSlice } from '@reduxjs/toolkit';
import { characterConfig } from 'character-creator/utilities/character-config';
import { set } from 'lodash';

const configSlice = createSlice({
  name: "config",
  initialState: characterConfig,
  reducers: {
    updateConfig: (state, action) => {
        set(state, action.payload.attribute, action.payload.value)
      }
    }
})

export const selectConfig = state => state.config;

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
