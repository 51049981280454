import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Eyes from "character-creator/components/head/face/eyes/CharacterEyes";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Eyes preview={true} previewId={previewId} undereye={component} />
    </>
  );
};

export const viewbox = "170 205 80 80";
