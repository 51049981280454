import React from "react";
import {
  viewbox,
  PreviewBase,
} from "character-creator/components/head/ears/Preview";

const ear_shape_TEKWA = {
  name: "Ears 1",
  description: "Smooth human-like ears",
  tags: ["side", "rounded", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M172.46 237.61c-1.33-14.15-32.27-33.73-32.86 5.46-1.71 24.21 21.8 38.87 27.62 36.61 11.14-12.16 9.82-30.51 5.24-42.07z" />
      ),
      basic: {
        dark: (
          <path d="M174.06 273.44c-2.16-5.82 1.22-65.52-6.74-37.11-4.06-11.89-26.06-15.06-21.73-.44 3.72-11.06 20.35-.65 10.33 8.31-6.59 9.68-4.37 28.28 9.76 17.81 4.23 3.39 2.14 12.99 6.46 17.48 3.75 2.97 2.07-4.79 1.92-6.05z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M327.54 237.61c1.33-14.15 32.27-33.73 32.86 5.46 1.71 24.21-21.8 38.87-27.62 36.61-11.14-12.16-9.82-30.51-5.24-42.07z" />
      ),
      basic: {
        dark: (
          <path d="M325.94 273.44c2.16-5.82-1.22-65.52 6.74-37.11 4.06-11.89 26.06-15.06 21.73-.44-3.72-11.06-20.35-.65-10.33 8.31 6.59 9.68 4.37 28.28-9.76 17.81-4.23 3.39-2.14 12.99-6.46 17.48-3.75 2.97-2.07-4.79-1.92-6.05z" />
        ),
      },
    },
  },
};

const ear_shape_0775v = {
  name: "Ears 2",
  description: "Smooth human-like ears with a long dangling lobe",
  tags: ["rounded", "side", "long", "smooth"],
  preview: {
    viewbox: "110 210 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.09 250s-6.1-13.91-10.49-19.82c-4.39-5.91-15.06-9.15-19.83 7.63-4.77 16.78 1.91 23.65 3.43 28.22 1.53 4.58 2.41 17.04 12.59 28.6 8.39 9.53 10.28-17.88 10.28-17.88l4.02-26.75z" />
      ),
      basic: {
        dark: (
          <path d="M158.26 288.29c13.18 4.92 9.92-22.62 7.8-29.46-2.63 2.28-.62 4.96 1.03 6.99-.43 9.53-14.17 2.1-11.73-5.21 8.86 11.17 11.47-5.77 5.64-11.55-2.21-3.4-7.04 1.65-7.72-1.93-1.23-2.82 1.55-4.51 2.02-7.27.23-3.38-7.68 3.47-6.82-1.58 3.24-13.36 12.91-5.32 17.31 4.82.6-3.56 1.73-7.01 3.52-10.15 4.3 8.73-.45 19.83 1.79 29.33.6 5.21 4.94 13.03 1.09 17.39-.5-.65-.84-1.38-1.13-2.15-1.89 24.91-12.34 24.94-20.19 2.17 2.72 2.6 4.03 6.64 7.39 8.6zm-16.13-44.73c1.8 7.79 5.14 14.96 8.21 22.32.15.41-.16.68-.54.81-1.66.75-1.36 2.25-1.69 3.61-3.02-8.19-8.75-17.82-5.98-26.74z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325.91 250s6.1-13.91 10.49-19.82 15.07-9.15 19.83 7.63-1.91 23.65-3.43 28.22c-1.53 4.58-2.41 17.04-12.59 28.6-8.39 9.53-10.28-17.88-10.28-17.88L325.91 250z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M341.74 288.29c-13.18 4.92-9.92-22.62-7.8-29.46 2.63 2.28.62 4.96-1.03 6.99.43 9.53 14.17 2.1 11.73-5.21-8.86 11.17-11.47-5.77-5.64-11.55 2.21-3.4 7.04 1.65 7.72-1.93 1.23-2.82-1.55-4.51-2.02-7.27-.23-3.38 7.68 3.47 6.82-1.58-3.24-13.36-12.91-5.32-17.31 4.82-.6-3.56-1.73-7.01-3.52-10.15-4.3 8.73.45 19.83-1.79 29.33-.6 5.21-4.94 13.03-1.09 17.39.5-.65.84-1.38 1.13-2.15 1.89 24.91 12.34 24.94 20.19 2.17-2.72 2.6-4.03 6.64-7.39 8.6z" />
            <path d="M357.87 243.56c-1.8 7.79-5.14 14.96-8.21 22.32-.15.41.16.68.54.81 1.66.75 1.36 2.25 1.69 3.61 3.02-8.19 8.75-17.82 5.98-26.74z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_HOZ9g = {
  name: "Ears 3",
  description: "Smooth, tall and large double-pointed ears",
  tags: ["pointed", "large", "side", "smooth"],
  preview: {
    viewbox: "80 140 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M184.36 255.54s-44.56-60.95-56.62-95.5c-8.12-23.28-12.86-6.82-9.16 19.08 5.79 40.5-23.63 26.52-10.13 41.47 8.08 8.95 21.22 4.08 29.41 27.05 9.13 25.57 5.06 32.5 34.24 38.05 29.17 5.53 12.26-30.15 12.26-30.15z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M160.87 280.98c2.88 1.41 6.56 2.29 9.18-.11-3.74-16.22-3.8-33.29-.14-49.53 4.49-1.93 1.04 27.72 2.64 32.17.36 4.89.96 9.76 2 14.55.4 1.84 3.99 10.92 2.42 11.78-1.72.94-3.27-.59-4.46-2.61-14.61 1.78-31.17-12.03-30.54-27.26 3.14 9.17 10.13 16.93 18.9 21.01zm-12-46.92c-.11 2.24-1.36 4.49-2.13 6.59-1.24-1.98-.97-4.96-2.06-7.08-1.19-2.71-3.72-4.64-4.96-7.33 2.64 2.96 7.26 4.42 9.15 7.82zm-36.27-13.02c7.91 1.81 15.87-.47 18.49 9.7 1.13 4.06 4.63 8.68 4.78 12.75-7.16-12.83-25.31-14.42-31.37-27.9 1.78 2.81 4.91 4.51 8.1 5.45z" />
            <path d="M139.35 204.81c10.07 17.1 26.27 33.15 27.01 53.97-1.14 1.83-3.98.27-5.74.99-.49.16-1.62 2.19-2.16 2.07-1.28-.28-1.14-3.89-1.38-5.06.3-4.75-10.07-5.37-4.77-9.8 21.85-3.93-11.22-23.56-13.17-33.39-2.47-5.17-9.61-27.06-9.57-32.79 1.89 2.32 2.94 12.14 9.78 24.01z" />
          </>
        ),
        light: (
          <path d="M153.77 265.98c1.19.39 2.49-.1 3.56-.75 1.07-.65 2.07-1.47 3.27-1.82 1.2-.35 2.73-.05 3.29 1.08.37.74.22 1.63 0 2.43-.41 1.49-1.05 2.92-1.88 4.21-.58.9-1.28 1.76-2.23 2.24-1.87.95-4.25.16-5.74-1.32-1.23-1.22-2.7-3.43-3.31-5.06-1.06-2.81 1.57-1.49 3.04-1.01zm-20.85-48.02c-.68-2.36-.84-4.84-1-7.29-1.65 3.58-1.11 7.85.42 11.48 1.53 3.64 3.95 6.81 6.14 10.09 1.58 2.36 3.08 4.84 4 7.54.64 1.89.37 4.87 1.45 6.44 2.51-3.5-.55-10.4-2.35-13.5-2.87-4.93-7.05-9.18-8.66-14.76zm-10.71-69.41c-.25-.31-.53-.64-.92-.71-.88-.18-1.4.97-1.47 1.87-.17 2.51.57 4.98 1.3 7.38 3.37 11.03 6.87 22.13 12.6 32.13 5.37 9.36 12.58 17.54 20.3 25.08-8.96-12.91-14.28-27.93-19.53-42.74-1.55-4.36-3.11-8.74-5.21-12.88-1.89-3.74-4.48-6.87-7.07-10.13z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M315.64 255.54s44.56-60.95 56.62-95.5c8.12-23.28 12.86-6.82 9.16 19.08-5.79 40.5 23.63 26.52 10.13 41.47-8.08 8.95-21.22 4.08-29.41 27.05-9.13 25.57-5.06 32.5-34.24 38.05-29.17 5.53-12.26-30.15-12.26-30.15z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M339.13 280.98c-2.88 1.41-6.56 2.29-9.18-.11 3.74-16.22 3.8-33.29.14-49.53-4.49-1.93-1.04 27.72-2.64 32.17-.36 4.89-.96 9.76-2 14.55-.4 1.84-3.99 10.92-2.42 11.78 1.72.94 3.27-.59 4.46-2.61 14.61 1.78 31.17-12.03 30.54-27.26-3.14 9.17-10.13 16.93-18.9 21.01zm12-46.92c.11 2.24 1.36 4.49 2.13 6.59 1.24-1.98.97-4.96 2.06-7.08 1.19-2.71 3.72-4.64 4.96-7.33-2.64 2.96-7.26 4.42-9.15 7.82zm36.27-13.02c-7.91 1.81-15.87-.47-18.49 9.7-1.13 4.06-4.63 8.68-4.78 12.75 7.16-12.83 25.31-14.42 31.37-27.9-1.78 2.81-4.91 4.51-8.1 5.45z" />
            <path d="M360.65 204.81c-10.07 17.1-26.27 33.15-27.01 53.97 1.14 1.83 3.98.27 5.74.99.49.16 1.62 2.19 2.16 2.07 1.28-.28 1.14-3.89 1.38-5.06-.3-4.75 10.07-5.37 4.77-9.8-21.85-3.93 11.22-23.56 13.17-33.39 2.47-5.17 9.61-27.06 9.57-32.79-1.89 2.32-2.94 12.14-9.78 24.01z" />
          </>
        ),
        light: (
          <path d="M346.23 265.98c-1.19.39-2.49-.1-3.56-.75-1.07-.65-2.07-1.47-3.27-1.82-1.2-.35-2.73-.05-3.29 1.08-.37.74-.22 1.63 0 2.43.41 1.49 1.05 2.92 1.88 4.21.58.9 1.28 1.76 2.23 2.24 1.87.95 4.25.16 5.74-1.32 1.23-1.22 2.7-3.43 3.31-5.06 1.06-2.81-1.57-1.49-3.04-1.01zm20.85-48.02c.68-2.36.84-4.84 1-7.29 1.65 3.58 1.11 7.85-.42 11.48-1.53 3.64-3.95 6.81-6.14 10.09-1.58 2.36-3.08 4.84-4 7.54-.64 1.89-.37 4.87-1.45 6.44-2.51-3.5.55-10.4 2.35-13.5 2.87-4.93 7.05-9.18 8.66-14.76zm10.71-69.41c.25-.31.53-.64.92-.71.88-.18 1.4.97 1.47 1.87.17 2.51-.57 4.98-1.3 7.38-3.37 11.03-6.87 22.13-12.6 32.13-5.37 9.36-12.58 17.54-20.3 25.08 8.96-12.91 14.28-27.93 19.53-42.74 1.55-4.36 3.11-8.74 5.21-12.88 1.89-3.74 4.48-6.87 7.07-10.13z" />
        ),
      },
    },
  },
};

const ear_shape_vBJ7j = {
  name: "Ears 4",
  description: "Smooth sharp-pointed ears that point up and out",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "100 170 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M183.66 283.51c-14.14-69.31 18.99-47.93-49.24-98.62-35.44-24.73 6.18 18.54 10.72 58.11 2.8 32.05 16.51 46.24 38.52 40.51z" />
      ),
      basic: {
        dark: (
          <path d="M175.74 280.82c-6.14-15.78-.82-30.32-2.45-46.86.72-5.69 3.37-11.25 3.21-16.95-3.41-7.77-5.26 4.3-6.4 7.47-8.78-8.99-17.94-19.58-29.18-25.37 3.77 9.4 15.01 15.34 17.01 25.98 2.56 12.17-9.97 35.14 8.84 38.45.75 7.78 2.32 15.48 4.75 22.91 4.48 8.66 6.65-.91 4.22-5.63z" />
        ),
        light: (
          <path d="M161.94 263.6c1.1.63 2.34 1.19 2.95 2.3s-.01 2.91-1.28 2.84c-.48-.03-.9-.32-1.29-.61-4.67-3.48-8.4-8.18-10.84-13.46-1.79-3.88-4.32-10.43-3.08-14.72 1.97 2.35 2.45 6.68 3.47 9.56 1.95 5.49 4.85 11.08 10.07 14.09zm1.39-57.02c1.31 1.24 2.71 2.87 2.26 4.62-4.97-2.6-9.44-6.03-13.98-9.33-6.66-4.85-13.5-9.45-20.5-13.8-2.16-1.34-17.58-8.63-16.6-11.31 2.12-5.78 26.61 11.58 28.56 12.99 7.13 5.15 13.9 10.78 20.26 16.83z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M316.34 283.51c14.14-69.31-18.99-47.93 49.24-98.62 35.44-24.73-6.18 18.54-10.72 58.11-2.8 32.05-16.51 46.24-38.52 40.51z" />
      ),
      basic: {
        dark: (
          <path d="M324.26 280.82c6.14-15.78.82-30.32 2.45-46.86-.72-5.69-3.37-11.25-3.21-16.95 3.41-7.77 5.26 4.3 6.4 7.47 8.78-8.99 17.94-19.58 29.18-25.37-3.77 9.4-15.01 15.34-17.01 25.98-2.56 12.17 9.97 35.14-8.84 38.45-.75 7.78-2.32 15.48-4.75 22.91-4.48 8.66-6.65-.91-4.22-5.63z" />
        ),
        light: (
          <path d="M338.06 263.6c-1.1.63-2.34 1.19-2.95 2.3-.61 1.11.01 2.91 1.28 2.84.48-.03.9-.32 1.29-.61 4.67-3.48 8.4-8.18 10.84-13.46 1.79-3.88 4.32-10.43 3.08-14.72-1.97 2.35-2.45 6.68-3.47 9.56-1.95 5.49-4.85 11.08-10.07 14.09zm-1.39-57.02c-1.31 1.24-2.71 2.87-2.26 4.62 4.97-2.6 9.44-6.03 13.98-9.33 6.66-4.85 13.5-9.45 20.5-13.8 2.16-1.34 17.58-8.63 16.6-11.31-2.12-5.78-26.61 11.58-28.56 12.99-7.13 5.15-13.9 10.78-20.26 16.83z" />
        ),
      },
    },
  },
};

const ear_shape_FYH9c = {
  name: "Ears 5",
  description: "Smooth, wide rounded ears",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.46 252.29s-33.18-43.66-46.91-27.83-11.44 30.51 0 50.34c11.44 19.83 30.51 9.34 41.19 14.87 10.68 5.53 5.72-37.38 5.72-37.38z" />
      ),
      basic: {
        dark: (
          <path d="M160.29 243.26c-.27 6.59-7.47 8.22-12.75 5.84-19.7-11.92-2.04-35 19.12-12.64-.21-7.1 7.02-11.19 6.59-1.69-2.36 13.39 1.11 26.73 1.8 39.9-.46-.48-.95-.94-1.46-1.37.26 5.3 4.7 11.02 3.21 16.07-6.96 1.15-17.6 1.9-23.24-3.52 9.48 2.48 17.53-6.14 12.8-14.86-6.72 2.29-15.99 8.57-19.47-1.59-4.18-11.07 17.59-16.24 13.28-28.32.12.73.17 1.45.12 2.18z" />
        ),
        light: (
          <path d="M155.47 273.33c1.84-1.11 3.68-3.02 5.75-2.43 1.61.46 2.45 2.36 2.28 4.03-.34 3.45-4.03 5.83-7.5 5.77s-6.63-1.96-9.32-4.15c-2.6-2.11-4.99-4.61-6.36-7.67-.37-.83-.67-1.74-.53-2.63.14-.9.86-1.76 1.77-1.79 1.43-.05 2.12 1.67 2.54 3.03 1.61 5.24 5.97 9.1 11.37 5.84zm9.33-42.85c-.27.25-.7.14-1.04.02-3.94-1.46-7.6-3.58-11.58-4.91-2.03-.68-4.13-1.18-6.29-1.2-1.39-.02-5.04 1.06-6.15.05-1.8-1.63 2.78-3 4.07-3.31 3.41-.81 7.2.08 10.4 1.34 4.18 1.62 7.91 4.4 10.59 8.01z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.54 252.29s33.18-43.66 46.91-27.83c13.73 15.83 11.44 30.51 0 50.34-11.44 19.83-30.51 9.34-41.19 14.87-10.68 5.53-5.72-37.38-5.72-37.38z" />
      ),
      basic: {
        dark: (
          <path d="M339.71 243.26c.27 6.59 7.47 8.22 12.75 5.84 19.7-11.92 2.04-35-19.12-12.64.21-7.1-7.02-11.19-6.59-1.69 2.36 13.39-1.11 26.73-1.8 39.9.46-.48.95-.94 1.46-1.37-.26 5.3-4.7 11.02-3.21 16.07 6.96 1.15 17.6 1.9 23.24-3.52-9.48 2.48-17.53-6.14-12.8-14.86 6.72 2.29 15.99 8.57 19.47-1.59 4.18-11.07-17.59-16.24-13.28-28.32-.12.73-.17 1.45-.12 2.18z" />
        ),
        light: (
          <path d="M344.53 273.33c-1.85-1.11-3.68-3.02-5.75-2.43-1.61.46-2.45 2.36-2.28 4.03.34 3.45 4.03 5.83 7.5 5.77 3.46-.06 6.63-1.96 9.32-4.15 2.6-2.11 4.99-4.61 6.36-7.67.37-.83.67-1.74.53-2.63-.14-.9-.86-1.76-1.77-1.79-1.43-.05-2.12 1.67-2.54 3.03-1.61 5.24-5.97 9.1-11.37 5.84zm-9.33-42.85c.27.25.7.14 1.04.02 3.94-1.46 7.6-3.58 11.58-4.91 2.03-.68 4.13-1.18 6.29-1.2 1.39-.02 5.04 1.06 6.15.05 1.8-1.63-2.78-3-4.07-3.31-3.41-.81-7.2.08-10.4 1.34-4.18 1.62-7.91 4.4-10.59 8.01z" />
        ),
      },
    },
  },
};

const ear_shape_aoGZG = {
  name: "Ears 6",
  description: "Smooth, very wide rounded ears",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M180.72 253.12s-20.29-48.12-41.9-41.57c-21.6 6.55-29.79 20.61-23.9 38.46 7.37 21.77 90.27 52.97 65.8 3.11z" />
      ),
      basic: {
        dark: (
          <path d="M172.67 265.28c-1.5-6.3 1.87-54.72-5.12-26.87-6.77-7.92-23.53-27.41-33.24-14.6 8.09-.27 15.28 1.32 20.49 8.5.75.67.94 1.68-.04 2.17-15.64 11.11-3.23 38.76 14.02 39.38 6.28 21.4 6.82-.81 3.89-8.58z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M319.28 253.12s20.29-48.12 41.9-41.57c21.6 6.55 29.79 20.61 23.9 38.46-7.37 21.77-90.27 52.97-65.8 3.11z" />
      ),
      basic: {
        dark: (
          <path d="M327.33 265.28c1.5-6.3-1.87-54.72 5.12-26.87 6.77-7.92 23.53-27.41 33.24-14.6-8.09-.27-15.28 1.32-20.49 8.5-.75.67-.94 1.68.04 2.17 15.64 11.11 3.23 38.76-14.02 39.38-6.28 21.4-6.82-.81-3.89-8.58z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_ZgR2p = {
  name: "Ears 7",
  description: "Smooth, long and very wide pointed ears",
  tags: ["pointed", "side", "long", "smooth"],
  preview: {
    viewbox: "60 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M192.72,257.37c0,0-30.11-32.9-59.25-34.7c-29.13-1.8-54.5,2.78-59.9,7.53c-4.02,6.41,25.45,11.43,31.87,17.63c30.6,25.46,48.41,35.41,83.35,31.14L192.72,257.37z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M174.14,270.15c4.33-3.11,1.14-9.07-1.05-12.84c-1.74-5.88,2.39-24.49-4.73-25.4c-2,5.44-3.01,11.11-3.29,16.86c-9.48-10.78-23.36-17.63-37.71-18.42c-1.19-0.25-1.82,1.17-1.16,2.13c4.2,2.96,10.64,2.94,13.87,7.37c4.54,5.51,2.41,13.91,5.08,20.54c3.48,8.55,13.57,11.97,22.24,11.19c1.31,4.02,1.5,9.03,4.45,12.18c178.19,288.01,173.78,272.79,174.14,270.15z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M307.28,257.37c0,0,30.11-32.9,59.25-34.7c29.13-1.8,54.5,2.78,59.9,7.53c4.02,6.41-25.45,11.43-31.87,17.63c-30.6,25.46-48.41,35.41-83.35,31.14L307.28,257.37z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M325.86,270.15c-4.33-3.11-1.14-9.07,1.05-12.84c1.74-5.88-2.39-24.49,4.73-25.4c2,5.44,3.01,11.11,3.29,16.86c9.48-10.78,23.36-17.63,37.71-18.42c1.19-0.25,1.82,1.17,1.16,2.13c-4.2,2.96-10.64,2.94-13.87,7.37c-4.54,5.51-2.41,13.91-5.08,20.54c-3.48,8.55-13.57,11.97-22.24,11.19c-1.31,4.02-1.5,9.03-4.45,12.18C321.81,288.01,326.22,272.79,325.86,270.15z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_vdyix = {
  name: "Ears 8",
  description: "Smooth, long drooping ears",
  tags: ["long", "side", "top", "smooth"],
  preview: {
    viewbox: "90 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M197.79,203.36c-72.45-55.65-56.34,49.63-79.54,96.56c-11.46,24.88,21.11,12.27,29.78,0C165.91,279.95,181.33,217.15,197.79,203.36z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M187.35,193.02c-2.26-7.17-15.43,21.86-15.83,24.32c-14.49,22.35-20.09,49.22-32.1,72.9c-0.55,1.1-1.1,2.44-0.48,3.5c2.33,2.77,5.89-0.69,7.32-2.9c7.48-10.01,14.74-20.24,20.65-31.23c0.27,1.99,1.92,9.11,4.65,5.61c0.73-1.27-0.42-5.5-0.47-7.02c-0.08-2.44-0.02-4.89,0.15-7.33c4.5-11.08,9.38-23.54,7.97-35.55c1.56-4.88,2.96-9.9,5.51-14.37c186.12,198.47,187.97,195.81,187.35,193.02z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M302.21,203.36c72.45-55.65,56.34,49.63,79.54,96.56c11.46,24.88-21.11,12.27-29.78,0C334.09,279.95,318.67,217.15,302.21,203.36z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M312.65,193.02c2.26-7.17,15.43,21.86,15.83,24.32c14.49,22.35,20.09,49.22,32.1,72.9c0.55,1.1,1.1,2.44,0.48,3.5c-2.33,2.77-5.89-0.69-7.32-2.9c-7.48-10.01-14.74-20.24-20.65-31.23c-0.27,1.99-1.92,9.11-4.65,5.61c-0.73-1.27,0.42-5.5,0.47-7.02c0.08-2.44,0.02-4.89-0.15-7.33c-4.5-11.08-9.38-23.54-7.97-35.55c-1.56-4.88-2.96-9.9-5.51-14.37C313.88,198.47,312.03,195.81,312.65,193.02z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_TbLPe = {
  name: "Ears 9",
  description: "Smooth, large pointed ears on the top of the head",
  tags: ["pointed", "top", "smooth"],
  preview: {
    viewbox: "110 110 120 120",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M204.47 189.86c1.78-10.75-9.46-27.98-21.36-36.25-15.39-9.82-25.7-15.06-32.41-28.15s-14.89 4.91-15.38 26.84c-1.88 29.07 8.87 43.11 27.64 54.88 5.9 4.51 19.65.78 26.33.62 9.81-1.04 14.18-8.9 15.18-17.94z" />
      ),
      basic: {
        dark: (
          <path d="M203.39 172.86c-1.76-1.27-4.14.28-5.7 1.79-5.51 1.85-10.26-12.36-18.28-12.01-24.6-5.96-19.63 34.87-2.55 39.13-3.88 4.48-4.43 20.47 3.16 10.29 5.13-13.6 24.54-25.32 23.37-39.2z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M295.53 189.86c-1.78-10.75 9.46-27.98 21.36-36.25 15.39-9.82 25.7-15.06 32.41-28.15s14.89 4.91 15.38 26.84c1.88 29.07-8.87 43.11-27.64 54.88-5.9 4.51-19.65.78-26.33.62-9.81-1.04-14.18-8.9-15.18-17.94z" />
      ),
      basic: {
        dark: (
          <path d="M296.61 172.86c1.76-1.27 4.14.28 5.7 1.79 5.51 1.85 10.26-12.36 18.28-12.01 24.6-5.96 19.63 34.87 2.55 39.13 3.88 4.48 4.43 20.47-3.16 10.29-5.13-13.6-24.54-25.32-23.37-39.2z" />
        ),
      },
    },
  },
};

const ear_shape_3wnpn = {
  name: "Ears 10",
  description: "Smooth, large round ears on the top of the head",
  tags: ["rounded", "large", "top", "smooth"],
  preview: {
    viewbox: "110 110 120 120",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M129.35 169.21c0 21.27 16.5 32.11 34.37 38.51 18 6.45 33.06 11.82 34.37-38.51 1.3-49.92-69.59-50.04-68.74 0z" />
      ),
      basic: {
        dark: (
          <path d="M200.96 176.58c-1.42-4.06-6.29 3.66-8.04 4.85-11.39-49.86-64.66 11.61-16.31 23.22-1.14 2.73-3.44 5.54-3.02 8.6 1.66 6.93 8.61-5.71 9.53-7.09 5.4-9.92 15.19-18.67 17.84-29.58z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M370.65 169.21c0 21.27-16.5 32.11-34.37 38.51-18 6.45-33.06 11.82-34.37-38.51-1.3-49.92 69.59-50.04 68.74 0z" />
      ),
      basic: {
        dark: (
          <path d="M299.04 176.58c1.42-4.06 6.29 3.66 8.04 4.85 11.39-49.86 64.66 11.61 16.31 23.22 1.14 2.73 3.44 5.54 3.02 8.6-1.66 6.93-8.61-5.71-9.53-7.09-5.4-9.92-15.19-18.67-17.84-29.58z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_PDULw = {
  name: "Ears 11",
  description: "Smooth, small finned ears with three points",
  tags: ["pointed", "side", "finned", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M182.7,247.37c0,0-43.1-35.11-53.46-36.73c-13.55-2.12-4.34,4.52-0.36,19.51c3.98,15-11.08,14.9-11.07,21.32c-0.66,10.32,22.77,9.64,14.44,26.49c-1.2,2.59,0.19,5.71,2.98,6.33c4.38,0.97,32.54-3.69,47.47-18.02V247.37z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M171.49,261.13c0.42-7.47,3.21-47.18-5.12-19.35c-3.31-2.88-30.41-23.21-26.49-15.66c4.12,3.13,7.75,6.89,10.73,11.12c5.4,6.04,2.3,13.19-1.85,19.1c-4.59,6.34-2.18,16.62,6.78,14.4c4.28-0.62,6.79-5.44,10.86-6.09c169.54,289.57,176.14,279.22,171.49,261.13z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M317.3,247.37c0,0,43.1-35.11,53.46-36.73c13.55-2.12,4.34,4.52,0.36,19.51s11.08,14.9,11.07,21.32c0.66,10.32-22.77,9.64-14.44,26.49c1.2,2.59-0.19,5.71-2.98,6.33c-4.38,0.97-32.54-3.69-47.47-18.02V247.37z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M328.51,261.13c-0.42-7.47-3.21-47.18,5.12-19.35c3.31-2.88,30.41-23.21,26.49-15.66c-4.12,3.13-7.75,6.89-10.73,11.12c-5.4,6.04-2.3,13.19,1.85,19.1c4.59,6.34,2.18,16.62-6.78,14.4c-4.28-0.62-6.79-5.44-10.86-6.09C330.46,289.57,323.86,279.22,328.51,261.13z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_kOBSt = {
  name: "Ears 12",
  description: "Smooth, tall finned ears with three points",
  tags: ["pointed", "side", "finned", "smooth"],
  preview: {
    viewbox: "100 180 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M174.52 246.26c1.3 2.55-48.16-96.64-37.23-43.01 3.98 15 8.91 25.81-11.92 38.31-11.06 7.37 26.66 7.58 23.43 32.53 0 0-1.2 18.97 25.72-6.87v-20.96z" />
      ),
      basic: {
        dark: (
          <path d="M172 262.52c-.52-6.61-.56-13.25-.14-19.87.28-4.29.68-8.9-1.51-12.6-1.6 3.78-2.79 7.73-3.56 11.76-5.23-8.32-10.95-16.34-16.37-24.54.04 6.81 4.75 12.83 5.38 19.61.99 8.48-5.69 17.86-.77 25.81 1.19 1.59 3.22 2.65 5.17 2.29 2.36-.57 4.3-2.63 6.6-.74.29 1.88.61 3.76.94 5.64.26 1.47.55 3.03 1.57 4.12 2.31 2.48 3.76.47 3.78-1.76.05-3.16-.84-6.55-1.09-9.72z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M325.48 246.26c-1.3 2.55 48.16-96.64 37.23-43.01-3.98 15-8.91 25.81 11.92 38.31 11.06 7.37-26.66 7.58-23.43 32.53 0 0 1.2 18.97-25.72-6.87v-20.96z" />
      ),
      basic: {
        dark: (
          <path d="M328 262.52c.52-6.61.56-13.25.14-19.87-.28-4.29-.68-8.9 1.51-12.6 1.6 3.78 2.79 7.73 3.56 11.76 5.23-8.32 10.95-16.34 16.37-24.54-.04 6.81-4.75 12.83-5.38 19.61-.99 8.48 5.69 17.86.77 25.81-1.19 1.59-3.22 2.65-5.17 2.29-2.36-.57-4.3-2.63-6.6-.74-.29 1.88-.61 3.76-.94 5.64-.26 1.47-.55 3.03-1.57 4.12-2.31 2.48-3.76.47-3.78-1.76-.05-3.16.84-6.55 1.09-9.72z" />
        ),
      },
    },
  },
};

const ear_shape_yAT34 = {
  name: "Ears 13",
  description: "Smooth, large and wide pointed ears that droop down",
  tags: ["pointed", "side", "drooping", "large", "smooth"],
  preview: {
    viewbox: "80 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.04 244.84c-.08-1.23-.15-2.45-.19-3.67-.19-5.03-.02-10.57.67-15.98 0-1.01.01-2.02.01-3.03-.01-5.14-1.45-9.84-2.26-14.75-14.29-3.45-31.23-1.67-42.92 6.8-11.46 8.33-41.11 50.72-42.35 94.08-.35 12.12 15.97-9.72 29.51-10.07 13.54-.35 12.5 11.98 30.9-6.94 7.89-6.78 14.97-10.96 21.22-14.48 3.32-10.44 4.85-21.05 5.41-31.96z" />
      ),
      basic: {
        dark: (
          <path d="M182.35 204.02c-7.83-2.79-10.5 10.27-12.57 15.8-18.91 2.78-46.55 38.88-44.84 57.34 25.25-39.65 20.5-6.61 40.79-16.35 1.42 8.25 1.52 18.36 6.69 25.12 5.73 3.42 2.57-9.97 2.34-11.78-.81-6.4-1.46-12.83-1.7-19.28-.47-12.69.65-25.58 5.19-37.53 1.4-4.34 4.35-8.75 4.1-13.32z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.96 244.84c.08-1.23.15-2.45.19-3.67.19-5.03.02-10.57-.67-15.98 0-1.01-.01-2.02-.01-3.03.01-5.14 1.45-9.84 2.26-14.75 14.29-3.45 31.23-1.67 42.92 6.8 11.46 8.33 41.11 50.72 42.35 94.08.35 12.12-15.97-9.72-29.51-10.07-13.54-.35-12.5 11.98-30.9-6.94-7.89-6.78-14.97-10.96-21.22-14.48-3.32-10.44-4.85-21.05-5.41-31.96z" />
      ),
      basic: {
        dark: (
          <path d="M317.65 204.02c7.83-2.79 10.5 10.27 12.57 15.8 18.91 2.78 46.55 38.88 44.84 57.34-25.25-39.65-20.5-6.61-40.79-16.35-1.42 8.25-1.52 18.36-6.69 25.12-5.73 3.42-2.57-9.97-2.34-11.78.81-6.4 1.46-12.83 1.7-19.28.47-12.69-.65-25.58-5.19-37.53-1.4-4.34-4.35-8.75-4.1-13.32z" />
        ),
      },
    },
  },
};

const ear_shape_8wAS4 = {
  name: "Ears 14",
  description: "Smooth, wide ears with a long dangling lobe",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: "100 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M181.5 247.72s-23.65-38.71-39.28-27.46c-15.64 11.25-28.99 16.64-16.02 49.96 3.26 8.38 5.7.26 18.31 25.93 9.34 19.03 28.48-1.75 26.56-19.43-.77-7.03 10.43-29 10.43-29z" />
      ),
      basic: {
        dark: (
          <path d="M173.79 265.4c-.58-6.18 2.73-51.43-5.77-31.7-10.93-9.09-27.52-11.09-34.32 3.46-.08.81.57 1.73 1.38 1.6 14.71-18.41 30.18 8.4 14.75 20.93-7.51 5.69-6.5 20.01 2.64 22.88 3.85-.65 3.57-5.57 4.81-8.43 8-10.93 10.86 4.09 13.61 10.1 6.06-5.2 2.92-11.63 2.9-18.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M318.5 247.72s23.65-38.71 39.28-27.46c15.64 11.25 28.99 16.64 16.02 49.96-3.26 8.38-5.7.26-18.31 25.93-9.34 19.03-28.48-1.75-26.56-19.43.77-7.03-10.43-29-10.43-29z" />
      ),
      basic: {
        dark: (
          <path d="M326.21 265.4c.58-6.18-2.73-51.43 5.77-31.7 10.93-9.09 27.52-11.09 34.32 3.46.08.81-.57 1.73-1.38 1.6-14.71-18.41-30.18 8.4-14.75 20.93 7.51 5.69 6.5 20.01-2.64 22.88-3.85-.65-3.57-5.57-4.81-8.43-8-10.93-10.86 4.09-13.61 10.1-6.06-5.2-2.92-11.63-2.9-18.84z" />
        ),
      },
    },
  },
};

// TODO: Tweak right manually - looked strange on SVGomg
const ear_shape_gcoC9 = {
  name: "Ears 15",
  description: "Smooth, wide ears with a slight point",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "90 200 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M187.51 245.81s-32.8-37.38-70.18-19.45c-8.82 4.23-10.01 7.63 12.97 40.05 37.15 51.72 49.77-3.78 57.21-20.6z" />
      ),
      basic: {
        dark: (
          <path d="M176 279.78c-2.14-12.51-3.22-25.21-3.23-37.9 0-2.77 1.98-13.57-1.11-14.73-3.71-1.39-5.3 5.41-5.89 9.55-6.21-4.5-28.93-13.13-34.78-7.29 36.84-.49 6.66 33.35 17.02 43.56 7.66 5.7 12-6.43 18.41-7.33 1.09 7.26 2.03 16.3 8.47 20.62 1.76-1.49 1.49-4.21 1.11-6.48z" />
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M312.49,245.81c0,0,32.8-37.38,70.18-19.45c8.82,4.23,10.01,7.63-12.97,40.05C332.55,318.13,319.93,262.63,312.49,245.81z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324,279.78c2.14-12.51,3.22-25.21,3.23-37.9c0-2.77-1.98-13.57,1.11-14.73c3.71-1.39,5.3,5.41,5.89,9.55c6.21-4.5,28.93-13.13,34.78-7.29c-36.84-0.49-6.66,33.35-17.02,43.56c-7.66,5.7-12-6.43-18.41-7.33c-1.09,7.26-2.03,16.3-8.47,20.62c323.35,284.77,323.62,282.05,324,279.78z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_bPDFx = {
  name: "Ears 16",
  description: "Smooth, small ears with a slight point that point upwards",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "110 190 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M176.01 250c-4.34-20.92-64.61-99.06-34.7 2.48 11.85 42.94 47.99 29.25 34.7-2.48z" />
      ),
      basic: {
        dark: (
          <path d="M175.05 279.45c-3.65-14.96-2.45-30.34-2.38-45.53-.15-4.23-4.02-3.05-4.54.61-.52 2.49-.95 5.01-1.3 7.54-4.75-12.6-13.01-26.72-25.46-32.51 20.85 19.68-.34 52.86 17.15 57.43 2.73.2 5.84-1.4 8.09-.08 1.37 5.69 1.55 13.55 6.33 17.42 4.07 1.63 2.48-3.27 2.11-4.88z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M323.99 250c4.34-20.92 64.61-99.06 34.7 2.48-11.85 42.94-47.99 29.25-34.7-2.48z" />
      ),
      basic: {
        dark: (
          <path d="M324.95 279.45c3.65-14.96 2.45-30.34 2.38-45.53.15-4.23 4.02-3.05 4.54.61.52 2.49.95 5.01 1.3 7.54 4.75-12.6 13.01-26.72 25.46-32.51-20.85 19.68.34 52.86-17.15 57.43-2.73.2-5.84-1.4-8.09-.08-1.37 5.69-1.55 13.55-6.33 17.42-4.07 1.63-2.48-3.27-2.11-4.88z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_oEAG6 = {
  name: "Ears 17",
  description: "Smooth, small ears with a slight point",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M174.6,261.03c-0.66-8.82-0.91-19.63,2.69-28.29c-14.65-2.82-33.82-6-42.42-5.23c-14.87,1.33,6.1,18.5,11.44,33.56c7.69,17.63,17.35,35.06,32.25,21.54C175.17,276.5,175.01,266.41,174.6,261.03z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M174.6,261.03c-3-11.42-3.38-22.02-0.77-33.5c-0.45-0.74-1.03-1.4-1.81-1.91c-2.59,1.45-3.7,4.56-4.44,7.43c-0.28,1.07-0.53,2.15-0.76,3.24c-2.24-1.11-4.72-1.67-7.19-2.07c-6.12-0.98-12.4-1.02-18.53-0.13c0.73,2.02,3.19,2.64,5.13,3.55c4.08,1.92,6.7,6.09,7.97,10.41c1.27,4.32,1.4,8.88,1.83,13.37c0.22,2.37,0.56,4.82,1.81,6.85c1.24,2.03,3.63,3.55,5.97,3.08c0.96-0.2,1.89-0.71,2.87-0.6c0.19,0.02,0.36,0.07,0.53,0.13c0.77,3.32,1.73,6.6,2.92,9.8c1.01,2.71,7.41,17.89,7.9,7.98c0.02-0.38,0.03-0.76,0.03-1.13c172.62,275.76,177.63,272.54,174.6,261.03z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M325.4,261.03c0.66-8.82,0.91-19.63-2.69-28.29c14.65-2.82,33.82-6,42.42-5.23c14.87,1.33-6.1,18.5-11.44,33.56c-7.69,17.63-17.35,35.06-32.25,21.54C324.83,276.5,324.99,266.41,325.4,261.03z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M325.4,261.03c3-11.42,3.38-22.02,0.77-33.5c0.45-0.74,1.03-1.4,1.81-1.91c2.59,1.45,3.7,4.56,4.44,7.43c0.28,1.07,0.53,2.15,0.76,3.24c2.24-1.11,4.72-1.67,7.19-2.07c6.12-0.98,12.4-1.02,18.53-0.13c-0.73,2.02-3.19,2.64-5.13,3.55c-4.08,1.92-6.7,6.09-7.97,10.41c-1.27,4.32-1.4,8.88-1.83,13.37c-0.22,2.37-0.56,4.82-1.81,6.85c-1.24,2.03-3.63,3.55-5.97,3.08c-0.96-0.2-1.89-0.71-2.87-0.6c-0.19,0.02-0.36,0.07-0.53,0.13c-0.77,3.32-1.73,6.6-2.92,9.8c-1.01,2.71-7.41,17.89-7.9,7.98c-0.02-0.38-0.03-0.76-0.03-1.13C327.38,275.76,322.37,272.54,325.4,261.03z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_Dp6oZ = {
  name: "Ears 18",
  description:
    "Smooth, wide ears with a slight point that reach slightly upwards",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M187.7 261.97s-17.35-33.18-40.05-47.67c-22.69-14.49-12.97 35.09-8.77 47.67s24.03 28.22 33.56 29.37c9.53 1.14 20.21-16.4 20.21-16.4l-4.95-12.97z" />
      ),
      basic: {
        dark: (
          <path d="M176.9 284.23c-1.21-4.1-2.79-8.36-3.01-12.36-5.07-11.16-4.58-23.8-2-35.8-.77-5.21-4.28-6.51-5.24.94-6.7-4.73-12.26-14.39-20.9-14.87 7.8 8.65 11.84 19.51 7.87 30.8-4.48 11.03 2.28 25.96 15.55 22.1 1.86 6.36 2.46 13.96 7.19 18.92 5.6 3.18 1.27-7.35.54-9.73z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M312.3 261.97s17.35-33.18 40.05-47.67c22.69-14.49 12.97 35.09 8.77 47.67s-24.03 28.22-33.56 29.37c-9.53 1.14-20.21-16.4-20.21-16.4l4.95-12.97z" />
      ),
      basic: {
        dark: (
          <path d="M323.1 284.23c1.21-4.1 2.79-8.36 3.01-12.36 5.07-11.16 4.58-23.8 2-35.8.77-5.21 4.28-6.51 5.24.94 6.7-4.73 12.26-14.39 20.9-14.87-7.8 8.65-11.84 19.51-7.87 30.8 4.48 11.03-2.28 25.96-15.55 22.1-1.86 6.36-2.46 13.96-7.19 18.92-5.6 3.18-1.27-7.35-.54-9.73z" />
        ),
      },
    },
  },
};

const ear_shape_kf82L = {
  name: "Ears 19",
  description: "Smooth, large and wavy ears on the top of the head",
  tags: ["top", "smooth"],
  preview: {
    viewbox: "90 75 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M190.02 199.18c6.73-12.77 5.63-31.4-3.39-51.5-11.56-20.76-20.92-22.87-21.08-42.29-.17-21.97-16.79-27.31-19.66 15.27-1.12 16.65-32.2-10.79-14.84 37.49 7.97 22.18-16.07 7.49-4.46 31.07 10.25 20.83 34.12 16.29 48.76 17 5.78.32 11.22-2.46 14.67-7.04z" />
      ),
      basic: {
        dark: (
          <path d="M194.86 177.98c-2.61.55-4.42 2.9-6.06 4.93-1.32-13.62-14.29-32.58-29.41-23.48-17.13 15.81-8.48 45.96 17.62 39.1-4.98 6.32-4.91 16.44 4.49 8.6 7.3-7.84 14.42-17.95 13.36-29.15z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M309.98 199.18c-6.73-12.77-5.63-31.4 3.39-51.5 11.56-20.76 20.92-22.87 21.08-42.29.17-21.97 16.79-27.31 19.66 15.27 1.12 16.65 32.2-10.79 14.84 37.49-7.97 22.18 16.07 7.49 4.46 31.07-10.25 20.83-34.12 16.29-48.76 17-5.78.32-11.22-2.46-14.67-7.04z" />
      ),
      basic: {
        dark: (
          <path d="M305.14 177.98c2.61.55 4.42 2.9 6.06 4.93 1.32-13.62 14.29-32.58 29.41-23.48 17.13 15.81 8.48 45.96-17.62 39.1 4.98 6.32 4.91 16.44-4.49 8.6-7.3-7.84-14.42-17.95-13.36-29.15z" />
        ),
      },
    },
  },
};

const ear_shape_8sDmX = {
  name: "Ears 20",
  description:
    "Smooth, wide ears that droop downwards from the top of the head",
  tags: ["rounded", "side", "top", "drooping", "large", "smooth"],
  preview: {
    viewbox: "90 160 190 190",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M178.56 278.33c-2.12-25.35-.58-52.23 10.44-75.54 4.04-8.17 10.21-14.77 13.01-23.45-3.78-7.17-9.98-11.56-19.6-6.75-72.04 27.87-83.25 259.31-3.57 117.61-.58-3.92-.2-7.84-.28-11.87z" />
      ),
      basic: {
        dark: (
          <path d="M203.4 174.95c-13.18-5.84-40.86 53.43-44.22 64.97-4.69 16.25-18.7 54.51-7.77 68.37 9.77 1.34 17.32-8.75 20.8-17.17 6.74 14.21 11.83 6.91 5.84-3.64-4.3-11.85-2-24.65-2.44-37.07.86-14.74 3.24-25.84 8.23-40.39 3.92-8.71 8.81-16.99 14.49-24.66 1.87-3.14 6.1-6.51 5.07-10.41z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M321.44 278.33c2.12-25.35.58-52.23-10.44-75.54-4.04-8.17-10.21-14.77-13.01-23.45 3.78-7.17 9.98-11.56 19.6-6.75 72.04 27.87 83.25 259.31 3.57 117.61.58-3.92.2-7.84.28-11.87z" />
      ),
      basic: {
        dark: (
          <path d="M296.6 174.95c13.18-5.84 40.86 53.43 44.22 64.97 4.69 16.25 18.7 54.51 7.77 68.37-9.77 1.34-17.32-8.75-20.8-17.17-6.74 14.21-11.83 6.91-5.84-3.64 4.3-11.85 2-24.65 2.44-37.07-.86-14.74-3.24-25.84-8.23-40.39-3.92-8.71-8.81-16.99-14.49-24.66-1.87-3.14-6.1-6.51-5.07-10.41z" />
        ),
      },
    },
  },
};

const ear_shape_k9vVR = {
  name: "Ears 21",
  description: "Smooth, large finned ears with three sharp points",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "100 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M182.05 292.04c-15.38-31.14-3.67-64.83 4.01-94.61-25.29-2.05-17.7 36.55-56.96 24.64-23.63-5.74 30.38 14.52-8.65 45.24-4.14 3.26 33.63 4.39 28.9 27.01-5.29 25.33-1.23-4.56 39.7 16.35.41-3.39-6.3-16.84-7-18.63z" />
      ),
      basic: {
        dark: (
          <path d="M186.51 303.37c-20.53-33.61-14.43-74.59 1.24-107.74-8.16-3.17-12.92 17.75-16.13 23.55-17.69 15.77-21.87 39.02-11.61 60.37 2.92 5.72 7.69 10.71 13.56 13.14 2.76 6.18 5.42 12.81 9.85 17.97 7.06 3.1 7.44-2.91 3.09-7.29z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M317.95 292.04c15.38-31.14 3.67-64.83-4.01-94.61 25.29-2.05 17.7 36.55 56.96 24.64 23.63-5.74-30.38 14.52 8.65 45.24 4.14 3.26-33.63 4.39-28.9 27.01 5.29 25.33 1.23-4.56-39.7 16.35-.41-3.39 6.3-16.84 7-18.63z" />
      ),
      basic: {
        dark: (
          <path d="M313.49 303.37c20.53-33.61 14.43-74.59-1.24-107.74 8.16-3.17 12.92 17.75 16.13 23.55 17.69 15.77 21.87 39.02 11.61 60.37-2.92 5.72-7.69 10.71-13.56 13.14-2.76 6.18-5.42 12.81-9.85 17.97-7.06 3.1-7.44-2.91-3.09-7.29z" />
        ),
      },
    },
  },
};

const ear_shape_aKjrI = {
  name: "Ears 22",
  description: "Smooth, large finned ears with three rounded points",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "100 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M193.51 307.16c-11.91-25.07-19.04-52.46-11.76-80.03 1.37-9.57 10.29-19.08 7.05-28.82-5.82-4.11-15.4-1.28-19.78 4.91-30.4 42.33-42.83 18.62-43.29 29.5-.6 14.5 10.98 14.21 11.54 24.4 1.15 21.22-18.47 8.77-12.83 20.09 4.62 9.28 10.96 5.96 19.72 19.85 3.67 5.83-11.78 10.57 3.02 16.61 8.41 3.43 15.52-12.59 44.01-1.46 1.1-1.69 1.96-3.1 2.32-5.05z" />
      ),
      basic: {
        dark: (
          <path d="M185.2 295.84c-14.66-30.6-13.85-67.22 2.3-97.03 1.34-2.85-.26-6.85-3.24-4.4-9.39 8.18-12.43 21.73-15.63 33.32-24.16 17.2-21.35 50.71 4.48 64.71 2.39 5.38 9.82 27.51 17.67 18.15 2.01-3.93-3.99-11.3-5.58-14.75z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M306.49 307.16c11.91-25.07 19.04-52.46 11.76-80.03-1.37-9.57-10.29-19.08-7.05-28.82 5.82-4.11 15.4-1.28 19.78 4.91 30.4 42.33 42.83 18.62 43.29 29.5.6 14.5-10.98 14.21-11.54 24.4-1.15 21.22 18.47 8.77 12.83 20.09-4.62 9.28-10.96 5.96-19.72 19.85-3.67 5.83 11.78 10.57-3.02 16.61-8.41 3.43-15.52-12.59-44.01-1.46-1.1-1.69-1.96-3.1-2.32-5.05z" />
      ),
      basic: {
        dark: (
          <path d="M314.8 295.84c14.66-30.6 13.85-67.22-2.3-97.03-1.34-2.85.26-6.85 3.24-4.4 9.39 8.18 12.43 21.73 15.63 33.32 24.16 17.2 21.35 50.71-4.48 64.71-2.39 5.38-9.82 27.51-17.67 18.15-2.01-3.93 3.99-11.3 5.58-14.75z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_2rsVH = {
  name: "Ears 23",
  description: "Smooth, wide rounded ears that droop slightly downwards",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: "100 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M177.99,251.93c-3.21-11.98,1.42-24.06,0.53-35.81c-32.97-30.25-66.9,34.68-56.09,56.27c8.94,12.08,10.96,0.57,20.36,6.84c9.4,6.27,12.54,6.87,34.47,1.78C185.38,276.46,177.96,260.16,177.99,251.93z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M173.88,210.32c-2.57,1.88-3.38,6.83-4.68,9.99c-0.26,0.62-0.9,1-1.57,0.94c-14.64-1.36-26.59,20.11-29.6,34.89c-0.33,1.61,1.74,2.56,2.78,1.3c15.87-19.18,10.48,10.05,22.34,7.75c0.83-0.16,1.64,0.39,1.81,1.22c1.41,6.8,2.5,22.65,10.05,18.4c0.55-0.31,0.86-0.92,0.79-1.55c-0.78-7.28-2.44-14.99-2.17-22.5c0-0.09,0-0.18-0.02-0.27c-2.71-15.89,7.16-34.91,2.73-49.34c176.02,210.1,174.76,209.68,173.88,210.32z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M322.01,251.93c3.21-11.98-1.42-24.06-0.53-35.81c32.97-30.25,66.9,34.68,56.09,56.27c-8.94,12.08-10.96,0.57-20.36,6.84c-9.4,6.27-12.54,6.87-34.47,1.78C314.62,276.46,322.04,260.16,322.01,251.93z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M326.12,210.32c2.57,1.88,3.38,6.83,4.68,9.99c0.26,0.62,0.9,1,1.57,0.94c14.64-1.36,26.59,20.11,29.6,34.89c0.33,1.61-1.74,2.56-2.78,1.3c-15.87-19.18-10.48,10.05-22.34,7.75c-0.83-0.16-1.64,0.39-1.81,1.22c-1.41,6.8-2.5,22.65-10.05,18.4c-0.55-0.31-0.86-0.92-0.79-1.55c0.78-7.28,2.44-14.99,2.17-22.5c0-0.09,0-0.18,0.02-0.27c2.71-15.89-7.16-34.91-2.73-49.34C323.98,210.1,325.24,209.68,326.12,210.32z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_axjXx = {
  name: "Ears 24",
  description:
    "Smooth, very wide ears with double points that droop slightly downwards",
  tags: ["pointed", "side", "drooping", "large", "smooth"],
  preview: {
    viewbox: "70 190 140 140",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M192.17,238.87c0,0-38.77-37.99-60.31-31.33s-23.89,28.55-33.68,48.96c-5.08,10.58-12.65,16.63-16.06,20.76c-5,6.05-2.29,9.45,5.54,9.03c4.76-0.25,11.16-2.19,20.3-5.59c9.35-3.47,0.78,12.22,0.78,12.22s-8.62,7.83,2.35,8.62s47.59-9.79,66.19-23.89S192.17,238.87,192.17,238.87z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M175.41,224.77c0.65-2.48,0.18-6.77-3.16-6.43c-4.3,2.67-4.76,9.48-6.49,13.99c-20.39-4.97-26,21.71-33.39,35.26c-6.38,16.04,27.66,5.55,33.06,0.62c1.4,5.96,3.54,11.74,6.41,17.16c4.27-1.77,2.02-3.77,1.49-7.91c172.42,259.94,173.12,242.16,175.41,224.77z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M307.83,238.87c0,0,38.77-37.99,60.31-31.33s23.89,28.55,33.68,48.96c5.08,10.58,12.65,16.63,16.06,20.76c5,6.05,2.29,9.45-5.54,9.03c-4.76-0.25-11.16-2.19-20.3-5.59c-9.35-3.47-0.78,12.22-0.78,12.22s8.62,7.83-2.35,8.62s-47.59-9.79-66.19-23.89S307.83,238.87,307.83,238.87z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324.59,224.77c-0.65-2.48-0.18-6.77,3.16-6.43c4.3,2.67,4.76,9.48,6.49,13.99c20.39-4.97,26,21.71,33.39,35.26c6.38,16.04-27.66,5.55-33.06,0.62c-1.4,5.96-3.54,11.74-6.41,17.16c-4.27-1.77-2.02-3.77-1.49-7.91C327.58,259.94,326.88,242.16,324.59,224.77z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_Zxpa2 = {
  name: "Ears 25",
  description: "Smooth, swooping ears on the top of the head",
  tags: ["pointed", "top", "large", "smooth"],
  preview: {
    viewbox: "100 80 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M195.24 204.83c12.55-.77 17.64-16.61 7.89-24.56-13.5-11-29.48-28.09-38.1-51.84-16.74-46.16-33.02-63.05-38.79 8.24.56 83.87 40.16 64.28 69 68.16z" />
      ),
      basic: {
        dark: (
          <path d="M200.57 173.09c-2.39 2.06-4.68 4.23-6.88 6.49-13.18-6.2-25.32-27.44-35.23-27.04-9.41 17.21-.9 45.83 21.63 44.09-1.01 2.93-7.95 9.15-3.77 11.1 7.07 1.1 8.02-6.54 11.27-11.41 2.34-6.88 19.67-16.5 12.98-23.23z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M304.76 204.83c-12.55-.77-17.64-16.61-7.89-24.56 13.5-11 29.48-28.09 38.1-51.84 16.74-46.16 33.02-63.05 38.79 8.24-.56 83.87-40.16 64.28-69 68.16z" />
      ),
      basic: {
        dark: (
          <path d="M299.43 173.09c2.39 2.06 4.68 4.23 6.88 6.49 13.18-6.2 25.32-27.44 35.23-27.04 9.41 17.21.9 45.83-21.63 44.09 1.01 2.93 7.95 9.15 3.77 11.1-7.07 1.1-8.02-6.54-11.27-11.41-2.34-6.88-19.67-16.5-12.98-23.23z" />
        ),
      },
    },
  },
};

const ear_shape_jWOL0 = {
  name: "Ears 26",
  description: "Smooth, wide and long drooping ears",
  tags: ["side", "large", "drooping", "smooth"],
  preview: {
    viewbox: "90 170 180 180",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M183.08,230.99c-24.18-81.56-80.34-50.68-56.79,49.51c15.46,54.6,17.72,56.87,28.2,59.34c19.4,4.58,1.27-63.05,19.61-83.25S183.08,230.99,183.08,230.99z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M175.35,208.8c-2.16,3.89-3.96,7.97-5.45,12.15c-26.54-43.66-33.82,81.39-17.07,82.77c6.29-15.47,4.93-33.86,12.43-49.39c3.72,30.36,7.65,9.33,6.64-6.29C169.92,235.11,181.58,220,175.35,208.8z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M316.92,230.99c24.18-81.56,80.34-50.68,56.79,49.51c-15.46,54.6-17.72,56.87-28.2,59.34c-19.4,4.58-1.27-63.05-19.61-83.25S316.92,230.99,316.92,230.99z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M324.65,208.8c2.16,3.89,3.96,7.97,5.45,12.15c26.54-43.66,33.82,81.39,17.07,82.77c-6.29-15.47-4.93-33.86-12.43-49.39c-3.72,30.36-7.65,9.33-6.64-6.29C330.08,235.11,318.42,220,324.65,208.8z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_TUUUj = {
  name: "Ears 27",
  description:
    "Smooth, wide ears that have a bump on the top and a slight point to the side",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: "90 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M177.64 266.34c-3.48-3.79-5.82-8.48-7.22-13.43-4.7-16.6-15.49-47.93-30.04-47.93-19.79 0-17.01 33.67-25.69 40.96s-17.7 10.76-11.11 17.01c6.6 6.25 34.19 38.88 61.79 23.95 17.64-9.53 15.86-16.65 12.27-20.56z" />
      ),
      basic: {
        dark: (
          <path d="M174 274.4c-.75-6.18-1.12-12.41-1.09-18.63-.44-5.01 1.34-11.52-1.85-15.68-5.93-1.39-4.4 7.92-5.2 11.52-3.8-6.2-9.31-23.81-16.9-24.09 7.21 17.5 10.8 18.62-4.66 32.62-5.58 5.99-3.85 18.2 5.38 18.73 5.94.04 10.65-5.63 16.82-4.3.86 4.26 2.25 8.39 4.38 12.16 7.73 1.85 3.62-8.19 3.12-12.33z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M322.36 266.34c3.48-3.79 5.82-8.48 7.22-13.43 4.7-16.6 15.49-47.93 30.04-47.93 19.79 0 17.01 33.67 25.69 40.96s17.7 10.76 11.11 17.01c-6.6 6.25-34.19 38.88-61.79 23.95-17.64-9.53-15.86-16.65-12.27-20.56z" />
      ),
      basic: {
        dark: (
          <path d="M326 274.4c.75-6.18 1.12-12.41 1.09-18.63.44-5.01-1.34-11.52 1.85-15.68 5.93-1.39 4.4 7.92 5.2 11.52 3.8-6.2 9.31-23.81 16.9-24.09-7.21 17.5-10.8 18.62 4.66 32.62 5.58 5.99 3.85 18.2-5.38 18.73-5.94.04-10.65-5.63-16.82-4.3-.86 4.26-2.25 8.39-4.38 12.16-7.73 1.85-3.62-8.19-3.12-12.33z" />
        ),
      },
    },
  },
};

const ear_shape_Wk3es = {
  name: "Ears 28",
  description:
    "Smooth, long rounded ears that stand diagonally from the top of the head",
  tags: ["rounded", "top", "large", "smooth"],
  preview: {
    viewbox: "80 80 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M205.17 202.1C121.26 32.47 30.48 91 173.43 206.59c33.52 25.57 39.91 16.36 31.74-4.49z" />
      ),
      basic: {
        dark: (
          <path d="M197.24 181.1c-2.84-3.16-6.32 1.56-8.36 3.51-53.52-74.39-71.29-30.97-10.79 14.6-1.75 3.59-8.98 14.67-1.27 15.25 3.16-.4 4.67-6.2 5.95-8.53 2.51-6.87 15.15-19.25 14.47-24.83z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M294.83 202.1c83.92-169.62 174.69-111.09 31.74 4.5-33.52 25.56-39.91 16.35-31.74-4.5z" />
      ),
      basic: {
        dark: (
          <path d="M302.76 181.1c2.84-3.16 6.32 1.56 8.36 3.51 53.52-74.39 71.29-30.97 10.79 14.6 1.75 3.59 8.98 14.67 1.27 15.25-3.16-.4-4.67-6.2-5.95-8.53-2.51-6.87-15.15-19.25-14.47-24.83z" />
        ),
      },
    },
  },
};

const ear_shape_XkDvO = {
  name: "Ears 29",
  description:
    "Smooth, long rounded ears that stand diagonally from the side of the head",
  tags: ["rounded", "side", "large", "smooth"],
  preview: {
    viewbox: "20 120 180 180",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M178.44 229.42c-139.53-126.34-204.11-39.55-27.85 15.87 40.58 11.42 43.12.51 27.85-15.87z" />
      ),
      basic: {
        dark: (
          <path d="M174.82 222.5c-4.35-5.59-5.59 3.22-6.31 6.36-15.25-10.36-31.02-19.97-46.45-30.06-5.28-3.39-11.46-6.93-17.47-5.12-28.29 16.76 49.06 45.51 61.67 48.46.06 3.37-2.95 14.76 2.89 13.9 1.72-.76 1.76-6.1 2.03-7.62.51-2.85 1.01-5.71 1.52-8.56.79-5.49 3.07-11.83 2.12-17.36z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M321.56 229.42c139.53-126.34 204.11-39.55 27.85 15.87-40.58 11.42-43.12.51-27.85-15.87z" />
      ),
      basic: {
        dark: (
          <path d="M325.18 222.5c4.35-5.59 5.59 3.22 6.31 6.36 15.25-10.36 31.02-19.97 46.45-30.06 5.28-3.39 11.46-6.93 17.47-5.12 28.29 16.76-49.06 45.51-61.67 48.46-.06 3.37 2.95 14.76-2.89 13.9-1.72-.76-1.76-6.1-2.03-7.62-.51-2.85-1.01-5.71-1.52-8.56-.79-5.49-3.07-11.83-2.12-17.36z" />
        ),
      },
    },
  },
};

const ear_shape_xyzCw = {
  name: "Ears 30",
  description:
    "Smooth, skinny tapered ears that stand from the top of the head",
  tags: ["rounded", "top", "smooth"],
  preview: {
    viewbox: "90 75 150 150",
    base: PreviewBase,
  },
  placement: "top",
  shape: {
    left: {
      base: (
        <path d="M196.27 185.33c-.57-2.31-.83-4.68-.72-7.05.28-6.09-.9-20.4-14.84-37.57C162.1 117.8 167 99.19 164.55 92.93s-12.82-7.18-14 20.37c-1.39 32.31-16.25 47 21.54 80.68 30.85 27.49 26.73 1.74 24.18-8.65z" />
      ),
      basic: {
        dark: (
          <path d="M196.51 176.81c-2.18.29-3.64 2.44-5.22 3.81-17.87-54.07-47.08-21.46-9.26 11.34-2.24 3.16-5.2 7.5-3.04 11.12 4.7 4.57 8.09-4.31 10-6.95 2.06-4.89 15.96-15.7 7.52-19.32z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M303.73 185.33c.57-2.31.83-4.68.72-7.05-.28-6.09.9-20.4 14.84-37.57 18.6-22.91 13.71-41.51 16.16-47.78s12.82-7.18 14 20.37c1.39 32.31 16.25 47-21.54 80.68-30.85 27.49-26.73 1.74-24.18-8.65z" />
      ),
      basic: {
        dark: (
          <path d="M303.49 176.81c2.18.29 3.64 2.44 5.22 3.81 17.87-54.07 47.08-21.46 9.26 11.34 2.24 3.16 5.2 7.5 3.04 11.12-4.7 4.57-8.09-4.31-10-6.95-2.06-4.89-15.96-15.7-7.52-19.32z" />
        ),
      },
    },
  },
};

const ear_shape_epGZS = {
  name: "Ears 31",
  description:
    "Smooth, large and wide ears that look a little like a butterfly wing",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "80 170 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M181.55 258.79s-31.37-64.41-69.76-64.53c-11.57-.04-3.33 53.76 17.98 64.53 15.58 7.87-11.37 34.66 2.52 46.23 7.72 3.71 64.65-30.23 49.26-46.23z" />
      ),
      basic: {
        dark: (
          <path d="M172.98 269.81c-2.45-11.56-1.64-23.24-1.91-34.81-.4-.98-1.46-1.79-2.49-1.54-2.74 1.3-1.81 5.7-2.38 8.26-5.04-5.74-10.31-11.48-16.14-16.46-1.63-1.42-13.98-9.16-13.98-10.56 1.35 11.55 20.86 16.94 18.45 34.51-.97 5.62-5.25 10.9-3.98 16.8.64 2.85 3.05 5.47 5.97 5.47 4.42-.77 7.37-2.17 11 1.61.4 2.38.86 4.75 1.35 7.11.59 2.85 4.72 8.68 6.8 3.54 1.26-3.1-2.11-10.61-2.69-13.93z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M318.45 258.79s31.37-64.41 69.76-64.53c11.57-.04 3.33 53.76-17.98 64.53-15.58 7.87 11.37 34.66-2.52 46.23-7.72 3.71-64.65-30.23-49.26-46.23z" />
      ),
      basic: {
        dark: (
          <path d="M327.02 269.81c2.45-11.56 1.64-23.24 1.91-34.81.4-.98 1.46-1.79 2.49-1.54 2.74 1.3 1.81 5.7 2.38 8.26 5.04-5.74 10.31-11.48 16.14-16.46 1.63-1.42 13.98-9.16 13.98-10.56-1.35 11.55-20.86 16.94-18.45 34.51.97 5.62 5.25 10.9 3.98 16.8-.64 2.85-3.05 5.47-5.97 5.47-4.42-.77-7.37-2.17-11 1.61-.4 2.38-.86 4.75-1.35 7.11-.59 2.85-4.72 8.68-6.8 3.54-1.26-3.1 2.11-10.61 2.69-13.93z" />
        ),
      },
    },
  },
};

const ear_shape_4qBKl = {
  name: "Ears 32",
  description: "Smooth, wide ears that sweep down and outwards",
  tags: ["pointed", "side", "drooping", "smooth"],
  preview: {
    viewbox: "70 180 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M181.9 224.29c-52.82-35.67-40.31 15.82-85.38 29.93-21.74 5.08 43.31 56.23 58.76 20.67s31.71-24.99 31.71-24.99l-5.09-25.61z" />
      ),
      basic: {
        dark: (
          <path d="M175.57 218.76c-3.36-6.54-5.5 4.13-5.97 7.02-26.63 2.56-17.27 45.34-40.73 53.2 20.29 9.61 24.84-22.86 37.32-31.94.24 2.97-.13 14.72 4.95 10.95.37-12.71.68-26.7 4.43-39.23z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M318.1 224.29c52.82-35.67 40.31 15.82 85.38 29.93 21.74 5.08-43.31 56.23-58.76 20.67s-31.71-24.99-31.71-24.99l5.09-25.61z" />
      ),
      basic: {
        dark: (
          <path d="M324.43 218.76c3.36-6.54 5.5 4.13 5.97 7.02 26.63 2.56 17.27 45.34 40.73 53.2-20.29 9.61-24.84-22.86-37.32-31.94-.24 2.97.13 14.72-4.95 10.95-.37-12.71-.68-26.7-4.43-39.23z" />
        ),
      },
    },
  },
};

const ear_shape_zwyyL = {
  name: "Ears 33",
  description: "Smooth, small but wide ears that bump outwards at the top",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: "100 190 100 100",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M168.71 244.24c-4.62-15.95-40.04-67.69-48.21-10.51.56 19.82 20.65 18.57 24.14 30.52 6.64 23.13 26.87 6.75 33.57 1.68l-9.5-21.69z" />
      ),
      basic: {
        dark: (
          <path d="M171.57 266.17c1.2-6.44-1.49-45.81-5.58-18-4.43-10-15.87-21.33-22.87-28.59.44 7.35 10.58 12.47 9.57 20.83-3.21 12.4-5.65 30.78 13.67 26.78.46 14.02 8.46 11.89 5.21-1.02z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M331.29 244.25c4.62-15.95 40.04-67.69 48.21-10.51-.56 19.82-20.65 18.57-24.14 30.52-6.64 23.13-26.87 6.75-33.57 1.68l9.5-21.69z" />
      ),
      basic: {
        dark: (
          <path d="M328.43 266.18c-1.2-6.44 1.49-45.81 5.58-18 4.43-10 15.87-21.33 22.87-28.59-.44 7.35-10.58 12.47-9.57 20.83 3.21 12.4 5.65 30.78-13.67 26.78-.46 14.02-8.46 11.89-5.21-1.02z" />
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_kDDB4 = {
  name: "Ears 34",
  description: "Smooth, wide ears that droop downwards into a slight point",
  tags: ["pointed", "side", "drooping", "smooth"],
  preview: {
    viewbox: "110 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M180.2,208.22c0,0-50.74-8.16-51.02,80.71c-0.05,16.52,8.57,10.66,19.24,0c10.66-10.66,5.85-15.88,31.36-15.89S180.2,208.22,180.2,208.22z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M180.18,209.14c0.33-1.11-0.08-2.36-1.1-2.9c-4.94-2.64-7.22,17.25-11.93,18.47c-7.6,2.74-36.8,61.73-19.26,54.87c0.35-0.14,0.67-0.36,0.93-0.64c3.2-3.56,4.41-8.85,8.28-11.66c0.09-0.07,0.19-0.13,0.29-0.19c1.77-1.03,3.73-1.86,5.41-3.02c1.51-1.04,3.58-0.22,3.95,1.58c3.9,18.59,9.88,5.21,5.75-9.1c-0.05-0.17-0.08-0.35-0.1-0.53c171.31,239.9,175.63,224.27,180.18,209.14z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M319.8,208.22c0,0,50.74-8.16,51.02,80.71c0.05,16.52-8.57,10.66-19.24,0c-10.66-10.66-5.85-15.88-31.36-15.89C294.71,273.03,319.8,208.22,319.8,208.22z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M319.82,209.14c-0.33-1.11,0.08-2.36,1.1-2.9c4.94-2.64,7.22,17.25,11.93,18.47c7.6,2.74,36.8,61.73,19.26,54.87c-0.35-0.14-0.67-0.36-0.93-0.64c-3.2-3.56-4.41-8.85-8.28-11.66c-0.09-0.07-0.19-0.13-0.29-0.19c-1.77-1.03-3.73-1.86-5.41-3.02c-1.51-1.04-3.58-0.22-3.95,1.58c-3.9,18.59-9.88,5.21-5.75-9.1c0.05-0.17,0.08-0.35,0.1-0.53C328.69,239.9,324.37,224.27,319.82,209.14z" />
          </>
        ),
      },
    },
  },
};

// TODO: Tweak manually - looked strange on SVGomg
const ear_shape_AwTvI = {
  name: "Ears 35",
  description:
    "Smooth, long ears that sweep upwards to a slight point, with another point/fin shape partway down",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "90 180 130 130",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <>
          <path d="M176.67,255.99c-5.31-4.61-49.95-118.88-44.24-34.59c0.76,20.22-12.52,17.4-16.34,25.55c-1.97,4.2,15.77,5.35,27.02,20.98c11.25,15.64,29.37,32.99,36.61,30.51C186.97,295.97,176.67,255.99,176.67,255.99z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M178.95,291.52c-6.5-17.48-7.11-36.82-6.85-55.3c-0.9,0.3-1.98-0.05-2.52-0.82c-4.29,2.17-3.49,8.83-4.35,12.98c-5.71-12.06-9.62-29.96-20.7-37.38c2.44,8.6,10.58,15.44,10.46,24.38c-0.07,5.25-3.05,9.97-4.36,15.05c-3.01,10.6,3.04,27.88,16.44,24.22c2.03,8.65,5.44,16.97,10.16,24.5c1.18,1.88,5.14,5.85,5.59,1.37c183.1,297.83,180.03,293.88,178.95,291.52z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <>
          <path d="M323.33,255.99c5.31-4.61,49.95-118.88,44.24-34.59c-0.76,20.22,12.52,17.4,16.34,25.55c1.97,4.2-15.77,5.35-27.02,20.98s-29.37,32.99-36.61,30.51C313.03,295.97,323.33,255.99,323.33,255.99z" />
        </>
      ),
      basic: {
        dark: (
          <>
            <path d="M321.05,291.52c6.5-17.48,7.11-36.82,6.85-55.3c0.9,0.3,1.98-0.05,2.52-0.82c4.29,2.17,3.49,8.83,4.35,12.98c5.71-12.06,9.62-29.96,20.7-37.38c-2.44,8.6-10.58,15.44-10.46,24.38c0.07,5.25,3.05,9.97,4.36,15.05c3.01,10.6-3.04,27.88-16.44,24.22c-2.03,8.65-5.44,16.97-10.16,24.5c-1.18,1.88-5.14,5.85-5.59,1.37C316.9,297.83,319.97,293.88,321.05,291.52z" />
          </>
        ),
      },
    },
  },
};

const ear_shape_0U6iQ = {
  name: "Ears 36",
  description:
    "Smooth, extra wide ears that reach outwards and droop slightly at the tip",
  tags: ["pointed", "side", "smooth"],
  preview: {
    viewbox: "70 180 150 150",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M186.75 291.28c-8-20.26-10.42-42.8-9.57-64.39-25.35-7.89-57.29-14.42-71.99-3.44-26.32 19.64 4.96 4.18 24.41 26.69 14.85 23.07 18.77 49.51 61.27 50.26-1.7-2.9-2.96-6.18-4.12-9.12z" />
      ),
      basic: {
        dark: (
          <path d="M177.59 278.28c-10.72-4.89-.02-82.77-9.82-46.39-16.09-3.61-36.87-13.06-52.13-4.05 27.88-4.53 34.98 20.64 38.56 42.68 1.88 3.72 5.79 6.97 10.16 6.61 8.45-1.02 7.73 20.98 17.71 26.08 4.08-8.31-3.07-16.42-4.48-24.93z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M313.25 291.28c8-20.26 10.42-42.8 9.57-64.39 25.35-7.89 57.29-14.42 71.99-3.44 26.32 19.64-4.96 4.18-24.41 26.69-14.85 23.07-18.77 49.51-61.27 50.26 1.7-2.9 2.96-6.18 4.12-9.12z" />
      ),
      basic: {
        dark: (
          <path d="M322.41 278.28c10.72-4.89.02-82.77 9.82-46.39 16.09-3.61 36.87-13.06 52.13-4.05-27.88-4.53-34.98 20.64-38.56 42.68-1.88 3.72-5.79 6.97-10.16 6.61-8.45-1.02-7.73 20.98-17.71 26.08-4.08-8.31 3.07-16.42 4.48-24.93z" />
        ),
      },
    },
  },
};

const ear_shape_BTIMM = {
  name: "Ears 37",
  description: "Smooth, large ears that bump slightly outwards at the top",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: "100 190 120 120",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M179.32 253.49s3.62-30.13-24.41-45.77-30.32 23.26-24.79 33.56c5.53 10.3 12.78 11.82 15.64 33.56s8.79 23.33 24.13 19.39 22.02-27.02 22.02-27.02l-12.59-13.72z" />
      ),
      basic: {
        dark: (
          <path d="M173.95 235.54c-1.06-5.16 1.33-12.55-3.24-15.66-1.28 2.85-2.06 5.92-2.79 8.92-6.77-6.2-16.17-17.62-25.83-16.08 16.13 11.18 18.68 33.6 13.08 51.25-6.77 13.48 5.33 26 14.24 10.13 1.95 6.89 2.52 14.69 6.41 20.74 8.11-6.61-4.8-22.12-2.8-32.46-.82-8.94.9-17.89.93-26.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M320.68 253.49s-3.62-30.13 24.41-45.77 30.32 23.26 24.79 33.56c-5.53 10.3-12.78 11.82-15.64 33.56s-8.79 23.33-24.13 19.39c-15.34-3.94-22.02-27.02-22.02-27.02l12.59-13.72z" />
      ),
      basic: {
        dark: (
          <path d="M326.05 235.54c1.06-5.16-1.33-12.55 3.24-15.66 1.28 2.85 2.06 5.92 2.79 8.92 6.77-6.2 16.17-17.62 25.83-16.08-16.13 11.18-18.68 33.6-13.08 51.25 6.77 13.48-5.33 26-14.24 10.13-1.95 6.89-2.52 14.69-6.41 20.74-8.11-6.61 4.8-22.12 2.8-32.46.82-8.94-.9-17.89-.93-26.84z" />
        ),
      },
    },
  },
};

const ear_shape_cnX1o = {
  name: "Ears 38",
  description:
    "Smooth, tall ears that sweep upwards and slightly out from the side of the head into a slight point",
  tags: ["pointed", "side", "large", "smooth"],
  preview: {
    viewbox: "80 150 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M177.91 253.82s-24.41-58.93-55.3-78.57 5.34 28.8 9.15 51.87c4.48 80.81 80.49 71.73 46.15 26.7z" />
      ),
      basic: {
        dark: (
          <path d="M178.75 285.07c-4.33-17.6-3-35.86-3.49-53.81.41-6.18-5.53-6.33-7.62-.93-1.04 2.46-1.66 5.07-2.09 7.71-7.17-12.55-21.4-43.01-34.31-48.12 17.77 17.71 20.35 43.22 17.25 66.85.34 5.57 3.41 13.77 9.92 13.44 2.46-.93 4.38-3.77 7.39-2.83 2.04 9.15 3.77 19.64 9.95 26.93 8.96 4.54 4.72-5 3-9.24z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M322.09 253.82s24.41-58.93 55.3-78.57-5.34 28.8-9.15 51.87c-4.48 80.81-80.49 71.73-46.15 26.7z" />
      ),
      basic: {
        dark: (
          <path d="M321.25 285.07c4.33-17.6 3-35.86 3.49-53.81-.41-6.18 5.53-6.33 7.62-.93 1.04 2.46 1.66 5.07 2.09 7.71 7.17-12.55 21.4-43.01 34.31-48.12-17.77 17.71-20.35 43.22-17.25 66.85-.34 5.57-3.41 13.77-9.92 13.44-2.46-.93-4.38-3.77-7.39-2.83-2.04 9.15-3.77 19.64-9.95 26.93-8.96 4.54-4.72-5-3-9.24z" />
        ),
      },
    },
  },
};

const ear_shape_UEVQI = {
  name: "Ears 39",
  description:
    "Smooth, tall and rounded ears that sweep upwards and slightly out from the side of the head",
  tags: ["rounded", "side", "smooth"],
  preview: {
    viewbox: "80 150 160 160",
    base: PreviewBase,
  },
  placement: "side",
  shape: {
    left: {
      base: (
        <path d="M181.37,251.53c0,0-35.09-61.59-61.02-69.41c-25.93-7.82-7.32,22.75,0,42.33c11.82,31.66,26.51,53.78,50.73,64.84c24.22,11.06,21.36-17.16,21.36-17.16L181.37,251.53z" />
      ),
      basic: {
        dark: (
          <path d="M183.51,287.65c-5.48-20.85-8.15-42.7-6.91-63.78c-8.48-4.05-9.28,9.02-9.81,14.57c-4.27-8.51-42.68-55.73-51.29-48.2c12.92,7.35,26.35,17.47,28.62,33.42c1.37,8.2-0.23,16.69,1.22,24.88c1.22,8.59,12.67,30.44,20.86,16.48c1.93,9.9,1.36,32.41,13.13,34.93C184.82,298.9,184.77,291.98,183.51,287.65z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M318.63,251.53c0,0,35.09-61.59,61.02-69.41c25.93-7.82,7.32,22.75,0,42.33c-11.82,31.66-26.51,53.78-50.73,64.84c-24.22,11.06-21.36-17.16-21.36-17.16L318.63,251.53z" />
      ),
      basic: {
        dark: (
          <path d="M316.49,287.65c5.48-20.85,8.15-42.7,6.91-63.78c8.48-4.05,9.28,9.02,9.81,14.57c4.27-8.51,42.68-55.73,51.29-48.2c-12.92,7.35-26.35,17.47-28.62,33.42c-1.37,8.2,0.23,16.69-1.22,24.88c-1.22,8.59-12.67,30.44-20.86,16.48c-1.93,9.9-1.36,32.41-13.13,34.93C315.18,298.9,315.23,291.98,316.49,287.65z" />
        ),
      },
    },
  },
};

export const EarShapePack1 = {
  ear_shape_TEKWA,
  ear_shape_0775v,
  ear_shape_HOZ9g,
  ear_shape_vBJ7j,
  ear_shape_FYH9c,
  ear_shape_aoGZG,
  ear_shape_ZgR2p,
  ear_shape_vdyix,
  ear_shape_TbLPe,
  ear_shape_3wnpn,
  ear_shape_PDULw,
  ear_shape_kOBSt,
  ear_shape_yAT34,
  ear_shape_8wAS4,
  ear_shape_gcoC9,
  ear_shape_bPDFx,
  ear_shape_oEAG6,
  ear_shape_Dp6oZ,
  ear_shape_kf82L,
  ear_shape_8sDmX,
  ear_shape_k9vVR,
  ear_shape_aKjrI,
  ear_shape_2rsVH,
  ear_shape_axjXx,
  ear_shape_Zxpa2,
  ear_shape_jWOL0,
  ear_shape_TUUUj,
  ear_shape_Wk3es,
  ear_shape_XkDvO,
  ear_shape_xyzCw,
  ear_shape_epGZS,
  ear_shape_4qBKl,
  ear_shape_zwyyL,
  ear_shape_kDDB4,
  ear_shape_AwTvI,
  ear_shape_0U6iQ,
  ear_shape_BTIMM,
  ear_shape_cnX1o,
  ear_shape_UEVQI,
};
