// Icons
// import icon from "character-creator/assets/menu-icons/streamline-cog.png";

// Sections
import BackgroundShapesPage from "./pages/BackgroundShapesPage";

const BackgroundMenuConfig = {
  id: "Background",
  name: "Background",
  // icon: icon,
  description:
    "Want something a bit more exciting than a plain white background?",
  groups: [
    {
      id: "BackgroundElements",
      name: "Background Elements",
      pages: { backgroundShapesPage: BackgroundShapesPage },
    },
  ],
};

export default BackgroundMenuConfig;
