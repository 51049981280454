// Sections
import NosePage from "./pages/FaceNosePage";
import TattoosPage from "./pages/cosmetics/FaceTattoosPage";
import EyeShapePage from "./pages/eyes/EyeShapePage";
import IrisPage from "./pages/eyes/IrisPage";
import PupilPage from "./pages/eyes/PupilPage";
import EyeShinePage from "./pages/eyes/EyeShinePage";
import EyelashesPage from "./pages/eyes/EyelashesPage";
import EyesUndereyePage from "./pages/eyes/UndereyePage";
import MouthShapePage from "./pages/mouth/MouthShape";
import TeethPage from "./pages/mouth/TeethPage";
import MuzzlePage from "./pages/extras/MuzzlesPage";
import WhiskersPage from "./pages/extras/WhiskersPage";
import FacePaintPage from "./pages/cosmetics/FacePaintPage";
import CheeksPage from "./pages/cosmetics/CheeksPage";
import ContouringPage from "./pages/cosmetics/ContouringPage";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const FaceMenuConfig = {
  id: "Face",
  name: "Face",
  // icon: icon,
  description: "Add your face",
  groups: [
    {
      id: "Eyes",
      name: "Eyes",
      pages: {
        eyesPage: EyeShapePage,
        irisPage: IrisPage,
        pupilPage: PupilPage,
        eyeShinePage: EyeShinePage,
        eyelashesPage: EyelashesPage,
        eyesUndereyePage: EyesUndereyePage,
      },
    },
    {
      id: "Nose",
      name: "Nose",
      pages: { nosePage: NosePage },
    },
    {
      id: "Mouth",
      name: "Mouth",
      pages: { mouthShapePage: MouthShapePage, teethPage: TeethPage },
    },
    {
      id: "Extras",
      name: "Extras",
      pages: { muzzlePage: MuzzlePage, whiskersPage: WhiskersPage },
    },
    // TODO: Own Menu?
    {
      id: "FaceCosmetics",
      name: "Cosmetics",
      pages: {
        CheeksPage,
        ContouringPage,
        faceTattoosPage: TattoosPage,
        facePaintPage: FacePaintPage,
      },
    },
  ],
};

export default FaceMenuConfig;
