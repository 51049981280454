// Components
import { allColours } from "character-creator/colours/colourSchemes";
import { cheekShapeList } from "character-creator/components/head/markings/cheeks/CheekShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const cheeksPath = "base.head.face.markings.cheeks.";
const leftTransformPath = cheeksPath + "left.transform.";
const rightTransformPath = cheeksPath + "right.transform.";

const CheeksPage = {
  id: "cheeksPage",
  name: "Cheeks",
  description: "Let's get cheeky!",
  symmetryAttribute: cheeksPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "cheeksShape",
        name: "Shape",
        description: "",
        dataAttribute: [cheeksPath + "left.shape", cheeksPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        previewSide: "L",
        previewViewbox: "0 0 100 100",
        options: cheekShapeList,
        colourControls: [
          {
            id: "cheeksColour",
            name: "Colour",
            description: "",
            dataAttribute: [
              cheeksPath + "left.colour",
              cheeksPath + "right.colour",
            ],
            attributeType: "multi",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "cheeksTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [leftTransformPath + "x", rightTransformPath + "x"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "cheeksTransformY",
            name: "Vertical",
            description: "",
            dataAttribute: [leftTransformPath + "y", rightTransformPath + "y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "cheeksTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [
              leftTransformPath + "scale",
              rightTransformPath + "scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
          {
            id: "cheeksTransformO",
            name: "Opacity",
            description: "",
            dataAttribute: [
              leftTransformPath + "opacity",
              rightTransformPath + "opacity",
            ],
            range: {
              min: 0.25,
              max: 1,
              step: 0.25,
            },
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "faceTattooLeft",
        name: "Left shape",
        description: "",
        dataAttribute: [cheeksPath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        previewSide: "L",
        previewViewbox: "0 0 100 100",
        options: cheekShapeList,
        colourControls: [
          {
            id: "faceTattooLeftColour",
            name: "Left colour",
            description: "",
            dataAttribute: [cheeksPath + "left.colour"],
            attributeType: "multi",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "faceTattooLeftTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [leftTransformPath + "x"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "faceTattooLeftTransformY",
            name: "Vertical",
            description: "",
            dataAttribute: [leftTransformPath + "y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "faceTattooLeftTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [leftTransformPath + "scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
          {
            id: "faceTattooLeftTransformO",
            name: "Opacity",
            description: "",
            dataAttribute: [leftTransformPath + "opacity"],
            range: {
              min: 0.25,
              max: 1,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "faceTattooRight",
        name: "Right",
        description: "",
        dataAttribute: [cheeksPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        previewSide: "R",
        previewViewbox: "0 0 100 100",
        options: cheekShapeList,
        colourControls: [
          {
            id: "faceTattooRightColour",
            name: "Right colour",
            description: "",
            dataAttribute: [cheeksPath + "right.colour"],
            attributeType: "multi",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "faceTattooRightTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [rightTransformPath + "x"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "faceTattooRightTransformY",
            name: "Vertical",
            description: "",
            dataAttribute: [rightTransformPath + "y"],
            range: {
              min: -50,
              max: 50,
              step: 10,
            },
          },
          {
            id: "faceTattooRightTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [rightTransformPath + "scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
          {
            id: "faceTattooRightTransformO",
            name: "Opacity",
            description: "",
            dataAttribute: [leftTransformPath + "opacity"],
            range: {
              min: 0.25,
              max: 1,
              step: 0.25,
            },
          },
        ],
      },
    ],
  },
};

export default CheeksPage;
