import { allColours } from "character-creator/colours/colourSchemes";
import {
  NoseAccessoriesListLeft,
  NoseAccessoriesListRight,
} from "character-creator/components/clothing/Accessories/Face/Nose/NoseAccessoriesList";

const noseAccessoriesPath = "clothing.accessories.nose.";
const noseAccessoriesLeftPath = noseAccessoriesPath + "left.";
const noseAccessoriesRightPath = noseAccessoriesPath + "right.";

const NoseAccessoriesSidePage = {
  id: "noseAccessoriesSidePage",
  name: "Nose",
  description: "Depending on your nose you may need to adjust the placement",
  symmetryAttribute: noseAccessoriesPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "accessoriesNoseStyleGroup",
        name: "Style",
        description: "",
        dataAttribute: [
          noseAccessoriesLeftPath + "shape",
          noseAccessoriesRightPath + "shape",
        ],
        previewType: "preview",
        options: NoseAccessoriesListLeft,
        colourControls: [
          {
            id: "accessoriesNoseColour",
            name: "Colour",
            description: "",
            dataAttribute: [
              noseAccessoriesLeftPath + "colour",
              noseAccessoriesRightPath + "colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesNoseTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [
              noseAccessoriesLeftPath + "transform.x",
              noseAccessoriesRightPath + "transform.x",
            ],
            range: {
              min: -50,
              max: 50,
              step: 5,
            },
          },
          {
            id: "accessoriesNoseTransformY",
            name: "Vertical",
            description: "",
            dataAttribute: [
              noseAccessoriesLeftPath + "transform.y",
              noseAccessoriesRightPath + "transform.y",
            ],
            range: {
              min: -50,
              max: 50,
              step: 5,
            },
          },
          {
            id: "accessoriesNoseTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [
              noseAccessoriesLeftPath + "transform.scale",
              noseAccessoriesRightPath + "transform.scale",
            ],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "accessoriesNoseLeftStyleGroup",
        name: "Style",
        description: "",
        dataAttribute: [noseAccessoriesLeftPath + "shape"],
        previewType: "preview",
        options: NoseAccessoriesListLeft,

        colourControls: [
          {
            id: "accessoriesNoseLeftColour",
            name: "Colour",
            description: "",
            dataAttribute: [noseAccessoriesLeftPath + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesNoseLeftTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [noseAccessoriesLeftPath + "transform.x"],
            range: {
              min: -50,
              max: 50,
              step: 5,
            },
          },
          {
            id: "accessoriesNoseLeftTransformY",
            name: "Vertical",
            description: "",
            dataAttribute: [noseAccessoriesLeftPath + "transform.y"],
            range: {
              min: -50,
              max: 50,
              step: 5,
            },
          },
          {
            id: "accessoriesNoseLeftTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [noseAccessoriesLeftPath + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
      {
        id: "accessoriesNoseRightStyleGroup",
        name: "Right Style",
        description: "",
        dataAttribute: [noseAccessoriesRightPath + "shape"],
        previewType: "preview",
        options: NoseAccessoriesListRight,
        colourControls: [
          {
            id: "accessoriesNoseRightColour",
            name: "Right Colour",
            description: "",
            dataAttribute: [noseAccessoriesRightPath + "colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: allColours,
          },
        ],
        transformControls: [
          {
            id: "accessoriesNoseRightTransformX",
            name: "Horizontal",
            description: "",
            dataAttribute: [noseAccessoriesRightPath + "transform.x"],
            range: {
              min: -50,
              max: 50,
              step: 5,
            },
          },
          {
            id: "accessoriesNoseRightTransformY",
            name: "Vertical",
            description: "",
            dataAttribute: [noseAccessoriesRightPath + "transform.y"],
            range: {
              min: -50,
              max: 50,
              step: 5,
            },
          },
          {
            id: "accessoriesNoseRightTransformScale",
            name: "Size",
            description: "",
            dataAttribute: [noseAccessoriesRightPath + "transform.scale"],
            range: {
              min: 0.5,
              max: 1.5,
              step: 0.25,
            },
          },
        ],
      },
    ],
  },
};

export default NoseAccessoriesSidePage;
