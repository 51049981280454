// individual menu option component
import React from "react";
import { connect, useDispatch } from "react-redux";
import { updateConfig } from "character-creator/utilities/characterSlice";
import Preview from "character-creator/menu/elements/controls/options/PreviewOption";
import Swatch from "character-creator/menu/elements/controls/options/SwatchOption";
import { updateRecentColours } from "../../menu-helper";

const mapDispatchToProps = {
  updateConfig,
};

const mapStateToProps = (state, ownProps) => {
  return {
    navKeys: state.menu.navKeys,
    recentColours: state.config.recentColours,
    ...ownProps,
  };
};

const updateCharacterConfig = (dispatch, attributeList, updateValue = "") => {
  attributeList.forEach((thisAttribute) => {
    dispatch(updateConfig({ attribute: thisAttribute, value: updateValue }));
  });
};

const Control = (props) => {
  const {
    controlName,
    dataAttribute,
    selected,
    attributeType,
    previewType,
    previewSide,
    controlOption,
    navKeys,
    controlId,
    recentColours,
    controlType = "radio",
    onChangeOverride,
  } = props;

  const dispatch = useDispatch();

  const updateValue = attributeType === "multi" ? controlOption : controlId;

  const onKeyPress = (event) => {
    const pressedKey = event.which || event.keyCode;
    if (navKeys.includes(pressedKey)) {
      onChange(event);
    }
  };

  const optionElementId = controlName + "_" + controlId;
  // Number(selected) === Number(controlId) || <-- why did I do this?? selected !== "" &&
  const isSelected =
    selected === (controlOption.id ? controlOption.id : controlId); // Instead of controlId

  const onContextMenu = (event) => {
    event.preventDefault(event);
    navigator.clipboard.writeText(updateValue);
  };

  const onChange = (event) => {
    if (onChangeOverride) {
      onChangeOverride(
        dispatch,
        dataAttribute,
        controlOption.id ? controlOption.id : controlId
      );
    } else {
      updateCharacterConfig(dispatch, dataAttribute, updateValue);
      if (previewType === "swatch") {
        let updatedColours = updateRecentColours(recentColours, updateValue);
        updateCharacterConfig(dispatch, ["recentColours"], updatedColours);
      }
    }
  };

  return (
    <li
      data-selected={isSelected}
      tabIndex={0}
      onKeyPress={(e) => onKeyPress(e)}
      data-navcontroltype="control-label"
      className="control-label"
      onContextMenu={($this) => onContextMenu($this)}
    >
      <label htmlFor={optionElementId}>
        <input
          id={optionElementId}
          type={controlType}
          name={controlName}
          title={controlOption.description}
          aria-label={controlOption.name}
          value={controlOption.id ? controlOption.id : controlId}
          // data-attribute={dataAttribute}
          data-navcontroltype="control"
          onChange={($this) => onChange($this)}
          checked={isSelected}
          className="hidden"
        />
        <span className="sr-only">{controlOption.name}</span>
        {previewType === "swatch" && <Swatch component={controlOption} />}
        {previewType === "preview" && (
          <Preview
            id={controlId}
            component={controlOption}
            side={previewSide}
          />
        )}
        {isSelected && (
          <span
            className="control-selected"
            aria-hidden="true"
            title="Selected"
          >
            ✓
          </span>
        )}
      </label>
    </li>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Control);
