import { allColours } from "character-creator/colours/colourSchemes";

import { ScarsList } from "character-creator/components/head/markings/scars/HeadScarsShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";

const facePath = basePath + "head.face.";

const scarsPath = facePath + "markings.scars.";

const HeadScarsPage = {
  id: "headScarsPage",
  name: "Scars",
  description: "",
  controls: [
    {
      id: "scar1Shape",
      name: "Scar 1",
      description: "",
      dataAttribute: [scarsPath + "scar1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ScarsList,
      colourControls: [
        {
          id: "scar1Colour",
          name: "Scar Colour",
          description: "",
          dataAttribute: [scarsPath + "scar1.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headScar1TransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath + "scar1.transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScar1TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath + "scar1.transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScar1TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath + "scar1.transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "headScar1TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath + "scar1.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "scar2Shape",
      name: "Scar 2",
      description: "",
      dataAttribute: [scarsPath + "scar2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ScarsList,
      colourControls: [
        {
          id: "scar2Colour",
          name: "Scar Colour",
          description: "",
          dataAttribute: [scarsPath + "scar2.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headScar2TransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath + "scar2.transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScar2TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath + "scar2.transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScar2TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath + "scar2.transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "headScar2TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath + "scar2.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "scar3Shape",
      name: "Scar 3",
      description: "",
      dataAttribute: [scarsPath + "scar3.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: ScarsList,
      colourControls: [
        {
          id: "scar3Colour",
          name: "Scar Colour",
          description: "",
          dataAttribute: [scarsPath + "scar3.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headScar3TransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath + "scar3.transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScar3TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath + "scar3.transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "headScar3TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath + "scar3.transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "headScar3TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath + "scar3.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default HeadScarsPage;
