import React from "react";
import { connect } from "react-redux";
import {
  colourStyles,
  getGlobalStyle,
} from "character-creator/components/StyleOptions";
import { linearGradientFade } from "./GradientConfig";

function mapStateToProps(state, ownProps) {
  const styleConfig = ownProps.styleOverride
    ? ownProps.styleOverride
    : getGlobalStyle(state);
  const styleConfigColour =
    styleConfig.colour !== "" && colourStyles[styleConfig.colour].style.colour;

  const metaData = {
    id: ownProps.previewId
      ? ownProps.id + "_" + ownProps.previewId
      : ownProps.id,
    name: ownProps.component.name,
    description: ownProps.component.description,
  };

  // Whether globalStyle enables and sets an outline
  const outlineEnabled =
    styleConfig.colour === "coloursBlank" || styleConfig.outline;
  const globalStyleOutline =
    outlineEnabled && styleConfigColour?.outline
      ? styleConfigColour.outline
      : false;

  // If not, get the outline from the colours
  let colour = { base: "hsla(0,0,0,0)" };
  if (!ownProps.invisible && styleConfigColour) {
    colour = styleConfigColour;
  } else if (!ownProps.invisible && ownProps.colour) {
    colour = ownProps.colour;
  }
  const outlineColour =
    !ownProps.invisible && outlineEnabled && ownProps.colour.outline
      ? ownProps.colour.outline
      : "none";

  // Get the correct shading object from the config
  let shapeLayers = ownProps.component.shape.basic
    ? { base: ownProps.component.shape.base, ...ownProps.component.shape.basic }
    : ownProps.component.shape;
  let shadow = ownProps.component.shape.shadow;
  let shadowMask = ownProps.component.shadowMask;

  let componentMaskId = ownProps.component.maskId ?? "";
  let propMaskId = ownProps.maskId ?? "";
  let usedMaskId = componentMaskId ? componentMaskId : propMaskId;

  let componentStroke =
    ownProps.component.stroke != null && ownProps.component.stroke !== undefined
      ? ownProps.component.stroke
      : true;

  let strokeOverride =
    ownProps.strokeOverride != null && ownProps.strokeOverride !== undefined
      ? ownProps.strokeOverride
      : componentStroke || outlineEnabled;

  return {
    metaData,
    shadow,
    shadowMask,
    colour,
    shading: styleConfig.shading,
    outline:
      !strokeOverride || ownProps.invisible
        ? "none"
        : globalStyleOutline
        ? globalStyleOutline
        : outlineColour,
    strokeOverride,
    useClip:
      ownProps.component.useClip != null ? ownProps.component.useClip : true,
    maskId: usedMaskId ?? "",
    customStyle: ownProps.style,
    shapeLayers,
    transform: ownProps.transform ?? "",
    gradient:
      ownProps.gradient &&
      linearGradientFade(colour.base, ownProps.gradient, metaData.id),
  };
}

const Component = (props) => {
  const {
    metaData,
    outline,
    shading,
    shadow,
    shadowMask,
    shadowOnBase = false,
    useClip,
    maskId,
    colour,
    customStyle,
    shapeLayers,
    transform,
    preview,
    previewTransform,
    invisible,
    gradient,
  } = props;
  const { id, name } = metaData;

  ///////////////////////////////////////////////////////
  // Maketh the thing!
  ////////////////////////////////////////////////////////
  const maskPathId = maskId !== "" ? `url(#${maskId})` : "none";

  const style = {
    mask: maskId ? maskPathId : "none",
    ...customStyle,
  };

  const baseMask = {
    mask: !useClip ? "none" : `url(#${id}_mask)`,
  };

  const transformProps = (!preview || previewTransform) &&
    (transform.position || transform.opacity || transform.scale) && {
      transform: transform.position,
      opacity: transform.opacity ?? 1,
      scale: transform.scale ?? 1,
    };

  const componentCode = (
    <g
      id={id}
      data-name={name}
      style={style}
      transform-box={"fill-box"}
      transform-origin="center center"
      {...transformProps}
    >
      {gradient && <defs>{gradient}</defs>}
      <desc id={id + "Desc"}>{metaData.description}</desc>
      {useClip && (
        <g>
          <mask id={id + "_mask"} fill="white" fillRule="evenodd">
            {shapeLayers.clip ? shapeLayers.clip : shapeLayers.base}
          </mask>
        </g>
      )}
      <g>
        {shadow && !shadowOnBase && (
          <g
            id={id + "_shadow"}
            fill={colour.dark}
            style={{ mask: `url(#${shadowMask})` }}
          >
            {shadow}
          </g>
        )}
        <g
          id={id + "_base"}
          fill={
            invisible ? "none" : gradient ? `url(#gradient_${id})` : colour.base
          }
          stroke={"none"}
          style={baseMask}
        >
          {shapeLayers.base}
        </g>
        {!invisible && shading && (
          <g data-name="shading-layers" style={baseMask}>
            {shapeLayers.dark && (
              <g id={id + "_dark"} fill={colour.dark}>
                {shapeLayers.dark}
                {shadowOnBase && shadow}
              </g>
            )}
            {shapeLayers.darker && (
              <g id={id + "_darker"} fill={colour.darker}>
                {shapeLayers.darker}
              </g>
            )}
            {shapeLayers.light && (
              <g id={id + "_light"} fill={colour.light}>
                {shapeLayers.light}
              </g>
            )}
            {shapeLayers.lighter && (
              <g id={id + "_lighter"} fill={colour.lighter}>
                {shapeLayers.lighter}
              </g>
            )}

            {outline !== "none" && (
              <g id="outline" fill="none" stroke={outline} strokeWidth="2">
                {shapeLayers.base}
              </g>
            )}
          </g>
        )}
      </g>
    </g>
  );
  ////////////////////////////////////////////////////////
  // Returneth the thing!
  ////////////////////////////////////////////////////////
  return componentCode;
};

export default connect(mapStateToProps)(Component);
