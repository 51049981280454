import React, { Fragment } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import ControlContainer from "./ControlContainer";
import { checkDependencies } from "../../menu-helper";
import ControlsTransform from "./ControlsTransform";

const mapStateToProps = (state, ownProps) => {
  // Get the main control object, which houses all subcontrols, conditions etc
  const mainControlObject = ownProps.group;
  const controlDependencies = mainControlObject.dependencies;

  const dependenciesMet = controlDependencies
    ? checkDependencies(controlDependencies, state.config)
    : true;

  const isColouringStyle = state.config.style.type === "0";

  // Colour controls! Only visible when the main object has been selected
  const colourControlsList = mainControlObject.colourControls
    ? mainControlObject.colourControls
    : null;

  // Now determine whether the colours are visible yet or not - the "main" control must have a selected option
  const mainDataAttributeValue = get(
    state.config,
    mainControlObject.dataAttribute[0]
  );

  // Transform controls!
  const transformControlsList = mainControlObject.transformControls ? (
    <ControlsTransform
      controls={mainControlObject.transformControls}
      sectionLevel={false}
    />
  ) : null;

  const mainSelectedOption = mainControlObject.attributeType
    ? mainDataAttributeValue.id
    : mainDataAttributeValue;
  const extraControlsVisible =
    mainSelectedOption &&
    mainSelectedOption !== "" &&
    mainSelectedOption !== "0";

  return {
    mainControlObject,
    colourControlsList,
    extraControlsVisible,
    dependenciesMet,
    isColouringStyle,
    transformControlsList,
  };
};

const ControlGroup = (props) => {
  const {
    mainControlObject,
    colourControlsList,
    extraControlsVisible,
    sectionLevel = false,
    dependenciesMet,
    isColouringStyle = false,
    transformControlsList,
  } = props;

  // Main control - usually the shape
  const MainControl = (
    <ControlContainer sectionLevel={sectionLevel} control={mainControlObject} />
  );

  const ColourControls =
    extraControlsVisible &&
    colourControlsList &&
    colourControlsList.map((colourControl, index) => {
      return (
        <Fragment key={index}>
          <ControlContainer
            sectionLevel={false}
            control={colourControl}
            colourControl={true}
          />
        </Fragment>
      );
    });

  const controlGroupElement = (
    <div className="control-group">
      <div className="control-container">{MainControl}</div>

      {extraControlsVisible && transformControlsList && (
        <div className="control-container">{transformControlsList}</div>
      )}

      {ColourControls && !isColouringStyle && (
        <div className="control-container">{ColourControls}</div>
      )}
    </div>
  );

  return <>{dependenciesMet && controlGroupElement}</>;
};

export default connect(mapStateToProps)(ControlGroup);
