import { allColours } from "character-creator/colours/colourSchemes";
import { TailShapeList } from "character-creator/components/body/extras/tails/TailShapeList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";
const bodyPath = basePath + "body.";
const tailsPath = bodyPath + "extras.tail.";

const TailsPage = {
  id: "tailsPage",
  name: "Tails",
  description: "",
  controls: [
    {
      id: "tailShape",
      name: "Shape",
      description: "",
      dataAttribute: [tailsPath + "shape"],
      previewType: "preview",
      options: TailShapeList,
      colourControls: [
        {
          id: "tailColour",
          name: "Colour",
          description: "",
          dataAttribute: [tailsPath + "colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
  ],
};

export default TailsPage;
