import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { facePaintShapeList } from "./FacePaintShapeList";
import { buildTransform } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const facePaintConfig = state.config.base.head.face.extras.facePaint;

  let facePaintObject = null;
  if (ownProps.preview && ownProps.facePaint) {
    facePaintObject = ownProps.facePaint;
  } else if (facePaintConfig.shape !== "0") {
    facePaintObject = facePaintShapeList[facePaintConfig.shape];
  }

  const colour = facePaintConfig.colour
    ? facePaintConfig.colour
    : { base: state.config.base.skintone.lighter };

  const transform = buildTransform(facePaintConfig.transform);

  return {
    colour,
    transform,
    FacePaint: facePaintObject,
  };
}

const FacePaint = (props) => {
  const {
    colour,
    FacePaint,
    previewId,
    transform,
    alternateId,
    alternateMask,
  } = props;

  let componentId = "facePaint";
  let maskId = previewId ? `head~${previewId}_mask` : "head_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  if (FacePaint) {
    return (
      <g
        id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
        style={{
          mask: `url(#${maskId})`,
        }}
      >
        <Component
          id={previewId ? `${componentId}~${previewId}` : componentId}
          component={FacePaint}
          colour={colour}
          transform={transform}
          strokeOverride={false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(FacePaint);
