import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import AvatarCreator from 'pages/AvatarCreator';
import { HeaderEmoji } from "character-creator/utilities/HomeElements";
// import { CreateYourBlank, KofiButton, DemoButton, Feature } from "character-creator/utilities/HomeElements"
import HomePage from "pages/Home";

// #TODO Add skip link
export default function CharacterArtSite() {
  return (
    <Router>
      <header>
          <div className="header-title">
            <Link to="/"><h1>Character.Art</h1></Link>
            <HeaderEmoji/>
          </div>
          <nav title="Main navigation" className="nav nav-inline">
              <ul>
                <li className="button button-primary">
                  <Link to="/create">Create!</Link>
                </li>
                <li>
                  <a href="https://ko-fi.com/characterart" target="_blank" rel="noopener noreferrer" title="Join us on Ko-fi (opens in new tab)">Ko-fi</a>
                </li>
                <li>
                  <a href="https://patreon.com/characterartapp" target="_blank" rel="noopener noreferrer" title="Join us on Patreon (opens in new tab)">Patreon</a>
                </li>
                {/* <li>
                  <a href="https://www.instagram.com/characterartapp/" target="_blank" rel="noopener noreferrer" title="Follow on Instagram for updates (opens in new tab)">Instagram</a>
                </li> */}
              </ul>
          </nav>
      </header>
      <main>
            <Switch>
              <Route exact path="/">
                <HomePage/>
              </Route>
              <Route path="/create">
               <AvatarCreator />
              </Route>
            </Switch>
      </main>
    </Router>
  );
}
