import React from "react";
import Pattern from "./Pattern";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <Pattern preview={true} previewId={previewId} pattern={component} />
    </>
  );
};

export const viewbox = "30 300 200 200";
