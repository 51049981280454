import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Contouring from "./Contouring";

export const PreviewBaseCheeks = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Contouring
        preview={true}
        previewId={previewId}
        contouringCheeks={component}
      />
    </>
  );
};

export const PreviewBaseEyes = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Contouring
        preview={true}
        previewId={previewId}
        contouringEyes={component}
      />
    </>
  );
};

export const PreviewBaseLips = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Contouring
        preview={true}
        previewId={previewId}
        contouringLips={component}
      />
    </>
  );
};

export const viewboxCheeks = "150 160 200 180";
export const viewboxEyes = "150 160 200 180";
export const viewboxLips = "150 160 200 180";
