import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { HoodiesPack1 } from "./packs/Hoodies/Pack1";
import { CardigansPack1 } from "./packs/Knitwear/CardigansPack1";
import { JumpersPack1 } from "./packs/Knitwear/JumpersPack1";
import { ShawlsPack1 } from "./packs/Knitwear/ShawlsPack1";
import { ShirtsPack1 } from "./packs/Shirts/Pack1";
import { ShirtsPack2 } from "./packs/Shirts/Pack2";
import { TShirtsPack1 } from "./packs/TShirts/TShirtsPack1";
import { VestsPack1 } from "./packs/Vests/VestsPack1";
import { VestsPack2 } from "./packs/Vests/VestsPack2";
import { VestsPack3 } from "./packs/Vests/VestsPack3";
import { CapesPack1 } from "./packs/Capes/CapesPack1";

const TopsList = {
  ...TShirtsPack1,
  ...VestsPack1,
  ...VestsPack2,
  ...VestsPack3,
  ...ShirtsPack1,
  ...ShirtsPack2,
};

const KnitwearList = {
  ...CardigansPack1,
  ...JumpersPack1,
  ...ShawlsPack1,
};

const JacketsList = {
  ...HoodiesPack1,
  ...CapesPack1,
};

export const ClothingList = {
  "0": BlankListOption,
  ...TopsList,
  ...JacketsList,
  ...KnitwearList,
};
