import React from "react";
import { viewbox, PreviewBase } from "../Preview";

/********************************************
Squares
 ********************************************/
const bg_shape_cnx84ce94t = {
  name: "Square (Sharp)",
  description: "A centred square with sharp corners",
  tags: ["geometric", "sharp", "square", "central"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M75 75h350v350H75z" />,
  },
};

const bg_shape_ALA9YxUjew = {
  name: "Square (Rounded)",
  description: "A centred square with rounded corners",
  tags: ["geometric", "rounded", "square", "central"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M425 105c0-16.569-13.431-30-30-30H105c-16.569 0-30 13.431-30 30v290c0 16.569 13.431 30 30 30h290c16.569 0 30-13.431 30-30V105Z" />
    ),
  },
};

/********************************************
Arches - sharp
 ********************************************/
// TODO: see about just changing transform-origin instead of having multiple sizes
const bg_shape_rqX5CzZT3m = {
  name: "Arch (Sharp) 1",
  description:
    "A wide rectangle with sharp corners that sits at the bottom edge of the image and goes to above the character's head",
  tags: ["geometric", "sharp", "arch"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M39.882 52.541h420.236V500H39.882z" />,
  },
};

const bg_shape_WkcE2Smgxh = {
  name: "Arch (Sharp) 2",
  description:
    "A medium rectangle with sharp corners that sits at the bottom edge and goes to above the character's head",
  tags: ["geometric", "sharp", "arch"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M69.899 84.502h360.203V500H69.899z" />,
  },
};

const bg_shape_6jQPN78FqY = {
  name: "Arch (Sharp) 3",
  description:
    "A narrow rectangle with sharp corners that sits at the bottom edge and goes to above the character's head",
  tags: ["geometric", "sharp", "square"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M99.916 116.464h300.169V500H99.916z" />,
  },
};

/********************************************
Arches - rounded
 ********************************************/
// TODO: see about just changing transform-origin instead of having multiple sizes
const bg_shape_bBTRcnGd6X = {
  name: "Arch (Rounded) 1",
  description:
    "A wide arch that sits at the bottom edge and goes to above the character's head",
  tags: ["geometric", "rounded", "arch"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M450 252.541c0-53.043-21.071-103.914-58.579-141.421C353.914 73.612 303.043 52.541 250 52.541c-110.457 0-200 89.543-200 200V500h400V252.541Z" />
    ),
  },
};

const bg_shape_aLc2jwQZQC = {
  name: "Arch (Rounded) 2",
  description:
    "A medium arch that sits at the bottom edge and goes to above the character's head",
  tags: ["geometric", "rounded", "arch"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M425 259.502c0-46.412-18.437-90.924-51.256-123.743C340.925 102.94 296.413 84.502 250 84.502c-96.65 0-175 78.351-175 175V500h350V259.502Z" />
    ),
  },
};

const bg_shape_WzeubNYC72 = {
  name: "Arch (Rounded) 3",
  description:
    "A narrow arch that sits at the bottom edge and goes to above the character's head",
  tags: ["geometric", "rounded", "arch"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M399.916 266.464c0-82.843-67.158-150-150-150h-.001c-82.842 0-149.999 67.157-149.999 150V500h300V266.464Z" />
    ),
  },
};

/********************************************
Circles
 ********************************************/

const bg_shape_PjU9Pf6JDX = {
  name: "Circle (Central)",
  description: "A central circle that frames the character's face",
  tags: ["geometric", "central", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <circle cx="250" cy="250" r="175" />,
  },
};

const bg_shape_UVPcMBSD9Q = {
  name: "Circle (Half) 1",
  description: "A half circle with its flat edge at the top of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M0 0c0 138.071 111.929 250 250 250S500 138.071 500 0H0Z" />,
  },
};

const bg_shape_SGWavy4VPn = {
  name: "Circle (Half) 2",
  description: "A half circle with its curved edge at the top of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M0,500C0,361.929 111.929,250 250,250C388.071,250 500,361.929 500,500L250,500L0,500Z" />
    ),
  },
};

const bg_shape_VMNghKrdn4 = {
  name: "Circle (Half) 3",
  description: "A half circle with its flat edge at the bottom of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M0 500c0-138.071 111.929-250 250-250s250 111.929 250 250H0Z" />
    ),
  },
};

const bg_shape_4zuTZnevCj = {
  name: "Circle (Half) 4",
  description: "A half circle with its curved edge at the bottom of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M0,0C0,138.071 111.929,250 250,250C388.071,250 500,138.071 500,0L250,0L0,0Z" />
    ),
  },
};

const bg_shape_8A3viHMewd = {
  name: "Circle (Half) 5",
  description: "A half circle with its flat edge at the left of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M0 500c138.071 0 250-111.929 250-250S138.071 0 0 0v500Z" />,
  },
};

const bg_shape_gUpCGbSeaT = {
  name: "Circle (Half) 6",
  description: "A half circle with its curved edge at the left of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M250 500C111.929 500 0 388.071 0 250S111.929 0 250 0v500Z" />
    ),
  },
};

const bg_shape_pxTCPUDfhA = {
  name: "Circle (Half) 7",
  description: "A half circle with its flat edge at the right of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M500 500c-138.071 0-250-111.929-250-250S361.929 0 500 0v500Z" />
    ),
  },
};

const bg_shape_h9D9cnUG42 = {
  name: "Circle (Half) 8",
  description: "A half circle with its curved edge at the right of the image",
  tags: ["geometric", "circle"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M250 500c138.071 0 250-111.929 250-250S388.071 0 250 0v500Z" />
    ),
  },
};

/********************************************
Halfs
 ********************************************/

const bg_shape_pknapYqjk7 = {
  name: "Half (Vertical) 1",
  description: "A rectangle that covers the left half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M0 0h250v500H0z" />,
  },
};

const bg_shape_NDfc52u68g = {
  name: "Half (Vertical) 2",
  description: "A rectangle that covers the right half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M250 0h250v500H250z" />,
  },
};

const bg_shape_umjBAjn8Yd = {
  name: "Half (Horizontal) 1",
  description: "A rectangle that covers the top half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M0 0h500v250H0z" />,
  },
};

const bg_shape_jnKqU6KWMj = {
  name: "Half (Horizontal) 2",
  description: "A rectangle that covers the bottom half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M0 250h500v250H0z" />,
  },
};

const bg_shape_ZTyWAGNDiR = {
  name: "Half (Diagonal) 1",
  description: "A triangle that covers the top-right half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M500 500 0 0h500v500Z" />,
  },
};

const bg_shape_4DaCxVxKHx = {
  name: "Half (Diagonal) 2",
  description: "A triangle that covers the bottom-left half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="m0 0 500 500H0V0Z" />,
  },
};

const bg_shape_i6xbFTFJ2Y = {
  name: "Half (Diagonal) 3",
  description: "A triangle that covers the top-left half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M0 500 500 0H0v500Z" />,
  },
};

const bg_shape_7Y7NT8XMvh = {
  name: "Half (Diagonal) 4",
  description: "A triangle that covers the bottom-right half of the image",
  tags: ["geometric", "half"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M500 0 0 500h500V0Z" />,
  },
};

/********************************************
Sunbursts
 ********************************************/
const bg_shape_zwB745tuhc = {
  name: "Sunburst (Thick) 1",
  description:
    "A large central circle with thick spokes/rays coming out from it in all directions",
  tags: ["geometric", "full", "nature"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M311.158 87.657 400.91-67.797l48.922 28.246-89.787 155.515c8.77 7.21 16.821 15.263 24.03 24.039l153.539-88.646 28.245 48.922-153.493 88.619c3.961 10.507 6.93 21.498 8.793 32.857h130.578v56.49H421.16c-1.862 11.359-4.83 22.348-8.792 32.858l139.369 80.464-28.246 48.922-139.413-80.49c-7.208 8.773-15.259 16.827-24.031 24.04l88.64 153.531-48.922 28.245-88.604-153.468c-10.512 3.966-21.507 6.94-32.871 8.808v130.537h-56.491V421.17c-11.371-1.863-22.371-4.833-32.887-8.795L99.176 567.802l-48.922-28.245 89.76-155.468c-8.783-7.216-16.846-15.277-24.063-24.055L-62.365 462.985l-28.246-48.922L87.648 311.145c-3.967-10.516-6.942-21.52-8.808-32.9H-51.648v-56.49H78.841c1.867-11.377 4.842-22.382 8.809-32.899L-82.197 90.795l28.245-48.922 169.906 98.095c7.217-8.78 15.28-16.839 24.062-24.053L50.257-39.551l48.922-28.246L188.914 87.63c10.519-3.963 21.519-6.933 32.885-8.794V-51.692h56.491V78.85c11.364 1.867 22.358 4.841 32.868 8.807Z" />
    ),
  },
};

const bg_shape_ZeQmApzcJL = {
  name: "Sunburst (Thick) 2",
  description:
    "A medium central circle with thick spokes/rays coming out from it in all directions",
  tags: ["geometric", "full", "nature"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M299.141 108.472 400.91-67.797l48.922 28.246-101.804 176.329c5.434 4.71 10.526 9.804 15.235 15.241L537.614 51.357l28.245 48.922-174.31 100.638c2.341 6.741 4.212 13.699 5.577 20.838h154.611v56.49h-154.61c-1.365 7.141-3.237 14.102-5.576 20.839l160.186 92.483-28.246 48.922-160.225-92.506c-4.709 5.438-9.803 10.534-15.236 15.243L448.687 537.57l-48.922 28.245-100.622-174.283c-6.745 2.345-13.71 4.222-20.853 5.589v154.571h-56.491V397.138c-7.149-1.365-14.119-3.238-20.868-5.58L99.176 567.802l-48.922-28.245 101.775-176.28c-5.445-4.714-10.547-9.814-15.266-15.26L-62.365 462.985l-28.246-48.922 199.075-114.936c-2.347-6.755-4.224-13.729-5.591-20.882H-51.648v-56.49h154.522c1.367-7.154 3.244-14.128 5.591-20.881L-82.197 90.795l28.245-48.922 190.718 110.111c4.718-5.443 9.821-10.544 15.265-15.258L50.257-39.551l48.922-28.246 101.754 176.243c6.75-2.342 13.718-4.214 20.866-5.579V-51.692h56.491v154.576c7.145 1.368 14.11 3.245 20.851 5.588Z" />
    ),
  },
};

const bg_shape_AUVjHpgbFE = {
  name: "Sunburst (Thick) 3",
  description:
    "A small central circle with thick spokes/rays coming out from it in all directions",
  tags: ["geometric", "full", "nature"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M287.624 128.42 400.91-67.797l48.922 28.246-113.32 196.274c2.355 2.185 4.627 4.458 6.807 6.81L537.614 51.357l28.245 48.922L371.6 212.434c.945 3.059 1.778 6.167 2.494 9.321h177.643v56.49H374.095c-.716 3.156-1.55 6.267-2.494 9.321l180.136 104.001-28.246 48.922-180.169-104.02c-2.183 2.354-4.454 4.626-6.808 6.811l112.173 194.29-48.922 28.245-112.138-194.23c-3.065.948-6.178 1.784-9.337 2.502v177.605h-56.491V374.107c-3.162-.717-6.281-1.551-9.35-2.499L99.176 567.802l-48.922-28.245 113.29-196.223c-2.363-2.19-4.643-4.468-6.835-6.832L-62.365 462.985l-28.246-48.922L128.412 287.61c-.951-3.074-1.789-6.197-2.507-9.365H-51.648v-56.49h177.554c.719-3.167 1.556-6.289 2.507-9.364L-82.197 90.795l28.245-48.922L156.712 163.5c2.191-2.363 4.471-4.642 6.834-6.83L50.257-39.551l48.922-28.246 113.272 196.194c3.068-.948 6.185-1.783 9.348-2.499v-177.59h56.491v177.61c3.159.718 6.275 1.555 9.334 2.502Z" />
    ),
  },
};

const bg_shape_fdU6KyzvkF = {
  name: "Sunburst (Thin) 1",
  description:
    "A large central circle with thin spokes/rays coming out from it in all directions",
  tags: ["geometric", "full", "nature"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="m273.52 77.64 43.662-186.537 32.065 7.137-43.742 186.879c14.843 5.001 28.794 11.951 41.544 20.547L470.225-44.95l25.714 19.997-123.7 151.257c10.687 10.56 20.014 22.495 27.69 35.512l179.729-84.4 14.27 28.896-180.278 84.657c4.922 13.626 8.188 28.037 9.559 42.995h200.598v32.072H423.209c-1.37 14.955-4.636 29.365-9.559 42.995l180.278 84.657-14.27 28.896-179.729-84.4c-7.679 13.017-17.004 24.949-27.69 35.512l123.7 151.257-25.714 19.997-123.176-150.616c-12.751 8.594-26.699 15.545-41.544 20.547l43.742 186.879-32.065 7.137L273.52 422.36c-7.69 1.041-15.542 1.58-23.52 1.58-7.949 0-15.774-.535-23.434-1.569l-43.659 186.526-32.065-7.137 43.735-186.851c-14.853-4.996-28.812-11.944-41.569-20.537L29.864 544.95 4.15 524.953l123.661-151.208c-10.699-10.565-20.035-22.505-27.721-35.529l-179.659 84.368-14.27-28.896 180.202-84.622c-4.928-13.63-8.201-28.057-9.572-43.03h-200.509v-32.072H76.791c1.371-14.973 4.643-29.399 9.572-43.03l-180.202-84.622 14.27-28.896 179.659 84.368c7.688-13.027 17.025-24.967 27.721-35.529L4.15-24.953 29.864-44.95l123.144 150.578c12.758-8.594 26.717-15.542 41.569-20.537L150.842-101.76l32.065-7.137 43.659 186.526c7.66-1.034 15.485-1.569 23.434-1.569 7.978 0 15.83.539 23.52 1.58Z" />
    ),
  },
};

const bg_shape_HCYrLChxMd = {
  name: "Sunburst (Thin) 2",
  description:
    "A medium central circle with thin spokes/rays coming out from it in all directions",
  tags: ["geometric", "full", "nature"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="m267.899 101.657 49.283-210.554 32.065 7.137-49.364 210.898c11.193 3.969 21.774 9.234 31.558 15.613L470.225-44.95l25.714 19.997L356.63 145.389c7.967 8.12 15.016 17.144 20.978 26.909l202.05-94.882 14.27 28.896-202.6 95.14c3.566 10.366 6.022 21.251 7.226 32.512h225.253v32.072H398.554c-1.204 11.254-3.659 22.137-7.226 32.512l202.6 95.14-14.27 28.896-202.05-94.882c-5.959 9.761-13.008 18.787-20.978 26.909l139.309 170.342-25.714 19.997-138.784-169.701c-9.789 6.381-20.37 11.646-31.558 15.613l49.364 210.898-32.065 7.137-49.283-210.554c-5.861.702-11.838 1.063-17.899 1.063-6.028 0-11.973-.357-17.812-1.053l-49.281 210.544-32.065-7.137 49.357-210.869c-11.2-3.963-21.789-9.226-31.582-15.605L29.864 544.95 4.15 524.953 143.419 354.66c-7.979-8.123-15.038-17.154-21.007-26.926l-201.981 94.85-14.27-28.896 202.523-95.104c-3.573-10.383-6.033-21.279-7.238-32.548h-225.164v-32.072h225.164c1.205-11.269 3.665-22.166 7.238-32.548l-202.523-95.104 14.27-28.896 201.981 94.85c5.969-9.771 13.029-18.804 21.007-26.926L4.15-24.953 29.864-44.95l138.753 169.664c9.796-6.38 20.383-11.642 31.582-15.605L150.842-101.76l32.065-7.137 49.281 210.544c5.839-.696 11.784-1.053 17.812-1.053 6.061 0 12.038.361 17.899 1.063Z" />
    ),
  },
};

const bg_shape_7CbkxnXZ8F = {
  name: "Sunburst (Thin) 3",
  description:
    "A small central circle with thin spokes/rays coming out from it in all directions",
  tags: ["geometric", "full", "nature"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="m262.93 122.884 54.252-231.781 32.065 7.137-54.333 232.128c7.988 3.003 15.597 6.785 22.732 11.251L470.225-44.95l25.714 19.997-153.104 187.211c5.605 5.928 10.649 12.392 15.046 19.304L579.658 77.416l14.27 28.896L371.6 210.716c2.416 7.474 4.161 15.246 5.164 23.248h247.043v32.072H376.764c-1.003 8.001-2.748 15.774-5.164 23.248l222.328 104.404-14.27 28.896-221.777-104.146c-4.396 6.912-9.438 13.375-15.046 19.304l153.104 187.211-25.714 19.997-152.579-186.569c-7.136 4.467-14.744 8.248-22.732 11.251l54.333 232.128-32.065 7.137-54.252-231.781c-4.251.428-8.565.647-12.93.647-4.337 0-8.623-.216-12.843-.639l-54.25 231.773-32.065-7.137 54.326-232.097c-7.996-3.001-15.612-6.781-22.756-11.245L29.864 544.95 4.15 524.953l153.063-187.16c-5.616-5.933-10.67-12.404-15.074-19.323L-79.569 422.584l-14.27-28.896L128.412 289.32c-2.422-7.485-4.171-15.269-5.176-23.284h-246.954v-32.072h246.954c1.005-8.014 2.754-15.798 5.176-23.284L-93.839 106.312l14.27-28.896L142.139 181.53c4.404-6.92 9.455-13.388 15.074-19.323L4.15-24.953 29.864-44.95l152.548 186.532c7.146-4.466 14.763-8.246 22.756-11.245L150.842-101.76l32.065-7.137 54.25 231.773c4.22-.423 8.506-.639 12.843-.639 4.365 0 8.679.219 12.93.647Z" />
    ),
  },
};

export const BackgroundShapesPack1 = {
  bg_shape_cnx84ce94t,
  bg_shape_ALA9YxUjew,
  bg_shape_rqX5CzZT3m,
  bg_shape_WkcE2Smgxh,
  bg_shape_6jQPN78FqY,
  bg_shape_bBTRcnGd6X,
  bg_shape_aLc2jwQZQC,
  bg_shape_WzeubNYC72,
  bg_shape_PjU9Pf6JDX,
  bg_shape_UVPcMBSD9Q,
  bg_shape_SGWavy4VPn,
  bg_shape_VMNghKrdn4,
  bg_shape_4zuTZnevCj,
  bg_shape_8A3viHMewd,
  bg_shape_gUpCGbSeaT,
  bg_shape_pxTCPUDfhA,
  bg_shape_h9D9cnUG42,
  bg_shape_pknapYqjk7,
  bg_shape_NDfc52u68g,
  bg_shape_umjBAjn8Yd,
  bg_shape_jnKqU6KWMj,
  bg_shape_ZTyWAGNDiR,
  bg_shape_4DaCxVxKHx,
  bg_shape_i6xbFTFJ2Y,
  bg_shape_7Y7NT8XMvh,
  bg_shape_zwB745tuhc,
  bg_shape_ZeQmApzcJL,
  bg_shape_AUVjHpgbFE,
  bg_shape_fdU6KyzvkF,
  bg_shape_HCYrLChxMd,
  bg_shape_7CbkxnXZ8F,
};
