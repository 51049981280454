import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { beaksPack1 } from "./beaks/BeaksPack1";
import { mouthPack1 } from "./packs/MouthsPack1";
import { MouthsPack2 } from "./packs/MouthsPack2";

export const mouthShapeList = {
  "0": BlankListOption,
  ...mouthPack1,
  ...MouthsPack2,
  ...beaksPack1,
};
