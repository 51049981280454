import React, { Fragment, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { get, random } from "lodash";
import ControlColours from "./colour/ControlColours";
import {
  checkDependencies,
  ControlOptionsList,
  hasVariation,
  updateRecentColours,
} from "../../menu-helper";
import { BlankListOption } from "./options/BlankOption";
import { updateConfig } from "character-creator/utilities/characterSlice";

const mapStateToProps = (state, ownProps) => {
  const dataAttributeValue = get(
    state.config,
    ownProps.control.dataAttribute[0]
  );

  const controlDependencies = ownProps.control.dependencies;

  const dependenciesMet = controlDependencies
    ? checkDependencies(controlDependencies, state.config)
    : true;

  const variationsStateValue =
    ownProps.control.variationsAttribute &&
    get(state.config, ownProps.control.variationsAttribute[0]);

  const selectedOption = ownProps.control.attributeType
    ? dataAttributeValue.id
    : dataAttributeValue;
  return {
    dependenciesMet,
    itemsPerPage: state.menu.controlsPerPage,
    dataAttributeValue: dataAttributeValue,
    selectedOption: selectedOption ? selectedOption : ownProps.selectedOption,
    sectionLevel: ownProps.sectionLevel,
    variationsStateValue,
    recentColours: state.config.recentColours,
  };
};

const updateCharacterConfig = (dispatch, attributeList, updateValue = "") => {
  attributeList.forEach((thisAttribute) => {
    dispatch(updateConfig({ attribute: thisAttribute, value: updateValue }));
  });
};

const ControlContainer = (props) => {
  // Current page, total pages, page limit
  const {
    dependenciesMet,
    control,
    itemsPerPage,
    selectedOption,
    sectionLevel,
    colourControl = false,
    variationsStateValue,
    recentColours,
    hideHeader = false,
  } = props;

  const dispatch = useDispatch();

  const controlOptionsKeys = Object.keys(control.options);
  const controlOptionsValues = Object.values(control.options);

  const totalPages = Math.ceil(controlOptionsValues.length / itemsPerPage);
  const [currentPage, updateCurrentPage] = useState(1);
  currentPage > totalPages && updateCurrentPage(1);

  const pageNumbers = Array.apply(null, Array(totalPages)).map((x, i) => {
    return i + 1;
  });

  // Get current shown items
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const currentOptions = ControlOptionsList({
    controlName: control.id,
    dataAttribute: control.dataAttribute,
    selected: selectedOption,
    previewType: control.previewType,
    previewSide: control.previewSide,
    attributeType: control.attributeType,
    optionKeys: controlOptionsKeys.slice(firstItemIndex, lastItemIndex),
    optionList: controlOptionsValues.slice(firstItemIndex, lastItemIndex),
    onChangeOverride: props.onChangeOverride,
  });

  // Variations
  const optionVariations =
    control.variations &&
    variationsStateValue &&
    control.variations.map((variation) => {
      const selectedMainOption =
        controlOptionsValues[controlOptionsKeys.indexOf(selectedOption)];

      const isVariationAvailable = hasVariation(
        selectedMainOption,
        variation.value
      );

      const variationOptions = selectedMainOption
        ? get(selectedMainOption, `variations.${variation.value}.options`)
        : null;

      let variationObjectsList = isVariationAvailable && {
        "0": BlankListOption,
        ...variationOptions,
      };

      const variationDataAttribute = [
        `${control.variationsAttribute}.${variation.value}`,
      ];

      return (
        isVariationAvailable && (
          <>
            <div className="header">
              {sectionLevel ? (
                <h3>{variation.name}</h3>
              ) : (
                <h4>{variation.name}</h4>
              )}
              {variation.description && (
                <p className="menu-description">{variation.description}</p>
              )}
            </div>
            <fieldset
              id={control.id + "_variations_" + variation.value}
              key={variation.value}
            >
              <legend className="sr-only">{variation.name}</legend>
              <ul>
                {ControlOptionsList({
                  controlName: variation.value,
                  dataAttribute: variationDataAttribute,
                  selected: variationsStateValue[variation.value],
                  previewType: control.previewType,
                  previewSide: control.previewSide,
                  attributeType: control.attributeType,
                  optionKeys: Object.keys(variationObjectsList),
                  optionList: Object.values(variationObjectsList),
                  onChangeOverride: props.onChangeOverride,
                })}
              </ul>
            </fieldset>
          </>
        )
      );
    });

  // Page controls
  const pageControls = pageNumbers.map((number) => {
    const isCurrentPage = currentPage === number;
    const classNames = isCurrentPage ? "selected" : "";

    return (
      <li
        key={number}
        aria-label={
          isCurrentPage
            ? `Current page, page ${number} of ${control.name}`
            : `Page ${number} of ${control.name}`
        }
        data-page={number}
        onClick={() => updateCurrentPage(number)}
        className={classNames ? "selected" : ""}
        aria-current={isCurrentPage ? "true" : "false"}
        data-currentpage={isCurrentPage ? "true" : "false"}
      >
        {number}
      </li>
    );
  });

  const pickRandom = () => {
    let randomValue;
    if (control.attributeType === "multi") {
      randomValue =
        controlOptionsValues[random(1, controlOptionsKeys.length - 1)];
      if (colourControl) {
        let updatedColours = updateRecentColours(recentColours, randomValue);
        updateCharacterConfig(dispatch, ["recentColours"], updatedColours);
      }
    } else {
      randomValue =
        controlOptionsKeys[random(1, controlOptionsKeys.length - 1)];
    }
    updateCharacterConfig(dispatch, control.dataAttribute, randomValue);
  };

  const PageControlsSection = (
    <div
      className="pagination"
      aria-label={`Pagination controls for ${control.name}`}
    >
      <nav className="nav-inline">
        <ul>{pageControls}</ul>
      </nav>
    </div>
  );

  // Return the whole thing
  if (dependenciesMet) {
    return (
      <>
        {!hideHeader && (
          <div className="header">
            {sectionLevel ? <h2>{control.name}</h2> : <h3>{control.name}</h3>}
            <div className="actions">
              <button
                className="button-actions"
                onClick={() => pickRandom()}
                id={control.id + "_random"}
              >
                Random
              </button>
            </div>
            {control.description && (
              <p className="menu-description">{control.description}</p>
            )}
          </div>
        )}

        <fieldset>
          <legend className="sr-only">{control.name}</legend>

          {colourControl && <ControlColours control={control} />}
          <ul>{currentOptions}</ul>
        </fieldset>
        {totalPages > 1 && PageControlsSection}
        {control.variations && (
          <div className="item-variations">{optionVariations}</div>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(ControlContainer);
