import { allColours } from "character-creator/colours/colourSchemes";

import BackHornsList from "character-creator/components/head/extras/horns/back/BackHornsList";
import {
  FrontSideHornsList,
  CentreHornsList,
} from "character-creator/components/head/extras/horns/front/FrontHornsList";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";

const headPath = basePath + "head.";
const hornsPath = headPath + "extras.horns.";

const HornsPage = {
  id: "hornsPage",
  name: "Horns",
  description:
    "Feeling a bit devilish? Or perhaps you want to get in touch with your natural side with some antlers?",
  sections: [
    {
      id: "backHornsGroup",
      name: "Back horns",
      description: "These horns come out from behind the head.",
      symmetryAttribute: hornsPath + "back.symmetry",
      controls: {
        symmetrical: [
          {
            id: "backHornsShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              hornsPath + "back.left.shape",
              hornsPath + "back.right.shape",
            ],
            previewType: "preview",
            options: BackHornsList,
            colourControls: [
              {
                id: "backHornsColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  hornsPath + "back.left.colour",
                  hornsPath + "back.right.colour",
                ],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "backHornsLeftShape",
            name: "Left shape",
            description: "",
            dataAttribute: [hornsPath + "back.left.shape"],
            previewType: "preview",
            options: BackHornsList,
            colourControls: [
              {
                id: "backHornsLeftColour",
                name: "Left Colour",
                description: "",
                dataAttribute: [hornsPath + "back.left.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
          {
            id: "backHornsRightShape",
            name: "Right shape",
            description: "",
            dataAttribute: [hornsPath + "back.right.shape"],
            previewType: "preview",
            options: BackHornsList,
            colourControls: [
              {
                id: "backHornsRightColour",
                name: "Right Colour",
                description: "",
                dataAttribute: [hornsPath + "back.right.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
      },
    },
    {
      id: "frontHornsGroup",
      name: "Front horns",
      description:
        "These horns come out from the front/side the head. Note - at the moment these will be behind any 'Front Hair'.",
      symmetryAttribute: hornsPath + "front.symmetry",
      controls: {
        symmetrical: [
          {
            id: "frontHornsShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              hornsPath + "front.left.shape",
              hornsPath + "front.right.shape",
            ],
            previewType: "preview",
            options: FrontSideHornsList,
            colourControls: [
              {
                id: "frontHornsColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  hornsPath + "front.left.colour",
                  hornsPath + "front.right.colour",
                ],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "frontHornsLeftShape",
            name: "Left shape",
            description: "",
            dataAttribute: [hornsPath + "front.left.shape"],
            previewType: "preview",
            options: FrontSideHornsList,
            colourControls: [
              {
                id: "frontHornsLeftColour",
                name: "Left Colour",
                description: "",
                dataAttribute: [hornsPath + "front.left.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
          {
            id: "frontHornsRightShape",
            name: "Right shape",
            description: "",
            dataAttribute: [hornsPath + "front.right.shape"],
            previewType: "preview",
            options: FrontSideHornsList,
            colourControls: [
              {
                id: "frontHornsColour",
                name: "Right Colour",
                description: "",
                dataAttribute: [hornsPath + "front.right.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
          },
        ],
      },
    },
    {
      id: "centreHornsGroup",
      name: "Centre horn",
      description: "Note - at the moment these will be behind any 'Front Hair'",
      controls: [
        {
          id: "centreHornsShape",
          name: "Shape",
          description: "",
          dataAttribute: [hornsPath + "front.centre.shape"],
          previewType: "preview",
          options: CentreHornsList,
          colourControls: [
            {
              id: "centreHornsColour",
              name: "Colour",
              description: "",
              dataAttribute: [hornsPath + "front.centre.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
        },
      ],
    },
  ],
};

export default HornsPage;
