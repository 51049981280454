import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { ContouringCheeksPack1 } from "./packs/ContouringCheeksPack1";
import { ContouringEyesPack1 } from "./packs/ContouringEyesPack1";
import { ContouringLipsPack1 } from "./packs/ContouringLipsPack1";

export const ContouringCheeksShapeList = {
  "0": BlankListOption,
  ...ContouringCheeksPack1,
};

export const ContouringEyesShapeList = {
  "0": BlankListOption,
  ...ContouringEyesPack1,
};

export const ContouringLipsShapeList = {
  "0": BlankListOption,
  ...ContouringLipsPack1,
};

export const ContouringShapeList = {
  "0": BlankListOption,
  ...ContouringCheeksPack1,
  ...ContouringEyesPack1,
  ...ContouringLipsPack1,
};
