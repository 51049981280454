// Icons
// import icon from "character-creator/assets/menu-icons/streamline-cog.png";

// Sections
import ShadingPage from "./pages/ShadingStyle";

const SettingsMenuConfig = {
  name: "Settings",
  // icon: icon,
  description: "Basic settings for your portrait",
  groups: [
    {
      id: "groupSettingsStyle",
      name: "Style",
      pages: { settingsShadingPage: ShadingPage },
    },
  ],
};

export default SettingsMenuConfig;
