import AllSkintones from "character-creator/colours/skin";
import { BodyScarsList } from "character-creator/components/body/markings/Scars/BodyScarsList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const bodyScarsPath = "base.body.scars.";
const scarsPath1 = bodyScarsPath + "scar1.";
const scarsPath2 = bodyScarsPath + "scar2.";
const scarsPath3 = bodyScarsPath + "scar3.";

const BodyScarsPage = {
  id: "bodyScarsPage",
  name: "Scars",
  description: "",
  controls: [
    /////////////////////////////
    // Extra 1
    /////////////////////////////
    {
      id: "bodyScars1Shape",
      name: "Scar 1",
      description: "",
      dataAttribute: [scarsPath1 + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyScarsList,
      colourControls: [
        {
          id: "bodyScars1Colour",
          name: "Colour",
          description: "",
          dataAttribute: [scarsPath1 + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
      transformControls: [
        {
          id: "bodyScar1TransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath1 + "transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "bodyScar1TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath1 + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "bodyScar1TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath1 + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "bodyScar1TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath1 + "transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    /////////////////////////////
    // Extra 2
    /////////////////////////////
    {
      id: "bodyScars2Shape",
      name: "Scar 2",
      description: "",
      dataAttribute: [scarsPath2 + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyScarsList,
      colourControls: [
        {
          id: "bodyScars2Colour",
          name: "Colour",
          description: "",
          dataAttribute: [scarsPath2 + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
      transformControls: [
        {
          id: "bodyScar2TransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath2 + "transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "bodyScar2TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath2 + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "bodyScar2TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath2 + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "bodyScar2TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath2 + "transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    /////////////////////////////
    // Extra 3
    /////////////////////////////
    {
      id: "bodyScars3Shape",
      name: "Scar 3",
      description: "",
      dataAttribute: [scarsPath3 + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyScarsList,
      colourControls: [
        {
          id: "bodyScars3Colour",
          name: "Colour",
          description: "",
          dataAttribute: [scarsPath3 + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
      transformControls: [
        {
          id: "bodyScar3TransformX",
          name: "Horizontal",
          description: "",
          dataAttribute: [scarsPath3 + "transform.x"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "bodyScar3TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [scarsPath3 + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "bodyScar3TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [scarsPath3 + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "bodyScar3TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [scarsPath3 + "transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default BodyScarsPage;
