import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import {
  ContouringCheeksShapeList,
  ContouringEyesShapeList,
  ContouringLipsShapeList,
} from "./ContouringShapeList";
import { buildTransform } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const contouringConfig = state.config.base.head.face.markings.contouring;

  // ////////////////////////////////
  // Contouring 1
  // ////////////////////////////////

  const contouringCheeksConfig = contouringConfig.contouringCheeks;
  const contouringCheeksObject =
    ownProps.preview && ownProps.contouringCheeks
      ? ownProps.contouringCheeks
      : ContouringCheeksShapeList[contouringCheeksConfig.left.shape];

  const contouringCheeksTransformLeft = buildTransform(
    contouringCheeksConfig.left.transform
  );
  const contouringCheeksTransformRight = buildTransform(
    contouringCheeksConfig.right.transform
  );

  const contouringCheeksColours = contouringCheeksConfig.colour
    ? contouringCheeksConfig.colour
    : { base: state.config.base.skintone.dark };

  const contouringCheeksGradient = contouringCheeksObject.gradient ?? "";

  // ////////////////////////////////
  // Contouring 2
  // ////////////////////////////////

  const contouringEyesConfig = contouringConfig.contouringEyes;
  const contouringEyesObject =
    ownProps.preview && ownProps.contouringEyes
      ? ownProps.contouringEyes
      : ContouringEyesShapeList[contouringEyesConfig.left.shape];

  const contouringEyesGradient = contouringEyesObject.gradient ?? "";

  const contouringEyesTransformLeft = buildTransform(
    contouringEyesConfig.left.transform
  );
  const contouringEyesTransformRight = buildTransform(
    contouringEyesConfig.right.transform
  );

  const contouringEyesColours = contouringEyesConfig.colour
    ? contouringEyesConfig.colour
    : { base: state.config.base.skintone.dark };

  // ////////////////////////////////
  // Contouring 3
  // ////////////////////////////////

  const contouringLipsConfig = contouringConfig.contouringLips;
  const contouringLipsObject =
    ownProps.preview && ownProps.contouringLips
      ? ownProps.contouringLips
      : ContouringLipsShapeList[contouringLipsConfig.shape];

  const contouringLipsGradient = contouringLipsObject.gradient ?? null;

  const contouringLipsTransform = buildTransform(
    contouringLipsConfig.transform
  );

  const contouringLipsColours = contouringLipsConfig.colour
    ? contouringLipsConfig.colour
    : { base: state.config.base.skintone.dark };

  return {
    ContouringCheeks: {
      left: {
        shape: contouringCheeksObject.shape.left,
        colour: contouringCheeksColours,
        transform: contouringCheeksTransformLeft,
        gradient: contouringCheeksGradient.left,
      },
      right: {
        shape: contouringCheeksObject.shape.right,
        colour: contouringCheeksColours,
        transform: contouringCheeksTransformRight,
        gradient: contouringCheeksGradient.right,
      },
    },
    ContouringEyes: {
      left: {
        shape: contouringEyesObject.shape.left,
        colour: contouringEyesColours,
        transform: contouringEyesTransformLeft,
        gradient: contouringEyesGradient.left,
      },
      right: {
        shape: contouringEyesObject.shape.right,
        colour: contouringEyesColours,
        transform: contouringEyesTransformRight,
        gradient: contouringEyesGradient.right,
      },
    },
    ContouringLips: {
      shape: { shape: contouringLipsObject.shape },
      colour: contouringLipsColours,
      transform: contouringLipsTransform,
      gradient: contouringLipsGradient ?? "",
    },
    previewId: ownProps.previewId,
    alternateId: ownProps.alternateId,
    alternateMask: ownProps.alternateMask,
  };
}

const Contouring = (props) => {
  const { ContouringCheeks, ContouringEyes, ContouringLips, previewId } = props;

  if (
    ContouringCheeks.left.shape ||
    ContouringEyes.left.shape ||
    ContouringLips.shape
  ) {
    return (
      <g
        id={previewId ? `g-head-contouring~${previewId}` : "g-head-contouring"}
        mask="url(#head_mask)"
      >
        {ContouringCheeks.left.shape && (
          <Component
            id={
              previewId
                ? `contouring-cheeks-l~${previewId}`
                : "contouring-cheeks-l"
            }
            component={ContouringCheeks.left}
            colour={ContouringCheeks.left.colour}
            preview={previewId ? true : false}
            transform={ContouringCheeks.left.transform}
            strokeOverride={false}
            gradient={ContouringCheeks.left.gradient}
          />
        )}
        {ContouringCheeks.right.shape && (
          <Component
            id={
              previewId
                ? `contouring-cheeks-r~${previewId}`
                : "contouring-cheeks-r"
            }
            component={ContouringCheeks.right}
            colour={ContouringCheeks.right.colour}
            preview={previewId ? true : false}
            transform={ContouringCheeks.right.transform}
            strokeOverride={false}
            gradient={ContouringCheeks.right.gradient}
          />
        )}

        {ContouringEyes.left.shape && (
          <Component
            id={
              previewId ? `contouring-eyes-l~${previewId}` : "contouring-eyes-l"
            }
            component={ContouringEyes.left}
            colour={ContouringEyes.left.colour}
            preview={previewId ? true : false}
            transform={ContouringEyes.left.transform}
            strokeOverride={false}
            gradient={ContouringEyes.left.gradient}
          />
        )}
        {ContouringEyes.right.shape && (
          <Component
            id={
              previewId ? `contouring-eyes-r~${previewId}` : "contouring-eyes-r"
            }
            component={ContouringEyes.right}
            colour={ContouringEyes.right.colour}
            preview={previewId ? true : false}
            transform={ContouringEyes.right.transform}
            strokeOverride={false}
            gradient={ContouringEyes.right.gradient}
          />
        )}
        {ContouringLips.shape && (
          <Component
            id={previewId ? `contouring-lips~${previewId}` : "contouring-lips"}
            component={ContouringLips.shape}
            colour={ContouringLips.colour}
            preview={previewId ? true : false}
            transform={ContouringLips.transform}
            strokeOverride={false}
            // gradient={ContouringLips.gradient}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Contouring);
