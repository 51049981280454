import React from "react";
import CharacterHead from "../../CharacterHead";
import Nose from "./CharacterNose";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Nose
        preview={true}
        previewId={previewId}
        previewTransform={false}
        nose={component}
      />
    </>
  );
};

export const previewViewbox = "200 210 100 100";
