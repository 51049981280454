import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { EyeAccessoriesList, GlassesShineList } from "./EyeAccessoriesList";
import AllGreys from "character-creator/colours/collections/Greys";
import {
  buildTransform,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import { allColours } from "character-creator/colours/colourSchemes";

function mapStateToProps(state, ownProps) {
  // Configs
  const eyeAccessoriesConfig = state.config.clothing.accessories.eyes;
  const accessoryFullConfig = eyeAccessoriesConfig.full;
  // const accessoryLeftConfig = eyeAccessoriesConfig.left;
  // const accessoryRightConfig = eyeAccessoriesConfig.right;

  ////////////////////////
  // Full style
  ////////////////////////
  const AccessoryFullObject =
    ownProps.preview && ownProps.accessory
      ? ownProps.accessory
      : EyeAccessoriesList[accessoryFullConfig.shape];

  const previewBridgeObject =
    ownProps.preview && ownProps.bridge ? ownProps.bridge : null;

  const configBridgeObject =
    accessoryFullConfig.variations.bridge !== "0" &&
    AccessoryFullObject.variations?.bridge?.options[
      accessoryFullConfig.variations.bridge
    ];

  // If using a shape that has left/right defined, join them together to one shape
  let AccessoryFull = AccessoryFullObject;
  if (AccessoryFullObject.shape?.left) {
    AccessoryFull = {
      ...AccessoryFullObject,
      shape: {
        lens: {
          base: (
            <>
              {AccessoryFullObject.shape.left.lens.base}
              {AccessoryFullObject.shape.right.lens.base}
            </>
          ),
        },
        frame: {
          base: (
            <>
              {AccessoryFullObject.shape.left.frame.base}
              {AccessoryFullObject.shape.right.frame.base}
            </>
          ),
        },
        bridgeMask:
          previewBridgeObject || configBridgeObject
            ? {
                shape: {
                  base: AccessoryFullObject.variations.bridge.bridgeMask,
                },
              }
            : null,
        bridge:
          previewBridgeObject || configBridgeObject
            ? previewBridgeObject
              ? previewBridgeObject.shape
              : configBridgeObject.shape
            : null,
      },
    };
  }

  const AccessoryFullColours = {
    frame: accessoryFullConfig.colour.frame
      ? accessoryFullConfig.colour.frame
      : AllGreys[Object.keys(AllGreys)[0]],
    lens: accessoryFullConfig.colour.lens
      ? accessoryFullConfig.colour.lens
      : AllGreys[Object.keys(AllGreys)[1]],
  };

  let fullShineShape;
  if (ownProps.shine && ownProps.shine.shape.left) {
    fullShineShape = {
      base: (
        <>
          {ownProps.shine.shape.left.base}
          {ownProps.shine.shape.right.base}
        </>
      ),
    };
  } else if (ownProps.shine && !ownProps.shine.shape.left) {
    fullShineShape = {
      base: <>{ownProps.shine.shape.base}</>,
    };
  } else if (
    accessoryFullConfig.variations.shine.shape !== "0" &&
    GlassesShineList[accessoryFullConfig.variations.shine.shape].shape.left
  ) {
    // If left/right
    fullShineShape = {
      base: (
        <>
          {
            GlassesShineList[accessoryFullConfig.variations.shine.shape].shape
              .left.base
          }
          {
            GlassesShineList[accessoryFullConfig.variations.shine.shape].shape
              .right.base
          }
        </>
      ),
    };
  } else if (
    accessoryFullConfig.variations.shine.shape !== "0" &&
    !GlassesShineList[accessoryFullConfig.variations.shine.shape].shape.left
  ) {
    fullShineShape = {
      base: (
        <>
          {
            GlassesShineList[accessoryFullConfig.variations.shine.shape].shape
              .base
          }
        </>
      ),
    };
  } else {
    fullShineShape = null;
  }

  const AccessoryFullShine = {
    shape: fullShineShape,
    colour: accessoryFullConfig.variations.shine.colour
      ? accessoryFullConfig.variations.shine.colour
      : allColours[Object.keys(allColours)[5]],
    transform: !ownProps.preview
      ? buildTransform(accessoryFullConfig.variations.shine.transform)
      : "",
  };
  ////////////////////////
  // Left style
  ////////////////////////

  ////////////////////////
  // Right style
  ////////////////////////

  ////////////////////////
  // Return
  ////////////////////////
  return {
    AccessoryFull: {
      object: AccessoryFull,
      colour: AccessoryFullColours,
      transform: !ownProps.preview
        ? translateTransformObject(
            buildTransform(accessoryFullConfig.transform)
          )
        : "",
      lensTransform: buildTransform(accessoryFullConfig.lensTransform),
      bridgeTransform: buildTransform(accessoryFullConfig.bridgeTransform),
      shine: AccessoryFullShine,
    },
  };
}

const EyeAccessories = (props) => {
  const { AccessoryFull, previewId } = props;

  if (AccessoryFull.object.shape) {
    return (
      <g
        id={
          previewId ? `g-accessories-eyes~${previewId}` : "g-accessories-eyes"
        }
        transform-origin="50% 50%"
        {...AccessoryFull.transform}
      >
        {/* Full */}
        {AccessoryFull.object.shape && (
          <g
            id={
              previewId
                ? `g-accessories-eyes-full~${previewId}`
                : "g-accessories-eyes-full"
            }
          >
            {/* Bridge mask */}
            {AccessoryFull.object.shape.bridgeMask && (
              <Component
                id={
                  previewId
                    ? `eye-accessory-full-bridgeMask~${previewId}`
                    : "eye-accessory-full-bridgeMask"
                }
                invisible={true}
                component={AccessoryFull.object.shape.bridgeMask}
                preview={previewId ? true : false}
              />
            )}
            {/* Bridge */}
            {AccessoryFull.object.shape.bridge && (
              <Component
                id={
                  previewId
                    ? `eye-accessory-full-bridge~${previewId}`
                    : "eye-accessory-full-bridge"
                }
                component={{
                  ...AccessoryFull.object,
                  shape: AccessoryFull.object.shape.bridge,
                }}
                colour={AccessoryFull.colour.frame}
                preview={previewId ? true : false}
                transform={AccessoryFull.bridgeTransform}
                maskId={
                  previewId
                    ? `eye-accessory-full-bridgeMask~${previewId}_mask`
                    : "eye-accessory-full-bridgeMask_mask"
                }
              />
            )}

            {/* Lens */}
            {AccessoryFull.object.shape.lens && (
              <Component
                id={
                  previewId
                    ? `eye-accessory-full-lens~${previewId}`
                    : "eye-accessory-full-lens"
                }
                component={{
                  ...AccessoryFull.object,
                  shape: AccessoryFull.object.shape.lens,
                }}
                colour={AccessoryFull.colour.lens}
                preview={previewId ? true : false}
                transform={AccessoryFull.lensTransform}
              />
            )}
            {/* Lens shine */}
            {AccessoryFull.shine.shape && (
              <Component
                id={
                  previewId
                    ? `eye-accessory-full-lens-shine~${previewId}`
                    : "eye-accessory-full-lens-shine"
                }
                component={{
                  ...AccessoryFull.object,
                  shape: AccessoryFull.shine.shape,
                }}
                colour={AccessoryFull.shine.colour}
                preview={previewId ? true : false}
                transform={AccessoryFull.shine.transform}
                maskId={
                  previewId
                    ? `eye-accessory-full-lens~${previewId}_mask`
                    : "eye-accessory-full-lens_mask"
                }
                strokeOverride={false}
              />
            )}

            {/* Frame */}
            {AccessoryFull.object.shape.frame && (
              <Component
                id={
                  previewId
                    ? `eye-accessory-full-frame~${previewId}`
                    : "eye-accessory-full-frame"
                }
                component={{
                  ...AccessoryFull.object,
                  shape: AccessoryFull.object.shape.frame,
                }}
                colour={AccessoryFull.colour.frame}
                preview={previewId ? true : false}
              />
            )}
          </g>
        )}

        {/* Left */}

        {/* Right */}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(EyeAccessories);
