import { AllBlues } from "./collections/Blues";
import { AllBrowns } from "./collections/Browns";
import { AllGreens } from "./collections/Greens";
import { AllGreys } from "./collections/Greys";
import { AllMetals } from "./collections/Metals";
import { AllPinks } from "./collections/Pinks";
import { AllPurples } from "./collections/Purples";
import { AllReds } from "./collections/Reds";

export const IrisColours = {
  ...AllBlues,
  ...AllBrowns,
  ...AllGreens,
  ...AllGreys,
  ...AllMetals,
  ...AllPinks,
  ...AllPurples,
  ...AllReds,
};

export const EyeballColours = {
  colour_eyeball_29JeE: {
    id: "colour_eyeball_29JeE",
    name: "Default",
    base: "#e6e7e8",
    dark: "#d6d7d8",
  },
  ...IrisColours,
};

export const pupilColours = Object.values(IrisColours).map(
  (irisColour, index) => {
    return {
      [irisColour.id]: {
        key: index,
        id: irisColour.id,
        name: irisColour.name,
        outline: irisColour.outline,
        base: irisColour.outline,
      },
    };
  }
);

export const ShineColours = {
  colour_shine_29JeE: {
    id: "colour_shine_29JeE",
    name: "Default",
    base: "#eeeeee",
  },
  ...IrisColours,
};
