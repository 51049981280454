import React from "react";
import { viewbox } from "../horns/Viewboxes";
import { PreviewBase } from "../horns/front/Preview";

export const antennae_QGMbUMRkVn = {
  name: "Antennae 1",
  description: "Slim, mid-length inward curving horns on the top of the head",
  tags: ["slim", "top", "medium", "curved"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M204.215 216.37c4.91-1.664 4.632-8.54.741-12.089-1.634-1.491-19.222-12.224-24.073-47.726-.505-3.697 9.65-5.865 7.46-13.826-1.498-5.444-26.333-6.275-23.895 5.2 1.689 7.951 8.445 4.844 8.516 9.08.405 24.003 17.489 64.025 31.251 59.361Z" />
      ),
      basic: {
        light: (
          <path d="M188.377 142.857a4.299 4.299 0 0 1-.68 2.298c-.23.369-.696 1.204-1.249 1.109-.641-.11-.632-1.08-.652-1.554-.049-1.127-.416-2.163-1.32-2.889-2.427-1.948-6.146-1.777-9.062-1.647-1.554.069-3.026.256-4.498.779-1.96.696-4.267 1.981-4.411 4.333-.061.999.76 1.803 1.461 2.4.259.221.713.455.842.789.19.495-.736.566-.999.547-1.218-.092-2.505-.759-3.466-1.701-1.446-10.836 22.529-9.938 24-4.592l.034.128Z" />
        ),
        dark: (
          <path d="M164.441 147.896c6.705 4.886 18.914 4.522 24.211-3.11.089 5.518-6.257 7.815-7.557 10.545-2.272.76-5.019 1.069-8.32.66-1.112-2.73-6.797-.862-8.327-8.062l-.007-.033Zm43.352 61.716c.441 2.899-.64 5.762-3.578 6.758-10.917 3.699-23.924-20.72-29.038-42.956.36-1.427.526-1.45.283.612-.221 1.886 20.287 45.546 32.333 35.586Zm-32.575-68.972c4.676-.496 8.631.606 8.828 2.458.196 1.853-3.44 3.76-8.116 4.256-4.676.496-8.632-.606-8.828-2.458-.197-1.853 3.44-3.76 8.116-4.256Z" />
        ),
        darker: (
          <path
            id="darker12"
            d="M175.555 142.775c-3.806.403-6.787 1.758-6.653 3.023.134 1.265 3.333 1.964 7.139 1.561 3.806-.404 6.787-1.759 6.653-3.024-.134-1.265-3.333-1.964-7.139-1.56Z"
          />
        ),
      },
    },
    right: {
      base: (
        <path d="M295.785 216.37c-4.91-1.664-4.632-8.54-.741-12.089 1.634-1.491 19.222-12.224 24.073-47.726.505-3.697-9.65-5.865-7.46-13.826 1.498-5.444 26.333-6.275 23.895 5.2-1.689 7.951-8.445 4.844-8.516 9.08-.405 24.003-17.489 64.025-31.251 59.361Z" />
      ),
      basic: {
        light: (
          <path d="M311.623 142.857c.001.803.229 1.575.68 2.298.23.369.696 1.204 1.249 1.109.641-.11.632-1.08.652-1.554.049-1.127.416-2.163 1.32-2.889 2.427-1.948 6.146-1.777 9.062-1.647 1.554.069 3.026.256 4.498.779 1.96.696 4.267 1.981 4.411 4.333.061.999-.76 1.803-1.461 2.4-.259.221-.713.455-.842.789-.19.495.736.566.999.547 1.218-.092 2.505-.759 3.466-1.701 1.446-10.836-22.529-9.938-24-4.592l-.034.128Z" />
        ),
        dark: (
          <path d="M335.559 147.896c-6.705 4.886-18.914 4.522-24.211-3.11-.089 5.518 6.257 7.815 7.557 10.545 2.272.76 5.019 1.069 8.32.66 1.112-2.73 6.797-.862 8.327-8.062l.007-.033Zm-43.352 61.716c-.441 2.899.64 5.762 3.578 6.758 10.917 3.699 23.924-20.72 29.038-42.956-.36-1.427-.526-1.45-.283.612.221 1.886-20.287 45.546-32.333 35.586Zm32.575-68.972c-4.676-.496-8.631.606-8.828 2.458-.196 1.853 3.44 3.76 8.116 4.256 4.676.496 8.632-.606 8.828-2.458.197-1.853-3.44-3.76-8.116-4.256Z" />
        ),
        darker: (
          <path d="M324.445 142.775c3.806.403 6.787 1.758 6.653 3.023-.134 1.265-3.333 1.964-7.139 1.561-3.806-.404-6.787-1.759-6.653-3.024.134-1.265 3.333-1.964 7.139-1.56Z" />
        ),
      },
    },
  },
};

export const antennae_hJRF2vbB32 = {
  name: "Horn 1",
  description: "Slim, mid-length inward curving horns on the top of the head",
  tags: ["slim", "top", "medium", "curved"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M204.215 216.734c4.91-1.664 4.632-8.54.741-12.09-1.634-1.49-21.469-11.988-24.073-47.726-.117-1.604 10.92-7.977 9.227-23.493-.1-.923-.371-4.371-.552-4.552-.094-.095-23.06 5.519-18.731 24.699.343 1.52 1.992 2.835 2.145 5.331.522 8.522 11.416 58.667 31.243 57.831" />
      ),
      basic: {
        light: (
          <path d="M189.838 130.615c-1.967.965-3.907 1.924-5.414 3.58-2.646 2.905-3.89 6.765-5.538 10.253-.7 1.481-1.701 3.645-3.613 3.706-2.477.079-3.864-2.441-3.865-4.648 0-.298.022-.595.062-.889 4.186-10.457 18.015-13.817 18.088-13.744.083.083.184.848.28 1.742Z" />
        ),
        dark: (
          <path d="M170.284 148.893c3.019 8.778 17.224 2.464 19.635-8.344.058-.26.11-.46.157-.608-1.357 10.519-8.824 15.327-9.181 16.846-2.238 1.032-4.966 1.338-8.276.44-.544-1.492-1.535-2.516-1.792-3.655a21.067 21.067 0 0 1-.543-4.679Zm37.509 61.083c.441 2.899-.64 5.762-3.578 6.758-12.942.546-22.077-20.628-27.006-37.771 5.185 11.376 20.721 39.168 30.584 31.013Z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M295.785 216.734c-4.91-1.664-4.632-8.54-.741-12.09 1.634-1.49 21.469-11.988 24.073-47.726.117-1.604-10.92-7.977-9.227-23.493.1-.923.371-4.371.552-4.552.094-.095 23.06 5.519 18.731 24.699-.343 1.52-1.992 2.835-2.145 5.331-.522 8.522-11.416 58.667-31.243 57.831" />
      ),
      basic: {
        light: (
          <path d="M310.162 130.615c1.967.965 3.907 1.924 5.414 3.58 2.646 2.905 3.89 6.765 5.538 10.253.7 1.481 1.701 3.645 3.613 3.706 2.477.079 3.864-2.441 3.865-4.648 0-.298-.022-.595-.062-.889-4.186-10.457-18.015-13.817-18.088-13.744-.083.083-.184.848-.28 1.742Z" />
        ),
        dark: (
          <path d="M329.716 148.893c-3.019 8.778-17.224 2.464-19.635-8.344-.058-.26-.11-.46-.157-.608 1.357 10.519 8.824 15.327 9.181 16.846 2.238 1.032 4.966 1.338 8.276.44.544-1.492 1.535-2.516 1.792-3.655.375-1.66.545-3.217.543-4.679Zm-37.509 61.083c-.441 2.899.64 5.762 3.578 6.758 12.942.546 22.077-20.628 27.006-37.771-5.185 11.376-20.721 39.168-30.584 31.013Z" />
        ),
      },
    },
  },
};

export const antennae_V4SJxzia5q = {
  name: "Horn 1",
  description: "Slim, mid-length inward curving horns on the top of the head",
  tags: ["slim", "top", "medium", "curved"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M203.9 207.546c2.296 9.665-11.28 6.021-15.005 4.673-9.781-3.538-18.901-8.471-26.13-15.867-5.622-5.751-10.5-12.533-12.225-20.436-4.18-19.158 2.429-45.129 25.796-52.289 12.324-3.777 27.465 1.978 33.818 13.665 7.148 13.148 4.101 29.206-10.307 35.542-5.717 2.514-11.737 2.44-17.235-.665-2.568-1.45-5.162-4.822-4.222-7.941 1.547-5.137 5.85 1.168 7.58 2.418.882.637 1.904 1.048 2.949 1.334 6.555 1.794 12.461-1.492 15.278-7.565 2.567-5.534 3.212-12.196.741-17.876-4.524-10.4-18.521-15.426-28.666-10.235-7.491 3.832-14.262 10.676-16.47 18.482-3.142 11.106-4.98 27.153 9.448 41.128 5.226 5.062 15.2 10.905 22.191 12.398 3.068.655 11.723.133 12.459 3.234Z" />
      ),
      basic: {
        light: (
          <path d="M152.814 146c-1.316 6.5-1.735 11.798.077 6.612 7.468-21.374 29.858-34.846 49.222-20.518 4.642 3.434 7.862 6.922 10.121 9.888a31.652 31.652 0 0 0-2.08-4.69c-6.353-11.687-21.494-17.442-33.818-13.665-11.972 3.668-19.545 12.275-23.522 22.373Z" />
        ),
        dark: (
          <path d="M213.367 156.391c-2.035 4.29-4.958 8.311-8.74 11.229-6.125 4.725-13.027 5.442-20.118 2.68-2.145-.835-4.565-1.912-6.124-3.677a4.34 4.34 0 0 1-.109-.128c.422 2.365 2.386 4.573 4.336 5.674 5.498 3.105 11.518 3.179 17.235.665 7.657-3.367 12.106-9.48 13.52-16.443Zm-6.887-6.825c.022-.103.042-.206.058-.31.148-.939.17-1.917.098-2.862-.204-2.652-1.114-5.505-2.451-7.8-1.82-3.121-4.713-4.998-7.876-6.565-3.945-1.955-7.673-3.159-12.097-3.26-10.205-.23-20.764 6.242-23.944 16.24-.938 2.951-1.213 5.834-1.236 8.737.241-1.02.501-2.008.77-2.96 2.208-7.806 8.979-14.65 16.47-18.482 10.145-5.191 24.142-.165 28.666 10.235.972 2.233 1.461 4.618 1.542 7.027Zm-2.803 57.446c-6.177 10.713-52.974-10.195-53.672-36.487-.013-.51-.17-.743-.41-.756a50.97 50.97 0 0 0 .945 6.147c1.725 7.903 6.603 14.685 12.225 20.436 7.229 7.396 16.349 12.329 26.13 15.867 3.725 1.348 17.301 4.992 15.005-4.673a1.784 1.784 0 0 0-.223-.534Z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M296.1 204.401c-2.296 9.665 11.28 6.022 15.005 4.674 9.781-3.538 18.901-8.471 26.13-15.867 5.622-5.752 10.5-12.533 12.225-20.436 4.18-19.158-2.429-45.13-25.796-52.29-12.324-3.776-27.465 1.979-33.818 13.666-7.148 13.148-4.101 29.206 10.307 35.542 5.717 2.513 11.737 2.44 17.235-.665 2.568-1.451 5.162-4.822 4.222-7.941-1.547-5.137-5.85 1.167-7.58 2.417-.882.638-1.904 1.049-2.949 1.335-6.555 1.793-12.461-1.492-15.278-7.566-2.567-5.533-3.212-12.195-.741-17.876 4.524-10.399 18.521-15.425 28.666-10.234 7.491 3.832 14.262 10.676 16.47 18.481 3.142 11.107 4.98 27.154-9.448 41.129-5.226 5.061-15.2 10.905-22.191 12.397-3.068.656-11.723.134-12.459 3.234Z" />
      ),
      basic: {
        light: (
          <path d="M347.186 142.855c1.316 6.5 1.735 11.799-.077 6.613-7.468-21.375-29.858-34.846-49.222-20.519-4.642 3.435-7.862 6.922-10.121 9.889a31.56 31.56 0 0 1 2.08-4.69c6.353-11.687 21.494-17.442 33.818-13.666 11.972 3.669 19.545 12.275 23.522 22.373Z" />
        ),
        dark: (
          <path d="M286.633 153.246c2.035 4.291 4.958 8.312 8.74 11.23 6.125 4.724 13.027 5.442 20.118 2.68 2.145-.836 4.565-1.912 6.124-3.678.037-.042.074-.084.109-.127-.422 2.365-2.386 4.573-4.336 5.674-5.498 3.105-11.518 3.178-17.235.665-7.657-3.368-12.106-9.481-13.52-16.444Zm6.887-6.825a5.293 5.293 0 0 1-.058-.31 12.458 12.458 0 0 1-.098-2.862c.204-2.652 1.114-5.504 2.451-7.799 1.82-3.121 4.713-4.998 7.876-6.565 3.945-1.955 7.673-3.16 12.097-3.26 10.205-.231 20.764 6.242 23.944 16.24.938 2.95 1.213 5.834 1.236 8.737a70.565 70.565 0 0 0-.77-2.961c-2.208-7.805-8.979-14.649-16.47-18.481-10.145-5.191-24.142-.165-28.666 10.234-.972 2.234-1.461 4.619-1.542 7.027Zm2.803 57.446c6.177 10.714 52.974-10.195 53.672-36.486.013-.51.17-.744.41-.756a51 51 0 0 1-.945 6.147c-1.725 7.903-6.603 14.684-12.225 20.436-7.229 7.396-16.349 12.329-26.13 15.867-3.725 1.348-17.301 4.991-15.005-4.674.045-.191.121-.369.223-.534Z" />
        ),
      },
    },
  },
};

export const AntennaePack1 = {
  antennae_QGMbUMRkVn,
  antennae_hJRF2vbB32,
  antennae_V4SJxzia5q,
};
