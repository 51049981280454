import { allColours } from "character-creator/colours/colourSchemes";

import { BodyMarkingsList } from "character-creator/components/body/markings/BodyMarkingsList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";
const bodyPath = basePath + "body.";
const bodyMarkingsPath = bodyPath + "markings.";

const BodyMarkingsPage = {
  id: "bodyMarkingsPage",
  name: "Markings",
  description: "",
  controls: [
    {
      id: "bodyMarking1Shape",
      name: "Marking 1",
      description: "",
      dataAttribute: [bodyMarkingsPath + "marking1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyMarkingsList,
      colourControls: [
        {
          id: "bodyMarking1Colour",
          name: "Colour",
          description: "",
          dataAttribute: [bodyMarkingsPath + "marking1.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "bodyMarking1TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [bodyMarkingsPath + "marking1.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "bodyMarking2Shape",
      name: "Marking 2",
      description: "",
      dataAttribute: [bodyMarkingsPath + "marking2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyMarkingsList,
      colourControls: [
        {
          id: "bodyMarking2Colour",
          name: "Colour",
          description: "",
          dataAttribute: [bodyMarkingsPath + "marking2.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "bodyMarking2TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [bodyMarkingsPath + "marking2.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "bodyMarking3Shape",
      name: "Marking 3",
      description: "",
      dataAttribute: [bodyMarkingsPath + "marking3.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: BodyMarkingsList,
      colourControls: [
        {
          id: "bodyMarking3Colour",
          name: "Colour",
          description: "",
          dataAttribute: [bodyMarkingsPath + "marking3.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "bodyMarking3TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [bodyMarkingsPath + "marking3.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
  /**
  sections: [
    {
      id: "bodyFreckles",
      name: "Freckles",
      description: "",
      symmetrical: [
        {
          id: "bodyFrecklesShape",
          name: "Style",
          description: "",
          dataAttribute: [bodyMarkingsPath + "freckles.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyFrecklesList,
          colourControls: [
            {
              id: "bodyFrecklesColour",
              name: "Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "freckles.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyFrecklesTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "freckles.left.transform.opacity",
                bodyMarkingsPath + "freckles.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
    },
    {
      id: "bodyStripes",
      name: "Stripes",
      description: "",
      symmetrical: [
        {
          id: "bodyStripesShape",
          name: "Style",
          description: "",
          dataAttribute: [
            bodyMarkingsPath + "stripes.left.shape",
            bodyMarkingsPath + "stripes.right.shape",
          ],
          variationAttribute: "",
          previewType: "preview",
          options: BodyStripesList,
          colourControls: [
            {
              id: "bodyStripesColour",
              name: "Colour",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "stripes.left.colour",
                bodyMarkingsPath + "stripes.right.colour",
              ],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyStripesTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "stripes.left.transform.opacity",
                bodyMarkingsPath + "stripes.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
      asymmetrical: [
        {
          id: "bodyStripesLeftShape",
          name: "Left Shape",
          description: "",
          dataAttribute: [bodyMarkingsPath + "stripes.left.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyStripesList,
          colourControls: [
            {
              id: "bodyStripesLeftColour",
              name: "Left Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "stripes.left.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyStripesLeftTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "stripes.left.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
        {
          id: "bodyStripesRightShape",
          name: "Right Shape",
          description: "",
          dataAttribute: [bodyMarkingsPath + "stripes.right.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyStripesList,
          colourControls: [
            {
              id: "bodyStripesRightColour",
              name: "Right Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "stripes.right.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyStripesRightTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "stripes.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
    },
    {
      id: "bodyCentres",
      name: "Centres",
      description: "",
      symmetrical: [
        {
          id: "bodyCentresShape",
          name: "Style",
          description: "",
          dataAttribute: [bodyMarkingsPath + "centre.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyCentresList,
          colourControls: [
            {
              id: "bodyCentresColour",
              name: "Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "centre.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyCentresTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [bodyMarkingsPath + "centre.transform.opacity"],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
    },
    {
      id: "bodySpots",
      name: "Spots",
      description: "",
      symmetrical: [
        {
          id: "bodySpotsShape",
          name: "Style",
          description: "",
          dataAttribute: [
            bodyMarkingsPath + "spots.left.shape",
            bodyMarkingsPath + "spots.right.shape",
          ],
          variationAttribute: "",
          previewType: "preview",
          options: BodySpotsList,
          colourControls: [
            {
              id: "bodySpotsColour",
              name: "Colour",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "spots.left.colour",
                bodyMarkingsPath + "spots.right.colour",
              ],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodySpotsTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "spots.left.transform.opacity",
                bodyMarkingsPath + "spots.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
      asymmetrical: [
        {
          id: "bodySpotsLeftShape",
          name: "Left Shape",
          description: "",
          dataAttribute: [bodyMarkingsPath + "spots.left.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodySpotsList,
          colourControls: [
            {
              id: "bodySpotsLeftColour",
              name: "Left Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "spots.left.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodySpotsLeftTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "spots.left.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
        {
          id: "bodySpotsRightShape",
          name: "Right Shape",
          description: "",
          dataAttribute: [bodyMarkingsPath + "spots.right.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyStripesList,
          colourControls: [
            {
              id: "bodySpotsRightColour",
              name: "Right Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "spots.right.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodySpotsRightTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "spots.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
    },
    {
      id: "bodyPatches",
      name: "Patches",
      description: "",
      symmetrical: [
        {
          id: "bodyPatchesShape",
          name: "Style",
          description: "",
          dataAttribute: [
            bodyMarkingsPath + "patches.left.shape",
            bodyMarkingsPath + "patches.right.shape",
          ],
          variationAttribute: "",
          previewType: "preview",
          options: BodyPatchesList,
          colourControls: [
            {
              id: "bodyPatchesColour",
              name: "Colour",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "patches.left.colour",
                bodyMarkingsPath + "patches.right.colour",
              ],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyPatchesTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [bodyMarkingsPath + "patches.transform.opacity"],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
      asymmetrical: [
        {
          id: "bodyPatchesLeftShape",
          name: "Left Shape",
          description: "",
          dataAttribute: [bodyMarkingsPath + "patches.left.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyPatchesList,
          colourControls: [
            {
              id: "bodyPatchesLeftColour",
              name: "Left Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "patches.left.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyPatchesLeftTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "patches.left.transform.opacity",
                bodyMarkingsPath + "patches.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
        {
          id: "bodyPatchesRightShape",
          name: "Right Shape",
          description: "",
          dataAttribute: [bodyMarkingsPath + "patches.right.shape"],
          variationAttribute: "",
          previewType: "preview",
          options: BodyStripesList,
          colourControls: [
            {
              id: "bodyPatchesRightColour",
              name: "Right Colour",
              description: "",
              dataAttribute: [bodyMarkingsPath + "patches.right.colour"],
              attributeType: "multi",
              previewType: "swatch",
              options: allColours,
            },
          ],
          transformControls: [
            {
              id: "bodyPatchesRightTransformO",
              name: "Opacity",
              description: "",
              dataAttribute: [
                bodyMarkingsPath + "patches.right.transform.opacity",
              ],
              range: {
                min: 0.25,
                max: 1,
                step: 0.25,
              },
            },
          ],
        },
      ],
    },
  ],
   */
};

export default BodyMarkingsPage;
