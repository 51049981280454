import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} head={component} />
    </>
  );
};

export const previewViewbox = "125 125 250 250";

export const bodyMaskId = "body_mask";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const headTemplate = {
  name: "Head 1",
  description: "A round face",
  tags: ["round", "humanoid"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: <ellipse cx="250" cy="246.78" rx="83.86" ry="88.63" />,
  },
};
