import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import AllBlues from "character-creator/colours/collections/Blues";
import { EarAccessoriesList } from "./EarAccessoriesList";

function mapStateToProps(state, ownProps) {
  const { side, layerConfig } = ownProps;
  let earConfig = state.config.base.head.ears[side];
  let accessoryObject =
    ownProps.accessory ?? EarAccessoriesList[layerConfig.shape];
  let accessoryShape = null;

  // initial config
  if (accessoryObject.shape[earConfig.shape]) {
    accessoryShape =
      accessoryObject.shape[earConfig.shape][side].back ??
      accessoryObject.shape[earConfig.shape][side];
  } else if (accessoryObject.shape[side]) {
    accessoryShape =
      accessoryObject.shape[side].back ?? accessoryObject.shape[side];
  }

  const colour =
    layerConfig && layerConfig.colour !== ""
      ? layerConfig.colour
      : AllBlues[Object.keys(AllBlues)[3]];

  const AccessoryLayer = {
    shape: accessoryShape,
    colour: colour,
  };

  return {
    AccessoryLayer,
  };
}

const EarAccessoryBack = (props) => {
  const { AccessoryLayer, previewId, layer, side } = props;

  if (AccessoryLayer.shape && AccessoryLayer.shape !== "0") {
    return (
      <Component
        id={
          previewId
            ? `ear-accessory-layer-back-${layer}-${side}~${previewId}`
            : `ear-accessory-layer-back-${layer}-${side}`
        }
        component={AccessoryLayer}
        colour={AccessoryLayer.colour}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(EarAccessoryBack);
