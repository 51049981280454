export const tipsDescription = {
  name: "Tip",
  description: "",
};

/*

1: {
      name: "Tip 1",
      description: "",
      shape: {},
    },

*/
