import React from "react";
import { connect, useDispatch } from "react-redux";
import { updateConfig } from "character-creator/utilities/characterSlice";
import { get } from "lodash";

const mapStateToProps = (state, ownProps) => {
  const controlsConfig = ownProps.controls.map((control, index) => {
    const controlValue = get(state.config, control.dataAttribute[0]);
    return {
      key: index,
      value: controlValue,
      ...control,
    };
  });

  return {
    controlsConfig,
  };
};

const ControlsTransform = (props) => {
  const { controlsConfig } = props;
  const dispatch = useDispatch();

  const updateCharacterConfig = (attributeList, updateValue) => {
    attributeList.forEach((thisAttribute) => {
      dispatch(updateConfig({ attribute: thisAttribute, value: updateValue }));
    });
  };

  const controlsList = controlsConfig.map((control, index) => {
    const updateSymmetricalConfig = (dataAttributeArray, value) => {
      if (!!control.transformOpposite) {
        // Left
        updateCharacterConfig([dataAttributeArray[0]], value);
        // Right
        updateCharacterConfig([dataAttributeArray[1]], -value);
      } else {
        updateCharacterConfig(dataAttributeArray, value);
      }
    };

    return (
      <div key={index} className="control-container">
        <label key={index} htmlFor={control.id}>
          {control.name}
          {control.description && (
            <p className="menu-description">{control.description}</p>
          )}
        </label>
        <input
          type="range"
          min={control.range.min}
          max={control.range.max}
          step={control.range.step ? control.range.step : 1}
          value={control.value}
          className="slider"
          id={control.id}
          onChange={($this) =>
            updateSymmetricalConfig(control.dataAttribute, $this.target.value)
          }
        ></input>
      </div>
    );
  });

  /**
   * Build the lot!
   * */

  return (
    <>
      <div className="header">
        {" "}
        <h3>Transform</h3>
      </div>
      <fieldset>
        <legend className="sr-only">Transform</legend>
        {controlsList}
      </fieldset>
    </>
  );
};

export default connect(mapStateToProps)(ControlsTransform);
