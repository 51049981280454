import {
  colourStyles,
  outlineStyles,
  // shadingStyles,
} from "character-creator/components/StyleOptions";

const ShadingPage = {
  id: "shadingPage",
  name: "Shading and Outline",
  description: "Choose the style of art to use.",
  controls: [
    {
      id: "colourStyle",
      name: "Colours",
      dataAttribute: ["style.colour"],
      previewType: "preview",
      options: colourStyles,
    },
    // {
    //   id: "shadingStyle",
    //   name: "Shading",
    //   dataAttribute: ["style.shading"],
    //   previewType: "preview",
    //   options: shadingStyles,
    //   dependencies: [
    //     {
    //       description: "Must not be colouring style",
    //       attribute: ["style.colour"],
    //       value: ["coloursNormal"],
    //     },
    //   ],
    // },
    {
      id: "outlineStyle",
      name: "Outline",
      dataAttribute: ["style.outline"],
      previewType: "preview",
      options: outlineStyles,
      dependencies: [
        {
          description: "Must not be colouring style",
          attribute: ["style.colour"],
          value: ["coloursNormal"],
        },
      ],
    },
  ],
};

export default ShadingPage;
