import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Eyes from "../CharacterEyes";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Eyes preview={true} previewId={previewId} pupil={component} />
    </>
  );
};

export const viewbox = "170 205 80 80";
