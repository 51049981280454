const colour_brown_wbuWb = {
  id: "colour_brown_wbuWb",
  name: "Deep Red Brown",
  outline: "hsl(355, 66%, 2%)",
  darker: "hsl(355, 66%, 8%)",
  dark: "hsl(355, 65%, 12%)",
  base: "hsl(355, 47%, 20%)",
  light: "hsl(355, 36%, 30%)",
  lighter: "hsl(355, 17%, 61%)",
};

const colour_brown_HIGYU = {
  id: "colour_brown_HIGYU",
  name: "Mid Red Brown",
  outline: "hsl(9, 49%, 10%)",
  darker: "hsl(9, 49%, 22%)",
  dark: "hsl(9, 39%, 37%)",
  base: "hsl(9, 46%, 65%)",
  light: "hsl(10, 45%, 76%)",
  lighter: "hsl(8, 46%, 84%)",
};

const colour_brown_Z9NyT = {
  id: "colour_brown_Z9NyT",
  name: "Mid Red Brown 2",
  outline: "#231812",
  darker: "#4E291D",
  dark: "#864A27",
  base: "#AB6F42",
  light: "#C4936A",
  lighter: "#E1B090",
};

const colour_brown_u5mhz = {
  id: "colour_brown_u5mhz",
  name: "Mid Brown",
  outline: "hsl(22, 45%, 10%)",
  darker: "hsl(22, 40%, 24%)",
  dark: "hsl(23, 40%, 43%)",
  base: "hsl(23, 36%, 55%)",
  light: "hsl(23, 46%, 68%)",
  lighter: "hsl(22, 46%, 74%)",
};

const colour_brown_fhVTO = {
  id: "colour_brown_fhVTO",
  name: "Mid Brown 2",
  outline: "hsl(23, 33%, 15%)",
  darker: "hsl(23, 33%, 28%)",
  dark: "hsl(23, 38%, 56%)",
  base: "hsl(22, 46%, 68%)",
  light: "hsl(22, 51%, 82%)",
  lighter: "hsl(21, 63%, 89%)",
};

const colour_brown_wKvP8 = {
  id: "colour_brown_wKvP8",
  name: "Mid Brown 3",
  outline: "hsl(23, 31%, 15%)",
  darker: "hsl(23, 31%, 28%)",
  dark: "hsl(24, 19%, 56%)",
  base: "hsl(21, 28%, 72%)",
  light: "hsl(21, 63%, 89%)",
  lighter: "hsl(23, 62%, 96%)",
};

const colour_brown_SilWm = {
  id: "colour_brown_SilWm",
  name: "Mid Olive",
  outline: "hsl(38, 55%, 23%)",
  darker: "hsl(38, 88%, 28%)",
  dark: "hsl(38, 74%, 42%)",
  base: "hsl(38, 60%, 49%)",
  light: "hsl(38, 75%, 60%)",
  lighter: "hsl(38, 76%, 72%)",
};

const colour_brown_ydAEG = {
  id: "colour_brown_ydAEG",
  name: "Dark brown",
  outline: "hsl(22, 31%, 8%)",
  darker: "hsl(22, 31%, 12%)",
  dark: "hsl(23, 31%, 20%)",
  base: "hsl(23, 33%, 28%)",
  light: "hsl(23, 19%, 48%)",
  lighter: "hsl(21, 63%, 89%)",
};

const colour_brown_ywfEG = {
  id: "colour_brown_ywfEG",
  name: "Light Brown",
  outline: "#9B6B46",
  darker: "#BC8962",
  dark: "#DDA77E",
  base: "#E6BEA0",
  light: "#EED3BE",
  lighter: "#F7E9DF",
};

const colour_brown_JyqfO = {
  id: "colour_brown_JyqfO",
  name: "Light Brown 2",
  outline: "#BC8962",
  darker: "#D1A481",
  dark: "#E6BEA0",
  base: "#EED3BE",
  light: "#F3DECF",
  lighter: "#F7E9DF",
};

const colour_brown_arLmj = {
  id: "colour_brown_arLmj",
  name: "Light Brown 3",
  outline: "#BF9373",
  darker: "#D5AE90",
  dark: "#EBC8AD",
  base: "#F3DECF",
  light: "#F7E9DF",
  lighter: "#FBF4EF",
};

const colour_brown_nfu36 = {
  id: "colour_brown_nfu36",
  name: "Fair",
  outline: "#D5AE90",
  darker: "#EBC8AD",
  dark: "#EFD3BE",
  base: "#F3DECF",
  light: "#F7E9DF",
  lighter: "#FBF4EF",
};

const colour_brown_kw872 = {
  id: "colour_brown_kw872",
  name: "Fair 2",
  outline: "#D9BDB8",
  darker: "#EFD0CA",
  dark: "#F3DDD5",
  base: "#F7E9E2",
  light: "#FBF4EF",
  lighter: "#FDFAF7",
};

const colour_brown_arLR6 = {
  id: "colour_brown_arLR6",
  name: "Dark Gold",
  outline: "#543515",
  darker: "#543515",
  dark: "#9d6326",
  base: "#cd8132",
  light: "#d99f62",
  lighter: "#e4bc93",
};

const colour_brown_xllKG = {
  id: "colour_brown_xllKG",
  name: "Dark Brown 2",
  outline: "#23140a",
  darker: "#23140a",
  dark: "#523017",
  base: "#814b24",
  light: "#b06631",
  lighter: "#ce844f",
};

const colour_brown_VlZO3 = {
  id: "colour_brown_VlZO3",
  name: "Dark Brown 3",
  outline: "#21140c",
  darker: "#21140c",
  dark: "#372114",
  base: "#4d2e1c",
  light: "#643c23",
  lighter: "#905633",
};

const colour_brown_PSlaT = {
  id: "colour_brown_PSlaT",
  name: "Ginger",
  outline: "#6a3d1d",
  darker: "#6a3d1d",
  dark: "#99582a",
  base: "#c87437",
  light: "#d59466",
  lighter: "#e2b595",
};

const colour_brown_EFlqN = {
  id: "colour_brown_EFlqN",
  name: "Blonde",
  outline: "#755a30",
  darker: "#755a30",
  dark: "#a07b41",
  base: "#b68b49",
  light: "#c7a774",
  lighter: "#d8c29f",
};

const colour_brown_Hpd9N = {
  id: "colour_brown_Hpd9N",
  name: "Blonde 2",
  outline: "#402B22",
  darker: "#886851",
  dark: "#9E7C57",
  base: "#C8B18C",
  light: "#D4C6A8",
  lighter: "#DCD5C2",
};

const colour_brown_mrWK8 = {
  id: "colour_brown_mrWK8",
  name: "Blonde 3",
  outline: "#7A522A",
  darker: "#916131",
  dark: "#A1764B",
  base: "#B6905C",
  light: "#D5B38B",
  lighter: "#FDF4D8",
};

const colour_brown_ldPZ1 = {
  id: "colour_brown_ldPZ1",
  name: "Blonde 4",
  outline: "#736051",
  darker: "#B2845F",
  dark: "#C1A688",
  base: "#CDB59B",
  light: "#DBCAAC",
  lighter: "#EEE4D8",
};

const colour_brown_AIyLF = {
  id: "colour_brown_AIyLF",
  name: "Mid Brown 4",
  outline: "#311E11",
  darker: "#6A4630",
  dark: "#81503F",
  base: "#98623C",
  light: "#AF7B56",
  lighter: "#C09A6D",
};

export const AllBrowns = {
  colour_brown_ydAEG: colour_brown_ydAEG,
  colour_brown_xllKG: colour_brown_xllKG,
  colour_brown_VlZO3: colour_brown_VlZO3,
  colour_brown_wbuWb: colour_brown_wbuWb,
  colour_brown_HIGYU: colour_brown_HIGYU,
  colour_brown_Z9NyT: colour_brown_Z9NyT,
  colour_brown_u5mhz: colour_brown_u5mhz,
  colour_brown_fhVTO: colour_brown_fhVTO,
  colour_brown_wKvP8: colour_brown_wKvP8,
  colour_brown_AIyLF: colour_brown_AIyLF,
  colour_brown_SilWm: colour_brown_SilWm,
  colour_brown_ywfEG: colour_brown_ywfEG,
  colour_brown_JyqfO: colour_brown_JyqfO,
  colour_brown_arLmj: colour_brown_arLmj,
  colour_brown_nfu36: colour_brown_nfu36,
  colour_brown_kw872: colour_brown_kw872,
  colour_brown_arLR6: colour_brown_arLR6,
  colour_brown_PSlaT: colour_brown_PSlaT,
  colour_brown_EFlqN: colour_brown_EFlqN,
  colour_brown_Hpd9N: colour_brown_Hpd9N,
  colour_brown_mrWK8: colour_brown_mrWK8,
  colour_brown_ldPZ1: colour_brown_ldPZ1,
};

export default AllBrowns;
