import React from "react";
import { connect } from "react-redux";
import ClothingFront from "./Layer 1/ClothingFront";

/**
This will be used to build the different front layers of clothing
 */

function mapStateToProps(state, ownProps) {
  const Layer1 = state.config.clothing.layer1;
  const Layer2 = state.config.clothing.layer2;
  const Layer3 = state.config.clothing.layer3;

  return { Layer1, Layer2, Layer3 };
}

const ClothingFrontLayers = (props) => {
  const { previewId, Layer1, Layer2, Layer3 } = props;

  if (Layer1.shape) {
    return (
      <g
        id={
          previewId
            ? `g-clothing-front-layers~${previewId}`
            : "g-clothing-front-layers"
        }
      >
        {Layer1.shape && <ClothingFront layer="1" layerConfig={Layer1} />}
        {Layer2.shape && <ClothingFront layer="2" layerConfig={Layer2} />}
        {Layer3.shape && <ClothingFront layer="3" layerConfig={Layer3} />}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(ClothingFrontLayers);
