import React, { Fragment } from "react";
import { connect } from "react-redux";
import { updateMenu } from "character-creator/utilities/menuSlice";
import Section from "./Section";
import ControlGroup from "./controls/ControlGroup";

const mapDispatchToProps = {
  updateMenu,
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};

const SubPage = (props) => {
  const { id } = props;
  const subPage = props.page;

  let subPageContent = <></>;

  if (subPage.sections) {
    subPageContent = subPage.sections.map((section, index) => {
      const isDependencyMet = section.dependency ? section.dependency : true;
      const sectionCode = isDependencyMet ? (
        <Section section={section} />
      ) : (
        <></>
      );

      return <Fragment key={index}>{sectionCode}</Fragment>;
    });
  } else if (subPage.symmetryAttribute) {
    const isDependencyMet = subPage.dependency ? subPage.dependency : true;
    subPageContent = isDependencyMet ? <Section section={subPage} /> : <></>;
  } else if (subPage.controls) {
    subPageContent = subPage.controls.map((control, index) => {
      return (
        <Fragment key={index}>
          <ControlGroup sectionLevel={true} group={control} />
        </Fragment>
      );
    });
  }

  return (
    <section
      id={`panel-${id}`}
      className={"subpage"}
      role="tabpanel"
      aria-labelledby={`tab-${id}`}
    >
      {/* <h2 className="">{subPage.name}</h2> */}
      {/* {subPage.description && <p className="subpage-desc">
        {subPage.description}
      </p>}
       */}
      {/* {Sections} */}
      {subPageContent}
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubPage);
