import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { cheekShapeList } from "./CheekShapeList";
import { buildTransform } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const cheeksConfig = state.config.base.head.face.markings.cheeks;

  let cheeksObjectLeft = null;
  if (ownProps.preview && ownProps.cheeks.shape?.left) {
    cheeksObjectLeft = ownProps.cheeks.shape.left;
  } else if (cheeksConfig.left.shape !== "0") {
    cheeksObjectLeft = cheekShapeList[cheeksConfig.left.shape].shape.left;
  }

  const coloursLeft = cheeksConfig.left.colour
    ? cheeksConfig.left.colour
    : { base: state.config.base.skintone.light };

  let cheeksObjectRight = null;
  if (ownProps.preview && ownProps.cheeks.shape?.right) {
    cheeksObjectRight = ownProps.cheeks.shape.right;
  } else if (cheeksConfig.right.shape !== "0") {
    cheeksObjectRight = cheekShapeList[cheeksConfig.right.shape].shape.right;
  }

  const coloursRight = cheeksConfig.right.colour
    ? cheeksConfig.right.colour
    : { base: state.config.base.skintone.light };

  const leftTransform = buildTransform(cheeksConfig.left.transform);
  const rightTransform = buildTransform(cheeksConfig.right.transform);

  return {
    cheeks: {
      left: {
        shape: cheeksObjectLeft,
        colour: coloursLeft,
        transform: leftTransform,
      },
      right: {
        shape: cheeksObjectRight,
        colour: coloursRight,
        transform: rightTransform,
      },
    },
  };
}

const Cheeks = (props) => {
  const { cheeks, previewId } = props;

  if (cheeks.left.shape || cheeks.right.shape) {
    return (
      <g
        id={previewId ? `g-head-cheeks~${previewId}` : "g-head-cheeks"}
        mask="url(#head_mask)"
      >
        {cheeks.left.shape && (
          <Component
            id={previewId ? `cheeks-left~${previewId}` : "cheeks-left"}
            component={cheeks.left}
            colour={cheeks.left.colour}
            preview={previewId ? true : false}
            transform={cheeks.left.transform}
            strokeOverride={false}
          />
        )}
        {cheeks.right.shape && (
          <Component
            id={previewId ? `cheeks-right~${previewId}` : "cheeks-right"}
            component={cheeks.right}
            colour={cheeks.right.colour}
            preview={previewId ? true : false}
            transform={cheeks.left.transform}
            strokeOverride={false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Cheeks);
