import React from "react";
import { viewbox, PreviewBase } from "../../Preview";

// Round neck, narrow straps

const vest_QCV2DbPrMw_AC = {
  base: (
    <path d="M109 500s3.146-58.592 24.675-101.736c2.031-4.07 15.726-6.164 18.623-4.491 1.434.829-.753 5.065-.753 10.382 0 43.267 37.445 78.502 97.744 78.776h1.422c60.299-.274 97.744-35.509 97.744-78.776 0-5.317-2.187-9.553-.753-10.382 2.897-1.673 16.592.421 18.623 4.491C387.854 441.408 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-219.525-66.544c-.081-.276-7.147-4.781-10.762-12.792-4.142-9.179-8.093-38.203-4.849-40.499 21.298-15.069 15.687 52.386 15.611 53.291.001.004.001.007-.001.009l.001-.009Zm170.154 0c-.001.004-.001.007.001.009l-.001-.009c.081-.276 7.147-4.781 10.762-12.792 4.142-9.179 8.093-38.203 4.849-40.499-21.298-15.069-15.687 52.386-15.611 53.291Z" />
    ),
    light: (
      <path d="M167.263 446.893c0-19.314 165.474-19.314 165.474 0 0 19.313-37.073 42.525-82.737 42.525-45.664 0-82.737-23.212-82.737-42.525Z" />
    ),
  },
};

const vest_QCV2DbPrMw_B = {
  base: (
    <path d="M109 500s3.146-66.255 24.675-115.043c2.031-4.602 15.726-6.97 18.623-5.078 1.434.937-.753 5.728-.753 11.74 0 48.926 37.445 88.769 97.744 89.079h1.422c60.299-.31 97.744-40.153 97.744-89.079 0-6.012-2.187-10.803-.753-11.74 2.897-1.892 16.592.476 18.623 5.078C387.854 433.745 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-219.525-77.369c-.081-.276-7.147-4.78-10.762-12.792-4.142-9.179-8.093-38.203-4.849-40.499 21.298-15.069 15.687 52.387 15.611 53.291.001.004.001.007-.001.01l.001-.01Zm170.154 0c-.001.004-.001.007.001.01l-.001-.01c.081-.276 7.147-4.78 10.762-12.792 4.142-9.179 8.093-38.203 4.849-40.499-21.298-15.069-15.687 52.387-15.611 53.291Z" />
    ),
    light: (
      <path d="M167.263 442.303c0-19.314 165.474-19.314 165.474 0S295.664 484.829 250 484.829c-45.664 0-82.737-23.212-82.737-42.526Z" />
    ),
  },
};

const vest_QCV2DbPrMw = {
  name: "Round neck vest - narrow straps",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: { ...vest_QCV2DbPrMw_AC },
    // Body 1B
    base_body_shape_taw74: {
      ...vest_QCV2DbPrMw_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...vest_QCV2DbPrMw_AC,
    },
    // Body 2A
    base_body_shape_g825h: {
      ...vest_QCV2DbPrMw_AC,
    },
    // Body 2B
    base_body_shape_fa048: {
      ...vest_QCV2DbPrMw_B,
    },
    // Body 2C
    base_body_shape_mge5o: { ...vest_QCV2DbPrMw_AC },
    // Body 3A
    base_body_shape_gj245: {
      ...vest_QCV2DbPrMw_AC,
    },
    // Body 3B
    base_body_shape_942n5: {
      base: (
        <path d="M109 500s.659-61.928 22.188-112.477c2.031-4.768 18.305-12.25 21.202-10.291 1.434.971-.753 5.935-.753 12.164 0 50.692 37.353 90.285 97.652 90.606h1.422c60.299-.321 97.744-41.602 97.744-92.295 0-6.229-1.63-8.879-.195-9.849 2.896-1.96 17.757 5.54 19.788 10.308C389.577 438.715 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 442.303c0-19.314 165.474-19.314 165.474 0S295.664 484.829 250 484.829c-45.664 0-82.737-23.212-82.737-42.526Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-219.525-77.369c-.081-.276-7.147-4.78-10.762-12.792-4.142-9.179-8.093-42.883-4.849-45.179 21.298-15.069 15.687 57.067 15.611 57.971.001.004.001.007-.001.01l.001-.01Zm170.154 0c-.001.004-.001.007.001.01l-.001-.01c.081-.276 7.147-4.78 10.762-12.792 4.142-9.179 8.093-42.883 4.849-45.179-21.298-15.069-15.687 57.067-15.611 57.971Z" />
        ),
      },
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path d="M109 500s3.466-55.815 24.995-98.333c2.031-4.01 18.481-8.441 19.552-4.569.44 1.592-.339 2.973-.339 8.213 0 42.638 35.782 77.598 96.081 77.868h1.422c60.299-.27 95.524-33.571 95.524-76.21 0-5.239-1.723-8.745-.519-9.874 4.15-3.89 17.264.984 19.295 4.994C386.54 444.607 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 446.04c0-19.313 165.474-19.313 165.474 0 0 19.314-37.073 42.526-82.737 42.526-45.664 0-82.737-23.212-82.737-42.526Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-219.525-67.17c-.081-.276-7.147-4.781-10.762-12.792-4.142-9.18-8.093-38.204-4.849-40.499 21.298-15.069 15.687 52.386 15.611 53.291.001.004.001.007-.001.009l.001-.009Zm170.154 0c-.001.004-.001.007.001.009l-.001-.009c.081-.276 7.147-4.781 10.762-12.792 4.142-9.18 8.093-38.204 4.849-40.499-21.298-15.069-15.687 52.386-15.611 53.291Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path d="M109 500s.435-57.392 21.964-102.533c2.031-4.257 20.261-9.273 22.246-6.638 1.026 1.362-.583 4.472-.583 10.035 0 45.269 36.363 80.99 96.662 81.277h1.422c60.299-.287 97.744-37.152 97.744-82.421 0-5.563-2.108-7.962-.673-8.829 2.897-1.75 20.19 2.424 22.221 6.682C391.532 442.714 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 444.811c0-19.313 165.474-19.313 165.474 0 0 19.314-37.073 42.526-82.737 42.526-45.664 0-82.737-23.212-82.737-42.526Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041ZM164.923 441.87c-.081-.276-7.147-4.78-10.762-12.792-4.142-9.179-8.093-38.203-4.849-40.499 21.298-15.069 15.687 52.387 15.611 53.291.001.004.001.007-.001.009l.001-.009Zm170.154 0c-.001.004-.001.007.001.009l-.001-.009c.081-.276 7.147-4.78 10.762-12.792 4.142-9.179 8.093-38.203 4.849-40.499-21.298-15.069-15.687 52.387-15.611 53.291Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path d="M109 500s6.518-67.055 28.047-118.942c2.03-4.894 19.402-7.849 22.299-5.837 1.434.996-.753 6.091-.753 12.485 0 52.034 30.397 91.437 90.696 91.766h1.422c60.299-.329 90.696-39.732 90.696-91.766 0-6.394-2.187-11.489-.753-12.485 2.897-2.012 20.269.943 22.299 5.837C384.482 432.945 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M168.572 436.408c0-22.405 162.856-22.405 162.856 0 0 22.406-36.487 49.333-81.428 49.333-44.941 0-81.428-26.927-81.428-49.333Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-216.023-84.262c-.077-.276-6.852-4.781-10.318-12.792-3.972-9.18-7.76-38.204-4.65-40.499 20.422-15.069 15.041 52.386 14.968 53.291.002.004.001.007 0 .009v-.009Zm163.15 0c-.002.004-.001.007 0 .009v-.009c.077-.276 6.852-4.781 10.318-12.792 3.972-9.18 7.76-38.204 4.65-40.499-20.422-15.069-15.041 52.386-14.968 53.291Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      ...vest_QCV2DbPrMw_AC,
    },
  },
};

// Round neck, medium straps

const vest_6d9ZJ24nFc_A = {
  base: (
    <path d="M109 500s4.613-62.525 22.972-100.665c2.432-5.053 33.131-12.182 31.706-5.47-2.168 10.211.085 33.49.085 33.49 3.37 33.068 32.484 55.845 86.237 55.845l.629-.001c53.35-.176 82.251-22.905 85.608-55.844 0 0 2.253-23.279.085-33.49-1.425-6.712 29.274.417 31.706 5.47C386.387 437.475 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-66.862c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
    ),
    light: (
      <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
    ),
  },
};

const vest_6d9ZJ24nFc_B = {
  base: (
    <path d="M109 500s4.613-71.383 22.972-114.927c2.432-5.769 33.131-13.908 31.706-6.244-2.168 11.657.085 38.234.085 38.234 3.37 37.753 32.484 63.757 86.237 63.757l.629-.001c53.35-.202 82.251-26.15 85.608-63.756 0 0 2.253-26.577.085-38.234-1.425-7.664 29.274.475 31.706 6.244C386.387 428.617 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-81.726c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.179-7.533-38.203-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.179 7.533-38.203 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
    ),
    light: (
      <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
    ),
  },
};

const vest_6d9ZJ24nFc_C = {
  base: (
    <path d="M109 500s4.613-61.374 22.972-98.812c2.432-4.96 33.131-11.958 31.706-5.368-2.168 10.022.085 32.872.085 32.872 3.37 32.46 32.484 54.818 86.237 54.818l.629-.001c53.35-.174 82.251-22.484 85.608-54.817 0 0 2.253-22.85.085-32.872-1.425-6.59 29.274.408 31.706 5.368C386.387 438.626 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-68.06c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
    ),
    light: (
      <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
    ),
  },
};

const vest_6d9ZJ24nFc = {
  name: "Round neck vest - medium straps",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      ...vest_6d9ZJ24nFc_A,
    },
    // Body 1B
    base_body_shape_taw74: {
      ...vest_6d9ZJ24nFc_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...vest_6d9ZJ24nFc_C,
    },
    // Body 2A
    base_body_shape_g825h: {
      base: (
        <path d="M109 500s4.613-62.249 22.972-100.22c2.432-5.031 33.131-12.128 31.706-5.445-2.168 10.165.085 33.341.085 33.341 3.37 32.922 32.484 55.599 86.237 55.599.295 0 .597.024.905.071 53.173-.25 81.981-22.757 85.332-55.35 0 0 2.253-23.073.085-33.193-1.425-6.654 29.274.412 31.706 5.421C386.387 438.027 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-68.06c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
        ),
      },
    },
    // Body 2B
    base_body_shape_fa048: {
      ...vest_6d9ZJ24nFc_B,
    },
    // Body 2C
    base_body_shape_mge5o: {
      base: (
        <path d="M109 500s7.055-61.051 25.413-98.665c2.432-4.983 32.068-10.767 30.642-4.146-2.168 10.069-1.292 31.167-1.292 31.167 3.37 32.612 32.484 55.076 86.237 55.076.295 0 .597.024.905.071 53.173-.249 81.981-22.544 85.332-54.83 0 0 3.176-21.626 1.008-31.651-1.426-6.591 29.816.335 32.351 7.576C383.303 443.746 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-68.06c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
        ),
      },
    },
    // Body 3A
    base_body_shape_gj245: {
      ...vest_6d9ZJ24nFc_A,
    },
    // Body 3B
    base_body_shape_942n5: {
      ...vest_6d9ZJ24nFc_B,
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path d="M109 500s9.42-60.288 27.779-97.902c2.432-4.984 28.572-12.793 27.147-6.173-2.168 10.07-.163 32.431-.163 32.431 3.37 32.612 32.484 55.076 86.237 55.076.295 0 .597.024.905.071 53.173-.249 81.981-22.544 85.332-54.83 0 0 4.329-21.678 2.161-31.703-1.425-6.591 27.651 2.153 30.186 9.394C382.29 445.512 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-68.06c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path d="M109 500s4.613-64.505 22.972-103.852c2.432-5.213 33.131-12.568 31.706-5.642-2.168 10.533.085 34.549.085 34.549 3.37 34.115 32.484 57.614 86.237 57.614.295 0 .597.025.905.074 53.173-.26 81.981-23.583 85.332-57.356 0 0 2.253-23.91.085-34.397-1.425-6.894 29.274.428 31.706 5.618C386.387 435.781 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-213.629-68.06c-.075-.276-6.652-4.781-10.016-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.6 52.386 14.529 53.291.001.004.001.007-.001.009l.001-.009Zm158.362 0c-.001.004-.001.007.001.009l-.001-.009c.075-.276 6.652-4.781 10.016-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.6 52.386-14.529 53.291Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path d="M109 500s6.358-72.065 24.717-117.69c2.432-6.045 31.622-12.505 30.466-6.907-2.509 12.144-.42 37.695-.42 37.695 3.37 39.558 32.484 66.806 86.237 66.806l.629-.002c53.35-.211 82.251-27.4 85.608-66.804 0 0 2.089-25.551-.42-37.695-1.156-5.598 28.034.862 30.466 6.907C384.642 427.935 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 443.123c0-21.92 165.474-21.92 165.474 0 0 21.919-37.073 48.263-82.737 48.263-45.664 0-82.737-26.344-82.737-48.263Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-54.154-84.174c-.002.004-.001.007 0 .009v-.009c.075-.276 6.651-4.781 10.015-12.792 3.855-9.179 7.533-38.203 4.513-40.499-19.822-15.069-14.599 52.387-14.528 53.291Zm-160.588 0c-.075-.276-6.651-4.781-10.015-12.792-3.855-9.179-7.533-38.203-4.513-40.499 19.822-15.069 14.599 52.387 14.528 53.291.002.004.001.007 0 .009v-.009Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      base: (
        <path d="M391 500H109s4.943-63.605 25.388-101.194c2.666-4.902 27.365-9.454 25.85-3.611-2.653 10.234-.163 33.324-.163 33.324 3.37 33.511 36.172 54.456 89.925 54.456s86.555-20.945 89.925-54.456c0 0 2.49-23.09-.163-33.324-1.515-5.843 23.184-1.291 25.85 3.611C386.057 436.395 391 500 391 500Z" />
      ),
      basic: {
        light: (
          <path d="M167.263 449.19c0-19.163 165.474-19.163 165.474 0 0 19.164-37.073 42.196-82.737 42.196-45.664 0-82.737-23.032-82.737-42.196Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041ZM169 442.948c-.075-.276-6.651-4.781-10.015-12.792-3.855-9.18-7.533-38.204-4.513-40.499 19.822-15.069 14.599 52.386 14.528 53.291.001.004.001.007 0 .009v-.009Zm162 0c-.001.004-.001.007 0 .009v-.009c.075-.276 6.651-4.781 10.015-12.792 3.855-9.18 7.533-38.204 4.513-40.499-19.822-15.069-14.599 52.386-14.528 53.291Z" />
        ),
      },
    },
  },
};

// Round neck, thick straps

const vest_RxbWyk2BUY_A = {
  base: (
    <path d="M250 483.111c53.753 0 72.327-24.003 75.697-57.247 0 0 .029-27.577-6.57-35.707-6.124-7.544 42.027 1.039 45.871 8.54C384.384 436.524 391 500 391 500H109s6.616-63.476 26.002-101.303c3.844-7.501 51.995-16.084 45.871-8.54-6.599 8.13-6.57 35.707-6.57 35.707 3.37 33.244 21.944 57.247 75.697 57.247Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-205.071-70.173c.001.004 0 .007-.002.009l.002-.009c-.033-.284-5.861-5.722-7.988-14.147-2.437-9.653-1.729-38.9 1.6-40.718 21.854-11.932 6.594 53.982 6.388 54.865Zm141.246 0c-.001.004 0 .007.002.009l-.002-.009c.033-.284 5.861-5.722 7.988-14.147 2.437-9.653 1.729-38.9-1.6-40.718-21.854-11.932-6.594 53.982-6.388 54.865Z" />
    ),
    light: (
      <path d="M177.363 443.123c0-21.92 145.274-21.92 145.274 0 0 21.919-32.548 48.263-72.637 48.263s-72.637-26.344-72.637-48.263Z" />
    ),
  },
};

const vest_RxbWyk2BUY_B = {
  base: (
    <path d="M109 500s6.616-72.13 26.002-115.115c3.844-8.523 52.036-21.657 45.912-13.085-6.599 9.238-6.611 43.957-6.611 43.957 3.37 37.776 21.944 65.052 75.697 65.052s72.327-27.276 75.697-65.052c0 0-.012-34.719-6.611-43.957-6.124-8.572 42.068 4.562 45.912 13.085C384.384 427.87 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-205.071-88.321c.001.004 0 .007-.002.009l.002-.009c-.033-.284-5.861-5.723-7.988-14.147-2.437-9.653-1.729-38.901 1.6-40.719 21.854-11.932 6.594 53.982 6.388 54.866Zm141.246 0c-.001.004 0 .007.002.009l-.002-.009c.033-.284 5.861-5.723 7.988-14.147 2.437-9.653 1.729-38.901-1.6-40.719-21.854-11.932-6.594 53.982-6.388 54.866Z" />
    ),
    light: (
      <path d="M180.215 444.825c0-19.574 139.57-19.574 139.57 0s-31.27 43.099-69.785 43.099-69.785-23.525-69.785-43.099Z" />
    ),
  },
};

const vest_RxbWyk2BUY_C = {
  base: (
    <path d="M109 500s7.826-63.479 27.212-99.432c3.844-7.128 53.125-17.694 44.702-7.794-6.584 7.74-6.611 36.765-6.611 36.765 3.37 31.596 21.944 54.41 75.697 54.41s72.327-22.814 75.697-54.41c0 0-.027-29.025-6.611-36.765-8.423-9.9 40.858.666 44.702 7.794C383.174 436.521 391 500 391 500H109Z" />
  ),
  basic: {
    dark: (
      <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-205.071-70.173c.001.004 0 .007-.002.009l.002-.009c-.033-.284-5.861-5.722-7.988-14.147-2.437-9.653-1.729-38.9 1.6-40.718 21.854-11.932 6.594 53.982 6.388 54.865Zm141.246 0c-.001.004 0 .007.002.009l-.002-.009c.033-.284 5.861-5.722 7.988-14.147 2.437-9.653 1.729-38.9-1.6-40.718-21.854-11.932-6.594 53.982-6.388 54.865Z" />
    ),
    light: (
      <path d="M177.363 443.123c0-21.92 145.274-21.92 145.274 0 0 21.919-32.548 48.263-72.637 48.263s-72.637-26.344-72.637-48.263Z" />
    ),
  },
};

const vest_RxbWyk2BUY = {
  name: "Round neck vest - wide straps",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      ...vest_RxbWyk2BUY_A,
    },
    // Body 1B
    base_body_shape_taw74: {
      ...vest_RxbWyk2BUY_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...vest_RxbWyk2BUY_C,
    },
    // Body 2A
    base_body_shape_g825h: {
      ...vest_RxbWyk2BUY_A,
    },
    // Body 2B
    base_body_shape_fa048: {
      ...vest_RxbWyk2BUY_B,
    },
    // Body 2C
    base_body_shape_mge5o: {
      base: (
        <path d="M109 500s9.104-63.17 28.49-99.122c3.844-7.129 52.767-16.634 44.345-6.734-6.585 7.74-7.532 35.395-7.532 35.395 3.37 31.596 21.944 54.41 75.697 54.41s72.327-22.814 75.697-54.41c0 0 1.896-27.315-4.689-35.055-8.422-9.9 39.095.246 42.939 7.375C383.334 437.811 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M177.363 447.39c0-19.982 145.274-19.982 145.274 0 0 19.981-32.548 43.996-72.637 43.996s-72.637-24.015-72.637-43.996Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-205.071-70.173c.001.004 0 .007-.002.009l.002-.009c-.033-.284-5.861-5.722-7.988-14.147-2.437-9.653-1.729-38.9 1.6-40.718 21.854-11.932 6.594 53.982 6.388 54.865Zm141.246 0c-.001.004 0 .007.002.009l-.002-.009c.033-.284 5.861-5.722 7.988-14.147 2.437-9.653 1.729-38.9-1.6-40.718-21.854-11.932-6.594 53.982-6.388 54.865Z" />
        ),
      },
    },
    // Body 3A
    base_body_shape_gj245: {
      base: (
        <path d="M250 482.848c53.753 0 72.327-24.379 75.697-58.141 0 0 .029-28.008-6.57-36.265-6.124-7.662 42.027 1.056 45.871 8.673C384.384 435.533 391 500 391 500H109s6.616-64.467 26.002-102.885c3.844-7.617 54.768-16.047 48.644-8.385-6.599 8.257-7.975 37.28-7.975 37.28 3.37 33.762 20.576 56.838 74.329 56.838Z" />
      ),
      basic: {
        light: (
          <path d="M180.611 446.786c0-20.255 138.778-20.255 138.778 0 0 20.256-31.092 44.6-69.389 44.6s-69.389-24.344-69.389-44.6Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-203.166-70.173c.001.004 0 .007-.002.009l.002-.009c-.033-.284-5.861-5.722-7.988-14.147-2.437-9.653-1.729-38.9 1.6-40.718 21.854-11.932 6.594 53.982 6.388 54.865Zm139.341 0c-.001.004 0 .007.002.009l-.002-.009c.033-.284 5.861-5.722 7.988-14.147 2.437-9.653 1.729-38.9-1.6-40.718-21.854-11.932-6.594 53.982-6.388 54.865Z" />
        ),
      },
    },
    // Body 3B
    base_body_shape_942n5: {
      base: (
        <path d="M109 500s4.936-72.661 24.322-116.98c3.844-8.788 52.037-22.33 45.913-13.491-6.6 9.525-4.932 43.612-4.932 43.612 3.37 38.949 21.944 67.072 75.697 67.072s72.327-28.123 75.697-67.072c0 0 1.702-33.827-4.897-43.352-6.124-8.839 42.068 4.703 45.912 13.491C386.098 427.599 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M182.298 445.426c0-18.747 135.404-18.747 135.404 0S287.366 486.703 250 486.703s-67.702-22.53-67.702-41.277Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-206.542-85.986c.001.004 0 .007-.002.01l.002-.01c-.034-.311-5.983-6.266-8.155-15.49-2.488-10.569-1.765-42.592 1.633-44.582 22.31-13.064 6.732 59.104 6.522 60.072Zm144.188 0c-.001.004 0 .007.002.01l-.002-.01c.034-.311 5.983-6.266 8.155-15.49 2.488-10.569 1.765-42.592-1.633-44.582-22.31-13.064-6.732 59.104-6.522 60.072Z" />
        ),
      },
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path d="M109 500s10.686-62.715 30.072-98.667c3.844-7.129 48.235-13.716 42.763-7.189-6.529 7.787-7.532 35.395-7.532 35.395 3.37 31.596 21.944 54.41 75.697 54.41s72.327-22.814 75.697-54.41c0 0 1.546-27.031-4.689-35.055-6.61-8.507 36.143-1.934 41.3 8.436C380.497 439.492 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M177.363 447.559c0-19.904 145.274-19.904 145.274 0 0 19.905-32.548 43.827-72.637 43.827s-72.637-23.922-72.637-43.827Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-205.071-70.173c.001.004 0 .007-.002.009l.002-.009c-.033-.284-5.861-5.722-7.988-14.147-2.437-9.653-1.729-38.9 1.6-40.718 21.854-11.932 6.594 53.982 6.388 54.865Zm141.246 0c-.001.004 0 .007.002.009l-.002-.009c.033-.284 5.861-5.722 7.988-14.147 2.437-9.653 1.729-38.9-1.6-40.718-21.854-11.932-6.594 53.982-6.388 54.865Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path d="M250,482.273C303.753,482.273 322.327,457.077 325.697,422.182C325.697,422.182 325.726,393.236 319.127,384.702C313.003,376.783 361.248,388.979 365.092,396.852C384.478,436.558 391,500 391,500L109,500C109,500 115.009,437.121 134.395,397.416C138.239,389.542 189.77,377.081 183.646,384.999C177.047,393.533 175.671,423.529 175.671,423.529C179.041,458.423 196.247,482.273 250,482.273Z" />
      ),
      basic: {
        light: (
          <path d="M178.362,443.123C178.362,421.203 321.638,421.203 321.638,443.123C321.638,465.042 289.538,491.386 250,491.386C210.462,491.386 178.362,465.042 178.362,443.123Z" />
        ),
        dark: (
          <path d="M115.552,511.008C115.552,511.008 114.107,441.89 119.669,422.273C125.231,402.656 99.56,460.967 99.56,460.967C99.56,460.967 103.641,506.789 115.552,511.008ZM384.448,511.008C384.448,511.008 385.893,441.89 380.331,422.273C374.769,402.656 400.44,460.967 400.44,460.967C400.44,460.967 396.359,506.789 384.448,511.008ZM181.611,433.712C181.611,433.716 181.61,433.719 181.608,433.721L181.611,433.712C181.578,433.428 175.75,427.954 173.622,419.517C171.185,409.849 171.871,380.564 175.222,378.808C198.596,366.56 181.761,433.668 181.611,433.712ZM320.623,433.422C320.622,433.426 320.623,433.429 320.625,433.431L320.623,433.422C320.656,433.138 326.484,427.7 328.611,419.275C331.048,409.622 330.34,380.374 327.011,378.557C305.157,366.624 320.417,432.538 320.623,433.422Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path d="M109 500s4.936-72.956 24.322-117.456c3.844-8.823 47.41-18.475 41.286-9.601-6.599 9.564-.305 39.845-.305 39.845 3.37 39.107 21.944 67.345 75.697 67.345s73.662-27.13 77.033-66.237c0 0 5.624-30.771-.975-40.335-6.124-8.875 36.81.421 40.654 9.244C386.098 427.305 391 500 391 500H109Z" />
      ),
      basic: {
        light: (
          <path d="M182.163 445.118c0-19.171 135.674-19.171 135.674 0S287.44 487.329 250 487.329c-37.44 0-67.837-23.04-67.837-42.211Z" />
        ),
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041ZM177.27 428.31c0 .004-.001.007-.003.01l.003-.01c-.035-.31-6.036-6.234-8.227-15.411-2.51-10.515-1.781-42.374 1.647-44.354 22.507-12.998 6.791 58.802 6.58 59.765Zm145.46 0c0 .004.001.007.003.01l-.003-.01c.035-.31 6.036-6.234 8.227-15.411 2.51-10.515 1.781-42.374-1.647-44.354-22.507-12.998-6.791 58.802-6.58 59.765Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      ...vest_RxbWyk2BUY_C,
    },
  },
};

export const VestsPack2 = {
  vest_QCV2DbPrMw,
  vest_6d9ZJ24nFc,
  vest_RxbWyk2BUY,
};
