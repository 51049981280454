import { allColours } from "character-creator/colours/colourSchemes";
import { ClothingList } from "character-creator/components/clothing/Layer 1/ClothingList";
import { PatternsList } from "character-creator/components/patterns/PatternList";

const clothingPath = "clothing.";
const clothingLayer1Path = clothingPath + "layer1.";
const clothingLayer2Path = clothingPath + "layer2.";
const clothingLayer3Path = clothingPath + "layer3.";

const ClothingLayersPage = {
  id: "clothingLayersPage",
  name: "Layers",
  controls: [
    /////////////////////////////////
    // LAYER 1
    /////////////////////////////////
    {
      id: "clothingLayer1StyleGroup",
      name: "Layer 1",
      description: "",
      dataAttribute: [clothingLayer1Path + "shape"],
      previewType: "preview",
      options: ClothingList,
      colourControls: [
        {
          id: "clothingLayer1Colour",
          name: "Colour",
          description: "",
          dataAttribute: [clothingLayer1Path + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
    {
      id: "clothingLayer1PatternGroup",
      name: "Layer 1 Pattern",
      description: "",
      dependencies: [
        {
          description: "Layer 1",
          attribute: [clothingLayer1Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick something for Layer 1 first",
      dataAttribute: [clothingLayer1Path + "pattern.shape"],
      previewType: "preview",
      options: PatternsList,
      colourControls: [
        {
          id: "clothingLayer1PatternColour",
          name: "Colour",
          description: "",
          dataAttribute: [clothingLayer1Path + "pattern.colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "clothingLayer1PatternTransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [clothingLayer1Path + "pattern.transform.opacity"],
          range: {
            min: 0,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    /////////////////////////////////
    // LAYER 2
    /////////////////////////////////
    {
      id: "clothingLayer2StyleGroup",
      name: "Layer 2",
      description: "",
      dependencies: [
        {
          description: "Layer 1",
          attribute: [clothingLayer1Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick something for Layer 1 first",
      dataAttribute: [clothingLayer2Path + "shape"],
      previewType: "preview",
      options: ClothingList,
      colourControls: [
        {
          id: "clothingLayer2Colour",
          name: "Colour",
          description: "",
          dataAttribute: [clothingLayer2Path + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
    /////////////////////////////////
    // LAYER 3
    /////////////////////////////////
    {
      id: "clothingLayer3StyleGroup",
      name: "Layer 3",
      description: "",
      dependencies: [
        {
          description: "Layer 2",
          attribute: [clothingLayer2Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick something for Layer 2 first",
      dataAttribute: [clothingLayer3Path + "shape"],
      previewType: "preview",
      options: ClothingList,
      colourControls: [
        {
          id: "clothingLayer3Colour",
          name: "Colour",
          description: "",
          dataAttribute: [clothingLayer3Path + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
    },
  ],
};

export default ClothingLayersPage;
