import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const shine_Dzi3a67JhC = {
  name: "Shine Dzi3a67JhC",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <circle cx="208.99" cy="243.52" r="2.165" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <circle cx="284.49" cy="243.52" r="2.165" />
        </>
      ),
    },
  },
};

const shine_bgm6qtWMxf = {
  name: "Shine bgm6qtWMxf",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="m208.99 240.897.594 1.829h1.923l-1.556 1.13.595 1.829-1.556-1.13-1.556 1.13.595-1.829-1.556-1.13h1.923l.594-1.829Z" />
      ),
    },
    right: {
      base: (
        <path d="m284.49 240.897.594 1.829h1.923l-1.556 1.13.595 1.829-1.556-1.13-1.556 1.13.595-1.829-1.556-1.13h1.923l.594-1.829Z" />
      ),
    },
  },
};

const shine_zKtCRfvTEy = {
  name: "Shine zKtCRfvTEy",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M208.99,241.355L210.314,243.52L208.99,245.685L207.666,243.52L208.99,241.355Z" />
      ),
    },
    right: {
      base: (
        <path d="M284.49,241.355L285.814,243.52L284.49,245.685L283.166,243.52L284.49,241.355Z" />
      ),
    },
  },
};

const shine_G4NvUJuVh4 = {
  name: "Shine G4NvUJuVh4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M208.99 242.249c.396-.802 1.187-.802 1.583-.401.396.401.396 1.202 0 2.004-.277.601-.989 1.202-1.583 1.603-.594-.401-1.306-1.002-1.583-1.603-.396-.802-.396-1.603 0-2.004s1.187-.401 1.583.401Z" />
      ),
    },
    right: {
      base: (
        <path d="M284.49 242.249c.396-.802 1.187-.802 1.583-.401.396.401.396 1.202 0 2.004-.277.601-.989 1.202-1.583 1.603-.594-.401-1.306-1.002-1.583-1.603-.396-.802-.396-1.603 0-2.004s1.187-.401 1.583.401Z" />
      ),
    },
  },
};

const shine_eT7FCUrpAS = {
  name: "Shine eT7FCUrpAS",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M208.433 242.363c0-.556.249-1.008.557-1.008.308 0 .557.452.557 1.008.435-.347.944-.433 1.135-.193.192.241-.005.717-.44 1.064.542.124.927.468.858.768-.068.3-.564.442-1.106.319.241.501.212 1.016-.065 1.149-.277.134-.698-.165-.939-.666-.241.501-.662.8-.939.666-.277-.133-.306-.648-.065-1.149-.542.123-1.038-.019-1.106-.319-.069-.3.316-.644.858-.768-.435-.347-.632-.823-.44-1.064.191-.24.7-.154 1.135.193Z" />
      ),
    },
    right: {
      base: (
        <path d="M283.933 242.363c0-.556.249-1.008.557-1.008.308 0 .557.452.557 1.008.435-.347.944-.433 1.135-.193.192.241-.005.717-.44 1.064.542.124.927.468.858.768-.068.3-.564.442-1.106.319.241.501.212 1.016-.065 1.149-.277.134-.698-.165-.939-.666-.241.501-.662.8-.939.666-.277-.133-.306-.648-.065-1.149-.542.123-1.038-.019-1.106-.319-.069-.3.316-.644.858-.768-.435-.347-.632-.823-.44-1.064.191-.24.7-.154 1.135.193Z" />
      ),
    },
  },
};

const shine_n8ZPW2zPqP = {
  name: "Shine n8ZPW2zPqP",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M210.521 241.989c-.845-.845-2.217-.845-3.062 0-.845.845-.845 2.217 0 3.062-.047-.648.253-1.513.901-2.161.648-.648 1.513-.948 2.161-.901Z" />
      ),
    },
    right: {
      base: (
        <path d="M286.021 241.989c-.845-.845-2.217-.845-3.062 0-.845.845-.845 2.217 0 3.062-.047-.648.253-1.513.901-2.161.648-.648 1.513-.948 2.161-.901Z" />
      ),
    },
  },
};

const shine_GcMD84k43e = {
  name: "Shine GcMD84k43e",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M209.348 241.385c-.237-.04-.479-.04-.716 0l-.129.46c-.185.054-.361.139-.519.25l-.44-.186c-.179.161-.33.35-.446.56l.279.387c-.073.179-.117.369-.128.562l-.42.228c.014.24.068.476.159.698l.477.023c.094.169.216.322.36.451l-.084.47c.196.139.414.244.645.311l.316-.359c.191.032.385.032.576 0l.316.359c.231-.067.449-.172.645-.311l-.084-.47c.144-.129.266-.282.36-.451l.477-.023c.091-.222.145-.458.159-.698l-.42-.228c-.011-.193-.055-.383-.128-.562l.279-.387c-.116-.21-.267-.399-.446-.56l-.44.186c-.158-.111-.334-.196-.519-.25l-.129-.46Zm-.358 1.069c.589 0 1.066.477 1.066 1.066 0 .589-.477 1.066-1.066 1.066-.589 0-1.066-.477-1.066-1.066 0-.589.477-1.066 1.066-1.066Z" />
      ),
    },
    right: {
      base: (
        <path d="M284.848 241.385c-.237-.04-.479-.04-.716 0l-.129.46c-.185.054-.361.139-.519.25l-.44-.186c-.179.161-.33.35-.446.56l.279.387c-.073.179-.117.369-.128.562l-.42.228c.014.24.068.476.159.698l.477.023c.094.169.216.322.36.451l-.084.47c.196.139.414.244.645.311l.316-.359c.191.032.385.032.576 0l.316.359c.231-.067.449-.172.645-.311l-.084-.47c.144-.129.266-.282.36-.451l.477-.023c.091-.222.145-.458.159-.698l-.42-.228c-.011-.193-.055-.383-.128-.562l.279-.387c-.116-.21-.267-.399-.446-.56l-.44.186c-.158-.111-.334-.196-.519-.25l-.129-.46Zm-.358 1.069c.589 0 1.066.477 1.066 1.066 0 .589-.477 1.066-1.066 1.066-.589 0-1.066-.477-1.066-1.066 0-.589.477-1.066 1.066-1.066Z" />
      ),
    },
  },
};

const shine_4t5hyZSfP3 = {
  name: "Shine 4t5hyZSfP3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M206.884 243.255c.525 0 .951.426.951.951 0 .524-.426.95-.951.95-.525 0-.951-.426-.951-.95 0-.525.426-.951.951-.951Zm2.58-2.85c.9 0 1.63.73 1.63 1.63 0 .899-.73 1.629-1.63 1.629-.899 0-1.629-.73-1.629-1.629 0-.9.73-1.63 1.629-1.63Z" />
      ),
    },
    right: {
      base: (
        <path d="M282.384 243.255c.525 0 .951.426.951.951 0 .524-.426.95-.951.95-.525 0-.951-.426-.951-.95 0-.525.426-.951.951-.951Zm2.58-2.85c.9 0 1.63.73 1.63 1.63 0 .899-.73 1.629-1.63 1.629-.899 0-1.629-.73-1.629-1.629 0-.9.73-1.63 1.629-1.63Z" />
      ),
    },
  },
};

const shine_Qnk2Aw3kJM = {
  name: "Shine Qnk2Aw3kJM",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <path d="M208.99 241.355c1.195 0 2.165.97 2.165 2.165s-.97 2.165-2.165 2.165-2.165-.97-2.165-2.165.97-2.165 2.165-2.165Zm0 .541c.896 0 1.624.728 1.624 1.624 0 .896-.728 1.624-1.624 1.624-.896 0-1.624-.728-1.624-1.624 0-.896.728-1.624 1.624-1.624Z" />
      ),
    },
    right: {
      base: (
        <path d="M284.49 241.355c1.195 0 2.165.97 2.165 2.165s-.97 2.165-2.165 2.165-2.165-.97-2.165-2.165.97-2.165 2.165-2.165Zm0 .541c.896 0 1.624.728 1.624 1.624 0 .896-.728 1.624-1.624 1.624-.896 0-1.624-.728-1.624-1.624 0-.896.728-1.624 1.624-1.624Z" />
      ),
    },
  },
};

export const ShinePack1 = {
  shine_Dzi3a67JhC,
  shine_bgm6qtWMxf,
  shine_zKtCRfvTEy,
  shine_G4NvUJuVh4,
  shine_eT7FCUrpAS,
  shine_n8ZPW2zPqP,
  shine_GcMD84k43e,
  shine_4t5hyZSfP3,
  shine_Qnk2Aw3kJM,
};
