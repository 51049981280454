import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    navKeys: state.menu.navKeys,
  };
};

const Tab = (props) => {
  const { navKeys, id, isSelected, name, icon, onClick } = props;

  const onKeyPress = (event, id) => {
    const pressedKey = event.which || event.keyCode;
    if (navKeys.includes(pressedKey)) {
      onClick(id);
    }
  };

  return (
    <li
      id={`tab-${id}`}
      aria-controls={`panel-${id}`}
      role="tab"
      tabIndex={isSelected ? -1 : 0}
      aria-selected={isSelected}
      className={`menu-tab ${isSelected ? "selected" : ""}`}
      title={`Open ${name} menu`}
      data-navcontroltype="tab"
      onClick={() => onClick(id)}
      onKeyPress={(event) => onKeyPress(event, id)}
    >
      {icon ? <img src={icon} alt="" aria-hidden="true" /> : ""}
      <span>{name}</span>
    </li>
  );
};

export default connect(mapStateToProps)(Tab);
