// Components
import { allColours } from "character-creator/colours/colourSchemes";
import { faceTattoosEyesSidedShapeList } from "character-creator/components/head/markings/tattoos/TattooList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const tattoosPath = "base.head.face.markings.tattoo.";

const leftTransformPath = tattoosPath + "left.transform.";

const rightTransformPath = tattoosPath + "right.transform.";

// TODO: Have multiple options: 1/2/3
const TattoosPage = {
  id: "faceTattoosPage",
  name: "Face Tattoos",
  description: "",
  sections: [
    {
      id: "eyeTattoosGroup",
      name: "Eyes",
      symmetryAttribute: tattoosPath + "symmetry",
      controls: {
        symmetrical: [
          {
            id: "eyeTattoosShape",
            name: "Shape",
            description: "",
            dataAttribute: [
              tattoosPath + "left.shape",
              tattoosPath + "right.shape",
            ],
            variationAttribute: "",
            previewType: "preview",
            previewSide: "L",
            previewViewbox: "0 0 100 100",
            options: faceTattoosEyesSidedShapeList,
            colourControls: [
              {
                id: "eyeTattoosColour",
                name: "Colour",
                description: "",
                dataAttribute: [
                  tattoosPath + "left.colour",
                  tattoosPath + "right.colour",
                ],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
            transformControls: [
              {
                id: "eyeTattoosTransformX",
                name: "Horizontal",
                description: "",
                dataAttribute: [
                  leftTransformPath + "x",
                  rightTransformPath + "x",
                ],
                range: {
                  min: -50,
                  max: 50,
                  step: 10,
                },
              },
              {
                id: "eyeTattoosTransformY",
                name: "Vertical",
                description: "",
                dataAttribute: [
                  leftTransformPath + "y",
                  rightTransformPath + "y",
                ],
                range: {
                  min: -50,
                  max: 50,
                  step: 10,
                },
              },
              {
                id: "eyeTattoosTransformScale",
                name: "Size",
                description: "",
                dataAttribute: [
                  leftTransformPath + "scale",
                  rightTransformPath + "scale",
                ],
                range: {
                  min: 0.5,
                  max: 1.5,
                  step: 0.25,
                },
              },
              {
                id: "eyeTattoosTransformO",
                name: "Opacity",
                description: "",
                dataAttribute: [
                  leftTransformPath + "opacity",
                  rightTransformPath + "opacity",
                ],
                range: {
                  min: 0.25,
                  max: 1,
                  step: 0.25,
                },
              },
            ],
          },
        ],
        asymmetrical: [
          {
            id: "faceTattooLeft",
            name: "Left shape",
            description: "",
            dataAttribute: [tattoosPath + "left.shape"],
            variationAttribute: "",
            previewType: "preview",
            previewSide: "L",
            previewViewbox: "0 0 100 100",
            options: faceTattoosEyesSidedShapeList,
            colourControls: [
              {
                id: "faceTattooLeftColour",
                name: "Left colour",
                description: "",
                dataAttribute: [tattoosPath + "left.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
            transformControls: [
              {
                id: "faceTattooLeftTransformX",
                name: "Horizontal",
                description: "",
                dataAttribute: [leftTransformPath + "x"],
                range: {
                  min: -50,
                  max: 50,
                  step: 10,
                },
              },
              {
                id: "faceTattooLeftTransformY",
                name: "Vertical",
                description: "",
                dataAttribute: [leftTransformPath + "y"],
                range: {
                  min: -50,
                  max: 50,
                  step: 10,
                },
              },
              {
                id: "faceTattooLeftTransformScale",
                name: "Size",
                description: "",
                dataAttribute: [leftTransformPath + "scale"],
                range: {
                  min: 0.5,
                  max: 1.5,
                  step: 0.25,
                },
              },
              {
                id: "faceTattooLeftTransformO",
                name: "Opacity",
                description: "",
                dataAttribute: [leftTransformPath + "opacity"],
                range: {
                  min: 0.25,
                  max: 1,
                  step: 0.25,
                },
              },
            ],
          },
          {
            id: "faceTattooRight",
            name: "Right",
            description: "",
            dataAttribute: [tattoosPath + "right.shape"],
            variationAttribute: "",
            previewType: "preview",
            previewSide: "R",
            previewViewbox: "0 0 100 100",
            options: faceTattoosEyesSidedShapeList,
            colourControls: [
              {
                id: "faceTattooRightColour",
                name: "Right colour",
                description: "",
                dataAttribute: [tattoosPath + "right.colour"],
                attributeType: "multi",
                previewType: "swatch",
                options: allColours,
              },
            ],
            transformControls: [
              {
                id: "faceTattooRightTransformX",
                name: "Horizontal",
                description: "",
                dataAttribute: [rightTransformPath + "x"],
                range: {
                  min: -50,
                  max: 50,
                  step: 10,
                },
              },
              {
                id: "faceTattooRightTransformY",
                name: "Vertical",
                description: "",
                dataAttribute: [rightTransformPath + "y"],
                range: {
                  min: -50,
                  max: 50,
                  step: 10,
                },
              },
              {
                id: "faceTattooRightTransformScale",
                name: "Size",
                description: "",
                dataAttribute: [rightTransformPath + "scale"],
                range: {
                  min: 0.5,
                  max: 1.5,
                  step: 0.25,
                },
              },
              {
                id: "faceTattooRightTransformO",
                name: "Opacity",
                description: "",
                dataAttribute: [leftTransformPath + "opacity"],
                range: {
                  min: 0.25,
                  max: 1,
                  step: 0.25,
                },
              },
            ],
          },
        ],
      },
    },
  ],
};

export default TattoosPage;
