import React from "react";
import Background from "./background/Background";
import CharacterBase from "./CharacterBase";

const Portrait = (props) => {
  return (
    <svg
      id="portrait-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <Background />
      <CharacterBase />
    </svg>
  );
};

export default Portrait;
