import { allColours } from "character-creator/colours/colourSchemes";
import { NeckAccessoriesList } from "character-creator/components/clothing/Accessories/Neck/NeckAccessoriesList";

const neckAccessoriesPath = "clothing.accessories.neck.";
const neckAccessory1Path = neckAccessoriesPath + "layer1.";
const neckAccessory2Path = neckAccessoriesPath + "layer2.";
const neckAccessory3Path = neckAccessoriesPath + "layer3.";

const NeckAccessoriesPage = {
  id: "neckAccessorysPage",
  name: "Neck",
  controls: [
    {
      id: "neckAccessory1StyleGroup",
      name: "Layer 1",
      description: "",
      dataAttribute: [neckAccessory1Path + "shape"],
      previewType: "preview",
      options: NeckAccessoriesList,
      colourControls: [
        {
          id: "neckAccessory1ColourMain",
          name: "Main Colour",
          description: "",
          dataAttribute: [neckAccessory1Path + "colour.main"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
        {
          id: "neckAccessory1ColourExtra",
          name: "Extra Colour",
          description: "",
          dataAttribute: [neckAccessory1Path + "colour.extra"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "neckAccessory1TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [neckAccessory1Path + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "neckAccessory1TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [neckAccessory1Path + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "neckAccessory2StyleGroup",
      name: "Layer 2",
      description: "",
      dependencies: [
        {
          description: "Layer 1",
          attribute: [neckAccessory1Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick something for Layer 1 first",
      dataAttribute: [neckAccessory2Path + "shape"],
      previewType: "preview",
      options: NeckAccessoriesList,
      colourControls: [
        {
          id: "neckAccessory2ColourMain",
          name: "MainColour",
          description: "",
          dataAttribute: [neckAccessory2Path + "colour.main"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
        {
          id: "neckAccessory2ColourExtra",
          name: "Extra Colour",
          description: "",
          dataAttribute: [neckAccessory2Path + "colour.extra"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "neckAccessory2TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [neckAccessory2Path + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "neckAccessory2TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [neckAccessory2Path + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "neckAccessory3StyleGroup",
      name: "Layer 3",
      description: "",
      dependencies: [
        {
          description: "Layer 2",
          attribute: [neckAccessory2Path + "shape"],
        },
      ],
      dependencyMessage: "Please pick something for Layer 2 first",
      dataAttribute: [neckAccessory3Path + "shape"],
      previewType: "preview",
      options: NeckAccessoriesList,
      colourControls: [
        {
          id: "neckAccessory3ColourMain",
          name: "Main Colour",
          description: "",
          dataAttribute: [neckAccessory3Path + "colour.main"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
        {
          id: "neckAccessory3ColourExtra",
          name: "Extra Colour",
          description: "",
          dataAttribute: [neckAccessory3Path + "colour.extra"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "neckAccessory3TransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [neckAccessory3Path + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "neckAccessory3TransformScale",
          name: "Size",
          description: "",
          dataAttribute: [neckAccessory3Path + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default NeckAccessoriesPage;
