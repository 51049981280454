import React from "react";
import Blank from "character-creator/menu/elements/controls/options/BlankOption";
import { nanoid } from "@reduxjs/toolkit";
import { defaultViewbox, SvgButton } from "./SvgConfig";

const Preview = (props) => {
  const { component, side } = props;
  const { viewbox, base } = component.preview;

  const PreviewBase = base ? base : Blank;

  return (
    <SvgButton
      title={component.name}
      viewbox={viewbox ? viewbox : defaultViewbox}
    >
      <PreviewBase previewId={nanoid(5)} component={component} side={side} />
    </SvgButton>
  );
};

export default Preview;
