import React from "react";
import { viewbox, PreviewBase } from "../../Preview";

const vest_HVu6SCdVM7 = {
  name: "V-neck vest (Square neck, narrow straps)",
  description: "A v-neck vest with a square neck and narrow straps",
  tags: ["basic", "vest", "square", "sleeveless"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      base: (
        <path
          id="base"
          d="M369.498 450.977c-3.338-20.397-7.237-41.86-10.434-52.843-.58-1.994 10.704 1.042 12.31 3.198 15.102 20.285 18.428 99.332 18.428 99.332H110.198s3.326-79.047 18.428-99.332c1.606-2.156 12.89-5.192 12.31-3.198-3.197 10.983-7.096 32.446-10.434 52.843h238.996Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 1B
    base_body_shape_taw74: {
      base: (
        <path
          id="base"
          d="M108.26 500s7.741-92.122 23.059-112.084c1.853-2.414 13.013-5.898 12.386-3.633-3.79 13.679-8.723 41.668-12.775 66.694h238.14c-4.052-25.026-8.985-53.015-12.775-66.694-.627-2.265 10.533 1.219 12.386 3.633C383.999 407.878 391.74 500 391.74 500H108.26Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 1C
    base_body_shape_jnr29: {
      base: (
        <path
          id="base"
          d="M108.312 500s7.089-78.03 20.123-95.52c1.594-2.138 13.341-7.479 12.763-5.502-3.159 10.82-6.997 31.892-10.291 51.999h238.186c-3.294-20.107-7.132-41.179-10.291-51.999-.578-1.977 11.169 3.364 12.763 5.502 13.034 17.49 20.123 95.52 20.123 95.52H108.312Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 2A
    base_body_shape_g825h: {
      base: (
        <path
          id="base"
          d="M369.371 450.977c-3.217-19.912-6.991-40.826-10.207-52.024-.592-2.061 10.21.415 11.939 2.588C384.974 418.974 391.828 500 391.828 500H108.172s6.854-81.026 20.725-98.459c1.729-2.173 12.531-4.649 11.939-2.588-3.216 11.198-6.99 32.112-10.207 52.024h238.742Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 2B
    base_body_shape_fa048: {
      base: (
        <path
          id="base"
          d="M109.074 500s6.168-97.383 23.872-112.364c2.401-2.032 13.163-5.399 12.522-3.056-3.845 14.052-8.962 41.691-13.208 66.397h235.48c-4.246-24.706-13.283-53.147-15.832-67.492-.712-4.009 12.745 2.119 15.146 4.151C384.758 402.617 390.926 500 390.926 500H109.074Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 2C
    base_body_shape_mge5o: {
      base: (
        <path
          id="base"
          d="M390.89 500H109.11s2.77-77.775 19.35-95.851c5.168-5.633 14.398-6.317 13.432-2.648-2.811 10.681-6.468 30.439-9.721 49.476h235.658c-3.253-19.037-5.451-40.067-8.262-50.748-.966-3.669 9.077-.288 12.443 3.795C387.615 422.949 390.89 500 390.89 500Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 3A
    base_body_shape_gj245: {
      base: (
        <path
          id="base"
          d="M390.905 500h-281.81s3.255-81.289 20.631-98.437c1.955-1.929 12.863-7.145 12.275-5.107-3.278 11.361-7.212 33.577-10.539 54.521h237.076c-3.327-20.944-7.261-43.16-10.539-54.521-.588-2.038 10.32 3.178 12.275 5.107C387.65 418.711 390.905 500 390.905 500Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 3B
    base_body_shape_942n5: {
      base: (
        <path
          id="base"
          d="M109.094 500s5.951-93.675 23.142-112.861c2.093-2.336 13.576-8.836 12.937-6.5-3.943 14.393-9.156 44.142-13.39 70.338h236.434c-4.234-26.196-9.447-55.945-13.39-70.338-.639-2.336 10.844 4.164 12.937 6.5C384.955 406.325 390.906 500 390.906 500H109.094Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path
          id="base"
          d="M109.447 500s2.521-80.462 20.261-96.31c2.005-1.791 13.351-7.541 12.77-5.547-3.195 10.98-7.094 32.434-10.433 52.834h235.91c-3.339-20.4-7.238-41.854-10.433-52.834-.581-1.994 10.765 3.756 12.77 5.547 17.74 15.848 20.261 96.31 20.261 96.31H109.447Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path
          id="base"
          d="M109 500s2.91-84.181 21.178-101.58c2.05-1.953 13.015-6.265 12.416-4.163-3.39 11.893-7.539 35.08-11.042 56.72h236.769c-3.503-21.64-7.653-44.827-11.042-56.72-.599-2.102 10.365 2.21 12.416 4.163C387.963 415.819 390.873 500 390.873 500H109Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path
          id="base"
          d="M109.141 500s7.602-99.26 26.934-118.132c2.296-2.24 12.06-4.196 11.41-1.806-4.002 14.734-9.783 44.646-14.569 70.915h234.168c-4.786-26.269-10.567-56.181-14.569-70.915-.65-2.39 9.114-.434 11.41 1.806C383.257 400.74 390.859 500 390.859 500H109.141Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      base: (
        <path
          id="base"
          d="M109.007 500s3.02-81.084 22.023-100.805c1.887-1.959 12.848-3.543 12.264-1.526-3.227 11.141-7.391 32.783-11.008 53.308h235.428c-3.617-20.525-7.781-42.167-11.008-53.308-.584-2.017 10.377-.433 12.264 1.526C387.973 418.916 390.993 500 390.993 500H109.007Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.552 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.896 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.489-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-22.308-3.203c-1.955.173-4.306-.059-4.306-.059s9.872-54.462 14.15-55.005l-9.844 55.064Zm234.698 0c1.955.173 4.306-.059 4.306-.059s-9.872-54.462-14.15-55.005l9.844 55.064Z" />
        ),
      },
    },
  },
};

const vest_K9Fq3qWJ7Y = {
  name: "V-neck vest (Square neck, medium straps)",
  description: "A v-neck vest with a square neck and medium straps",
  tags: ["basic", "vest", "square", "sleeveless"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      base: (
        <path
          id="base"
          d="M355.639 450.977c-2.621-21.139-6.085-43.705-9.993-55.621-1.32-4.025 20.846-1.422 26.705 9.41C386.973 431.803 390.834 500 390.834 500H109.166s3.861-68.197 18.483-95.234c5.859-10.832 28.025-13.435 26.705-9.41-3.908 11.916-7.372 34.482-9.993 55.621h211.278Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 1B
    base_body_shape_taw74: {
      base: (
        <path
          id="base"
          d="M144.601 450.977h210.796c-3.371-26.777-7.905-57.434-12.493-72.242-1.393-4.495 23.675 1.922 27.903 14.922C377.667 414.747 391 500 391 500H108.998s13.333-85.253 20.193-106.343c4.229-13 29.296-19.417 27.903-14.922-4.587 14.808-9.122 45.465-12.493 72.242Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 1C
    base_body_shape_jnr29: {
      base: (
        <path
          id="base"
          d="M391 500H108.998s11.524-76.923 18.853-94.706c2.639-6.405 28.117-13.389 25.672-7.465-4.549 11.025-7.917 32.629-10.251 53.148h213.454c-2.334-20.519-5.702-42.123-10.251-53.148-2.445-5.924 23.033 1.06 25.673 7.465C379.477 423.077 391 500 391 500Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 2A
    base_body_shape_g825h: {
      base: (
        <path d="M355.339 450.977c-2.619-20.764-4.54-42.641-8.416-54.456-1.32-4.026 18.598-4.018 25.633 7.985 14.16 24.16 18.062 95.494 18.062 95.494H109.382s2.498-72.24 18.483-95.234c7.03-10.112 28.055-12.492 26.735-8.467-3.876 11.816-7.32 33.914-9.939 54.678h210.678Z" />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 2B
    base_body_shape_fa048: {
      base: (
        <path
          id="base"
          d="M108.998 500s4.832-84.221 20.423-109.56c5.574-9.059 28.644-14.42 27.252-9.925-4.537 14.643-8.918 44.344-12.171 70.462H355.35c-3.253-26.118-7.195-54.727-11.732-69.37-1.393-4.496 20.92-.884 26.494 8.176C385.702 415.121 390.854 500 390.854 500H108.998Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 2C
    base_body_shape_mge5o: {
      base: (
        <path
          id="base"
          d="M144.574 450.977h210.517c-2.614-20.139-6.837-41.139-10.28-52.384-1.764-5.761 20.557-2.701 26.864 5.002C388.353 423.961 390.666 500 390.666 500H108.998s3.212-75.381 19.854-95.776c6.178-7.571 26.72-12.095 24.956-6.334-3.443 11.245-6.621 32.948-9.234 53.087Z"
        />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 3A
    base_body_shape_gj245: {
      base: (
        <path d="M109.263 500s1.918-74.661 18.729-93.876c7.797-8.912 28.027-14.129 26.733-10.269-3.843 11.456-7.363 33.954-10.063 55.122h210.676c-2.7-21.168-6.22-43.666-10.063-55.122-1.294-3.86 18.936 1.357 26.733 10.269C388.819 425.339 390.737 500 390.737 500H109.263Z" />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 3B
    base_body_shape_942n5: {
      base: (
        <path d="M109.627 500s3.495-89.966 22.225-110.64c7.618-8.409 27.742-15.654 26.349-11.159-4.602 14.856-9.273 45.797-12.769 72.776H354.28c-3.496-26.979-8.167-57.92-12.769-72.776-1.393-4.495 18.669 2.835 26.287 11.243C386.528 410.118 390.085 500 390.085 500H109.627Z" />
      ),
      basic: {
        dark: (
          <path d="M116.347 511.008s-1.446-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.563 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.55.6-3.694 1.377-.144.776 1.228 1.466 3.146 1.581l47.52 2.858c.182.011.365.016.549.016h95.041c.183 0 .367-.005.548-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.755-1.377-3.695-1.377H155.587Zm-6.231-3.154-7.186-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.142 58.102-8.142 58.102Zm202.545 0 7.186-.248s-3.849-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path d="M109.143 500s1.73-75.217 17.892-91.89c8.27-8.532 28.484-14.568 27.188-10.684-3.769 11.302-7.152 32.851-9.745 53.285h210.756c-2.593-20.434-5.976-41.983-9.745-53.285-1.295-3.884 18.918 2.152 27.188 10.684C388.84 424.783 390.569 500 390.569 500H109.143Z" />
      ),
      basic: {
        dark: (
          <path d="M115.574 511.068s-1.445-69.494 4.117-89.217c5.563-19.724-20.108 38.904-20.108 38.904s4.081 46.071 15.991 50.313Zm268.564 0s1.445-69.494-4.117-89.217c-5.562-19.724 20.109 38.904 20.109 38.904s-4.082 46.071-15.992 50.313Zm-229.323-57.234c-1.939 0-3.55.604-3.694 1.385-.145.781 1.227 1.474 3.145 1.59l47.521 2.873c.182.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.873c1.918-.116 3.29-.809 3.146-1.59-.144-.781-1.756-1.385-3.695-1.385H154.815Zm-6.232-3.171-7.185-.249s3.848-54.29 15.328-58.168c11.481-3.879-8.143 58.417-8.143 58.417Zm202.546 0 7.185-.249s-3.848-54.29-15.328-58.168c-11.481-3.879 8.143 58.417 8.143 58.417Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path d="M390.211,500L109.789,500C109.789,500 112.82,417.666 129.33,399.332C134.9,393.147 157.256,387.378 155.936,391.404C151.894,403.728 148.117,428.309 145.243,450.977L354.757,450.977C351.883,428.309 348.106,403.728 344.064,391.404C342.744,387.378 365.1,393.147 370.67,399.332C387.18,417.666 390.211,500 390.211,500Z" />
      ),
      basic: {
        dark: (
          <path d="M115.718,511.008C115.718,511.008 114.273,441.89 119.835,422.273C125.398,402.656 99.726,460.967 99.726,460.967C99.726,460.967 103.808,506.789 115.718,511.008ZM384.282,511.008C384.282,511.008 385.727,441.89 380.165,422.273C374.602,402.656 400.274,460.967 400.274,460.967C400.274,460.967 396.192,506.789 384.282,511.008ZM154.959,454.084C153.02,454.084 151.408,454.684 151.264,455.461C151.12,456.237 152.492,456.927 154.41,457.042L201.931,459.9C202.112,459.911 202.296,459.916 202.48,459.916L297.52,459.916C297.704,459.916 297.888,459.911 298.069,459.9L345.59,457.042C347.508,456.927 348.88,456.237 348.736,455.461C348.592,454.684 346.98,454.084 345.041,454.084L154.959,454.084ZM148.727,450.93L141.542,450.682C141.542,450.682 145.39,396.685 156.87,392.828C168.351,388.971 148.727,450.93 148.727,450.93ZM351.273,450.93L358.458,450.682C358.458,450.682 354.61,396.685 343.13,392.828C331.649,388.971 351.273,450.93 351.273,450.93Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path d="M108.925 500s1.406-93.484 17.577-113.448c7.147-8.823 28.374-12.01 26.976-7.397-4.602 15.178-7.869 43.797-10.465 70.977h213.974c-2.596-27.18-5.829-55.265-10.431-70.442-1.398-4.614 19.795-1.961 26.942 6.862C389.669 406.516 391.075 500 391.075 500h-282.15Z" />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.198s-1.445-70.309 4.117-90.265c5.563-19.955-20.109 39.361-20.109 39.361s4.082 46.612 15.992 50.904Zm268.564 0s1.445-70.309-4.117-90.265c-5.563-19.955 20.109 39.361 20.109 39.361s-4.082 46.612-15.992 50.904Zm-229.323-57.906c-1.939 0-3.551.611-3.695 1.401-.144.79 1.228 1.492 3.146 1.609l47.521 2.907c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.907c1.918-.117 3.29-.819 3.146-1.609-.144-.79-1.756-1.401-3.695-1.401H154.959Zm-6.232-3.208-7.185-.253s3.848-54.927 15.328-58.85c11.481-3.924-8.143 59.103-8.143 59.103Zm202.546 0 7.185-.253s-3.848-54.927-15.328-58.85c-11.481-3.924 8.143 59.103 8.143 59.103Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      base: (
        <path d="M145.282 450.977h209.436c-2.998-21.229-6.811-43.917-10.728-55.86-1.321-4.025 19.919 1.678 22.769 3.873C387.83 415.223 390.845 500 390.845 500h-281.69s3.015-84.777 24.086-101.01c2.85-2.195 24.09-7.898 22.769-3.873-3.917 11.943-7.73 34.631-10.728 55.86Z" />
      ),
      basic: {
        dark: (
          <path d="M115.718 511.008s-1.445-69.118 4.117-88.735c5.563-19.617-20.109 38.694-20.109 38.694s4.082 45.822 15.992 50.041Zm268.564 0s1.445-69.118-4.117-88.735c-5.563-19.617 20.109 38.694 20.109 38.694s-4.082 45.822-15.992 50.041Zm-229.323-56.924c-1.939 0-3.551.6-3.695 1.377-.144.776 1.228 1.466 3.146 1.581l47.521 2.858c.181.011.365.016.549.016h95.04c.184 0 .368-.005.549-.016l47.521-2.858c1.918-.115 3.29-.805 3.146-1.581-.144-.777-1.756-1.377-3.695-1.377H154.959Zm-6.232-3.154-7.185-.248s3.848-53.997 15.328-57.854c11.481-3.857-8.143 58.102-8.143 58.102Zm202.546 0 7.185-.248s-3.848-53.997-15.328-57.854c-11.481-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
  },
};

const vest_t2p4LzYyCv = {
  name: "V-neck vest (Square neck, wide straps)",
  description: "A v-neck vest with a square neck and wide straps",
  tags: ["basic", "vest", "square", "sleeveless"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      base: (
        <path d="M391.055 500h-282.11s5.547-81.183 21.351-99.197c6.112-6.967 42.357-12.76 40.597-8.567-5.316 12.667-8.73 36.624-10.865 58.741h179.944c-2.135-22.117-5.549-46.074-10.865-58.741-1.76-4.193 34.485 1.6 40.597 8.567C385.508 418.817 391.055 500 391.055 500Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 1B
    base_body_shape_taw74: {
      base: (
        <path d="M109 500s4.22-92.286 23.363-112.365c7.133-7.483 44.127-18.572 42.282-13.822-6.274 16.153-10.208 48.209-13.365 77.122h177.236c-3.157-28.913-7.771-61.518-14.045-77.672-1.845-4.749 35.829 6.889 42.962 14.372C386.576 407.714 390.796 500 390.796 500H109Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.018s-1.445-69.178 4.117-88.812-20.109 38.728-20.109 38.728 4.081 45.86 15.992 50.084Zm268.684 0s1.445-69.178-4.117-88.812 20.109 38.728 20.109 38.728-4.081 45.86-15.992 50.084Zm-209.164-56.353c-1.527 0-2.795.473-2.908 1.085-.114.612.966 1.156 2.476 1.247l37.411 2.251c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.251c1.51-.091 2.59-.635 2.476-1.247-.113-.612-1.381-1.085-2.908-1.085H175.178Zm-12.076-3.778-7.186-.248s3.848-54.042 15.329-57.903c11.48-3.861-8.143 58.151-8.143 58.151Zm173.796 0 7.186-.248s-3.848-54.042-15.329-57.903c-11.48-3.861 8.143 58.151 8.143 58.151Z" />
        ),
      },
    },
    // Body 1C
    base_body_shape_jnr29: {
      base: (
        <path d="M160.842 450.977h178.316c-2.419-21.732-6.063-45.088-11.267-57.17-1.734-4.025 35.19 3.256 41.634 9.462C387.018 420.117 391.008 500 391.008 500H108.992s3.99-79.883 21.483-96.731c6.444-6.206 43.368-13.487 41.634-9.462-5.204 12.082-8.848 35.438-11.267 57.17Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 2A
    base_body_shape_g825h: {
      base: (
        <path d="M109.181 500s3.72-81.285 21.351-99.197c6.501-6.605 42.772-15.209 40.233-8.672-4.969 12.794-8.277 36.753-10.4 58.846h179.27c-2.123-22.093-5.501-44.826-10.469-57.621-2.539-6.537 33.801.842 40.302 7.447C387.099 418.715 390.819 500 390.819 500H109.181Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 2B
    base_body_shape_fa048: {
      base: (
        <path d="M109.144 500s5.507-91.043 23.434-112.734c6.607-7.994 43.035-18.036 40.487-11.905-6.548 15.758-10.6 47.909-12.998 75.616h179.866c-2.398-27.707-5.844-58.766-12.392-74.524-2.548-6.13 33.274 2.819 39.881 10.813C385.349 408.957 390.856 500 390.856 500H109.144Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 2C
    base_body_shape_mge5o: {
      base: (
        <path d="M109.112 500s4.63-80.577 22.567-97.245c6.553-6.09 42.2-14.768 39.546-8.048-4.782 12.113-8.164 34.951-10.431 56.27h178.412c-2.267-21.319-5.919-43.296-10.702-55.409-2.653-6.72 33.546 1.244 40.1 7.334C386.541 419.57 390.888 500 390.888 500H109.112Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 3A
    base_body_shape_gj245: {
      base: (
        <path d="M109.507 500s2.729-81.346 21.351-99.197c6.69-6.413 45.721-21.503 42.324-13.137-5.355 13.185-9.209 39.473-11.774 63.311h177.184c-2.565-23.838-6.419-50.126-11.774-63.311-3.397-8.366 35.634 6.724 42.324 13.137C387.764 418.654 390.493 500 390.493 500H109.507Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 3B
    base_body_shape_942n5: {
      base: (
        <path d="M109.149 500s7.133-95.426 23.874-115.589c6.775-8.16 43.926-17.949 42.059-13.063-6.345 16.611-10.98 50.713-13.969 79.629h177.774c-2.989-28.916-7.624-63.018-13.969-79.629-1.867-4.886 35.284 4.903 42.059 13.063C383.718 404.574 390.851 500 390.851 500H109.149Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 3C
    base_body_shape_0424n: {
      base: (
        <path d="M108.964 500s5.898-76.637 22.263-94.345c6.29-6.806 43.787-20.869 40.442-13.601-5.767 12.53-9.381 36.658-11.598 58.923h179.858c-2.217-22.265-5.831-46.393-11.598-58.923-3.345-7.268 34.152 6.795 40.442 13.601C385.138 423.363 391.036 500 391.036 500H108.964Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 4A
    base_body_shape_fds8g: {
      base: (
        <path d="M390.988 500H109.012s5.565-81.433 22.211-101.165c4.977-5.9 43.58-16.271 41.175-10.19-5.192 13.131-8.957 38.897-11.479 62.332h178.162c-2.522-23.435-6.287-49.201-11.479-62.332-2.405-6.081 36.198 4.29 41.175 10.19C385.423 418.567 390.988 500 390.988 500Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 4B
    base_body_shape_sg894: {
      base: (
        <path d="M109.1 500s6.554-91.369 23.76-114.85c6.233-8.506 44.482-18.232 42.621-13.377-6.319 16.478-11.072 50.392-14.182 79.204h177.402c-3.11-28.812-7.863-62.726-14.182-79.204-1.861-4.855 36.388 4.871 42.621 13.377C384.346 408.631 390.9 500 390.9 500H109.1Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
    // Body 4C
    base_body_shape_fa84m: {
      base: (
        <path d="M390.777 500H109.223s7.115-84.32 24.303-100.937c6.663-6.441 41.08-13.363 38.629-7.48-5.311 12.75-8.943 37.039-11.309 59.394h178.308c-2.366-22.355-5.998-46.644-11.309-59.394-2.451-5.883 31.966 1.039 38.629 7.48C383.662 415.68 390.777 500 390.777 500Z" />
      ),
      basic: {
        dark: (
          <path d="M115.658 511.008s-1.445-69.118 4.117-88.735c5.562-19.617-20.109 38.694-20.109 38.694s4.081 45.822 15.992 50.041Zm268.684 0s1.445-69.118-4.117-88.735c-5.562-19.617 20.109 38.694 20.109 38.694s-4.081 45.822-15.992 50.041Zm-209.164-56.304c-1.527 0-2.795.473-2.908 1.084-.114.612.966 1.155 2.476 1.245l37.411 2.25c.143.009.287.013.432.013h74.822c.145 0 .289-.004.432-.013l37.411-2.25c1.51-.09 2.59-.633 2.476-1.245-.113-.611-1.381-1.084-2.908-1.084H175.178Zm-12.076-3.774-7.186-.248s3.848-53.997 15.329-57.854c11.48-3.857-8.143 58.102-8.143 58.102Zm173.796 0 7.186-.248s-3.848-53.997-15.329-57.854c-11.48-3.857 8.143 58.102 8.143 58.102Z" />
        ),
      },
    },
  },
};

export const VestsPack1 = {
  vest_HVu6SCdVM7,
  vest_K9Fq3qWJ7Y,
  vest_t2p4LzYyCv,
};
