import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { TailShapeList } from "./TailShapeList";

function mapStateToProps(state, ownProps) {
  const tailConfig = state.config.base.body.extras.tail;
  let tailObject = null;
  if (ownProps.preview && ownProps.tail) {
    tailObject = ownProps.tail;
  } else if (tailConfig.shape !== "0") {
    tailObject = TailShapeList[tailConfig.shape];
  }

  const colour = tailConfig.colour
    ? tailConfig.colour
    : state.config.base.skintone;

  return {
    Tail: tailObject,
    colour: colour,
  };
}

const CharacterTail = (props) => {
  const { Tail, colour, previewId } = props;

  if (Tail) {
    return (
      <g id={previewId ? `g-tail~${previewId}` : "g-tail"}>
        <Component
          id={previewId ? `tail~${previewId}` : "tail"}
          component={Tail}
          colour={colour}
          preview={previewId ? true : false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterTail);
