import React from "react";

import { previewViewbox, PreviewBase } from "../Preview";

const body_scars_DUvBf5TYAi = {
  name: "Body scar 1",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: <ellipse cx="250" cy="426.922" rx="4.03" ry="43.56" />,
  },
};

const body_scars_9pSQWgUdZ6 = {
  name: "Body scar 2",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M269.81 407.102c-1.44-1.44-10.01 4.77-19.82 14.18-9.8-9.41-18.38-15.62-19.82-14.18-1.44 1.44 4.77 10.01 14.18 19.82-9.41 9.8-15.62 18.38-14.18 19.82 1.44 1.44 10.01-4.77 19.82-14.18 9.8 9.41 18.38 15.62 19.82 14.18 1.44-1.44-4.77-10.01-14.18-19.82 9.41-9.81 15.62-18.38 14.18-19.82Z" />
    ),
  },
};

const body_scars_dFkked88mU_L = {
  name: "Body scar 3 (Left)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M24.944 558.237c10.087-16.725 21.731-32.572 35.572-46.261 13.842-13.69 29.925-25.19 47.768-32.622-1.463 5.695-32.855 27.805-40.356 35.269-23.736 23.603-22.285 21.138-39.774 49.82-.849 1.398-2.014 2.996-3.623 2.919-1.821-.087-2.805-2.451-2.247-4.219.559-1.767 1.645-3.21 2.66-4.906Zm-9.948-32.859c6.866-18.279 15.434-35.984 26.544-51.963 11.109-15.978 24.83-30.223 41.021-40.78-.393 5.866-27.244 33.336-33.255 42.032-19.041 27.538-18.059 24.845-30.026 56.235-.583 1.535-1.43 3.304-3.03 3.528-1.801.247-3.199-1.899-2.973-3.739.218-1.851 1.027-3.464 1.719-5.313Zm20.025-8.1c10.088-16.724 21.731-32.571 35.573-46.261 13.842-13.689 29.925-25.189 47.767-32.622-1.463 5.696-32.854 27.806-40.355 35.27-23.736 23.602-22.286 21.138-39.775 49.82-.848 1.398-2.014 2.995-3.622 2.919-1.822-.088-2.806-2.451-2.247-4.219.558-1.768 1.647-3.22 2.659-4.907Z" />
    ),
  },
};

const body_scars_dFkked88mU_R = {
  name: "Body scar 3 (Right)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M475.056 558.237c-10.087-16.725-21.731-32.572-35.572-46.261-13.842-13.69-29.925-25.19-47.768-32.622 1.463 5.695 32.855 27.805 40.356 35.269 23.736 23.603 22.285 21.138 39.774 49.82.849 1.398 2.014 2.996 3.623 2.919 1.821-.087 2.805-2.451 2.247-4.219-.559-1.767-1.645-3.21-2.66-4.906Zm9.948-32.859c-6.866-18.279-15.434-35.984-26.544-51.963-11.109-15.978-24.83-30.223-41.021-40.78.393 5.866 27.244 33.336 33.255 42.032 19.041 27.538 18.059 24.845 30.026 56.235.583 1.535 1.43 3.304 3.03 3.528 1.801.247 3.199-1.899 2.973-3.739-.218-1.851-1.027-3.464-1.719-5.313Zm-20.025-8.1c-10.088-16.724-21.731-32.571-35.573-46.261-13.842-13.689-29.925-25.189-47.767-32.622 1.463 5.696 32.854 27.806 40.355 35.27 23.736 23.602 22.286 21.138 39.775 49.82.848 1.398 2.014 2.995 3.622 2.919 1.822-.088 2.806-2.451 2.247-4.219-.558-1.768-1.647-3.22-2.659-4.907Z" />
    ),
  },
};

const body_scars_a5vGG39JVF_L = {
  name: "Body scar 4 (Left)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M130.926 535.795c13.649-13.971 28.611-26.731 45.219-36.888 16.608-10.157 34.897-17.67 53.967-20.82-2.728 5.209-38.348 19.545-47.358 25.094-28.51 17.541-26.533 15.474-50.124 39.391-1.146 1.166-2.646 2.454-4.195 2.012-1.753-.503-2.169-3.029-1.221-4.622s2.336-2.748 3.712-4.167Zm-2.161-34.263c10.869-16.222 23.263-31.495 37.735-44.506 14.473-13.011 31.09-23.736 49.268-30.306-1.725 5.62-34.152 26.214-41.995 33.302-24.839 22.448-23.267 20.051-42.102 47.868-.919 1.36-2.149 2.888-3.758 2.74-1.81-.172-2.679-2.581-2.038-4.32.637-1.752 1.794-3.137 2.89-4.778Zm21.348-3.301c13.649-13.97 28.611-26.731 45.219-36.888 16.608-10.157 34.897-17.669 53.967-20.82-2.728 5.21-38.348 19.546-47.358 25.095-28.51 17.541-26.533 15.474-50.124 39.39-1.146 1.166-2.646 2.455-4.195 2.012-1.753-.502-2.169-3.028-1.221-4.621.948-1.593 2.34-2.757 3.712-4.168Z" />
    ),
  },
};

const body_scars_a5vGG39JVF_R = {
  name: "Body scar 4 (Right)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M369.074 535.795c-13.649-13.971-28.611-26.731-45.219-36.888-16.608-10.157-34.897-17.67-53.967-20.82 2.728 5.209 38.348 19.545 47.358 25.094 28.51 17.541 26.533 15.474 50.124 39.391 1.146 1.166 2.646 2.454 4.195 2.012 1.753-.503 2.169-3.029 1.221-4.622s-2.336-2.748-3.712-4.167Zm2.161-34.263c-10.869-16.222-23.263-31.495-37.735-44.506-14.473-13.011-31.09-23.736-49.268-30.306 1.725 5.62 34.152 26.214 41.995 33.302 24.839 22.448 23.267 20.051 42.102 47.868.919 1.36 2.149 2.888 3.758 2.74 1.81-.172 2.679-2.581 2.038-4.32-.637-1.752-1.794-3.137-2.89-4.778Zm-21.348-3.301c-13.649-13.97-28.611-26.731-45.219-36.888-16.608-10.157-34.897-17.669-53.967-20.82 2.728 5.21 38.348 19.546 47.358 25.095 28.51 17.541 26.533 15.474 50.124 39.39 1.146 1.166 2.646 2.455 4.195 2.012 1.753-.502 2.169-3.028 1.221-4.621-.948-1.593-2.34-2.757-3.712-4.168Z" />
    ),
  },
};

const body_scars_DEHanxZwKd_L = {
  name: "Body scar 5 (Left)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M96.774 379.937c18.311 6.796 36.049 15.284 52.077 26.333 16.028 11.05 30.323 24.71 40.949 40.856-5.868-.377-33.439-27.101-42.169-33.08-27.611-18.925-24.919-17.958-56.357-29.799-1.531-.573-3.318-1.421-3.543-3.015-.253-1.807 1.886-3.213 3.727-2.994 1.841.22 3.46 1.018 5.316 1.699Zm30.43-15.894c19.238 3.341 38.23 8.462 55.998 16.4 17.768 7.939 34.319 18.766 47.708 32.708-5.837.706-37.828-20.558-47.491-24.845-30.603-13.578-27.774-13.115-60.843-19.025-1.617-.287-3.513-.79-4.031-2.32-.578-1.724 1.27-3.497 3.119-3.617 1.86-.13 3.596.364 5.54.699Zm11.689 18.166c18.31 6.796 36.049 15.284 52.077 26.334 16.028 11.049 30.323 24.709 40.949 40.855-5.869-.377-33.439-27.101-42.17-33.08-27.61-18.925-24.919-17.958-56.356-29.799-1.532-.573-3.319-1.421-3.543-3.015-.253-1.806 1.886-3.213 3.727-2.994 1.84.22 3.47 1.019 5.316 1.699Z" />
    ),
  },
};

const body_scars_DEHanxZwKd_R = {
  name: "Body scar 5 (Right)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M403.226 379.937c-18.311 6.796-36.049 15.284-52.077 26.333-16.028 11.05-30.323 24.71-40.949 40.856 5.868-.377 33.439-27.101 42.169-33.08 27.611-18.925 24.919-17.958 56.357-29.799 1.531-.573 3.318-1.421 3.543-3.015.253-1.807-1.886-3.213-3.727-2.994-1.841.22-3.46 1.018-5.316 1.699Zm-30.43-15.894c-19.238 3.341-38.23 8.462-55.998 16.4-17.768 7.939-34.319 18.766-47.708 32.708 5.837.706 37.828-20.558 47.491-24.845 30.603-13.578 27.774-13.115 60.843-19.025 1.617-.287 3.513-.79 4.031-2.32.578-1.724-1.27-3.497-3.119-3.617-1.86-.13-3.596.364-5.54.699Zm-11.689 18.166c-18.31 6.796-36.049 15.284-52.077 26.334-16.028 11.049-30.323 24.709-40.949 40.855 5.869-.377 33.439-27.101 42.17-33.08 27.61-18.925 24.919-17.958 56.356-29.799 1.532-.573 3.319-1.421 3.543-3.015.253-1.806-1.886-3.213-3.727-2.994-1.84.22-3.47 1.019-5.316 1.699Z" />
    ),
  },
};

const body_scars_uxep9f5DKM_L = {
  name: "Body scar 6 (Left)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M171.693 376.715c10.438 1.355 44.905 11.489 49.731 9.111-14.504-7.995-30.326-13.006-46.381-15.571-.941 2.221-2.06 4.376-3.35 6.46Zm6.057-15.083c3.928.438 8.603 1.003 14.455 1.68 9.941 1.146 45.249 11.573 50.129 9.168-16.494-9.097-34.7-14.329-52.994-16.492-3.57-.417-7.154-.726-10.728-.929-.117 2.21-.409 4.407-.862 6.573Zm-.132-18.492c1.15.126 2.345.251 3.602.39 9.838 1.052 44.711 11.195 49.568 8.723-16.247-9.009-34.225-14.098-52.326-16.079-1.079-.117-2.152-.225-3.222-.324 1.033 2.38 1.815 4.819 2.378 7.29Z" />
    ),
  },
};

const body_scars_uxep9f5DKM_R = {
  name: "Body scar 6 (Right)",
  description: "",
  tags: [""],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  useClip: false,
  shape: {
    base: (
      <path d="M328.307 376.715c-10.438 1.355-44.905 11.489-49.731 9.111 14.504-7.995 30.326-13.006 46.381-15.571.941 2.221 2.06 4.376 3.35 6.46Zm-6.057-15.083c-3.928.438-8.603 1.003-14.455 1.68-9.941 1.146-45.249 11.573-50.129 9.168 16.494-9.097 34.7-14.329 52.994-16.492 3.57-.417 7.154-.726 10.728-.929.117 2.21.409 4.407.862 6.573Zm.132-18.492c-1.15.126-2.345.251-3.602.39-9.838 1.052-44.711 11.195-49.568 8.723 16.247-9.009 34.225-14.098 52.326-16.079 1.079-.117 2.152-.225 3.222-.324-1.033 2.38-1.815 4.819-2.378 7.29Z" />
    ),
  },
};

export const BodyScarsPack1 = {
  body_scars_DUvBf5TYAi,
  body_scars_9pSQWgUdZ6,
  body_scars_dFkked88mU_L,
  body_scars_dFkked88mU_R,
  body_scars_a5vGG39JVF_L,
  body_scars_a5vGG39JVF_R,
  body_scars_DEHanxZwKd_L,
  body_scars_DEHanxZwKd_R,
  body_scars_uxep9f5DKM_L,
  body_scars_uxep9f5DKM_R,
};
