import React from "react";
import { PreviewBase } from "../Preview";
import { viewboxExtraTall, viewboxTall } from "../../Viewboxes";

const horn_centre_Zx7kTFpE7R = {
  name: "Centre Horn 1",
  description: "A thick, straight horn in the middle of the forehead",
  tags: ["thick", "straight", "centre"],
  preview: {
    viewbox: viewboxTall,
    base: PreviewBase,
  },
  shape: {
    centre: {
      base: (
        <path d="M273.14 186.39c0 14.21-10.37 25.72-23.14 25.72-12.78 0-23.14-11.51-23.14-25.72 0-.93.06-4.17.24-4.91l19.36-75.56c1.09-4.26 5.99-4.26 7.08 0l19.35 75.56c.19.74.25 3.98.25 4.91z" />
      ),
      basic: {
        dark: (
          <path d="M250.61 101.15c-.61-.03-1.22-.08-1.83-.13l-20.18 107.5c2.03 4.05 3.83 9.04 6.58 10.34.44-2.51 14.54-114.19 14.9-116.77 3.19 27.21 9.93 114.07 17.9 114.38-.27-2.17-13.57-90.12-17.37-115.32z" />
        ),
        light: (
          <path d="M268.99 150.01c-4.25-11.94-9.88-35.56-14.68-50.49-1.97-.11-3.97-.24-5.87.15-7.58 29.76-27.56 102.32-21.8 104.78.44-1.86 15.23-70.73 21.9-101.78l-6.51 108.23c4.44 3.54 8.73 8.07 13.67 8.74.09-2.06-3.18-82.46-4.32-111.47l19.49 91.97c1.07.18 2.14.39 3.21.61 4.45-16.49.28-34.79-5.09-50.74z" />
        ),
      },
    },
  },
};

const horn_centre_reLxcb2UGc = {
  name: "Centre Horn 2",
  description:
    "A slim, twisted horn that curves gently upwards from the middle of the forehead",
  tags: ["slim", "twisted", "centre", "curved"],
  preview: {
    viewbox: viewboxExtraTall,
    base: PreviewBase,
  },
  shape: {
    centre: {
      base: (
        <path d="M268.71 107.72c.21.45.37.97.45 1.57.24 1.86-.22 4.17-1.22 6.66.23.45.4.97.48 1.57.25 1.87-.25 4.18-1.32 6.67.25.45.44.97.54 1.57.3 1.86-.22 4.18-1.32 6.66.27.46.49.97.61 1.58.38 1.86-.11 4.17-1.22 6.66.33.45.58.97.74 1.57.48 1.86.05 4.18-1.02 6.66.37.46.67.98.88 1.58.62 1.86.28 4.17-.74 6.66.44.45.8.97 1.06 1.57.8 1.86.61 4.19-.32 6.67.5.46.93.97 1.25 1.57 1.17 2.16.95 4.92-.26 7.85.09.14.2.25.28.38.99 1.61 1.26 3.53.95 5.62-.04.21-.08.42-.12.64.17.62.31 1.24.43 1.86-.01.02-.01.03 0 .05.01.32.02.64.02.97 0 10.41-8.45 18.86-18.86 18.86-.16 0-.33 0-.49-.01-7.98-.2-14.73-5.36-17.28-12.52-.22-.37-.42-.74-.6-1.12.01-.11.04-.22.07-.32-.12-.13-.25-.25-.36-.38-.03-.05-.07-.1-.1-.15-.61-1.38-1.03-2.82-1.25-4.3-.06-.4-.11-.81-.13-1.21.14-1.2.53-2.45 1.15-3.72-.26-.67-.41-1.37-.47-2.1-.15-1.9.39-4 1.53-6.14-.66-2.36-.05-5.24 1.6-8.22-.44-2.36.33-5.25 2.05-8.23-.25-2.37.62-5.26 2.37-8.24-.11-2.36.84-5.25 2.58-8.23.01-2.37 1-5.26 2.68-8.24.08-2.36 1.06-5.25 2.67-8.23.12-2.37 1.06-5.26 2.54-8.24.11-2.36.97-5.25 2.3-8.23.07-2.37.8-5.26 1.93-8.24 0-2.36.56-5.25 1.46-8.23-.12-2.37.24-5.26.87-8.24-.27-2.36-.15-5.25.16-8.24-.96-5.53-.89-14.02.01-19.67.91-6.04 4.05-6.52 7.27-1.08 1.04 1.86 1.81 4.18 2.21 6.66.31.46.6.98.87 1.58.83 1.86 1.33 4.17 1.43 6.66.28.45.52.97.74 1.57.66 1.86.91 4.18.75 6.66.23.46.45.98.61 1.58.52 1.86.56 4.17.16 6.66.22.45.4.97.53 1.57.41 1.86.27 4.18-.33 6.67.21.45.38.97.48 1.57.32 1.86.04 4.17-.73 6.66.21.45.36.97.45 1.57.27 1.86-.12 4.18-1.02 6.67z" />
      ),
      basic: {
        dark: (
          <path d="M271.63 179.11c-1.11-.87-3.96 3.15-5.04 4.07-5.73 5.09-20.24 12.23-23.04.99 4.65-3.63 11.39-3.71 15.3-8.18-1.66.67-3.34 1.34-5.04 1.97.28-.18.56-.36.83-.55-2.72.75-5.49 1.33-8.28 1.73.15-.07.3-.12.46-.17-2.46.32-4.58-2.81-4.04-5.38 1.9-5.86 10.74-5.17 15.05-8.95-4.43.42-13.7 5.89-13.55-2.01 4.06-3.45 9.7-4.99 13.85-8.57-1.19-.14-2.35-.01-3.5.28.26-.2.52-.39.77-.59-2.89 1.31-5.38 1.6-8.56 2.65-1.04-.72-1.69-2.12-1.29-3.36 2.96-2.25 6.3-4.1 9.58-5.87-.66.2-1.31.41-1.97.61.01-.01.03-.01.04-.02-1.84.17-3.59 1.05-5.39 1.52-2.37-.74-1.85-3.62-.74-5.3 2.12-3.88 8.06-3.91 10.04-7.97-.83.47-1.68.9-2.54 1.3.05-.04.11-.09.15-.12-1.72.94-3.63 1.52-5.51 2.07-4.83-5.09 4.79-11.61 9.52-12.76-2.04.41-4.08.81-6.12 1.22-2.61-4.92 5.99-6.09 8.02-9.28-7.8 4.4-8.02-1.6-4.99-7.35 2.51-1.52 5.09-2.7 7.38-4.69-.65.17-1.3.33-1.95.5.42-.26.84-.52 1.27-.77-1.42.62-2.82 1.26-4.35 1.56.07-.08.13-.16.2-.23-1.24.92-3.15-.25-3.46-1.76-.45-4.36 4.22-6.8 7.05-9.3-.3.12-.6.24-.9.37.48-.53.93-1.08 1.32-1.68-1.46.96-3 1.96-4.75 2.14-2.84-1.48-2.21-5.28-.78-7.63 1.85-1.1 3.99-2.22 4.52-4.29-6.77 3.36-5.72-3.86-7.36-8.26-4.22 15.18-14.12 27.76-19.26 42.57-6.72 26.92-20.93 57.82-5.74 84.18 6 12.88 23.75 8.86 34.5 5.16 9.03-5.72 11.48-20.41 8.3-29.85z" />
        ),
        light: (
          <path d="M270.25 167.38c6.66-20.78 10.01-112.15-8.99-124.1-5.38-1.94-10.85 3.51-3.63 6.43 1.85 2.78 2.6 6.28 3.8 9.42-.73 1.25-1.56 2.37-2.67 3.39.69 1.82 2.31-.29 3.57-.49 1.1 3.19 2.3 7.11-.56 9.66-2.64 3.56 1.63 1.86 2.98.07 1.03 3.92 1.77 8.67-1.66 11.53-2.76 3.37 2.14 2.09 3.45.38 1.57 5.69-1.48 7.28-3.81 11.68 1.68-.36 3.24-1.54 4.33-3.23.05 2.19.05 4.39-.02 6.58-1.05 2.05-3.86 2.83-4.55 4.76 1.52.32 3.24-1.45 4.45-2.58-.08 2.03-.26 4.07-.46 6.09-.88.91-1.89 1.68-3 2.28.18.98 1.91-.02 2.69.47 1.27 3.76-9.29 8.99-1.01 7.69-.07 3.49-2.43 6.36-5.26 8.2 1.27.44 2.65.57 3.99.42-.03 3.35-3.04 5.47-5.43 7.52 2.7-.59 4.98 2.54 3.59 4.93-.95 1.73-3.27 2.41-4.11 4.2.39.99 1.92.42 2.92.78 3.03 1.92-.9 4.89-2.47 6.39 2.21.97 5.09 3.52 3.32 6.02-1.01 1.23-3.17 1.26-3.84 2.7 2.35 1.14 6.41 1.56 5.17 5.28-.05 2.47-5.9 3.28-3.78 5.56 1.13.22 2.2-.54 3.3-.71 2.82-.74 3.77 2.27 2.01 4.21-.77 1.05-1.76 2.15-1.66 3.45.13 1.67 4.4-2.23 5.05-3.01.69 1.26 1.44 2.49 2.28 3.66.02-3.19-.45-6.49.01-9.63z" />
        ),
      },
    },
  },
};

export const CentreHornsPack1 = {
  horn_centre_Zx7kTFpE7R,
  horn_centre_reLxcb2UGc,
};
