import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { PatternCheckeredPack1 } from "./packs/Checkered";
import { PatternWindowpanePack1 } from "./packs/Windowpane";
import { PatternSpotsPack1 } from "./packs/Spots";
import { PatternStripesPack1 } from "./packs/Stripes";

export const PatternsList = {
  "0": BlankListOption,
  ...PatternCheckeredPack1,
  ...PatternWindowpanePack1,
  ...PatternSpotsPack1,
  ...PatternStripesPack1,
};
