import React from "react";
import { viewboxCheeks, PreviewBaseCheeks } from "../Preview";

const contouring_shape_3123j = {
  name: "Contouring 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxCheeks,
    base: PreviewBaseCheeks,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="M194.508 359.211c-2.813-3.195-2.312-10.993-2.644-14.891-.973-11.415-2.076-22.869-3.31-34.259-2.093-19.318-12.577-31.683-26.192-44.88-4.825-4.677-9.737-9.332-15.023-13.498-1.437-1.133-4.193-4.061-6.276-3.793-2.772.356-3.089 6.341-3.238 8.223-.741 9.334.973 18.976 2.358 28.181 1.703 11.315 3.744 22.654 6.399 33.789 1.903 7.983 4.179 16.443 9.655 22.837 7.376 8.613 19.42 13.654 29.835 17.5" />
      ),
    },
    right: {
      base: (
        <path d="M305.492 359.211c2.813-3.195 2.312-10.993 2.644-14.891.973-11.415 2.076-22.869 3.31-34.259 2.093-19.318 12.577-31.683 26.192-44.88 4.825-4.677 9.737-9.332 15.023-13.498 1.437-1.133 4.193-4.061 6.276-3.793 2.772.356 3.089 6.341 3.238 8.223.741 9.334-.973 18.976-2.358 28.181-1.703 11.315-3.744 22.654-6.399 33.789-1.903 7.983-4.179 16.443-9.655 22.837-7.376 8.613-19.42 13.654-29.835 17.5" />
      ),
    },
  },
};

const contouring_shape_fa942 = {
  name: "Contouring 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxCheeks,
    base: PreviewBaseCheeks,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="M158.773 244.755c-.165-.336.117 1.418.202 1.622.413 1.003.882 1.981 1.347 2.96 1.474 3.105 3.009 6.18 4.557 9.249 5.104 10.125 10.293 20.205 15.584 30.234 10.376 19.67 20.927 39.274 32.667 58.173 3.06 4.927 6.219 9.792 9.594 14.51 1.524 2.131 3.118 4.205 4.714 6.282.756.986 1.5 1.982 2.224 2.992 2.204 3.075 3.995 3.822.809 6.052-.625.437-1.252.87-1.883 1.297-1.039.703-2.103 1.371-3.18 2.015-1.758 1.051-3.564 2.009-5.424 2.866-7.144 3.289-14.832 5.405-22.689 5.989-5.758.428-11.53.171-17.202-.913-25.855-4.943-40.375-25.667-46.961-49.945-3.033-11.18-5.791-23.025-5.418-34.676.63-19.641 10.316-43.95 24.697-58.012" />
      ),
    },
    right: {
      base: (
        <path d="M341.227 244.755c.165-.336-.117 1.418-.202 1.622-.413 1.003-.882 1.981-1.347 2.96-1.474 3.105-3.009 6.18-4.557 9.249-5.104 10.125-10.293 20.205-15.584 30.234-10.376 19.67-20.927 39.274-32.667 58.173-3.06 4.927-6.219 9.792-9.594 14.51-1.524 2.131-3.118 4.205-4.714 6.282-.756.986-1.5 1.982-2.224 2.992-2.204 3.075-3.995 3.822-.809 6.052.625.437 1.252.87 1.883 1.297 1.039.703 2.103 1.371 3.18 2.015 1.758 1.051 3.564 2.009 5.424 2.866 7.144 3.289 14.832 5.405 22.689 5.989 5.758.428 11.53.171 17.202-.913 25.855-4.943 40.375-25.667 46.961-49.945 3.033-11.18 5.791-23.025 5.418-34.676-.63-19.641-10.316-43.95-24.697-58.012" />
      ),
    },
  },
};

const contouring_shape_fsd84 = {
  name: "Contouring 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxCheeks,
    base: PreviewBaseCheeks,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="M140.969 276.765c20.156-10.69 47.564 1.455 61.166 27.103 13.602 25.648 8.281 55.15-11.876 65.839-20.156 10.69-47.563-1.455-61.165-27.103-13.602-25.648-8.281-55.15 11.875-65.839Z" />
      ),
    },
    right: {
      base: (
        <path d="M359.031 276.765c-20.156-10.69-47.564 1.455-61.166 27.103-13.602 25.648-8.281 55.15 11.876 65.839 20.156 10.69 47.563-1.455 61.165-27.103 13.602-25.648 8.281-55.15-11.875-65.839Z" />
      ),
    },
  },
};

const contouring_MKR2erwBUR = {
  name: "Contouring MKR2erwBUR",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxCheeks,
    base: PreviewBaseCheeks,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="M178.722 262.701c6.48-3.741 18.36 4.689 26.514 18.812 8.155 14.124 9.514 28.627 3.035 32.368-6.479 3.741-18.36-4.688-26.514-18.812-8.155-14.123-9.514-28.627-3.035-32.368Z" />
      ),
    },
    right: {
      base: (
        <path d="M321.278 262.701c-6.48-3.741-18.36 4.689-26.514 18.812-8.155 14.124-9.514 28.627-3.035 32.368 6.479 3.741 18.36-4.688 26.514-18.812 8.155-14.123 9.514-28.627 3.035-32.368Z" />
      ),
    },
  },
  gradient: {
    left: "rotate(114.39 10.088 196.807) scale(26.9786)",
    right: "scale(26.9786 -26.9786) rotate(-65.61 -2.05 -13.796)",
  },
};

export const ContouringCheeksPack1 = {
  contouring_shape_3123j,
  contouring_shape_fa942,
  contouring_shape_fsd84,
  contouring_MKR2erwBUR,
};
