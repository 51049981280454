import React, { useState } from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    navKeys: state.menu.navKeys,
  };
};

export const Accordion = (props) => {
  const { navKeys, id, title, expanded, children, classes = null } = props;
  const [isAccordionOpen, toggleAccordion] = useState(expanded);

  const onKeyPress = (event, id) => {
    const pressedKey = event.which || event.keyCode;
    if (navKeys.includes(pressedKey)) {
      toggleAccordion(!isAccordionOpen);
    }
  };

  // TODO: Change header to be block colour, decent icons for collapse, remove border - maybe use shadow if not block colour? Auto-collapse other open navigation accordions

  return (
    <>
      <div
        id={`${id}-header`}
        className={`accordion-header ${classes ? classes : ""}`}
        aria-controls={`${id}-body`}
        onClick={() => toggleAccordion(!isAccordionOpen)}
        onKeyPress={(event) => onKeyPress(event, id)}
        role="button"
        tabIndex={isAccordionOpen ? -1 : 0}
      >
        {title}
        <span className="accordion-button">{isAccordionOpen ? `-` : `+`}</span>
      </div>
      <div
        id={`${id}-body`}
        aria-labelledby={`${id}-header`}
        className="accordion-content"
        aria-expanded={isAccordionOpen}
        {...{ hidden: !isAccordionOpen, "aria-hidden": !isAccordionOpen }}
      >
        {children}
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Accordion);
