import React from "react";
import Eyebrows from "character-creator/components/head/face/facialHair/eyebrows/CharacterEyebrows";
import CharacterHead from "character-creator/components/head/CharacterHead";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Eyebrows
        preview={true}
        previewId={previewId}
        previewTransform={false}
        eyebrows={component}
      />
    </>
  );
};

export const viewbox = "170 170 160 160";
