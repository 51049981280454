import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { HeadShapeList } from "../head/shape/HeadShapeList";

function mapStateToProps(state, ownProps) {
  // const colour = ownProps.colour;

  const headShadowObject = {
    id: "headShadow",
    shape: {
      base: (
        <g transform="translate(0 15)" opacity="0.75" stroke="none">
          {HeadShapeList[state.config.base.head.shape].shape.base}
        </g>
      ),
    },
  };
  const headShadowColour = {
    base: ownProps.colour.dark,
  };

  return {
    HeadShadow: { shape: headShadowObject, colour: headShadowColour },
  };
}
const CharacterHeadShadow = (props) => {
  const { HeadShadow, previewId, alternateId, alternateMask } = props;

  let componentId = "headShadow";
  let maskId = previewId ? `body~${previewId}_mask` : "body_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  return (
    <g
      id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
      style={{
        mask: `url(#${maskId})`,
      }}
    >
      <Component
        id={previewId ? `${componentId}~${previewId}` : componentId}
        component={HeadShadow.shape}
        colour={HeadShadow.colour}
        strokeOverride={false}
      />
    </g>
  );
};

export default connect(mapStateToProps)(CharacterHeadShadow);
