import React from "react";

import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterTail from "./CharacterTail";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterTail preview={true} previewId={previewId} tail={component} />
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewboxLeft = "0 170 260 260";
export const viewboxRight = "240 170 260 260";

/**
const body1 = {
  name: "Body 1",
  description: "A standard body",
  tags: ["medium", "humanoid"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: <path d="M411.87,443.59V500H88.13V443.59a55.82,55.82,0,0,1,55.81-55.82h48.24c6.31-11.09,11.57-22.4,11.57-34.79V259h92.49V353c0,12.39,5.26,23.7,11.57,34.79h48.25A55.82,55.82,0,0,1,411.87,443.59Z" />
  }
}
**/
