import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Beard from "./Beard";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} />
      <Beard beard={component} preview={true} previewId={previewId} />
    </>
  );
};
// 170 180 160 160
export const viewbox = "150 200 200 200";
