import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const base_body_shape_gj245 = {
  name: "Body 3A",
  description: "A mid-weight body with bumpy skin",
  tags: ["medium", "humanoid", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M58.32 473.14c5.51 5.11 2.61 9.84-.3 15.48-2.4 6.02 1.54 6.92.88 11.38h382.16c-.26-.88-.35-1.77-.08-2.65 5.16-6.39-2.75-12.17-2.52-18.79.98-4.74 6.07-5.95 3.62-11.39-1.89-15.23 7.34-11.35-8.13-26.1-10.49-10.8 3.65-15.51-14.97-20.66-3.61-2.06-5.14-1.11-8.73-1.82-4.13-.81-4.24-4.38-7.54-6.11-2.8-1.47-6.33.88-9.34-.11-3.04-1-4.17-4.9-6.99-6.42-3.18-1.71-7.06.2-10.66 0-5.5-.31-9.44-5.34-14.49-7.53-11.73-3.97-24.42 2-31.91-9.86-3.51-2.4-7.27-.23-11.03-.48-5.93-.61-13.33-3.99-14.28-10.51.3-4.46-.82-8.29-3.71-11.76-4.78-9.42 5.39-15.1.39-24.57 2.78-8.84 3.57-17.94 1.53-27.32-.56-3.78-.1-7.09-4.32-7.91-4.19-.82-8.38-1.63-12.57-2.45v-4.31h-80.71v6.26c-5.4.33-6.78 3.17-6.91 8.42-1.14 7.65-2.24 15.68.44 22.93 1.49 2.83 1.17 5.53-.09 8.34-1.88 7.55 6.2 12.58 1.57 20.61-8 10.41 1.97 18.64-17.99 22.27-6.37-.55-10.78-2-14.73 4.51-6.78 7.11-19.19 1.93-28.21 5.83-5.05 2.18-8.99 7.22-14.49 7.53-13.31-1.66-8.69-.52-17.65 6.43-3 .99-6.54-1.36-9.34.11-3.3 1.73-3.41 5.3-7.54 6.11-3.58.71-5.12-.24-8.73 1.82-6.2 2.56-12.19 2.9-10.37 11.09.16 10.38-15.95 14.6-13.55 25.36 3.84 8.05-1.66 11.06 1.29 16.27z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

const base_body_shape_942n5 = {
  name: "Body 3B",
  description: "A heavier-weight body with bumpy skin",
  tags: ["heavier", "humanoid", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M57.5 500h384.89c.12-.46.21-1 .25-1.66.3-4.93-2.31-9.88-2.58-14.82-.24-4.51.35-14.09-1.1-18.37-1.57-4.64-4.55-8.93-4.75-13.83-.15-3.47 1.06-7.3-.82-10.22-1.76-2.74-5.61-3.52-7.42-6.23-2.34-3.51-.32-8.76-2.85-12.14-2.11-2.82-6.26-2.91-9.34-4.63-3.93-2.19-5.75-6.78-8.72-10.15-1.74-1.97-3.99-3.58-6.55-4.15-.06-.01-.11-.02-.17-.03-3.49-1.43-7.18-2.49-10.1-4.84-3.19-2.56-5.34-6.54-9.14-8.06-4.46-1.79-9.75.42-14.24-1.28-4.58-1.74-6.91-6.96-11.16-9.38-6.4-3.64-15 .08-21.6-3.19-2.69-1.33-4.72-3.68-7.06-5.55-2.91-2.32-6.55-3.94-10.23-3.81-.06-.03-.13-.07-.19-.1-2.44-1.32-4.86-3-6.01-5.52-1.03-2.26-.89-4.87-1.55-7.27-1.67-6.09-8.33-10.47-8.2-16.79.1-5.08 4.73-9.47 4.02-14.5-.45-3.19-3-5.84-3.21-9.05-.26-4.11 3.37-7.63 3.43-11.75.04-3.26-2.34-5.96-5.37-7.28v-.87h-92v.02c-4.49.23-8.96 3.66-8.91 8.12.06 4.12 3.69 7.64 3.43 11.75-.21 3.21-2.76 5.86-3.21 9.05-.71 5.03 3.92 9.43 4.02 14.5.12 6.32-6.53 10.7-8.2 16.79-.66 2.4-.52 5.01-1.55 7.27-1.15 2.52-3.57 4.21-6.01 5.52-.06.03-.13.07-.19.1-3.68-.13-7.33 1.49-10.23 3.81-2.35 1.87-4.37 4.22-7.06 5.55-6.6 3.26-15.19-.45-21.6 3.19-4.26 2.42-6.59 7.64-11.16 9.38-4.49 1.71-9.78-.51-14.24 1.28-3.8 1.52-5.94 5.5-9.14 8.06-2.93 2.35-6.61 3.41-10.1 4.84-.06.01-.11.02-.17.03-2.56.58-4.81 2.18-6.55 4.15-2.98 3.37-4.79 7.96-8.72 10.15-3.08 1.71-7.23 1.8-9.34 4.63-2.52 3.38-.51 8.63-2.85 12.14-1.81 2.71-5.66 3.49-7.42 6.23-1.88 2.92-.67 6.75-.82 10.22-.21 4.9-3.18 9.19-4.75 13.83-1.45 4.28-.86 13.86-1.1 18.37-.26 4.93-2.87 9.88-2.58 14.82.01.67.1 1.21.22 1.67z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

const base_body_shape_0424n = {
  name: "Body 3C",
  description: "A lighter-weight body with bumpy skin",
  tags: ["lighter", "humanoid", "bumpy"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M62.01 493.51c-.16 2.26-1.02 4.37-1.84 6.49h379.66c-.82-2.13-1.68-4.24-1.84-6.49-.41-5.82 3.91-11.2 3.47-17.02-.42-5.62-5.13-10.01-6.6-15.45-1.57-5.8.68-12.3-1.68-17.83-2-4.69-6.79-7.48-10.05-11.39-3.87-4.64-5.69-11-10.52-14.63-1.78-1.34-3.9-2.17-6.1-2.5-1.37-.71-2.94-1.12-4.46-1.17-3.9-.14-7.67 1.32-11.54 1.89-3.66.54-8.73-1.23-11.3-3.89-4.54-4.7-9.82-4.31-12.08-4.24-4.03.11-5.5-5.29-8.63-7.85-5.15-4.21-13.13.36-19.45-1.73-4.67-1.55-8.16-6.67-13.07-6.3-3.6.27-6.91 3.58-10.35 2.47-2.02-.65-3.25-2.61-4.71-4.15-3.47-3.66-4.85-4.37-9.73-5.68-.33-.09-6.21 1.07-7.2-5.47-.02-.14.67-4.95-2.81-12.02-.8-1.63-.78-3.54.06-5.16.54-1.05 1-2.12 1.26-3.23 1.04-4.56-1.58-9.16-1.62-13.84-.05-6.76 5.18-13.91.81-19.63-2.51-3.28-7.01-4.09-10.77-2.45h-61.84c-3.76-1.64-8.26-.83-10.77 2.45-4.37 5.71.86 12.87.81 19.63-.03 4.68-2.66 9.28-1.62 13.84.25 1.11.72 2.18 1.26 3.23.83 1.61.86 3.53.06 5.16-3.47 7.07-2.79 11.88-2.81 12.02-.99 6.55-6.87 5.39-7.2 5.47-4.87 1.31-6.25 2.02-9.73 5.68-1.46 1.54-2.69 3.5-4.71 4.15-3.44 1.11-6.75-2.2-10.35-2.47-4.91-.36-8.4 4.76-13.07 6.3-6.32 2.09-14.29-2.48-19.45 1.73-3.13 2.55-4.59 7.96-8.63 7.85-2.26-.06-7.55-.46-12.08 4.24-2.57 2.66-7.64 4.43-11.3 3.89-3.86-.57-7.63-2.03-11.54-1.89-1.52.06-3.09.47-4.46 1.17-2.2.32-4.32 1.16-6.1 2.5-4.83 3.63-6.65 9.99-10.52 14.63-3.26 3.92-8.06 6.7-10.05 11.39-2.36 5.53-.11 12.03-1.68 17.83-1.47 5.44-6.18 9.83-6.6 15.45-.44 5.82 3.89 11.2 3.47 17.02z" />
    ),
    basic: {
      dark: (
        <path d="M110 477.81s-7.52 55.14-3.4 86.48.39-61.8 7.88-100.7M390 477.81s7.52 55.14 3.4 86.48-.39-61.8-7.88-100.7" />
      ),
    },
  },
};

export const BodyShapePack3 = {
  base_body_shape_gj245,
  base_body_shape_942n5,
  base_body_shape_0424n,
};
