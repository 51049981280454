import CharacterHead from "character-creator/components/head/CharacterHead";
import React from "react";
import Eyes from "../../CharacterEyes";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Eyes preview={true} previewId={previewId} irisInner={component} />
    </>
  );
};

export const viewbox = "170 205 80 80";
