import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { updateMenu } from "character-creator/utilities/menuSlice";
import SubSection from "./SubSection";
import ControlGroup from "./controls/ControlGroup";
import { updateConfig } from "character-creator/utilities/characterSlice";
import { get } from "lodash";
import { checkDependencies, SymmetryControl } from "../menu-helper";

const mapDispatchToProps = {
  updateMenu,
};

const mapStateToProps = (state, ownProps) => {
  // Is there a symmetry config item? If so, user can toggle
  const { section } = ownProps;
  const symmetryOption = section.symmetryAttribute && true;
  // If config option, get value, otherwise true
  const symmetry = symmetryOption
    ? get(state.config, section.symmetryAttribute)
    : true;
  // Switch control list based on chosen symmetry option
  const hasControls =
    section.controls.symmetrical || section.controls.asymmetrical;
  const controlGroups = symmetry
    ? section.controls.symmetrical
    : section.controls.asymmetrical;

  // Is there a dependency?
  const dependenciesMet = section.dependencies
    ? checkDependencies(section.dependencies, state.config)
    : true;

  const dependencyMessage = !dependenciesMet && section.dependencyMessage;

  return {
    symmetryOption,
    symmetry,
    controlGroups: hasControls && controlGroups,
    dependenciesMet,
    dependencyMessage,
  };
};

const Section = (props) => {
  const {
    section,
    symmetryOption,
    symmetry,
    controlGroups,
    dependenciesMet,
    dependencyMessage,
  } = props;

  const dispatch = useDispatch();

  const updateSymmetry = (symmetryAttribute, updateValue = true) => {
    dispatch(
      updateConfig({ attribute: symmetryAttribute, value: updateValue })
    );
  };

  // const [isAccordionOpen, toggleAccordion] = useState(
  // props.firstSection ? props.firstSection : false
  // );

  const BuildControlContainers =
    controlGroups &&
    controlGroups.map((control, index) => {
      return (
        <div key={index}>
          <ControlGroup sectionLevel={false} group={control} />
        </div>
      );
    });

  // originally in showHeader: subSections.length > 1 &&
  const BuildSubSections = (props) => {
    const { subSections } = props;
    return subSections.map((subSection, index) => {
      return (
        <Fragment key={index}>
          <SubSection
            subSection={subSection}
            showHeader={section.name !== subSection.name}
          />
        </Fragment>
      );
    });
  };

  const sectionDescription = section.description && (
    <p className="menu-description">{section.description}</p>
  );

  const sectionActions = (
    <div className="actions">
      {symmetryOption && (
        <SymmetryControl
          controlId={section.id}
          symmetryValue={symmetry}
          symmetryAttribute={section.symmetryAttribute}
          updateSymmetry={updateSymmetry}
        />
      )}
    </div>
  );

  const sectionContent = (
    <>
      {section.subSections && (
        <BuildSubSections subSections={section.subSections} />
      )}
      {controlGroups && BuildControlContainers}
    </>
  );

  //   const classNamesContent = "subpage-section " + (isSubPageOpen? "selected":"hidden");

  return (
    <section className="subpage-section">
      <div
        className="header"
        // aria-controls={"sectionContent~" + section.id}
        // onClick={() => toggleAccordion(!isAccordionOpen)}
        // role="button"
        {...{ disabled: !dependenciesMet }}
      >
        <h2>{section.name}</h2>
        {!dependenciesMet && (
          <span className="dependency-message">{dependencyMessage}</span>
        )}
        {dependenciesMet && sectionActions}
        {sectionDescription}
        {/* <div className="accordion-button">{isAccordionOpen ? `-` : `+`}</div> */}
      </div>
      <div
        id={"sectionContent~" + section.id}
        // className="accordion-content"
        // aria-expanded={isAccordionOpen}
        // {...{ hidden: isAccordionOpen }}
      >
        {dependenciesMet && sectionContent}
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
