import React from "react";
import { PreviewBase, viewbox } from "../../Preview";

const shapeEar1 = {
  left: {
    base: <circle cx="164.021" cy="274.008" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886 271.644c-1.235 1.234-1.229 3.245.012 4.486 1.242 1.242 3.252 1.248 4.487.013-.895-.155-2.023-.681-2.921-1.579-.897-.897-1.424-2.025-1.578-2.92Z" />
      ),
      light: (
        <path d="M163.478 271.45c.74-.741 2.036-.647 2.892.209.855.856.949 2.152.209 2.892-.14-.584-.53-1.334-1.148-1.953-.619-.619-1.369-1.008-1.953-1.148Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="274.008" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844 271.644c-1.234 1.234-1.229 3.245.013 4.486 1.241 1.242 3.252 1.248 4.486.013-.895-.155-2.023-.681-2.92-1.579-.898-.897-1.424-2.025-1.579-2.92Z" />
      ),
      light: (
        <path d="M335.436 271.45c.74-.741 2.036-.647 2.892.209.856.856.95 2.152.209 2.892-.139-.584-.529-1.334-1.148-1.953-.618-.619-1.369-1.008-1.953-1.148Z" />
      ),
    },
  },
};

const shapeEar2 = {
  left: {
    base: <circle cx="164.021" cy="274.574" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886 272.21c-1.235 1.234-1.229 3.245.012 4.486 1.242 1.242 3.252 1.247 4.487.013-.895-.155-2.023-.681-2.921-1.579-.897-.897-1.424-2.025-1.578-2.92Z" />
      ),
      light: (
        <path d="M163.478 272.016c.74-.741 2.036-.647 2.892.209.855.855.949 2.151.209 2.892-.14-.584-.53-1.335-1.148-1.953-.619-.619-1.369-1.009-1.953-1.148Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="274.574" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844 272.21c-1.234 1.234-1.229 3.245.013 4.486 1.241 1.242 3.252 1.247 4.486.013-.895-.155-2.023-.681-2.92-1.579-.898-.897-1.424-2.025-1.579-2.92Z" />
      ),
      light: (
        <path d="M335.436 272.016c.74-.741 2.036-.647 2.892.209.856.855.95 2.151.209 2.892-.139-.584-.529-1.335-1.148-1.953-.618-.619-1.369-1.009-1.953-1.148Z" />
      ),
    },
  },
};

const shapeEar3 = {
  left: {
    base: <circle cx="164.021" cy="277.139" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886 274.775c-1.235 1.235-1.229 3.245.012 4.487 1.242 1.241 3.252 1.247 4.487.012-.895-.155-2.023-.681-2.921-1.578-.897-.898-1.424-2.026-1.578-2.921Z" />
      ),
      light: (
        <path d="M163.478 274.581c.74-.74 2.036-.647 2.892.209.855.856.949 2.152.209 2.892-.14-.584-.53-1.334-1.148-1.953-.619-.618-1.369-1.008-1.953-1.148Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="277.139" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844 274.775c-1.234 1.235-1.229 3.245.013 4.487 1.241 1.241 3.252 1.247 4.486.012-.895-.155-2.023-.681-2.92-1.578-.898-.898-1.424-2.026-1.579-2.921Z" />
      ),
      light: (
        <path d="M335.436 274.581c.74-.74 2.036-.647 2.892.209.856.856.95 2.152.209 2.892-.139-.584-.529-1.334-1.148-1.953-.618-.618-1.369-1.008-1.953-1.148Z" />
      ),
    },
  },
};

const shapeEar4 = {
  left: {
    base: <circle cx="164.021" cy="275.917" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,273.553C160.651,274.788 160.657,276.798 161.898,278.039C163.14,279.281 165.15,279.287 166.385,278.052C165.49,277.897 164.362,277.371 163.464,276.473C162.567,275.576 162.04,274.448 161.886,273.553Z" />
      ),
      light: (
        <path d="M163.478,273.359C164.218,272.618 165.514,272.712 166.37,273.568C167.225,274.424 167.319,275.72 166.579,276.46C166.439,275.876 166.049,275.126 165.431,274.507C164.812,273.888 164.062,273.499 163.478,273.359Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="275.917" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,273.553C332.61,274.788 332.615,276.798 333.857,278.039C335.098,279.281 337.109,279.287 338.343,278.052C337.448,277.897 336.32,277.371 335.423,276.473C334.525,275.576 333.999,274.448 333.844,273.553Z" />
      ),
      light: (
        <path d="M335.436,273.359C336.176,272.618 337.472,272.712 338.328,273.568C339.184,274.424 339.278,275.72 338.537,276.46C338.398,275.876 338.008,275.126 337.389,274.507C336.771,273.888 336.02,273.499 335.436,273.359Z" />
      ),
    },
  },
};

const shapeEar5 = {
  left: {
    base: <circle cx="164.021" cy="282.248" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,279.884C160.651,281.118 160.657,283.129 161.898,284.37C163.14,285.612 165.15,285.617 166.385,284.383C165.49,284.228 164.362,283.702 163.464,282.804C162.567,281.907 162.04,280.779 161.886,279.884Z" />
      ),
      light: (
        <path d="M163.478,279.69C164.218,278.949 165.514,279.043 166.37,279.899C167.225,280.755 167.319,282.051 166.579,282.791C166.439,282.207 166.049,281.456 165.431,280.838C164.812,280.219 164.062,279.83 163.478,279.69Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="282.248" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,279.884C332.61,281.118 332.615,283.129 333.857,284.37C335.098,285.612 337.109,285.617 338.343,284.383C337.448,284.228 336.32,283.702 335.423,282.804C334.525,281.907 333.999,280.779 333.844,279.884Z" />
      ),
      light: (
        <path d="M335.436,279.69C336.176,278.949 337.472,279.043 338.328,279.899C339.184,280.755 339.278,282.051 338.537,282.791C338.398,282.207 338.008,281.456 337.389,280.838C336.771,280.219 336.02,279.83 335.436,279.69Z" />
      ),
    },
  },
};

const shapeEar6 = {
  left: {
    base: <circle cx="164.021" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,270.528C160.651,271.762 160.657,273.773 161.898,275.014C163.14,276.256 165.15,276.261 166.385,275.027C165.49,274.872 164.362,274.346 163.464,273.448C162.567,272.551 162.04,271.423 161.886,270.528Z" />
      ),
      light: (
        <path d="M163.478,270.334C164.218,269.593 165.514,269.687 166.37,270.543C167.225,271.399 167.319,272.694 166.579,273.435C166.439,272.851 166.049,272.1 165.431,271.482C164.812,270.863 164.062,270.473 163.478,270.334Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,270.528C332.61,271.762 332.615,273.773 333.857,275.014C335.098,276.256 337.109,276.261 338.343,275.027C337.448,274.872 336.32,274.346 335.423,273.448C334.525,272.551 333.999,271.423 333.844,270.528Z" />
      ),
      light: (
        <path d="M335.436,270.334C336.176,269.593 337.472,269.687 338.328,270.543C339.184,271.399 339.278,272.694 338.537,273.435C338.398,272.851 338.008,272.1 337.389,271.482C336.771,270.863 336.02,270.473 335.436,270.334Z" />
      ),
    },
  },
};

const shapeEar7 = {
  left: {
    base: <circle cx="164.021" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,270.528C160.651,271.762 160.657,273.773 161.898,275.014C163.14,276.256 165.15,276.261 166.385,275.027C165.49,274.872 164.362,274.346 163.464,273.448C162.567,272.551 162.04,271.423 161.886,270.528Z" />
      ),
      light: (
        <path d="M163.478,270.334C164.218,269.593 165.514,269.687 166.37,270.543C167.225,271.399 167.319,272.694 166.579,273.435C166.439,272.851 166.049,272.1 165.431,271.482C164.812,270.863 164.062,270.473 163.478,270.334Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="272.892" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,270.528C332.61,271.762 332.615,273.773 333.857,275.014C335.098,276.256 337.109,276.261 338.343,275.027C337.448,274.872 336.32,274.346 335.423,273.448C334.525,272.551 333.999,271.423 333.844,270.528Z" />
      ),
      light: (
        <path d="M335.436,270.334C336.176,269.593 337.472,269.687 338.328,270.543C339.184,271.399 339.278,272.694 338.537,273.435C338.398,272.851 338.008,272.1 337.389,271.482C336.771,270.863 336.02,270.473 335.436,270.334Z" />
      ),
    },
  },
};

const shapeEar8 = {
  left: {
    base: <circle cx="147.412" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M145.277,288.384C144.043,289.619 144.048,291.629 145.29,292.871C146.531,294.112 148.542,294.118 149.776,292.883C148.881,292.729 147.753,292.202 146.856,291.305C145.958,290.407 145.432,289.279 145.277,288.384Z" />
      ),
      light: (
        <path d="M146.869,288.19C147.609,287.45 148.905,287.543 149.761,288.399C150.617,289.255 150.711,290.551 149.97,291.291C149.83,290.707 149.441,289.957 148.822,289.338C148.204,288.72 147.453,288.33 146.869,288.19Z" />
      ),
    },
  },
  right: {
    base: <circle cx="352.629" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M350.494,288.384C349.259,289.619 349.265,291.629 350.506,292.871C351.748,294.112 353.758,294.118 354.993,292.883C354.098,292.729 352.97,292.202 352.072,291.305C351.175,290.407 350.648,289.279 350.494,288.384Z" />
      ),
      light: (
        <path d="M352.086,288.19C352.826,287.45 354.122,287.543 354.978,288.399C355.833,289.255 355.927,290.551 355.187,291.291C355.047,290.707 354.657,289.957 354.039,289.338C353.42,288.72 352.67,288.33 352.086,288.19Z" />
      ),
    },
  },
};

const shapeEar9 = {
  left: {
    base: <circle cx="167.433" cy="201.782" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.298,199.418C164.063,200.653 164.069,202.663 165.311,203.905C166.552,205.146 168.562,205.152 169.797,203.917C168.902,203.763 167.774,203.236 166.877,202.339C165.979,201.442 165.453,200.313 165.298,199.418Z" />
      ),
      light: (
        <path d="M166.89,199.224C167.63,198.484 168.926,198.578 169.782,199.433C170.638,200.289 170.732,201.585 169.991,202.325C169.851,201.742 169.462,200.991 168.843,200.372C168.224,199.754 167.474,199.364 166.89,199.224Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="201.782" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.432,199.418C329.197,200.653 329.203,202.663 330.444,203.905C331.686,205.146 333.696,205.152 334.931,203.917C334.036,203.763 332.908,203.236 332.01,202.339C331.113,201.442 330.586,200.313 330.432,199.418Z" />
      ),
      light: (
        <path d="M332.024,199.224C332.764,198.484 334.06,198.578 334.916,199.433C335.772,200.289 335.865,201.585 335.125,202.325C334.985,201.742 334.595,200.991 333.977,200.372C333.358,199.754 332.608,199.364 332.024,199.224Z" />
      ),
    },
  },
};

const shapeEar10 = {
  left: {
    base: <circle cx="167.433" cy="203.292" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.298,200.928C164.063,202.162 164.069,204.173 165.311,205.414C166.552,206.656 168.562,206.661 169.797,205.427C168.902,205.272 167.774,204.746 166.877,203.848C165.979,202.951 165.453,201.823 165.298,200.928Z" />
      ),
      light: (
        <path d="M166.89,200.734C167.63,199.993 168.926,200.087 169.782,200.943C170.638,201.798 170.732,203.094 169.991,203.835C169.851,203.251 169.462,202.5 168.843,201.882C168.224,201.263 167.474,200.873 166.89,200.734Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="203.292" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.432,200.928C329.197,202.162 329.203,204.173 330.444,205.414C331.686,206.656 333.696,206.661 334.931,205.427C334.036,205.272 332.908,204.746 332.01,203.848C331.113,202.951 330.586,201.823 330.432,200.928Z" />
      ),
      light: (
        <path d="M332.024,200.734C332.764,199.993 334.06,200.087 334.916,200.943C335.772,201.798 335.865,203.094 335.125,203.835C334.985,203.251 334.595,202.5 333.977,201.882C333.358,201.263 332.608,200.873 332.024,200.734Z" />
      ),
    },
  },
};

const shapeEar11 = {
  left: {
    base: <circle cx="164.021" cy="271.219" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,268.855C160.651,270.09 160.657,272.1 161.898,273.341C163.14,274.583 165.15,274.589 166.385,273.354C165.49,273.199 164.362,272.673 163.464,271.775C162.567,270.878 162.04,269.75 161.886,268.855Z" />
      ),
      light: (
        <path d="M163.478,268.661C164.218,267.921 165.514,268.014 166.37,268.87C167.225,269.726 167.319,271.022 166.579,271.762C166.439,271.178 166.049,270.428 165.431,269.809C164.812,269.19 164.062,268.801 163.478,268.661Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="271.219" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,268.855C332.61,270.09 332.615,272.1 333.857,273.341C335.098,274.583 337.109,274.589 338.343,273.354C337.448,273.199 336.32,272.673 335.423,271.775C334.525,270.878 333.999,269.75 333.844,268.855Z" />
      ),
      light: (
        <path d="M335.436,268.661C336.176,267.921 337.472,268.014 338.328,268.87C339.184,269.726 339.278,271.022 338.537,271.762C338.398,271.178 338.008,270.428 337.389,269.809C336.771,269.19 336.02,268.801 335.436,268.661Z" />
      ),
    },
  },
};

const shapeEar12 = {
  left: {
    base: <circle cx="164.021" cy="268.779" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,266.415C160.651,267.65 160.657,269.661 161.898,270.902C163.14,272.144 165.15,272.149 166.385,270.914C165.49,270.76 164.362,270.233 163.464,269.336C162.567,268.439 162.04,267.311 161.886,266.415Z" />
      ),
      light: (
        <path d="M163.478,266.222C164.218,265.481 165.514,265.575 166.37,266.431C167.225,267.286 167.319,268.582 166.579,269.323C166.439,268.739 166.049,267.988 165.431,267.37C164.812,266.751 164.062,266.361 163.478,266.222Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="268.779" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,266.415C332.61,267.65 332.615,269.661 333.857,270.902C335.098,272.144 337.109,272.149 338.343,270.914C337.448,270.76 336.32,270.233 335.423,269.336C334.525,268.439 333.999,267.311 333.844,266.415Z" />
      ),
      light: (
        <path d="M335.436,266.222C336.176,265.481 337.472,265.575 338.328,266.431C339.184,267.286 339.278,268.582 338.537,269.323C338.398,268.739 338.008,267.988 337.389,267.37C336.771,266.751 336.02,266.361 335.436,266.222Z" />
      ),
    },
  },
};

const shapeEar13 = {
  left: {
    base: <circle cx="160.845" cy="282.739" r="3.562" />,
    basic: {
      dark: (
        <path d="M158.71,280.375C157.475,281.609 157.481,283.62 158.722,284.861C159.964,286.103 161.974,286.108 163.209,284.874C162.314,284.719 161.186,284.193 160.288,283.295C159.391,282.398 158.864,281.27 158.71,280.375Z" />
      ),
      light: (
        <path d="M160.302,280.181C161.042,279.44 162.338,279.534 163.194,280.39C164.05,281.246 164.143,282.541 163.403,283.282C163.263,282.698 162.873,281.947 162.255,281.329C161.636,280.71 160.886,280.32 160.302,280.181Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.155" cy="282.739" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.02,280.375C335.785,281.609 335.791,283.62 337.032,284.861C338.274,286.103 340.284,286.108 341.519,284.874C340.624,284.719 339.496,284.193 338.598,283.295C337.701,282.398 337.175,281.27 337.02,280.375Z" />
      ),
      light: (
        <path d="M338.612,280.181C339.352,279.44 340.648,279.534 341.504,280.39C342.36,281.246 342.453,282.541 341.713,283.282C341.573,282.698 341.183,281.947 340.565,281.329C339.946,280.71 339.196,280.32 338.612,280.181Z" />
      ),
    },
  },
};

const shapeEar14 = {
  left: {
    base: <circle cx="164.021" cy="282.305" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,279.941C160.651,281.176 160.657,283.186 161.898,284.428C163.14,285.67 165.15,285.675 166.385,284.44C165.49,284.286 164.362,283.759 163.464,282.862C162.567,281.965 162.04,280.837 161.886,279.941Z" />
      ),
      light: (
        <path d="M163.478,279.747C164.218,279.007 165.514,279.101 166.37,279.957C167.225,280.812 167.319,282.108 166.579,282.849C166.439,282.265 166.049,281.514 165.431,280.896C164.812,280.277 164.062,279.887 163.478,279.747Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="282.305" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,279.941C332.61,281.176 332.615,283.186 333.857,284.428C335.098,285.67 337.109,285.675 338.343,284.44C337.448,284.286 336.32,283.759 335.423,282.862C334.525,281.965 333.999,280.837 333.844,279.941Z" />
      ),
      light: (
        <path d="M335.436,279.747C336.176,279.007 337.472,279.101 338.328,279.957C339.184,280.812 339.278,282.108 338.537,282.849C338.398,282.265 338.008,281.514 337.389,280.896C336.771,280.277 336.02,279.887 335.436,279.747Z" />
      ),
    },
  },
};

const shapeEar15 = {
  left: {
    base: <circle cx="157.914" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M155.779,279.246C154.544,280.481 154.55,282.491 155.791,283.732C157.033,284.974 159.043,284.98 160.278,283.745C159.383,283.59 158.255,283.064 157.357,282.166C156.46,281.269 155.933,280.141 155.779,279.246Z" />
      ),
      light: (
        <path d="M157.371,279.052C158.111,278.312 159.407,278.405 160.263,279.261C161.119,280.117 161.212,281.413 160.472,282.153C160.332,281.569 159.942,280.819 159.324,280.2C158.705,279.582 157.955,279.192 157.371,279.052Z" />
      ),
    },
  },
  right: {
    base: <circle cx="342.086" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M339.951,279.246C338.716,280.481 338.722,282.491 339.963,283.732C341.205,284.974 343.215,284.98 344.45,283.745C343.555,283.59 342.427,283.064 341.529,282.166C340.632,281.269 340.106,280.141 339.951,279.246Z" />
      ),
      light: (
        <path d="M341.543,279.052C342.283,278.312 343.579,278.405 344.435,279.261C345.291,280.117 345.384,281.413 344.644,282.153C344.504,281.569 344.114,280.819 343.496,280.2C342.877,279.582 342.127,279.192 341.543,279.052Z" />
      ),
    },
  },
};

const shapeEar16 = {
  left: {
    base: <circle cx="164.021" cy="273.568" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,271.204C160.651,272.439 160.657,274.449 161.898,275.691C163.14,276.933 165.15,276.938 166.385,275.703C165.49,275.549 164.362,275.022 163.464,274.125C162.567,273.228 162.04,272.1 161.886,271.204Z" />
      ),
      light: (
        <path d="M163.478,271.011C164.218,270.27 165.514,270.364 166.37,271.22C167.225,272.075 167.319,273.371 166.579,274.112C166.439,273.528 166.049,272.777 165.431,272.159C164.812,271.54 164.062,271.15 163.478,271.011Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="273.568" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,271.204C332.61,272.439 332.615,274.449 333.857,275.691C335.098,276.933 337.109,276.938 338.343,275.703C337.448,275.549 336.32,275.022 335.423,274.125C334.525,273.228 333.999,272.1 333.844,271.204Z" />
      ),
      light: (
        <path d="M335.436,271.011C336.176,270.27 337.472,270.364 338.328,271.22C339.184,272.075 339.278,273.371 338.537,274.112C338.398,273.528 338.008,272.777 337.389,272.159C336.771,271.54 336.02,271.15 335.436,271.011Z" />
      ),
    },
  },
};

const shapeEar17 = {
  left: {
    base: <circle cx="167.196" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.061,279.246C163.826,280.481 163.832,282.491 165.073,283.732C166.315,284.974 168.325,284.98 169.56,283.745C168.665,283.59 167.537,283.064 166.639,282.166C165.742,281.269 165.215,280.141 165.061,279.246Z" />
      ),
      light: (
        <path d="M166.653,279.052C167.393,278.312 168.689,278.405 169.545,279.261C170.401,280.117 170.494,281.413 169.754,282.153C169.614,281.569 169.224,280.819 168.606,280.2C167.987,279.582 167.237,279.192 166.653,279.052Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.804" cy="281.61" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.669,279.246C329.434,280.481 329.44,282.491 330.682,283.732C331.923,284.974 333.933,284.98 335.168,283.745C334.273,283.59 333.145,283.064 332.248,282.166C331.35,281.269 330.824,280.141 330.669,279.246Z" />
      ),
      light: (
        <path d="M332.261,279.052C333.001,278.312 334.297,278.405 335.153,279.261C336.009,280.117 336.103,281.413 335.362,282.153C335.222,281.569 334.833,280.819 334.214,280.2C333.595,279.582 332.845,279.192 332.261,279.052Z" />
      ),
    },
  },
};

const shapeEar18 = {
  left: {
    base: <circle cx="164.021" cy="282.176" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,279.812C160.651,281.047 160.657,283.057 161.898,284.299C163.14,285.54 165.15,285.546 166.385,284.311C165.49,284.157 164.362,283.63 163.464,282.733C162.567,281.835 162.04,280.707 161.886,279.812Z" />
      ),
      light: (
        <path d="M163.478,279.618C164.218,278.878 165.514,278.971 166.37,279.827C167.225,280.683 167.319,281.979 166.579,282.719C166.439,282.135 166.049,281.385 165.431,280.766C164.812,280.148 164.062,279.758 163.478,279.618Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="282.176" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,279.812C332.61,281.047 332.615,283.057 333.857,284.299C335.098,285.54 337.109,285.546 338.343,284.311C337.448,284.157 336.32,283.63 335.423,282.733C334.525,281.835 333.999,280.707 333.844,279.812Z" />
      ),
      light: (
        <path d="M335.436,279.618C336.176,278.878 337.472,278.971 338.328,279.827C339.184,280.683 339.278,281.979 338.537,282.719C338.398,282.135 338.008,281.385 337.389,280.766C336.771,280.148 336.02,279.758 335.436,279.618Z" />
      ),
    },
  },
};

const shapeEar19 = {
  left: {
    base: <circle cx="167.433" cy="200.184" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.298,197.82C164.063,199.055 164.069,201.065 165.311,202.307C166.552,203.548 168.562,203.554 169.797,202.319C168.902,202.165 167.774,201.638 166.877,200.741C165.979,199.843 165.453,198.715 165.298,197.82Z" />
      ),
      light: (
        <path d="M166.89,197.626C167.63,196.886 168.926,196.979 169.782,197.835C170.638,198.691 170.732,199.987 169.991,200.727C169.851,200.143 169.462,199.393 168.843,198.774C168.224,198.156 167.474,197.766 166.89,197.626Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="200.184" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.432,197.82C329.197,199.055 329.203,201.065 330.444,202.307C331.686,203.548 333.696,203.554 334.931,202.319C334.036,202.165 332.908,201.638 332.01,200.741C331.113,199.843 330.586,198.715 330.432,197.82Z" />
      ),
      light: (
        <path d="M332.024,197.626C332.764,196.886 334.06,196.979 334.916,197.835C335.772,198.691 335.865,199.987 335.125,200.727C334.985,200.143 334.595,199.393 333.977,198.774C333.358,198.156 332.608,197.766 332.024,197.626Z" />
      ),
    },
  },
};

const shapeEar20 = {
  left: {
    base: <circle cx="147.412" cy="324.473" r="3.562" />,
    basic: {
      dark: (
        <path d="M145.277,322.109C144.043,323.343 144.048,325.354 145.29,326.595C146.531,327.837 148.542,327.842 149.776,326.608C148.881,326.453 147.753,325.927 146.856,325.029C145.958,324.132 145.432,323.004 145.277,322.109Z" />
      ),
      light: (
        <path d="M146.869,321.915C147.609,321.174 148.905,321.268 149.761,322.124C150.617,322.98 150.711,324.276 149.97,325.016C149.83,324.432 149.441,323.681 148.822,323.063C148.204,322.444 147.453,322.054 146.869,321.915Z" />
      ),
    },
  },
  right: {
    base: <circle cx="352.629" cy="324.473" r="3.562" />,
    basic: {
      dark: (
        <path d="M350.494,322.109C349.259,323.343 349.265,325.354 350.506,326.595C351.748,327.837 353.758,327.842 354.993,326.608C354.098,326.453 352.97,325.927 352.072,325.029C351.175,324.132 350.648,323.004 350.494,322.109Z" />
      ),
      light: (
        <path d="M352.086,321.915C352.826,321.174 354.122,321.268 354.978,322.124C355.833,322.98 355.927,324.276 355.187,325.016C355.047,324.432 354.657,323.681 354.039,323.063C353.42,322.444 352.67,322.054 352.086,321.915Z" />
      ),
    },
  },
};

const shapeEar21 = {
  left: {
    base: <circle cx="154.883" cy="297.407" r="3.562" />,
    basic: {
      dark: (
        <path d="M152.748,295.043C151.514,296.277 151.519,298.288 152.761,299.529C154.002,300.771 156.013,300.776 157.247,299.542C156.352,299.387 155.224,298.861 154.327,297.963C153.429,297.066 152.903,295.938 152.748,295.043Z" />
      ),
      light: (
        <path d="M154.34,294.849C155.081,294.108 156.376,294.202 157.232,295.058C158.088,295.913 158.182,297.209 157.441,297.95C157.302,297.366 156.912,296.615 156.293,295.997C155.675,295.378 154.924,294.988 154.34,294.849Z" />
      ),
    },
  },
  right: {
    base: <circle cx="345.117" cy="297.407" r="3.562" />,
    basic: {
      dark: (
        <path d="M342.982,295.043C341.747,296.277 341.753,298.288 342.994,299.529C344.236,300.771 346.246,300.776 347.481,299.542C346.585,299.387 345.457,298.861 344.56,297.963C343.663,297.066 343.136,295.938 342.982,295.043Z" />
      ),
      light: (
        <path d="M344.574,294.849C345.314,294.108 346.61,294.202 347.466,295.058C348.321,295.913 348.415,297.209 347.675,297.95C347.535,297.366 347.145,296.615 346.527,295.997C345.908,295.378 345.157,294.988 344.574,294.849Z" />
      ),
    },
  },
};

const shapeEar22 = {
  left: {
    base: <circle cx="150.372" cy="307.979" r="3.562" />,
    basic: {
      dark: (
        <path d="M148.237,305.615C147.002,306.85 147.008,308.86 148.249,310.102C149.491,311.343 151.501,311.349 152.736,310.114C151.841,309.959 150.712,309.433 149.815,308.536C148.918,307.638 148.391,306.51 148.237,305.615Z" />
      ),
      light: (
        <path d="M149.829,305.421C150.569,304.681 151.865,304.774 152.721,305.63C153.576,306.486 153.67,307.782 152.93,308.522C152.79,307.938 152.4,307.188 151.782,306.569C151.163,305.951 150.412,305.561 149.829,305.421Z" />
      ),
    },
  },
  right: {
    base: <circle cx="350.564" cy="307.979" r="3.562" />,
    basic: {
      dark: (
        <path d="M348.429,305.615C347.195,306.85 347.2,308.86 348.442,310.102C349.683,311.343 351.694,311.349 352.928,310.114C352.033,309.959 350.905,309.433 350.008,308.536C349.11,307.638 348.584,306.51 348.429,305.615Z" />
      ),
      light: (
        <path d="M350.021,305.421C350.762,304.681 352.057,304.774 352.913,305.63C353.769,306.486 353.863,307.782 353.122,308.522C352.983,307.938 352.593,307.188 351.974,306.569C351.356,305.951 350.605,305.561 350.021,305.421Z" />
      ),
    },
  },
};

const shapeEar23 = {
  left: {
    base: <circle cx="157.263" cy="278.514" r="3.562" />,
    basic: {
      dark: (
        <path d="M155.128,276.15C153.893,277.384 153.899,279.395 155.141,280.636C156.382,281.878 158.392,281.883 159.627,280.649C158.732,280.494 157.604,279.968 156.706,279.07C155.809,278.173 155.283,277.045 155.128,276.15Z" />
      ),
      light: (
        <path d="M156.72,275.956C157.46,275.215 158.756,275.309 159.612,276.165C160.468,277.02 160.561,278.316 159.821,279.057C159.681,278.473 159.291,277.722 158.673,277.104C158.054,276.485 157.304,276.095 156.72,275.956Z" />
      ),
    },
  },
  right: {
    base: <circle cx="342.737" cy="278.514" r="3.562" />,
    basic: {
      dark: (
        <path d="M340.602,276.15C339.367,277.384 339.373,279.395 340.614,280.636C341.856,281.878 343.866,281.883 345.101,280.649C344.206,280.494 343.078,279.968 342.18,279.07C341.283,278.173 340.756,277.045 340.602,276.15Z" />
      ),
      light: (
        <path d="M342.194,275.956C342.934,275.215 344.23,275.309 345.086,276.165C345.942,277.02 346.035,278.316 345.295,279.057C345.155,278.473 344.765,277.722 344.147,277.104C343.528,276.485 342.778,276.095 342.194,275.956Z" />
      ),
    },
  },
};

const shapeEar24 = {
  left: {
    base: <circle cx="164.021" cy="279.325" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,276.961C160.651,278.196 160.657,280.206 161.898,281.448C163.14,282.69 165.15,282.695 166.385,281.46C165.49,281.306 164.362,280.779 163.464,279.882C162.567,278.985 162.04,277.857 161.886,276.961Z" />
      ),
      light: (
        <path d="M163.478,276.768C164.218,276.027 165.514,276.121 166.37,276.977C167.225,277.832 167.319,279.128 166.579,279.869C166.439,279.285 166.049,278.534 165.431,277.916C164.812,277.297 164.062,276.907 163.478,276.768Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="279.325" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,276.961C332.61,278.196 332.615,280.206 333.857,281.448C335.098,282.69 337.109,282.695 338.343,281.46C337.448,281.306 336.32,280.779 335.423,279.882C334.525,278.985 333.999,277.857 333.844,276.961Z" />
      ),
      light: (
        <path d="M335.436,276.768C336.176,276.027 337.472,276.121 338.328,276.977C339.184,277.832 339.278,279.128 338.537,279.869C338.398,279.285 338.008,278.534 337.389,277.916C336.771,277.297 336.02,276.907 335.436,276.768Z" />
      ),
    },
  },
};

const shapeEar25 = {
  left: {
    base: <circle cx="167.433" cy="198.58" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.298,196.216C164.063,197.451 164.069,199.461 165.311,200.703C166.552,201.944 168.562,201.95 169.797,200.715C168.902,200.561 167.774,200.034 166.877,199.137C165.979,198.239 165.453,197.111 165.298,196.216Z" />
      ),
      light: (
        <path d="M166.89,196.022C167.63,195.282 168.926,195.375 169.782,196.231C170.638,197.087 170.732,198.383 169.991,199.123C169.851,198.539 169.462,197.789 168.843,197.17C168.224,196.552 167.474,196.162 166.89,196.022Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="198.58" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.432,196.216C329.197,197.451 329.203,199.461 330.444,200.703C331.686,201.944 333.696,201.95 334.931,200.715C334.036,200.561 332.908,200.034 332.01,199.137C331.113,198.239 330.586,197.111 330.432,196.216Z" />
      ),
      light: (
        <path d="M332.024,196.022C332.764,195.282 334.06,195.375 334.916,196.231C335.772,197.087 335.865,198.383 335.125,199.123C334.985,198.539 334.595,197.789 333.977,197.17C333.358,196.552 332.608,196.162 332.024,196.022Z" />
      ),
    },
  },
};

const shapeEar26 = {
  left: {
    base: <circle cx="160.908" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M158.773,288.384C157.538,289.619 157.544,291.629 158.785,292.871C160.027,294.112 162.037,294.118 163.272,292.883C162.377,292.729 161.249,292.202 160.351,291.305C159.454,290.407 158.927,289.279 158.773,288.384Z" />
      ),
      light: (
        <path d="M160.365,288.19C161.105,287.45 162.401,287.543 163.257,288.399C164.113,289.255 164.206,290.551 163.466,291.291C163.326,290.707 162.936,289.957 162.318,289.338C161.699,288.72 160.949,288.33 160.365,288.19Z" />
      ),
    },
  },
  right: {
    base: <circle id="base146" cx="339.096" cy="290.748" r="3.562" />,
    basic: {
      dark: (
        <path d="M336.961,288.384C335.726,289.619 335.731,291.629 336.973,292.871C338.215,294.112 340.225,294.118 341.46,292.883C340.564,292.729 339.436,292.202 338.539,291.305C337.642,290.407 337.115,289.279 336.961,288.384Z" />
      ),
      light: (
        <path d="M338.552,288.19C339.293,287.45 340.589,287.543 341.444,288.399C342.3,289.255 342.394,290.551 341.654,291.291C341.514,290.707 341.124,289.957 340.505,289.338C339.887,288.72 339.136,288.33 338.552,288.19Z" />
      ),
    },
  },
};

const shapeEar27 = {
  left: {
    base: <circle cx="150.057" cy="285.24" r="3.562" />,
    basic: {
      dark: (
        <path d="M147.922,282.876C146.687,284.11 146.693,286.121 147.934,287.362C149.176,288.604 151.186,288.61 152.421,287.375C151.526,287.22 150.398,286.694 149.5,285.796C148.603,284.899 148.076,283.771 147.922,282.876Z" />
      ),
      light: (
        <path d="M149.514,282.682C150.254,281.941 151.55,282.035 152.406,282.891C153.262,283.747 153.355,285.043 152.615,285.783C152.475,285.199 152.085,284.449 151.467,283.83C150.848,283.211 150.098,282.822 149.514,282.682Z" />
      ),
    },
  },
  right: {
    base: <circle cx="349.943" cy="285.24" r="3.562" />,
    basic: {
      dark: (
        <path d="M347.808,282.876C346.573,284.11 346.579,286.121 347.821,287.362C349.062,288.604 351.072,288.61 352.307,287.375C351.412,287.22 350.284,286.694 349.387,285.796C348.489,284.899 347.963,283.771 347.808,282.876Z" />
      ),
      light: (
        <path d="M349.4,282.682C350.14,281.941 351.436,282.035 352.292,282.891C353.148,283.747 353.242,285.043 352.501,285.783C352.361,285.199 351.972,284.449 351.353,283.83C350.734,283.211 349.984,282.822 349.4,282.682Z" />
      ),
    },
  },
};

const shapeEar28 = {
  left: {
    base: <circle cx="167.433" cy="194.79" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.298,192.426C164.063,193.661 164.069,195.671 165.311,196.913C166.552,198.154 168.562,198.16 169.797,196.925C168.902,196.771 167.774,196.244 166.877,195.347C165.979,194.449 165.453,193.321 165.298,192.426Z" />
      ),
      light: (
        <path d="M166.89,192.232C167.63,191.492 168.926,191.585 169.782,192.441C170.638,193.297 170.732,194.593 169.991,195.333C169.851,194.749 169.462,193.999 168.843,193.38C168.224,192.762 167.474,192.372 166.89,192.232Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.567" cy="194.79" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.432,192.426C329.197,193.661 329.203,195.671 330.444,196.913C331.686,198.154 333.696,198.16 334.931,196.925C334.036,196.771 332.908,196.244 332.01,195.347C331.113,194.449 330.586,193.321 330.432,192.426Z" />
      ),
      light: (
        <path d="M332.024,192.232C332.764,191.492 334.06,191.585 334.916,192.441C335.772,193.297 335.865,194.593 335.125,195.333C334.985,194.749 334.595,193.999 333.977,193.38C333.358,192.762 332.608,192.372 332.024,192.232Z" />
      ),
    },
  },
};

const shapeEar29 = {
  left: {
    base: <circle cx="151.528" cy="239.752" r="3.562" />,
    basic: {
      dark: (
        <path d="M149.393,237.388C148.158,238.622 148.164,240.633 149.406,241.874C150.647,243.116 152.657,243.121 153.892,241.887C152.997,241.732 151.869,241.206 150.972,240.308C150.074,239.411 149.548,238.283 149.393,237.388Z" />
      ),
      light: (
        <path d="M150.985,237.194C151.725,236.453 153.021,236.547 153.877,237.403C154.733,238.258 154.826,239.554 154.086,240.295C153.946,239.711 153.556,238.96 152.938,238.342C152.319,237.723 151.569,237.333 150.985,237.194Z" />
      ),
    },
  },
  right: {
    base: <circle cx="348.472" cy="239.752" r="3.562" />,
    basic: {
      dark: (
        <path d="M346.337,237.388C345.102,238.622 345.108,240.633 346.349,241.874C347.591,243.116 349.601,243.121 350.836,241.887C349.941,241.732 348.813,241.206 347.915,240.308C347.018,239.411 346.491,238.283 346.337,237.388Z" />
      ),
      light: (
        <path d="M347.929,237.194C348.669,236.453 349.965,236.547 350.821,237.403C351.677,238.258 351.77,239.554 351.03,240.295C350.89,239.711 350.5,238.96 349.882,238.342C349.263,237.723 348.513,237.333 347.929,237.194Z" />
      ),
    },
  },
};

const shapeEar30 = {
  left: {
    base: <circle cx="173.722" cy="188.823" r="3.562" />,
    basic: {
      dark: (
        <path d="M171.587,186.459C170.352,187.694 170.358,189.704 171.599,190.946C172.841,192.187 174.851,192.193 176.086,190.958C175.191,190.803 174.063,190.277 173.165,189.38C172.268,188.482 171.741,187.354 171.587,186.459Z" />
      ),
      light: (
        <path d="M173.179,186.265C173.919,185.525 175.215,185.618 176.071,186.474C176.927,187.33 177.02,188.626 176.28,189.366C176.14,188.782 175.75,188.032 175.132,187.413C174.513,186.795 173.763,186.405 173.179,186.265Z" />
      ),
    },
  },
  right: {
    base: <circle cx="326.278" cy="188.823" r="3.562" />,
    basic: {
      dark: (
        <path d="M324.143,186.459C322.908,187.694 322.914,189.704 324.155,190.946C325.397,192.187 327.407,192.193 328.642,190.958C327.747,190.803 326.619,190.277 325.721,189.38C324.824,188.482 324.298,187.354 324.143,186.459Z" />
      ),
      light: (
        <path d="M325.735,186.265C326.475,185.525 327.771,185.618 328.627,186.474C329.483,187.33 329.576,188.626 328.836,189.366C328.696,188.782 328.306,188.032 327.688,187.413C327.069,186.795 326.319,186.405 325.735,186.265Z" />
      ),
    },
  },
};

const shapeEar31 = {
  left: {
    base: <circle cx="160.845" cy="286.007" r="3.562" />,
    basic: {
      dark: (
        <path d="M158.71,283.643C157.475,284.878 157.481,286.888 158.722,288.13C159.964,289.371 161.974,289.377 163.209,288.142C162.314,287.988 161.186,287.461 160.288,286.564C159.391,285.666 158.864,284.538 158.71,283.643Z" />
      ),
      light: (
        <path d="M160.302,283.449C161.042,282.709 162.338,282.802 163.194,283.658C164.05,284.514 164.143,285.81 163.403,286.55C163.263,285.966 162.873,285.216 162.255,284.597C161.636,283.979 160.886,283.589 160.302,283.449Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.155" cy="286.007" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.02,283.643C335.785,284.878 335.791,286.888 337.032,288.13C338.274,289.371 340.284,289.377 341.519,288.142C340.624,287.988 339.496,287.461 338.598,286.564C337.701,285.666 337.175,284.538 337.02,283.643Z" />
      ),
      light: (
        <path d="M338.612,283.449C339.352,282.709 340.648,282.802 341.504,283.658C342.36,284.514 342.453,285.81 341.713,286.55C341.573,285.966 341.183,285.216 340.565,284.597C339.946,283.979 339.196,283.589 338.612,283.449Z" />
      ),
    },
  },
};

const shapeEar32 = {
  left: {
    base: <circle cx="149.512" cy="275.16" r="3.562" />,
    basic: {
      dark: (
        <path d="M147.377,272.796C146.142,274.031 146.148,276.041 147.389,277.283C148.631,278.524 150.641,278.53 151.876,277.295C150.981,277.141 149.853,276.614 148.955,275.717C148.058,274.819 147.531,273.691 147.377,272.796Z" />
      ),
      light: (
        <path d="M148.969,272.602C149.709,271.862 151.005,271.955 151.861,272.811C152.716,273.667 152.81,274.963 152.07,275.703C151.93,275.119 151.54,274.369 150.922,273.75C150.303,273.132 149.553,272.742 148.969,272.602Z" />
      ),
    },
  },
  right: {
    base: <circle cx="350.488" cy="275.16" r="3.562" />,
    basic: {
      dark: (
        <path d="M348.353,272.796C347.119,274.031 347.124,276.041 348.366,277.283C349.607,278.524 351.618,278.53 352.852,277.295C351.957,277.141 350.829,276.614 349.932,275.717C349.034,274.819 348.508,273.691 348.353,272.796Z" />
      ),
      light: (
        <path d="M349.945,272.602C350.685,271.862 351.981,271.955 352.837,272.811C353.693,273.667 353.787,274.963 353.046,275.703C352.907,275.119 352.517,274.369 351.898,273.75C351.28,273.132 350.529,272.742 349.945,272.602Z" />
      ),
    },
  },
};

const shapeEar33 = {
  left: {
    base: <circle cx="158.027" cy="270.854" r="3.562" />,
    basic: {
      dark: (
        <path d="M155.892,268.49C154.657,269.725 154.663,271.735 155.905,272.977C157.146,274.218 159.157,274.224 160.391,272.989C159.496,272.835 158.368,272.308 157.471,271.411C156.573,270.513 156.047,269.385 155.892,268.49Z" />
      ),
      light: (
        <path d="M157.484,268.296C158.224,267.556 159.52,267.649 160.376,268.505C161.232,269.361 161.326,270.657 160.585,271.397C160.445,270.813 160.056,270.063 159.437,269.444C158.818,268.826 158.068,268.436 157.484,268.296Z" />
      ),
    },
  },
  right: {
    base: <circle cx="341.973" cy="270.854" r="3.562" />,
    basic: {
      dark: (
        <path d="M339.838,268.49C338.603,269.725 338.609,271.735 339.85,272.977C341.092,274.218 343.102,274.224 344.337,272.989C343.442,272.835 342.314,272.308 341.416,271.411C340.519,270.513 339.992,269.385 339.838,268.49Z" />
      ),
      light: (
        <path d="M341.43,268.296C342.17,267.556 343.466,267.649 344.322,268.505C345.178,269.361 345.271,270.657 344.531,271.397C344.391,270.813 344.001,270.063 343.383,269.444C342.764,268.826 342.014,268.436 341.43,268.296Z" />
      ),
    },
  },
};

const shapeEar34 = {
  left: {
    base: <circle cx="160.845" cy="269.491" r="3.562" />,
    basic: {
      dark: (
        <path d="M158.71,267.127C157.475,268.362 157.481,270.372 158.722,271.614C159.964,272.855 161.974,272.861 163.209,271.626C162.314,271.472 161.186,270.945 160.288,270.048C159.391,269.15 158.864,268.022 158.71,267.127Z" />
      ),
      light: (
        <path d="M160.302,266.933C161.042,266.193 162.338,266.286 163.194,267.142C164.05,267.998 164.143,269.294 163.403,270.034C163.263,269.45 162.873,268.7 162.255,268.081C161.636,267.463 160.886,267.073 160.302,266.933Z" />
      ),
    },
  },
  right: {
    base: <circle cx="339.155" cy="269.491" r="3.562" />,
    basic: {
      dark: (
        <path d="M337.02,267.127C335.785,268.362 335.791,270.372 337.032,271.614C338.274,272.855 340.284,272.861 341.519,271.626C340.624,271.472 339.496,270.945 338.598,270.048C337.701,269.15 337.175,268.022 337.02,267.127Z" />
      ),
      light: (
        <path d="M338.612,266.933C339.352,266.193 340.648,266.286 341.504,267.142C342.36,267.998 342.453,269.294 341.713,270.034C341.573,269.45 341.183,268.7 340.565,268.081C339.946,267.463 339.196,267.073 338.612,266.933Z" />
      ),
    },
  },
};

const shapeEar35 = {
  left: {
    base: <circle cx="164.021" cy="283.77" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,281.406C160.651,282.641 160.657,284.651 161.898,285.893C163.14,287.134 165.15,287.14 166.385,285.905C165.49,285.751 164.362,285.224 163.464,284.327C162.567,283.43 162.04,282.302 161.886,281.406Z" />
      ),
      light: (
        <path d="M163.478,281.212C164.218,280.472 165.514,280.566 166.37,281.421C167.225,282.277 167.319,283.573 166.579,284.314C166.439,283.73 166.049,282.979 165.431,282.361C164.812,281.742 164.062,281.352 163.478,281.212Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="283.77" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,281.406C332.61,282.641 332.615,284.651 333.857,285.893C335.098,287.134 337.109,287.14 338.343,285.905C337.448,285.751 336.32,285.224 335.423,284.327C334.525,283.43 333.999,282.302 333.844,281.406Z" />
      ),
      light: (
        <path d="M335.436,281.212C336.176,280.472 337.472,280.566 338.328,281.421C339.184,282.277 339.278,283.573 338.537,284.314C338.398,283.73 338.008,282.979 337.389,282.361C336.771,281.742 336.02,281.352 335.436,281.212Z" />
      ),
    },
  },
};

const shapeEar36 = {
  left: {
    base: <circle cx="167.196" cy="289.941" r="3.562" />,
    basic: {
      dark: (
        <path d="M165.061,287.577C163.826,288.812 163.832,290.822 165.073,292.064C166.315,293.305 168.325,293.311 169.56,292.076C168.665,291.921 167.537,291.395 166.639,290.498C165.742,289.6 165.215,288.472 165.061,287.577Z" />
      ),
      light: (
        <path d="M166.653,287.383C167.393,286.643 168.689,286.736 169.545,287.592C170.401,288.448 170.494,289.744 169.754,290.484C169.614,289.9 169.224,289.15 168.606,288.531C167.987,287.913 167.237,287.523 166.653,287.383Z" />
      ),
    },
  },
  right: {
    base: <circle cx="332.804" cy="289.941" r="3.562" />,
    basic: {
      dark: (
        <path d="M330.669,287.577C329.434,288.812 329.44,290.822 330.682,292.064C331.923,293.305 333.933,293.311 335.168,292.076C334.273,291.921 333.145,291.395 332.248,290.498C331.35,289.6 330.824,288.472 330.669,287.577Z" />
      ),
      light: (
        <path d="M332.261,287.383C333.001,286.643 334.297,286.736 335.153,287.592C336.009,288.448 336.103,289.744 335.362,290.484C335.222,289.9 334.833,289.15 334.214,288.531C333.595,287.913 332.845,287.523 332.261,287.383Z" />
      ),
    },
  },
};

const shapeEar37 = {
  left: {
    base: <circle cx="162.243" cy="289.032" r="3.562" />,
    basic: {
      dark: (
        <path d="M160.108,286.668C158.873,287.903 158.879,289.913 160.12,291.154C161.362,292.396 163.372,292.402 164.607,291.167C163.712,291.012 162.584,290.486 161.686,289.588C160.789,288.691 160.262,287.563 160.108,286.668Z" />
      ),
      light: (
        <path d="M161.7,286.474C162.44,285.733 163.736,285.827 164.592,286.683C165.448,287.539 165.541,288.835 164.801,289.575C164.661,288.991 164.271,288.241 163.653,287.622C163.034,287.003 162.284,286.614 161.7,286.474Z" />
      ),
    },
  },
  right: {
    base: <circle cx="337.757" cy="289.032" r="3.562" />,
    basic: {
      dark: (
        <path d="M335.622,286.668C334.387,287.903 334.393,289.913 335.634,291.154C336.876,292.396 338.886,292.402 340.121,291.167C339.226,291.012 338.098,290.486 337.2,289.588C336.303,288.691 335.776,287.563 335.622,286.668Z" />
      ),
      light: (
        <path d="M337.214,286.474C337.954,285.733 339.25,285.827 340.106,286.683C340.962,287.539 341.055,288.835 340.315,289.575C340.175,288.991 339.785,288.241 339.167,287.622C338.548,287.003 337.798,286.614 337.214,286.474Z" />
      ),
    },
  },
};

const shapeEar38 = {
  left: {
    base: <circle cx="164.021" cy="279.942" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,277.578C160.651,278.812 160.657,280.823 161.898,282.064C163.14,283.306 165.15,283.312 166.385,282.077C165.49,281.922 164.362,281.396 163.464,280.498C162.567,279.601 162.04,278.473 161.886,277.578Z" />
      ),
      light: (
        <path d="M163.478,277.384C164.218,276.643 165.514,276.737 166.37,277.593C167.225,278.449 167.319,279.745 166.579,280.485C166.439,279.901 166.049,279.151 165.431,278.532C164.812,277.913 164.062,277.524 163.478,277.384Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="279.942" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,277.578C332.61,278.812 332.615,280.823 333.857,282.064C335.098,283.306 337.109,283.312 338.343,282.077C337.448,281.922 336.32,281.396 335.423,280.498C334.525,279.601 333.999,278.473 333.844,277.578Z" />
      ),
      light: (
        <path d="M335.436,277.384C336.176,276.643 337.472,276.737 338.328,277.593C339.184,278.449 339.278,279.745 338.537,280.485C338.398,279.901 338.008,279.151 337.389,278.532C336.771,277.913 336.02,277.524 335.436,277.384Z" />
      ),
    },
  },
};

const shapeEar39 = {
  left: {
    base: <circle cx="164.021" cy="278.36" r="3.562" />,
    basic: {
      dark: (
        <path d="M161.886,275.996C160.651,277.231 160.657,279.241 161.898,280.483C163.14,281.724 165.15,281.73 166.385,280.495C165.49,280.341 164.362,279.814 163.464,278.917C162.567,278.019 162.04,276.891 161.886,275.996Z" />
      ),
      light: (
        <path d="M163.478,275.802C164.218,275.062 165.514,275.155 166.37,276.011C167.225,276.867 167.319,278.163 166.579,278.903C166.439,278.319 166.049,277.569 165.431,276.95C164.812,276.332 164.062,275.942 163.478,275.802Z" />
      ),
    },
  },
  right: {
    base: <circle cx="335.979" cy="278.36" r="3.562" />,
    basic: {
      dark: (
        <path d="M333.844,275.996C332.61,277.231 332.615,279.241 333.857,280.483C335.098,281.724 337.109,281.73 338.343,280.495C337.448,280.341 336.32,279.814 335.423,278.917C334.525,278.019 333.999,276.891 333.844,275.996Z" />
      ),
      light: (
        <path d="M335.436,275.802C336.176,275.062 337.472,275.155 338.328,276.011C339.184,276.867 339.278,278.163 338.537,278.903C338.398,278.319 338.008,277.569 337.389,276.95C336.771,276.332 336.02,275.942 335.436,275.802Z" />
      ),
    },
  },
};

export const earring1 = {
  name: "Earring 1",
  description: "A basic metal ear stud",
  tags: ["metal", "basic", "stud"],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Smooth ears
    ear_shape_TEKWA: { ...shapeEar1 },
    ear_shape_0775v: { ...shapeEar2 },
    ear_shape_HOZ9g: { ...shapeEar3 },
    ear_shape_vBJ7j: { ...shapeEar4 },
    ear_shape_FYH9c: { ...shapeEar5 },
    ear_shape_aoGZG: { ...shapeEar6 },
    ear_shape_ZgR2p: { ...shapeEar7 },
    ear_shape_vdyix: { ...shapeEar8 },
    ear_shape_TbLPe: { ...shapeEar9 },
    ear_shape_3wnpn: { ...shapeEar10 },
    ear_shape_PDULw: { ...shapeEar11 },
    ear_shape_kOBSt: { ...shapeEar12 },
    ear_shape_yAT34: { ...shapeEar13 },
    ear_shape_8wAS4: { ...shapeEar14 },
    ear_shape_gcoC9: { ...shapeEar15 },
    ear_shape_bPDFx: { ...shapeEar16 },
    ear_shape_oEAG6: { ...shapeEar17 },
    ear_shape_Dp6oZ: { ...shapeEar18 },
    ear_shape_kf82L: { ...shapeEar19 },
    ear_shape_8sDmX: { ...shapeEar20 },
    ear_shape_k9vVR: { ...shapeEar21 },
    ear_shape_aKjrI: { ...shapeEar22 },
    ear_shape_2rsVH: { ...shapeEar23 },
    ear_shape_axjXx: { ...shapeEar24 },
    ear_shape_Zxpa2: { ...shapeEar25 },
    ear_shape_jWOL0: { ...shapeEar26 },
    ear_shape_TUUUj: { ...shapeEar27 },
    ear_shape_Wk3es: { ...shapeEar28 },
    ear_shape_XkDvO: { ...shapeEar29 },
    ear_shape_xyzCw: { ...shapeEar30 },
    ear_shape_epGZS: { ...shapeEar31 },
    ear_shape_4qBKl: { ...shapeEar32 },
    ear_shape_zwyyL: { ...shapeEar33 },
    ear_shape_kDDB4: { ...shapeEar34 },
    ear_shape_AwTvI: { ...shapeEar35 },
    ear_shape_0U6iQ: { ...shapeEar36 },
    ear_shape_BTIMM: { ...shapeEar37 },
    ear_shape_cnX1o: { ...shapeEar38 },
    ear_shape_UEVQI: { ...shapeEar39 },
    // Fuzzy ears
    ear_shape_T2K38: { ...shapeEar1 },
    ear_shape_02738: { ...shapeEar2 },
    ear_shape_H2Z38: { ...shapeEar3 },
    ear_shape_v2J38: { ...shapeEar4 },
    ear_shape_F2H38: { ...shapeEar5 },
    ear_shape_a2G38: { ...shapeEar6 },
    ear_shape_Z2R38: { ...shapeEar7 },
    ear_shape_v2y38: { ...shapeEar8 },
    ear_shape_T2L38: { ...shapeEar9 },
    ear_shape_32n38: { ...shapeEar10 },
    ear_shape_P2U38: { ...shapeEar11 },
    ear_shape_k2B38: { ...shapeEar12 },
    ear_shape_y2T38: { ...shapeEar13 },
    ear_shape_82A38: { ...shapeEar14 },
    ear_shape_g2o38: { ...shapeEar15 },
    ear_shape_b2D38: { ...shapeEar16 },
    ear_shape_o2A38: { ...shapeEar17 },
    ear_shape_D2638: { ...shapeEar18 },
    ear_shape_k2838: { ...shapeEar19 },
    ear_shape_82D38: { ...shapeEar20 },
    ear_shape_k2v38: { ...shapeEar21 },
    ear_shape_a2j38: { ...shapeEar22 },
    ear_shape_22s38: { ...shapeEar23 },
    ear_shape_a34f8: { ...shapeEar24 },
    ear_shape_Z2p38: { ...shapeEar25 },
    ear_shape_j2O38: { ...shapeEar26 },
    ear_shape_T2U38: { ...shapeEar27 },
    ear_shape_W2338: { ...shapeEar28 },
    ear_shape_X2D38: { ...shapeEar29 },
    ear_shape_x2z38: { ...shapeEar30 },
    ear_shape_e2G38: { ...shapeEar31 },
    ear_shape_42B38: { ...shapeEar32 },
    ear_shape_z2y38: { ...shapeEar33 },
    ear_shape_k2D38: { ...shapeEar34 },
    ear_shape_A2T38: { ...shapeEar35 },
    ear_shape_02638: { ...shapeEar36 },
    ear_shape_B2I38: { ...shapeEar37 },
    ear_shape_c2X38: { ...shapeEar38 },
    ear_shape_U2V38: { ...shapeEar39 },
    // Bumpy
    ear_shape_T4KrT: { ...shapeEar1 },
    ear_shape_047rT: { ...shapeEar2 },
    ear_shape_H4ZrT: { ...shapeEar3 },
    ear_shape_v4JrT: { ...shapeEar4 },
    ear_shape_F4HrT: { ...shapeEar5 },
    ear_shape_a4GrT: { ...shapeEar6 },
    ear_shape_Z4RrT: { ...shapeEar7 },
    ear_shape_v4yrT: { ...shapeEar8 },
    ear_shape_T4LrT: { ...shapeEar9 },
    ear_shape_34nrT: { ...shapeEar10 },
    ear_shape_P4UrT: { ...shapeEar11 },
    ear_shape_k4BrT: { ...shapeEar12 },
    ear_shape_y4TrT: { ...shapeEar13 },
    ear_shape_84ArT: { ...shapeEar14 },
    ear_shape_g4orT: { ...shapeEar15 },
    ear_shape_b4DrT: { ...shapeEar16 },
    ear_shape_o4ArT: { ...shapeEar17 },
    ear_shape_D46rT: { ...shapeEar18 },
    ear_shape_k48rT: { ...shapeEar19 },
    ear_shape_84DrT: { ...shapeEar20 },
    ear_shape_k4vrT: { ...shapeEar21 },
    ear_shape_a4jrT: { ...shapeEar22 },
    ear_shape_24srT: { ...shapeEar23 },
    ear_shape_a442T: { ...shapeEar24 },
    ear_shape_Z4prT: { ...shapeEar25 },
    ear_shape_j4OrT: { ...shapeEar26 },
    ear_shape_T4UrT: { ...shapeEar27 },
    ear_shape_W43rT: { ...shapeEar28 },
    ear_shape_X4DrT: { ...shapeEar29 },
    ear_shape_x4zrT: { ...shapeEar30 },
    ear_shape_e4GrT: { ...shapeEar31 },
    ear_shape_44BrT: { ...shapeEar32 },
    ear_shape_z4yrT: { ...shapeEar33 },
    ear_shape_k4DrT: { ...shapeEar34 },
    ear_shape_A4TrT: { ...shapeEar35 },
    ear_shape_046rT: { ...shapeEar36 },
    ear_shape_B4IrT: { ...shapeEar37 },
    ear_shape_c4XrT: { ...shapeEar38 },
    ear_shape_U4VrT: { ...shapeEar39 },
    // Furry
    ear_shape_1TEKWA: { ...shapeEar1 },
    ear_shape_10775v: { ...shapeEar2 },
    ear_shape_1HOZ9g: { ...shapeEar3 },
    ear_shape_1vBJ7j: { ...shapeEar4 },
    ear_shape_1FYH9c: { ...shapeEar5 },
    ear_shape_1aoGZG: { ...shapeEar6 },
    ear_shape_1ZgR2p: { ...shapeEar7 },
    ear_shape_1vdyix: { ...shapeEar8 },
    ear_shape_1TbLPe: { ...shapeEar9 },
    ear_shape_13wnpn: { ...shapeEar10 },
    ear_shape_1PDULw: { ...shapeEar11 },
    ear_shape_1kOBSt: { ...shapeEar12 },
    ear_shape_1yAT34: { ...shapeEar13 },
    ear_shape_18wAS4: { ...shapeEar14 },
    ear_shape_1gcoC9: { ...shapeEar15 },
    ear_shape_1bPDFx: { ...shapeEar16 },
    ear_shape_1oEAG6: { ...shapeEar17 },
    ear_shape_1Dp6oZ: { ...shapeEar18 },
    ear_shape_1kf82L: { ...shapeEar19 },
    ear_shape_18sDmX: { ...shapeEar20 },
    ear_shape_1k9vVR: { ...shapeEar21 },
    ear_shape_1aKjrI: { ...shapeEar22 },
    ear_shape_12rsVH: { ...shapeEar23 },
    ear_shape_1axjXx: { ...shapeEar24 },
    ear_shape_1Zxpa2: { ...shapeEar25 },
    ear_shape_1jWOL0: { ...shapeEar26 },
    ear_shape_1TUUUj: { ...shapeEar27 },
    ear_shape_1Wk3es: { ...shapeEar28 },
    ear_shape_1XkDvO: { ...shapeEar29 },
    ear_shape_1xyzCw: { ...shapeEar30 },
    ear_shape_1epGZS: { ...shapeEar31 },
    ear_shape_14qBKl: { ...shapeEar32 },
    ear_shape_1zwyyL: { ...shapeEar33 },
    ear_shape_1kDDB4: { ...shapeEar34 },
    ear_shape_1AwTvI: { ...shapeEar35 },
    ear_shape_10U6iQ: { ...shapeEar36 },
    ear_shape_1BTIMM: { ...shapeEar37 },
    ear_shape_1cnX1o: { ...shapeEar38 },
    ear_shape_1UEVQI: { ...shapeEar39 },
  },
};
