import React from "react";
import { connect } from "react-redux";
import FacePaint from "character-creator/components/head/markings/facePaint/CharacterFacePaint";
import HeadScar from "./scars/HeadScar";
import Tattoo from "./tattoos/Tattoo";
import HeadMarkings from "./HeadMarkings";

function mapStateToProps(state, ownProps) {
  const headMarkingsConfig = state.config.base.head.markings;
  const faceMarkingsConfig = state.config.base.head.face.markings;
  const faceExtrasConfig = state.config.base.head.face.extras;

  return {
    markings: {
      scar: faceMarkingsConfig.scars.scar1.shape !== "0",
      facePaint: faceExtrasConfig.facePaint.shape !== "0",
      marking1: headMarkingsConfig.marking1.shape !== "0",
      tattoo: faceMarkingsConfig.tattoo.shape !== "0",
    },
    ...ownProps,
  };
}

//alternateMask={maskUrl}

const ClippedMarkings = (props) => {
  const { componentName, maskUrl, markings, maskTransform } = props;
  return (
    <g id={"g-" + componentName + "-markings"}>
      <mask id={maskUrl + "_markings"} fill="white" fillRule="evenodd">
        <use
          href={`#${maskUrl}`}
          transform-origin="50% 50%"
          {...maskTransform}
        />
      </mask>

      {markings.marking1 && (
        <HeadMarkings
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
      {markings.scar && (
        <HeadScar
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
      {markings.tattoo && (
        <Tattoo
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
      {markings.facePaint && (
        <FacePaint
          alternateId={`facePaint-${componentName}`}
          alternateMask={maskUrl + "_markings"}
        />
      )}
    </g>
  );
};

export default connect(mapStateToProps)(ClippedMarkings);
