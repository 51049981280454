import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import HeadMarkings from "./HeadMarkings";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <HeadMarkings preview={true} previewId={previewId} marking1={component} />
    </>
  );
};

export const viewbox = "150 160 200 180";
