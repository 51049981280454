import React from "react";
import { connect } from "react-redux";
import { HeadShapeList } from "character-creator/components/head/shape/HeadShapeList";
import CharacterFace from "character-creator/components/head/face/CharacterFace";
import Component from "character-creator/components/Component";
import HeadScar from "./markings/scars/HeadScar";
import Cheeks from "./markings/cheeks/Cheeks";
import Contouring from "./markings/contouring/Contouring";
import Tattoo from "./markings/tattoos/Tattoo";
import HeadMarkings from "./markings/HeadMarkings";
import { getGlobalStyle } from "../StyleOptions";
import { outlineOnly } from "character-creator/colours/colourSchemes";

function mapStateToProps(state, ownProps) {
  const useHeadOutline = getGlobalStyle(state).outline;

  const headConfig = state.config.base.head;
  const headObject =
    ownProps.preview && ownProps.head
      ? ownProps.head
      : HeadShapeList[headConfig.shape];
  const colour = headConfig.colour
    ? headConfig.colour
    : state.config.base.skintone;

  return {
    colour: colour,
    Head: headObject,
    preview: ownProps.preview,
    useHeadOutline,
  };
}

const CharacterHead = (props) => {
  const { Head, colour, preview, previewId, useHeadOutline } = props;

  return (
    <g id={previewId ? `g-head~${previewId}` : "g-head"}>
      <Component
        id={previewId ? `head~${previewId}` : "head"}
        component={Head}
        colour={colour}
      />
      {/* Base */}
      {!preview && <HeadMarkings />}

      {!preview && <Contouring />}
      {!preview && <Cheeks />}

      {!preview && <Tattoo />}

      {!preview && <HeadScar />}

      {!preview && <CharacterFace />}

      {useHeadOutline && (
        <Component
          id={previewId ? `headOutline~${previewId}` : "headOutline"}
          component={Head}
          linework={true}
          colour={{ ...outlineOnly, outline: colour.outline }}
        />
      )}
    </g>
  );
};

export default connect(mapStateToProps)(CharacterHead);
