import { earring1 } from "./Earrings/Earring1";
import { earring2 } from "./Earrings/Earring2";
import { earring3 } from "./Earrings/Earring3";
import { earring4 } from "./Earrings/Earring4";

export const EarringsPack1 = {
  earring1,
  earring2,
  earring3,
  earring4,
};
