import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { BodyCentresPack1 } from "./centres/BodyCentresPack1";
import { BodyFrecklesPack1 } from "./freckles/BodyFrecklesPack1";
import { BodyPatchesPack1 } from "./patches/BodyPatchesPack1";
import { BodyScalesPack1 } from "./Scales/BodyScalesPack1";
import { BodySpotsPack1 } from "./spots/BodySpotsPack1";
import { BodyStripesPack1 } from "./stripes/BodyStripesPack1";

export const BodyMarkingsList = {
  "0": BlankListOption,
  ...BodyCentresPack1,
  ...BodyFrecklesPack1,
  ...BodyPatchesPack1,
  ...BodySpotsPack1,
  ...BodyStripesPack1,
  ...BodyScalesPack1,
};
