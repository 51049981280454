import React from "react";
import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterHairBack from "character-creator/components/hair/back/CharacterHairBack";
import CharacterHairMid from "character-creator/components/hair/mid/CharacterHairMid";
import CharacterHairSides from "character-creator/components/hair/side/CharacterHairSide";
import CharacterHairBase from "character-creator/components/hair/base/CharacterHairBase";
import CharacterHairTop from "character-creator/components/hair/top/CharacterHairTop";
import CharacterHairFront from "character-creator/components/hair/front/CharacterHairFront";
import FrontHorns from "./head/extras/horns/front/FrontHorns";
import BackHorns from "./head/extras/horns/back/BackHorns";
import Ears from "character-creator/components/head/ears/CharacterEars";
import CharacterTail from "./body/extras/tails/CharacterTail";
import ClothingFrontLayers from "./clothing/ClothingFrontLayers";
import EyeAccessories from "./clothing/Accessories/Face/Eyes/EyeAccessories";
import ClothingBackLayers from "./clothing/ClothingBackLayers";
import NeckAccessoriesBackLayers from "./clothing/Accessories/Neck/NeckAccessoriesBackLayers";
import NeckAccessoriesFrontLayers from "./clothing/Accessories/Neck/NeckAccessoriesFrontLayers";

/**
 *
 * @param props
 * @returns A collection of the components that combine to make the CharacterBase
 */
const CharacterBase = (props) => {
  return (
    <g id="character-base">
      <CharacterTail />

      <CharacterHairBack />

      <ClothingBackLayers />
      <NeckAccessoriesBackLayers />

      <CharacterBody />

      <ClothingFrontLayers />
      <NeckAccessoriesFrontLayers />

      <CharacterHairMid />
      <BackHorns />
      <Ears />
      <CharacterHairSides />
      <CharacterHead />
      <CharacterHairBase />

      <CharacterHairTop />
      <FrontHorns />
      <CharacterHairFront />
      <EyeAccessories />
    </g>
  );
};

export default CharacterBase;
