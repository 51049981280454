// Components
// import skintones from 'character-creator/colours/skin'
import HairColours from "character-creator/colours/hair";
import { hairFrontFullList } from "character-creator/components/hair/front/full/HairFrontFullList";
import { hairFrontLeftList } from "character-creator/components/hair/front/left/HairFrontLeftList";
import { hairFrontRightList } from "character-creator/components/hair/front/right/HairFrontRightList";

const hairFrontPath = "base.hair.head.front.";

const HairFrontPage = {
  id: "hairFrontPage",
  name: "Front",
  description: "",
  controls: [
    {
      id: "hairFrontFullShape",
      name: "Full style",
      dataAttribute: [hairFrontPath + "full.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "F",
      previewViewbox: "0 0 500 500",
      options: hairFrontFullList,
      colourControls: [
        {
          id: "hairFrontFullColour",
          name: "Full colour",
          description: "",
          dataAttribute: [hairFrontPath + "full.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairFrontLeft",
      name: "Left",
      description: "",
      dataAttribute: [hairFrontPath + "left.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "L",
      previewViewbox: "0 0 100 100",
      options: hairFrontLeftList,
      colourControls: [
        {
          id: "hairFrontLeftColour",
          name: "Left colour",
          description: "",
          dataAttribute: [hairFrontPath + "left.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
    {
      id: "hairFrontRight",
      name: "Right style",
      description: "",
      dataAttribute: [hairFrontPath + "right.shape"],
      variationAttribute: "",
      previewType: "preview",
      previewSide: "R",
      previewViewbox: "0 0 100 100",
      options: hairFrontRightList,
      colourControls: [
        {
          id: "hairFrontRightColour",
          name: "Right colour",
          description: "",
          dataAttribute: [hairFrontPath + "right.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
    },
  ],
};

export default HairFrontPage;
