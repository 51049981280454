import React from "react";
import { PreviewBase, viewbox } from "../../Preview";

const shapeEar1 = {
  left: {
    base: (
      <path d="M141.164 237.072c-.713.118-1.437-.134-1.922-.669-.486-.536-.666-1.281-.479-1.979.641-2.39 1.513-5.648 2.154-8.039.187-.698.716-1.253 1.404-1.474.688-.221 1.441-.077 1.999.382 1.163.955 2.475 2.033 3.367 2.766.644.53.919 1.387.704 2.193-.348 1.297-.875 3.263-1.222 4.56-.216.806-.883 1.411-1.706 1.547-1.139.189-2.815.467-4.299.713Z" />
    ),
    basic: {
      dark: (
        <path d="m145.129 239.193.418.112 3.685-13.755-.418-.112-3.685 13.755Zm-1.538-.412 1.313.351 3.685-13.754-1.313-.352-3.685 13.755Z" />
      ),
      light: (
        <path d="m138.387 237.386 1.743.467 3.686-13.754-1.743-.467-3.686 13.754Zm2.742.735.854.229 3.685-13.755-.853-.228-3.686 13.754Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M358.836 237.072c.713.118 1.437-.134 1.922-.669.486-.536.666-1.281.479-1.979-.641-2.39-1.513-5.648-2.154-8.039-.187-.698-.716-1.253-1.404-1.474-.688-.221-1.441-.077-1.999.382-1.163.955-2.475 2.033-3.367 2.766-.644.53-.919 1.387-.704 2.193.348 1.297.875 3.263 1.222 4.56.216.806.883 1.411 1.706 1.547 1.139.189 2.815.467 4.299.713Z" />
    ),
    basic: {
      dark: (
        <path d="m354.871 239.193-.418.112-3.685-13.755.418-.112 3.685 13.755Zm1.538-.412-1.313.351-3.685-13.754 1.313-.352 3.685 13.755Z" />
      ),
      light: (
        <path d="m361.613 237.386-1.743.467-3.686-13.754 1.743-.467 3.686 13.754Zm-2.742.735-.854.229-3.685-13.755.853-.228 3.686 13.754Z" />
      ),
    },
  },
};

const shapeEar2 = {
  left: {
    base: (
      <path d="M144.725 240.939c-.713.118-1.437-.134-1.922-.669-.486-.535-.666-1.281-.479-1.979.641-2.39 1.514-5.648 2.154-8.039.187-.698.716-1.253 1.404-1.474.688-.22 1.441-.077 1.999.382 1.163.955 2.475 2.034 3.367 2.766.644.53.919 1.387.704 2.193-.348 1.297-.875 3.263-1.222 4.56-.216.806-.883 1.411-1.706 1.548-1.139.188-2.815.466-4.299.712Z" />
    ),
    basic: {
      dark: (
        <path d="m148.69 243.06.418.112 3.685-13.754-.418-.112-3.685 13.754Zm-1.538-.412 1.313.352 3.685-13.755-1.313-.351-3.685 13.754Z" />
      ),
      light: (
        <path d="m141.948 241.254 1.743.467 3.686-13.755-1.743-.467-3.686 13.755Zm2.742.734.854.229 3.685-13.754-.853-.229-3.686 13.754Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M355.275 240.939c.713.118 1.437-.134 1.922-.669.486-.535.666-1.281.479-1.979-.641-2.39-1.514-5.648-2.154-8.039-.187-.698-.716-1.253-1.404-1.474-.688-.22-1.441-.077-1.999.382-1.163.955-2.475 2.034-3.367 2.766-.644.53-.919 1.387-.704 2.193.348 1.297.875 3.263 1.222 4.56.216.806.883 1.411 1.706 1.548 1.139.188 2.815.466 4.299.712Z" />
    ),
    basic: {
      dark: (
        <path d="m351.31 243.06-.418.112-3.685-13.754.418-.112 3.685 13.754Zm1.538-.412-1.313.352-3.685-13.755 1.313-.351 3.685 13.754Z" />
      ),
      light: (
        <path d="m358.052 241.254-1.743.467-3.686-13.755 1.743-.467 3.686 13.755Zm-2.742.734-.854.229-3.685-13.754.853-.229 3.686 13.754Z" />
      ),
    },
  },
};

const shapeEar3 = {
  left: {
    base: (
      <path d="M130.833,171.175C131.506,170.433 132.51,170.084 133.499,170.249C134.487,170.413 135.324,171.068 135.722,171.988C137.082,175.139 138.935,179.433 140.295,182.583C140.692,183.503 140.595,184.562 140.037,185.394C139.479,186.226 138.536,186.718 137.534,186.699C135.449,186.661 133.093,186.617 131.494,186.587C130.337,186.566 129.3,185.87 128.842,184.808C128.104,183.099 126.985,180.507 126.247,178.798C125.789,177.736 125.994,176.504 126.771,175.648C127.847,174.463 129.43,172.719 130.833,171.175Z" />
    ),
    basic: {
      dark: (
        <path d="M124.697,172.279L124.146,172.517L131.971,190.645L132.522,190.407L124.697,172.279ZM126.724,171.404L124.993,172.151L132.819,190.279L134.55,189.532L126.724,171.404Z" />
      ),
      light: (
        <path d="M133.582,168.444L131.285,169.436L139.11,187.563L141.407,186.572L133.582,168.444ZM129.968,170.004L128.843,170.49L136.668,188.617L137.794,188.132L129.968,170.004Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M369.167,171.175C368.494,170.433 367.49,170.084 366.501,170.249C365.513,170.413 364.676,171.068 364.278,171.988C362.918,175.139 361.065,179.433 359.705,182.583C359.308,183.503 359.405,184.562 359.963,185.394C360.521,186.226 361.464,186.718 362.466,186.699C364.551,186.661 366.907,186.617 368.506,186.587C369.663,186.566 370.7,185.87 371.158,184.808C371.896,183.099 373.015,180.507 373.753,178.798C374.211,177.736 374.006,176.504 373.229,175.648C372.153,174.463 370.57,172.719 369.167,171.175Z" />
    ),
    basic: {
      dark: (
        <path d="M375.303,172.279L375.854,172.517L368.029,190.645L367.478,190.407L375.303,172.279ZM373.276,171.404L375.007,172.151L367.181,190.279L365.45,189.532L373.276,171.404Z" />
      ),
      light: (
        <path d="M366.418,168.444L368.715,169.436L360.89,187.563L358.593,186.572L366.418,168.444ZM370.032,170.004L371.157,170.49L363.332,188.617L362.206,188.132L370.032,170.004Z" />
      ),
    },
  },
};

const shapeEar4 = {
  left: {
    base: (
      <path d="M131.507,184.647C131.642,183.887 132.153,183.248 132.864,182.95C133.575,182.652 134.389,182.736 135.025,183.172C137.202,184.667 140.17,186.703 142.347,188.198C142.983,188.634 143.353,189.364 143.331,190.134C143.308,190.905 142.896,191.612 142.236,192.01C140.862,192.84 139.31,193.777 138.256,194.414C137.494,194.874 136.533,194.846 135.799,194.342C134.618,193.531 132.827,192.302 131.646,191.491C130.912,190.988 130.54,190.101 130.696,189.225C130.911,188.012 131.227,186.227 131.507,184.647Z" />
    ),
    basic: {
      dark: (
        <path d="M127.964,187.881L127.702,188.262L140.23,196.861L140.492,196.48L127.964,187.881ZM128.925,186.48L128.104,187.676L140.632,196.275L141.453,195.079L128.925,186.48Z" />
      ),
      light: (
        <path d="M132.178,181.741L131.089,183.328L143.617,191.927L144.706,190.34L132.178,181.741ZM130.464,184.238L129.93,185.016L142.458,193.615L142.992,192.837L130.464,184.238Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M368.493,184.647C368.358,183.887 367.847,183.248 367.136,182.95C366.425,182.652 365.611,182.736 364.975,183.172C362.798,184.667 359.83,186.703 357.653,188.198C357.017,188.634 356.647,189.364 356.669,190.134C356.692,190.905 357.104,191.612 357.764,192.01C359.138,192.84 360.69,193.777 361.744,194.414C362.506,194.874 363.467,194.846 364.201,194.342C365.382,193.531 367.173,192.302 368.354,191.491C369.088,190.988 369.46,190.101 369.304,189.225C369.089,188.012 368.773,186.227 368.493,184.647Z" />
    ),
    basic: {
      dark: (
        <path d="M372.036,187.881L372.298,188.262L359.77,196.861L359.508,196.48L372.036,187.881ZM371.075,186.48L371.896,187.676L359.368,196.275L358.547,195.079L371.075,186.48Z" />
      ),
      light: (
        <path d="M367.822,181.741L368.911,183.328L356.383,191.927L355.294,190.34L367.822,181.741ZM369.536,184.238L370.07,185.016L357.542,193.615L357.008,192.837L369.536,184.238Z" />
      ),
    },
  },
};

const shapeEar5 = {
  left: {
    base: (
      <path d="M133.817,236.148C133.099,236.068 132.47,235.63 132.148,234.983C131.825,234.337 131.853,233.571 132.222,232.949C133.485,230.821 135.207,227.92 136.47,225.792C136.838,225.171 137.498,224.779 138.22,224.753C138.942,224.726 139.628,225.068 140.042,225.661C140.902,226.895 141.874,228.288 142.534,229.235C143.012,229.919 143.045,230.819 142.619,231.536C141.934,232.691 140.895,234.441 140.209,235.596C139.784,236.313 138.978,236.715 138.149,236.624C137.001,236.498 135.313,236.312 133.817,236.148Z" />
    ),
    basic: {
      dark: (
        <path d="M137.061,239.261L137.433,239.483L144.701,227.237L144.328,227.016L137.061,239.261ZM135.691,238.449L136.86,239.143L144.128,226.898L142.959,226.204L135.691,238.449Z" />
      ),
      light: (
        <path d="M131.059,235.699L132.611,236.62L139.878,224.375L138.327,223.454L131.059,235.699ZM133.5,237.148L134.26,237.599L141.528,225.354L140.768,224.903L133.5,237.148Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M366.183,236.148C366.901,236.068 367.53,235.63 367.852,234.983C368.175,234.337 368.147,233.571 367.778,232.949C366.515,230.821 364.793,227.92 363.53,225.792C363.162,225.171 362.502,224.779 361.78,224.753C361.058,224.726 360.372,225.068 359.958,225.661C359.098,226.895 358.126,228.288 357.466,229.235C356.988,229.919 356.955,230.819 357.381,231.536C358.066,232.691 359.105,234.441 359.791,235.596C360.216,236.313 361.022,236.715 361.851,236.624C362.999,236.498 364.687,236.312 366.183,236.148Z" />
    ),
    basic: {
      dark: (
        <path d="M362.939,239.261L362.567,239.483L355.299,227.237L355.672,227.016L362.939,239.261ZM364.309,238.449L363.14,239.143L355.872,226.898L357.041,226.204L364.309,238.449Z" />
      ),
      light: (
        <path d="M368.941,235.699L367.389,236.62L360.122,224.375L361.673,223.454L368.941,235.699ZM366.5,237.148L365.74,237.599L358.472,225.354L359.232,224.903L366.5,237.148Z" />
      ),
    },
  },
};

const shapeEar6 = {
  left: {
    base: (
      <path d="M122.162,223.187C121.338,222.604 120.866,221.642 120.911,220.633C120.955,219.625 121.508,218.707 122.38,218.198C125.366,216.454 129.435,214.077 132.421,212.333C133.293,211.823 134.363,211.792 135.264,212.249C136.164,212.706 136.77,213.589 136.874,214.593C137.089,216.684 137.332,219.045 137.498,220.649C137.617,221.809 137.047,222.931 136.041,223.518C134.421,224.465 131.965,225.899 130.345,226.846C129.339,227.434 128.082,227.379 127.13,226.705C125.814,225.773 123.877,224.402 122.162,223.187Z" />
    ),
    basic: {
      dark: (
        <path d="M124.014,229.19L124.319,229.713L141.498,219.677L141.193,219.155L124.014,229.19ZM122.892,227.269L123.85,228.909L141.029,218.874L140.071,217.234L122.892,227.269Z" />
      ),
      light: (
        <path d="M119.095,220.77L120.367,222.947L137.546,212.912L136.275,210.735L119.095,220.77ZM121.096,224.195L121.719,225.261L138.898,215.226L138.275,214.159L121.096,224.195Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M377.838,223.187C378.662,222.604 379.134,221.642 379.089,220.633C379.045,219.625 378.492,218.707 377.62,218.198C374.634,216.454 370.565,214.077 367.579,212.333C366.707,211.823 365.637,211.792 364.736,212.249C363.836,212.706 363.23,213.589 363.126,214.593C362.911,216.684 362.668,219.045 362.502,220.649C362.383,221.809 362.953,222.931 363.959,223.518C365.579,224.465 368.035,225.899 369.655,226.846C370.661,227.434 371.918,227.379 372.87,226.705C374.186,225.773 376.123,224.402 377.838,223.187Z" />
    ),
    basic: {
      dark: (
        <path d="M375.986,229.19L375.681,229.713L358.502,219.677L358.807,219.155L375.986,229.19ZM377.108,227.269L376.15,228.909L358.971,218.874L359.929,217.234L377.108,227.269Z" />
      ),
      light: (
        <path d="M380.905,220.77L379.633,222.947L362.454,212.912L363.725,210.735L380.905,220.77ZM378.904,224.195L378.281,225.261L361.102,215.226L361.725,214.159L378.904,224.195Z" />
      ),
    },
  },
};

const shapeEar7 = {
  left: {
    base: (
      <path d="M124.431,223.939C124.773,223.186 124.709,222.311 124.261,221.616C123.813,220.921 123.043,220.501 122.216,220.501C119.384,220.501 115.525,220.501 112.693,220.501C111.866,220.501 111.095,220.921 110.648,221.616C110.2,222.311 110.136,223.186 110.477,223.939C111.189,225.506 111.992,227.277 112.538,228.479C112.932,229.348 113.799,229.906 114.753,229.906C116.29,229.906 118.619,229.906 120.156,229.906C121.11,229.906 121.976,229.348 122.371,228.479C122.917,227.277 123.72,225.506 124.431,223.939Z" />
    ),
    basic: {
      dark: (
        <path d="M125.601,228.949L125.601,229.444L109.307,229.444L109.307,228.949L125.601,228.949ZM125.601,227.127L125.601,228.682L109.307,228.682L109.307,227.127L125.601,227.127Z" />
      ),
      light: (
        <path d="M125.601,220.963L125.601,223.027L109.307,223.027L109.307,220.963L125.601,220.963ZM125.601,224.211L125.601,225.222L109.307,225.222L109.307,224.211L125.601,224.211Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M389.523,223.939C389.864,223.186 389.8,222.311 389.352,221.616C388.905,220.921 388.134,220.501 387.307,220.501C384.475,220.501 380.616,220.501 377.784,220.501C376.957,220.501 376.187,220.921 375.739,221.616C375.291,222.311 375.227,223.186 375.569,223.939C376.28,225.506 377.083,227.277 377.629,228.479C378.024,229.348 378.89,229.906 379.844,229.906C381.381,229.906 383.71,229.906 385.247,229.906C386.201,229.906 387.068,229.348 387.462,228.479C388.008,227.277 388.811,225.506 389.523,223.939Z" />
    ),
    basic: {
      dark: (
        <path d="M390.693,228.949L390.693,229.444L374.399,229.444L374.399,228.949L390.693,228.949ZM390.693,227.127L390.693,228.682L374.399,228.682L374.399,227.127L390.693,227.127Z" />
      ),
      light: (
        <path d="M390.693,220.963L390.693,223.027L374.399,223.027L374.399,220.963L390.693,220.963ZM390.693,224.211L390.693,225.222L374.399,225.222L374.399,224.211L390.693,224.211Z" />
      ),
    },
  },
};

const shapeEar8 = {
  left: {
    base: (
      <path d="M165.585,225.412C166.308,225.425 166.974,225.805 167.354,226.419C167.734,227.034 167.776,227.8 167.465,228.452C166.401,230.686 164.951,233.731 163.886,235.966C163.576,236.618 162.955,237.068 162.238,237.16C161.521,237.252 160.807,236.974 160.341,236.421C159.372,235.271 158.277,233.972 157.533,233.089C156.996,232.452 156.881,231.558 157.24,230.805C157.817,229.593 158.693,227.755 159.27,226.543C159.629,225.79 160.395,225.316 161.229,225.332C162.383,225.353 164.081,225.384 165.585,225.412Z" />
    ),
    basic: {
      dark: (
        <path d="M162.072,222.606L161.681,222.42L155.558,235.275L155.949,235.462L162.072,222.606ZM163.51,223.291L162.282,222.706L156.159,235.562L157.386,236.146L163.51,223.291Z" />
      ),
      light: (
        <path d="M168.373,225.607L166.744,224.832L160.621,237.687L162.25,238.463L168.373,225.607ZM165.81,224.387L165.012,224.007L158.889,236.862L159.687,237.242L165.81,224.387Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M334.415,225.412C333.692,225.425 333.026,225.805 332.646,226.419C332.266,227.034 332.224,227.8 332.535,228.452C333.599,230.686 335.049,233.731 336.114,235.966C336.424,236.618 337.045,237.068 337.762,237.16C338.479,237.252 339.193,236.974 339.659,236.421C340.628,235.271 341.723,233.972 342.467,233.089C343.004,232.452 343.119,231.558 342.76,230.805C342.183,229.593 341.307,227.755 340.73,226.543C340.371,225.79 339.605,225.316 338.771,225.332C337.617,225.353 335.919,225.384 334.415,225.412Z" />
    ),
    basic: {
      dark: (
        <path d="M337.928,222.606L338.319,222.42L344.442,235.275L344.051,235.462L337.928,222.606ZM336.49,223.291L337.718,222.706L343.841,235.562L342.614,236.146L336.49,223.291Z" />
      ),
      light: (
        <path d="M331.627,225.607L333.256,224.832L339.379,237.687L337.75,238.463L331.627,225.607ZM334.19,224.387L334.988,224.007L341.111,236.862L340.313,237.242L334.19,224.387Z" />
      ),
    },
  },
};

const shapeEar9 = {
  left: {
    base: (
      <path d="M136.826,144.117C136.124,144.288 135.383,144.09 134.859,143.593C134.336,143.095 134.1,142.365 134.235,141.655C134.696,139.224 135.325,135.91 135.786,133.478C135.921,132.769 136.407,132.176 137.076,131.904C137.746,131.633 138.508,131.72 139.099,132.136C140.329,133.003 141.718,133.981 142.661,134.645C143.343,135.125 143.682,135.96 143.526,136.779C143.276,138.099 142.897,140.099 142.646,141.418C142.491,142.237 141.871,142.89 141.06,143.087C139.939,143.36 138.288,143.762 136.826,144.117Z" />
    ),
    basic: {
      dark: (
        <path d="M140.937,145.938L141.363,146.018L144.016,132.028L143.591,131.948L140.937,145.938ZM139.373,145.641L140.709,145.894L143.362,131.904L142.026,131.651L139.373,145.641Z" />
      ),
      light: (
        <path d="M134.08,144.637L135.853,144.973L138.507,130.983L136.734,130.647L134.08,144.637ZM136.869,145.166L137.738,145.331L140.391,131.341L139.523,131.176L136.869,145.166Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M363.174,144.117C363.876,144.288 364.617,144.09 365.141,143.593C365.664,143.095 365.9,142.365 365.765,141.655C365.304,139.224 364.675,135.91 364.214,133.478C364.079,132.769 363.593,132.176 362.924,131.904C362.254,131.633 361.492,131.72 360.901,132.136C359.671,133.003 358.282,133.981 357.339,134.645C356.657,135.125 356.318,135.96 356.474,136.779C356.724,138.099 357.103,140.099 357.354,141.418C357.509,142.237 358.129,142.89 358.94,143.087C360.061,143.36 361.712,143.762 363.174,144.117Z" />
    ),
    basic: {
      dark: (
        <path d="M359.063,145.938L358.637,146.018L355.984,132.028L356.409,131.948L359.063,145.938ZM360.627,145.641L359.291,145.894L356.638,131.904L357.974,131.651L360.627,145.641Z" />
      ),
      light: (
        <path d="M365.92,144.637L364.147,144.973L361.493,130.983L363.266,130.647L365.92,144.637ZM363.131,145.166L362.262,145.331L359.609,131.341L360.477,131.176L363.131,145.166Z" />
      ),
    },
  },
};

const shapeEar10 = {
  left: {
    base: (
      <path d="M137.157,145.44C136.493,145.155 136.02,144.552 135.9,143.839C135.78,143.127 136.03,142.402 136.565,141.915C138.394,140.248 140.887,137.976 142.716,136.31C143.25,135.823 143.995,135.641 144.694,135.826C145.392,136.012 145.948,136.539 146.171,137.227C146.634,138.658 147.157,140.274 147.512,141.372C147.769,142.166 147.538,143.036 146.921,143.598C145.929,144.502 144.424,145.873 143.432,146.778C142.815,147.34 141.927,147.489 141.161,147.16C140.1,146.704 138.54,146.034 137.157,145.44Z" />
    ),
    basic: {
      dark: (
        <path d="M139.35,149.365L139.642,149.685L150.167,140.094L149.876,139.774L139.35,149.365ZM138.278,148.188L139.194,149.193L149.719,139.602L148.803,138.597L138.278,148.188Z" />
      ),
      light: (
        <path d="M134.65,144.206L135.865,145.54L146.39,135.949L145.175,134.615L134.65,144.206ZM136.562,146.304L137.157,146.958L147.682,137.367L147.087,136.714L136.562,146.304Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M362.843,145.44C363.507,145.155 363.98,144.552 364.1,143.839C364.22,143.127 363.97,142.402 363.435,141.915C361.606,140.248 359.113,137.976 357.284,136.31C356.75,135.823 356.005,135.641 355.306,135.826C354.608,136.012 354.052,136.539 353.829,137.227C353.366,138.658 352.843,140.274 352.488,141.372C352.231,142.166 352.462,143.036 353.079,143.598C354.071,144.502 355.576,145.873 356.568,146.778C357.185,147.34 358.073,147.489 358.839,147.16C359.9,146.704 361.46,146.034 362.843,145.44Z" />
    ),
    basic: {
      dark: (
        <path d="M360.65,149.365L360.358,149.685L349.833,140.094L350.124,139.774L360.65,149.365ZM361.722,148.188L360.806,149.193L350.281,139.602L351.197,138.597L361.722,148.188Z" />
      ),
      light: (
        <path d="M365.35,144.206L364.135,145.54L353.61,135.949L354.825,134.615L365.35,144.206ZM363.438,146.304L362.843,146.958L352.318,137.367L352.913,136.714L363.438,146.304Z" />
      ),
    },
  },
};

const shapeEar11 = {
  left: {
    base: (
      <path d="M132.933,213.164C133.016,212.446 133.458,211.82 134.106,211.5C134.754,211.181 135.52,211.213 136.14,211.585C138.262,212.859 141.153,214.595 143.275,215.869C143.895,216.241 144.283,216.903 144.306,217.625C144.328,218.347 143.983,219.032 143.388,219.442C142.15,220.296 140.752,221.261 139.802,221.916C139.115,222.39 138.215,222.419 137.5,221.989C136.349,221.298 134.604,220.25 133.452,219.559C132.737,219.129 132.339,218.322 132.435,217.493C132.567,216.346 132.761,214.659 132.933,213.164Z" />
    ),
    basic: {
      dark: (
        <path d="M129.803,216.391L129.58,216.763L141.788,224.093L142.011,223.722L129.803,216.391ZM130.623,215.026L129.923,216.192L142.131,223.522L142.83,222.357L130.623,215.026Z" />
      ),
      light: (
        <path d="M133.396,210.408L132.467,211.955L144.675,219.285L145.604,217.739L133.396,210.408ZM131.935,212.842L131.479,213.6L143.687,220.93L144.142,220.172L131.935,212.842Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M367.067,213.164C366.984,212.446 366.542,211.82 365.894,211.5C365.246,211.181 364.48,211.213 363.86,211.585C361.738,212.859 358.847,214.595 356.725,215.869C356.105,216.241 355.717,216.903 355.694,217.625C355.672,218.347 356.017,219.032 356.612,219.442C357.85,220.296 359.248,221.261 360.198,221.916C360.885,222.39 361.785,222.419 362.5,221.989C363.651,221.298 365.396,220.25 366.548,219.559C367.263,219.129 367.661,218.322 367.565,217.493C367.433,216.346 367.239,214.659 367.067,213.164Z" />
    ),
    basic: {
      dark: (
        <path d="M370.197,216.391L370.42,216.763L358.212,224.093L357.989,223.722L370.197,216.391ZM369.377,215.026L370.077,216.192L357.869,223.522L357.17,222.357L369.377,215.026Z" />
      ),
      light: (
        <path d="M366.604,210.408L367.533,211.955L355.325,219.285L354.396,217.739L366.604,210.408ZM368.065,212.842L368.521,213.6L356.313,220.93L355.858,220.172L368.065,212.842Z" />
      ),
    },
  },
};

const shapeEar12 = {
  left: {
    base: (
      <path d="M145.719,198.29C146.105,197.679 146.775,197.307 147.497,197.3C148.22,197.294 148.896,197.656 149.293,198.26C150.651,200.328 152.502,203.148 153.86,205.217C154.257,205.821 154.32,206.585 154.027,207.246C153.734,207.906 153.125,208.373 152.411,208.484C150.925,208.716 149.246,208.978 148.106,209.156C147.282,209.285 146.459,208.92 146.001,208.223C145.264,207.1 144.147,205.398 143.41,204.276C142.952,203.579 142.944,202.678 143.39,201.973C144.007,200.998 144.915,199.562 145.719,198.29Z" />
    ),
    basic: {
      dark: (
        <path d="M141.498,199.838L141.136,200.076L148.95,211.979L149.312,211.742L141.498,199.838ZM142.829,198.964L141.692,199.71L149.507,211.614L150.643,210.868L142.829,198.964Z" />
      ),
      light: (
        <path d="M147.332,196.008L145.823,196.998L153.638,208.902L155.146,207.912L147.332,196.008ZM144.959,197.566L144.22,198.051L152.035,209.955L152.774,209.469L144.959,197.566Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M354.281,198.29C353.895,197.679 353.225,197.307 352.503,197.3C351.78,197.294 351.104,197.656 350.707,198.26C349.349,200.328 347.498,203.148 346.14,205.217C345.743,205.821 345.68,206.585 345.973,207.246C346.266,207.906 346.875,208.373 347.589,208.484C349.075,208.716 350.754,208.978 351.894,209.156C352.718,209.285 353.541,208.92 353.999,208.223C354.736,207.1 355.853,205.398 356.59,204.276C357.048,203.579 357.056,202.678 356.61,201.973C355.993,200.998 355.085,199.562 354.281,198.29Z" />
    ),
    basic: {
      dark: (
        <path d="M358.502,199.838L358.864,200.076L351.05,211.979L350.688,211.742L358.502,199.838ZM357.171,198.964L358.308,199.71L350.493,211.614L349.357,210.868L357.171,198.964Z" />
      ),
      light: (
        <path d="M352.668,196.008L354.177,196.998L346.362,208.902L344.854,207.912L352.668,196.008ZM355.041,197.566L355.78,198.051L347.965,209.955L347.226,209.469L355.041,197.566Z" />
      ),
    },
  },
};

const shapeEar13 = {
  left: {
    base: (
      <path d="M153.628,226.713C154.296,226.987 154.78,227.582 154.912,228.292C155.043,229.003 154.805,229.731 154.279,230.227C152.478,231.924 150.024,234.237 148.222,235.935C147.697,236.43 146.955,236.625 146.254,236.451C145.552,236.278 144.987,235.759 144.753,235.076C144.266,233.652 143.716,232.045 143.342,230.953C143.072,230.164 143.289,229.29 143.896,228.718C144.873,227.797 146.354,226.401 147.332,225.48C147.939,224.908 148.824,224.744 149.596,225.06C150.664,225.498 152.236,226.142 153.628,226.713Z" />
    ),
    basic: {
      dark: (
        <path d="M151.369,222.825L151.072,222.51L140.709,232.275L141.006,232.591L151.369,222.825ZM152.461,223.984L151.529,222.994L141.166,232.76L142.098,233.75L152.461,223.984Z" />
      ),
      light: (
        <path d="M156.156,227.904L154.918,226.591L144.555,236.357L145.793,237.67L156.156,227.904ZM154.209,225.838L153.603,225.195L143.239,234.961L143.846,235.604L154.209,225.838Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M346.372,226.713C345.704,226.987 345.22,227.582 345.088,228.292C344.957,229.003 345.195,229.731 345.721,230.227C347.522,231.924 349.976,234.237 351.778,235.935C352.303,236.43 353.045,236.625 353.746,236.451C354.448,236.278 355.013,235.759 355.247,235.076C355.734,233.652 356.284,232.045 356.658,230.953C356.928,230.164 356.711,229.29 356.104,228.718C355.127,227.797 353.646,226.401 352.668,225.48C352.061,224.908 351.176,224.744 350.404,225.06C349.336,225.498 347.764,226.142 346.372,226.713Z" />
    ),
    basic: {
      dark: (
        <path d="M348.631,222.825L348.928,222.51L359.291,232.275L358.994,232.591L348.631,222.825ZM347.539,223.984L348.471,222.994L358.834,232.76L357.902,233.75L347.539,223.984Z" />
      ),
      light: (
        <path d="M343.844,227.904L345.082,226.591L355.445,236.357L354.207,237.67L343.844,227.904ZM345.791,225.838L346.397,225.195L356.761,234.961L356.154,235.604L345.791,225.838Z" />
      ),
    },
  },
};

const shapeEar14 = {
  left: {
    base: (
      <path d="M125.852,236.449C124.913,236.079 124.227,235.256 124.03,234.266C123.833,233.276 124.152,232.253 124.878,231.551C127.364,229.147 130.751,225.871 133.237,223.467C133.962,222.765 134.995,222.48 135.978,222.71C136.961,222.94 137.76,223.653 138.099,224.604C138.806,226.584 139.603,228.819 140.145,230.338C140.537,231.436 140.25,232.661 139.412,233.471C138.064,234.776 136.019,236.753 134.671,238.057C133.833,238.868 132.599,239.113 131.515,238.685C130.015,238.093 127.807,237.221 125.852,236.449Z" />
    ),
    basic: {
      dark: (
        <path d="M129.078,241.84L129.499,242.275L143.8,228.443L143.379,228.008L129.078,241.84ZM127.532,240.241L128.852,241.606L143.153,227.774L141.833,226.409L127.532,240.241Z" />
      ),
      light: (
        <path d="M122.299,234.831L124.052,236.643L138.353,222.811L136.6,220.999L122.299,234.831ZM125.056,237.681L125.915,238.569L140.216,224.737L139.357,223.85L125.056,237.681Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M374.148,236.449C375.087,236.079 375.773,235.256 375.97,234.266C376.167,233.276 375.848,232.253 375.122,231.551C372.636,229.147 369.249,225.871 366.763,223.467C366.038,222.765 365.005,222.48 364.022,222.71C363.039,222.94 362.24,223.653 361.901,224.604C361.194,226.584 360.397,228.819 359.855,230.338C359.463,231.436 359.75,232.661 360.588,233.471C361.936,234.776 363.981,236.753 365.329,238.057C366.167,238.868 367.401,239.113 368.485,238.685C369.985,238.093 372.193,237.221 374.148,236.449Z" />
    ),
    basic: {
      dark: (
        <path d="M370.922,241.84L370.501,242.275L356.2,228.443L356.621,228.008L370.922,241.84ZM372.468,240.241L371.148,241.606L356.847,227.774L358.167,226.409L372.468,240.241Z" />
      ),
      light: (
        <path d="M377.701,234.831L375.948,236.643L361.647,222.811L363.4,220.999L377.701,234.831ZM374.944,237.681L374.085,238.569L359.784,224.737L360.643,223.85L374.944,237.681Z" />
      ),
    },
  },
};

const shapeEar15 = {
  left: {
    base: (
      <path d="M117.177,227.89C116.496,227.145 116.24,226.105 116.498,225.128C116.755,224.152 117.491,223.374 118.451,223.062C121.74,221.992 126.222,220.535 129.51,219.466C130.47,219.154 131.523,219.351 132.306,219.989C133.088,220.627 133.493,221.619 133.38,222.622C133.146,224.711 132.882,227.07 132.702,228.672C132.572,229.83 131.777,230.806 130.668,231.166C128.884,231.746 126.179,232.625 124.395,233.206C123.287,233.566 122.07,233.245 121.284,232.384C120.196,231.194 118.595,229.442 117.177,227.89Z" />
    ),
    basic: {
      dark: (
        <path d="M117.71,234.15L117.897,234.725L136.818,228.574L136.631,227.998L117.71,234.15ZM117.022,232.034L117.609,233.84L136.53,227.689L135.943,225.882L117.022,232.034Z" />
      ),
      light: (
        <path d="M114.695,224.876L115.474,227.274L134.395,221.122L133.616,218.725L114.695,224.876ZM115.921,228.648L116.303,229.822L135.224,223.671L134.842,222.496L115.921,228.648Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M382.823,227.89C383.504,227.145 383.76,226.105 383.502,225.128C383.245,224.152 382.509,223.374 381.549,223.062C378.26,221.992 373.778,220.535 370.49,219.466C369.53,219.154 368.477,219.351 367.694,219.989C366.912,220.627 366.507,221.619 366.62,222.622C366.854,224.711 367.118,227.07 367.298,228.672C367.428,229.83 368.223,230.806 369.332,231.166C371.116,231.746 373.821,232.625 375.605,233.206C376.713,233.566 377.93,233.245 378.716,232.384C379.804,231.194 381.405,229.442 382.823,227.89Z" />
    ),
    basic: {
      dark: (
        <path d="M382.29,234.15L382.103,234.725L363.182,228.574L363.369,227.998L382.29,234.15ZM382.978,232.034L382.391,233.84L363.47,227.689L364.057,225.882L382.978,232.034Z" />
      ),
      light: (
        <path d="M385.305,224.876L384.526,227.274L365.605,221.122L366.384,218.725L385.305,224.876ZM384.079,228.648L383.697,229.822L364.776,223.671L365.158,222.496L384.079,228.648Z" />
      ),
    },
  },
};

const shapeEar16 = {
  left: {
    base: (
      <path d="M142.398,204.016C142.669,203.294 143.289,202.76 144.042,202.597C144.796,202.434 145.581,202.665 146.126,203.21C147.993,205.077 150.538,207.622 152.406,209.49C152.951,210.035 153.182,210.82 153.019,211.573C152.856,212.327 152.321,212.946 151.599,213.218C150.097,213.782 148.4,214.42 147.247,214.853C146.414,215.166 145.474,214.963 144.845,214.333C143.832,213.32 142.296,211.784 141.283,210.771C140.653,210.141 140.45,209.202 140.763,208.369C141.196,207.216 141.834,205.519 142.398,204.016Z" />
    ),
    basic: {
      dark: (
        <path d="M138.323,206.548L137.996,206.875L148.741,217.62L149.067,217.293L138.323,206.548ZM139.524,205.347L138.499,206.373L149.243,217.117L150.269,216.091L139.524,205.347Z" />
      ),
      light: (
        <path d="M143.589,201.282L142.228,202.644L152.972,213.388L154.334,212.027L143.589,201.282ZM141.447,203.424L140.78,204.091L151.525,214.836L152.192,214.169L141.447,203.424Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M357.239,204.016C356.968,203.294 356.349,202.76 355.595,202.597C354.841,202.434 354.057,202.665 353.511,203.21C351.644,205.077 349.099,207.622 347.231,209.49C346.686,210.035 346.455,210.82 346.618,211.573C346.781,212.327 347.316,212.946 348.038,213.218C349.541,213.782 351.238,214.42 352.39,214.853C353.224,215.166 354.163,214.963 354.792,214.333C355.806,213.32 357.341,211.784 358.355,210.771C358.984,210.141 359.187,209.202 358.874,208.369C358.441,207.216 357.804,205.519 357.239,204.016Z" />
    ),
    basic: {
      dark: (
        <path d="M361.314,206.548L361.641,206.875L350.897,217.62L350.57,217.293L361.314,206.548ZM360.113,205.347L361.139,206.373L350.394,217.117L349.368,216.091L360.113,205.347Z" />
      ),
      light: (
        <path d="M356.048,201.282L357.41,202.644L346.665,213.388L345.304,212.027L356.048,201.282ZM358.19,203.424L358.857,204.091L348.113,214.836L347.446,214.169L358.19,203.424Z" />
      ),
    },
  },
};

const shapeEar17 = {
  left: {
    base: (
      <path d="M137.697,229.227C137.365,228.412 137.469,227.484 137.974,226.763C138.479,226.042 139.316,225.627 140.196,225.661C143.208,225.779 147.313,225.938 150.326,226.056C151.205,226.09 152.007,226.569 152.455,227.327C152.902,228.084 152.934,229.018 152.54,229.805C151.718,231.443 150.79,233.292 150.16,234.549C149.704,235.457 148.76,236.015 147.744,235.975C146.11,235.912 143.632,235.815 141.998,235.751C140.983,235.712 140.084,235.082 139.701,234.141C139.17,232.84 138.389,230.924 137.697,229.227Z" />
    ),
    basic: {
      dark: (
        <path d="M136.245,234.507L136.224,235.035L153.556,235.709L153.577,235.182L136.245,234.507ZM136.32,232.569L136.256,234.224L153.588,234.899L153.652,233.244L136.32,232.569Z" />
      ),
      light: (
        <path d="M136.576,226.013L136.49,228.209L153.822,228.884L153.908,226.687L136.576,226.013ZM136.441,229.468L136.399,230.544L153.731,231.218L153.773,230.142L136.441,229.468Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M362.303,229.227C362.635,228.412 362.531,227.484 362.026,226.763C361.521,226.042 360.684,225.627 359.804,225.661C356.792,225.779 352.687,225.938 349.674,226.056C348.795,226.09 347.993,226.569 347.545,227.327C347.098,228.084 347.066,229.018 347.46,229.805C348.282,231.443 349.21,233.292 349.84,234.549C350.296,235.457 351.24,236.015 352.256,235.975C353.89,235.912 356.368,235.815 358.002,235.751C359.017,235.712 359.916,235.082 360.299,234.141C360.83,232.84 361.611,230.924 362.303,229.227Z" />
    ),
    basic: {
      dark: (
        <path d="M363.755,234.507L363.776,235.035L346.444,235.709L346.423,235.182L363.755,234.507ZM363.68,232.569L363.744,234.224L346.412,234.899L346.348,233.244L363.68,232.569Z" />
      ),
      light: (
        <path d="M363.424,226.013L363.51,228.209L346.178,228.884L346.092,226.687L363.424,226.013ZM363.559,229.468L363.601,230.544L346.269,231.218L346.227,230.142L363.559,229.468Z" />
      ),
    },
  },
};

const shapeEar18 = {
  left: {
    base: (
      <path d="M144.589,213.745C144.774,212.947 145.353,212.297 146.125,212.02C146.897,211.743 147.756,211.878 148.407,212.377C150.635,214.086 153.672,216.414 155.901,218.123C156.551,218.622 156.904,219.418 156.837,220.235C156.77,221.052 156.293,221.779 155.569,222.165C154.064,222.97 152.363,223.878 151.208,224.495C150.373,224.941 149.355,224.857 148.604,224.281C147.395,223.354 145.562,221.949 144.353,221.021C143.602,220.445 143.257,219.483 143.471,218.561C143.767,217.286 144.203,215.408 144.589,213.745Z" />
    ),
    basic: {
      dark: (
        <path d="M140.645,216.982L140.346,217.372L153.168,227.204L153.467,226.814L140.645,216.982ZM141.745,215.548L140.806,216.772L153.628,226.604L154.566,225.38L141.745,215.548Z" />
      ),
      light: (
        <path d="M145.464,210.697L144.218,212.322L157.04,222.154L158.286,220.53L145.464,210.697ZM143.504,213.253L142.894,214.049L155.715,223.881L156.326,223.086L143.504,213.253Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M355.411,213.745C355.226,212.947 354.647,212.297 353.875,212.02C353.103,211.743 352.244,211.878 351.593,212.377C349.365,214.086 346.328,216.414 344.099,218.123C343.449,218.622 343.096,219.418 343.163,220.235C343.23,221.052 343.707,221.779 344.431,222.165C345.936,222.97 347.637,223.878 348.792,224.495C349.627,224.941 350.645,224.857 351.396,224.281C352.605,223.354 354.438,221.949 355.647,221.021C356.398,220.445 356.743,219.483 356.529,218.561C356.233,217.286 355.797,215.408 355.411,213.745Z" />
    ),
    basic: {
      dark: (
        <path d="M359.355,216.982L359.654,217.372L346.832,227.204L346.533,226.814L359.355,216.982ZM358.255,215.548L359.194,216.772L346.372,226.604L345.434,225.38L358.255,215.548Z" />
      ),
      light: (
        <path d="M354.536,210.697L355.782,212.322L342.96,222.154L341.714,220.53L354.536,210.697ZM356.496,213.253L357.106,214.049L344.285,223.881L343.674,223.086L356.496,213.253Z" />
      ),
    },
  },
};

const shapeEar19 = {
  left: {
    base: (
      <path d="M176.612,134.506C177.409,134.341 178.067,133.783 178.359,133.024C178.652,132.266 178.539,131.41 178.06,130.753C176.418,128.504 174.181,125.438 172.54,123.188C172.06,122.531 171.28,122.163 170.468,122.21C169.657,122.257 168.924,122.713 168.524,123.421C167.691,124.895 166.751,126.559 166.112,127.69C165.65,128.507 165.709,129.519 166.262,130.277C167.153,131.498 168.503,133.348 169.394,134.568C169.947,135.327 170.892,135.691 171.811,135.501C173.083,135.237 174.955,134.85 176.612,134.506Z" />
    ),
    basic: {
      dark: (
        <path d="M173.311,138.339L172.917,138.627L163.473,125.683L163.866,125.396L173.311,138.339ZM174.758,137.283L173.522,138.185L164.078,125.241L165.314,124.34L174.758,137.283Z" />
      ),
      light: (
        <path d="M179.655,133.71L178.015,134.907L168.57,121.964L170.21,120.767L179.655,133.71ZM177.075,135.593L176.271,136.18L166.826,123.236L167.63,122.649L177.075,135.593Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M323.388,134.506C322.591,134.341 321.933,133.783 321.641,133.024C321.348,132.266 321.461,131.41 321.94,130.753C323.582,128.504 325.819,125.438 327.46,123.188C327.94,122.531 328.72,122.163 329.532,122.21C330.343,122.257 331.076,122.713 331.476,123.421C332.309,124.895 333.249,126.559 333.888,127.69C334.35,128.507 334.291,129.519 333.738,130.277C332.847,131.498 331.497,133.348 330.606,134.568C330.053,135.327 329.108,135.691 328.189,135.501C326.917,135.237 325.045,134.85 323.388,134.506Z" />
    ),
    basic: {
      dark: (
        <path d="M326.689,138.339L327.083,138.627L336.527,125.683L336.134,125.396L326.689,138.339ZM325.242,137.283L326.478,138.185L335.922,125.241L334.686,124.34L325.242,137.283Z" />
      ),
      light: (
        <path d="M320.345,133.71L321.985,134.907L331.43,121.964L329.79,120.767L320.345,133.71ZM322.925,135.593L323.729,136.18L333.174,123.236L332.37,122.649L322.925,135.593Z" />
      ),
    },
  },
};

const shapeEar20 = {
  left: {
    base: (
      <path d="M155.606,248.723C156.317,248.592 157.045,248.832 157.54,249.358C158.035,249.885 158.228,250.627 158.053,251.328C157.455,253.73 156.639,257.002 156.04,259.404C155.865,260.105 155.346,260.669 154.662,260.902C153.978,261.135 153.222,261.004 152.656,260.555C151.477,259.621 150.146,258.565 149.242,257.848C148.588,257.33 148.298,256.477 148.5,255.668C148.825,254.365 149.317,252.39 149.642,251.087C149.844,250.278 150.5,249.661 151.321,249.51C152.456,249.302 154.127,248.995 155.606,248.723Z" />
    ),
    basic: {
      dark: (
        <path d="M151.605,246.672L151.185,246.567L147.74,260.383L148.161,260.488L151.605,246.672ZM153.15,247.057L151.831,246.728L148.386,260.544L149.706,260.873L153.15,247.057Z" />
      ),
      light: (
        <path d="M158.377,248.36L156.627,247.924L153.182,261.74L154.932,262.177L158.377,248.36ZM155.623,247.673L154.765,247.459L151.32,261.276L152.178,261.49L155.623,247.673Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M344.394,248.723C343.683,248.592 342.955,248.832 342.46,249.358C341.965,249.885 341.772,250.627 341.947,251.328C342.545,253.73 343.361,257.002 343.96,259.404C344.135,260.105 344.654,260.669 345.338,260.902C346.022,261.135 346.778,261.004 347.344,260.555C348.523,259.621 349.854,258.565 350.758,257.848C351.412,257.33 351.702,256.477 351.5,255.668C351.175,254.365 350.683,252.39 350.358,251.087C350.156,250.278 349.5,249.661 348.679,249.51C347.544,249.302 345.873,248.995 344.394,248.723Z" />
    ),
    basic: {
      dark: (
        <path d="M348.395,246.672L348.815,246.567L352.26,260.383L351.839,260.488L348.395,246.672ZM346.85,247.057L348.169,246.728L351.614,260.544L350.294,260.873L346.85,247.057Z" />
      ),
      light: (
        <path d="M341.623,248.36L343.373,247.924L346.818,261.74L345.068,262.177L341.623,248.36ZM344.377,247.673L345.235,247.459L348.68,261.276L347.822,261.49L344.377,247.673Z" />
      ),
    },
  },
};

const shapeEar21 = {
  left: {
    base: (
      <path d="M137.516,224.708C137.281,224.19 137.325,223.587 137.633,223.109C137.941,222.631 138.472,222.341 139.041,222.341C140.99,222.341 143.647,222.341 145.596,222.341C146.165,222.341 146.695,222.631 147.004,223.109C147.312,223.587 147.356,224.19 147.121,224.708C146.631,225.787 146.078,227.005 145.703,227.833C145.431,228.431 144.835,228.816 144.178,228.816C143.12,228.816 141.517,228.816 140.459,228.816C139.802,228.816 139.206,228.431 138.934,227.833C138.559,227.005 138.006,225.787 137.516,224.708Z" />
    ),
    basic: {
      dark: (
        <path d="M136.71,228.157L136.71,228.498L147.926,228.498L147.926,228.157L136.71,228.157ZM136.71,226.902L136.71,227.973L147.926,227.973L147.926,226.902L136.71,226.902Z" />
      ),
      light: (
        <path d="M136.71,222.659L136.71,224.081L147.926,224.081L147.926,222.659L136.71,222.659ZM136.71,224.895L136.71,225.592L147.926,225.592L147.926,224.895L136.71,224.895Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M362.484,224.708C362.719,224.19 362.675,223.587 362.367,223.109C362.059,222.631 361.528,222.341 360.959,222.341C359.01,222.341 356.353,222.341 354.404,222.341C353.835,222.341 353.305,222.631 352.996,223.109C352.688,223.587 352.644,224.19 352.879,224.708C353.369,225.787 353.922,227.005 354.297,227.833C354.569,228.431 355.165,228.816 355.822,228.816C356.88,228.816 358.483,228.816 359.541,228.816C360.198,228.816 360.794,228.431 361.066,227.833C361.441,227.005 361.994,225.787 362.484,224.708Z" />
    ),
    basic: {
      dark: (
        <path d="M363.29,228.157L363.29,228.498L352.074,228.498L352.074,228.157L363.29,228.157ZM363.29,226.902L363.29,227.973L352.074,227.973L352.074,226.902L363.29,226.902Z" />
      ),
      light: (
        <path d="M363.29,222.659L363.29,224.081L352.074,224.081L352.074,222.659L363.29,222.659ZM363.29,224.895L363.29,225.592L352.074,225.592L352.074,224.895L363.29,224.895Z" />
      ),
    },
  },
};

const shapeEar22 = {
  left: {
    base: (
      <path d="M136.212,230.002C135.851,229.562 135.738,228.969 135.912,228.427C136.086,227.885 136.523,227.469 137.073,227.321C138.956,226.817 141.522,226.129 143.405,225.625C143.955,225.478 144.541,225.62 144.963,226.002C145.385,226.384 145.583,226.955 145.49,227.516C145.296,228.685 145.078,230.005 144.929,230.902C144.822,231.55 144.345,232.076 143.71,232.246C142.689,232.519 141.14,232.934 140.118,233.208C139.484,233.378 138.808,233.161 138.391,232.654C137.814,231.951 136.965,230.918 136.212,230.002Z" />
    ),
    basic: {
      dark: (
        <path d="M136.327,233.542L136.415,233.871L147.249,230.968L147.161,230.639L136.327,233.542ZM136.002,232.33L136.28,233.364L147.113,230.462L146.836,229.427L136.002,232.33Z" />
      ),
      light: (
        <path d="M134.904,228.232L135.272,229.605L146.106,226.702L145.738,225.329L134.904,228.232ZM135.483,230.391L135.663,231.064L146.497,228.161L146.317,227.489L135.483,230.391Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M363.788,230.002C364.149,229.562 364.262,228.969 364.088,228.427C363.914,227.885 363.477,227.469 362.927,227.321C361.044,226.817 358.478,226.129 356.595,225.625C356.045,225.478 355.459,225.62 355.037,226.002C354.615,226.384 354.417,226.955 354.51,227.516C354.704,228.685 354.922,230.005 355.071,230.902C355.178,231.55 355.655,232.076 356.29,232.246C357.311,232.519 358.86,232.934 359.882,233.208C360.516,233.378 361.192,233.161 361.609,232.654C362.186,231.951 363.035,230.918 363.788,230.002Z" />
    ),
    basic: {
      dark: (
        <path d="M363.673,233.542L363.585,233.871L352.751,230.968L352.839,230.639L363.673,233.542ZM363.998,232.33L363.72,233.364L352.887,230.462L353.164,229.427L363.998,232.33Z" />
      ),
      light: (
        <path d="M365.096,228.232L364.728,229.605L353.894,226.702L354.262,225.329L365.096,228.232ZM364.517,230.391L364.337,231.064L353.503,228.161L353.683,227.489L364.517,230.391Z" />
      ),
    },
  },
};

const shapeEar23 = {
  left: {
    base: (
      <path d="M126.038,238.448C125.32,238.369 124.691,237.93 124.368,237.284C124.046,236.637 124.074,235.871 124.443,235.249C125.706,233.121 127.427,230.221 128.69,228.093C129.059,227.471 129.718,227.08 130.441,227.053C131.163,227.027 131.849,227.369 132.262,227.961C133.123,229.195 134.095,230.588 134.755,231.535C135.232,232.219 135.265,233.119 134.84,233.836C134.154,234.991 133.115,236.741 132.43,237.896C132.004,238.614 131.198,239.016 130.369,238.924C129.222,238.798 127.534,238.612 126.038,238.448Z" />
    ),
    basic: {
      dark: (
        <path d="M129.281,241.562L129.654,241.783L136.922,229.538L136.549,229.317L129.281,241.562ZM127.912,240.749L129.081,241.443L136.349,229.198L135.18,228.504L127.912,240.749Z" />
      ),
      light: (
        <path d="M123.28,238L124.831,238.921L132.099,226.675L130.548,225.755L123.28,238ZM125.721,239.448L126.481,239.9L133.749,227.655L132.989,227.203L125.721,239.448Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M373.962,238.448C374.68,238.369 375.309,237.93 375.632,237.284C375.954,236.637 375.926,235.871 375.557,235.249C374.294,233.121 372.573,230.221 371.31,228.093C370.941,227.471 370.282,227.08 369.559,227.053C368.837,227.027 368.151,227.369 367.738,227.961C366.877,229.195 365.905,230.588 365.245,231.535C364.768,232.219 364.735,233.119 365.16,233.836C365.846,234.991 366.885,236.741 367.57,237.896C367.996,238.614 368.802,239.016 369.631,238.924C370.778,238.798 372.466,238.612 373.962,238.448Z" />
    ),
    basic: {
      dark: (
        <path d="M370.719,241.562L370.346,241.783L363.078,229.538L363.451,229.317L370.719,241.562ZM372.088,240.749L370.919,241.443L363.651,229.198L364.82,228.504L372.088,240.749Z" />
      ),
      light: (
        <path d="M376.72,238L375.169,238.921L367.901,226.675L369.452,225.755L376.72,238ZM374.279,239.448L373.519,239.9L366.251,227.655L367.011,227.203L374.279,239.448Z" />
      ),
    },
  },
};

const shapeEar24 = {
  left: {
    base: (
      <path d="M106.773,236.857C106.051,236.87 105.371,236.515 104.969,235.915C104.567,235.314 104.497,234.551 104.784,233.888C105.766,231.616 107.105,228.52 108.087,226.249C108.374,225.586 108.978,225.113 109.691,224.995C110.404,224.877 111.128,225.129 111.613,225.664C112.624,226.779 113.765,228.037 114.54,228.892C115.101,229.51 115.248,230.398 114.917,231.164C114.384,232.397 113.576,234.265 113.043,235.497C112.712,236.263 111.964,236.764 111.13,236.779C109.976,236.8 108.277,236.83 106.773,236.857Z" />
    ),
    basic: {
      dark: (
        <path d="M110.386,239.533L110.783,239.705L116.435,226.636L116.038,226.464L110.386,239.533ZM108.924,238.901L110.172,239.441L115.824,226.371L114.576,225.832L108.924,238.901Z" />
      ),
      light: (
        <path d="M103.98,236.763L105.636,237.479L111.288,224.41L109.632,223.694L103.98,236.763ZM106.585,237.89L107.397,238.241L113.049,225.171L112.237,224.82L106.585,237.89Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M393.227,236.857C393.949,236.87 394.629,236.515 395.031,235.915C395.433,235.314 395.503,234.551 395.216,233.888C394.234,231.616 392.895,228.52 391.913,226.249C391.626,225.586 391.022,225.113 390.309,224.995C389.596,224.877 388.872,225.129 388.387,225.664C387.376,226.779 386.235,228.037 385.46,228.892C384.899,229.51 384.752,230.398 385.083,231.164C385.616,232.397 386.424,234.265 386.957,235.497C387.288,236.263 388.036,236.764 388.87,236.779C390.024,236.8 391.723,236.83 393.227,236.857Z" />
    ),
    basic: {
      dark: (
        <path d="M389.614,239.533L389.217,239.705L383.565,226.636L383.962,226.464L389.614,239.533ZM391.076,238.901L389.828,239.441L384.176,226.371L385.424,225.832L391.076,238.901Z" />
      ),
      light: (
        <path d="M396.02,236.763L394.364,237.479L388.712,224.41L390.368,223.694L396.02,236.763ZM393.415,237.89L392.603,238.241L386.951,225.171L387.763,224.82L393.415,237.89Z" />
      ),
    },
  },
};

const shapeEar25 = {
  left: {
    base: (
      <path d="M130.363,113.353C129.661,113.524 128.92,113.326 128.396,112.829C127.872,112.331 127.637,111.601 127.772,110.891C128.233,108.46 128.862,105.146 129.323,102.714C129.458,102.004 129.944,101.411 130.613,101.14C131.283,100.869 132.045,100.956 132.636,101.372C133.866,102.238 135.255,103.216 136.198,103.881C136.88,104.361 137.218,105.196 137.063,106.015C136.813,107.335 136.433,109.334 136.183,110.654C136.028,111.473 135.407,112.126 134.597,112.323C133.476,112.596 131.825,112.998 130.363,113.353Z" />
    ),
    basic: {
      dark: (
        <path d="M134.474,115.174L134.9,115.254L137.553,101.264L137.128,101.184L134.474,115.174ZM132.91,114.877L134.245,115.13L136.899,101.14L135.563,100.887L132.91,114.877Z" />
      ),
      light: (
        <path d="M127.617,113.873L129.39,114.209L132.044,100.219L130.271,99.883L127.617,113.873ZM130.406,114.402L131.275,114.567L133.928,100.577L133.06,100.412L130.406,114.402Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M369.637,113.353C370.339,113.524 371.08,113.326 371.604,112.829C372.128,112.331 372.363,111.601 372.228,110.891C371.767,108.46 371.138,105.146 370.677,102.714C370.542,102.004 370.056,101.411 369.387,101.14C368.717,100.869 367.955,100.956 367.364,101.372C366.134,102.238 364.745,103.216 363.802,103.881C363.12,104.361 362.782,105.196 362.937,106.015C363.187,107.335 363.567,109.334 363.817,110.654C363.972,111.473 364.593,112.126 365.403,112.323C366.524,112.596 368.175,112.998 369.637,113.353Z" />
    ),
    basic: {
      dark: (
        <path d="M365.526,115.174L365.1,115.254L362.447,101.264L362.872,101.184L365.526,115.174ZM367.09,114.877L365.755,115.13L363.101,101.14L364.437,100.887L367.09,114.877Z" />
      ),
      light: (
        <path d="M372.383,113.873L370.61,114.209L367.956,100.219L369.729,99.883L372.383,113.873ZM369.594,114.402L368.725,114.567L366.072,100.577L366.94,100.412L369.594,114.402Z" />
      ),
    },
  },
};

const shapeEar26 = {
  left: {
    base: (
      <path d="M149.257,224.632C149.967,224.497 150.697,224.732 151.195,225.255C151.693,225.779 151.891,226.519 151.721,227.222C151.138,229.627 150.343,232.905 149.76,235.31C149.59,236.012 149.074,236.58 148.392,236.817C147.709,237.054 146.953,236.929 146.384,236.483C145.199,235.556 143.861,234.51 142.952,233.799C142.295,233.285 141.999,232.434 142.196,231.623C142.512,230.318 142.992,228.34 143.308,227.035C143.505,226.224 144.157,225.603 144.977,225.447C146.111,225.232 147.779,224.914 149.257,224.632Z" />
    ),
    basic: {
      dark: (
        <path d="M145.243,222.607L144.822,222.505L141.467,236.343L141.888,236.446L145.243,222.607ZM146.791,222.982L145.469,222.662L142.114,236.5L143.435,236.821L146.791,222.982Z" />
      ),
      light: (
        <path d="M152.026,224.251L150.272,223.826L146.917,237.665L148.671,238.09L152.026,224.251ZM149.267,223.583L148.408,223.374L145.053,237.213L145.912,237.421L149.267,223.583Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M350.743,224.632C350.033,224.497 349.303,224.732 348.805,225.255C348.307,225.779 348.109,226.519 348.279,227.222C348.862,229.627 349.657,232.905 350.24,235.31C350.41,236.012 350.926,236.58 351.608,236.817C352.291,237.054 353.047,236.929 353.616,236.483C354.801,235.556 356.139,234.51 357.048,233.799C357.705,233.285 358.001,232.434 357.804,231.623C357.488,230.318 357.008,228.34 356.692,227.035C356.495,226.224 355.843,225.603 355.023,225.447C353.889,225.232 352.221,224.914 350.743,224.632Z" />
    ),
    basic: {
      dark: (
        <path d="M354.757,222.607L355.178,222.505L358.533,236.343L358.112,236.446L354.757,222.607ZM353.209,222.982L354.531,222.662L357.886,236.5L356.565,236.821L353.209,222.982Z" />
      ),
      light: (
        <path d="M347.974,224.251L349.728,223.826L353.083,237.665L351.329,238.09L347.974,224.251ZM350.733,223.583L351.592,223.374L354.947,237.213L354.088,237.421L350.733,223.583Z" />
      ),
    },
  },
};

const shapeEar27 = {
  left: {
    base: (
      <path d="M122.653,226.9C121.94,227.018 121.216,226.766 120.731,226.231C120.245,225.696 120.065,224.951 120.252,224.253C120.893,221.862 121.766,218.604 122.406,216.214C122.593,215.516 123.122,214.961 123.81,214.74C124.498,214.519 125.251,214.663 125.81,215.121C126.972,216.076 128.284,217.155 129.176,217.888C129.82,218.417 130.096,219.275 129.88,220.08C129.532,221.378 129.005,223.344 128.658,224.641C128.442,225.446 127.775,226.052 126.952,226.188C125.813,226.377 124.137,226.654 122.653,226.9Z" />
    ),
    basic: {
      dark: (
        <path d="M126.618,229.021L127.036,229.133L130.722,215.379L130.303,215.267L126.618,229.021ZM125.08,228.609L126.393,228.961L130.078,215.207L128.765,214.855L125.08,228.609Z" />
      ),
      light: (
        <path d="M119.876,227.215L121.619,227.682L125.305,213.928L123.562,213.461L119.876,227.215ZM122.618,227.95L123.472,228.178L127.158,214.424L126.304,214.195L122.618,227.95Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M377.347,226.9C378.06,227.018 378.784,226.766 379.269,226.231C379.755,225.696 379.935,224.951 379.748,224.253C379.107,221.862 378.234,218.604 377.594,216.214C377.407,215.516 376.878,214.961 376.19,214.74C375.502,214.519 374.749,214.663 374.19,215.121C373.028,216.076 371.716,217.155 370.824,217.888C370.18,218.417 369.904,219.275 370.12,220.08C370.468,221.378 370.995,223.344 371.342,224.641C371.558,225.446 372.225,226.052 373.048,226.188C374.187,226.377 375.863,226.654 377.347,226.9Z" />
    ),
    basic: {
      dark: (
        <path d="M373.382,229.021L372.964,229.133L369.278,215.379L369.697,215.267L373.382,229.021ZM374.92,228.609L373.607,228.961L369.922,215.207L371.235,214.855L374.92,228.609Z" />
      ),
      light: (
        <path d="M380.124,227.215L378.381,227.682L374.695,213.928L376.438,213.461L380.124,227.215ZM377.382,227.95L376.528,228.178L372.842,214.424L373.696,214.195L377.382,227.95Z" />
      ),
    },
  },
};

const shapeEar28 = {
  left: {
    base: (
      <path d="M128.397,103.189C128.526,102.478 129.008,101.881 129.675,101.605C130.343,101.328 131.105,101.409 131.699,101.821C133.734,103.23 136.507,105.15 138.542,106.559C139.136,106.97 139.48,107.655 139.456,108.377C139.432,109.1 139.043,109.76 138.423,110.131C137.132,110.903 135.674,111.775 134.684,112.368C133.968,112.796 133.068,112.766 132.382,112.291C131.278,111.527 129.604,110.368 128.5,109.604C127.815,109.129 127.47,108.297 127.619,107.476C127.825,106.34 128.129,104.669 128.397,103.189Z" />
    ),
    basic: {
      dark: (
        <path d="M125.064,106.206L124.818,106.562L136.524,114.669L136.771,114.313L125.064,106.206ZM125.971,104.897L125.197,106.015L136.903,114.121L137.677,113.004L125.971,104.897Z" />
      ),
      light: (
        <path d="M129.037,100.468L128.01,101.952L139.717,110.058L140.744,108.575L129.037,100.468ZM127.421,102.802L126.918,103.529L138.625,111.635L139.128,110.909L127.421,102.802Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M371.603,103.189C371.474,102.478 370.992,101.881 370.325,101.605C369.657,101.328 368.895,101.409 368.301,101.821C366.266,103.23 363.493,105.15 361.458,106.559C360.864,106.97 360.52,107.655 360.544,108.377C360.568,109.1 360.957,109.76 361.577,110.131C362.868,110.903 364.326,111.775 365.316,112.368C366.032,112.796 366.932,112.766 367.618,112.291C368.722,111.527 370.396,110.368 371.5,109.604C372.185,109.129 372.53,108.297 372.381,107.476C372.175,106.34 371.871,104.669 371.603,103.189Z" />
    ),
    basic: {
      dark: (
        <path d="M374.936,106.206L375.182,106.562L363.476,114.669L363.229,114.313L374.936,106.206ZM374.029,104.897L374.803,106.015L363.097,114.121L362.323,113.004L374.029,104.897Z" />
      ),
      light: (
        <path d="M370.963,100.468L371.99,101.952L360.283,110.058L359.256,108.575L370.963,100.468ZM372.579,102.802L373.082,103.529L361.375,111.635L360.872,110.909L372.579,102.802Z" />
      ),
    },
  },
};

const shapeEar29 = {
  left: {
    base: (
      <path d="M79.608,167.058C79.515,166.236 79.847,165.424 80.488,164.901C81.128,164.379 81.991,164.218 82.777,164.473C85.47,165.348 89.141,166.541 91.834,167.417C92.621,167.672 93.223,168.31 93.435,169.109C93.646,169.909 93.436,170.761 92.878,171.371C91.717,172.642 90.406,174.077 89.515,175.052C88.872,175.757 87.875,176.02 86.967,175.725C85.506,175.25 83.291,174.53 81.83,174.055C80.922,173.76 80.271,172.961 80.164,172.013C80.017,170.701 79.8,168.769 79.608,167.058Z" />
    ),
    basic: {
      dark: (
        <path d="M76.946,171.461L76.793,171.932L92.289,176.969L92.443,176.497L76.946,171.461ZM77.51,169.728L77.029,171.208L92.525,176.244L93.006,174.765L77.51,169.728Z" />
      ),
      light: (
        <path d="M79.415,163.866L78.777,165.83L94.273,170.866L94.911,168.902L79.415,163.866ZM78.411,166.955L78.098,167.917L93.594,172.954L93.907,171.991L78.411,166.955Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M420.392,167.058C420.485,166.236 420.153,165.424 419.512,164.901C418.872,164.379 418.009,164.218 417.223,164.473C414.53,165.348 410.859,166.541 408.166,167.417C407.379,167.672 406.777,168.31 406.565,169.109C406.354,169.909 406.564,170.761 407.122,171.371C408.283,172.642 409.594,174.077 410.485,175.052C411.128,175.757 412.125,176.02 413.033,175.725C414.494,175.25 416.709,174.53 418.17,174.055C419.078,173.76 419.729,172.961 419.836,172.013C419.983,170.701 420.2,168.769 420.392,167.058Z" />
    ),
    basic: {
      dark: (
        <path d="M423.054,171.461L423.207,171.932L407.711,176.969L407.557,176.497L423.054,171.461ZM422.49,169.728L422.971,171.208L407.475,176.244L406.994,174.765L422.49,169.728Z" />
      ),
      light: (
        <path d="M420.585,163.866L421.223,165.83L405.727,170.866L405.089,168.902L420.585,163.866ZM421.589,166.955L421.902,167.917L406.406,172.954L406.093,171.991L421.589,166.955Z" />
      ),
    },
  },
};

const shapeEar30 = {
  left: {
    base: (
      <path d="M166.294,113.353C166.996,113.524 167.737,113.326 168.26,112.829C168.784,112.331 169.019,111.601 168.885,110.891C168.424,108.46 167.795,105.146 167.334,102.714C167.199,102.004 166.713,101.411 166.043,101.14C165.374,100.869 164.612,100.956 164.021,101.372C162.791,102.238 161.402,103.216 160.458,103.881C159.776,104.361 159.438,105.196 159.594,106.015C159.844,107.335 160.223,109.334 160.473,110.654C160.629,111.473 161.249,112.126 162.06,112.323C163.181,112.596 164.832,112.998 166.294,113.353Z" />
    ),
    basic: {
      dark: (
        <path d="M162.183,115.174L161.757,115.254L159.103,101.264L159.529,101.184L162.183,115.174ZM163.747,114.877L162.411,115.13L159.758,101.14L161.093,100.887L163.747,114.877Z" />
      ),
      light: (
        <path d="M169.039,113.873L167.267,114.209L164.613,100.219L166.386,99.883L169.039,113.873ZM166.251,114.402L165.382,114.567L162.728,100.577L163.597,100.412L166.251,114.402Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M333.706,113.353C333.004,113.524 332.263,113.326 331.74,112.829C331.216,112.331 330.981,111.601 331.115,110.891C331.576,108.46 332.205,105.146 332.666,102.714C332.801,102.004 333.287,101.411 333.957,101.14C334.626,100.869 335.388,100.956 335.979,101.372C337.209,102.238 338.598,103.216 339.542,103.881C340.224,104.361 340.562,105.196 340.406,106.015C340.156,107.335 339.777,109.334 339.527,110.654C339.371,111.473 338.751,112.126 337.94,112.323C336.819,112.596 335.168,112.998 333.706,113.353Z" />
    ),
    basic: {
      dark: (
        <path d="M337.817,115.174L338.243,115.254L340.897,101.264L340.471,101.184L337.817,115.174ZM336.253,114.877L337.589,115.13L340.242,101.14L338.907,100.887L336.253,114.877Z" />
      ),
      light: (
        <path d="M330.961,113.873L332.733,114.209L335.387,100.219L333.614,99.883L330.961,113.873ZM333.749,114.402L334.618,114.567L337.272,100.577L336.403,100.412L333.749,114.402Z" />
      ),
    },
  },
};

const shapeEar31 = {
  left: {
    base: (
      <path d="M123.99,197.318C124.088,196.32 124.685,195.441 125.576,194.983C126.468,194.525 127.53,194.551 128.398,195.052C131.37,196.768 135.42,199.106 138.392,200.822C139.26,201.323 139.814,202.23 139.863,203.231C139.912,204.232 139.449,205.189 138.634,205.772C136.938,206.986 135.022,208.357 133.721,209.288C132.78,209.961 131.533,210.022 130.531,209.444C128.919,208.513 126.474,207.102 124.862,206.171C123.86,205.592 123.289,204.482 123.402,203.331C123.558,201.738 123.787,199.394 123.99,197.318Z" />
    ),
    basic: {
      dark: (
        <path d="M119.727,201.866L119.427,202.386L136.526,212.259L136.826,211.739L119.727,201.866ZM120.831,199.954L119.888,201.587L136.988,211.459L137.93,209.827L120.831,199.954Z" />
      ),
      light: (
        <path d="M124.566,193.486L123.315,195.652L140.414,205.525L141.665,203.358L124.566,193.486ZM122.598,196.894L121.985,197.956L139.084,207.828L139.697,206.766L122.598,196.894Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M376.01,197.318C375.912,196.32 375.315,195.441 374.424,194.983C373.532,194.525 372.47,194.551 371.602,195.052C368.63,196.768 364.58,199.106 361.608,200.822C360.74,201.323 360.186,202.23 360.137,203.231C360.088,204.232 360.551,205.189 361.366,205.772C363.062,206.986 364.978,208.357 366.279,209.288C367.22,209.961 368.467,210.022 369.469,209.444C371.081,208.513 373.526,207.102 375.138,206.171C376.14,205.592 376.711,204.482 376.598,203.331C376.442,201.738 376.213,199.394 376.01,197.318Z" />
    ),
    basic: {
      dark: (
        <path d="M380.273,201.866L380.573,202.386L363.474,212.259L363.174,211.739L380.273,201.866ZM379.169,199.954L380.112,201.587L363.012,211.459L362.07,209.827L379.169,199.954Z" />
      ),
      light: (
        <path d="M375.434,193.486L376.685,195.652L359.586,205.525L358.335,203.358L375.434,193.486ZM377.402,196.894L378.015,197.956L360.916,207.828L360.303,206.766L377.402,196.894Z" />
      ),
    },
  },
};

const shapeEar32 = {
  left: {
    base: (
      <path d="M104.193,251.936C103.605,251.516 103.272,250.825 103.307,250.104C103.342,249.382 103.742,248.727 104.367,248.366C106.511,247.129 109.432,245.442 111.575,244.205C112.201,243.843 112.967,243.825 113.61,244.155C114.252,244.485 114.683,245.12 114.754,245.839C114.9,247.336 115.066,249.027 115.178,250.175C115.259,251.005 114.848,251.806 114.125,252.224C112.962,252.895 111.2,253.913 110.037,254.584C109.314,255.001 108.415,254.957 107.736,254.472C106.798,253.8 105.416,252.812 104.193,251.936Z" />
    ),
    basic: {
      dark: (
        <path d="M105.497,256.239L105.713,256.614L118.045,249.494L117.828,249.119L105.497,256.239ZM104.7,254.86L105.38,256.037L117.712,248.918L117.032,247.74L104.7,254.86Z" />
      ),
      light: (
        <path d="M102.007,250.195L102.909,251.757L115.241,244.638L114.339,243.075L102.007,250.195ZM103.426,252.653L103.868,253.419L116.2,246.299L115.758,245.533L103.426,252.653Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M395.807,251.936C396.395,251.516 396.728,250.825 396.693,250.104C396.658,249.382 396.258,248.727 395.633,248.366C393.489,247.129 390.568,245.442 388.425,244.205C387.799,243.843 387.033,243.825 386.39,244.155C385.748,244.485 385.317,245.12 385.246,245.839C385.1,247.336 384.934,249.027 384.822,250.175C384.741,251.005 385.152,251.806 385.875,252.224C387.038,252.895 388.8,253.913 389.963,254.584C390.686,255.001 391.585,254.957 392.264,254.472C393.202,253.8 394.584,252.812 395.807,251.936Z" />
    ),
    basic: {
      dark: (
        <path d="M394.503,256.239L394.287,256.614L381.955,249.494L382.172,249.119L394.503,256.239ZM395.3,254.86L394.62,256.037L382.288,248.918L382.968,247.74L395.3,254.86Z" />
      ),
      light: (
        <path d="M397.993,250.195L397.091,251.757L384.759,244.638L385.661,243.075L397.993,250.195ZM396.574,252.653L396.132,253.419L383.8,246.299L384.242,245.533L396.574,252.653Z" />
      ),
    },
  },
};

const shapeEar33 = {
  left: {
    base: (
      <path d="M123.039,225.678C122.326,225.796 121.602,225.544 121.117,225.009C120.631,224.474 120.451,223.728 120.638,223.03C121.278,220.64 122.151,217.382 122.792,214.991C122.979,214.293 123.508,213.738 124.196,213.517C124.884,213.297 125.637,213.44 126.195,213.899C127.357,214.854 128.67,215.933 129.562,216.665C130.206,217.195 130.481,218.052 130.265,218.858C129.918,220.155 129.391,222.121 129.043,223.419C128.828,224.224 128.16,224.829 127.338,224.966C126.199,225.154 124.523,225.432 123.039,225.678Z" />
    ),
    basic: {
      dark: (
        <path d="M127.003,227.799L127.422,227.911L131.107,214.157L130.689,214.045L127.003,227.799ZM125.465,227.387L126.778,227.739L130.464,213.984L129.151,213.633L125.465,227.387Z" />
      ),
      light: (
        <path d="M120.262,225.993L122.005,226.46L125.69,212.705L123.948,212.238L120.262,225.993ZM123.004,226.727L123.858,226.956L127.543,213.202L126.689,212.973L123.004,226.727Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M376.961,225.678C377.674,225.796 378.398,225.544 378.883,225.009C379.369,224.474 379.549,223.728 379.362,223.03C378.722,220.64 377.849,217.382 377.208,214.991C377.021,214.293 376.492,213.738 375.804,213.517C375.116,213.297 374.363,213.44 373.805,213.899C372.643,214.854 371.33,215.933 370.438,216.665C369.794,217.195 369.519,218.052 369.735,218.858C370.082,220.155 370.609,222.121 370.957,223.419C371.172,224.224 371.84,224.829 372.662,224.966C373.801,225.154 375.477,225.432 376.961,225.678Z" />
    ),
    basic: {
      dark: (
        <path d="M372.997,227.799L372.578,227.911L368.893,214.157L369.311,214.045L372.997,227.799ZM374.535,227.387L373.222,227.739L369.536,213.984L370.849,213.633L374.535,227.387Z" />
      ),
      light: (
        <path d="M379.738,225.993L377.995,226.46L374.31,212.705L376.052,212.238L379.738,225.993ZM376.996,226.727L376.142,226.956L372.457,213.202L373.311,212.973L376.996,226.727Z" />
      ),
    },
  },
};

const shapeEar34 = {
  left: {
    base: (
      <path d="M138.029,238.831C137.306,238.824 136.637,238.451 136.251,237.84C135.865,237.229 135.816,236.463 136.121,235.808C137.165,233.564 138.587,230.506 139.631,228.262C139.936,227.607 140.553,227.151 141.269,227.053C141.984,226.954 142.701,227.226 143.172,227.774C144.152,228.916 145.258,230.205 146.01,231.08C146.553,231.713 146.676,232.605 146.325,233.362C145.758,234.579 144.9,236.425 144.333,237.642C143.982,238.399 143.22,238.879 142.386,238.872C141.232,238.861 139.533,238.845 138.029,238.831Z" />
    ),
    basic: {
      dark: (
        <path d="M141.567,241.605L141.96,241.787L147.966,228.876L147.573,228.694L141.567,241.605ZM140.124,240.933L141.356,241.506L147.362,228.595L146.129,228.022L140.124,240.933Z" />
      ),
      light: (
        <path d="M135.239,238.661L136.875,239.422L142.881,226.511L141.245,225.75L135.239,238.661ZM137.813,239.858L138.615,240.231L144.62,227.32L143.819,226.947L137.813,239.858Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M361.971,238.831C362.694,238.824 363.363,238.451 363.749,237.84C364.135,237.229 364.184,236.463 363.879,235.808C362.835,233.564 361.413,230.506 360.369,228.262C360.064,227.607 359.447,227.151 358.731,227.053C358.016,226.954 357.299,227.226 356.828,227.774C355.848,228.916 354.742,230.205 353.99,231.08C353.447,231.713 353.324,232.605 353.675,233.362C354.242,234.579 355.1,236.425 355.667,237.642C356.018,238.399 356.78,238.879 357.614,238.872C358.768,238.861 360.467,238.845 361.971,238.831Z" />
    ),
    basic: {
      dark: (
        <path d="M358.433,241.605L358.04,241.787L352.034,228.876L352.427,228.694L358.433,241.605ZM359.876,240.933L358.644,241.506L352.638,228.595L353.871,228.022L359.876,240.933Z" />
      ),
      light: (
        <path d="M364.761,238.661L363.125,239.422L357.119,226.511L358.755,225.75L364.761,238.661ZM362.187,239.858L361.385,240.231L355.38,227.32L356.181,226.947L362.187,239.858Z" />
      ),
    },
  },
};

const shapeEar35 = {
  left: {
    base: (
      <path d="M148.406,203.226C148.855,202.599 149.592,202.243 150.362,202.281C151.132,202.318 151.83,202.745 152.216,203.412C153.536,205.7 155.336,208.817 156.656,211.104C157.042,211.771 157.062,212.589 156.709,213.275C156.357,213.961 155.68,214.421 154.913,214.496C153.315,214.652 151.511,214.829 150.285,214.949C149.399,215.035 148.544,214.596 148.099,213.825C147.383,212.584 146.297,210.703 145.58,209.462C145.135,208.691 145.182,207.731 145.7,207.008C146.417,206.006 147.472,204.532 148.406,203.226Z" />
    ),
    basic: {
      dark: (
        <path d="M143.815,204.618L143.414,204.849L151.012,218.008L151.412,217.777L143.815,204.618ZM145.286,203.768L144.03,204.493L151.627,217.653L152.884,216.927L145.286,203.768Z" />
      ),
      light: (
        <path d="M150.264,200.894L148.597,201.857L156.194,215.016L157.862,214.053L150.264,200.894ZM147.641,202.408L146.824,202.88L154.422,216.039L155.238,215.568L147.641,202.408Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M351.594,203.226C351.145,202.599 350.408,202.243 349.638,202.281C348.868,202.318 348.17,202.745 347.784,203.412C346.464,205.7 344.664,208.817 343.344,211.104C342.958,211.771 342.938,212.589 343.291,213.275C343.643,213.961 344.32,214.421 345.087,214.496C346.685,214.652 348.489,214.829 349.715,214.949C350.601,215.035 351.456,214.596 351.901,213.825C352.617,212.584 353.703,210.703 354.42,209.462C354.865,208.691 354.818,207.731 354.3,207.008C353.583,206.006 352.528,204.532 351.594,203.226Z" />
    ),
    basic: {
      dark: (
        <path d="M356.185,204.618L356.586,204.849L348.988,218.008L348.588,217.777L356.185,204.618ZM354.714,203.768L355.97,204.493L348.373,217.653L347.116,216.927L354.714,203.768Z" />
      ),
      light: (
        <path d="M349.736,200.894L351.403,201.857L343.806,215.016L342.138,214.053L349.736,200.894ZM352.359,202.408L353.176,202.88L345.578,216.039L344.762,215.568L352.359,202.408Z" />
      ),
    },
  },
};

const shapeEar36 = {
  left: {
    base: (
      <path d="M137.589,218C137.377,217.145 137.613,216.242 138.216,215.601C138.819,214.96 139.707,214.669 140.573,214.829C143.537,215.376 147.577,216.123 150.542,216.67C151.407,216.83 152.132,217.419 152.467,218.233C152.801,219.047 152.699,219.975 152.196,220.697C151.148,222.201 149.965,223.898 149.161,225.052C148.58,225.885 147.565,226.302 146.566,226.118C144.958,225.82 142.519,225.37 140.911,225.073C139.912,224.888 139.113,224.137 138.868,223.151C138.529,221.786 138.03,219.778 137.589,218Z" />
    ),
    basic: {
      dark: (
        <path d="M135.395,223.018L135.3,223.536L152.356,226.687L152.452,226.168L135.395,223.018ZM135.748,221.11L135.447,222.739L152.503,225.889L152.804,224.261L135.748,221.11Z" />
      ),
      light: (
        <path d="M136.939,214.658L136.54,216.819L153.597,219.97L153.996,217.808L136.939,214.658ZM136.311,218.058L136.116,219.117L153.172,222.267L153.368,221.208L136.311,218.058Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M362.411,218C362.623,217.145 362.387,216.242 361.784,215.601C361.181,214.96 360.293,214.669 359.427,214.829C356.463,215.376 352.423,216.123 349.458,216.67C348.593,216.83 347.868,217.419 347.533,218.233C347.199,219.047 347.301,219.975 347.804,220.697C348.852,222.201 350.035,223.898 350.839,225.052C351.42,225.885 352.435,226.302 353.434,226.118C355.042,225.82 357.481,225.37 359.089,225.073C360.088,224.888 360.887,224.137 361.132,223.151C361.471,221.786 361.97,219.778 362.411,218Z" />
    ),
    basic: {
      dark: (
        <path d="M364.605,223.018L364.7,223.536L347.644,226.687L347.548,226.168L364.605,223.018ZM364.252,221.11L364.553,222.739L347.497,225.889L347.196,224.261L364.252,221.11Z" />
      ),
      light: (
        <path d="M363.061,214.658L363.46,216.819L346.403,219.97L346.004,217.808L363.061,214.658ZM363.689,218.058L363.884,219.117L346.828,222.267L346.632,221.208L363.689,218.058Z" />
      ),
    },
  },
};

const shapeEar37 = {
  left: {
    base: (
      <path d="M128.324,225.678C127.611,225.796 126.887,225.544 126.402,225.009C125.916,224.474 125.736,223.728 125.923,223.03C126.564,220.64 127.437,217.382 128.077,214.991C128.264,214.293 128.793,213.738 129.481,213.517C130.169,213.297 130.922,213.44 131.48,213.899C132.643,214.854 133.955,215.933 134.847,216.665C135.491,217.195 135.767,218.052 135.551,218.858C135.203,220.155 134.676,222.121 134.329,223.419C134.113,224.224 133.446,224.829 132.623,224.966C131.484,225.154 129.808,225.432 128.324,225.678Z" />
    ),
    basic: {
      dark: (
        <path d="M132.289,227.799L132.707,227.911L136.393,214.157L135.974,214.045L132.289,227.799ZM130.751,227.387L132.064,227.739L135.749,213.984L134.436,213.633L130.751,227.387Z" />
      ),
      light: (
        <path d="M125.547,225.993L127.29,226.46L130.976,212.705L129.233,212.238L125.547,225.993ZM128.289,226.727L129.143,226.956L132.829,213.202L131.975,212.973L128.289,226.727Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M371.676,225.678C372.389,225.796 373.113,225.544 373.598,225.009C374.084,224.474 374.264,223.728 374.077,223.03C373.436,220.64 372.563,217.382 371.923,214.991C371.736,214.293 371.207,213.738 370.519,213.517C369.831,213.297 369.078,213.44 368.52,213.899C367.357,214.854 366.045,215.933 365.153,216.665C364.509,217.195 364.233,218.052 364.449,218.858C364.797,220.155 365.324,222.121 365.671,223.419C365.887,224.224 366.554,224.829 367.377,224.966C368.516,225.154 370.192,225.432 371.676,225.678Z" />
    ),
    basic: {
      dark: (
        <path d="M367.711,227.799L367.293,227.911L363.607,214.157L364.026,214.045L367.711,227.799ZM369.249,227.387L367.936,227.739L364.251,213.984L365.564,213.633L369.249,227.387Z" />
      ),
      light: (
        <path d="M374.453,225.993L372.71,226.46L369.024,212.705L370.767,212.238L374.453,225.993ZM371.711,226.727L370.857,226.956L367.171,213.202L368.025,212.973L371.711,226.727Z" />
      ),
    },
  },
};

const shapeEar38 = {
  left: {
    base: (
      <path d="M131.361,183.423C131.632,182.701 132.251,182.166 133.005,182.003C133.759,181.84 134.544,182.071 135.089,182.616C136.956,184.484 139.501,187.029 141.369,188.896C141.914,189.441 142.145,190.226 141.982,190.98C141.819,191.734 141.284,192.353 140.562,192.624C139.059,193.189 137.362,193.826 136.21,194.259C135.376,194.572 134.437,194.369 133.808,193.74C132.794,192.726 131.259,191.19 130.245,190.177C129.616,189.548 129.413,188.608 129.726,187.775C130.159,186.622 130.796,184.925 131.361,183.423Z" />
    ),
    basic: {
      dark: (
        <path d="M127.286,185.955L126.959,186.281L137.703,197.026L138.03,196.699L127.286,185.955ZM128.487,184.753L127.461,185.779L138.206,196.523L139.232,195.498L128.487,184.753Z" />
      ),
      light: (
        <path d="M132.552,180.689L131.19,182.05L141.935,192.794L143.296,191.433L132.552,180.689ZM130.41,182.83L129.743,183.497L140.487,194.242L141.154,193.575L130.41,182.83Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M368.639,183.423C368.368,182.701 367.749,182.166 366.995,182.003C366.241,181.84 365.456,182.071 364.911,182.616C363.044,184.484 360.499,187.029 358.631,188.896C358.086,189.441 357.855,190.226 358.018,190.98C358.181,191.734 358.716,192.353 359.438,192.624C360.941,193.189 362.638,193.826 363.79,194.259C364.624,194.572 365.563,194.369 366.192,193.74C367.206,192.726 368.741,191.19 369.755,190.177C370.384,189.548 370.587,188.608 370.274,187.775C369.841,186.622 369.204,184.925 368.639,183.423Z" />
    ),
    basic: {
      dark: (
        <path d="M372.714,185.955L373.041,186.281L362.297,197.026L361.97,196.699L372.714,185.955ZM371.513,184.753L372.539,185.779L361.794,196.523L360.768,195.498L371.513,184.753Z" />
      ),
      light: (
        <path d="M367.448,180.689L368.81,182.05L358.065,192.794L356.704,191.433L367.448,180.689ZM369.59,182.83L370.257,183.497L359.513,194.242L358.846,193.575L369.59,182.83Z" />
      ),
    },
  },
};

const shapeEar39 = {
  left: {
    base: (
      <path d="M125.28,185.435C125.355,184.667 125.815,183.991 126.501,183.638C127.187,183.286 128.005,183.306 128.673,183.691C130.96,185.012 134.077,186.811 136.364,188.132C137.032,188.517 137.458,189.215 137.495,189.986C137.533,190.756 137.177,191.492 136.55,191.941C135.244,192.875 133.77,193.93 132.769,194.647C132.045,195.165 131.085,195.212 130.314,194.767C129.073,194.05 127.192,192.964 125.951,192.248C125.18,191.803 124.741,190.948 124.827,190.062C124.947,188.837 125.124,187.033 125.28,185.435Z" />
    ),
    basic: {
      dark: (
        <path d="M121.999,188.935L121.768,189.336L134.928,196.933L135.159,196.533L121.999,188.935ZM122.849,187.464L122.124,188.72L135.283,196.318L136.008,195.061L122.849,187.464Z" />
      ),
      light: (
        <path d="M125.723,182.486L124.76,184.153L137.92,191.751L138.882,190.083L125.723,182.486ZM124.209,185.109L123.737,185.926L136.896,193.523L137.368,192.706L124.209,185.109Z" />
      ),
    },
  },
  right: {
    base: (
      <path d="M374.72,185.435C374.645,184.667 374.185,183.991 373.499,183.638C372.813,183.286 371.995,183.306 371.327,183.691C369.04,185.012 365.923,186.811 363.636,188.132C362.968,188.517 362.542,189.215 362.505,189.986C362.467,190.756 362.823,191.492 363.45,191.941C364.756,192.875 366.23,193.93 367.231,194.647C367.955,195.165 368.915,195.212 369.686,194.767C370.927,194.05 372.808,192.964 374.049,192.248C374.82,191.803 375.259,190.948 375.173,190.062C375.053,188.837 374.876,187.033 374.72,185.435Z" />
    ),
    basic: {
      dark: (
        <path d="M378.001,188.935L378.232,189.336L365.072,196.933L364.841,196.533L378.001,188.935ZM377.151,187.464L377.876,188.72L364.717,196.318L363.992,195.061L377.151,187.464Z" />
      ),
      light: (
        <path d="M374.277,182.486L375.24,184.153L362.08,191.751L361.118,190.083L374.277,182.486ZM375.791,185.109L376.263,185.926L363.104,193.523L362.632,192.706L375.791,185.109Z" />
      ),
    },
  },
};

export const earring4 = {
  name: "Earring 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Smooth ears
    ear_shape_TEKWA: { ...shapeEar1 },
    ear_shape_0775v: { ...shapeEar2 },
    ear_shape_HOZ9g: { ...shapeEar3 },
    ear_shape_vBJ7j: { ...shapeEar4 },
    ear_shape_FYH9c: { ...shapeEar5 },
    ear_shape_aoGZG: { ...shapeEar6 },
    ear_shape_ZgR2p: { ...shapeEar7 },
    ear_shape_vdyix: { ...shapeEar8 },
    ear_shape_TbLPe: { ...shapeEar9 },
    ear_shape_3wnpn: { ...shapeEar10 },
    ear_shape_PDULw: { ...shapeEar11 },
    ear_shape_kOBSt: { ...shapeEar12 },
    ear_shape_yAT34: { ...shapeEar13 },
    ear_shape_8wAS4: { ...shapeEar14 },
    ear_shape_gcoC9: { ...shapeEar15 },
    ear_shape_bPDFx: { ...shapeEar16 },
    ear_shape_oEAG6: { ...shapeEar17 },
    ear_shape_Dp6oZ: { ...shapeEar18 },
    ear_shape_kf82L: { ...shapeEar19 },
    ear_shape_8sDmX: { ...shapeEar20 },
    ear_shape_k9vVR: { ...shapeEar21 },
    ear_shape_aKjrI: { ...shapeEar22 },
    ear_shape_2rsVH: { ...shapeEar23 },
    ear_shape_axjXx: { ...shapeEar24 },
    ear_shape_Zxpa2: { ...shapeEar25 },
    ear_shape_jWOL0: { ...shapeEar26 },
    ear_shape_TUUUj: { ...shapeEar27 },
    ear_shape_Wk3es: { ...shapeEar28 },
    ear_shape_XkDvO: { ...shapeEar29 },
    ear_shape_xyzCw: { ...shapeEar30 },
    ear_shape_epGZS: { ...shapeEar31 },
    ear_shape_4qBKl: { ...shapeEar32 },
    ear_shape_zwyyL: { ...shapeEar33 },
    ear_shape_kDDB4: { ...shapeEar34 },
    ear_shape_AwTvI: { ...shapeEar35 },
    ear_shape_0U6iQ: { ...shapeEar36 },
    ear_shape_BTIMM: { ...shapeEar37 },
    ear_shape_cnX1o: { ...shapeEar38 },
    ear_shape_UEVQI: { ...shapeEar39 },
    // Fuzzy ears
    ear_shape_T2K38: { ...shapeEar1 },
    ear_shape_02738: { ...shapeEar2 },
    ear_shape_H2Z38: { ...shapeEar3 },
    ear_shape_v2J38: { ...shapeEar4 },
    ear_shape_F2H38: { ...shapeEar5 },
    ear_shape_a2G38: { ...shapeEar6 },
    ear_shape_Z2R38: { ...shapeEar7 },
    ear_shape_v2y38: { ...shapeEar8 },
    ear_shape_T2L38: { ...shapeEar9 },
    ear_shape_32n38: { ...shapeEar10 },
    ear_shape_P2U38: { ...shapeEar11 },
    ear_shape_k2B38: { ...shapeEar12 },
    ear_shape_y2T38: { ...shapeEar13 },
    ear_shape_82A38: { ...shapeEar14 },
    ear_shape_g2o38: { ...shapeEar15 },
    ear_shape_b2D38: { ...shapeEar16 },
    ear_shape_o2A38: { ...shapeEar17 },
    ear_shape_D2638: { ...shapeEar18 },
    ear_shape_k2838: { ...shapeEar19 },
    ear_shape_82D38: { ...shapeEar20 },
    ear_shape_k2v38: { ...shapeEar21 },
    ear_shape_a2j38: { ...shapeEar22 },
    ear_shape_22s38: { ...shapeEar23 },
    ear_shape_a34f8: { ...shapeEar24 },
    ear_shape_Z2p38: { ...shapeEar25 },
    ear_shape_j2O38: { ...shapeEar26 },
    ear_shape_T2U38: { ...shapeEar27 },
    ear_shape_W2338: { ...shapeEar28 },
    ear_shape_X2D38: { ...shapeEar29 },
    ear_shape_x2z38: { ...shapeEar30 },
    ear_shape_e2G38: { ...shapeEar31 },
    ear_shape_42B38: { ...shapeEar32 },
    ear_shape_z2y38: { ...shapeEar33 },
    ear_shape_k2D38: { ...shapeEar34 },
    ear_shape_A2T38: { ...shapeEar35 },
    ear_shape_02638: { ...shapeEar36 },
    ear_shape_B2I38: { ...shapeEar37 },
    ear_shape_c2X38: { ...shapeEar38 },
    ear_shape_U2V38: { ...shapeEar39 },
    // Bumpy
    ear_shape_T4KrT: { ...shapeEar1 },
    ear_shape_047rT: { ...shapeEar2 },
    ear_shape_H4ZrT: { ...shapeEar3 },
    ear_shape_v4JrT: { ...shapeEar4 },
    ear_shape_F4HrT: { ...shapeEar5 },
    ear_shape_a4GrT: { ...shapeEar6 },
    ear_shape_Z4RrT: { ...shapeEar7 },
    ear_shape_v4yrT: { ...shapeEar8 },
    ear_shape_T4LrT: { ...shapeEar9 },
    ear_shape_34nrT: { ...shapeEar10 },
    ear_shape_P4UrT: { ...shapeEar11 },
    ear_shape_k4BrT: { ...shapeEar12 },
    ear_shape_y4TrT: { ...shapeEar13 },
    ear_shape_84ArT: { ...shapeEar14 },
    ear_shape_g4orT: { ...shapeEar15 },
    ear_shape_b4DrT: { ...shapeEar16 },
    ear_shape_o4ArT: { ...shapeEar17 },
    ear_shape_D46rT: { ...shapeEar18 },
    ear_shape_k48rT: { ...shapeEar19 },
    ear_shape_84DrT: { ...shapeEar20 },
    ear_shape_k4vrT: { ...shapeEar21 },
    ear_shape_a4jrT: { ...shapeEar22 },
    ear_shape_24srT: { ...shapeEar23 },
    ear_shape_a442T: { ...shapeEar24 },
    ear_shape_Z4prT: { ...shapeEar25 },
    ear_shape_j4OrT: { ...shapeEar26 },
    ear_shape_T4UrT: { ...shapeEar27 },
    ear_shape_W43rT: { ...shapeEar28 },
    ear_shape_X4DrT: { ...shapeEar29 },
    ear_shape_x4zrT: { ...shapeEar30 },
    ear_shape_e4GrT: { ...shapeEar31 },
    ear_shape_44BrT: { ...shapeEar32 },
    ear_shape_z4yrT: { ...shapeEar33 },
    ear_shape_k4DrT: { ...shapeEar34 },
    ear_shape_A4TrT: { ...shapeEar35 },
    ear_shape_046rT: { ...shapeEar36 },
    ear_shape_B4IrT: { ...shapeEar37 },
    ear_shape_c4XrT: { ...shapeEar38 },
    ear_shape_U4VrT: { ...shapeEar39 },
    // Furry
    ear_shape_1TEKWA: { ...shapeEar1 },
    ear_shape_10775v: { ...shapeEar2 },
    ear_shape_1HOZ9g: { ...shapeEar3 },
    ear_shape_1vBJ7j: { ...shapeEar4 },
    ear_shape_1FYH9c: { ...shapeEar5 },
    ear_shape_1aoGZG: { ...shapeEar6 },
    ear_shape_1ZgR2p: { ...shapeEar7 },
    ear_shape_1vdyix: { ...shapeEar8 },
    ear_shape_1TbLPe: { ...shapeEar9 },
    ear_shape_13wnpn: { ...shapeEar10 },
    ear_shape_1PDULw: { ...shapeEar11 },
    ear_shape_1kOBSt: { ...shapeEar12 },
    ear_shape_1yAT34: { ...shapeEar13 },
    ear_shape_18wAS4: { ...shapeEar14 },
    ear_shape_1gcoC9: { ...shapeEar15 },
    ear_shape_1bPDFx: { ...shapeEar16 },
    ear_shape_1oEAG6: { ...shapeEar17 },
    ear_shape_1Dp6oZ: { ...shapeEar18 },
    ear_shape_1kf82L: { ...shapeEar19 },
    ear_shape_18sDmX: { ...shapeEar20 },
    ear_shape_1k9vVR: { ...shapeEar21 },
    ear_shape_1aKjrI: { ...shapeEar22 },
    ear_shape_12rsVH: { ...shapeEar23 },
    ear_shape_1axjXx: { ...shapeEar24 },
    ear_shape_1Zxpa2: { ...shapeEar25 },
    ear_shape_1jWOL0: { ...shapeEar26 },
    ear_shape_1TUUUj: { ...shapeEar27 },
    ear_shape_1Wk3es: { ...shapeEar28 },
    ear_shape_1XkDvO: { ...shapeEar29 },
    ear_shape_1xyzCw: { ...shapeEar30 },
    ear_shape_1epGZS: { ...shapeEar31 },
    ear_shape_14qBKl: { ...shapeEar32 },
    ear_shape_1zwyyL: { ...shapeEar33 },
    ear_shape_1kDDB4: { ...shapeEar34 },
    ear_shape_1AwTvI: { ...shapeEar35 },
    ear_shape_10U6iQ: { ...shapeEar36 },
    ear_shape_1BTIMM: { ...shapeEar37 },
    ear_shape_1cnX1o: { ...shapeEar38 },
    ear_shape_1UEVQI: { ...shapeEar39 },
  },
};
