import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import FacePaint from "./CharacterFacePaint";

export const PreviewBase = (props) => {
  const { component, previewId } = props;
  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <FacePaint preview={true} previewId={previewId} facePaint={component} />
    </>
  );
};

export const previewViewbox = "150 160 200 180";
