import React from "react";
import { connect } from "react-redux";
import BackHornsList from "./BackHornsList";
import Component from "character-creator/components/Component";

function mapStateToProps(state, ownProps) {
  // Config path
  const backHornsConfig = state.config.base.head.extras.horns.back;

  // Left
  const hornConfigLeft = backHornsConfig.left;

  let hornObjectLeft = null;
  if (ownProps.preview && ownProps.horns.shape.left) {
    hornObjectLeft = ownProps.horns.shape.left;
  } else if (hornConfigLeft.shape !== "0") {
    hornObjectLeft = BackHornsList[hornConfigLeft.shape].shape.left;
  }

  const coloursLeft = hornConfigLeft.colour
    ? hornConfigLeft.colour
    : state.config.base.skintone;

  // Right
  const hornConfigRight = backHornsConfig.right;

  let hornObjectRight = null;
  if (ownProps.preview && ownProps.horns.shape.right) {
    hornObjectRight = ownProps.horns.shape.right;
  } else if (hornConfigRight.shape !== "0") {
    hornObjectRight = BackHornsList[hornConfigRight.shape].shape.right;
  }

  const coloursRight = hornConfigRight.colour
    ? hornConfigRight.colour
    : state.config.base.skintone;

  return {
    left: {
      shape: hornObjectLeft,
      colour: coloursLeft,
    },
    right: {
      shape: hornObjectRight,
      colour: coloursRight,
    },
  };
}

const BackHorns = (props) => {
  const { left, right, previewId } = props;

  if (left.shape || right.shape) {
    return (
      <g id={previewId ? `g-horns-back~${previewId}` : "g-horns-back"}>
        {left.shape && (
          <Component
            id={previewId ? `horns-back-left~${previewId}` : "horns-back-left"}
            component={left}
            colour={left.colour}
            preview={previewId ? true : false}
          />
        )}
        {right.shape && (
          <Component
            id={
              previewId ? `horns-back-right~${previewId}` : "horns-back-right"
            }
            component={right}
            colour={right.colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BackHorns);
