import React from "react";
import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterHairSide from "./CharacterHairSide";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHairSide
        preview={true}
        previewId={previewId}
        hair={component}
      />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewbox = "0 0 500 500";

export const viewboxLeft = "40 150 300 350";
export const viewboxLeftAboveShoulderClose = "70 140 160 250";

export const viewboxRight = "290 150 300 350";
export const viewboxRightAboveShoulderClose = "300 140 160 250";
