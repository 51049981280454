import React from "react";
import { PreviewBase, viewbox } from "../../Preview";

const jumper_7EjQbQZLQ4_AC = {
  base: (
    <path d="M161.343 385.628c-.07-.432-.09-.877-.054-1.323.144-1.801 1.165-3.416 2.729-4.319 6.493-3.748 16.163-9.331 21.51-12.386 2.199-1.269 4.983-.856 6.719.997 11.762 12.12 33.23 20.232 57.764 20.232 24.534 0 46.002-8.112 57.743-20.251 1.745-1.863 4.544-2.279 6.754-1.002 5.333 3.079 15.003 8.662 21.485 12.429 1.558.899 2.575 2.508 2.718 4.301.036.446.016.89-.055 1.322 18.379 6.407 40.385 11.421 59.961 15.109 29.854 5.666 51.526 31.665 51.726 62.051.147 19.696.262 37.212.262 37.212H49.395s.115-17.516.262-37.212c.2-30.386 21.872-56.385 51.726-62.051 19.574-3.687 41.576-8.701 59.96-15.109Z" />
  ),
  basic: {
    dark: (
      <path d="M67.712 500c6.465-3.968 13.049-5.771 19.001-10.368 24.466-18.899 13.369-25.768 23.045-33.397 2.232-1.76 5.259.707 7.772.188 2.188-.451 4.733-3.116 6.847-2.098 9.441 4.547-7.541 22.577-7.566 37.098-.006 3.297-.15 6.134-.402 8.577H67.712Zm315.879 0h48.697c-6.465-3.968-13.049-5.771-19.001-10.368-24.466-18.899-13.369-25.768-23.045-33.397-2.232-1.76-5.259.707-7.772.188-2.188-.451-4.733-3.116-6.847-2.098-9.441 4.547 7.541 22.577 7.566 37.098.006 3.297.15 6.134.402 8.577Zm-44.935-114.372c1.357.473 2.734.938 4.129 1.396.147 2.353-.635 4.647-2.093 6.137-21.034 20.928-53.832 34.387-90.68 34.387-36.848 0-69.647-13.459-90.696-34.364-1.463-1.496-2.248-3.798-2.101-6.16 1.395-.457 2.771-.923 4.128-1.396.214 1.311.9 2.517 1.954 3.374 20.125 15.91 51.484 26.154 86.714 26.154 35.231 0 66.59-10.244 86.7-26.171 1.049-.853 1.731-2.053 1.945-3.357Z" />
    ),
    darker: (
      <path d="M110.675 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm281.77 0c-1.2-13.01-2.45-22.19-2.45-22.19l-4.48-14.22c1.87 9.69 3.04 22.75 3.81 36.41h3.12Zm-61.984-106.487c-.007 1.385-.673 2.703-1.829 3.585-18.237 13.551-46.674 22.267-78.622 22.267s-60.385-8.716-78.635-22.252c-1.159-.885-1.827-2.207-1.836-3.595 20.073 13.306 48.698 21.638 80.472 21.638 31.767 0 60.387-8.329 80.45-21.643Z" />
    ),
  },
};

const jumper_7EjQbQZLQ4_B = {
  base: (
    <path d="M162.441 371.099c-.842-1.074-1.263-2.437-1.152-3.826.144-1.801 1.165-3.416 2.729-4.319 6.493-3.748 16.163-9.331 21.51-12.385 2.199-1.27 4.983-.857 6.719.996 11.762 12.12 33.23 20.232 57.764 20.232 24.534 0 46.002-8.112 57.743-20.251 1.745-1.863 4.544-2.278 6.754-1.002 5.333 3.079 15.003 8.662 21.485 12.429 1.558.9 2.575 2.508 2.718 4.301.111 1.389-.312 2.752-1.157 3.823 18.602 8.279 41.096 14.735 61.063 19.458 29.854 7.116 51.526 39.761 51.726 77.917.147 24.731.262 46.726.262 46.726H49.395s.115-21.995.262-46.726c.2-38.156 21.872-70.801 51.726-77.917 19.967-4.723 42.461-11.179 61.058-19.456Z" />
  ),
  basic: {
    dark: (
      <path d="M67.712 500c6.465-3.968 13.049-5.771 19.001-10.368 24.466-18.899 13.369-25.768 23.045-33.397 2.232-1.76 5.259.707 7.772.188 2.188-.451 4.733-3.116 6.847-2.098 9.441 4.547-7.541 22.577-7.566 37.098-.006 3.297-.15 6.134-.402 8.577H67.712Zm315.879 0h48.697c-6.465-3.968-13.049-5.771-19.001-10.368-24.466-18.899-13.369-25.768-23.045-33.397-2.232-1.76-5.259.707-7.772.188-2.188-.451-4.733-3.116-6.847-2.098-9.441 4.547 7.541 22.577 7.566 37.098.006 3.297.15 6.134.402 8.577Zm-44.935-131.404c1.357.473 2.734.939 4.129 1.397.147 2.352-.635 4.646-2.093 6.137-21.034 20.927-53.832 34.386-90.68 34.386-36.848 0-69.647-13.459-90.696-34.364-1.463-1.495-2.248-3.798-2.101-6.159 1.395-.458 2.771-.924 4.128-1.397.214 1.311.9 2.517 1.954 3.375 20.125 15.91 51.484 26.153 86.714 26.153 35.231 0 66.59-10.243 86.7-26.171 1.049-.853 1.731-2.052 1.945-3.357Z" />
    ),
    darker: (
      <path d="M110.675 500c.77-13.66 1.94-26.72 3.81-36.41l-4.48 14.22s-1.25 9.19-2.45 22.19h3.12Zm281.77 0c-1.2-13.01-2.45-22.19-2.45-22.19l-4.48-14.22c1.87 9.69 3.04 22.75 3.81 36.41h3.12Zm-61.984-123.519c-.007 1.385-.673 2.703-1.829 3.586-18.237 13.551-46.674 22.266-78.622 22.266s-60.385-8.715-78.635-22.252c-1.159-.885-1.827-2.206-1.836-3.595 20.073 13.306 48.698 21.638 80.472 21.638 31.767 0 60.387-8.328 80.45-21.643Z" />
    ),
  },
};

const jumper_7EjQbQZLQ4 = {
  name: "Jumper 1",
  description: "Some clothing",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    // Body 1A
    base_body_shape_lcUGV: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 1B
    base_body_shape_taw74: {
      ...jumper_7EjQbQZLQ4_B,
    },
    // Body 1C
    base_body_shape_jnr29: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 2A
    base_body_shape_g825h: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 2B
    base_body_shape_fa048: {
      ...jumper_7EjQbQZLQ4_B,
    },
    // Body 2C
    base_body_shape_mge5o: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 3A
    base_body_shape_gj245: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 3B
    base_body_shape_942n5: {
      ...jumper_7EjQbQZLQ4_B,
    },
    // Body 3C
    base_body_shape_0424n: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 4A
    base_body_shape_fds8g: {
      ...jumper_7EjQbQZLQ4_AC,
    },
    // Body 4B
    base_body_shape_sg894: {
      ...jumper_7EjQbQZLQ4_B,
    },
    // Body 4C
    base_body_shape_fa84m: {
      ...jumper_7EjQbQZLQ4_AC,
    },
  },
};

export const JumpersPack1 = { jumper_7EjQbQZLQ4 };
