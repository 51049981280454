import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { BodyScarsList } from "./BodyScarsList";
import { buildTransform } from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const scarConfig = state.config.base.body.scars;
  const scar1Config = scarConfig.scar1;
  const scar2Config = scarConfig.scar2;
  const scar3Config = scarConfig.scar3;

  // Scar 1
  const scar1Object =
    ownProps.preview && ownProps.scar
      ? ownProps.scar
      : BodyScarsList[scar1Config.shape];

  let scar1Shape = scar1Object.shape;
  if (scar1Object.shape.left) {
    scar1Shape = {
      base: (
        <>
          {scar1Object.shape.left.base}
          {scar1Object.shape.right.base}
        </>
      ),
    };
  }

  const scar1Colours = scar1Config.colour
    ? scar1Config.colour
    : { base: state.config.base.skintone.light };

  const scar1Transform = buildTransform(scar1Config.transform);

  // Scar 2
  const scar2Object = BodyScarsList[scar2Config.shape];

  let scar2Shape = scar2Object.shape;
  if (scar2Object.shape.left) {
    scar2Shape = {
      base: (
        <>
          {scar2Object.shape.left.base}
          {scar2Object.shape.right.base}
        </>
      ),
    };
  }

  const scar2Colours = scar2Config.colour
    ? scar2Config.colour
    : { base: state.config.base.skintone.light };

  const scar2Transform = buildTransform(scar2Config.transform);

  // Scar 3
  const scar3Object = BodyScarsList[scar3Config.shape];

  let scar3Shape = scar3Object.shape;
  if (scar3Object.shape.left) {
    scar3Shape = {
      base: (
        <>
          {scar3Object.shape.left.base}
          {scar3Object.shape.right.base}
        </>
      ),
    };
  }

  const scar3Colours = scar3Config.colour
    ? scar3Config.colour
    : { base: state.config.base.skintone.light };

  const scar3Transform = buildTransform(scar3Config.transform);

  return {
    Scar1: {
      shape: { shape: scar1Shape },
      colour: scar1Colours,
      transform: scar1Transform,
    },
    Scar2: {
      shape: { shape: scar2Shape },
      colour: scar2Colours,
      transform: scar2Transform,
    },
    Scar3: {
      shape: { shape: scar3Shape },
      colour: scar3Colours,
      transform: scar3Transform,
    },
  };
}

const BodyScars = (props) => {
  const { Scar1, Scar2, Scar3, previewId, alternateId, alternateMask } = props;

  let componentId = "bodyScars";
  let maskId = previewId ? `body~${previewId}_mask` : "body_mask";

  if (alternateId) {
    componentId = alternateId;
  }
  if (alternateMask) {
    maskId = alternateMask;
  }

  if (Scar1.shape || Scar2.shape || Scar3.shape) {
    return (
      <g
        id={previewId ? `g-${componentId}~${previewId}` : `g-${componentId}`}
        style={{
          mask: `url(#${maskId})`,
        }}
      >
        {Scar1.shape && (
          <Component
            id={previewId ? `body-scar1~${previewId}` : "body-scar1"}
            component={Scar1.shape}
            colour={Scar1.colour}
            transform={Scar1.transform}
            previewTransform={false}
          />
        )}
        {!previewId && Scar2.shape && (
          <Component
            id={previewId ? `body-scar2~${previewId}` : "body-scar2"}
            component={Scar2.shape}
            colour={Scar2.colour}
            transform={Scar2.transform}
          />
        )}
        {!previewId && Scar3.shape && (
          <Component
            id={previewId ? `body-scar3~${previewId}` : "body-scar3"}
            component={Scar3.shape}
            colour={Scar3.colour}
            transform={Scar3.transform}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(BodyScars);
