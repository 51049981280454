import React from "react";
import {
  viewboxTall,
  viewbox,
  viewboxTallWide,
  viewboxExtraTall,
  viewboxWide,
  viewboxExtraTallWide,
} from "../../Viewboxes";

export const horn_gzKJD = {
  name: "Horn 1",
  description: "Slim, mid-length inward curving horns on the top of the head",
  tags: ["slim", "top", "medium", "curved"],
  preview: {
    viewbox: viewboxTall,
  },
  shape: {
    left: {
      base: (
        <path d="M211.36 189.99c.01-9.39-7.41-17.02-17-18.43-15.5-5.24-24.11-34.59-.84-50.8 9.44-4.82 10.02-11.45-3.98-8.63-41.9 7.38-48.66 88.02 1.85 96.49 10.6.17 20.01-8.21 19.97-18.63z" />
      ),
      basic: {
        dark: (
          <path d="M160.42 169.2c1.03 2.93 2.29 5.8 4.05 8.36 1.65 2.41 3.72 4.51 6 6.33 8.36 6.72 19.9 9.63 30.23 6.76 4.02-1.12 8.13-3.42 9.51-7.36 1.12.92 1.51 2.45 1.7 3.89 1.35 10.28-5.2 21.06-14.94 24.59-6.4 2.32-13.65 1.66-19.93-.98s-11.65-7.11-16.13-12.24c-2.39-2.73-4.56-5.68-6.16-8.94-1.52-3.1-2.5-6.43-3.36-9.77-1.73-6.76-2.99-13.78-2.06-20.69.5-3.72 1.63-7.32 3.11-10.77 1.2-2.8 2.85-7.85 5.31-9.75 3.38-2.61.49 4.54.24 6.02-.65 3.82-.74 7.72-.4 11.57.39 4.43 1.36 8.79 2.83 12.98zm23.07-41.86c1.65-2.53 3.71-4.85 6.31-6.4 1.88-1.12 4-1.81 5.92-2.88 2.17-1.21 4.04-2.89 5.88-4.55-1.68 5.15-6.15 8.78-9.54 12.99-4.66 5.79-7.33 12.83-9.45 19.88-.52 1.74-2.62 7.59-3.83 2.88-.85-3.33-.41-7.41.23-10.74.76-3.97 2.27-7.8 4.48-11.18z" />
        ),
        light: (
          <path d="M166.49 128.98c-7.78 11.16-9.03 26.76-2.41 38.65 1.27 2.28 2.81 4.43 4.73 6.2.43-2.98-.34-5.98-.81-8.95-1.58-9.98.27-20.47 5.18-29.29 4.91-8.83 12.84-15.94 22.15-19.85 1.53-.64 8.21-2.05 5.17-3.73-2.35-1.3-6.66.03-9.07.58-6.41 1.46-12.5 4.37-17.61 8.52-2.78 2.28-5.27 4.92-7.33 7.87z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M288.64 189.99c-.01-9.39 7.41-17.02 17-18.43 15.5-5.24 24.11-34.59.84-50.8-9.44-4.82-10.02-11.45 3.98-8.63 41.9 7.38 48.66 88.02-1.85 96.49-10.6.17-20.01-8.21-19.97-18.63z" />
      ),
      basic: {
        dark: (
          <path d="M339.89 169.46c-1.03 2.93-2.29 5.8-4.05 8.36-1.65 2.41-3.72 4.51-6 6.33-8.36 6.72-19.9 9.63-30.23 6.76-4.02-1.12-8.13-3.42-9.51-7.36-1.12.92-1.51 2.45-1.7 3.89-1.35 10.28 5.2 21.06 14.94 24.59 6.4 2.32 13.65 1.66 19.93-.98 6.28-2.63 11.65-7.11 16.13-12.24 2.39-2.73 4.56-5.68 6.16-8.94 1.52-3.1 2.5-6.43 3.36-9.77 1.73-6.76 2.99-13.78 2.06-20.69-.5-3.72-1.63-7.32-3.11-10.77-1.2-2.8-2.85-7.85-5.31-9.75-3.38-2.61-.49 4.54-.24 6.02.65 3.82.74 7.72.4 11.57-.38 4.44-1.36 8.8-2.83 12.98zm-23.06-41.85c-1.65-2.53-3.71-4.85-6.31-6.4-1.88-1.12-4-1.81-5.92-2.88-2.17-1.21-4.04-2.89-5.88-4.55 1.68 5.15 6.15 8.78 9.54 12.99 4.66 5.79 7.33 12.83 9.45 19.88.52 1.74 2.62 7.59 3.83 2.88.85-3.33.41-7.41-.23-10.74-.76-3.98-2.27-7.8-4.48-11.18z" />
        ),
        light: (
          <path d="M333.82 129.25c7.78 11.16 9.03 26.76 2.41 38.65-1.27 2.28-2.81 4.43-4.73 6.2-.43-2.98.34-5.98.81-8.95 1.58-9.98-.27-20.47-5.18-29.29-4.91-8.83-12.84-15.94-22.15-19.85-1.53-.64-8.21-2.05-5.17-3.73 2.35-1.3 6.66.03 9.07.58 6.41 1.46 12.5 4.37 17.61 8.52 2.79 2.28 5.27 4.92 7.33 7.87z" />
        ),
      },
    },
  },
};

export const horn_WDF2W = {
  name: "Horn 2",
  description:
    "Thick, short horns that curve out and down from the top of the head",
  tags: ["top", "thick", "short", "curved"],
  preview: { viewbox: viewbox },
  shape: {
    left: {
      base: (
        <path d="M130.75 206.75c3.21 10.37 6.96 8.75 8.6.87 6.76-20.86 28.55-21.14 37.14-13.85.31.19 2.24 1.99 2.97 2.64 9.44 8.3 25.2 7.9 35.2-.91 5.52-4.86 8.13-11.27 7.77-17.45-.13-2.3-.67-4.56-1.64-6.69 0-.02-.01-.03-.01-.04-1.24-2.12-3.01-4.54-5.24-6.93 0 0 0-.01-.01-.01-1.58-1.68-3.4-3.36-5.43-4.91-9.37-7.73-23.66-12.6-33.36-11.17-34.1 3.34-53.33 31.86-45.99 58.45z" />
      ),
      basic: {
        dark: (
          <path d="M225.66 180.46c-.13-1.62-1.66-2.3-2.71-.97-9.08 13.26-27.45 11.28-38.38 1.35.42-3.74-.37-7.58-1.62-11.1-2.65.7-3.58 4.16-6.02 5.36-9.65 3.69-19.58.09-28.41 8.49-.49-2.06-2.63-3.03-4.44-3.71-2.4 1.9.09 5.82-1.7 8.33-3.61 3.24-15.23 7.49-13.43-1.15 2.39-5.59-1.31-6.58-3.17-1.42-8.38 22.91 8.54 42.6 32.45 34.72 17.49-4.7 38.18-3.96 52.29-16.65 6.22-6.32 16.03-13.54 15.14-23.25z" />
        ),
        light: (
          <path d="M221.25 171.57c.17 2.59-1.13 5.15-3.07 6.88s-4.46 2.7-7.01 3.18c-.59.11-1.25.18-1.76-.13-.79-.48-.85-1.65-.48-2.5.37-.85 1.05-1.52 1.5-2.34 1.01-1.85.66-4.21-.39-6.04s-2.69-3.24-4.34-4.56c-6.59-5.29-14.16-10.04-22.6-10.62-.64-.04-1.33-.05-1.85.32-1.24.88-.6 3.1-1.75 4.1-1.09.95-2.74.07-4.01-.63-3.88-2.13-8.62-2.28-12.92-1.21s-8.23 3.26-11.97 5.63c-2.3 1.45-4.57 2.99-6.46 4.94-5.1 5.23-7.17 13.01-12.8 17.65-.18.15-.38.3-.61.34-.79.13-1.13-.98-1.1-1.78.28-7.48 4.03-14.53 9.18-19.95 5.16-5.42 11.65-9.38 18.32-12.78 5.41-2.76 11.08-5.22 17.11-5.95 7.15-.86 14.35.77 21.32 2.62 3.23.86 6.48 1.78 9.36 3.46 3.57 2.07 6.49 5.22 9.35 8.16 2.85 2.95 6.7 6.89 6.98 11.21z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M369.26 206.8c-3.21 10.37-6.96 8.75-8.6.87-6.76-20.86-28.55-21.14-37.14-13.85-.31.19-2.24 1.99-2.97 2.64-9.44 8.3-25.2 7.9-35.2-.91-5.52-4.86-8.13-11.27-7.77-17.45.13-2.3.67-4.56 1.64-6.69 0-.02.01-.03.01-.04 1.24-2.12 3.01-4.54 5.24-6.93 0 0 0-.01.01-.01 1.58-1.68 3.4-3.36 5.43-4.91 9.37-7.73 23.66-12.6 33.36-11.17 34.1 3.34 53.33 31.85 45.99 58.45z" />
      ),
      basic: {
        dark: (
          <path d="M274.35 180.51c.13-1.62 1.66-2.3 2.71-.97 9.08 13.26 27.45 11.28 38.38 1.35-.42-3.74.37-7.58 1.62-11.1 2.65.7 3.58 4.16 6.02 5.36 9.65 3.69 19.58.09 28.41 8.49.49-2.06 2.63-3.03 4.44-3.71 2.4 1.9-.09 5.82 1.7 8.33 3.61 3.24 15.23 7.49 13.43-1.15-2.39-5.59 1.31-6.58 3.17-1.42 8.38 22.91-8.54 42.6-32.45 34.72-17.49-4.7-38.18-3.96-52.29-16.65-6.22-6.32-16.03-13.54-15.14-23.25z" />
        ),
        light: (
          <path d="M278.76 171.62c-.17 2.59 1.13 5.15 3.07 6.88s4.46 2.7 7.01 3.18c.59.11 1.25.18 1.76-.13.79-.48.85-1.65.48-2.5s-1.05-1.52-1.5-2.34c-1.01-1.85-.66-4.21.39-6.04s2.69-3.24 4.34-4.56c6.59-5.29 14.16-10.04 22.6-10.62.64-.04 1.33-.05 1.85.32 1.24.88.6 3.1 1.75 4.1 1.09.95 2.74.07 4.01-.63 3.88-2.13 8.62-2.28 12.92-1.21s8.23 3.26 11.97 5.63c2.3 1.45 4.57 2.99 6.46 4.94 5.1 5.23 7.17 13.01 12.8 17.65.18.15.38.3.61.34.79.13 1.13-.98 1.1-1.78-.28-7.48-4.03-14.53-9.18-19.95-5.16-5.42-11.65-9.38-18.32-12.78-5.41-2.76-11.08-5.22-17.11-5.95-7.15-.86-14.35.77-21.32 2.62-3.23.86-6.48 1.78-9.36 3.46-3.57 2.07-6.49 5.22-9.35 8.16-2.85 2.95-6.69 6.88-6.98 11.21z" />
        ),
      },
    },
  },
};

export const horn_5hJxn = {
  name: "Horn 3",
  description:
    "Thick and long horns that curve downwards from the top of the head down the sides of the head",
  tags: ["thick", "long", "curved", "top", "side"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M157.85 324.57c25.17 9.13 25.57-2.27 9.06-13.44-39.81-36.45-11.61-87.63 18.98-89.52 1.31.16 2.65.24 4.01.24 18.39 0 33.3-14.63 33.3-32.68 0-8.98-3.69-17.11-9.66-23.02-5.1-5.04-11.85-8.46-19.4-9.4-.06 0-.12-.01-.18-.02-1.52-.13-3.1-.21-4.7-.23h-.22c-8.4-.09-17.64 1.38-25.46 5.45-72.68 36.58-67.88 136.59-5.73 162.62z" />
      ),
      basic: {
        dark: (
          <path d="M171.46 316.65c-5.52-1.79-11.35-2.45-16.79-4.46-11.15-4.12-19.3-13.64-26.61-23.02-4.08-5.24-8.09-10.6-11.13-16.51-5.52-10.74-7.62-22.87-9.65-34.77-1.48 31.07 9.8 63.12 32.5 84.38 7.91 7.41 17.69 13.67 28.51 14.12 7.66.31 17.52-5.9 12.28-14.71-1.77-2.98-6.06-4.04-9.11-5.03zm52.07-132.44c-.97 6.77-4.85 13.08-10.45 17-5.6 3.93-12.85 5.42-19.55 4.03-4.91-1.02-9.87-3.52-14.7-2.17-2.04.57-3.83 1.78-5.52 3.06-.51.38-1.01.78-1.51 1.18-.3-.23-.61-.44-.93-.64-1.85-1.13-3.91-1.92-6.05-2.3-1.22-.22-2.75-.16-3.34.93-.61 1.13.25 2.45.74 3.65.76 1.87.57 3.97.36 5.97-.05.49-.1.98-.17 1.46-6.25 7.29-11.02 15.86-13.83 25.05-2.99 9.79-3.74 20.25-2.27 30.37.46 3.17.59 10.52 4.49 11.15 3.93.63 10.01-4.29 13.06-6.29 13.86-9.06 27.05-19.33 38-31.75 10.95-12.42 19.63-27.08 23.63-43.15 1.49-5.95 2.09-12.95-1.96-17.55z" />
        ),
        light: (
          <path d="M213.4 163.93c5.56 6.17 7.95 15.25 5.48 23.18-.45 1.44-1.34 3.06-2.85 3.16-1.79.11-2.83-1.91-3.55-3.55-4.72-10.76-15.94-18.35-27.68-18.73-3.88-.12-8.03.6-10.87 3.25 4.7 2.36 8.2 6.97 9.22 12.13.12.62.18 1.35-.26 1.8-.33.33-.83.4-1.29.41-4.43.09-7.89-3.74-12.09-5.14-4.68-1.56-9.87.07-14.12 2.59s-7.95 5.89-12.26 8.3c-.91.51-2.21.9-2.85.09-.26-.33-.32-.78-.34-1.21-.2-4.41 2.72-8.32 5.71-11.57 6.13-6.67 13.22-12.44 20.98-17.1 14.41-8.66 34.25-11.5 46.77 2.39zm-79.11 114.75c.25.3.5.59.75.88.85.99 2.4 2 3.29 1.04.4-.42.43-1.07.41-1.65-.28-6.83-5.33-12.62-6.45-19.36-.48-2.88.47-6.67 3.36-7.09 1.52-.22 3.43.51 4.38-.69.36-.46.44-1.1.31-1.66-.35-1.49-1.94-2.36-3.43-2.69s-3.09-.34-4.48-1.01c-2.3-1.12-3.4-3.76-4.01-6.24-2.62-10.77.54-21.97 2.97-32.79.99-4.4-1.66-5.58-3.97-2.17-2.44 3.58-4.39 7.51-5.87 11.58-2.97 8.22-3.98 17.14-3.04 25.82 1.66 15.22 9.65 27.66 17.59 40.2 7.71 12.18 19.58 21.76 33.1 26.76-6.58-8.1-14.03-15.49-22.17-22.02-1.66-1.33-3.36-2.63-4.75-4.23-2.54-2.92-3.93-6.65-6.13-9.83-.18-.26-.42-.55-.74-.54s-.5.55-.19.63" />
        ),
      },
    },
    right: {
      base: (
        <path d="M344.14 324.57c-25.17 9.13-25.57-2.27-9.06-13.44 39.81-36.45 11.61-87.63-18.98-89.52-1.31.16-2.65.24-4.01.24-18.39 0-33.3-14.63-33.3-32.68 0-8.98 3.69-17.11 9.66-23.02 5.1-5.04 11.85-8.46 19.4-9.4.06 0 .12-.01.18-.02 1.52-.13 3.1-.21 4.7-.23h.22c8.4-.09 17.64 1.38 25.46 5.45 72.68 36.58 67.88 136.59 5.73 162.62z" />
      ),
      basic: {
        dark: (
          <path d="M330.74 316.72c5.52-1.79 11.35-2.45 16.79-4.46 11.15-4.12 19.3-13.64 26.61-23.02 4.08-5.24 8.09-10.6 11.13-16.51 5.52-10.74 7.62-22.87 9.65-34.77 1.48 31.07-9.8 63.12-32.5 84.38-7.91 7.41-17.69 13.67-28.51 14.12-7.66.31-17.52-5.9-12.28-14.71 1.77-2.98 6.05-4.04 9.11-5.03zm-52.08-132.44c.97 6.77 4.85 13.08 10.45 17 5.6 3.93 12.85 5.42 19.55 4.03 4.91-1.02 9.87-3.52 14.7-2.17 2.04.57 3.83 1.78 5.52 3.06.51.38 1.01.78 1.51 1.18.3-.23.61-.44.93-.64 1.85-1.13 3.91-1.92 6.05-2.3 1.22-.22 2.75-.16 3.34.93.61 1.13-.25 2.45-.74 3.65-.76 1.87-.57 3.97-.36 5.97.05.49.1.98.17 1.46 6.25 7.29 11.02 15.86 13.83 25.05 2.99 9.79 3.74 20.25 2.27 30.37-.46 3.17-.59 10.52-4.49 11.15-3.93.63-10.01-4.29-13.06-6.29-13.86-9.06-27.05-19.33-38-31.75-10.95-12.42-19.63-27.08-23.63-43.15-1.48-5.95-2.08-12.95 1.96-17.55z" />
        ),
        light: (
          <path d="M288.8 164c-5.56 6.17-7.95 15.25-5.48 23.18.45 1.44 1.34 3.06 2.85 3.16 1.79.11 2.83-1.91 3.55-3.55 4.72-10.76 15.94-18.35 27.68-18.73 3.88-.12 8.03.6 10.87 3.25-4.7 2.36-8.2 6.97-9.22 12.13-.12.62-.18 1.35.26 1.8.33.33.83.4 1.29.41 4.43.09 7.89-3.74 12.09-5.14 4.68-1.56 9.87.07 14.12 2.59s7.95 5.89 12.26 8.3c.91.51 2.21.9 2.85.09.26-.33.32-.78.34-1.21.2-4.41-2.72-8.32-5.71-11.57-6.13-6.67-13.22-12.44-20.98-17.1-14.41-8.66-34.25-11.5-46.77 2.39zm79.1 114.75c-.25.3-.5.59-.75.88-.85.99-2.4 2-3.29 1.04-.4-.42-.43-1.07-.41-1.65.28-6.83 5.33-12.62 6.45-19.36.48-2.88-.47-6.67-3.36-7.09-1.52-.22-3.43.51-4.38-.69-.36-.46-.44-1.1-.31-1.66.35-1.49 1.94-2.36 3.43-2.69s3.09-.34 4.48-1.01c2.3-1.12 3.4-3.76 4.01-6.24 2.62-10.77-.54-21.97-2.97-32.79-.99-4.4 1.66-5.58 3.97-2.17 2.44 3.58 4.39 7.51 5.87 11.58 2.97 8.22 3.98 17.14 3.04 25.82-1.66 15.22-9.65 27.66-17.59 40.2-7.71 12.18-19.58 21.76-33.1 26.76 6.58-8.1 14.03-15.49 22.17-22.02 1.66-1.33 3.36-2.63 4.75-4.23 2.54-2.92 3.93-6.65 6.13-9.83.18-.26.42-.55.74-.54s.5.55.19.63" />
        ),
      },
    },
  },
};

export const horn_yi4mB = {
  name: "Horn 4",
  description: "Very small horns that curve inwards on the top of the head",
  tags: ["small", "curved", "top", "short"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M204.17 193.63c-2.72 27.17-33.68 8.67-31.8-8.85-.63-24.94 32.73-31.61 24.12-23.13-7.47 6.87-4.98 15.76-1.02 18.42 4.92 2.18 8.75 7.09 8.7 13.56z" />
      ),
      basic: {
        dark: (
          <path d="M205.28 192.81c-.08-.54-.29-1.62-.73-1.98-.86-.69-1.16.31-1.68 1.01-.04.06-.09.11-.13.17-.81 2.54-2.64 4.68-5.32 5.94-4.22 1.98-8.69.47-12.04-2.45-5.08-4.44-9.41-12.5-9.26-19.68-.06-.49-.1-.98-.13-1.48-.14-2.53.18-4.79.81-6.88-1.18 1.25-2.26 2.57-3.13 4.05-1.18 2-1.92 4.22-2.5 6.46-1.52 5.93-1.86 12.27-.06 18.12s5.91 11.13 11.54 13.52c2.27.96 4.71 1.44 7.14 1.84 2 .32 4.05.59 6.04.24 3.88-.7 7.06-3.79 8.55-7.45 1.42-3.52 1.47-7.71.9-11.43z" />
        ),
        light: (
          <path d="M189.93 159.04c-1.81.86-3.62 1.81-4.99 3.28-.63.67-1.15 1.44-1.6 2.24-2.78 4.92-2.83 11.28-.12 16.24 2.71 4.96 8.08 8.37 13.72 8.7.55.03 1.13.03 1.62-.22.49-.25.86-.82.71-1.35-.1-.35-.39-.6-.68-.82-1.57-1.19-3.45-1.97-4.77-3.43-1.04-1.16-1.63-2.64-2.2-4.09-.09-.24-.17-.54.01-.71.33-.31.75.25 1.15.46.55.29 1.24-.2 1.42-.8.18-.59.01-1.23-.11-1.84-.46-2.33-.18-4.8.77-6.97.92-2.08 2.42-3.86 3.38-5.93.73-1.58 1.66-5.31-.3-6.44-2.11-1.21-6.12.78-8.01 1.68z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M295.93 193.75c2.72 27.17 33.68 8.67 31.8-8.85.63-24.94-32.73-31.61-24.12-23.13 7.47 6.87 4.98 15.76 1.02 18.42-4.92 2.18-8.75 7.09-8.7 13.56z" />
      ),
      basic: {
        dark: (
          <path d="M294.81 192.93c.08-.54.29-1.62.73-1.98.86-.69 1.16.31 1.68 1.01.04.06.09.11.13.17.81 2.54 2.64 4.68 5.32 5.94 4.22 1.98 8.69.47 12.04-2.45 5.08-4.44 9.41-12.5 9.26-19.68.06-.49.1-.98.13-1.48.14-2.53-.18-4.79-.81-6.88 1.18 1.25 2.26 2.57 3.13 4.05 1.18 2 1.92 4.22 2.5 6.46 1.52 5.93 1.86 12.27.06 18.12s-5.91 11.13-11.54 13.52c-2.27.96-4.71 1.44-7.14 1.84-2 .32-4.05.59-6.04.24-3.88-.7-7.06-3.79-8.55-7.45-1.41-3.52-1.47-7.71-.9-11.43z" />
        ),
        light: (
          <path d="M310.17 159.16c1.81.86 3.62 1.81 4.99 3.28.63.67 1.15 1.44 1.6 2.24 2.78 4.92 2.83 11.28.12 16.24-2.71 4.96-8.08 8.37-13.72 8.7-.55.03-1.13.03-1.62-.22-.49-.25-.86-.82-.71-1.35.1-.35.39-.6.68-.82 1.57-1.19 3.45-1.97 4.77-3.43 1.04-1.16 1.63-2.64 2.2-4.09.09-.24.17-.54-.01-.71-.33-.31-.75.25-1.15.46-.55.29-1.24-.2-1.42-.8-.18-.59-.01-1.23.11-1.84.46-2.33.18-4.8-.77-6.97-.92-2.08-2.42-3.86-3.38-5.93-.73-1.58-1.66-5.31.3-6.44 2.11-1.21 6.12.79 8.01 1.68z" />
        ),
      },
    },
  },
};

export const horn_2KAy6 = {
  name: "Horn 5",
  description:
    "Slim horns that curve backwards from the top of the head then sweep up into sharp points",
  tags: ["curved", "top", "long"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M214.4 175.37c-6.26-16.18-27.56-29.41-36.6-25.76-1.13.35-2.24.87-3.29 1.55-.04.03-.08.05-.12.08-.23.15-.45.32-.68.49-.08.06-.16.11-.23.17-.2.15-.39.32-.59.49-.1.08-.19.16-.29.24-.18.16-.36.34-.53.51-.1.1-.2.19-.3.29-.18.19-.35.38-.53.58-.09.1-.18.19-.26.29-.2.24-.39.48-.59.73-.05.07-.11.13-.16.2-.5.67-.97 1.38-1.4 2.14-1.9-4.06-2.14-9.12.43-14.83 1.28-2.82 3.24-5.8 6.03-8.89 4.25-3.96 3.43-5.95-3.18-2.88-12.55 5.77-21.29 15.91-24.75 26.93-.64 2.01-1.1 4.05-1.37 6.1-.06.67-.09 1.35-.09 2.03 0 1.93.24 3.81.69 5.61h-.01c.01.03.02.06.02.08.14.55.3 1.09.48 1.63.03.1.07.2.1.3.19.54.39 1.07.62 1.6.03.06.05.12.08.18.24.54.49 1.07.77 1.58.01.01.01.02.02.04.28.52.58 1.02.9 1.52.04.07.08.13.13.2.31.48.64.95.99 1.4.04.06.09.11.13.17.72.93 1.52 1.8 2.37 2.61.05.05.11.1.16.15.41.38.84.75 1.28 1.1.07.06.14.11.22.17.45.35.91.69 1.39 1.01.03.02.07.04.11.07.46.31.94.6 1.43.87.06.03.12.07.18.1.49.27 1 .52 1.51.76.1.04.19.09.29.13.51.23 1.04.44 1.57.63.08.03.15.05.23.08.52.18 1.06.35 1.6.49.05.01.1.03.14.04.56.15 1.13.27 1.7.37l.33.06c.56.09 1.14.17 1.71.22.1.01.21.02.31.02.61.05 1.22.08 1.83.08 3.05 0 5.96-.59 8.63-1.66.2.86.4 1.72.59 2.56 3.95 16.47 10.87 21.49 22.71 17.42 11.28-4.17 19.99-15.7 13.29-32.05z" />
      ),
      basic: {
        dark: (
          <path d="M217.4 186.54c-.11-.67-.4-1.46-1.07-1.56-.74-.11-1.23.71-1.57 1.37-.77 1.53-1.8 2.93-3.02 4.12-1.55 1.51-3.46 2.71-5.58 3.16-2.12.45-4.45.1-6.19-1.2-.43-.32-.83-.71-1.06-1.19-.23-.48-.27-1.03-.35-1.55-.28-1.79-1.42-3.78-3.23-3.79-.75 0-1.45.34-2.13.66-1.38.63-2.95 1.13-4.43 1.1-1.48-2.19-2.78-4.73-3.15-7.61-2.08-6.72-5.12-26.55-14.94-16.54-3.02 3.82-2.21 10.09-6.11 13.01-7.19 4.38-14.32-5.35-16.14-11.54-1.77-4.42.11-9.17.38-13.42-.82-1.33-2.08.08-2.61.94-10.01 15.5.84 36.03 18.19 39.64 4.69 1.14 9.56 1.86 14.42 2.5 1.93 7.46 7.69 17.05 16.12 17.46 4.11.2 8.16-1.46 11.41-3.98s5.77-5.87 7.95-9.36c.97-1.56 1.88-3.16 2.48-4.89.83-2.33 1.04-4.88.63-7.33z" />
        ),
        light: (
          <path d="M213.71 171.2c-4.73-11.95-16.2-24.57-30.01-24.15-.8-3.87-6.18-3.1-9.21-3.19 19.49-29.17-11.48-7.67-13.57 6.48-.47 3-2.11 5.89-5.49 5.65-2.95 1.19 1.12 3.93 1.85 5.51 7.43 9.68 11.96-10.02 18.32-10.84 5.03-1.08 11.06.63 12.61 6.15 1.61-.17 1.75-3.09 3.6-1.59 6.15 3.92 12.09 9.11 13.99 16.15.78 3.45 2.57 3.64 4.34.73 1.69 2.63 2.32 5.75 2.89 8.71 3.59-.98 1.59-7.01.68-9.61z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M285.6 175.37c6.26-16.18 27.56-29.41 36.6-25.76 1.13.35 2.24.87 3.29 1.55.04.03.08.05.12.08.23.15.45.32.68.49.08.06.16.11.23.17.2.15.39.32.59.49.1.08.19.16.29.24.18.16.36.34.53.51.1.1.2.19.3.29.18.19.35.38.53.58.09.1.18.19.26.29.2.24.39.48.59.73.05.07.11.13.16.2.5.67.97 1.38 1.4 2.14 1.9-4.06 2.14-9.12-.43-14.83-1.28-2.82-3.24-5.8-6.03-8.89-4.25-3.96-3.43-5.95 3.18-2.88 12.55 5.77 21.29 15.91 24.75 26.93.64 2.01 1.1 4.05 1.37 6.1.06.67.09 1.35.09 2.03 0 1.93-.24 3.81-.69 5.61h.01c-.01.03-.02.06-.02.08-.14.55-.3 1.09-.48 1.63-.03.1-.07.2-.1.3-.19.54-.39 1.07-.62 1.6-.03.06-.05.12-.08.18-.24.54-.49 1.07-.77 1.58-.01.01-.01.02-.02.04-.28.52-.58 1.02-.9 1.52-.04.07-.08.13-.13.2-.31.48-.64.95-.99 1.4-.04.06-.09.11-.13.17-.72.93-1.52 1.8-2.37 2.61-.05.05-.11.1-.16.15-.41.38-.84.75-1.28 1.1-.07.06-.14.11-.22.17-.45.35-.91.69-1.39 1.01-.03.02-.07.04-.11.07-.46.31-.94.6-1.43.87-.06.03-.12.07-.18.1-.49.27-1 .52-1.51.76-.1.04-.19.09-.29.13-.51.23-1.04.44-1.57.63-.08.03-.15.05-.23.08-.52.18-1.06.35-1.6.49-.05.01-.1.03-.14.04-.56.15-1.13.27-1.7.37l-.33.06c-.56.09-1.14.17-1.71.22-.1.01-.21.02-.31.02-.61.05-1.22.08-1.83.08-3.05 0-5.96-.59-8.63-1.66-.2.86-.4 1.72-.59 2.56-3.95 16.47-10.87 21.49-22.71 17.42-11.28-4.17-19.99-15.7-13.29-32.05z" />
      ),
      basic: {
        dark: (
          <path d="M282.51 186.52c.11-.67.4-1.46 1.07-1.56.74-.11 1.23.71 1.57 1.37.77 1.53 1.8 2.93 3.02 4.12 1.55 1.51 3.46 2.71 5.58 3.16 2.12.45 4.45.1 6.19-1.2.43-.32.83-.71 1.06-1.19.23-.48.27-1.03.35-1.55.28-1.79 1.42-3.78 3.23-3.79.75 0 1.45.34 2.13.66 1.38.63 2.95 1.13 4.43 1.1 1.48-2.19 2.78-4.73 3.15-7.61 2.08-6.72 5.12-26.55 14.94-16.54 3.02 3.82 2.21 10.09 6.11 13.01 7.19 4.38 14.32-5.35 16.14-11.54 1.77-4.42-.11-9.17-.38-13.42.82-1.33 2.08.08 2.61.94 10.01 15.5-.84 36.03-18.19 39.64-4.69 1.14-9.56 1.86-14.42 2.5-1.93 7.46-7.69 17.05-16.12 17.46-4.11.2-8.16-1.46-11.41-3.98s-5.77-5.87-7.95-9.36c-.97-1.56-1.88-3.16-2.48-4.89-.82-2.33-1.04-4.88-.63-7.33z" />
        ),
        light: (
          <path d="M286.2 171.18c4.73-11.95 16.2-24.57 30.01-24.15.8-3.87 6.18-3.1 9.21-3.19-19.49-29.17 11.48-7.67 13.57 6.48.47 3 2.11 5.89 5.49 5.65 2.95 1.19-1.12 3.93-1.85 5.51-7.43 9.68-11.96-10.02-18.32-10.84-5.03-1.08-11.06.63-12.61 6.15-1.61-.17-1.75-3.09-3.6-1.59-6.15 3.92-12.09 9.11-13.99 16.15-.78 3.45-2.57 3.64-4.34.73-1.69 2.63-2.32 5.75-2.89 8.71-3.59-.98-1.59-7.01-.68-9.61z" />
        ),
      },
    },
  },
};

export const horn_a6buF = {
  name: "Horn 6",
  description:
    "Thick, short horns that curve backwards and down from the top of the head",
  tags: ["thick", "short", "top"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M201.01 207.43c-11.6 3.99-18.47-.76-22.46-16.42-.26-.72-.36-1.51-.54-2.25.01-.03.02-.06.02-.1-.43-2.84-.58-5.68-.72-8.54-3.03 4.4-4.61 10.88-1.7 18.17 1.45 2.86.79 4.26-2.31 2.46-11.14-6-14.06-24.93-6.68-39.72 1.33-2.67 3.04-5.15 5.27-7.13 8.66-7.68 18.2-3.39 24.49.72 21.04 13.06 30.2 42.76 4.63 52.81z" />
      ),
      basic: {
        dark: (
          <path d="M171.91 164.48c.59-1.64 3.1-2.4 6.14-1.01.51.24 2.89 4.37 2.91 4.93.21 4.89 1.45 3.67 3.22 8.23 1.78 4.56 6 8.6 10.9 8.4 1.41-.06 3.07-.37 4.02.68.55.61.65 1.48.95 2.25.75 1.91 2.83 3.03 4.87 3.25 2.04.21 4.08-.32 6.06-.84 1.5-.4 3-.79 4.5-1.19.38-.1.77-.2 1.16-.16 1.35.16 1.88 1.88 1.7 3.22-.33 2.57-2.02 4.74-3.69 6.73-4.63 5.52-9.83 10.95-16.58 13.45-8.17 3.02-17.26 1.28-25.7-.89-3.6-.93-7.4-2.06-9.8-4.9-1.64-1.93-2.42-4.44-3-6.91-.98-4.18-1.5-8.48-1.53-12.77-.02-2.25-.69-7.99 2.15-8.72 1.85-.48 5.79 2.08 7.67 1 2.24-1.3 4.73-2.72 5.25-5.18.78-3.67-2.66-5.48-1.2-9.57z" />
        ),
        light: (
          <path d="M187.41 153.21c2.39.65 4.76 1.8 6.24 3.79 1.54 2.05 1.96 4.71 2.06 7.27.08 1.93.02 3.95.85 5.7s2.93 3.09 4.71 2.33c1.19-.51 1.86-1.75 2.77-2.66.91-.91 2.57-1.46 3.41-.47.35.41.44.97.47 1.5.05 1.17-.18 2.36-.66 3.43.84 1 2.62.14 3.7.87.51.35.74.98.89 1.58.3 1.2.43 2.47 1.04 3.54.61 1.07 1.93 1.9 3.07 1.44 1.44-.58 1.56-2.55 1.35-4.09-1.84-12.91-10.84-24.22-22.32-30.4-2.25-1.21-4.63-2.26-7.16-2.7-6.73-1.18-13.33 2.21-18.02 6.83-1.05 1.04-5.89 5.74-1.97 6.06 1.35.11 2.71-1.35 3.81-1.97 1.47-.82 3.04-1.46 4.66-1.92 3.61-1.03 7.49-1.12 11.1-.13z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M298.99 207.59c11.6 3.99 18.47-.76 22.46-16.42.26-.72.36-1.51.54-2.25-.01-.03-.02-.06-.02-.1.43-2.84.58-5.68.72-8.54 3.03 4.4 4.61 10.88 1.7 18.17-1.45 2.86-.79 4.26 2.31 2.46 11.14-6 14.06-24.93 6.68-39.72-1.33-2.67-3.04-5.15-5.27-7.13-8.66-7.68-18.2-3.39-24.49.72-21.03 13.06-30.19 42.76-4.63 52.81z" />
      ),
      basic: {
        dark: (
          <path d="M328.09 164.64c-.59-1.64-3.1-2.4-6.14-1.01-.51.24-2.89 4.37-2.91 4.93-.21 4.89-1.45 3.67-3.22 8.23-1.78 4.56-6 8.6-10.9 8.4-1.41-.06-3.07-.37-4.02.68-.55.61-.65 1.48-.95 2.25-.75 1.91-2.83 3.03-4.87 3.25-2.04.21-4.08-.32-6.06-.84-1.5-.4-3-.79-4.5-1.19-.38-.1-.77-.2-1.16-.16-1.35.16-1.88 1.88-1.7 3.22.33 2.57 2.02 4.74 3.69 6.73 4.63 5.52 9.83 10.95 16.58 13.45 8.17 3.02 17.26 1.28 25.7-.89 3.6-.93 7.4-2.06 9.8-4.9 1.64-1.93 2.42-4.44 3-6.91.98-4.18 1.5-8.48 1.53-12.77.02-2.25.69-7.99-2.15-8.72-1.85-.48-5.79 2.08-7.67 1-2.24-1.3-4.73-2.72-5.25-5.18-.78-3.67 2.67-5.48 1.2-9.57z" />
        ),
        light: (
          <path d="M312.59 153.37c-2.39.65-4.76 1.8-6.24 3.79-1.54 2.05-1.96 4.71-2.06 7.27-.08 1.93-.02 3.95-.85 5.7s-2.93 3.09-4.71 2.33c-1.19-.51-1.86-1.75-2.77-2.66-.91-.91-2.57-1.46-3.41-.47-.35.41-.44.97-.47 1.5-.05 1.17.18 2.36.66 3.43-.84 1-2.62.14-3.7.87-.51.35-.74.98-.89 1.58-.3 1.2-.43 2.47-1.04 3.54-.61 1.07-1.93 1.9-3.07 1.44-1.44-.58-1.56-2.55-1.35-4.09 1.84-12.91 10.84-24.22 22.32-30.4 2.25-1.21 4.63-2.26 7.16-2.7 6.73-1.18 13.33 2.21 18.02 6.83 1.05 1.04 5.89 5.74 1.97 6.06-1.35.11-2.71-1.35-3.81-1.97-1.47-.82-3.04-1.46-4.66-1.92-3.61-1.03-7.49-1.12-11.1-.13z" />
        ),
      },
    },
  },
};

export const horn_XtBFK = {
  name: "Horn 7",
  description: "Mid-length, straight horns on the top of the head",
  tags: ["medium", "straight", "top"],
  preview: {
    viewbox: viewboxTall,
  },
  shape: {
    left: {
      base: (
        <path d="M208.24 209.43c-11.25 2.95-22.05-2.42-24.12-12-.25-1.11-.35-2.23-.35-3.34-2.68-38.76 4-76.31 18.28-109.95 1.33-3.09 5.6-2.06 5.38 1.18-2.25 34.63 3.69 68.05 16.21 97.98.01.01.01.02.02.04.2.49.41.98.62 1.46.23.63.42 1.27.56 1.94.15.69.25 1.39.3 2.09.68 8.92-6.48 17.86-16.9 20.6z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M194.15 99.64c2.1-6.97 4.77-14.16 10.26-18.94-2.43 7.5-4.86 15-6.7 22.66-8.62 35.84-3.74 74.76 13.46 107.36.27.51.54 1.14.24 1.63-.18.3-.53.45-.86.59-3.5 1.43-7.12 2.88-10.9 2.88-7.63 0-9.87-4.78-11.08-11.15-1.59-8.44-2.68-16.97-3.28-25.53-1.19-17.13-.4-34.4 2.36-51.35 1.54-9.51 3.71-18.92 6.5-28.15z" />
            <path d="M206.97 158.46c-4.63-15.9-5.42-32.21-4.44-48.39.6-9.94 1.97-20.14 8.53-29.08-2.2 5.43-4.4 10.87-5.64 16.43-1.28 5.72-1.54 11.52-1.56 17.31-.06 22.9 3.86 46.2 16.3 67.68 3.43 5.91 8.56 11.32 12.1 17.12-4.03-.16-6.51-5.1-8.12-7.18-2.71-3.51-5.13-7.13-7.28-10.81-4.36-7.47-7.6-15.22-9.89-23.08z" />
          </>
        ),
        light: (
          <>
            <path d="M207.62 182.85c-11.87-31.63-12.76-67.26-2.48-99.44.34-1.06 1.53-2.33 2.27-1.51-5.74 18.84-8.36 38.8-6.05 58.36s9.71 38.72 22.41 53.77c1.7 2.01 3.57 4.13 3.88 6.74.25 2.12-1.17 7.6-3.88 7.56-2.25-.04-5.59-5.45-6.77-7.08-4.05-5.6-6.96-11.95-9.38-18.4z" />
            <path d="M210.36 155.2c-3.09-12.45-4.33-25.3-4.82-38.12-.45-11.95-.09-24.56 6-34.86 1.18 2.54 1.3 5.43 1.35 8.24.16 9.01-.17 18.04.37 27.04.56 9.43 2.06 18.78 3.64 28.09 2.12 12.47 4.46 25.1 9.96 36.49.58 1.2 1.27 2.6 1.22 3.96-.03.93-.81 3.07-1.9 3.3-1.74.37-4.45-5.58-5.24-6.98-3.1-5.47-5.62-11.26-7.65-17.21-1.13-3.28-2.09-6.6-2.93-9.95z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <path d="M291.92 209.43c11.25 2.95 22.05-2.42 24.12-12 .25-1.11.35-2.23.35-3.34 2.68-38.76-4-76.31-18.28-109.95-1.33-3.09-5.6-2.06-5.38 1.18 2.25 34.63-3.69 68.05-16.21 97.98-.01.01-.01.02-.02.04-.2.49-.41.98-.62 1.46-.23.63-.42 1.27-.56 1.94-.15.69-.25 1.39-.3 2.09-.68 8.92 6.47 17.86 16.9 20.6z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M293.19 158.46c4.63-15.9 5.42-32.21 4.44-48.39-.6-9.94-1.97-20.14-8.53-29.08 2.2 5.43 4.4 10.87 5.64 16.43 1.28 5.72 1.54 11.52 1.56 17.31.06 22.9-3.86 46.2-16.3 67.68-3.43 5.91-8.56 11.32-12.1 17.12 4.03-.16 6.51-5.1 8.12-7.18 2.71-3.51 5.13-7.13 7.28-10.81 4.36-7.47 7.6-15.22 9.89-23.08z" />
            <path d="M306.01 99.64c-2.1-6.97-4.77-14.16-10.26-18.94 2.43 7.5 4.86 15 6.7 22.66 8.62 35.84 3.74 74.76-13.46 107.36-.27.51-.54 1.14-.24 1.63.18.3.53.45.86.59 3.5 1.43 7.12 2.88 10.9 2.88 7.63 0 9.87-4.78 11.08-11.15 1.59-8.44 2.68-16.97 3.28-25.53 1.19-17.13.4-34.4-2.36-51.35-1.55-9.51-3.72-18.92-6.5-28.15z" />
          </>
        ),
        light: (
          <>
            <path d="M292.54 182.85c11.87-31.63 12.76-67.26 2.48-99.44-.34-1.06-1.53-2.33-2.27-1.51 5.74 18.84 8.36 38.8 6.05 58.36s-9.71 38.72-22.41 53.77c-1.7 2.01-3.57 4.13-3.88 6.74-.25 2.12 1.17 7.6 3.88 7.56 2.25-.04 5.59-5.45 6.77-7.08 4.05-5.6 6.96-11.95 9.38-18.4z" />
            <path d="M289.79 155.2c3.09-12.45 4.33-25.3 4.82-38.12.45-11.95.09-24.56-6-34.86-1.18 2.54-1.3 5.43-1.35 8.24-.16 9.01.17 18.04-.37 27.04-.56 9.43-2.06 18.78-3.64 28.09-2.12 12.47-4.46 25.1-9.96 36.49-.58 1.2-1.27 2.6-1.22 3.96.03.93.81 3.07 1.9 3.3 1.74.37 4.45-5.58 5.24-6.98 3.1-5.47 5.62-11.26 7.65-17.21 1.14-3.28 2.1-6.6 2.93-9.95z" />
          </>
        ),
      },
    },
  },
};

export const horn_EEtKp = {
  name: "Horn 8",
  description: "Short, straight horns on the top of the head",
  tags: ["top", "short", "straight"],
  preview: {
    viewbox: viewboxTall,
  },
  shape: {
    left: {
      base: (
        <path d="M208.24 209.64c-11.25 2.2-22.05-1.8-24.12-8.94-.25-.83-.35-1.66-.35-2.49-2.68-28.87 4-56.84 18.28-81.89 1.33-2.3 5.6-1.53 5.38.88-2.25 25.79 3.69 50.68 16.21 72.98.01.01.01.01.02.03.2.36.41.73.62 1.09.23.47.42.95.56 1.44.15.51.25 1.04.3 1.56.68 6.64-6.48 13.3-16.9 15.34z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M197.23 162.37c1.74 16.84 7.48 33.29 16.83 47.41-1.63 1.29-3.66 2.55-5.67 1.99-1.97-.55-3.05-2.6-3.87-4.47-5.67-12.88-9.61-26.52-11.68-40.43-1.34-9.02-1.89-18.2-.83-27.25.59-5.01 1.68-9.97 3.37-14.72 1.07-3.02 3.02-9.81 5.92-11.4.94 1.84-.05 5.03-.48 6.96-.98 4.41-2.13 8.75-2.82 13.22-1.46 9.47-1.75 19.14-.77 28.69z" />
            <path d="M191.26 211.98c-.69.42-1.57-.01-2.01-.71-.45-.71-.57-1.61-.68-2.49-2.75-22.33-2.57-45.25.51-67.74.18-1.35.6-7.45 1.82-8.09 2.01-1.04.79 4.17.64 5.06-.97 5.89-1.7 11.81-2.21 17.76-1.03 11.98-1.15 24.04-.34 35.98.45 6.79 1.21 13.55 2.27 20.23z" />
          </>
        ),
        light: (
          <path d="M224.77 197.63c.51.69 1.05 1.43 1.12 2.3.06.74-.25 1.46-.6 2.12-1.09 2.08-2.63 3.93-4.48 5.38-3.08-2.83-5.7-6.14-7.75-9.78-1.95-3.47-3.36-7.21-4.64-10.98-6.51-19.21-9.57-40.05-6.26-60.2.75-4.53 1.43-12.28 4.31-15.9 1.99 1.42.52 4.83.15 6.75-.57 3.03-1 6.09-1.29 9.16-.57 6-.6 12.05-.12 18.05.97 12.16 4.05 24.16 9.11 35.26 2.85 6.28 6.35 12.27 10.45 17.84z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M291.76 209.64c11.25 2.2 22.05-1.8 24.12-8.94.25-.83.35-1.66.35-2.49 2.68-28.87-4-56.84-18.29-81.89-1.33-2.3-5.6-1.53-5.38.88 2.25 25.79-3.69 50.68-16.21 72.98-.01.01-.01.01-.02.03-.2.36-.41.73-.62 1.09-.23.47-.42.95-.56 1.44-.15.51-.25 1.04-.3 1.56-.67 6.64 6.49 13.3 16.91 15.34z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M308.72 211.94c.69.42 1.57-.01 2.01-.71.45-.71.57-1.61.68-2.49 2.75-22.33 2.57-45.25-.51-67.74-.18-1.35-.6-7.45-1.82-8.09-2.01-1.04-.79 4.17-.64 5.06.97 5.89 1.7 11.81 2.21 17.76 1.03 11.98 1.15 24.04.34 35.98-.45 6.8-1.21 13.55-2.27 20.23z" />
            <path d="M302.74 162.33c-1.74 16.84-7.48 33.29-16.83 47.41 1.63 1.29 3.66 2.55 5.67 1.99 1.97-.55 3.05-2.6 3.87-4.47 5.67-12.88 9.61-26.52 11.68-40.43 1.34-9.02 1.89-18.2.83-27.25-.59-5.01-1.68-9.97-3.37-14.72-1.07-3.02-3.02-9.81-5.92-11.4-.94 1.84.05 5.03.48 6.96.98 4.41 2.13 8.75 2.82 13.22 1.47 9.47 1.76 19.15.77 28.69z" />
          </>
        ),
        light: (
          <>
            <path d="M275.21 197.59c-.51.69-1.05 1.43-1.12 2.3-.06.74.25 1.46.6 2.12 1.09 2.08 2.63 3.93 4.48 5.38 3.08-2.83 5.7-6.14 7.75-9.78 1.95-3.47 3.36-7.21 4.64-10.98 6.51-19.21 9.57-40.05 6.26-60.2-.75-4.53-1.43-12.28-4.31-15.9-1.99 1.42-.52 4.83-.15 6.75.57 3.03 1 6.09 1.29 9.16.57 6 .6 12.05.12 18.05-.97 12.16-4.05 24.16-9.11 35.26-2.86 6.28-6.35 12.28-10.45 17.84z" />
          </>
        ),
      },
    },
  },
};

export const horn_PS7EE = {
  name: "Horn 9",
  description:
    "Very long, curving horns that curve outwards from the side of the head in an S shape",
  tags: ["long", "curved", "side"],
  preview: {
    viewbox: viewboxTallWide,
  },
  shape: {
    left: {
      base: (
        <path d="M210.56 204.23c-4.32 11.62-14.31 18.97-23.7 18.15h-.01c-.44-.04-.87-.09-1.3-.16-.02-.01-.03-.01-.04-.01-.99-.27-1.98-.54-2.97-.82-35.1-9.84-70.82-28.08-102.18-52.76-13.38-11.61-9.69-35 6.9-42.39 0 0 .01-.02.02-.01 49.28-18.04 45.05-75.08-24.67-64.49-8.13 1.39-9.05-.83-.22-3.68 75.6-25.45 137.66 30.53 69.55 87.67 21.23 11.78 43.17 20.62 65.15 26.39 1.27.34 2.53.66 3.8.97 4.73 1.83 8.2 5.77 10.08 10.77 2.21 5.86 2.25 13.2-.41 20.37z" />
      ),
      basic: {
        dark: (
          <path d="M220.08 195.88c-4.13.91-5.77 4.19-8.25 7.22-1.87 2.29-3.97 4.44-6.27 6.33-5.48 1.24-11.26.96-16.77.08-2.44-.39-4.85-.9-7.23-1.5.77-1.75 1.41-3.55 1.93-5.38 1.16-3.15 1.94-5.84 2.1-6.72.28-1.51.48-3.11-.11-4.52-1.45 1.75-2.16 3.96-3.01 6.07-1.41 3.5-3.27 6.47-5.37 9.3-14.04-4.28-27.17-11.68-40.02-18.99-8.02-4.56-16.04-9.13-24.06-13.69-.85-.48-1.7-.97-2.56-1.45 2.12-4.48 4.87-8.67 8.18-12.36.41-.46.85-.94.97-1.54.13-.6-.21-1.35-.82-1.43-.38-.05-.74.16-1.06.36-4.85 3.13-8.35 7.76-11.28 12.81-1.09-.57-2.19-1.12-3.31-1.63-3.43-1.58-7-2.87-10.45-4.4.41-.66.84-1.31 1.27-1.93 1.6-2.31 3.2-4.63 4.79-6.94-3.71-.12-6.43 3.31-8.51 6.39-.22.33-.45.66-.67 1-.72-.37-1.44-.76-2.14-1.18-4.94-2.93-9.36-7.41-10.45-13.04-1.49-7.67 3.38-14.98 7.99-21.28-5.02 2.05-9.2 6.09-11.42 11.04-1 2.23-1.59 4.6-1.83 7.01-.33-.61-.66-1.22-.97-1.84-1.36 8.7-2.39 17.71-.16 26.23 2.34 8.93 8.17 16.69 15.32 22.53 10.72 8.76 24.12 13.41 37.21 17.9 19.44 6.66 38.98 13.36 59.25 16.73 3.95.66 8 1.19 11.93.44 5.41-1.03 9.82-4.25 13.57-8.36 2.75-1.18 5.21-2.85 7.15-5.22 1.26-1.54 2.65-4.64 1.22-6.44 2.11-3.38 4.52-7.53 3.84-11.6zM100.01 79.56c2.51 1.24 4.92 2.8 6.7 4.96 1.72 2.09 2.79 4.65 3.45 7.28 1.48 5.88 1 12.23-1.35 17.82 3.64-2.9 6.8-6.39 9.33-10.29 1.76-2.71 3.22-5.65 4.02-8.78 2.78-10.88-3.2-22.73-12.47-29.08-9.26-6.36-21.1-7.94-32.29-6.94-6.56.58-13.21 1.92-19.39 4.22C44 63.98 64.31 71.04 70.04 72.3c10.03 2.18 20.63 2.65 29.97 7.26zm17.5 57.68c5.01 4.57 11.18 7.84 17.77 9.44l7.12.38c9.42-3.31 14.98-13.04 18.04-22.54.07-.21.13-.42.19-.63 5.65-12.48 7.54-26.74 4.26-40.01-.43-1.73-1.15-3.68-2.82-4.29-.26 1.62-.52 3.23-.79 4.85-.41-.7-.89-1.37-1.45-1.97.34 2.15.54 4.32.62 6.49-.64 3.22-1.39 6.4-2.38 9.53-2.68 8.41-7.17 16.22-12.97 22.87-4.81 5.5-10.3 9.2-16.12 12.98-3.63 1.68-7.45 2.8-11.47 2.9z" />
        ),
        light: (
          <path d="M216.28 181.14c-1.79-5.02-6.15-8.68-10.63-11.57-12.97-8.37-28.21-12.34-43.15-16.18-6.58-1.69-13.17-3.39-19.75-5.08-5.47-1.41-10.97-2.82-16.08-5.23-6.36-3-12.04-7.48-17.99-11.25 1.36-.72 2.67-1.57 3.93-2.44 6.4-4.41 12.45-9.55 16.86-15.91 1.23-2.78 3.01-5.13 4.58-7.83 1.78-3.07 2.47-6.49 3.18-9.91-.17-5.27-.34-10.54-.52-15.8-.02-.58-.02-1.21.36-1.65.33-.38.85-.5 1.34-.58 4.66-.8 9.6.21 13.57 2.77.82.53 2.12 1.01 2.57.15-2.76-4.58-6.51-9.74-11.86-9.65-2.79.05-6.5 1.16-7.67-1.37-.97-2.08 1.36-4.34 3.63-4.67 2.27-.33 4.59.39 6.86.04-15.37-13.8-38.12-18.83-57.88-12.79 9.57.54 19.27 2.61 27.4 7.71 8.13 5.09 14.5 13.54 15.47 23.08.89 8.8-2.8 17.52-7.97 24.69-4.83 6.7-11.15 12.47-18.54 16.21-1.28.65-4.31 1.39-6.67 2.37-.88-.28-1.76-.53-2.67-.73-2.12-.47-4.33-.7-6.47-.33-3.28.57-6.19 2.52-8.56 4.87-4.68 4.62-7.63 10.95-8.16 17.5 1.94-4.05 3.94-8.18 7.09-11.38 3.16-3.2 7.74-5.34 12.14-4.43-2.94 3.03-5.9 6.1-8.02 9.76-2.11 3.65-3.32 8.01-2.44 12.14 1.36-3.48 2.75-7.02 5.01-10 2.26-2.98 5.54-5.4 9.26-5.76 3.45-.34 6.81 1.08 9.99 2.47 7.91 3.46 15.83 6.91 23.74 10.37.97.42 2.05 1 2.55 1.87 6.08 1.66 11.65 4.72 17.19 7.74 5.22 1.97 10.2 4.34 15.56 6.06 4.69 1.5 9.47 3.39 13.83 5.72 3.54.64 6.89 1.98 9.72 4.19 4.31 3.36 6.62 8.23 7.32 13.47l2.91-8.58c3.17 3.81 4.63 8.99 3.93 13.9 2.21.27 4.29-1.37 5.25-3.37.97-2 1.06-4.3 1.14-6.52 1.1-.36 2.3.44 2.8 1.48s.47 2.24.43 3.4c-.07 2.1-1.68 11.42 1.09 11.91 3.11.55 4.44-7.94 4.77-9.72.7-3.74.82-7.62-.44-11.14z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M289.44 204.23c4.32 11.62 14.31 18.97 23.7 18.15h.01c.44-.04.87-.09 1.3-.16.02-.01.03-.01.04-.01.99-.27 1.98-.54 2.97-.82 35.1-9.84 70.82-28.08 102.18-52.76 13.38-11.61 9.69-35-6.9-42.39 0 0-.01-.02-.02-.01-49.28-18.04-45.05-75.08 24.67-64.49 8.13 1.39 9.05-.83.22-3.68-75.6-25.45-137.66 30.53-69.55 87.67-21.23 11.78-43.17 20.62-65.15 26.39-1.27.34-2.53.66-3.8.97-4.73 1.83-8.2 5.77-10.08 10.77-2.21 5.86-2.25 13.2.41 20.37z" />
      ),
      basic: {
        dark: (
          <path d="M279.69 195.88c4.13.91 5.77 4.19 8.25 7.22 1.87 2.29 3.97 4.44 6.27 6.33 5.48 1.24 11.26.96 16.77.08 2.44-.39 4.85-.9 7.23-1.5-.77-1.75-1.41-3.55-1.93-5.38-1.16-3.15-1.94-5.84-2.1-6.72-.28-1.51-.48-3.11.11-4.52 1.45 1.75 2.16 3.96 3.01 6.07 1.41 3.5 3.27 6.47 5.37 9.3 14.04-4.28 27.17-11.68 40.02-18.99 8.02-4.56 16.04-9.13 24.06-13.69.85-.48 1.7-.97 2.56-1.45-2.12-4.48-4.87-8.67-8.18-12.36-.41-.46-.85-.94-.97-1.54-.13-.6.21-1.35.82-1.43.38-.05.74.16 1.06.36 4.85 3.13 8.35 7.76 11.28 12.81 1.09-.57 2.19-1.12 3.31-1.63 3.43-1.58 7-2.87 10.45-4.4-.41-.66-.84-1.31-1.27-1.93-1.6-2.31-3.2-4.63-4.79-6.94 3.71-.12 6.43 3.31 8.51 6.39.22.33.45.66.67 1 .72-.37 1.44-.76 2.14-1.18 4.94-2.93 9.36-7.41 10.45-13.04 1.49-7.67-3.38-14.98-7.99-21.28 5.02 2.05 9.2 6.09 11.42 11.04 1 2.23 1.59 4.6 1.83 7.01.33-.61.66-1.22.97-1.84 1.36 8.7 2.39 17.71.16 26.23-2.34 8.93-8.17 16.69-15.32 22.53-10.72 8.76-24.12 13.41-37.21 17.9-19.44 6.66-38.98 13.36-59.25 16.73-3.95.66-8 1.19-11.93.44-5.41-1.03-9.82-4.25-13.57-8.36-2.75-1.18-5.21-2.85-7.15-5.22-1.26-1.54-2.65-4.64-1.22-6.44-2.11-3.38-4.52-7.53-3.84-11.6zM399.76 79.56c-2.51 1.24-4.92 2.8-6.7 4.96-1.72 2.09-2.79 4.65-3.45 7.28-1.48 5.88-1 12.23 1.35 17.82-3.64-2.9-6.8-6.39-9.33-10.29-1.76-2.71-3.22-5.65-4.02-8.78-2.78-10.88 3.2-22.73 12.47-29.08 9.26-6.36 21.1-7.94 32.29-6.94 6.56.58 13.21 1.92 19.39 4.22 14.01 5.23-6.3 12.29-12.03 13.55-10.03 2.18-20.63 2.65-29.97 7.26zm-17.5 57.68c-5.01 4.57-11.18 7.84-17.77 9.44l-7.12.38c-9.42-3.31-14.98-13.04-18.04-22.54-.07-.21-.13-.42-.19-.63-5.65-12.48-7.54-26.74-4.26-40.01.43-1.73 1.15-3.68 2.82-4.29.26 1.62.52 3.23.79 4.85.41-.7.89-1.37 1.45-1.97-.34 2.15-.54 4.32-.62 6.49.64 3.22 1.39 6.4 2.38 9.53 2.68 8.41 7.17 16.22 12.97 22.87 4.81 5.5 10.3 9.2 16.12 12.98 3.63 1.68 7.45 2.8 11.47 2.9z" />
        ),
        light: (
          <path d="M283.49 181.14c1.79-5.02 6.15-8.68 10.63-11.57 12.97-8.37 28.21-12.34 43.15-16.18 6.58-1.69 13.17-3.39 19.75-5.08 5.47-1.41 10.97-2.82 16.08-5.23 6.36-3 12.04-7.48 17.99-11.25-1.36-.72-2.67-1.57-3.93-2.44-6.4-4.41-12.45-9.55-16.86-15.91-1.23-2.78-3.01-5.13-4.58-7.83-1.78-3.07-2.47-6.49-3.18-9.91.17-5.27.34-10.54.52-15.8.02-.58.02-1.21-.36-1.65-.33-.38-.85-.5-1.34-.58-4.66-.8-9.6.21-13.57 2.77-.82.53-2.12 1.01-2.57.15 2.76-4.58 6.51-9.74 11.86-9.65 2.79.05 6.5 1.16 7.67-1.37.97-2.08-1.36-4.34-3.63-4.67-2.27-.33-4.59.39-6.86.04 15.37-13.8 38.12-18.83 57.88-12.79-9.57.54-19.27 2.61-27.4 7.71-8.13 5.09-14.5 13.54-15.47 23.08-.89 8.8 2.8 17.52 7.97 24.69 4.83 6.7 11.15 12.47 18.54 16.21 1.28.65 4.31 1.39 6.67 2.37.88-.28 1.76-.53 2.67-.73 2.12-.47 4.33-.7 6.47-.33 3.28.57 6.19 2.52 8.56 4.87 4.68 4.62 7.63 10.95 8.16 17.5-1.94-4.05-3.94-8.18-7.09-11.38-3.16-3.2-7.74-5.34-12.14-4.43 2.94 3.03 5.9 6.1 8.02 9.76 2.11 3.65 3.32 8.01 2.44 12.14-1.36-3.48-2.75-7.02-5.01-10-2.26-2.98-5.54-5.4-9.26-5.76-3.45-.34-6.81 1.08-9.99 2.47-7.91 3.46-15.83 6.91-23.74 10.37-.97.42-2.05 1-2.55 1.87-6.08 1.66-11.65 4.72-17.19 7.74-5.22 1.97-10.2 4.34-15.56 6.06-4.69 1.5-9.47 3.39-13.83 5.72-3.54.64-6.89 1.98-9.72 4.19-4.31 3.36-6.62 8.23-7.32 13.47l-2.91-8.58c-3.17 3.81-4.63 8.99-3.93 13.9-2.21.27-4.29-1.37-5.25-3.37-.97-2-1.06-4.3-1.14-6.52-1.1-.36-2.3.44-2.8 1.48s-.47 2.24-.43 3.4c.07 2.1 1.68 11.42-1.09 11.91-3.11.55-4.44-7.94-4.77-9.72-.7-3.74-.82-7.62.44-11.14z" />
        ),
      },
    },
  },
};

export const horn_Wa16s = {
  name: "Horn 10",
  description:
    "Long, curving horns that curve outwards from the side of the head in an S shape",
  tags: ["long", "curved", "side"],
  preview: {
    viewbox: viewboxTall,
  },
  shape: {
    left: {
      base: (
        <path d="M209.98 203.78c-3.91 8.11-11.68 12.76-18.47 11.55h-.01c-.32-.06-.63-.12-.94-.2-.01-.01-.02-.01-.03-.01-.7-.26-1.41-.52-2.11-.79-24.94-9.45-49.78-25.01-71.01-44.93-8.99-9.28-4.76-25.95 7.82-30.19 0 0 .01-.01.02-.01 37.11-9.77 37.79-51.29-13.74-48.26-6.02.46-6.54-1.2.08-2.68 56.79-13.38 98.34 31.22 44.93 68.01 14.7 9.93 30.11 17.78 45.76 23.41.9.33 1.8.65 2.71.95 3.33 1.64 5.6 4.72 6.64 8.46 1.22 4.38.76 9.69-1.65 14.69z" />
      ),
      basic: {
        dark: (
          <path d="M217.64 198.4c-3.07.38-4.49 2.65-6.49 4.67-1.52 1.53-3.19 2.94-4.99 4.16-4.07.53-8.27-.06-12.23-1.06-1.75-.45-3.48-.97-5.17-1.57.67-1.21 1.26-2.47 1.76-3.76 1.05-2.2 1.8-4.1 1.97-4.72.3-1.07.56-2.22.22-3.28-1.17 1.17-1.84 2.72-2.59 4.19-1.26 2.44-2.81 4.46-4.53 6.37-9.96-4.03-19.04-10.25-27.92-16.39-5.55-3.83-11.09-7.66-16.64-11.5-.59-.41-1.18-.81-1.77-1.22 1.84-3.1 4.12-5.94 6.78-8.39.33-.3.68-.62.81-1.05.13-.43-.06-.99-.5-1.09-.27-.06-.55.06-.79.19-3.74 1.94-6.6 5.06-9.07 8.51-.76-.48-1.52-.95-2.3-1.4-2.4-1.37-4.92-2.54-7.33-3.87.34-.45.7-.89 1.05-1.31 1.32-1.57 2.63-3.13 3.95-4.7-2.7-.33-4.91 1.97-6.62 4.06-.18.22-.37.45-.55.68-.5-.32-1-.64-1.48-.99-3.41-2.45-6.33-5.98-6.76-10.12-.58-5.64 3.45-10.61 7.23-14.86-3.8 1.15-7.11 3.79-9.05 7.23-.88 1.55-1.46 3.22-1.79 4.95-.2-.46-.4-.93-.58-1.39-1.57 6.2-2.91 12.65-1.84 18.96 1.12 6.61 4.85 12.61 9.68 17.31 7.24 7.04 16.7 11.3 25.95 15.42 13.73 6.11 27.54 12.24 42.09 16.03 2.83.74 5.75 1.39 8.67 1.11 4.01-.38 7.44-2.42 10.45-5.14 2.08-.67 3.98-1.71 5.55-3.3 1.02-1.03 2.24-3.18 1.32-4.57 1.7-2.34 3.73-5.18 3.51-8.16zm-79.87-92.08c1.75 1.06 3.4 2.35 4.56 4.03 1.12 1.62 1.73 3.55 2.04 5.49.7 4.35-.07 8.91-2.16 12.79 2.84-1.85 5.38-4.17 7.48-6.82 1.46-1.84 2.72-3.87 3.51-6.08 2.74-7.68-.84-16.65-7.17-21.86-6.33-5.21-14.86-7.14-23.08-7.16-4.82-.01-9.76.51-14.42 1.77-10.56 2.85 3.78 9.3 7.88 10.6 7.16 2.25 14.86 3.29 21.36 7.24zm8.96 42.87c3.35 3.63 7.64 6.41 12.33 8l5.17.75c7.08-1.77 11.78-8.44 14.63-15.1.06-.15.12-.29.18-.44 4.94-8.65 7.26-18.83 5.74-28.65-.2-1.28-.59-2.74-1.78-3.29-.3 1.15-.59 2.3-.9 3.45-.25-.54-.56-1.05-.93-1.52.11 1.58.11 3.16.03 4.74-.68 2.28-1.43 4.54-2.37 6.73-2.51 5.9-6.29 11.25-10.96 15.67-3.87 3.66-8.11 5.97-12.6 8.31-2.75.99-5.61 1.54-8.54 1.35z" />
        ),
        light: (
          <path d="M215.84 187.49c-.98-3.75-3.91-6.68-6.98-9.07-8.9-6.91-19.75-10.8-30.39-14.57l-14.07-4.98c-3.89-1.38-7.81-2.77-11.38-4.85-4.44-2.59-8.29-6.21-12.37-9.33 1.04-.43 2.05-.96 3.02-1.5 4.95-2.77 9.7-6.08 13.34-10.39 1.08-1.93 2.53-3.51 3.86-5.36 1.5-2.1 2.23-4.53 2.97-6.96.22-3.82.44-7.64.67-11.46.02-.42.07-.88.37-1.17.26-.25.65-.3 1.02-.33 3.45-.27 6.98.79 9.71 2.9.56.44 1.48.87 1.86.28-1.71-3.5-4.11-7.47-8.01-7.76-2.03-.15-4.82.41-5.5-1.5-.57-1.57 1.28-3.04 2.95-3.13 1.67-.09 3.32.59 5 .48-10.29-11-26.55-16.15-41.35-13.09 6.94 1.03 13.88 3.17 19.47 7.39 5.59 4.22 9.68 10.75 9.76 17.71.07 6.42-3.19 12.48-7.44 17.32-3.96 4.52-8.95 8.27-14.59 10.49-.97.38-3.23.72-5.01 1.27-.62-.26-1.25-.5-1.9-.71-1.52-.48-3.11-.79-4.7-.67-2.43.19-4.68 1.41-6.56 2.95-3.71 3.03-6.28 7.41-7.1 12.11 1.68-2.8 3.41-5.66 5.92-7.76 2.51-2.1 6-3.35 9.14-2.4-2.34 2-4.71 4.02-6.49 6.52-1.78 2.5-2.95 5.57-2.58 8.61 1.22-2.43 2.47-4.89 4.31-6.9 1.84-2.01 4.39-3.54 7.13-3.55 2.54-.02 4.89 1.23 7.12 2.45 5.54 3.02 11.08 6.05 16.62 9.07.68.37 1.43.86 1.74 1.52 4.32 1.6 8.18 4.19 12.02 6.74 3.68 1.77 7.15 3.82 10.94 5.41 3.32 1.4 6.68 3.08 9.71 5.06 2.54.7 4.89 1.89 6.81 3.68 2.92 2.72 4.28 6.39 4.45 10.23.9-2 1.79-4.01 2.69-6.01 2.06 2.97 2.78 6.81 1.95 10.31 1.59.34 3.22-.71 4.05-2.09.84-1.38 1.06-3.04 1.26-4.64.82-.19 1.65.47 1.94 1.26.29.79.19 1.65.09 2.49-.19 1.51-1.98 8.14.01 8.68 2.23.61 3.76-5.44 4.12-6.71.74-2.62 1.08-5.41.4-8.04z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M290.02 203.78c3.91 8.11 11.68 12.76 18.47 11.55h.01c.32-.06.63-.12.94-.2.01-.01.02-.01.03-.01.7-.26 1.41-.52 2.11-.79 24.94-9.45 49.78-25.01 71.01-44.93 8.99-9.28 4.76-25.95-7.82-30.19 0 0-.01-.01-.02-.01-37.11-9.77-37.79-51.29 13.74-48.26 6.02.46 6.54-1.2-.08-2.68-56.79-13.38-98.34 31.22-44.93 68.01-14.7 9.93-30.11 17.78-45.76 23.41-.9.33-1.8.65-2.71.95-3.33 1.64-5.6 4.72-6.64 8.46-1.22 4.38-.76 9.69 1.65 14.69z" />
      ),
      basic: {
        dark: (
          <path d="M282.36 198.4c3.07.38 4.49 2.65 6.49 4.67 1.52 1.53 3.19 2.94 4.99 4.16 4.07.53 8.27-.06 12.23-1.06 1.75-.45 3.48-.97 5.17-1.57-.67-1.21-1.26-2.47-1.76-3.76-1.05-2.2-1.8-4.1-1.97-4.72-.3-1.07-.56-2.22-.22-3.28 1.17 1.17 1.84 2.72 2.59 4.19 1.26 2.44 2.81 4.46 4.53 6.37 9.96-4.03 19.04-10.25 27.92-16.39 5.55-3.83 11.09-7.66 16.64-11.5.59-.41 1.18-.81 1.77-1.22-1.84-3.1-4.12-5.94-6.78-8.39-.33-.3-.68-.62-.81-1.05-.13-.43.06-.99.5-1.09.27-.06.55.06.79.19 3.74 1.94 6.6 5.06 9.07 8.51.76-.48 1.52-.95 2.3-1.4 2.4-1.37 4.92-2.54 7.33-3.87-.34-.45-.7-.89-1.05-1.31-1.32-1.57-2.63-3.13-3.95-4.7 2.7-.33 4.91 1.97 6.62 4.06.18.22.37.45.55.68.5-.32 1-.64 1.48-.99 3.41-2.45 6.33-5.98 6.76-10.12.58-5.64-3.45-10.61-7.23-14.86 3.8 1.15 7.11 3.79 9.05 7.23.88 1.55 1.46 3.22 1.79 4.95.2-.46.4-.93.58-1.39 1.57 6.2 2.91 12.65 1.84 18.96-1.12 6.61-4.85 12.61-9.68 17.31-7.24 7.04-16.7 11.3-25.95 15.42-13.73 6.11-27.54 12.24-42.09 16.03-2.83.74-5.75 1.39-8.67 1.11-4.01-.38-7.44-2.42-10.45-5.14-2.08-.67-3.98-1.71-5.55-3.3-1.02-1.03-2.24-3.18-1.32-4.57-1.7-2.34-3.73-5.18-3.51-8.16zm79.87-92.08c-1.75 1.06-3.4 2.35-4.56 4.03-1.12 1.62-1.73 3.55-2.04 5.49-.7 4.35.07 8.91 2.16 12.79-2.84-1.85-5.38-4.17-7.48-6.82-1.46-1.84-2.72-3.87-3.51-6.08-2.74-7.68.84-16.65 7.17-21.86 6.33-5.21 14.86-7.14 23.08-7.16 4.82-.01 9.76.51 14.42 1.77 10.56 2.85-3.78 9.3-7.88 10.6-7.16 2.25-14.86 3.29-21.36 7.24zm-8.96 42.87c-3.35 3.63-7.64 6.41-12.33 8l-5.17.75c-7.08-1.77-11.78-8.44-14.63-15.1-.06-.15-.12-.29-.18-.44-4.94-8.65-7.26-18.83-5.74-28.65.2-1.28.59-2.74 1.78-3.29.3 1.15.59 2.3.9 3.45.25-.54.56-1.05.93-1.52-.11 1.58-.11 3.16-.03 4.74.68 2.28 1.43 4.54 2.37 6.73 2.51 5.9 6.29 11.25 10.96 15.67 3.87 3.66 8.11 5.97 12.6 8.31 2.75.99 5.61 1.54 8.54 1.35z" />
        ),
        light: (
          <path d="M284.16 187.49c.98-3.75 3.91-6.68 6.98-9.07 8.9-6.91 19.75-10.8 30.39-14.57l14.07-4.98c3.89-1.38 7.81-2.77 11.38-4.85 4.44-2.59 8.29-6.21 12.37-9.33-1.04-.43-2.05-.96-3.02-1.5-4.95-2.77-9.7-6.08-13.34-10.39-1.08-1.93-2.53-3.51-3.86-5.36-1.5-2.1-2.23-4.53-2.97-6.96-.22-3.82-.44-7.64-.67-11.46-.02-.42-.07-.88-.37-1.17-.26-.25-.65-.3-1.02-.33-3.45-.27-6.98.79-9.71 2.9-.56.44-1.48.87-1.86.28 1.71-3.5 4.11-7.47 8.01-7.76 2.03-.15 4.82.41 5.5-1.5.57-1.57-1.28-3.04-2.95-3.13s-3.32.59-5 .48c10.29-11 26.55-16.15 41.35-13.09-6.94 1.03-13.88 3.17-19.47 7.39-5.59 4.22-9.68 10.75-9.76 17.71-.07 6.42 3.19 12.48 7.44 17.32 3.96 4.52 8.95 8.27 14.59 10.49.97.38 3.23.72 5.01 1.27.62-.26 1.25-.5 1.9-.71 1.52-.48 3.11-.79 4.7-.67 2.43.19 4.68 1.41 6.56 2.95 3.71 3.03 6.28 7.41 7.1 12.11-1.68-2.8-3.41-5.66-5.92-7.76-2.51-2.1-6-3.35-9.14-2.4 2.34 2 4.71 4.02 6.49 6.52 1.78 2.5 2.95 5.57 2.58 8.61-1.22-2.43-2.47-4.89-4.31-6.9-1.84-2.01-4.39-3.54-7.13-3.55-2.54-.02-4.89 1.23-7.12 2.45-5.54 3.02-11.08 6.05-16.62 9.07-.68.37-1.43.86-1.74 1.52-4.32 1.6-8.18 4.19-12.02 6.74-3.68 1.77-7.15 3.82-10.94 5.41-3.32 1.4-6.68 3.08-9.71 5.06-2.54.7-4.89 1.89-6.81 3.68-2.92 2.72-4.28 6.39-4.45 10.23-.9-2-1.79-4.01-2.69-6.01-2.06 2.97-2.78 6.81-1.95 10.31-1.59.34-3.22-.71-4.05-2.09-.84-1.38-1.06-3.04-1.26-4.64-.82-.19-1.65.47-1.94 1.26-.29.79-.19 1.65-.09 2.49.19 1.51 1.98 8.14-.01 8.68-2.23.61-3.76-5.44-4.12-6.71-.74-2.62-1.08-5.41-.4-8.04z" />
        ),
      },
    },
  },
};

export const horn_1ejoH = {
  name: "Horn 11",
  description:
    "Long curved horns that sweep up and out in a slight S shape from the top of the head",
  tags: ["long", "curved", "top"],
  preview: {
    viewbox: viewboxExtraTall,
  },
  shape: {
    left: {
      base: (
        <path d="M216.99 182.78c-46.42-43.4 55.68-91.25-1.83-127.65-13.17-7.74-29.65-8.44-40.42-1.39-5.78 4.31-1.82 8.23 3.87 7.55 22.13-.18 27.53 11.92 27.68 17.77.35 14.58-72.71 88.79-21.1 127.24 5.12 4.16 12.05 6.44 20.94 5.47 10.22-1.26 22.01-7.03 20.45-17.53-.67-2.91-8.19-10.14-9.59-11.46z" />
      ),
      basic: {
        dark: (
          <path d="M208.41 73.47c.03-5.71-3.98-9.85-9.13-11.42-3-1.69-4.99-5.5-8.43-5.64-3.77.27-7.18 3.02-10.05-.24-5.88.48-3.91 1.04-8.01-3.44-6.13-2.46-7.39 6.38-3.34 9.72 5.92 6.82 16.11 8.16 22.93 14.08 6.83 8.73 15.81 10.82 16.03-3.06zM203 143.48c5.4-9.39-2.93-14.56 9.09-22.11 2.86-3.21 1.66-8.36 3.47-12.27 1.73-3.74 5.95-5.77 7.93-9.39 1.95-8.79 10.79-14.21 10.83-23.61 9.7 14.32-22.39 66.82-26.13 87.82-2.29 3.83-2.83 1.11-4-2.07-2.15-5.81-3.75-12.41-1.19-18.37zm-9.85 74.51c-29.99-6.22-41.16-46.03-23.99-69.77.89-1.3 2.64-2.68 3.83-1.64 1.99 3.36-1.61 8.42 1.03 11.54 1.81 2.47 5.91 2.82 6.98 5.69 1.39 3.74-3.77 8.28-1.27 11.38 1.87 2.37 7.47 1.03 7.87 4.77-.76 2.46 1.4 2.79 3.06 3.92 2.08 2.13-2.04 6.52.29 8.36 4.2 1.86 8.95-.53 13.33-1.08 2.48-.64 4.33.75 4.42 3.24 2.56 7.48 11.14 4.43 15.75.89 4.28-.56 3.34 7.11 2.22 9.31-7.03 10.56-21.16 15.96-33.52 13.39z" />
        ),
        light: (
          <path d="M212.27 185.17c-10.82-18.64-16.76-4.38-21-16.88 1.15-17.66-13.51-8.92-8.85-30.32-1.76-6.45-6.4-14.6-.79-20.41 4.51-5.3 6.96-11.89 10.17-17.91 2.8-1.76 5.35-3.36 6.69-6.6 3.04-3.85 14.65-4.42 11.33-11.44 6.59-3.29 5.62-12.88 3.31-18.67-1.17-1.61-3.61-2-4.58-3.74-.49-4.91-5.5-4.72-8.15-7.7-1.7-4.05 2.03-4.53 4.71-2.28 3.65.58 6.72.6 9.28 3.81 9.21 3.91 10.42 4.31 14.2 14.01 5.93 6.92 1.28 9.56-3.84 14.86-2.71 3.7-2.33 9.35-5.86 12.27-2.25 1.2-2.79 3.47-3.36 5.81-1.93 6.54-12.94 6.89-11.47 14.98-.66 4.97-6.68 7.53-8.33 12.27-1.3 3.71.33 8.14-1.56 11.58-1.85 2.31-2.49 4.67-.06 6.74 2.09 3.16-.86 7.82 1.08 11.07 1.11 1.87 3.49 2.61 4.84 4.32 1.15 1.45 1.46 3.48 2.78 4.77 2.42 2.38 7.18 1.37 9.02 4.23.89 1.38.81 3.37 2.11 4.38 9.72 2.16 8.67 16.4-1.67 10.85z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M283.01 182.78c46.42-43.4-55.68-91.25 1.83-127.65 13.17-7.74 29.65-8.44 40.42-1.39 5.78 4.31 1.82 8.23-3.87 7.55-22.13-.18-27.53 11.92-27.68 17.77-.35 14.58 72.71 88.79 21.1 127.24-5.12 4.16-12.05 6.44-20.94 5.47-10.22-1.26-22.01-7.03-20.45-17.53.67-2.91 8.19-10.14 9.59-11.46z" />
      ),
      basic: {
        dark: (
          <path d="M306.85 217.99c29.99-6.22 41.16-46.03 23.99-69.77-.89-1.3-2.64-2.68-3.83-1.64-1.99 3.36 1.61 8.42-1.03 11.54-1.81 2.47-5.91 2.82-6.98 5.69-1.39 3.74 3.77 8.28 1.27 11.38-1.87 2.37-7.47 1.03-7.87 4.77.76 2.46-1.4 2.79-3.06 3.92-2.08 2.13 2.04 6.52-.29 8.36-4.2 1.86-8.95-.53-13.33-1.08-2.48-.64-4.33.75-4.42 3.24-2.56 7.48-11.14 4.43-15.75.89-4.28-.56-3.34 7.11-2.22 9.31 7.03 10.56 21.16 15.96 33.52 13.39zM291.59 73.47c-.03-5.71 3.98-9.85 9.13-11.42 3-1.69 4.99-5.5 8.43-5.64 3.77.27 7.18 3.02 10.05-.24 5.88.48 3.91 1.04 8.01-3.44 6.13-2.46 7.39 6.38 3.34 9.72-5.92 6.82-16.11 8.16-22.93 14.08-6.83 8.73-15.81 10.82-16.03-3.06zm5.41 70.01c-5.4-9.39 2.93-14.56-9.09-22.11-2.86-3.21-1.66-8.36-3.47-12.27-1.73-3.74-5.95-5.77-7.93-9.39-1.95-8.79-10.79-14.21-10.83-23.61-9.7 14.32 22.39 66.82 26.13 87.82 2.29 3.83 2.83 1.11 4-2.07 2.15-5.81 3.75-12.41 1.19-18.37z" />
        ),
        light: (
          <path d="M287.73 185.17c10.82-18.64 16.76-4.38 21-16.88-1.15-17.66 13.51-8.92 8.85-30.32 1.76-6.45 6.4-14.6.79-20.41-4.51-5.3-6.96-11.89-10.17-17.91-2.8-1.76-5.35-3.36-6.69-6.6-3.04-3.85-14.65-4.42-11.33-11.44-6.59-3.29-5.62-12.88-3.31-18.67 1.17-1.61 3.61-2 4.58-3.74.49-4.91 5.5-4.72 8.15-7.7 1.7-4.05-2.03-4.53-4.71-2.28-3.65.58-6.72.6-9.28 3.81-9.21 3.91-10.42 4.31-14.2 14.01-5.93 6.92-1.28 9.56 3.84 14.86 2.71 3.7 2.33 9.35 5.86 12.27 2.25 1.2 2.79 3.47 3.36 5.81 1.93 6.54 12.94 6.89 11.47 14.98.66 4.97 6.68 7.53 8.33 12.27 1.3 3.71-.33 8.14 1.56 11.58 1.85 2.31 2.49 4.67.06 6.74-2.09 3.16.86 7.82-1.08 11.07-1.11 1.87-3.49 2.61-4.84 4.32-1.15 1.45-1.46 3.48-2.78 4.77-2.42 2.38-7.18 1.37-9.02 4.23-.89 1.38-.81 3.37-2.11 4.38-9.72 2.16-8.67 16.4 1.67 10.85z" />
        ),
      },
    },
  },
};

export const horn_E9d6o = {
  name: "Horn 12",
  description:
    "Long, slim horns that curve outwards from the side of the head in an S shape",
  tags: ["slim", "curved", "side", "long"],
  preview: {
    viewbox: viewboxTall,
  },
  shape: {
    left: {
      base: (
        <path d="M206.51 208.02c-5.59 6.06-12.99 8.6-17.69 6.42-1.05-.49-1.87-1.34-2.55-2.26-9.03-12.23-31.92-13.74-46.66-22.23C97 164.1 212.65 107 144.26 103.83c-7.17-.33-13.2-1.6-3.97-6.35 10.01-4.95 27.26-6.74 39.59 1.51 25.59 17.14-5.96 41.28-15.49 61.96-5.53 11.61 35.83 19.51 43.26 25.71 7.74 3.56 5.84 13.48-1.14 21.36z" />
      ),
      basic: {
        dark: (
          <path d="M189.29 217.34c6.95-1.19 15.05-3.4 18.34-10.26 1.15-1.93.91-5.46-1.83-5.61-3.93 1.08-15.42 11.82-12.98.64.09-2.15-2.41-3.95-4.42-3.19-1.66.63-3.23 2.61-4.76 1.72-1.19-.69-1.12-2.64-2.32-3.3-3.37-.56-4.08 1.78-6.77-2.14-5.39-2.9-14.29 3.39-16.12-6.09-1.6-2.77-4.64-.72-6.83-.58-3.87-5.62-11.54.08-12.17-10.11-.88-1.16-2.73-.42-4.16-.7-5.46-1.75-.64-9.34-2.93-12.93-7.23 5.16-5.37 18.63-1.49 25.5 5.94 9.14 17.69 10.93 26.95 15.2 9.91 4.63 20.32 11.54 31.49 11.85zm-27.94-58.22c-.27-1.84-.19-3.89.98-5.35 1.11-1.38 3.03-2.01 3.92-3.54 1.03-1.78.33-4.16 1.3-5.97 1.08-2.01 3.87-2.7 4.76-4.79.75-1.77-.05-4.14 1.29-5.52.76-.79 1.95-.93 2.98-1.3 1.64-.6 3-1.92 3.65-3.53.29-.72.47-1.54 1.04-2.06.34-.31.77-.49 1.16-.72 1.05-.64 1.74-1.73 2.4-2.78 1.61-2.59 3.25-5.27 3.66-8.29.07-.55.11-1.13.4-1.6.29-.47.94-.78 1.42-.5.29.17.43.51.55.83.79 2.18 1.1 4.53 1.05 6.85-.16 8.5-4.82 16.18-9.33 23.39-4.29 6.86-8.58 13.71-12.88 20.57-4.19 6.67-7.72-1.37-8.35-5.69zm3.81-38.86c1.39-3.27 2.8-6.73 2.38-10.26-.42-3.53-3.41-7.02-6.94-6.64-.77.08-1.54.34-2.3.19-1.79-.35-2.55-2.64-4.25-3.28-2.13-.8-4.6 1.35-6.67.42-.56-.25-1.02-.71-1.61-.9-1.31-.41-2.67.67-4.02.49-1.09-.14-1.91-1.06-2.91-1.52-1.26-.58-2.84-.37-3.9.51-1.07.88-1.56 2.4-1.23 3.74.55 2.19 2.87 3.37 4.94 4.26 8.17 3.51 16.38 6.91 24.64 10.2l1.87 2.79z" />
        ),
        light: (
          <path d="M216.08 192.44c-.03 2.8-2.04 5.95-4.83 5.69-4.15-.32-7.03-9.91-11.46-5-.98-4.11-7.43 2.31-10.64-3.07-4.97-11.77-5.95-1.79-10.86-4.67-9.03 5.39-6.15-2.91-10.48-2.33-3.14.73-8.5 1.14-9.01-3.07-.04-1.26.64-2.79-.32-3.61-.83-.7-2.06-.12-3.04.32-4.06 1.84-9.88.95-12.47-2.88-.76-2.93 1.48-6.14-2.43-7.39-4.6-6.88-1.93-17.58 3.67-23.36 5.44-6.19 10.39-13.06 16.74-18.34 6.11-3.65 16.4-11.72 11.31-19.34 2.3-6.76-7.27-4.71-9.34-8.8-3.48.57-7.16-.25-10.07-2.24 7.2-5.73 44.71 2.21 34.49 15.54-2.52 1.48-4.49 2.37-3.34 5.43-.45 4.46-11.66 4.93-10.87 9.19.49 2.72-3.3 4.04-4.67 6.43-1.09 1.9-.55 4.42-1.67 6.3-2.23 3.2-7.87 3.71-6.99 8.37-.62 4.54-6.63 4.98-9.09 8.12-1.78 1.48-.18 5.26 1.88 3.32-.08 1.72-.76 3.42-1.91 4.71.71.04 1.41.08 2.12.12-2.03 8.95 10.21 4.8 13.47 8.89 1.01 1.51 1.29 4.11 3.1 4.09 3.59-2.13 7.03 1.28 10.72.82 2.67-1.4 3.76-.27 5.9 1.23 3.31.05 6.66-.35 9.87 1.19 7.06 3.54 20.32 3.42 20.22 14.34z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M293.95 208.02c5.59 6.06 12.99 8.6 17.69 6.42 1.05-.49 1.87-1.34 2.55-2.26 9.03-12.23 31.92-13.74 46.66-22.23 42.61-25.85-73.04-82.95-4.65-86.12 7.17-.33 13.2-1.6 3.97-6.35-10.01-4.95-27.26-6.74-39.59 1.51-25.59 17.14 5.96 41.28 15.49 61.96 5.53 11.61-35.83 19.51-43.26 25.71-7.73 3.56-5.84 13.48 1.14 21.36z" />
      ),
      basic: {
        dark: (
          <path d="M335.31 120.26c-1.39-3.27-2.8-6.73-2.38-10.26s3.41-7.02 6.94-6.64c.77.08 1.54.34 2.3.19 1.79-.35 2.55-2.64 4.25-3.28 2.13-.8 4.6 1.35 6.67.42.56-.25 1.02-.71 1.61-.9 1.31-.41 2.67.67 4.02.49 1.09-.14 1.91-1.06 2.91-1.52 1.26-.58 2.84-.37 3.9.51s1.56 2.4 1.23 3.74c-.55 2.19-2.87 3.37-4.94 4.26-8.17 3.51-16.38 6.91-24.64 10.2l-1.87 2.79zm-24.13 97.08c-6.95-1.19-15.05-3.4-18.34-10.26-1.15-1.93-.91-5.46 1.83-5.61 3.93 1.08 15.42 11.82 12.98.64-.09-2.15 2.41-3.95 4.42-3.19 1.66.63 3.23 2.61 4.76 1.72 1.19-.69 1.12-2.64 2.32-3.3 3.37-.56 4.08 1.78 6.77-2.14 5.39-2.9 14.29 3.39 16.12-6.09 1.6-2.77 4.64-.72 6.83-.58 3.87-5.62 11.54.08 12.17-10.11.88-1.16 2.73-.42 4.16-.7 5.46-1.75.64-9.34 2.93-12.93 7.23 5.16 5.37 18.63 1.49 25.5-5.94 9.14-17.69 10.93-26.95 15.2-9.92 4.63-20.32 11.54-31.49 11.85zm27.93-58.22c.27-1.84.19-3.89-.98-5.35-1.11-1.38-3.03-2.01-3.92-3.54-1.03-1.78-.33-4.16-1.3-5.97-1.08-2.01-3.87-2.7-4.76-4.79-.75-1.77.05-4.14-1.29-5.52-.76-.79-1.95-.93-2.98-1.3-1.64-.6-3-1.92-3.65-3.53-.29-.72-.47-1.54-1.04-2.06-.34-.31-.77-.49-1.16-.72-1.05-.64-1.74-1.73-2.4-2.78-1.61-2.59-3.25-5.27-3.66-8.29-.07-.55-.11-1.13-.4-1.6-.29-.47-.94-.78-1.42-.5-.29.17-.43.51-.55.83-.79 2.18-1.1 4.53-1.05 6.85.16 8.5 4.82 16.18 9.33 23.39 4.29 6.86 8.58 13.71 12.88 20.57 4.19 6.67 7.72-1.37 8.35-5.69z" />
        ),
        light: (
          <path d="M284.38 192.44c.03 2.8 2.04 5.95 4.83 5.69 4.15-.32 7.03-9.91 11.46-5 .98-4.11 7.43 2.31 10.64-3.07 4.97-11.77 5.95-1.79 10.86-4.67 9.03 5.39 6.15-2.91 10.48-2.33 3.14.73 8.5 1.14 9.01-3.07.04-1.26-.64-2.79.32-3.61.83-.7 2.06-.12 3.04.32 4.06 1.84 9.88.95 12.47-2.88.76-2.93-1.48-6.14 2.43-7.39 4.6-6.88 1.93-17.58-3.67-23.36-5.44-6.19-10.39-13.06-16.74-18.34-6.11-3.65-16.4-11.72-11.31-19.34-2.3-6.76 7.27-4.71 9.34-8.8 3.48.57 7.16-.25 10.07-2.24-7.2-5.73-44.71 2.21-34.49 15.54 2.52 1.48 4.49 2.37 3.34 5.43.45 4.46 11.66 4.93 10.87 9.19-.49 2.72 3.3 4.04 4.67 6.43 1.09 1.9.55 4.42 1.67 6.3 2.23 3.2 7.87 3.71 6.99 8.37.62 4.54 6.63 4.98 9.09 8.12 1.78 1.48.18 5.26-1.88 3.32.08 1.72.76 3.42 1.91 4.71-.71.04-1.41.08-2.12.12 2.03 8.95-10.21 4.8-13.47 8.89-1.01 1.51-1.29 4.11-3.1 4.09-3.59-2.13-7.03 1.28-10.72.82-2.67-1.4-3.76-.27-5.9 1.23-3.31.05-6.66-.35-9.87 1.19-7.06 3.54-20.32 3.42-20.22 14.34z" />
        ),
      },
    },
  },
};

export const horn_1o7sQ = {
  name: "Horn 13",
  description:
    "Short, thick horns that curve gently backwards from the sides of the head",
  tags: ["short", "thick", "curved"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M223.2 197.26c.06 6.25-3.18 13.75-14.66 16.86-10.94 3.21-28.33-1.22-41.97-12.17-12.69-9.66-22.23-24.8-24.68-36.92-.23-1.12-.4-2.2-.5-3.26-1.27-11.59 2.53-16.85 7.72-17.13h.13c.24 0 .48 0 .72.01 7.91.19 15.58 2.15 22.32 5.8 5.28 3.09 4.11 6.63.12 7.04.69.49 1.4.98 2.11 1.47 19.57 14.38 33.76 15.42 41.17 22.7 3.9 3.91 7.37 9.12 7.52 15.6z" />
      ),
      basic: {
        dark: (
          <path d="M221.11 207.67c2.4-4.38-6.72-1.7-8.75-1.53-3.76 1.05-7.62 1.85-10.71-.72-2.58-.69-5.37-.22-7.65-1.96-3.62-.51-7.41.03-10.97-1.39-3.75-.39-5.04-3.4-6.73-6.16-3.67-2.3-8.26-3-12.23-4.79-3.69-.76-4.58-4.48-6.65-7.07-8.23-5.46-13.09-14.42-17.87-22.84 1.26 18.23 14.54 37.04 27.52 49.46 15.59 10.25 41.32 12.69 54.04-3z" />
        ),
        light: (
          <path d="M225.07 193.76c.46 7.35-4.81-.48-7.19-1.29-1.81-.06-2.39 3.13-4.2 3.08-1.45-.04-1.95-2.08-3.27-2.68-1.88-.84-3.59 1.64-5.62 1.98-6.24.69-4.49-12.8-11.45-9.73-2.64 2.9-8.42-3.69-12.66.25-3.74 3.25-4.2-4.49-6.67-5.65-6.75-1.41-10.53-8.31-14.23-13.82.45-2.52-.08-4.81-2.1-6.66-1.47-2.41-8.49-6.9-4.14-9.16-.42-.57-.84-1.14-1.25-1.72 8.74-3.78 19.67-1.82 26.54 4.77-5.38.79-10.45-3.93-16.09-3.07-.64 1.8 1.62 2.62 3.01 2.91 6.33 2.68 11.17 8.32 17.82 10.69 15.04 8.36 37.97 9.8 41.5 30.1z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M276.8 197.26c-.06 6.25 3.18 13.75 14.66 16.86 10.94 3.21 28.33-1.22 41.97-12.17 12.69-9.66 22.23-24.8 24.68-36.92.23-1.12.4-2.2.5-3.26 1.27-11.59-2.53-16.85-7.72-17.13h-.13c-.24 0-.48 0-.72.01-7.91.19-15.58 2.15-22.32 5.8-5.28 3.09-4.11 6.63-.12 7.04-.69.49-1.4.98-2.11 1.47-19.57 14.38-33.76 15.42-41.17 22.7-3.9 3.91-7.37 9.12-7.52 15.6z" />
      ),
      basic: {
        dark: (
          <path d="M279.04 207.67c-2.4-4.38 6.72-1.7 8.75-1.53 3.76 1.05 7.62 1.85 10.71-.72 2.58-.69 5.37-.22 7.65-1.96 3.62-.51 7.41.03 10.97-1.39 3.75-.39 5.04-3.4 6.73-6.16 3.67-2.3 8.26-3 12.23-4.79 3.69-.76 4.58-4.48 6.65-7.07 8.23-5.46 13.09-14.42 17.87-22.84-1.26 18.23-14.54 37.04-27.52 49.46-15.58 10.25-41.31 12.69-54.04-3z" />
        ),
        light: (
          <path d="M275.08 193.76c-.46 7.35 4.81-.48 7.19-1.29 1.81-.06 2.39 3.13 4.2 3.08 1.45-.04 1.95-2.08 3.27-2.68 1.88-.84 3.59 1.64 5.62 1.98 6.24.69 4.49-12.8 11.45-9.73 2.64 2.9 8.42-3.69 12.66.25 3.74 3.25 4.2-4.49 6.67-5.65 6.75-1.41 10.53-8.31 14.23-13.82-.45-2.52.08-4.81 2.1-6.66 1.47-2.41 8.49-6.9 4.14-9.16.42-.57.84-1.14 1.25-1.72-8.74-3.78-19.67-1.82-26.54 4.77 5.38.79 10.45-3.93 16.09-3.07.64 1.8-1.62 2.62-3.01 2.91-6.33 2.68-11.17 8.32-17.82 10.69-15.03 8.36-37.96 9.8-41.5 30.1z" />
        ),
      },
    },
  },
};

export const horn_SBneR = {
  name: "Horn 15",
  description: "Short, slightly curved horns on the top of the head",
  tags: ["short", "curved", "top"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M205.34 177.52c-17.35-7.91-28.03-24.53-27.05-47.44-5.15-18.19-28.36 46.35.95 76.69 11.64 14.48 39.24-18.98 26.1-29.25z" />
      ),
      basic: {
        dark: (
          <path d="M209.35 192.89c-27.69-10.26-37.9-43.45-31.48-70.51-2.5.61-4.58 2.72-5.14 5.23.32-.11.66-.2.99-.26-15.73 24.86-3.46 64.52 16.54 83.84 2.25 1.96 8.66-1.38 4.56-3.96-23.08-18.36-31.66-52.74-20.57-79.98.53-.06 1.07-.07 1.6-.02-6.03 26.48 6.16 54.81 31.2 66.43 1.04.54 3.77.74 2.3-.77z" />
        ),
        light: (
          <path d="M208.49 182.54c-18.38-10.58-28.28-34.04-29.45-54.38 3-4.67-4.93-6.25-3.41-1.13-13.53 27.13 4.43 63.07 31.62 73.3 2.27-1.11 2.14-3.25-.7-3.23-25.58-12.88-38.66-42.27-30.78-69.82.48.68 1.3 1.2 1.97 1.85-7.52 51.08 49.24 69.25 30.75 53.41z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M294.66 177.52c17.35-7.91 28.03-24.53 27.05-47.44 5.15-18.19 28.36 46.35-.95 76.69-11.64 14.48-39.24-18.98-26.1-29.25z" />
      ),
      basic: {
        dark: (
          <path d="M290.33 192.89c27.69-10.26 37.9-43.45 31.48-70.51 2.5.61 4.58 2.72 5.14 5.23-.32-.11-.66-.2-.99-.26 15.73 24.86 3.46 64.52-16.54 83.84-2.25 1.96-8.66-1.38-4.56-3.96 23.08-18.36 31.66-52.74 20.57-79.98-.53-.06-1.07-.07-1.6-.02 6.03 26.48-6.16 54.81-31.2 66.43-1.04.54-3.78.74-2.3-.77z" />
        ),
        light: (
          <path d="M291.19 182.54c18.38-10.58 28.28-34.04 29.45-54.38-3-4.67 4.93-6.25 3.41-1.13 13.53 27.13-4.43 63.07-31.62 73.3-2.27-1.11-2.14-3.25.7-3.23 25.58-12.88 38.66-42.27 30.78-69.82-.48.68-1.3 1.2-1.97 1.85 7.52 51.08-49.24 69.25-30.75 53.41z" />
        ),
      },
    },
  },
};

export const horn_sKEBM = {
  name: "Horn 16",
  description:
    "Long, thick horns that spiral downwards from the sides of the head",
  tags: ["long", "thick", "spiral", "side"],
  preview: {
    viewbox: viewboxWide,
  },
  shape: {
    left: {
      base: (
        <path d="M185.19 215.25c11.87 3.57 34.89-16 27.89-26.13-39.14-56.68-124.59-23.32-132.53 40-3.65 29.11 12.82 61.17 37.87 78.92 3.46 2.78 7.88 5.67 12.28 7.49 33.12 14.2 56.79 2.93 64.99-15.08 8.41-18.27 1.35-43.28-22.18-62.68-5.02-4.13-8.44-5.22-9.1-4.36-.76.87 1.26 3.7 4.79 7.66 17.02 19.37 14.29 36.18 5.39 44.22-9.11 8.3-24.38 7.83-37.26-1.25-.07-.05-.14-.09-.22-.14-13.19-9.87-20.77-28.03-16.12-45.1 6.71-24.66 29.52-49.19 64.2-23.55z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M196.11 216.72c4.38-2.12 8.96-4.67 12.32-8.23 2.23-3.49-1.78-5.69-5.02-4.24-1.84.57-3.99 1.28-5.55.15-4.49-6.68-9.13-2.52-12.47-8.26-5.4-5.25-7.91 1.12-12.27.96-3.13-2.49-3.33-1.3-6.73-.7-1.66-.17-2.59-2.22-4.23-2.5-4.59.94-5.08 2.58-9.89-.6-7.65-3.35-7.84 1.96-11.63 6.18-4.36 1.44-9.47 3.46-11.43 7.99-5.39 4.61-5.32 5.66-7.36 12.32-2.71 4.26-8.73 6.85-7.64 12.73-1.66 1.66-2.91 3.62-1.64 6.09 1.5 2.62 2.15 5.33 1.54 8.3.18 7.22 6.02 13.05 9.25 19.23-1.86-28.15 20.73-62.84 52.08-53.34 6.89 3.39 12.84 8.35 20.67 3.92z" />
            <path d="M84.94 261.31c3 4.52 6.55 8.73 8.83 13.66 1.3 3.72 3.46 6.39 7.28 7.18 1.74.98 2.32 3.14 3.07 4.99 1.69 4.2 4.91 7.58 8.05 10.85 2.03 2.38 4.63 3.69 7.47 2.4 7.43 2.49 13.11 9.12 20.04 12.8 1.76-6.46 9.63 1.01 13.77-1.36 3.97-.46 8.12 5.29 11.94 2.33 3.12-4.16 2.18-5.23 7.48-3.24 2.22-.05 2.92-3.45 5.07-3.99 1.31-.09 2.6-.32 3.51-1.4 4.95-3.32 2.57-12.5 8.85-13.55 1.74-1.03.51-3.76 1.12-5.69 3.77-4.82 6.12-10.74 4.2-16.88 8.76-6.4 3.31 26.63 1.74 30.25-4.26 20.81-27.56 28.59-46.56 25.16-34.64-4.27-64.57-31.57-72-65.67-.35-1.52-2.42-12.97.81-9.68 1.72 3.66 2.48 8.54 5.33 11.84z" />
            <path d="M182.36 263.99c1.4-7.99-12.82-29.17-19.93-32.79-1.04.33-.91 1.84-.54 2.87 4.07 11.48 9.88 22.36 12.83 34.17 3.39 18.68 7.44 5.6 7.64-4.25z" />
          </>
        ),
        light: (
          <path d="M215.95 193.92c-1.57 3.29-5.24 3.73-5.87.02-1.46-2.47-5.66-.52-8.01-2.18-2.12-1.5-1.51-5-3.25-6.94-1.02-1.14-2.64-1.49-4.12-1.88-6.37-1.2-11.36-5.54-16.71-8.59-4.14 1.61-6.07 4.08-11.05 1.31-3.59-1.56-8.07-6.93-11.04-1.97-1.19.66-2.58-.38-3.92-.61-3.3-.56-5.56 3.79-8.89 4.11-1.97.19-4.5-.9-5.63.73-.66 7.38-.32 9.47-8.67 10.95-4.56 2.93-6.99 8.65-11.68 11.41-2.71 1.16-6.15-.24-8.63 1.36 3.31 6.29-4.64 13.64-6.59 19.69-1.55 2.65-2.16 5.53-.56 8.19.49 4.47-1.2 9.17 1.34 13.48 3.21 8.8 6.32 18.17 14.62 23.47 12.69 8.41 27.45 20.47 43.35 19.15 3.14-.86 5.78-2.96 8.78-4.21 3.7-1.54 8.53-2.3 9.91-6.06.17-4.1 5.73-4.27 5.36-8.53 3.07-14.52-6.56-16.65-5.75-27.4 4.46 3.98 19.56 19 14.3 24.73-2.61 3.95-.05 9.56-2.02 13.86-1.81 3.95-7.16 6.17-7.21 10.52-7.4 1.31-2.24 12.34-16.1 12.33-4.04.1-8.11-.76-12.12-.22-3.56.48-7.82 1.82-10.26-.82-1.47-1.59-1.8-4.33-3.82-5.1-8.33.02-15.67-5.1-23.44-7.78-3.12-.89-4.63-3.28-4.81-6.4-1.84-7.35-13.46-8.81-15.07-16.22-1.3-8.29-10.78-12.55-14.59-19.67-.32-6.84.63-13.94-.67-20.95.62-10.87 3-32.73 14.87-36.26 2.54-.19 4.93-.76 6.65-2.78 4-3.67 8.81-6.46 13.98-8.13 4.5-.99 8.27-3.44 11.69-6.48 9.32-7.39 20.92-11.87 32.8-12.67 16.61-1.44 32.94 5.82 43.94 18.16 4.52 4.88 12.03 11.06 8.89 18.38z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M314.81 215.25c-11.87 3.57-34.89-16-27.89-26.13 39.14-56.68 124.59-23.32 132.53 40 3.65 29.11-12.82 61.17-37.87 78.92-3.46 2.78-7.88 5.67-12.28 7.49-33.12 14.2-56.79 2.93-64.99-15.08-8.41-18.27-1.35-43.28 22.18-62.68 5.02-4.13 8.44-5.22 9.1-4.36.76.87-1.26 3.7-4.79 7.66-17.02 19.37-14.29 36.18-5.39 44.22 9.11 8.3 24.38 7.83 37.26-1.25.07-.05.14-.09.22-.14 13.19-9.87 20.77-28.03 16.12-45.1-6.71-24.66-29.52-49.19-64.2-23.55z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M317.68 264.18c-1.4-7.99 12.82-29.17 19.93-32.79 1.04.33.91 1.84.54 2.87-4.07 11.48-9.88 22.36-12.83 34.17-3.38 18.68-7.44 5.6-7.64-4.25z" />
            <path d="M415.1 261.5c-3 4.52-6.55 8.73-8.83 13.66-1.3 3.72-3.46 6.39-7.28 7.18-1.74.98-2.32 3.14-3.07 4.99-1.69 4.2-4.91 7.58-8.05 10.85-2.03 2.38-4.63 3.69-7.47 2.4-7.43 2.49-13.11 9.12-20.04 12.8-1.76-6.46-9.63 1.01-13.77-1.36-3.97-.46-8.12 5.29-11.94 2.33-3.12-4.16-2.18-5.23-7.48-3.24-2.22-.05-2.92-3.45-5.07-3.99-1.31-.09-2.6-.32-3.51-1.4-4.95-3.32-2.57-12.5-8.85-13.55-1.74-1.03-.51-3.76-1.12-5.69-3.77-4.82-6.12-10.74-4.2-16.88-8.76-6.4-3.31 26.63-1.74 30.25 4.26 20.81 27.56 28.59 46.56 25.16 34.64-4.27 64.57-31.57 72-65.67.35-1.52 2.42-12.97-.81-9.68-1.71 3.66-2.48 8.55-5.33 11.84z" />
            <path d="M303.93 216.91c-4.38-2.12-8.96-4.67-12.32-8.23-2.23-3.49 1.78-5.69 5.02-4.24 1.84.57 3.99 1.28 5.55.15 4.49-6.68 9.13-2.52 12.47-8.26 5.4-5.25 7.91 1.12 12.27.96 3.13-2.49 3.33-1.3 6.73-.7 1.66-.17 2.59-2.22 4.23-2.5 4.59.94 5.08 2.58 9.89-.6 7.65-3.35 7.84 1.96 11.63 6.18 4.36 1.44 9.47 3.46 11.43 7.99 5.39 4.61 5.32 5.66 7.36 12.32 2.71 4.26 8.73 6.85 7.64 12.73 1.66 1.66 2.91 3.62 1.64 6.09-1.5 2.62-2.15 5.33-1.54 8.3-.18 7.22-6.02 13.05-9.25 19.23 1.86-28.15-20.73-62.84-52.08-53.34-6.89 3.39-12.84 8.35-20.67 3.92z" />
          </>
        ),
        light: (
          <path d="M284.1 194.11c1.57 3.29 5.24 3.73 5.87.02 1.46-2.47 5.66-.52 8.01-2.18 2.12-1.5 1.51-5 3.25-6.94 1.02-1.14 2.64-1.49 4.12-1.88 6.37-1.2 11.36-5.54 16.71-8.59 4.14 1.61 6.07 4.08 11.05 1.31 3.59-1.56 8.07-6.93 11.04-1.97 1.19.66 2.58-.38 3.92-.61 3.3-.56 5.56 3.79 8.89 4.11 1.97.19 4.5-.9 5.63.73.66 7.38.32 9.47 8.67 10.95 4.56 2.93 6.99 8.65 11.68 11.41 2.71 1.16 6.15-.24 8.63 1.36-3.31 6.29 4.64 13.64 6.59 19.69 1.55 2.65 2.16 5.53.56 8.19-.49 4.47 1.2 9.17-1.34 13.48-3.21 8.8-6.32 18.17-14.62 23.47-12.69 8.41-27.45 20.47-43.35 19.15-3.14-.86-5.78-2.96-8.78-4.21-3.7-1.54-8.53-2.3-9.91-6.06-.17-4.1-5.73-4.27-5.36-8.53-3.07-14.52 6.56-16.65 5.75-27.4-4.46 3.98-19.56 19-14.3 24.73 2.61 3.95.05 9.56 2.02 13.86 1.81 3.95 7.16 6.17 7.21 10.52 7.4 1.31 2.24 12.34 16.1 12.33 4.04.1 8.11-.76 12.12-.22 3.56.48 7.82 1.82 10.26-.82 1.47-1.59 1.8-4.33 3.82-5.1 8.33.02 15.67-5.1 23.44-7.78 3.12-.89 4.63-3.28 4.81-6.4 1.84-7.35 13.46-8.81 15.07-16.22 1.3-8.29 10.78-12.55 14.59-19.67.32-6.84-.63-13.94.67-20.95-.62-10.87-3-32.73-14.87-36.26-2.54-.19-4.93-.76-6.65-2.78-4-3.67-8.81-6.46-13.98-8.13-4.5-.99-8.27-3.44-11.69-6.48-9.32-7.39-20.92-11.87-32.8-12.67-16.61-1.44-32.94 5.82-43.94 18.16-4.53 4.88-12.04 11.07-8.89 18.38z" />
        ),
      },
    },
  },
};

export const horn_zkrvm = {
  name: "Horn 17",
  description:
    "Mid-length horns that go straight out from the side of the head and sweep up into a curve at the end",
  tags: ["curve", "straight", "side"],
  preview: {
    viewbox: viewboxWide,
  },
  shape: {
    left: {
      base: (
        <path d="M196.49 204.09c-3.91 7.87-8.55 13.25-14.65 12.17-13.38-1.2-32.18-.64-40.85-.46-35.73.72-55.83-46.37-33.64-68.23 8.79-8.47 11.35-3.73 7.73 4.01-.15.32-.29.65-.39.98-5.72 18.05 8.39 31.16 18.86 30.53 19.62-.21 39.4-1.07 58.98 1.47 3.4.44 6.11 3.16 6.48 6.57.4 3.61-.43 8.28-2.52 12.96z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M151.97 212.46c-7.51-.27-15.07-.69-22.39-2.42s-14.44-4.86-19.96-9.96c-9.27-8.58-12.96-21.74-13.65-34.35 1.75 2.66 2.35 5.88 3.31 8.92 3.05 9.6 10.14 17.63 18.73 22.88s18.62 7.86 28.65 8.68c17.24 1.4 34.72-2.27 51.8.41.19.03-2.27 3.97-2.57 4.3-1.34 1.49-2.02 1.6-4.03 1.68-4.37.17-8.76.21-13.14.24-8.91.07-17.84-.06-26.75-.38z" />
            <path d="M177.52 202.09c-12.14.56-24.43 1.11-36.33-1.37-11.9-2.48-23.55-8.37-30.51-18.33-1.91-2.74-3.45-5.75-4.54-8.91-1.97-5.69-2.48-11.95-.99-17.79s5.04-11.21 10.1-14.48c-4.66 5.58-8.29 12.45-8.19 19.72.04 2.64.57 5.26 1.31 7.8 1.3 4.45 3.26 8.72 6.02 12.44 6.46 8.72 16.89 13.84 27.52 16.04 10.63 2.2 21.61 1.79 32.46 1.36 8.86-.35 18.28-1.64 27.11-.99-.34 4.52-5.3 3.64-8.51 3.79-5.16.25-10.3.48-15.45.72z" />
          </>
        ),
        light: (
          <path d="M152.76 192.04c-6.84-.68-13.73-1.51-20.23-3.75s-12.65-6.03-16.62-11.64c-3.73-5.26-5.33-11.9-4.81-18.32.51-6.42 3.07-12.61 6.92-17.78 1.15 5.24-1.64 10.49-2.08 15.84-.38 4.67 1.05 9.32 2.94 13.61 1.15 2.62 2.51 5.2 4.5 7.26 3.45 3.55 8.41 5.14 13.22 6.31 18.64 4.51 38.09 4.48 57.21 3.01 2.26-.17 4.66-.34 6.7.65 1.35.66 2.81 2.3 2.74 3.91-.11 2.55-3.02 1.93-5.06 2.09-4.98.38-9.98.54-14.97.54-10.19-.02-20.34-.73-30.46-1.73z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M303.54 204.18c3.91 7.87 8.55 13.25 14.65 12.17 13.38-1.2 32.18-.64 40.85-.46 35.73.72 55.83-46.37 33.64-68.23-8.79-8.47-11.35-3.73-7.73 4.01.15.32.29.65.39.98 5.72 18.05-8.39 31.16-18.86 30.53-19.62-.21-39.4-1.07-58.98 1.47-3.4.44-6.11 3.16-6.48 6.57-.4 3.6.42 8.28 2.52 12.96z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M348.06 212.54c7.51-.27 15.07-.69 22.39-2.42s14.44-4.86 19.96-9.96c9.27-8.58 12.96-21.74 13.65-34.35-1.75 2.66-2.35 5.88-3.31 8.92-3.05 9.6-10.14 17.63-18.73 22.88-8.6 5.25-18.62 7.86-28.65 8.68-17.24 1.4-34.72-2.27-51.8.41-.19.03 2.27 3.97 2.57 4.3 1.34 1.49 2.02 1.6 4.03 1.68 4.37.17 8.76.21 13.14.24 8.91.07 17.83-.05 26.75-.38z" />
            <path d="M322.5 202.18c12.14.56 24.43 1.11 36.33-1.37 11.9-2.48 23.55-8.37 30.51-18.33 1.91-2.74 3.45-5.75 4.54-8.91 1.97-5.69 2.48-11.95.99-17.79s-5.04-11.21-10.1-14.48c4.66 5.58 8.29 12.45 8.19 19.72-.04 2.64-.57 5.26-1.31 7.8-1.3 4.45-3.26 8.72-6.02 12.44-6.46 8.72-16.89 13.84-27.52 16.04-10.63 2.2-21.61 1.79-32.46 1.36-8.86-.35-18.28-1.64-27.11-.99.34 4.52 5.3 3.64 8.51 3.79 5.17.24 10.31.48 15.45.72z" />
          </>
        ),
        light: (
          <path d="M347.26 192.12c6.84-.68 13.73-1.51 20.23-3.75s12.65-6.03 16.62-11.64c3.73-5.26 5.33-11.9 4.81-18.32-.51-6.42-3.07-12.61-6.92-17.78-1.15 5.24 1.64 10.49 2.08 15.84.38 4.67-1.05 9.32-2.94 13.61-1.15 2.62-2.51 5.2-4.5 7.26-3.45 3.55-8.41 5.14-13.22 6.31-18.64 4.51-38.09 4.48-57.21 3.01-2.26-.17-4.66-.34-6.7.65-1.35.66-2.81 2.3-2.74 3.91.11 2.55 3.02 1.93 5.06 2.09 4.98.38 9.98.54 14.97.54 10.19-.01 20.35-.72 30.46-1.73z" />
        ),
      },
    },
  },
};

export const horn_krtQg = {
  name: "Horn 18",
  description:
    "Very long horns that go straight out from the side of the head and sweep up into a curve at the end",
  tags: ["curve", "straight", "side", "long"],
  preview: {
    viewbox: viewboxExtraTallWide,
  },
  shape: {
    left: {
      base: (
        <path d="M196.49 204.09c-6.21 14.11-13.16 12.55-24.83 11.59-8.83-.29-18.26-.14-25.16 0-26.34.53-67.99 1.87-84.72 0-29.63-3.3-52.8-46.75-30.85-68.11 9.6-9.19 11.14-2.63 7.33 4.99-5.46 18.51 10.73 28.23 20.07 30.57 10.08 2.52 73.67-.04 75.21-.04 19.61-.21 39.39-1.07 58.98 1.47 9.21 1.9 7.11 12.13 3.97 19.53z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M140.93 209.81c-28.13.47-56.45 3.28-84.25-1.8-4.59-.84-9.19-1.91-13.45-4.09-13.72-7.04-21.78-24.8-23.91-42.41 4.3 17.14 15.79 31.49 29.97 37.4 9 3.75 18.7 4.21 28.23 4.38 40.95.71 81.97-3.32 122.83-.04.64.05-1.48 8.32-3.95 8.77-3.67.67-8.44-.79-12.18-1.05-9.15-.64-18.32-1.03-27.49-1.16-5.27-.09-10.54-.09-15.8 0z" />
            <path d="M110.25 198.64c-12.51.47-25.04 1.27-37.55.62-14.14-.74-29.28-3.94-38.73-14.47-3.45-3.85-5.95-8.49-7.63-13.38-3.13-9.11-2.84-20.62 4.58-26.77-4.81 9.3-4.4 20.95.51 30.2s14.01 16.01 24.13 18.69c4.94 1.3 10.07 1.69 15.17 1.94 30.67 1.53 61.31-1.39 91.98-1.55 7.56-.04 15.12.08 22.66.49 2.4.13 19.81.73 18.91 3.17-.8 2.17-6.18 1.5-7.71 1.45-3.82-.11-7.65-.21-11.47-.31-7.74-.2-15.48-.37-23.23-.49-17.2-.26-34.42-.24-51.62.41z" />
          </>
        ),
        light: (
          <path d="M74.06 192.29c-7.7-.08-15.61-.37-22.72-3.34-14.12-5.91-22.46-23.05-18.37-37.8 1.36-4.92 4.64-10.01 9.69-10.71-2.76 8.93-5.53 18.59-2.49 27.42 3.12 9.1 11.96 15.29 21.24 17.82 9.28 2.54 19.08 2.01 28.69 1.49 36.52-1.96 73.19-3.53 109.61-.24 2 .18 6.68 2.31 3.67 4.73-2.19 1.77-9.23-.16-11.8-.34-9.46-.64-18.95-.89-28.44-.9-19.02-.03-38.02.87-57.03 1.44-10.68.32-21.37.54-32.05.43z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M303.49 204.09c6.21 14.11 13.16 12.55 24.83 11.59 8.83-.29 18.26-.14 25.16 0 26.34.53 67.99 1.87 84.72 0 29.63-3.3 52.8-46.75 30.85-68.11-9.6-9.19-11.14-2.63-7.33 4.99 5.46 18.51-10.73 28.23-20.07 30.57-10.08 2.52-73.67-.04-75.21-.04-19.61-.21-39.39-1.07-58.98 1.47-9.2 1.9-7.1 12.13-3.97 19.53z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M389.73 198.64c12.51.47 25.04 1.27 37.55.62 14.14-.74 29.28-3.94 38.73-14.47 3.45-3.85 5.95-8.49 7.63-13.38 3.13-9.11 2.84-20.62-4.58-26.77 4.81 9.3 4.4 20.95-.51 30.2-4.9 9.25-14.01 16.01-24.13 18.69-4.94 1.3-10.07 1.69-15.17 1.94-30.67 1.53-61.31-1.39-91.98-1.55-7.56-.04-15.12.08-22.66.49-2.4.13-19.81.73-18.91 3.17.8 2.17 6.18 1.5 7.71 1.45 3.82-.11 7.65-.21 11.47-.31 7.74-.2 15.48-.37 23.23-.49 17.2-.26 34.42-.24 51.62.41z" />
            <path d="M359.05 209.81c28.13.47 56.45 3.28 84.25-1.8 4.59-.84 9.19-1.91 13.45-4.09 13.72-7.04 21.78-24.8 23.91-42.41-4.3 17.14-15.79 31.49-29.97 37.4-9 3.75-18.7 4.21-28.23 4.38-40.95.71-81.97-3.32-122.83-.04-.64.05 1.48 8.32 3.95 8.77 3.67.67 8.44-.79 12.18-1.05 9.15-.64 18.32-1.03 27.49-1.16 5.27-.09 10.54-.09 15.8 0z" />
          </>
        ),
        light: (
          <path d="M425.92 192.29c7.7-.08 15.61-.37 22.72-3.34 14.12-5.91 22.46-23.05 18.37-37.8-1.36-4.92-4.64-10.01-9.69-10.71 2.76 8.93 5.53 18.59 2.49 27.42-3.12 9.1-11.96 15.29-21.24 17.82-9.28 2.54-19.08 2.01-28.69 1.49-36.52-1.96-73.19-3.53-109.61-.24-2 .18-6.68 2.31-3.67 4.73 2.19 1.77 9.23-.16 11.8-.34 9.46-.64 18.95-.89 28.44-.9 19.02-.03 38.02.87 57.03 1.44 10.68.32 21.37.54 32.05.43z" />
        ),
      },
    },
  },
};

export const horn_akeSD = {
  name: "Horn 19",
  description:
    "Long, twisted horns that sweep upwards in gentle curves from the top of the head",
  tags: ["curve", "straight", "twisted", "long", "top"],
  preview: {
    viewbox: viewboxExtraTall,
  },
  shape: {
    left: {
      base: (
        <path d="M211.46 164.22c.8.66 1.55 1.37 2.26 2.16 7.44 8.13 8.52 22.75.59 34.13-2.91 3.94-6.78 6.65-11.14 7.78-5.78 1.54-12.44.2-18.08-4.03-.07-.16-.13-.32-.19-.49-.2-.07-.4-.11-.59-.19-.07-.03-.14-.06-.2-.1-1.59-1.29-3.08-2.82-4.41-4.54-.37-.47-.71-.96-1.05-1.45-.81-1.67-1.42-3.58-1.84-5.66-.74-.64-1.41-1.39-2.02-2.27-1.57-2.26-2.67-5.29-3.22-8.7-2.36-2.41-3.93-6.39-4.48-10.98-2.11-2.57-3.43-6.56-3.77-11.05-1.86-2.69-2.93-6.68-3.08-11.07-1.61-2.8-2.43-6.78-2.39-11.08-1.34-2.9-1.92-6.87-1.69-11.08-1.09-2.98-1.43-6.92-1.02-11.05-.83-3.03-.93-6.96-.33-11-.58-3.08-.45-6.96.35-10.93-.33-3.1.04-6.95 1.03-10.84-.09-3.11.53-6.92 1.71-10.72.16-3.11 1.02-6.86 2.41-10.58.41-3.09 1.5-6.78 3.1-10.41 1.44-7.21 7.09-17.34 12.66-22.71 5.98-5.75 8.58-4.51 6.34 1.94-.73 2.23-1.89 4.83-3.24 7.56-.06.58-.17 1.23-.34 1.96-.53 2.25-1.47 4.89-2.63 7.66 0 .58-.05 1.23-.15 1.96-.32 2.26-1.05 4.92-2.02 7.73.05.57.08 1.22.04 1.95-.1 2.25-.63 4.92-1.42 7.76.12.57.21 1.2.24 1.92.11 2.23-.22 4.89-.84 7.75.19.55.34 1.17.44 1.87.32 2.19.18 4.84-.26 7.7.25.53.47 1.12.64 1.81.53 2.13.57 4.75.29 7.62.32.5.61 1.07.85 1.73.73 2.06.97 4.64.84 7.5.38.46.74 1 1.04 1.63.94 1.97 1.36 4.49 1.38 7.34.45.42.87.91 1.24 1.51 1.15 1.85 1.74 4.31 1.9 7.15.51.37.99.82 1.43 1.37 1.35 1.71 2.11 4.1 2.41 6.92.58.31 1.13.7 1.63 1.2 1.54 1.56 2.47 3.85 2.89 6.65.64.24 1.25.56 1.81 1.01 1.73 1.38 2.8 3.57 3.35 6.35.7.16 1.37.41 2 .78 1.92 1.17 3.17 3.24 3.82 6 .75.07 1.47.23 2.15.54 2.43 1.08 3.89 3.51 4.56 6.97.16.05.33.07.48.12 2.09.75 3.56 2.43 4.52 4.9z" />
      ),
      basic: {
        dark: (
          <path d="M215.2 200.88c1.9-2.89 3.75-7.26 1.43-10.23-1.02-.77-2.43.01-3.49.72-4.33 2.89-9.42 4.63-14.62 4.99-7.15.03-1.58-6.31.54-9 1.88-9.38-9.94 1.78-13.85-1.98 10.78-29.93-.16-5.37-9.84-12.02-4.1-5.13 5.65-11.14 2.84-16.46-2.86 1.12-11.22 6.25-8.34-.93 1.85-3.5 6.69-6.35 5.85-10.7-2.68-4.34-7.74 2.21-11.19 1.13-2.83-.83-2.97-4.97-1.49-7.51 16.53-21.11-15.31 2.26 2.73-21.68-14.7-2.05-3.75-9.18-6.1-12.56-5.55-2.6 1.65-7.92-.05-12.14-4.36-3.71.01-8.75.95-13.18-1.76.08-1.88-2.71-.84-4.13 1.05-1.42 2.67-2.89 2.16-4.58-4.16-9.12 5.7-22.34 5.66-32.17-24.76 41.17-19.63 100.17-1.8 143.2 4.17 9.32 9.48 17.37 16.34 24.91 9.14 9.42 26.32 4.34 33.11-5.68z" />
        ),
        light: (
          <path d="M220.63 177.26c.56 4.12-4.06 6.59-5.47 2.11l-1.79-8.32c-2.2-5.73-5.21 0-8.15 1.94-.97.64-2.26-.05-2.28-1.21l-.16-9.13c-.02-1.19-1.37-1.86-2.34-1.17-8.04 5.71-6.34 1.31-5.5-6.53.11-1.04-.87-1.87-1.87-1.58-1.19.34-2.52 2-3.49 1.23-2.47-1.97-4-6.07-4.05-7.09l1.41-5.49c-.05-.87-.84-1.54-1.7-1.38-1.69.32-3.44 1.4-4.85.49-2.89-2.21 1.02-6.18-.15-8.91-.73-1.75-3.16-2.6-3.29-4.49-.11-1.52 1.41-2.98 1.04-4.38-.05-.17-.14-.34-.26-.47-2.15-2.39-8.96.92-6.11-6.95 2.75-6.33.97-6.13-3.63-8-.38-.16-.7-.47-.83-.86-1.12-3.35 4.86-7.63-.37-9.59-.38-.14-.68-.46-.85-.83-2.01-4.48 4.68-4.75-.4-11.55-.22-.29-.34-.66-.3-1.02.52-4.81 4.23-4.38.92-10.32-.16-.29-.23-.63-.18-.95.94-5.91 4.58-4.35.21-10.94-.2-.3-.3-.67-.24-1.02.81-5.08 5.14-3.68 2.97-11.36-.06-.2-.08-.41-.05-.61.38-2.33 2.96-3.87 3.58-6.18.03-.11.04-.23.05-.34.22-8.11 5.66-16.42 11.99-21.49.85-.68 2.14-.22 2.36.84 1.3 6.29.02 12.94-1.2 19.32-6.66 33.22-10.61 71.68 10.58 100.51 8.74 11.82 24.08 19.53 24.4 35.72z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M288.54 164.22c-.8.66-1.55 1.37-2.26 2.16-7.44 8.13-8.52 22.75-.59 34.13 2.91 3.94 6.78 6.65 11.14 7.78 5.78 1.54 12.44.2 18.08-4.03.07-.16.13-.32.19-.49.2-.07.4-.11.59-.19.07-.03.14-.06.2-.1 1.59-1.29 3.08-2.82 4.41-4.54.37-.47.71-.96 1.05-1.45.81-1.67 1.42-3.58 1.84-5.66.74-.64 1.41-1.39 2.02-2.27 1.57-2.26 2.67-5.29 3.22-8.7 2.36-2.41 3.93-6.39 4.48-10.98 2.11-2.57 3.43-6.56 3.77-11.05 1.86-2.69 2.93-6.68 3.08-11.07 1.61-2.8 2.43-6.78 2.39-11.08 1.34-2.9 1.92-6.87 1.69-11.08 1.09-2.98 1.43-6.92 1.02-11.05.83-3.03.93-6.96.33-11 .58-3.08.45-6.96-.35-10.93.33-3.1-.04-6.95-1.03-10.84.09-3.11-.53-6.92-1.71-10.72-.16-3.11-1.02-6.86-2.41-10.58-.41-3.09-1.5-6.78-3.1-10.41-1.44-7.21-7.09-17.34-12.66-22.71-5.98-5.75-8.58-4.51-6.34 1.94.73 2.23 1.89 4.83 3.24 7.56.06.58.17 1.23.34 1.96.53 2.25 1.47 4.89 2.63 7.66 0 .58.05 1.23.15 1.96.32 2.26 1.05 4.92 2.02 7.73-.05.57-.08 1.22-.04 1.95.1 2.25.63 4.92 1.42 7.76-.12.57-.21 1.2-.24 1.92-.11 2.23.22 4.89.84 7.75-.19.55-.34 1.17-.44 1.87-.32 2.19-.18 4.84.26 7.7-.25.53-.47 1.12-.64 1.81-.53 2.13-.57 4.75-.29 7.62-.32.5-.61 1.07-.85 1.73-.73 2.06-.97 4.64-.84 7.5-.38.46-.74 1-1.04 1.63-.94 1.97-1.36 4.49-1.38 7.34-.45.42-.87.91-1.24 1.51-1.15 1.85-1.74 4.31-1.9 7.15-.51.37-.99.82-1.43 1.37-1.35 1.71-2.11 4.1-2.41 6.92-.58.31-1.13.7-1.63 1.2-1.54 1.56-2.47 3.85-2.89 6.65-.64.24-1.25.56-1.81 1.01-1.73 1.38-2.8 3.57-3.35 6.35-.7.16-1.37.41-2 .78-1.92 1.17-3.17 3.24-3.82 6-.75.07-1.47.23-2.15.54-2.43 1.08-3.89 3.51-4.56 6.97-.16.05-.33.07-.48.12-2.09.75-3.56 2.43-4.52 4.9z" />
      ),
      basic: {
        dark: (
          <path d="M284.53 200.71c-1.9-2.89-3.75-7.26-1.43-10.23 1.02-.77 2.43.01 3.49.72 4.33 2.89 9.42 4.63 14.62 4.99 7.15.03 1.58-6.31-.54-9-1.88-9.38 9.94 1.78 13.85-1.98-10.78-29.93.16-5.37 9.84-12.02 4.1-5.13-5.65-11.14-2.84-16.46 2.86 1.12 11.22 6.25 8.34-.93-1.85-3.5-6.69-6.35-5.85-10.7 2.68-4.34 7.74 2.21 11.19 1.13 2.83-.83 2.97-4.97 1.49-7.51-16.53-21.11 15.31 2.26-2.73-21.68 14.7-2.05 3.75-9.18 6.1-12.56 5.55-2.6-1.65-7.92.05-12.14 4.36-3.71-.01-8.75-.95-13.18 1.76.08 1.88-2.71.84-4.13-1.05-1.42-2.67-2.89-2.16-4.58 4.16-9.12-5.7-22.34-5.66-32.17 24.76 41.17 19.63 100.17 1.8 143.2-4.17 9.32-9.48 17.37-16.34 24.91-9.14 9.42-26.32 4.34-33.11-5.68z" />
        ),
        light: (
          <path d="M279.11 177.09c-.56 4.12 4.06 6.59 5.47 2.11l1.79-8.32c2.2-5.73 5.21 0 8.15 1.94.97.64 2.26-.05 2.28-1.21l.16-9.13c.02-1.19 1.37-1.86 2.34-1.17 8.04 5.71 6.34 1.31 5.5-6.53-.11-1.04.87-1.87 1.87-1.58 1.19.34 2.52 2 3.49 1.23 2.47-1.97 4-6.07 4.05-7.09l-1.41-5.49c.05-.87.84-1.54 1.7-1.38 1.69.32 3.44 1.4 4.85.49 2.89-2.21-1.02-6.18.15-8.91.73-1.75 3.16-2.6 3.29-4.49.11-1.52-1.41-2.98-1.04-4.38.05-.17.14-.34.26-.47 2.15-2.39 8.96.92 6.11-6.95-2.75-6.33-.97-6.13 3.63-8 .38-.16.7-.47.83-.86 1.12-3.35-4.86-7.63.37-9.59.38-.14.68-.46.85-.83 2.01-4.48-4.68-4.75.4-11.55.22-.29.34-.66.3-1.02-.52-4.81-4.23-4.38-.92-10.32.16-.29.23-.63.18-.95-.94-5.91-4.58-4.35-.21-10.94.2-.3.3-.67.24-1.02-.81-5.08-5.14-3.68-2.97-11.36.06-.2.08-.41.05-.61-.38-2.33-2.96-3.87-3.58-6.18-.03-.11-.04-.23-.05-.34-.22-8.11-5.66-16.42-11.99-21.49-.85-.68-2.14-.22-2.36.84-1.3 6.29-.02 12.94 1.2 19.32 6.66 33.22 10.61 71.68-10.58 100.51-8.75 11.82-24.08 19.53-24.4 35.72z" />
        ),
      },
    },
  },
};

export const horn_OIlzY = {
  name: "Horn 20",
  description:
    "Long horns that sweep backwards and then curve sharply upwards from the side of the head",
  tags: ["long", "curve", "side"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M202.18 188.76c-9.13-12.15-21.79-16.22-39.32-17.72 3.38-12.59 13.9-30.58 36.84-48.23 3.44-2.59 5.37-8.81 1.81-8.12-27.36 5.18-64.17 30.7-88.97 73.94-7.31 14.22 25.55 5.95 41.4 18.64 18.64 12.73 27.14 25.05 47.6 9.27 14.23-10.98 8.15-17.99.64-27.78z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M187.97 197.79c-6.46-5.79-13.9-10.67-22.21-13.13-6.82-2.02-14-2.37-21.1-2.68-3.26-.14-6.54-.28-9.78.07-3.99.43-8.06 1.59-11.95.59-.96-.25-1.93-.66-2.52-1.45-1.22-1.62-.34-3.92.62-5.7 5.3-9.85 12.23-18.81 20.42-26.42 1.43-1.33 2.97-2.66 4.84-3.21-7.6 7.77-14.25 16.45-19.77 25.81-.98 1.65-1.89 3.86-.68 5.35 1.01 1.24 2.89 1.18 4.49 1.02 14.78-1.51 30.38-1.95 43.74 4.53 13.58 6.58 22.93 19.34 31.72 31.61.42.59 1.19 1.91.15 2.33-.85.34-1.82-1.27-2.23-1.76-1.66-1.97-3.31-3.95-5-5.89-3.39-3.88-6.91-7.64-10.74-11.07z" />
            <path d="M183.41 197.62c-7.87-5.96-16.91-10.71-26.74-12.49-14.96-2.71-40.21.78-40.71-5.3.61 1.34-6.18 1.8-4.85 2.54 5.5 3.04 20.67 3.93 29.06 3.67 8.39-.26 17 .17 24.75 3.29 8.41 3.39 15.14 9.67 21.66 15.8 4.42 4.16 8.84 8.32 13.25 12.48 1.81 1.71 6 1.63 3.5-1.17-2.05-2.29-4.22-4.51-6.38-6.7-4.24-4.32-8.69-8.45-13.54-12.12z" />
            <path d="M164.21 207.4c-10.09-7.66-23.21-10.25-35.88-10.45-4.99-.08-10.05.17-14.9-1.02-1.51-.37-3.12-.99-3.84-2.36-.96-1.81.11-4.12 1.73-5.37-.19 1.06.49 2.12 1.4 2.69.91.57 2.01.75 3.07.89 8.45 1.12 17.02.58 25.54.95s17.26 1.74 24.51 6.22c3.81 2.36 7.06 5.5 10.29 8.61 5.3 5.12 11.54 10.04 16.21 15.72-5.11 4.61-10.31.36-14.39-3.18-4.71-4.08-8.75-8.91-13.74-12.7zm35.12-91.32c-2.87 1.25-5.54 2.96-8.15 4.75-18.78 12.88-32.93 29.57-44.83 49.56 2.33-.35 2.75-.02 5.11-.05.96-.01 1.94-.01 2.83-.36 1.77-.69 2.8-2.59 3.72-4.33 8.82-16.65 21.09-31.26 35.72-42.51 2.65-2.04 5.39-3.98 8.41-5.36.9-.41 3.38-1.47 2.18-2.81-.99-1.1-3.98.66-4.99 1.11z" />
          </>
        ),
        light: (
          <path d="M187.45 213.33c-15.81-19.91-34.27-26.08-59.24-24.71-4.17-.18-16.69-.61-18.98-3.49 3.78 6.85 34.49 3.36 44.19 7.54 16.18 2.7 27.41 15.35 38.21 26.63.74.8 2.75 4.1 3.97 2.83-2.22-3.17-5.47-5.98-8.15-8.8zm22.21-6.57c-8.91-19.8-28.6-34.08-50.22-36.27 12.16-22.92 31.42-41.19 50.54-58.35-14.26 6.19-23.62 19.32-33.25 30.95-7.38 9.06-14.27 15.91-19.09 27.23-4.98-1.76-19.01 4.03-17.79-4.6 8.77-26.65 38.44-38.99 61.43-51.78-13.43-7.48-81.68 47.41-65.76 58.75 32.12 3.08 50.41 2.19 69.67 32.78.78 1.28 1.67 4.01 3.15 4.57 2.36.89 1.92-1.98 1.32-3.28z" />
        ),
      },
    },
    right: {
      base: (
        <path d="M297.82 188.76c9.13-12.15 21.79-16.22 39.32-17.72-3.38-12.59-13.9-30.58-36.84-48.23-3.44-2.59-5.37-8.81-1.81-8.12 27.36 5.18 64.17 30.7 88.97 73.94 7.31 14.22-25.55 5.95-41.4 18.64-18.64 12.73-27.14 25.05-47.6 9.27-14.23-10.98-8.15-17.99-.64-27.78z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M300.52 116.09c2.87 1.25 5.54 2.96 8.15 4.75 18.78 12.88 34.6 29.67 46.51 49.66-2.33-.35-4.43-.11-6.79-.15-.96-.01-1.94-.01-2.83-.36-1.77-.69-2.8-2.59-3.72-4.33-8.82-16.65-21.09-31.26-35.72-42.51-2.65-2.04-5.39-3.98-8.41-5.36-.9-.41-3.38-1.47-2.18-2.81.99-1.09 3.98.67 4.99 1.11zm35.11 91.33c10.09-7.66 23.21-10.25 35.88-10.45 4.99-.08 10.05.17 14.9-1.02 1.51-.37 3.12-.99 3.84-2.36.96-1.81-.11-4.12-1.73-5.37.19 1.06-.49 2.12-1.4 2.69-.91.57-2.01.75-3.07.89-8.45 1.12-17.02.58-25.54.95s-17.26 1.74-24.51 6.22c-3.81 2.36-7.06 5.5-10.29 8.61-5.3 5.12-11.54 10.04-16.21 15.72 5.11 4.61 10.31.36 14.39-3.18 4.71-4.08 8.76-8.91 13.74-12.7z" />
            <path d="M316.43 197.63c7.87-5.96 16.91-10.71 26.74-12.49 14.96-2.71 40.21.78 40.71-5.3-.61 1.34 6.18 1.8 4.85 2.54-5.5 3.04-20.67 3.93-29.06 3.67-8.39-.26-17 .17-24.75 3.29-8.41 3.39-15.14 9.67-21.66 15.8-4.42 4.16-8.84 8.32-13.25 12.48-1.81 1.71-6 1.63-3.5-1.17 2.05-2.29 4.22-4.51 6.38-6.7 4.25-4.31 8.7-8.45 13.54-12.12z" />
            <path d="M311.88 197.81c6.46-5.79 13.9-10.67 22.21-13.13 6.82-2.02 14-2.37 21.1-2.68 3.26-.14 6.54-.28 9.78.07 3.99.43 8.06 1.59 11.95.59.96-.25 1.93-.66 2.52-1.45 1.22-1.62.34-3.92-.62-5.7-5.3-9.85-12.23-18.81-20.42-26.42-1.43-1.33-2.97-2.66-4.84-3.21 7.6 7.77 14.25 16.45 19.77 25.81.98 1.65 1.89 3.86.68 5.35-1.01 1.24-2.89 1.18-4.49 1.02-14.78-1.51-30.38-1.95-43.74 4.53-13.58 6.58-22.93 19.34-31.72 31.61-.42.59-1.19 1.91-.15 2.33.85.34 1.82-1.27 2.23-1.76 1.66-1.97 3.31-3.95 5-5.89 3.39-3.88 6.91-7.64 10.74-11.07z" />
          </>
        ),
        light: (
          <path d="M366.93 168.13c-5.26-20.36-47.26-56.38-68.37-54.18 22.97 12.78 52.67 25.14 61.43 51.78 1.23 8.6-12.65 2.87-17.6 4.58-5.1-11.19-11.85-18.16-19.28-27.21-9.63-11.63-18.99-24.77-33.25-30.95 19.27 17.12 38.16 35.59 50.72 58.33-21.68 2.13-41.47 16.44-50.4 36.29-.59 1.3-1.03 4.17 1.31 3.27 1.48-.57 2.37-3.3 3.15-4.57 26.65-46.32 73.93-22.8 72.29-37.34zm-44.8 37.41c3.14-2.85 6.4-5.62 10.08-7.72 4.39-2.5 9.29-3.99 14.21-5.13 8.81-2.05 17.87-3.06 26.92-3.02 2.61.01 5.16-.38 7.74-.79 4.14-.66 9.46-1.13 9.53-3.74-1.12 2.44-8.4 2.13-10.99 2.84s-5.31.67-8 .64c-7.86-.08-15.78.04-23.51 1.48s-15.32 4.28-21.48 9.18c-2.17 1.72-4.13 3.68-6.08 5.65-2.75 2.78-5.47 5.58-8.17 8.41-2.68 2.82-5.93 5.63-8.16 8.8 1.23 1.27 3.24-2.04 3.97-2.83 1.48-1.6 2.98-3.18 4.5-4.73 3.07-3.1 6.21-6.11 9.44-9.04z" />
        ),
      },
    },
  },
};

export const horn_8VORY = {
  name: "Horn 21",
  description: "Short, thick antlers on the side of the head",
  tags: ["short", "thick", "antler", "side"],
  preview: {
    viewbox: viewbox,
  },
  shape: {
    left: {
      base: (
        <path d="M191.46 180.9c-25.19-9.49-45.79-33.71-45.16-65.25.54-8.58-8.69-11.63-11.73-1.66-4.5 16.72-3.55 34.64 2.55 51.25-8.25 2.2-18.16 1.53-27.53-3.51-20.41-4.89 13.97 31.12 40.87 27.6 3.75 4.53 14.39 16.83 24.01 22.06 2.92 2.01 6.48 3.2 10.33 3.2 9.88 0 17.9-7.77 17.9-17.36-.01-6.1-3.08-13.25-11.24-16.33z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M172.45 205.94c-4.94-3.95-9.44-8.42-14.26-12.52-2.05-1.74-4.18-3.42-6.62-4.55-2.68-1.24-5.63-1.75-8.53-2.3-13.28-2.53-27.06-6.49-36.48-16.18 7.29 6.23 16.99 8.7 26.37 10.75 9.37 2.04 19.09 3.96 26.99 9.4 4.51 3.1 8.21 7.21 12.33 10.81 6.22 5.42 13.45 9.67 21.2 12.47-3.26 2.65-7.26.45-10.5-1.1-3.77-1.81-7.24-4.18-10.5-6.78zm-30.87-42.86c-2.82-6.61-5.5-13.35-6.76-20.43-1.95-10.94-.43-22.18 1.36-33.14-3.7 8.53-4.39 18.13-3.38 27.37.58 5.32 1.7 10.57 3.05 15.74.67 2.56 1.41 5.11 2.17 7.65.21.7.53 3.15 1.1 3.55.35.25 2.62-.36 2.46-.74z" />
            <path d="M154.78 184.74c-5.05-2.1-10.4-3.32-15.73-4.53l-7.92-1.8c7.72.63 15.39 1.86 22.91 3.67 1.63.39 3.27.82 4.81 1.51 2 .89 3.77 2.21 5.53 3.52 3.2 2.38 6.4 4.75 9.49 7.27 2.56 2.08 5.03 4.26 7.65 6.26 5.25 4.01 11.04 7.31 17.17 9.78-.36-.14-1.57 1.16-1.98 1.26-.84.21-1.18-.05-2.01-.44-1.58-.75-3.1-1.67-4.59-2.59-2.96-1.82-5.79-3.86-8.56-5.95-5.48-4.14-10.75-8.58-16.49-12.37-3.25-2.15-6.66-4.09-10.28-5.59zm21.67-.79c-7.49-5.38-14.56-11.46-20.2-18.75-5.64-7.3-9.8-15.89-11.01-25.03 3.36 11.32 9.99 21.54 18.39 29.84s18.52 14.74 29.27 19.63c2.51 1.14 5.06 2.21 7.71 2.94.92.26 1.87.48 2.66 1 .8.52 1.43 1.42 1.33 2.37-.33 3.21-4.18.87-6.19.14-2.66-.96-5.25-2.11-7.77-3.38-4.97-2.5-9.67-5.51-14.19-8.76zm-45.93-9.14c-8.17-1.65-16.41-4.74-22-10.92 5.61 4.18 12.09 7.19 18.91 8.77 6.15 1.43 12.51 1.7 18.74 2.72 6.23 1.01 12.55 2.88 17.36 6.97-2.45-1.26-5.14-3.01-7.83-3.67-2.24-.56-4.72-.73-7.01-1.11-6.04-1-12.16-1.55-18.17-2.76zm6.12-54.43c-.21 7.13.46 14.29 1.97 21.26 1.54 7.12 4.52 13.38 6.93 20.15-1.18.81-1.48 1.19-2.26.18-.64-.84-.99-2.72-1.39-3.73-1.17-2.94-2.2-5.94-3.06-8.99-1.71-6.06-2.77-12.33-2.88-18.63-.05-3.46.59-6.82.69-10.24z" />
          </>
        ),
        light: (
          <>
            <path d="M189.51 184.68c-14.41-7.7-27.39-18.55-35.92-32.48-8.53-13.93-12.32-31.08-8.62-46.99-.99 17.65 2.08 36.17 12.45 50.49 2.13 2.94 4.53 5.66 7.08 8.25 5.86 5.96 12.48 11.17 19.63 15.5 3.53 2.14 7.19 4.06 10.96 5.75 1.93.87 3.89 1.67 5.87 2.41.96.36 4.28 1.2 2.05 2.06-1.71.65-5.17-1.15-6.75-1.78-2.32-.93-4.55-2.03-6.75-3.21z" />
            <path d="M143.19 149.71c-3.43-12.92-6.32-26.51-3.48-39.57-1.76 13.5 1.11 27.11 5.58 39.82 1.28 3.63 3.63 9.12 5.23 12.62-2.94 3.04-6.92-11.29-7.33-12.87zm15.12 26.53c-2.83-1.67-5.76-3.25-8.94-4.11-3.22-.87-6.59-.97-9.93-1.12-6.19-.3-12.42-.83-18.4-2.47-5.98-1.64-11.75-4.43-16.18-8.77 6.08 0 8.26 8.16 34.68 7.84 3.95-.05 7.96.25 11.7 1.52 3.04 1.03 5.77 2.82 8.42 4.65 13.69 9.44 26.85 20.54 42.89 24.93 1.21.33 3.53 1.14 2.74 2.88-.69 1.5-2.71.69-3.86.31-3.83-1.28-7.52-3.08-11.06-5-7.11-3.86-13.71-8.55-20.38-13.09-3.83-2.62-7.69-5.2-11.68-7.57z" />
            <path d="M145.68 162.53c1.11-.29 2.24-.58 3.38-.5 2.38.16 4.33 1.86 6.07 3.49-1.93-.95-3.9-1.91-6.03-2.22-2.78-.4-5.53.36-8.18 1.11-2.57.73-5.62 1.83-8.32 1.32.65-.7 1.28-.51 2.1-.51 1.17.01 2.35-.45 3.47-.74 2.51-.65 5.01-1.3 7.51-1.95zm39.67 37.95c-5.4-3.57-10.59-7.46-15.55-11.61 9.78 6.71 19.75 13.51 31.05 17.11.62.2 1.28.41 1.67.93 1.46 1.97-1.69 1.1-2.34.92-1.77-.49-3.5-1.12-5.18-1.87-3.39-1.5-6.57-3.44-9.65-5.48z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <path d="M308.54 180.9c25.19-9.49 45.79-33.71 45.16-65.25-.54-8.58 8.69-11.63 11.73-1.66 4.5 16.72 3.55 34.64-2.55 51.25 8.25 2.2 18.16 1.53 27.53-3.51 20.41-4.89-13.97 31.12-40.87 27.6-3.75 4.53-14.39 16.83-24.01 22.06-2.92 2.01-6.48 3.2-10.33 3.2-9.88 0-17.9-7.77-17.9-17.36.01-6.1 3.08-13.25 11.24-16.33z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M363.59 120.27c.21 7.13-.46 14.29-1.97 21.26-1.54 7.12-4.52 13.38-6.93 20.15 1.18.81 1.48 1.19 2.26.18.64-.84.99-2.72 1.39-3.73 1.17-2.94 2.2-5.94 3.06-8.99 1.71-6.06 2.77-12.33 2.88-18.63.06-3.46-.59-6.81-.69-10.24zm-35.8 85.56c4.94-3.95 9.44-8.42 14.26-12.52 2.05-1.74 4.18-3.42 6.62-4.55 2.68-1.24 5.63-1.75 8.53-2.3 13.28-2.53 27.06-6.49 36.48-16.18-7.29 6.23-16.99 8.7-26.37 10.75-9.37 2.04-19.09 3.96-26.99 9.4-4.51 3.1-8.21 7.21-12.33 10.81-6.22 5.42-13.45 9.67-21.2 12.47 3.26 2.65 7.26.45 10.5-1.1 3.77-1.81 7.24-4.18 10.5-6.78z" />
            <path d="M358.65 162.97c2.82-6.61 5.5-13.35 6.76-20.43 1.95-10.94.43-22.18-1.36-33.14 3.7 8.53 4.39 18.13 3.38 27.37-.58 5.32-1.7 10.57-3.05 15.74-.67 2.56-1.41 5.11-2.17 7.65-.21.7-.53 3.15-1.1 3.55-.35.25-2.62-.36-2.46-.74zm-13.2 21.66c5.05-2.1 10.4-3.32 15.73-4.53l7.92-1.8c-7.72.63-15.39 1.86-22.91 3.67-1.63.39-3.27.82-4.81 1.51-2 .89-3.77 2.21-5.53 3.52-3.2 2.38-6.4 4.75-9.49 7.27-2.56 2.08-5.03 4.26-7.65 6.26-5.25 4.01-11.04 7.31-17.17 9.78.36-.14 1.57 1.16 1.98 1.26.84.21 1.18-.05 2.01-.44 1.58-.75 3.1-1.67 4.59-2.59 2.96-1.82 5.79-3.86 8.56-5.95 5.48-4.14 10.75-8.58 16.49-12.37 3.26-2.15 6.67-4.09 10.28-5.59zm-21.66-.78c7.49-5.38 14.56-11.46 20.2-18.75 5.64-7.3 9.8-15.89 11.01-25.03-3.36 11.32-9.99 21.54-18.39 29.84s-18.52 14.74-29.27 19.63c-2.51 1.14-5.06 2.21-7.71 2.94-.92.26-1.87.48-2.66 1-.8.52-1.43 1.42-1.33 2.37.33 3.21 4.18.87 6.19.14 2.66-.96 5.25-2.11 7.77-3.38 4.97-2.5 9.67-5.52 14.19-8.76zm45.92-9.15c8.17-1.65 16.41-4.74 22-10.92-5.61 4.18-12.09 7.19-18.91 8.77-6.15 1.43-12.51 1.7-18.74 2.72-6.23 1.01-12.55 2.88-17.36 6.97 2.45-1.26 5.14-3.01 7.83-3.67 2.24-.56 4.72-.73 7.01-1.11 6.04-1 12.16-1.54 18.17-2.76z" />
          </>
        ),
        light: (
          <>
            <path d="M314.89 200.37c5.4-3.57 10.59-7.46 15.55-11.61-9.78 6.71-19.75 13.51-31.05 17.11-.62.2-1.28.41-1.67.93-1.46 1.97 1.69 1.1 2.34.92 1.77-.49 3.5-1.12 5.18-1.87 3.39-1.5 6.56-3.44 9.65-5.48zm39.66-37.95c-1.11-.29-2.24-.58-3.38-.5-2.38.16-4.33 1.86-6.07 3.49 1.93-.95 3.9-1.91 6.03-2.22 2.78-.4 5.53.36 8.18 1.11 2.57.73 5.62 1.83 8.32 1.32-.65-.7-1.28-.51-2.1-.51-1.17.01-2.35-.45-3.47-.74-2.5-.64-5-1.3-7.51-1.95z" />
            <path d="M341.93 176.14c2.83-1.67 5.76-3.25 8.94-4.11 3.22-.87 6.59-.97 9.93-1.12 6.19-.3 12.42-.83 18.4-2.47 5.98-1.64 11.75-4.43 16.18-8.77-6.08 0-8.26 8.16-34.68 7.84-3.95-.05-7.96.25-11.7 1.52-3.04 1.03-5.77 2.82-8.42 4.65-13.69 9.44-26.85 20.54-42.89 24.93-1.21.33-3.53 1.14-2.74 2.88.69 1.5 2.71.69 3.86.31 3.83-1.28 7.52-3.08 11.06-5 7.11-3.86 13.71-8.55 20.38-13.09 3.82-2.63 7.68-5.21 11.68-7.57zm15.12-26.53c3.43-12.92 6.32-26.51 3.48-39.57 1.76 13.5-1.11 27.11-5.58 39.82-1.28 3.63-3.63 9.12-5.23 12.62 2.94 3.03 6.91-11.3 7.33-12.87z" />
            <path d="M310.72 184.58c14.41-7.7 27.39-18.55 35.92-32.48s12.32-31.08 8.62-46.99c.99 17.65-2.08 36.17-12.45 50.49-2.13 2.94-4.53 5.66-7.08 8.25-5.86 5.96-12.48 11.17-19.63 15.5-3.53 2.14-7.19 4.06-10.96 5.75-1.93.87-3.89 1.67-5.87 2.41-.96.36-4.28 1.2-2.05 2.06 1.71.65 5.17-1.15 6.75-1.78 2.32-.93 4.56-2.04 6.75-3.21z" />
          </>
        ),
      },
    },
  },
};

export const horn_T0Axo = {
  name: "Horn 22",
  description:
    "Long, thick antlers that surve upwards from the side of the head",
  tags: ["long", "thick", "antler", "side", "curve"],
  preview: {
    viewbox: viewboxTallWide,
  },
  shape: {
    left: {
      base: (
        <path d="M199.49 182.57c-3.58-3.4-18.1-7.53-23.25-13.92-.23-2.63-.57-5.24-1-7.82-1-5.97-2.52-11.77-4.56-17.26-3.45-8.54-9.4-4.11-7.85 3.62.63 3.89.98 7.75 1.08 11.51.02.99.03 1.96.01 2.93-9.04-6.16-17.35-14.24-24.62-23.86.19-.24.37-.49.53-.74 10.59-15.65 12.97-37.69 5.42-56.37-4.26-9.02-10.06-9.55-7.72-1.08 2.93 14.08-.36 29.68-8.55 42.24-11.84-21.55-19.1-48.23-19.41-77.46.27-14.43-12.33-18.32-14.09-1.35-.56 7.06-.74 14.13-.55 21.15-5.63-3.27-9.41-8.75-10.3-15.5-.29-5.14-3.79-2.47-4.59 3.74-.85 12.34 5.18 22.2 14.99 25.51.24.08.48.15.74.2 3.03 32.14 13.54 62.77 29.95 87.47-22.01-2.32-43.5-11.43-53.47-26.25-5.01-8.96-8.11-2.19-3.33 8.58 1.91 3.7 4.37 7.12 7.28 10.29-1.5.64-3.43.94-5.74.84-2.11-.07-3.35.29-2.1 1.25l5.55 4.23c1.25.97 4.54 2.06 6.24 1.71 1.33-.25 2.42-.74 3.29-1.4 10.3 8.04 23.88 13.75 38.45 17.04 2.42.73 5 1.26 7.6 1.49 2.63.43 5.27.79 7.92 1.07 1.11.12 2.2.18 3.25.2 12.15 13.12 26.1 24.35 42.39 30.02 6.21 2.17 43.65-6.2 16.44-32.08z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M194.45 210.19c-15.34-6.78-29.52-16.17-41.73-27.67-1.76-1.65-3.51-3.38-5.67-4.44-4.22-2.08-9.2-1.32-13.91-1.27-10.25.11-20.19-3.35-29.58-7.46-15.4-6.74-30.84-16.25-37.96-31.48 2.77 9.99 10.02 18.27 18.49 24.26 8.47 5.99 18.17 9.95 27.77 13.86 3.57 1.45 7.16 2.92 10.93 3.74 5.79 1.26 11.79.97 17.68 1.59 1 .11 2.02.24 2.96.62 1.46.59 2.62 1.72 3.76 2.81 6.86 6.56 14.3 12.51 22.2 17.77 6.94 4.62 14.33 9.05 22.04 12.3 2.85 1.2 5.49 1.2 8.49.48 5.37-1.3-3.8-4.37-5.47-5.11zM81.59 163.35c-5.2.74-10.4-1.68-14.49-4.97 1 3.02 3.54 5.26 6.2 7 1.83 1.2 3.86 2.26 6.05 2.32.96.03 4.44-.26 4.95-1.33.57-1.22-1.59-3.18-2.71-3.02zm4.58-93.9c2.2 2.71 4.91 5 7.93 6.75.76.44 1.59.77 2.35 1.22.23.13.45.35.67.56-.48 6.03.81 12.05 2.25 17.93 1.46 5.94 3.1 11.84 4.96 17.67 3.28 10.25 7.26 20.3 12.24 29.86 2.51 4.81 5.26 9.5 8.29 14 1.27 1.88 2.68 4.79 4.46 6.21.88.7 1.71.84 2.85 1.02.52.08 3.73-.02 3.93.24-12.78-17.21-23.14-36.2-30.69-56.26-1.96-5.19-3.73-10.48-4.87-15.91-1.03-4.93-1.53-9.96-1.5-14.99.08-.74.03-1.74-.18-2.09-.21-.36-.63-.55-1.02-.72-1.44-.6-2.9-1.16-4.29-1.88-5.17-2.66-9.19-7.45-10.92-12.99-1.57-5.04-1.28-10.46-.79-15.71-2.45 6.49-1.93 13.99 1.17 20.2.83 1.74 1.94 3.39 3.16 4.89zm75.62 113.22c-2.44-1.94-4.89-3.91-6.84-6.34-2-2.49-3.44-5.41-5.47-7.88-2.48-3-5.74-5.23-8.65-7.83-4.96-4.44-8.84-9.94-12.66-15.38 2.64 6.37 6.49 12.23 11.27 17.19 2.95 3.05 6.25 5.77 9.01 8.99 1.52 1.78 2.86 3.7 4.3 5.54 3.27 4.18 7.06 7.94 11.26 11.19 2.93 2.27 6.06 4.27 9.18 6.28 5.65 3.62 11.31 7.26 17.25 10.4 3.04 1.61 6.15 3.09 9.35 4.36 2.86 1.13 4.58 2.03 6.91-.31-3.57-1.3-7.2-2.32-10.71-3.86-12.53-5.52-23.55-13.88-34.2-22.35zm-21.04-80.86c1.18-10.65-.12-21.69-4.41-31.42 3.8 9.83 4.52 20.83 2.93 31.31-1.6 10.49-5.44 20.5-10.42 29.73-.48.89-.98 2-.49 2.88 7.8-7.7 11.19-21.6 12.39-32.5z" />
            <path d="M98.85 63.72c.49 1.08 1.32 1.96 1.93 2.98 1.93 3.26 1.25 7.34 1.04 11.12-.43 7.82 1.45 15.57 3.32 23.18.78 3.17 1.62 6.12 3.16 8.98 1.24 2.32 3.01 5.2 3.91 7.64-3.61-9.82-6.34-19.97-8.16-30.28-.33-1.88-.63-3.79-.46-5.68.14-1.6.62-3.15.93-4.73.87-4.5.17-9.58-3.03-12.86-.54-.56-1.16-1.07-1.58-1.72-.7-1.09-.79-2.46-.84-3.76-.23-6.49-.02-12.99.64-19.44-1.21 6.84-1.72 13.81-1.51 20.75.04 1.29.11 2.63.65 3.82zm6.85 1.04c1.35 12.68 6.4 24.65 11.9 36.16-2.57-8.01-5.14-16.04-6.97-24.25-2.09-9.36-3.21-18.94-3.35-28.54-.07-4.82.4-9.57.54-14.38.04-1.53-.53-3.73-1.78-1.61-.93 1.58-.71 4.68-.83 6.45-.37 5.37-.21 10.75-.06 16.13.09 3.35.19 6.7.55 10.04zm33.31 52.74c-1.2 3.41-2.59 6.75-4.18 9.98-.82 1.67-5.26 7.22-1.69 8 2.23-2.92 3.63-6.43 4.99-9.88 3.65-9.22 7.37-19.12 5.54-28.92.54 2.88-1.06 7.55-1.69 10.37-.78 3.55-1.77 7.04-2.97 10.45zm25.85 51.62c4.34-.71 1.96-10.45 1.63-13.23-.66-5.58-1.91-11.13-3.73-16.55 3.28 9.76 1.89 19.92 2.1 29.78zm34.19 29.52c-15.05-6.97-29.59-15.3-42.31-26.02 6.84 5.77 13.26 11.57 20.85 16.42 6.78 4.33 13.9 8.09 21.23 11.39 1.07.48 9.57 4.87 10.08 3.08.27-.95-8.66-4.32-9.85-4.87zM67.3 138.98c4.23 7.75 10.48 14.36 17.75 19.38 8.1 5.6 17.44 9.29 27.06 11.4-2.32-1.94-5.3-2.86-8.14-3.91-15.46-5.74-28.75-17.14-36.8-31.54-.98.1-1.54 1.28-1.36 2.25.19.94.85 1.71 1.49 2.42z" />
          </>
        ),
        light: (
          <>
            <path d="M102.97 47.5c.19-4.76.77-9.5 1.26-14.24.05-.53.1-1.08-.12-1.56-.98-2.15-1.6.7-1.69 1.43-.22 1.69-.43 3.39-.62 5.08-.36 3.29-.62 6.6-.63 9.92-.02 5.73.72 11.53 2.87 16.84-.94-5.76-1.3-11.63-1.07-17.47zm-7.23 25.18c.84.32 1.94.94 2.59.06.43-.57.2-1.6-.24-2.09-.68-.76-2.32-1.07-3.23-1.56-1.14-.62-2.21-1.35-3.21-2.16-2.18-1.78-4.01-3.99-5.4-6.44-2.44-4.32-3.52-9.34-3.43-14.3-.21-.11-.38.2-.41.43-.74 5.42-.06 11.1 2.41 15.98 2.28 4.51 6.17 8.29 10.92 10.08z" />
            <path d="M87.15 57.72c2.21 3.74 5.8 6.77 10.04 7.74-.33-.69-1.06-1.08-1.72-1.46-3.19-1.86-5.7-4.76-7.5-7.99-1.85-3.31-2.53-6.86-3.85-10.33-.67 1.56.19 4.28.58 5.87.52 2.16 1.31 4.26 2.45 6.17zm53.95 20.16c.31 1 .53 2.03.72 3.06.82 4.34 1.31 8.74 1.45 13.15.58-1.35.71-2.84.84-4.3.15-1.82.28-3.66.19-5.48.96 2.34 1.71 4.77 2.12 7.27.4 2.44.48 4.93.45 7.4-.11 8.37-1.45 16.73-3.97 24.71 2.54-2.71 3.52-6.48 4.41-10.09.63-2.54 1.26-5.08 1.66-7.67.63-4.07.68-8.2.6-12.31-.06-3.1-.21-6.22-.88-9.25-.68-3.03-1.91-5.98-3.95-8.33-1.15-1.33-2.55-2.44-4.1-3.27-.59-.31-1.75-.73-2.11.14-.27.65.62 1.63.92 2.13.55.95 1.11 1.89 1.65 2.84zm-16.27 48.98c.16 0 .29-.13.4-.25.82-.91 2.69-2.09 1.69-3.37-8.78-11.11-13.61-24.74-18.3-38.1 2.22 14.67 8.25 28.55 15.76 41.35.1.18.24.37.45.37zm45.42 42.22c-.14 1.69-.3 3.52.62 4.95.6.94 1.59 1.56 2.55 2.14 2.71 1.64 5.45 3.23 8.22 4.77 5 2.79 10.09 5.42 15.26 7.89 2.57 1.23 5.15 2.41 7.75 3.56 1.25.55 2.6 1.35 3.92 1.69 1.69.44.91-.62.39-1.71-.6-1.24-1.21-2.51-2.2-3.47-1.11-1.07-2.6-1.66-4.03-2.25-4.94-2.06-9.68-4.56-14.41-7.06-2.9-1.53-5.8-3.07-8.46-4.99-1.48-1.07-2.92-2.29-3.82-3.88-.69-1.2-1.04-2.56-1.36-3.91-1.79-7.58-2.67-15.48-6-22.52 1.73 8.12 2.26 16.51 1.57 24.79z" />
            <path d="M209.42 197.13c-2.49-2.57-6.91-3.83-10.08-5.4-8.79-4.35-17.51-8.87-25.91-13.95-12.27-7.42-23.9-16.05-33.61-26.6 2.02 3.68 5.12 6.63 8.24 9.45 11.64 10.52 24.22 20.07 37.86 27.85 6.75 3.85 13.76 7.26 20.99 10.12.59.23 3.41 1.79 3.92 1.13.49-.63-1.05-2.23-1.41-2.6z" />
            <path d="M141.91 149.1c2.9 3.29 6.06 6.35 9.43 9.16 1.9 1.58 3.86 3.07 5.89 4.48 1.9 1.32 3.88 2.57 6.11 3.18.1-.75.2-1.51.31-2.26.03-.23-5.35-3.12-5.9-3.49-1.95-1.31-3.82-2.72-5.62-4.22-3.6-3-6.9-6.34-9.94-9.89-1.72-2-3.18-4.31-4.96-6.23-2.09 1.13-.4 2.97.56 4.21 1.32 1.73 2.68 3.42 4.12 5.06zm-20.02-17.99c-.02-.64.02-1.29-.09-1.93-.17-.98-.67-1.86-1.17-2.72-1.7-2.92-3.47-5.79-5.3-8.62 1.24 2.63 2.48 5.25 3.71 7.88.41.87.82 1.75 1.05 2.69.33 1.37.24 2.8.32 4.21.23 3.71 1.9 7.52 5.08 9.45 1.73 1.05 3.76 1.47 5.68 2.09 1.19.38 2.23.96 3.33 1.49.12-1.33-2.56-1.89-3.48-2.26-5-2.01-8.99-6.88-9.13-12.28zm-45.25 29.2c-2.64-.45-5.29-.89-7.93-1.34-.76-.13-1.84-.14-.97.71.66.64 2.39.96 3.32 1.16 1.82.39 3.69.49 5.56.6 1.27.07 2.55.14 3.82.21-.63-.44-1.35-.77-2.11-.99-.55-.15-1.12-.25-1.69-.35zm4.96-9.87c11.86 13.4 31.24 19.12 48.53 21.15 6.43.46 13.69.24 18.87 4.66l-1.07-1.19c1.22 1.25 2.43 2.51 3.65 3.76-13.21-18.16-47.71-5.56-69.98-28.38zm29.04-45.65c-1.11-4.72-2.04-9.49-2.78-14.28-.37-2.42-.7-4.84-.98-7.27-.13-1.15-.25-2.31-.36-3.46-.08-.83.07-2.44-.46-3.06-.47 4.6.38 9.72 1.13 14.26.78 4.73 2.36 9.17 3.45 13.81z" />
          </>
        ),
      },
    },
    right: {
      base: (
        <path d="M300.51 182.57c3.58-3.4 18.1-7.53 23.25-13.92.23-2.63.57-5.24 1-7.82 1-5.97 2.52-11.77 4.56-17.26 3.45-8.54 9.4-4.11 7.85 3.62-.63 3.89-.98 7.75-1.08 11.51-.02.99-.03 1.96-.01 2.93 9.04-6.16 17.35-14.24 24.62-23.86-.19-.24-.37-.49-.53-.74-10.59-15.65-12.97-37.69-5.42-56.37 4.26-9.02 10.06-9.55 7.72-1.08-2.93 14.08.36 29.68 8.55 42.24 11.84-21.55 19.1-48.23 19.41-77.46-.27-14.43 12.33-18.32 14.09-1.35.56 7.06.74 14.13.55 21.15 5.63-3.27 9.41-8.75 10.3-15.5.29-5.14 3.79-2.47 4.59 3.74.85 12.34-5.18 22.2-14.99 25.51-.24.08-.48.15-.74.2-3.03 32.14-13.54 62.77-29.95 87.47 22.01-2.32 43.5-11.43 53.47-26.25 5.01-8.96 8.11-2.19 3.33 8.58-1.91 3.7-4.37 7.12-7.28 10.29 1.5.64 3.43.94 5.74.84 2.11-.07 3.35.29 2.1 1.25l-5.55 4.23c-1.25.97-4.54 2.06-6.24 1.71-1.33-.25-2.42-.74-3.29-1.4-10.3 8.04-23.88 13.75-38.45 17.04-2.42.73-5 1.26-7.6 1.49-2.63.43-5.27.79-7.92 1.07-1.11.12-2.2.18-3.25.2-12.15 13.12-26.1 24.35-42.39 30.02-6.21 2.17-43.65-6.2-16.44-32.08z" />
      ),
      basic: {
        dark: (
          <>
            <path d="M434.38 137.79c-7.13 15.22-22.57 24.74-37.96 31.48-9.39 4.11-19.33 7.57-29.58 7.46-4.71-.05-9.69-.8-13.91 1.27-2.16 1.07-3.92 2.79-5.67 4.44-12.21 11.5-26.39 20.89-41.73 27.67-1.68.74-10.84 3.81-5.46 5.1 3.01.72 5.64.72 8.49-.48 7.7-3.24 15.09-7.68 22.04-12.3 7.9-5.26 15.34-11.21 22.2-17.77 1.14-1.09 2.3-2.22 3.76-2.81.94-.38 1.95-.52 2.96-.62 5.89-.62 11.89-.33 17.68-1.59 3.77-.82 7.36-2.28 10.93-3.74 9.6-3.91 19.3-7.88 27.77-13.86 8.45-5.97 15.71-14.25 18.48-24.25zm-16.01 25.49c-1.12-.16-3.28 1.81-2.7 3.02.51 1.07 3.99 1.36 4.95 1.33 2.19-.06 4.22-1.12 6.05-2.32 2.66-1.74 5.2-3.98 6.2-7-4.1 3.29-9.3 5.71-14.5 4.97zm-17.26-87.69c-.21.36-.26 1.35-.18 2.09.03 5.03-.47 10.07-1.5 14.99-1.14 5.43-2.91 10.71-4.87 15.91-7.55 20.06-17.91 39.06-30.69 56.26.2-.27 3.41-.16 3.93-.24 1.14-.18 1.98-.32 2.85-1.02 1.78-1.42 3.19-4.33 4.46-6.21 3.03-4.5 5.79-9.19 8.29-14 4.97-9.56 8.96-19.61 12.24-29.86 1.86-5.83 3.5-11.73 4.96-17.67 1.45-5.87 2.73-11.9 2.25-17.93.22-.21.45-.43.67-.56.75-.45 1.58-.78 2.35-1.22 3.02-1.75 5.73-4.04 7.93-6.75 1.22-1.5 2.32-3.15 3.16-4.9 3.09-6.21 3.62-13.71 1.17-20.2.48 5.26.78 10.67-.79 15.71-1.73 5.55-5.76 10.34-10.92 12.99-1.39.71-2.85 1.28-4.29 1.88-.4.17-.81.36-1.02.73zm-91.58 129.17c5.94-3.14 11.6-6.78 17.25-10.4 3.12-2 6.24-4.01 9.18-6.28 4.2-3.24 7.99-7.01 11.26-11.19 1.44-1.84 2.78-3.76 4.3-5.54 2.76-3.23 6.06-5.94 9.01-8.99 4.79-4.96 8.64-10.82 11.27-17.19-3.83 5.45-7.7 10.95-12.66 15.38-2.9 2.59-6.17 4.82-8.65 7.83-2.03 2.47-3.47 5.39-5.47 7.88-1.95 2.43-4.4 4.4-6.84 6.34-10.66 8.47-21.67 16.83-34.2 22.35-3.5 1.54-7.13 2.56-10.71 3.86 2.34 2.34 4.06 1.44 6.91.31 3.2-1.27 6.31-2.75 9.35-4.36zm62.07-70.51c.49-.89-.01-1.99-.49-2.88-4.98-9.23-8.82-19.24-10.42-29.73-1.6-10.49-.88-21.48 2.93-31.31-4.29 9.72-5.59 20.77-4.41 31.42 1.21 10.9 4.6 24.8 12.39 32.5z" />
            <path d="M396.39 81.58c.17 1.9-.13 3.81-.46 5.68-1.82 10.31-4.55 20.45-8.16 30.28.9-2.44 2.67-5.32 3.91-7.64 1.53-2.86 2.38-5.81 3.16-8.98 1.87-7.61 3.75-15.36 3.32-23.18-.21-3.78-.89-7.86 1.04-11.12.6-1.02 1.44-1.9 1.93-2.98.54-1.19.61-2.53.65-3.84.21-6.94-.3-13.91-1.51-20.75.66 6.46.88 12.96.64 19.44-.05 1.3-.13 2.67-.84 3.76-.42.66-1.04 1.16-1.58 1.72-3.2 3.28-3.9 8.36-3.03 12.86.3 1.6.78 3.15.93 4.75zm-1.57-26.94c.15-5.37.31-10.76-.06-16.13-.12-1.78.1-4.87-.83-6.45-1.25-2.12-1.82.08-1.78 1.61.14 4.8.61 9.56.54 14.38-.13 9.59-1.25 19.17-3.35 28.54-1.83 8.21-4.41 16.24-6.97 24.25 5.5-11.5 10.55-23.48 11.9-36.16.36-3.33.45-6.68.55-10.04zm-28 80.78c3.57-.77-.87-6.32-1.69-8-1.59-3.23-2.98-6.57-4.18-9.98-1.2-3.41-2.19-6.9-2.98-10.44-.63-2.82-2.22-7.49-1.69-10.37-1.83 9.8 1.89 19.69 5.54 28.92 1.38 3.44 2.77 6.95 5 9.87zm-31.71 33.63c.21-9.86-1.18-20.02 2.1-29.79-1.82 5.42-3.07 10.97-3.73 16.55-.33 2.79-2.71 12.53 1.63 13.24zm-12.73 19.92c7.59-4.85 14.01-10.65 20.85-16.42-12.72 10.72-27.26 19.05-42.31 26.02-1.19.55-10.13 3.92-9.85 4.87.51 1.78 9.01-2.6 10.08-3.08 7.33-3.3 14.45-7.06 21.23-11.39zm65.47-19.27c9.62-2.11 18.96-5.8 27.06-11.4 7.27-5.03 13.52-11.63 17.75-19.38.65-.71 1.31-1.47 1.49-2.42.18-.97-.38-2.14-1.36-2.25-8.04 14.4-21.33 25.79-36.8 31.54-2.83 1.05-5.82 1.96-8.14 3.91z" />
          </>
        ),
        light: (
          <>
            <path d="M397 47.43c.23 5.84-.13 11.71-1.07 17.48 2.14-5.32 2.88-11.11 2.87-16.84-.01-3.32-.27-6.63-.63-9.92-.18-1.7-.4-3.39-.62-5.08-.1-.74-.71-3.59-1.69-1.43-.22.48-.17 1.04-.12 1.56.49 4.74 1.07 9.47 1.26 14.23zm7.22 25.19c4.75-1.79 8.64-5.57 10.92-10.07 2.47-4.88 3.14-10.56 2.41-15.98-.03-.23-.2-.55-.41-.43.09 4.96-.99 9.98-3.43 14.3-1.39 2.45-3.22 4.65-5.4 6.44-1 .82-2.08 1.55-3.21 2.16-.91.5-2.55.8-3.23 1.56-.44.49-.66 1.52-.24 2.09.65.87 1.75.24 2.59-.07z" />
            <path d="M412.82 57.66c1.13-1.92 1.93-4.02 2.46-6.18.38-1.59 1.24-4.3.58-5.87-1.32 3.47-2 7.03-3.85 10.33-1.8 3.23-4.3 6.13-7.5 7.99-.66.39-1.4.77-1.72 1.46 4.22-.97 7.82-4 10.03-7.73zm-60.15 55.89c.89 3.6 1.87 7.38 4.41 10.09-2.52-7.98-3.86-16.34-3.97-24.71-.03-2.48.05-4.96.45-7.4.41-2.5 1.16-4.93 2.12-7.27-.09 1.82.04 3.66.19 5.48.12 1.46.26 2.95.84 4.3.14-4.41.63-8.82 1.45-13.15.19-1.03.41-2.06.72-3.06.54-.95 1.1-1.88 1.67-2.81.31-.5 1.19-1.48.92-2.13-.36-.87-1.52-.45-2.11-.14-1.55.83-2.95 1.94-4.1 3.27-2.04 2.34-3.27 5.3-3.95 8.33-.68 3.03-.82 6.15-.88 9.25-.08 4.11-.03 8.24.6 12.31.38 2.56 1.01 5.1 1.64 7.64zm22.07 12.99c.11.12.24.25.4.25.2 0 .34-.2.45-.37 7.51-12.8 13.54-26.68 15.76-41.35-4.69 13.36-9.52 26.99-18.3 38.1-1.01 1.28.87 2.47 1.69 3.37zM291.39 194c1.32-.34 2.67-1.14 3.92-1.69 2.6-1.15 5.19-2.33 7.75-3.56 5.17-2.47 10.26-5.1 15.26-7.89 2.77-1.54 5.51-3.13 8.22-4.77.96-.58 1.95-1.19 2.55-2.14.91-1.43.76-3.26.62-4.95-.69-8.28-.16-16.67 1.57-24.8-3.33 7.04-4.21 14.94-6 22.52-.32 1.35-.68 2.71-1.36 3.91-.91 1.59-2.34 2.81-3.82 3.88-2.66 1.92-5.56 3.45-8.46 4.99-4.73 2.5-9.47 5-14.41 7.06-1.43.6-2.91 1.18-4.03 2.25-.99.96-1.61 2.23-2.2 3.47-.53 1.11-1.3 2.16.39 1.72z" />
            <path d="M360.14 151.12c-9.71 10.54-21.34 19.18-33.61 26.6-8.4 5.08-17.11 9.6-25.91 13.95-3.17 1.57-7.59 2.83-10.08 5.4-.36.37-1.89 1.97-1.4 2.6.51.66 3.33-.9 3.92-1.13 7.23-2.85 14.24-6.26 20.99-10.12 13.64-7.78 26.22-17.33 37.86-27.85 3.11-2.82 6.21-5.77 8.23-9.45z" />
            <path d="M342.73 162.67c2.03-1.41 3.99-2.9 5.89-4.48 3.37-2.81 6.53-5.87 9.43-9.16 1.44-1.63 2.8-3.33 4.13-5.06.95-1.24 2.65-3.08.56-4.21-1.78 1.92-3.25 4.22-4.96 6.23-3.04 3.55-6.34 6.9-9.94 9.89-1.8 1.5-3.68 2.91-5.62 4.22-.55.37-5.93 3.26-5.9 3.49.1.75.2 1.51.31 2.26 2.22-.61 4.21-1.87 6.1-3.18zM374.47 142c3.18-1.93 4.85-5.74 5.08-9.45.09-1.41 0-2.84.32-4.21.22-.94.64-1.82 1.05-2.69 1.24-2.63 2.48-5.25 3.71-7.88-1.84 2.83-3.61 5.7-5.3 8.62-.5.86-1 1.74-1.17 2.72-.11.64-.07 1.28-.09 1.93-.14 5.4-4.13 10.27-9.14 12.28-.92.37-3.6.93-3.48 2.26 1.1-.53 2.15-1.11 3.33-1.49 1.93-.62 3.96-1.04 5.69-2.09zm56.79 16.91c-2.64.45-5.29.89-7.93 1.34-.57.1-1.14.19-1.69.35-.77.22-1.49.56-2.11.99 1.27-.07 2.55-.14 3.82-.22 1.87-.11 3.75-.21 5.56-.6.93-.2 2.66-.51 3.32-1.16.87-.84-.22-.83-.97-.7zm-82.86 19.85c1.22-1.25 2.43-2.51 3.65-3.76l-1.07 1.19c5.18-4.42 12.44-4.2 18.87-4.66 17.3-2.03 36.67-7.75 48.53-21.15-22.29 22.81-56.78 10.21-69.98 28.38zm44.38-87.86c.75-4.54 1.59-9.66 1.13-14.26-.53.62-.38 2.23-.46 3.06-.11 1.16-.23 2.31-.36 3.46-.28 2.43-.61 4.86-.98 7.27-.74 4.79-1.67 9.56-2.78 14.28 1.08-4.63 2.67-9.06 3.45-13.81z" />
          </>
        ),
      },
    },
  },
};
