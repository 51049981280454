import React from "react";
import { connect } from "react-redux";
import noseShapeList from "character-creator/components/head/face/nose/NoseShapeList";
import Component from "character-creator/components/Component";
import {
  buildTransform,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import ClippedMarkings from "../../markings/ClippedMarkings";
import NoseAccessories from "character-creator/components/clothing/Accessories/Face/Nose/NoseAccessories";

function mapStateToProps(state, ownProps) {
  const noseConfig = state.config.base.head.face.nose;
  let noseObject = null;
  if (ownProps.preview && ownProps.nose) {
    noseObject = ownProps.nose;
  } else if (noseConfig.shape !== "0") {
    noseObject = noseShapeList[noseConfig.shape];
  }
  const colour = noseConfig.colour
    ? {
        darker: noseConfig.colour.darker,
        dark: noseConfig.colour.dark,
        base: noseConfig.colour.light,
        light: noseConfig.colour.lighter,
      }
    : {
        darker: state.config.base.skintone.darker,
        dark: state.config.base.skintone.dark,
        base: state.config.base.skintone.light,
        light: state.config.base.skintone.lighter,
      };

  const transform = !ownProps.preview
    ? translateTransformObject(buildTransform(noseConfig.transform))
    : "";

  return {
    colour: colour,
    Nose: noseObject,
    transform: transform,
  };
}

const Nose = (props) => {
  const { colour, Nose, previewId, transform } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (Nose) {
    return (
      <>
        <g
          id={previewId ? `g-nose~${previewId}` : "g-nose"}
          transform-origin="50% 50%"
          {...transform}
        >
          <Component
            id={previewId ? `nose~${previewId}` : "nose"}
            component={Nose}
            colour={colour}
            preview={previewId ? true : false}
          />

          {!previewId && <NoseAccessories />}
        </g>
        {!previewId && (
          <ClippedMarkings
            componentName="nose"
            maskUrl="nose_base"
            maskTransform={transform}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Nose);
