import { nanoid } from "@reduxjs/toolkit";
import React from "react";
import { SvgButton } from "./SvgConfig";

/*
Common configs:
1 colour
3 colours: dark, base, light
4 colours (dark): darker, dark, base, light
4 colours (light): dark, base, light, lighter
5 colours/full: darker, dark, base, light, lighter

and then you also have outline colour - hence why a redesigned palette preview might be better
*/

// TODO: Remake swatches and optimise
// TODO: Not really for this file ? but need to have dynamic swatches based on what's used for each component
const Swatch = (props) => {
  const { component } = props;
  // const svgSize = svgDimensions["small"];
  // const svgSizeHalf = toNumber(svgSize) / 2;
  const swatchId = nanoid(5);

  return (
    <SvgButton size="small" viewbox={`0 0 1000 1000`} title={component.name}>
      <>
        <circle
          cx="500"
          cy="500"
          r="389.487"
          fill={component.base}
          stroke={component.outline ? component.outline : "none"}
          strokeWidth="30"
        />
        <clipPath id={`_clip${swatchId}`}>
          <circle cx="500" cy="500" r="389.487" fill={component.base} />
        </clipPath>
        <g clipPath={`url(#_clip${swatchId})`}>
          <circle cx="500" cy="500" r="389.487" fill={component.base} />
          {(component.darker || component.dark) && (
            <path
              d="M195.188,257.628C163.305,312.501 145.043,376.248 145.043,444.226C145.043,649.661 311.83,816.448 517.265,816.448C713.434,816.448 874.364,664.366 888.479,471.798C889.147,481.119 889.487,490.52 889.487,500C889.487,714.963 714.963,889.487 500,889.487C285.037,889.487 110.513,714.963 110.513,500C110.513,408.404 142.2,324.151 195.188,257.628Z"
              fill={component.darker ? component.darker : component.dark}
            />
          )}
          {(component.dark || component.darker) && (
            <path
              d="M888.751,420.667C889.239,428.465 889.487,436.317 889.487,444.226C889.487,649.661 722.7,816.448 517.265,816.448C311.83,816.448 145.043,649.661 145.043,444.226C145.043,238.791 311.83,72.004 517.265,72.004C525.174,72.004 533.026,72.251 540.824,72.74C370.856,84.091 236.296,225.755 236.296,398.6C236.296,578.853 382.638,725.195 562.891,725.195C735.736,725.195 877.4,590.635 888.751,420.667Z"
              fill={component.dark ? component.dark : component.darker}
            />
          )}
          {component.light && (
            <circle
              cx="638.623"
              cy="287.645"
              r="250.864"
              fill={component.light}
            />
          )}
          {component.lighter && (
            <circle
              cx="667.191"
              cy="248.674"
              r="149.926"
              fill={component.lighter}
            />
          )}
        </g>
      </>
    </SvgButton>
  );
};

export default Swatch;
