import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { teethUpperPack1, teethLowerPack1 } from "./packs/TeethPack1";
import { teethUpperPack2, teethLowerPack2 } from "./packs/TeethPack2";

export const teethUpperShapeList = {
  "0": BlankListOption,
  ...teethUpperPack1,
  ...teethUpperPack2,
};

export const teethLowerShapeList = {
  "0": BlankListOption,
  ...teethLowerPack1,
  ...teethLowerPack2,
};
