import { allColours } from "character-creator/colours/colourSchemes";
import {
  EyeAccessoriesList,
  GlassesShineList,
} from "character-creator/components/clothing/Accessories/Face/Eyes/EyeAccessoriesList";

const eyeAccessoriesPath = "clothing.accessories.eyes.";
const eyeAccessoriesFullPath = eyeAccessoriesPath + "full.";

const eyeAccessoriesFullShinePath =
  eyeAccessoriesFullPath + "variations.shine.";

const EyeAccessoriesFullPage = {
  id: "eyeAccessoriesFullPage",
  name: "Glasses",
  description: "",
  controls: [
    {
      id: "accessoriesEyeFullStyleGroup",
      name: "Style",
      description: "",
      dataAttribute: [eyeAccessoriesFullPath + "shape"],
      previewType: "preview",
      options: EyeAccessoriesList,
      variations: [
        { name: "Bridge", value: "bridge", description: "Bridge that gap" },
      ],
      variationsAttribute: [eyeAccessoriesFullPath + "variations"],
      colourControls: [
        {
          id: "accessoriesEyeFullFrameColour",
          name: "Frame Colour",
          description: "",
          dataAttribute: [eyeAccessoriesFullPath + "colour.frame"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
        {
          id: "accessoriesEyeFullLensColour",
          name: "Lens Colour",
          description: "",
          dataAttribute: [eyeAccessoriesFullPath + "colour.lens"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "accessoriesEyeFullTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [eyeAccessoriesFullPath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "accessoriesEyeFullTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [eyeAccessoriesFullPath + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
        {
          id: "accessoriesEyeFullLensTransformO",
          name: "Lens Opacity",
          description: "",
          dataAttribute: [eyeAccessoriesFullPath + "lensTransform.opacity"],
          range: {
            min: 0,
            max: 1,
            step: 0.25,
          },
        },
        // {
        //   id: "accessoriesEyeFullBridgeTransformY",
        //   name: "Bridge Height",
        //   description: "",
        //   dataAttribute: [eyeAccessoriesFullPath + "bridgeTransform.y"],
        //   range: {
        //     min: -30,
        //     max: 30,
        //     step: 10,
        //   },
        // },
      ],
    },
    {
      id: "accessoriesEyeFullShineShape",
      name: "Shine",
      description: "",
      dataAttribute: [eyeAccessoriesFullShinePath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      options: GlassesShineList,
      dependencies: [
        {
          description: "Full shape",
          attribute: [eyeAccessoriesFullPath + "shape"],
        },
      ],
      colourControls: [
        {
          id: "accessoriesEyeFullShineColour",
          name: "Colour",
          description: "",
          dataAttribute: [eyeAccessoriesFullShinePath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "accessoriesEyeFullLensShineTransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [
            eyeAccessoriesFullPath + "variations.shine.transform.opacity",
          ],
          range: {
            min: 0,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default EyeAccessoriesFullPage;
