import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { beardPack1 } from "./packs/BeardPack1";
import { beardPack2 } from "./packs/BeardPack2";
import { BeardPack3 } from "./packs/BeardPack3";

const BeardList = {
  "0": BlankListOption,
  ...beardPack1,
  ...beardPack2,
  ...BeardPack3,
};

export default BeardList;
