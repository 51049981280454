import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { NeckAccessoriesList } from "./NeckAccessoriesList";
import AllMetals from "character-creator/colours/collections/Metals";

function mapStateToProps(state, ownProps) {
  const layerConfig = ownProps.layerConfig;
  let layerObject = null;

  if (ownProps.preview && ownProps.clothing) {
    layerObject = ownProps.accessory.shape.back
      ? ownProps.accessory.shape.back
      : ownProps.accessory.shape;
  } else if (layerConfig && layerConfig.shape !== "0") {
    layerObject = NeckAccessoriesList[layerConfig.shape].shape.back
      ? NeckAccessoriesList[layerConfig.shape].shape.back
      : NeckAccessoriesList[layerConfig.shape].shape;
  }

  const colour =
    layerConfig && layerConfig.colour !== ""
      ? layerConfig.colour
      : AllMetals[Object.keys(AllMetals)[3]];

  const AccessoryLayer = {
    shape: layerObject,
    colour: colour,
  };

  return {
    AccessoryLayer,
  };
}

const NeckAccessoryBack = (props) => {
  const { AccessoryLayer, previewId, layer } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (AccessoryLayer.shape) {
    return (
      <Component
        id={
          previewId
            ? `neckAccessory-layer-back-${layer}~${previewId}`
            : `neckAccessory-layer-back-${layer}`
        }
        component={AccessoryLayer}
        colour={AccessoryLayer.colour}
      />
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NeckAccessoryBack);
