import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { headFrecklesPack1 } from "./freckles/HeadFrecklesPack1";
import { molesPack1 } from "./moles/HeadMolesPack1";
import { patchPack1 } from "./patches/HeadPatchesPack1";
import { scalesPack1 } from "./scales/HeadScalesPack1";
import { headSpotsPack1 } from "./spots/HeadSpotsPack1";
import { headStripesPack1 } from "./stripes/HeadStripesPack1";
import { wrinklePack1 } from "./wrinkles/HeadWrinklesPack1";

export const HeadMarkingsList = {
  "0": BlankListOption,
  ...headFrecklesPack1,
  ...headSpotsPack1,
  ...headStripesPack1,
  ...patchPack1,
  ...scalesPack1,
  ...molesPack1,
  ...wrinklePack1,
};
