import skintonesList from "character-creator/colours/collections/Browns";
import HairColours from "character-creator/colours/hair";
import LipColours from "character-creator/colours/lips";
import teethColours from "character-creator/colours/teeth";
import {
  EyeballColours,
  IrisColours,
  pupilColours,
} from "character-creator/colours/eye";

const symmetryAttribute = {
  symmetry: true,
};

const standardAttributes = {
  shape: "0",
  colour: "",
};

const transformAttributes = {
  x: 0,
  y: 0,
  scale: 1,
  opacity: 1,
};

const patternAttribute = {
  shape: "",
  colour: "",
  transform: { ...transformAttributes },
};

const standardAttributesWithTransform = {
  ...standardAttributes,
  transform: { ...transformAttributes },
};

/**
Character components - here are all of the character components!
**/
export const characterConfig = {
  recentColours: {},
  customPalettes: {},
  style: {
    shading: "shadingBasic",
    outline: "outlinesNone",
    colour: "coloursNormal",
  },
  background: {
    colour: "",
    shapes: {
      shape1: { ...standardAttributesWithTransform },
      shape2: { ...standardAttributesWithTransform },
      shape3: { ...standardAttributesWithTransform },
    },
  },
  foreground: {
    labels: {
      label1: {
        ...standardAttributesWithTransform,
        text: {
          ...standardAttributesWithTransform,
          content: "",
        },
      },
      label2: {
        ...standardAttributesWithTransform,
        text: {
          ...standardAttributesWithTransform,
          content: "",
        },
      },
    },
  },
  base: {
    skintone: skintonesList[Object.keys(skintonesList)[0]],
    body: {
      ...standardAttributes,
      shape: "base_body_shape_lcUGV",
      markings: {
        marking1: { ...standardAttributesWithTransform },
        marking2: { ...standardAttributesWithTransform },
        marking3: { ...standardAttributesWithTransform },
      },
      scars: {
        scar1: { ...standardAttributesWithTransform },
        scar2: { ...standardAttributesWithTransform },
        scar3: { ...standardAttributesWithTransform },
      },
      extras: {
        extras1: { ...standardAttributesWithTransform },
        extras2: { ...standardAttributesWithTransform },
        extras3: { ...standardAttributesWithTransform },
        tail: {
          ...standardAttributes,
          variations: {
            tip: { ...standardAttributes },
          },
        },
      },
    },
    head: {
      ...standardAttributes,
      shape: "head_shape_ZH6yu",
      ears: {
        ...symmetryAttribute,
        left: { ...standardAttributes },
        right: { ...standardAttributes },
      },
      face: {
        nose: { ...standardAttributesWithTransform },
        eyes: {
          base: {
            ...symmetryAttribute,
            left: {
              ...standardAttributesWithTransform,
              colour: {
                eyeball: EyeballColours[Object.keys(EyeballColours)[0]],
                eyelid: skintonesList[Object.keys(skintonesList)[0]],
              },
            },
            right: {
              ...standardAttributesWithTransform,
              colour: {
                eyeball: EyeballColours[Object.keys(EyeballColours)[0]],
                eyelid: skintonesList[Object.keys(skintonesList)[0]],
              },
            },
          },
          irises: {
            ...symmetryAttribute,
            left: {
              outer: {
                ...standardAttributesWithTransform,
                colour: IrisColours[Object.keys(IrisColours)[0]],
              },
              inner: {
                ...standardAttributesWithTransform,
                colour: IrisColours[Object.keys(IrisColours)[1]],
              },
            },
            right: {
              outer: {
                ...standardAttributesWithTransform,
                colour: IrisColours[Object.keys(IrisColours)[0]],
              },
              inner: {
                ...standardAttributesWithTransform,
                colour: IrisColours[Object.keys(IrisColours)[1]],
              },
            },
          },
          pupils: {
            ...symmetryAttribute,
            left: {
              ...standardAttributes,
              colour: pupilColours[Object.keys(pupilColours)[0]],
            },
            right: {
              ...standardAttributes,
              colour: pupilColours[Object.keys(pupilColours)[0]],
            },
          },
          shine: {
            ...symmetryAttribute,
            left: {
              ...standardAttributesWithTransform,
              colour: EyeballColours[Object.keys(EyeballColours)[0]],
            },
            right: {
              ...standardAttributesWithTransform,
              colour: EyeballColours[Object.keys(EyeballColours)[0]],
            },
          },
          eyelashes: {
            symmetry: {
              upper: true,
              lower: true,
            },
            left: {
              upper: {
                ...standardAttributes,
                colour: HairColours[Object.keys(HairColours)[0]],
              },
              lower: {
                ...standardAttributes,
                colour: HairColours[Object.keys(HairColours)[0]],
              },
            },
            right: {
              upper: {
                ...standardAttributes,
                colour: HairColours[Object.keys(HairColours)[0]],
              },
              lower: {
                ...standardAttributes,
                colour: HairColours[Object.keys(HairColours)[0]],
              },
            },
          },
          undereye: {
            ...symmetryAttribute,
            left: { ...standardAttributes },
            right: { ...standardAttributes },
          },
        },
        mouth: {
          ...standardAttributesWithTransform,
          colour: {
            lips: LipColours[Object.keys(LipColours)[0]],
            tongue: LipColours[Object.keys(LipColours)[4]],
          },
          teeth: {
            underbite: false,
            upper: {
              ...standardAttributes,
              colour: teethColours[Object.keys(teethColours)[0]],
            },
            lower: {
              ...standardAttributes,
              colour: teethColours[Object.keys(teethColours)[1]],
            },
          },
        },
        eyebrows: {
          ...symmetryAttribute,
          left: { ...standardAttributesWithTransform },
          right: { ...standardAttributesWithTransform },
        },
        moustache: {
          ...standardAttributesWithTransform,
          colour: HairColours[Object.keys(HairColours)[0]],
        },
        beard: {
          ...standardAttributesWithTransform,
          colour: HairColours[Object.keys(HairColours)[0]],
        },
        markings: {
          scars: {
            scar1: { ...standardAttributesWithTransform },
            scar2: { ...standardAttributesWithTransform },
            scar3: { ...standardAttributesWithTransform },
          },
          cheeks: {
            ...symmetryAttribute,
            left: { ...standardAttributesWithTransform },
            right: { ...standardAttributesWithTransform },
          },
          contouring: {
            contouringEyes: {
              left: { ...standardAttributesWithTransform },
              right: { ...standardAttributesWithTransform },
            },
            contouringCheeks: {
              left: { ...standardAttributesWithTransform },
              right: { ...standardAttributesWithTransform },
            },
            contouringLips: { ...standardAttributesWithTransform },
          },
          tattoo: {
            //...symmetryAttribute,
            full: { ...standardAttributesWithTransform },
            left: { ...standardAttributesWithTransform },
            right: { ...standardAttributesWithTransform },
          },
        },
        extras: {
          muzzle: { ...standardAttributesWithTransform },
          whiskers: { ...standardAttributesWithTransform },
          facePaint: { ...standardAttributesWithTransform },
        },
      },
      markings: {
        marking1: { ...standardAttributesWithTransform },
        marking2: { ...standardAttributesWithTransform },
        marking3: { ...standardAttributesWithTransform },
      },
      extras: {
        horns: {
          back: {
            ...symmetryAttribute,
            left: {
              ...standardAttributes,
              texture: "",
            },
            right: {
              ...standardAttributes,
              texture: "",
            },
          },
          front: {
            ...symmetryAttribute,
            left: {
              ...standardAttributes,
              texture: "",
            },
            right: {
              ...standardAttributes,
              texture: "",
            },
            centre: {
              ...standardAttributes,
              texture: "",
            },
          },
        },
      },
    },
    hair: {
      colour: HairColours[Object.keys(HairColours)[0]],
      head: {
        base: { ...standardAttributesWithTransform },
        back: {
          colour: "",
          full: {
            ...standardAttributes,
            variation: "F",
          },
          left: {
            ...standardAttributes,
            variation: "L",
          },
          right: {
            ...standardAttributes,
            variation: "R",
          },
        },
        side: {
          ...symmetryAttribute,
          colour: "",
          left: {
            ...standardAttributes,
            variation: "L",
          },
          right: {
            ...standardAttributes,
            variation: "R",
          },
        },
        mid: {
          ...symmetryAttribute,
          colour: "",
          top: {
            ...standardAttributes,
            variation: "F",
          },
          left: {
            ...standardAttributes,
            variation: "L",
          },
          right: {
            ...standardAttributes,
            variation: "R",
          },
        },
        top: {
          colour: "",
          full: {
            ...standardAttributes,
            variation: "F",
          },
          left: {
            ...standardAttributes,
            variation: "L",
          },
          right: {
            ...standardAttributes,
            variation: "R",
          },
        },
        front: {
          colour: "",
          full: {
            ...standardAttributes,
            variation: "F",
          },
          left: {
            ...standardAttributes,
            variation: "L",
          },
          right: {
            ...standardAttributes,
            variation: "R",
          },
        },
      },
    },
  },
  clothing: {
    layer1: { ...standardAttributes, pattern: { ...patternAttribute } },
    layer2: { ...standardAttributes, pattern: { ...patternAttribute } },
    layer3: { ...standardAttributes, pattern: { ...patternAttribute } },
    accessories: {
      eyes: {
        full: {
          ...standardAttributesWithTransform,
          variations: {
            bridge: "",
            shine: { ...standardAttributesWithTransform },
          },
          colour: {
            frame: "",
            lens: "",
          },
          lensTransform: {
            opacity: 1,
          },
          bridgeTransform: {
            x: 0,
            y: 0,
          },
        },
        left: {
          ...standardAttributesWithTransform,
          variation: "",
          colour: {
            frame: "",
            lens: "",
          },
          lensTransform: {
            opacity: 1,
          },
        },
        right: {
          ...standardAttributesWithTransform,
          variation: "",
          colour: {
            frame: "",
            lens: "",
          },
          lensTransform: {
            opacity: 1,
          },
        },
      },
      nose: {
        ...symmetryAttribute,
        left: { ...standardAttributesWithTransform },
        right: { ...standardAttributesWithTransform },
        septum: { ...standardAttributesWithTransform },
      },
      ears: {
        ...symmetryAttribute,
        left: {
          layer1: { ...standardAttributesWithTransform },
          layer2: { ...standardAttributesWithTransform },
          layer3: { ...standardAttributesWithTransform },
        },
        right: {
          layer1: { ...standardAttributesWithTransform },
          layer2: { ...standardAttributesWithTransform },
          layer3: { ...standardAttributesWithTransform },
        },
      },
      neck: {
        layer1: {
          ...standardAttributesWithTransform,
          colour: {
            main: "",
            extra: "",
          },
        },
        layer2: {
          ...standardAttributesWithTransform,
          colour: {
            main: "",
            extra: "",
          },
        },
        layer3: {
          ...standardAttributesWithTransform,
          colour: {
            main: "",
            extra: "",
          },
        },
      },
    },
  },
};
