import React from "react";
import CharacterHead from "character-creator/components/head/CharacterHead";
import Mouth from "character-creator/components/head/face/mouth/CharacterMouth";
import { connect } from "react-redux";
import { mouthShapeList } from "character-creator/components/head/face/mouth/MouthShapeList";

function mapStateToProps(state, ownProps) {
  const mouthConfigShape = state.config.base.head.face.mouth.shape;
  return {
    defaultMouthShape:
      mouthConfigShape !== "0" ? mouthConfigShape : "mouth_shape_Pf59F",
    ...ownProps,
  };
}

export const PreviewBase = (props) => {
  const { component, defaultMouthShape, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Mouth
        preview={true}
        previewId={previewId}
        mouth={mouthShapeList[defaultMouthShape]}
        teeth={component}
        defaultMouthShape={defaultMouthShape}
      />
    </>
  );
};

export const previewViewbox = "210 270 80 80";

export default connect(mapStateToProps)(PreviewBase);
