// Components
// import skintones from 'character-creator/colours/skin'
import HairColours from "character-creator/colours/hair";
import { hairBaseList } from "character-creator/components/hair/base/HairBaseList";

const hairBasePath = "base.hair.head.base.";

const HairBasePage = {
  id: "hairBasePage",
  name: "Hairline",
  description: "",
  controls: [
    {
      id: "hairBaseShape",
      name: "Style",
      description: "",
      dataAttribute: [hairBasePath + "shape"],
      variationAttribute: "",
      previewType: "preview",
      previewViewbox: "0 0 500 500",
      options: hairBaseList,
      colourControls: [
        {
          id: "hairBaseColour",
          name: "Colour",
          description: "",
          dataAttribute: [hairBasePath + "colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: HairColours,
        },
      ],
      transformControls: [
        {
          id: "hairBaseTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [hairBasePath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
      ],
    },
  ],
};

export default HairBasePage;
