import React from "react";

/**
 * Variations/options
 */
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterHairFront from "./CharacterHairFront";
import CharacterBody from "character-creator/components/body/CharacterBody";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
      <CharacterHairFront
        preview={true}
        previewId={previewId}
        hair={component}
      />
    </>
  );
};

export const viewbox = "130 110 240 240";
export const viewboxTall = "70 100 360 400";

export const viewboxLeft = "100 110 240 300";
export const viewboxRight = "150 110 240 300";
