import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const head_scar_lKVfp = {
  name: "Scar 1 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: <ellipse cx="212.25" cy="246.78" rx="4.03" ry="43.56" />,
  },
};

const head_scar_bnNhR = {
  name: "Scar 1 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: <ellipse cx="287.75" cy="246.78" rx="4.03" ry="43.56" />,
  },
};

const head_scar_fYtUW = {
  name: "Scar 1 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <ellipse cx="212.25" cy="246.78" rx="4.03" ry="43.56" />
        <ellipse cx="287.75" cy="246.78" rx="4.03" ry="43.56" />
      </>
    ),
  },
};

const head_scar_h2MLT = {
  name: "Scar 2",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M250 257.54c11.89-.11 20.13 7.83 20.01 9.22.12 1.61-8.13-3.9-20.01-3.79-11.89-.11-20.14 5.4-20.01 3.79-.13-1.39 8.12-9.33 20.01-9.22z" />
    ),
  },
};

const head_scar_m5B2J = {
  name: "Scar 3 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M319.37 292.55c-1.07 1.1-3.49-5.68-9.69-12.11-6.1-6.53-12.75-9.29-11.59-10.3.97-1 9.34.12 15.44 6.65 6.2 6.43 6.89 14.84 5.84 15.76zm-138.74 0c1.07 1.1 3.49-5.68 9.69-12.11 6.1-6.53 12.75-9.29 11.59-10.3-.97-1-9.34.12-15.44 6.65-6.2 6.43-6.89 14.84-5.84 15.76z" />
    ),
  },
};

const head_scar_fas41 = {
  name: "Scar 3 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M180.63,292.55c1.07,1.1,3.49-5.68,9.69-12.11c6.1-6.53,12.75-9.29,11.59-10.3c-0.97-1-9.34,0.12-15.44,6.65C180.27,283.22,179.58,291.63,180.63,292.55z" />
    ),
  },
};

const head_scar_gar52 = {
  name: "Scar 3 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M319.37,292.55c-1.07,1.1-3.49-5.68-9.69-12.11c-6.1-6.53-12.75-9.29-11.59-10.3c0.97-1,9.34,0.12,15.44,6.65C319.73,283.22,320.42,291.63,319.37,292.55z" />
    ),
  },
};

const head_scar_F1xti = {
  name: "Scar 4 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M316.48 226.05c-.69.71-2.26-3.67-6.27-7.82-3.95-4.21-8.25-6-7.5-6.65.63-.65 6.04.08 9.99 4.29 4.02 4.16 4.46 9.59 3.78 10.18zm-132.96 0c.69.71 2.25-3.67 6.27-7.82 3.95-4.21 8.25-6 7.5-6.65-.63-.65-6.04.08-9.99 4.29-4.02 4.16-4.46 9.59-3.78 10.18z" />
    ),
  },
};

const head_scar_fafJ9 = {
  name: "Scar 4 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M183.52,226.05c0.69,0.71,2.25-3.67,6.27-7.82c3.95-4.21,8.25-6,7.5-6.65c-0.63-0.65-6.04,0.08-9.99,4.29C183.28,220.03,182.84,225.46,183.52,226.05z" />
    ),
  },
};

const head_scar_dafa4 = {
  name: "Scar 4 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M316.48,226.05c-0.69,0.71-2.26-3.67-6.27-7.82c-3.95-4.21-8.25-6-7.5-6.65c0.63-0.65,6.04,0.08,9.99,4.29C316.72,220.03,317.16,225.46,316.48,226.05z" />
    ),
  },
};

const head_scar_w85R8 = {
  name: "Scar 5 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="261.58"
          cy="315.22"
          rx="1.52"
          ry="5.91"
          transform="matrix(0.866 -0.5 0.5 0.866 -122.566 173.0195)"
        />
        <ellipse
          cx="238.42"
          cy="315.22"
          rx="5.91"
          ry="1.52"
          transform="matrix(0.5 -0.866 0.866 0.5 -153.7774 364.0919)"
        />
      </>
    ),
  },
};

const head_scar_faJwi = {
  name: "Scar 5 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="238.42"
          cy="315.22"
          rx="5.91"
          ry="1.52"
          transform="matrix(0.5 -0.866 0.866 0.5 -153.7774 364.0919)"
        />
      </>
    ),
  },
};

const head_scar_treoq = {
  name: "Scar 5 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <>
        <ellipse
          cx="261.58"
          cy="315.22"
          rx="1.52"
          ry="5.91"
          transform="matrix(0.866 -0.5 0.5 0.866 -122.566 173.0195)"
        />
      </>
    ),
  },
};

const head_scar_y457j = {
  name: "Scar 6 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M232.07 226.96c-1.44-1.44-10.01 4.77-19.82 14.18-9.8-9.41-18.38-15.62-19.82-14.18s4.77 10.01 14.18 19.82c-9.41 9.8-15.62 18.38-14.18 19.82s10.01-4.77 19.82-14.18c9.8 9.41 18.38 15.62 19.82 14.18s-4.77-10.01-14.18-19.82c9.41-9.81 15.62-18.38 14.18-19.82zm75.49 0c-1.44-1.44-10.01 4.77-19.82 14.18-9.8-9.41-18.38-15.62-19.82-14.18s4.77 10.01 14.18 19.82c-9.41 9.8-15.62 18.38-14.18 19.82s10.01-4.77 19.82-14.18c9.8 9.41 18.38 15.62 19.82 14.18s-4.77-10.01-14.18-19.82c9.41-9.81 15.62-18.38 14.18-19.82z" />
    ),
  },
};

const head_scar_75683 = {
  name: "Scar 6 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M232.07,226.96c-1.44-1.44-10.01,4.77-19.82,14.18c-9.8-9.41-18.38-15.62-19.82-14.18s4.77,10.01,14.18,19.82c-9.41,9.8-15.62,18.38-14.18,19.82s10.01-4.77,19.82-14.18c9.8,9.41,18.38,15.62,19.82,14.18s-4.77-10.01-14.18-19.82C227.3,236.97,233.51,228.4,232.07,226.96z" />
    ),
  },
};

const head_scar_57474 = {
  name: "Scar 6 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M307.56,226.96c-1.44-1.44-10.01,4.77-19.82,14.18c-9.8-9.41-18.38-15.62-19.82-14.18s4.77,10.01,14.18,19.82c-9.41,9.8-15.62,18.38-14.18,19.82s10.01-4.77,19.82-14.18c9.8,9.41,18.38,15.62,19.82,14.18s-4.77-10.01-14.18-19.82C302.79,236.97,309,228.4,307.56,226.96z" />
    ),
  },
};

const head_scar_45643 = {
  name: "Scar 7 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M172.95 309.86c5.8-18.65 13.32-36.82 23.49-53.42s23.04-31.61 38.59-43.09c-.06 5.88-25.26 34.85-30.76 43.89-17.41 28.59-16.59 25.85-26.72 57.88-.49 1.56-1.24 3.39-2.82 3.7-1.79.35-3.31-1.71-3.19-3.56s.83-3.52 1.41-5.4zm0 42.18c5.8-18.65 13.32-36.82 23.49-53.42s23.04-31.61 38.59-43.09c-.06 5.88-25.26 34.85-30.76 43.89-17.41 28.59-16.59 25.85-26.72 57.88-.49 1.56-1.24 3.39-2.82 3.7-1.79.35-3.31-1.71-3.19-3.56.12-1.85.83-3.51 1.41-5.4zm-17.51-29.53c2.3-19.39 6.39-38.63 13.36-56.8 6.97-18.17 16.89-35.28 30.09-49.4 1.02 5.79-18.49 38.88-22.25 48.76-11.91 31.29-11.6 28.44-15.72 61.78-.2 1.63-.6 3.55-2.1 4.15-1.69.67-3.56-1.08-3.78-2.92-.23-1.85.17-3.61.4-5.57zm171.61-12.65c-5.8-18.65-13.32-36.82-23.49-53.42-10.17-16.6-23.04-31.61-38.59-43.09.06 5.88 25.26 34.85 30.76 43.89 17.41 28.59 16.59 25.85 26.72 57.88.49 1.56 1.24 3.39 2.82 3.7 1.79.35 3.31-1.71 3.19-3.56s-.83-3.52-1.41-5.4zm0 42.18c-5.8-18.65-13.32-36.82-23.49-53.42-10.17-16.6-23.04-31.61-38.59-43.09.06 5.88 25.26 34.85 30.76 43.89 17.41 28.59 16.59 25.85 26.72 57.88.49 1.56 1.24 3.39 2.82 3.7 1.79.35 3.31-1.71 3.19-3.56-.12-1.85-.83-3.51-1.41-5.4zm17.51-29.53c-2.3-19.39-6.39-38.63-13.36-56.8-6.97-18.17-16.89-35.28-30.09-49.4-1.02 5.79 18.49 38.88 22.25 48.76 11.91 31.29 11.6 28.44 15.72 61.78.2 1.63.6 3.55 2.1 4.15 1.69.67 3.56-1.08 3.78-2.92.23-1.85-.17-3.61-.4-5.57z" />
    ),
  },
};

const head_scar_fgh73 = {
  name: "Scar 7 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M172.95 309.86c5.8-18.65 13.32-36.82 23.49-53.42s23.04-31.61 38.59-43.09c-.06 5.88-25.26 34.85-30.76 43.89-17.41 28.59-16.59 25.85-26.72 57.88-.49 1.56-1.24 3.39-2.82 3.7-1.79.35-3.31-1.71-3.19-3.56s.83-3.52 1.41-5.4zm0 42.18c5.8-18.65 13.32-36.82 23.49-53.42s23.04-31.61 38.59-43.09c-.06 5.88-25.26 34.85-30.76 43.89-17.41 28.59-16.59 25.85-26.72 57.88-.49 1.56-1.24 3.39-2.82 3.7-1.79.35-3.31-1.71-3.19-3.56.12-1.85.83-3.51 1.41-5.4zm-17.51-29.53c2.3-19.39 6.39-38.63 13.36-56.8 6.97-18.17 16.89-35.28 30.09-49.4 1.02 5.79-18.49 38.88-22.25 48.76-11.91 31.29-11.6 28.44-15.72 61.78-.2 1.63-.6 3.55-2.1 4.15-1.69.67-3.56-1.08-3.78-2.92-.23-1.85.17-3.61.4-5.57z" />
    ),
  },
};

const head_scar_iytur = {
  name: "Scar 7 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M327.05 309.86c-5.8-18.65-13.32-36.82-23.49-53.42-10.17-16.6-23.04-31.61-38.59-43.09.06 5.88 25.26 34.85 30.76 43.89 17.41 28.59 16.59 25.85 26.72 57.88.49 1.56 1.24 3.39 2.82 3.7 1.79.35 3.31-1.71 3.19-3.56s-.83-3.52-1.41-5.4zm0 42.18c-5.8-18.65-13.32-36.82-23.49-53.42-10.17-16.6-23.04-31.61-38.59-43.09.06 5.88 25.26 34.85 30.76 43.89 17.41 28.59 16.59 25.85 26.72 57.88.49 1.56 1.24 3.39 2.82 3.7 1.79.35 3.31-1.71 3.19-3.56-.12-1.85-.83-3.51-1.41-5.4zm17.51-29.53c-2.3-19.39-6.39-38.63-13.36-56.8-6.97-18.17-16.89-35.28-30.09-49.4-1.02 5.79 18.49 38.88 22.25 48.76 11.91 31.29 11.6 28.44 15.72 61.78.2 1.63.6 3.55 2.1 4.15 1.69.67 3.56-1.08 3.78-2.92.23-1.85-.17-3.61-.4-5.57z" />
    ),
  },
};

const head_scar_astsd = {
  name: "Scar 8 (Both)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M157.59 311.52c.63-.97 1.28-1.98 1.97-3.04 5.37-8.31 30.13-34.88 30.11-40.33-15.36 10.45-28 24.21-37.92 39.48-.59.91-1.17 1.82-1.74 2.73 2.59.15 5.12.55 7.58 1.16zm16.57 8.21c2.16-3.31 4.77-7.23 8.01-12.15 5.5-8.36 30.71-35.19 30.76-40.63-15.55 10.63-28.42 24.53-38.59 39.89-1.98 3-3.87 6.06-5.66 9.16 1.92 1.1 3.75 2.35 5.48 3.73zm10.74 12.2c5.91-8.71 30.48-34.92 30.53-40.3-13.67 9.35-25.27 21.22-34.79 34.4 1.56 1.84 2.98 3.81 4.26 5.9zm157.51-20.41c-.63-.97-1.28-1.98-1.97-3.04-5.37-8.31-30.13-34.88-30.11-40.33 15.36 10.45 28 24.21 37.92 39.48.59.91 1.17 1.82 1.74 2.73-2.59.15-5.12.55-7.58 1.16zm-16.57 8.21c-2.16-3.31-4.77-7.23-8.01-12.15-5.5-8.36-30.71-35.19-30.76-40.63 15.55 10.63 28.42 24.53 38.59 39.89 1.98 3 3.87 6.06 5.66 9.16-1.92 1.1-3.75 2.35-5.48 3.73zm-10.74 12.2c-5.91-8.71-30.48-34.92-30.53-40.3 13.67 9.35 25.27 21.22 34.79 34.4-1.56 1.84-2.98 3.81-4.26 5.9z" />
    ),
  },
};

const head_scar_otjre = {
  name: "Scar 8 (Left)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M157.59 311.52c.63-.97 1.28-1.98 1.97-3.04 5.37-8.31 30.13-34.88 30.11-40.33-15.36 10.45-28 24.21-37.92 39.48-.59.91-1.17 1.82-1.74 2.73 2.59.15 5.12.55 7.58 1.16zm16.57 8.21c2.16-3.31 4.77-7.23 8.01-12.15 5.5-8.36 30.71-35.19 30.76-40.63-15.55 10.63-28.42 24.53-38.59 39.89-1.98 3-3.87 6.06-5.66 9.16 1.92 1.1 3.75 2.35 5.48 3.73zm10.74 12.2c5.91-8.71 30.48-34.92 30.53-40.3-13.67 9.35-25.27 21.22-34.79 34.4 1.56 1.84 2.98 3.81 4.26 5.9z" />
    ),
  },
};

const head_scar_pgsdn = {
  name: "Scar 8 (Right)",
  description: "",
  tags: ["smooth"],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  shape: {
    base: (
      <path d="M342.41 311.52c-.63-.97-1.28-1.98-1.97-3.04-5.37-8.31-30.13-34.88-30.11-40.33 15.36 10.45 28 24.21 37.92 39.48.59.91 1.17 1.82 1.74 2.73-2.59.15-5.12.55-7.58 1.16zm-16.57 8.21c-2.16-3.31-4.77-7.23-8.01-12.15-5.5-8.36-30.71-35.19-30.76-40.63 15.55 10.63 28.42 24.53 38.59 39.89 1.98 3 3.87 6.06 5.66 9.16-1.92 1.1-3.75 2.35-5.48 3.73zm-10.74 12.2c-5.91-8.71-30.48-34.92-30.53-40.3 13.67 9.35 25.27 21.22 34.79 34.4-1.56 1.84-2.98 3.81-4.26 5.9z" />
    ),
  },
};

export const HeadScarsPack1 = {
  head_scar_lKVfp,
  head_scar_bnNhR,
  head_scar_fYtUW,
  head_scar_h2MLT,
  head_scar_m5B2J,
  head_scar_fas41,
  head_scar_gar52,
  head_scar_F1xti,
  head_scar_fafJ9,
  head_scar_dafa4,
  head_scar_w85R8,
  head_scar_faJwi,
  head_scar_treoq,
  head_scar_y457j,
  head_scar_75683,
  head_scar_57474,
  head_scar_45643,
  head_scar_fgh73,
  head_scar_iytur,
  head_scar_astsd,
  head_scar_otjre,
  head_scar_pgsdn,
};
