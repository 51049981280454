// Components
import { pupilColours } from "character-creator/colours/eye";
import { pupilShapeList } from "character-creator/components/head/face/eyes/pupils/PupilShapeList";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const eyePath = "base.head.face.eyes.";
const eyeBasePath = eyePath + "base.";
const pupilPath = eyePath + "pupils.";

const PupilPage = {
  id: "pupilPage",
  name: "Pupils",
  description: "",
  dependencyMessage: "Please choose an eye shape first",
  dependencies: [
    {
      description: "Eye shape chosen",
      attribute: [eyeBasePath + "left.shape", eyeBasePath + "right.shape"],
    },
  ],
  symmetryAttribute: pupilPath + "symmetry",
  controls: {
    symmetrical: [
      {
        id: "pupilShape",
        name: "Shape",
        description: "",
        dataAttribute: [pupilPath + "left.shape", pupilPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: pupilShapeList,
        dependencies: [
          {
            description: "Eye shape chosen",
            attribute: [
              eyeBasePath + "left.shape",
              eyeBasePath + "right.shape",
            ],
          },
        ],
        colourControls: [
          {
            id: "pupilColour",
            name: "Colour",
            description: "",
            dataAttribute: [
              pupilPath + "left.colour",
              pupilPath + "right.colour",
            ],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: pupilColours,
          },
        ],
      },
    ],
    asymmetrical: [
      {
        id: "leftPupilShape",
        name: "Left Shape",
        description: "",
        dataAttribute: [pupilPath + "left.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: pupilShapeList,
        dependencies: [
          {
            description: "Left eye shape chosen",
            attribute: [eyeBasePath + "left.shape"],
          },
        ],
        colourControls: [
          {
            id: "leftPupilColour",
            name: "Left Colour",
            description: "",
            dataAttribute: [pupilPath + "left.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: pupilColours,
          },
        ],
      },
      {
        id: "rightPupilShape",
        name: "Right Shape",
        description: "",
        dataAttribute: [pupilPath + "right.shape"],
        variationAttribute: "",
        previewType: "preview",
        options: pupilShapeList,
        dependencies: [
          {
            description: "Right Eye shape chosen",
            attribute: [eyeBasePath + "right.shape"],
          },
        ],
        colourControls: [
          {
            id: "rightPupilColour",
            name: "Right Colour",
            description: "",
            dataAttribute: [pupilPath + "right.colour"],
            attributeType: "multi",
            variationAttribute: "",
            previewType: "swatch",
            options: pupilColours,
          },
        ],
      },
    ],
  },
};

export default PupilPage;
