import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterBody from "./body/CharacterBody";
import colourSchemesList from "character-creator/colours/colourSchemes";

const PreviewBase = (props) => {
  const { previewId } = props;
  return (
    <>
      <CharacterBody previewId={previewId} />
      <CharacterHead previewId={previewId} />
    </>
  );
};

export const getGlobalStyle = (state) => {
  const styleConfig = state.config.style;
  const globalColours = styleConfig.colour;
  const globalOutline =
    styleConfig.outline !== ""
      ? outlineStyles[styleConfig.outline].style.outline
      : "";
  const globalShading =
    styleConfig.shading !== ""
      ? shadingStyles[styleConfig.shading].style.shading
      : "";

  return {
    colour: globalColours,
    outline: globalOutline,
    shading: globalShading,
  };
};

const viewbox = "150 200 160 160";

const coloursBlank = {
  name: "Colour yourself",
  description: "Outlines only - good for colouring!",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    colour: colourSchemesList[0],
  },
  shape: {
    base: <></>,
  },
};

const coloursNormal = {
  name: "The whole gamut",
  description: "The standard experience - pick colours",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    colour: "",
  },
  shape: {
    base: <></>,
  },
};

const shadingFlat = {
  name: "Flat colours",
  description: "Flat colours with minimal shading",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    shading: false,
  },
  shape: {
    base: <></>,
  },
};

const shadingBasic = {
  name: "Basic shading",
  description: "Some basic shading, similar to cel shading",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    shading: true,
  },
  shape: {
    base: <></>,
  },
};

const outlinesNone = {
  name: "No outlines",
  description: "No outlines for a flat look",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    outline: false,
  },
  shape: {
    base: <></>,
  },
};

const outlinesStandard = {
  name: "Standard outlines",
  description: "Thin outlines to help separate the different items",
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  style: {
    outline: true,
  },
  shape: {
    base: <></>,
  },
};

export const colourStyles = {
  coloursNormal,
  coloursBlank,
};

export const shadingStyles = {
  shadingFlat,
  shadingBasic,
};

export const outlineStyles = {
  outlinesNone,
  outlinesStandard,
};
