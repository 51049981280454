// Components
import AllSkintones from "character-creator/colours/skin";
import { muzzleShapeList } from "character-creator/components/head/face/extras/muzzle/MuzzleShapeList";
// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const muzzlePath = "base.head.face.extras.muzzle.";

const MuzzlePage = {
  id: "muzzlePage",
  name: "Muzzle",
  description: "",
  controls: [
    {
      id: "muzzleShapeGroup",
      name: "Shape",
      description: "",
      dataAttribute: [muzzlePath + "shape"],
      previewType: "preview",
      options: muzzleShapeList,
      colourControls: [
        {
          id: "MuzzleColour",
          name: "Colour",
          description: "",
          dataAttribute: [muzzlePath + "colour"],
          attributeType: "multi",
          variationAttribute: "",
          previewType: "swatch",
          options: AllSkintones,
        },
      ],
      transformControls: [
        {
          id: "muzzleTransformY",
          name: "Vertical",
          description: "",
          dataAttribute: [muzzlePath + "transform.y"],
          range: {
            min: -50,
            max: 50,
            step: 10,
          },
        },
        {
          id: "muzzleTransformScale",
          name: "Size",
          description: "",
          dataAttribute: [muzzlePath + "transform.scale"],
          range: {
            min: 0.5,
            max: 1.5,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default MuzzlePage;
