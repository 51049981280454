import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import MoustacheList from "./MoustacheList";
import {
  buildTransform,
  translateTransformObject,
} from "character-creator/menu/menu-helper";

function mapStateToProps(state, ownProps) {
  const moustacheConfig = state.config.base.head.face.moustache;
  let moustacheObject = null;
  if (ownProps.preview && ownProps.moustache) {
    moustacheObject = ownProps.moustache;
  } else if (moustacheConfig.shape !== "0") {
    moustacheObject = MoustacheList[moustacheConfig.shape];
  }

  const colour = moustacheConfig.colour
    ? moustacheConfig.colour
    : state.config.base.hair.colour;

  const transform = !ownProps.preview
    ? translateTransformObject(buildTransform(moustacheConfig.transform))
    : "";

  return {
    colour,
    moustache: moustacheObject,

    transform,
  };
}

const Moustache = (props) => {
  const { colour, moustache, previewId, transform } = props;

  if (moustache) {
    return (
      <g
        id={previewId ? `g-moustache~${previewId}` : "g-moustache"}
        {...transform}
        transform-origin="50% 50%"
      >
        {moustache.shape.left && (
          <Component
            id={previewId ? `moustache-left~${previewId}` : "moustache-left"}
            component={{ shape: moustache.shape.left }}
            colour={colour}
            preview={previewId ? true : false}
          />
        )}
        {moustache.shape.left && (
          <Component
            id={previewId ? `moustache-right~${previewId}` : "moustache-right"}
            component={{ shape: moustache.shape.right }}
            colour={colour}
            preview={previewId ? true : false}
          />
        )}
        {!moustache.shape.left && (
          <Component
            id={previewId ? `moustache-full~${previewId}` : "moustache-full"}
            component={moustache}
            colour={colour}
            preview={previewId ? true : false}
          />
        )}
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(Moustache);
