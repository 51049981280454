import React from "react";
import CharacterBody from "character-creator/components/body/CharacterBody";
import CharacterHead from "character-creator/components/head/CharacterHead";
import CharacterHairBack from "./CharacterHairBack";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHairBack
        preview={true}
        previewId={previewId}
        hair={component}
      />
      <CharacterBody preview={true} previewId={previewId} />
      <CharacterHead preview={true} previewId={previewId} />
    </>
  );
};

export const viewbox = "0 0 500 500";
export const viewboxAboveShoulder = "100 100 300 300";
export const viewboxAboveShoulderBig = "60 100 380 380";
export const viewboxTall = "100 50 300 300";

export const viewboxLeft = "0 200 300 300";
export const viewboxLeftUpper = "100 100 150 150";
export const viewboxLeftAboveShoulder = "30 100 300 300";
export const viewboxLeftAboveShoulderClose = "40 200 220 220";
export const viewboxLeftAboveShoulderBig = "30 60 300 300";
export const viewboxLeftLong = "0 100 380 380";

export const viewboxRight = "250 200 300 300";
export const viewboxRightUpper = "250 100 150 150";
export const viewboxRightAboveShoulder = "250 100 300 300";
export const viewboxRightAboveShoulderClose = "250 200 220 220";
export const viewboxRightAboveShoulderBig = "250 70 330 330";
export const viewboxRightLong = "250 100 380 380";
