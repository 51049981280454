import React from "react";
import { viewboxEyes, PreviewBaseEyes } from "../Preview";

const contouring_eyes_k8cEvcB754 = {
  name: "Contouring (Eyes) 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxEyes,
    base: PreviewBaseEyes,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M240.19 249.091c1.725-1.962 1.725-4.9.001-6.862-4.521-5.123-13.504-14.177-20.543-14.177-10.019 0-18.153 7.89-18.153 17.608s8.134 17.608 18.153 17.608c7.039 0 16.022-9.054 20.542-14.177Z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M259.81 249.091c-1.725-1.962-1.725-4.9-.001-6.862 4.521-5.123 13.504-14.177 20.543-14.177 10.019 0 18.153 7.89 18.153 17.608s-8.134 17.608-18.153 17.608c-7.039 0-16.022-9.054-20.542-14.177Z" />
        </>
      ),
    },
  },
};

const contouring_eyes_33c4YTnRfu = {
  name: "Contouring 5",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxEyes,
    base: PreviewBaseEyes,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M232.498 226.688c1.697-.98 4.379.483 5.985 3.265 1.606 2.783 1.532 5.837-.165 6.817-1.697.98-4.379-.483-5.985-3.266-1.607-2.782-1.533-5.836.165-6.816Z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M267.502 226.688c-1.697-.98-4.379.483-5.985 3.265-1.606 2.783-1.532 5.837.165 6.817 1.697.98 4.379-.483 5.985-3.266 1.607-2.782 1.533-5.836-.165-6.816Z" />
        </>
      ),
    },
  },
};

const contouring_eyes_wemPHPheX5 = {
  name: "Contouring 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxEyes,
    base: PreviewBaseEyes,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M230.91 230.317c-.124-.325-.034-.707.228-.97.262-.262.644-.352.97-.228 2.459.874 6.725 2.541 8.265 4.081 1.378 1.377 1.29 3.702-.196 5.188-1.485 1.485-3.81 1.573-5.187.195-1.54-1.54-3.207-5.806-4.08-8.266Z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M269.09 230.317c.124-.325.034-.707-.228-.97-.262-.262-.644-.352-.97-.228-2.459.874-6.725 2.541-8.265 4.081-1.378 1.377-1.29 3.702.196 5.188 1.485 1.485 3.81 1.573 5.187.195 1.54-1.54 3.207-5.806 4.08-8.266Z" />
        </>
      ),
    },
  },
};

const contouring_eyes_ScAbFAFchU = {
  name: "Contouring 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxEyes,
    base: PreviewBaseEyes,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M237.486 243.888c-3.204 3.203-9.945 1.664-15.045-3.435-5.099-5.1-6.638-11.841-3.435-15.045 3.204-3.204 9.945-1.664 15.045 3.435 5.099 5.1 6.639 11.841 3.435 15.045Z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M262.514 243.888c3.204 3.203 9.945 1.664 15.045-3.435 5.099-5.1 6.638-11.841 3.435-15.045-3.204-3.204-9.945-1.664-15.045 3.435-5.099 5.1-6.639 11.841-3.435 15.045Z" />
        </>
      ),
    },
  },
  gradient: {
    left: "matrix(-5.70988, -21.823, 21.823, -5.70988, 228.246, 245.559)",
    right: "matrix(5.70988,-21.823,-21.823,-5.70988,271.754,245.559)",
  },
};

const contouring_eyes_aJ92HvSA6S = {
  name: "Contouring 6",
  description: "",
  tags: [],
  preview: {
    viewbox: viewboxEyes,
    base: PreviewBaseEyes,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <>
          <path d="M237.486 247.231c-3.204-3.204-9.945-1.664-15.045 3.435-5.099 5.1-6.638 11.841-3.435 15.045 3.204 3.203 9.945 1.664 15.045-3.435 5.099-5.1 6.639-11.841 3.435-15.045Z" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <path d="M262.514 247.231c3.204-3.204 9.945-1.664 15.045 3.435 5.099 5.1 6.638 11.841 3.435 15.045-3.204 3.203-9.945 1.664-15.045-3.435-5.099-5.1-6.639-11.841-3.435-15.045Z" />
        </>
      ),
    },
  },
  gradient: {
    left: "matrix(-5.70988,21.823,21.823,5.70988,228.246,245.559)",
    right: "matrix(5.70988,21.823,-21.823,5.70988,271.754,245.559)",
  },
};

export const ContouringEyesPack1 = {
  contouring_eyes_k8cEvcB754,
  contouring_eyes_33c4YTnRfu,
  contouring_eyes_wemPHPheX5,
  contouring_eyes_ScAbFAFchU,
  contouring_eyes_aJ92HvSA6S,
};
