import React from "react";
import { connect } from "react-redux";
import { menuSkeleton } from "character-creator/menu/config/menu-config";
import TabbedNavigation from "./elements/TabbedNavigation";

const mapStateToProps = (state) => {
  return {
    config: state.config,
    navKeys: state.menu.navKeys,
  };
};

const Configurator = (props) => {
  return (
    <>
      <TabbedNavigation menus={menuSkeleton} />
    </>
  );
};

export default connect(mapStateToProps)(Configurator);
