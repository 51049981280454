import React from "react";
import { PreviewBase, viewbox } from "../Preview";

const iris_shape_agUl3 = {
  name: "Iris 1",
  description: "Standard circular irises",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <circle cx="212.25" cy="246.78" r="10.95" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <circle cx="287.75" cy="246.78" r="10.95" />
        </>
      ),
    },
  },
};

const iris_shape_piEvO = {
  name: "Iris 2",
  description: "Large irises that cover the whole eye",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <circle cx="212.25" cy="246.78" r="26.17" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <circle cx="287.75" cy="246.78" r="26.17" />
        </>
      ),
    },
  },
};

const iris_shape_pe42O = {
  name: "Iris 3",
  description: "Large oval irises",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: PreviewBase,
  },
  shape: {
    left: {
      base: (
        <>
          <ellipse cx="212.25" cy="245.85" rx="10.95" ry="22.25" />
        </>
      ),
    },
    right: {
      base: (
        <>
          <ellipse cx="287.75" cy="245.85" rx="10.95" ry="22.25" />
        </>
      ),
    },
  },
};

export const IrisOuterPack1 = {
  iris_shape_agUl3,
  iris_shape_piEvO,
  iris_shape_pe42O,
};
