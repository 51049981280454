import React from "react";

import CharacterHead from "character-creator/components/head/CharacterHead";
import Tattoo from "./Tattoo";

export const PreviewBase = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} previewId={previewId} />
      <Tattoo
        preview={true}
        previewId={previewId}
        tattoo={component}
        side="full"
      />
    </>
  );
};

export const PreviewBaseLeft = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Tattoo
        preview={true}
        previewId={previewId}
        tattoo={component}
        side="left"
      />
    </>
  );
};

export const PreviewBaseRight = (props) => {
  const { component, previewId } = props;

  return (
    <>
      <CharacterHead preview={true} />
      <Tattoo
        preview={true}
        previewId={previewId}
        tattoo={component}
        side="right"
      />
    </>
  );
};

export const viewbox = "150 160 200 180";
