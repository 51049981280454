import { BlankListOption } from "character-creator/menu/elements/controls/options/BlankOption";
import { CentreHornsPack1 } from "./packs/HornsFrontCentrePack1";
import { FrontHornsPack1 } from "./packs/HornsFrontSidePack1";
import { AntennaePack1 } from "../../forehead/AntennaePack1";

export const CentreHornsList = {
  "0": BlankListOption,
  ...CentreHornsPack1,
};

export const FrontSideHornsList = {
  "0": BlankListOption,
  ...FrontHornsPack1,
  ...AntennaePack1,
};
