const colour_metal_2AEsd = {
  id: "colour_metal_2AEsd",
  name: "Bronze",
  outline: "#2E2118",
  darker: "#532E13",
  dark: "#6C370F",
  base: "#904914",
  light: "#B87642",
  lighter: "#CD9E7A",
};
const colour_metal_gfBPl = {
  id: "colour_metal_gfBPl",
  name: "Copper",
  outline: "#661503",
  darker: "#87301D",
  dark: "#A0412C",
  base: "#BA5038",
  light: "#D46850",
  lighter: "#E09D8D",
};

const colour_metal_yTUOd = {
  id: "colour_metal_yTUOd",
  name: "Silver",
  outline: "#0B0835",
  darker: "#49475B",
  dark: "#6F6F7C",
  base: "#95969D",
  light: "#D5D5D7",
  lighter: "#F4F4F5",
};
const colour_metal_OZLjR = {
  id: "colour_metal_OZLjR",
  name: "Gold",
  outline: "#754110",
  darker: "#994F00",
  dark: "#BD7C00",
  base: "#D79500",
  light: "#F5AB00",
  lighter: "#FFDA85",
};

const colour_metal_4K6zI = {
  id: "colour_metal_4K6zI",
  name: "Platinum",
  outline: "#917171",
  darker: "#A9999A",
  dark: "#C1C1C3",
  base: "#D6D6D7",
  light: "#EAEAEB",
  lighter: "#F5F5F5",
};

const colour_metal_cWtCs = {
  id: "colour_metal_cWtCs",
  name: "Rose Gold",
  outline: "#8E563E",
  darker: "#BB7D63",
  dark: "#D28F74",
  base: "#EEA68A",
  light: "#F6BEA7",
  lighter: "#F9D6C7",
};

export const AllMetals = {
  colour_metal_2AEsd: colour_metal_2AEsd,
  colour_metal_gfBPl: colour_metal_gfBPl,
  colour_metal_yTUOd: colour_metal_yTUOd,
  colour_metal_OZLjR: colour_metal_OZLjR,
  colour_metal_4K6zI: colour_metal_4K6zI,
  colour_metal_cWtCs: colour_metal_cWtCs,
};

export default AllMetals;
