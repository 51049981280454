import React from "react";
import { ShinePreviewBase, viewbox } from "../Preview";

const glasses_shine_zrCM3ecFjJ = {
  name: "Shine 1",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: ShinePreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="m199.881 201.085 3.302-2.312 51.574 73.656-3.301 2.312-51.575-73.656Zm-18.171 12.724 15.159-10.615 51.575 73.656-15.159 10.614-51.575-73.655Zm-10.483 7.34 6.272-4.391 51.574 73.655-6.272 4.392-51.574-73.656Z" />
      ),
    },
    right: {
      base: (
        <path d="m273.897 201.085 3.302-2.312 51.574 73.656-3.301 2.312-51.575-73.656Zm-18.171 12.724 15.159-10.615 51.575 73.656-15.159 10.614-51.575-73.655Zm-10.483 7.34 6.271-4.391 51.575 73.655-6.272 4.392-51.574-73.656Z" />
      ),
    },
  },
};

const glasses_shine_NTvYT6DLqb = {
  name: "Shine 2",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: ShinePreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="m226.103 201.085-3.302-2.312-51.574 73.656 3.301 2.312 51.575-73.656Zm18.171 12.724-15.159-10.615-51.575 73.656 15.159 10.614 51.575-73.655Zm10.483 7.34-6.271-4.391-51.575 73.655 6.272 4.392 51.574-73.656Z" />
      ),
    },
    right: {
      base: (
        <path d="m300.119 201.085-3.302-2.312-51.574 73.656 3.301 2.312 51.575-73.656Zm18.171 12.724-15.159-10.615-51.575 73.656 15.159 10.614 51.575-73.655Zm10.483 7.34-6.272-4.391-51.574 73.655 6.272 4.392 51.574-73.656Z" />
      ),
    },
  },
};

const glasses_shine_hCutdkKnjN = {
  name: "Shine 3",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: ShinePreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="m233.933 205.679-1.327-.929-51.574 73.655 1.327.929 51.574-73.655Zm7.301 5.112-6.091-4.265-51.574 73.656 6.091 4.264 51.574-73.655Zm4.212 2.949-2.52-1.764-51.574 73.655 2.52 1.765 51.574-73.656Z" />
      ),
    },
    right: {
      base: (
        <path d="m309.53 205.679-1.327-.929-51.574 73.655 1.327.929 51.574-73.655Zm7.301 5.112-6.091-4.265-51.574 73.656 6.091 4.264 51.574-73.655Zm4.212 2.949-2.52-1.764-51.574 73.655 2.52 1.765 51.574-73.656Z" />
      ),
    },
  },
};

const glasses_shine_524fjeGTqP = {
  name: "Shine 4",
  description: "",
  tags: [],
  preview: {
    viewbox: viewbox,
    base: ShinePreviewBase,
  },
  stroke: false,
  shape: {
    left: {
      base: (
        <path d="m190.47 205.679 1.327-.929 51.574 73.655-1.327.929-51.574-73.655Zm-7.301 5.112 6.091-4.265 51.574 73.656-6.091 4.264-51.574-73.655Zm-4.212 2.949 2.52-1.764 51.574 73.655-2.52 1.765-51.574-73.656Z" />
      ),
    },
    right: {
      base: (
        <path d="m266.067 205.679 1.327-.929 51.574 73.655-1.327.929-51.574-73.655Zm-7.301 5.112 6.091-4.265 51.574 73.656-6.091 4.264-51.574-73.655Zm-4.212 2.949 2.52-1.764 51.574 73.655-2.52 1.765-51.574-73.656Z" />
      ),
    },
  },
};

export const GlassesShinePack1 = {
  glasses_shine_zrCM3ecFjJ,
  glasses_shine_NTvYT6DLqb,
  glasses_shine_hCutdkKnjN,
  glasses_shine_524fjeGTqP,
};
