import React from "react";
import { PreviewBase, previewViewbox } from "../Preview";

const facePaint_shape_pUF2d = {
  name: "Face Paint 1 (Small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="153.003" y="238.645" width="193.994" height="15.364" />,
  },
};

const facePaint_shape_xnxot = {
  name: "Face Paint 1 (Medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="153.003" y="230.758" width="193.994" height="31.139" />,
  },
};

const facePaint_shape_b1vLk = {
  name: "Face Paint 1 (Large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="153.003" y="224.366" width="193.994" height="63.109" />,
  },
};

const facePaint_shape_2ZLO8 = {
  name: "Face Paint 2 (Small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="244.879" y="150.59" width="10.242" height="227.241" />,
  },
};

const facePaint_shape_fa941 = {
  name: "Face Paint 2 (Medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="236.474" y="150.59" width="27.052" height="227.241" />,
  },
};

const facePaint_shape_hsel5 = {
  name: "Face Paint 2 (Large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="210.947" y="150.59" width="78.106" height="227.241" />,
  },
};

const facePaint_shape_daow3 = {
  name: "Face Paint 3 (Downwards 1)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 73.111v102.923L250 238.893l128.84-62.859V73.111H121.16Z" />
    ),
  },
};

const facePaint_shape_f9134 = {
  name: "Face Paint 3 (Downwards 2)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 105.27v102.923L250 271.052l128.84-62.859V105.27H121.16Z" />
    ),
  },
};

const facePaint_shape_glse4 = {
  name: "Face Paint 3 (Downwards 3)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 132.928v102.923L250 298.71l128.84-62.859V132.928H121.16Z" />
    ),
  },
};

const facePaint_shape_fa9o3 = {
  name: "Face Paint 3 (Downwards 4)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 167.109v102.923L250 332.891l128.84-62.859V167.109H121.16Z" />
    ),
  },
};

const facePaint_shape_4194n = {
  name: "Face Paint 3 (Upwards 1)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 455.681V352.758L250 289.899l128.84 62.859v102.923H121.16Z" />
    ),
  },
};

const facePaint_shape_dao93 = {
  name: "Face Paint 3 (Upwards 2)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 423.523V320.6L250 257.741 378.84 320.6v102.923H121.16Z" />
    ),
  },
};

const facePaint_shape_glemm = {
  name: "Face Paint 3 (Upwards 3)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 395.865V292.942L250 230.083l128.84 62.859v102.923H121.16Z" />
    ),
  },
};

const facePaint_shape_d041j = {
  name: "Face Paint 3 (Upwards 4)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M121.16 361.684V258.761L250 195.902l128.84 62.859v102.923H121.16Z" />
    ),
  },
};

const facePaint_shape_r42o4 = {
  name: "Face Paint 4 (Small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M250 233.848c13.389 0 23.982-7.454 40.168-7.454 28.633 0 51.878 9.091 51.878 20.289 0 11.197-23.245 20.288-51.878 20.288-16.186 0-26.779-7.454-40.168-7.454s-23.982 7.454-40.168 7.454c-28.633 0-51.878-9.091-51.878-20.288 0-11.198 23.245-20.289 51.878-20.289 16.186 0 26.779 7.454 40.168 7.454Z" />
    ),
  },
};

const facePaint_shape_falnn = {
  name: "Face Paint 4 (Large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M250 222.631c13.389 0 23.982-11.124 40.168-11.124 28.633 0 51.878 13.566 51.878 30.276s-23.245 30.277-51.878 30.277c-16.186 0-26.779-11.124-40.168-11.124s-23.982 11.124-40.168 11.124c-28.633 0-51.878-13.567-51.878-30.277s23.245-30.276 51.878-30.276c16.186 0 26.779 11.124 40.168 11.124Z" />
    ),
  },
};

const facePaint_shape_sd824 = {
  name: "Face Paint 5 (Left, small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="207.396" y="146.701" width="10.242" height="227.241" />,
  },
};

const facePaint_shape_fao43 = {
  name: "Face Paint 5 (Left, medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="198.991" y="146.701" width="27.052" height="227.241" />,
  },
};

const facePaint_shape_flaw9 = {
  name: "Face Paint 5 (Left, large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="185.965" y="146.701" width="53.104" height="227.241" />,
  },
};

const facePaint_shape_mjfol = {
  name: "Face Paint 5 (Right, small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="282.362" y="150.399" width="10.242" height="227.241" />,
  },
};

const facePaint_shape_fgoao = {
  name: "Face Paint 5 (Right, medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="273.957" y="150.399" width="27.052" height="227.241" />,
  },
};

const facePaint_shape_0h653 = {
  name: "Face Paint 5 (Right, large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: <rect x="260.931" y="150.399" width="53.104" height="227.241" />,
  },
};

const facePaint_shape_lgjhh = {
  name: "Face Paint 5 (Both, small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M207.396 146.701h10.242v227.241h-10.242zm74.966 3.698h10.242V377.64h-10.242z" />
    ),
  },
};

const facePaint_shape_0fqwk3 = {
  name: "Face Paint 5 (Both, medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M198.991 146.701h27.052v227.241h-27.052zm74.966 3.698h27.052V377.64h-27.052z" />
    ),
  },
};

const facePaint_shape_f0hhhs = {
  name: "Face Paint 5 (Both, large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M185.965 146.701h53.104v227.241h-53.104zm74.966 3.698h53.104V377.64h-53.104z" />
    ),
  },
};

const facePaint_shape_fljhh = {
  name: "Face Paint 6 (Left, small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M173.279,180.249L166.037,187.491L326.721,348.175L333.963,340.932L173.279,180.249Z" />
    ),
  },
};

const facePaint_shape_42387m = {
  name: "Face Paint 6 (Left, medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M179.222,174.306L160.094,193.434L320.778,354.118L339.906,334.989L179.222,174.306Z" />
    ),
  },
};

const facePaint_shape_cnkdf = {
  name: "Face Paint 6 (Left, large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M188.433,165.095L150.883,202.645L311.567,363.329L349.117,325.779L188.433,165.095Z" />
    ),
  },
};

const facePaint_shape_f9e5j = {
  name: "Face Paint 6 (Right, small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M326.721,180.249L333.963,187.491L173.279,348.175L166.037,340.932L326.721,180.249Z" />
    ),
  },
};

const facePaint_shape_bkgmnc = {
  name: "Face Paint 6 (Right, medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M320.778,174.306L339.906,193.434L179.222,354.118L160.094,334.989L320.778,174.306Z" />
    ),
  },
};

const facePaint_shape_vgodfg = {
  name: "Face Paint 6 (Right, large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <path d="M311.567,165.095L349.117,202.645L188.433,363.329L150.883,325.779L311.567,165.095Z" />
    ),
  },
};

const facePaint_shape_gosds = {
  name: "Face Paint 6 (Both, small)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <>
        <path d="M173.279,180.249L166.037,187.491L326.721,348.175L333.963,340.932L173.279,180.249Z" />
        <path d="M326.721,180.249L333.963,187.491L173.279,348.175L166.037,340.932L326.721,180.249Z" />
      </>
    ),
  },
};

const facePaint_shape_fldsj = {
  name: "Face Paint 6 (Both, medium)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <>
        <path d="M179.222,174.306L160.094,193.434L320.778,354.118L339.906,334.989L179.222,174.306Z" />
        <path d="M320.778,174.306L339.906,193.434L179.222,354.118L160.094,334.989L320.778,174.306Z" />
      </>
    ),
  },
};

const facePaint_shape_g9r53 = {
  name: "Face Paint 6 (Both, large)",
  description: "",
  tags: [],
  preview: {
    viewbox: previewViewbox,
    base: PreviewBase,
  },
  stroke: false,
  maskId: "url(#head_mask)",
  shape: {
    base: (
      <>
        <path d="M188.433,165.095L150.883,202.645L311.567,363.329L349.117,325.779L188.433,165.095Z" />
        <path d="M311.567,165.095L349.117,202.645L188.433,363.329L150.883,325.779L311.567,165.095Z" />
      </>
    ),
  },
};

export const facePaintPack1 = {
  facePaint_shape_pUF2d,
  facePaint_shape_xnxot,
  facePaint_shape_b1vLk,
  facePaint_shape_2ZLO8,
  facePaint_shape_fa941,
  facePaint_shape_hsel5,
  facePaint_shape_daow3,
  facePaint_shape_f9134,
  facePaint_shape_glse4,
  facePaint_shape_fa9o3,
  facePaint_shape_4194n,
  facePaint_shape_dao93,
  facePaint_shape_glemm,
  facePaint_shape_d041j,
  facePaint_shape_r42o4,
  facePaint_shape_falnn,
  facePaint_shape_sd824,
  facePaint_shape_fao43,
  facePaint_shape_flaw9,
  facePaint_shape_mjfol,
  facePaint_shape_fgoao,
  facePaint_shape_0h653,
  facePaint_shape_lgjhh,
  facePaint_shape_0fqwk3,
  facePaint_shape_f0hhhs,
  facePaint_shape_fljhh,
  facePaint_shape_42387m,
  facePaint_shape_cnkdf,
  facePaint_shape_f9e5j,
  facePaint_shape_bkgmnc,
  facePaint_shape_vgodfg,
  facePaint_shape_gosds,
  facePaint_shape_fldsj,
  facePaint_shape_g9r53,
};
