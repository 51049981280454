const colour_purple_Ld9Ia = {
  id: "colour_purple_Ld9Ia",
  name: "Light Dusky Purple",
  outline: "hsl(9, 49%, 10%)",
  darker: "hsl(289,13%,42%)",
  dark: "hsl(313,18%,64%)",
  base: "hsl(326,25%,76%)",
  light: "hsl(347,45%,84%)",
  lighter: "hsl(6,72%,89%)",
};

const colour_purple_m0Zdx = {
  id: "colour_purple_m0Zdx",
  name: "Dark Purple",
  outline: "hsl(240, 67%, 13%)",
  darker: "hsl(258, 56%, 20%)",
  dark: "hsl(258, 36%, 27%)",
  base: "hsl(258, 35%, 37%)",
  light: "hsl(259, 22%, 48%)",
  lighter: "hsl(261, 16%, 68%)",
};

const colour_purple_9rPCZ = {
  id: "colour_purple_9rPCZ",
  name: "Plum",
  outline: "#450B3B",
  darker: "#630D55",
  dark: "#770464",
  base: "#980B80",
  light: "#A83D96",
  lighter: "#C856B5",
};

const colour_purple_wmkrx = {
  id: "colour_purple_wmkrx",
  name: "Unicorn",
  outline: "#53486C",
  darker: "#A9A8C7",
  dark: "#D3B9DC",
  base: "#F1DCDB",
  light: "#E3EEF4",
  lighter: "#F6F2E6",
};

const colour_purple_wYEyH = {
  id: "colour_purple_wYEyH",
  name: "Mid Purple",
  outline: "#582867",
  darker: "#8F529D",
  dark: "#AD71AB",
  base: "#C38ABE",
  light: "#C7A1CA",
  lighter: "#DFCDE0",
};

export const AllPurples = {
  colour_purple_Ld9Ia: colour_purple_Ld9Ia,
  colour_purple_m0Zdx: colour_purple_m0Zdx,
  colour_purple_9rPCZ: colour_purple_9rPCZ,
  colour_purple_wmkrx: colour_purple_wmkrx,
  colour_purple_wYEyH: colour_purple_wYEyH,
};

export default AllPurples;
