import { allColours } from "character-creator/colours/colourSchemes";

import { HeadMarkingsList } from "character-creator/components/head/markings/HeadMarkingsList";

// import icon from "character-creator/assets/menu-icons/streamline-ink.png";

const basePath = "base.";

const headPath = basePath + "head.";

const markingsPath = headPath + "markings.";

const HeadMarkingsPage = {
  id: "headMarkingsPage",
  name: "Markings",
  description: "",
  controls: [
    {
      id: "headMarking1Shape",
      name: "Marking 1",
      description: "",
      dataAttribute: [markingsPath + "marking1.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: HeadMarkingsList,
      colourControls: [
        {
          id: "headMarking1Colour",
          name: "Colour",
          description: "",
          dataAttribute: [markingsPath + "marking1.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headMarking1TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [markingsPath + "marking1.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "headMarking2Shape",
      name: "Marking 2",
      description: "",
      dataAttribute: [markingsPath + "marking2.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: HeadMarkingsList,
      colourControls: [
        {
          id: "headMarking2Colour",
          name: "Colour",
          description: "",
          dataAttribute: [markingsPath + "marking2.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headMarking2TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [markingsPath + "marking2.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
    {
      id: "headMarking3Shape",
      name: "Marking 3",
      description: "",
      dataAttribute: [markingsPath + "marking3.shape"],
      variationAttribute: "",
      previewType: "preview",
      options: HeadMarkingsList,
      colourControls: [
        {
          id: "headMarking3Colour",
          name: "Colour",
          description: "",
          dataAttribute: [markingsPath + "marking3.colour"],
          attributeType: "multi",
          previewType: "swatch",
          options: allColours,
        },
      ],
      transformControls: [
        {
          id: "headMarking3TransformO",
          name: "Opacity",
          description: "",
          dataAttribute: [markingsPath + "marking3.transform.opacity"],
          range: {
            min: 0.25,
            max: 1,
            step: 0.25,
          },
        },
      ],
    },
  ],
};

export default HeadMarkingsPage;
