import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { hairBaseList } from "./HairBaseList";

function mapStateToProps(state, ownProps) {
  const hairConfig = state.config.base.hair.head.base;

  const hairBaseColour = hairConfig.colour
    ? hairConfig.colour
    : state.config.base.hair.colour;

  let hairObject = null;
  if (ownProps.preview) {
    hairObject = ownProps.hair.shape;
  } else if (hairConfig.shape !== "0") {
    hairObject = hairBaseList[hairConfig.shape].shape;
  }

  return {
    full: {
      shape: hairObject,
      colour: hairBaseColour,
    },
  };
}

const CharacterHairBase = (props) => {
  const { full, previewId } = props;

  if (full.shape) {
    return (
      <g
        id={previewId ? `g-hair-base~${previewId}` : "g-hair-base"}
        mask="url(#head_mask)"
      >
        <Component
          id={previewId ? `hair-base~${previewId}` : "hair-base"}
          component={full}
          colour={full.colour}
          preview={previewId ? true : false}
        />
      </g>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(CharacterHairBase);
