import React from "react";
import { connect } from "react-redux";
import Component from "character-creator/components/Component";
import { NeckAccessoriesList } from "./NeckAccessoriesList";
import AllMetals from "character-creator/colours/collections/Metals";
import {
  buildTransform,
  translateTransformObject,
} from "character-creator/menu/menu-helper";
import ClippedHeadShadow from "character-creator/components/head/ClippedHeadShadow";

function mapStateToProps(state, ownProps) {
  const layerConfig = ownProps.layerConfig;
  let layerObjectMain = null;
  let layerObjectExtra = null;
  let colourExtra = null;

  /**
  First check if front/back, if so then use front

  Then check if main/extra (could be front/back and also main/extra)
   */
  if (ownProps.preview && ownProps.accessory) {
    layerObjectMain = ownProps.accessory.shape.front
      ? ownProps.accessory.shape.front
      : ownProps.accessory.shape;
  } else if (layerConfig && layerConfig.shape !== "0") {
    layerObjectMain = NeckAccessoriesList[layerConfig.shape].shape.front
      ? NeckAccessoriesList[layerConfig.shape].shape.front
      : NeckAccessoriesList[layerConfig.shape].shape;
  }

  if (layerObjectMain && layerObjectMain["main"]) {
    // Assign "extra" before reassigning main
    layerObjectExtra = layerObjectMain["extra"];
    layerObjectMain = layerObjectMain["main"];
    colourExtra =
      layerConfig && layerConfig.colour.extra !== ""
        ? layerConfig.colour.extra
        : AllMetals[Object.keys(AllMetals)[2]];
  }

  const colourMain =
    layerConfig && layerConfig.colour.main !== ""
      ? layerConfig.colour.main
      : AllMetals[Object.keys(AllMetals)[3]];

  const AccessoryLayerMain = {
    shape: layerObjectMain,
    colour: colourMain,
  };

  const AccessoryLayerExtra = {
    shape: layerObjectExtra,
    colour: colourExtra,
  };

  const transform = !ownProps.preview
    ? translateTransformObject(buildTransform(layerConfig.transform))
    : "";

  return {
    AccessoryLayerMain,
    AccessoryLayerExtra,
    transform,
  };
}

const NeckAccessoryFront = (props) => {
  const {
    AccessoryLayerMain,
    AccessoryLayerExtra,
    previewId,
    layer,
    transform,
  } = props;

  // Add other things, like extras, markings and all that fun stuff

  if (AccessoryLayerMain.shape) {
    return (
      <>
        <g
          id={
            previewId
              ? `g-neckAccessory-layer-front-${layer}~${previewId}`
              : `g-neckAccessory-layer-front-${layer}`
          }
          transform-origin="50% 50%"
          {...transform}
        >
          <Component
            id={
              previewId
                ? `neckAccessory-layer-front-main-${layer}~${previewId}`
                : `neckAccessory-layer-front-main-${layer}`
            }
            component={AccessoryLayerMain}
            colour={AccessoryLayerMain.colour}
          />

          {AccessoryLayerExtra.shape && (
            <Component
              id={
                previewId
                  ? `neckAccessory-layer-front-extra-${layer}~${previewId}`
                  : `neckAccessory-layer-front-extra-${layer}`
              }
              component={AccessoryLayerExtra}
              colour={AccessoryLayerExtra.colour}
            />
          )}
        </g>
        {!previewId && (
          <ClippedHeadShadow
            componentName={`neckAccessory-layer-front-main-${layer}`}
            maskUrl={`neckAccessory-layer-front-main-${layer}_base`}
            colour={AccessoryLayerMain.colour}
            maskTransform={transform}
          />
        )}
        {!previewId && AccessoryLayerExtra.shape && (
          <ClippedHeadShadow
            componentName={`neckAccessory-layer-front-extra-${layer}`}
            maskUrl={`neckAccessory-layer-front-extra-${layer}_base`}
            colour={AccessoryLayerExtra.colour}
            maskTransform={transform}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default connect(mapStateToProps)(NeckAccessoryFront);
