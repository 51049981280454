import React from "react";
import BackgroundShapes from "./shapes/BackgroundShapes";

/**
 *
 * @param props
 * @returns A collection of the components that combine to make the Background
 */
const Background = (props) => {
  return (
    <g id="background">
      <BackgroundShapes />
    </g>
  );
};

export default Background;
